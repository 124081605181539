<template>
    <div class="">
        <div class="l-app-inbox-header ui relaxed padded grid">
            <div class="computer only tablet only equal width middle aligned row">
                <div class="column">
                    <span class="hidden">Filter by &nbsp;</span>
                    <select class="ui dropdown margin-right-sm hidden">
                                    <option value="" selected>Short Code</option>
                                </select>
                    <form class="ui search margin-right-sm">
                        <div class="ui left icon input">
                            <i class="search icon"></i>
                            <input type="text" placeholder="Search contact" v-model="msisdn" v-on:keyup="searchContact()">
                        </div>
                         <div class="ui left icon input">
                            <select class="ui fluid dropdown" v-model="shortcode">
                                <option value="">Select WhatsApp Number</option>
                                <option v-for="s_id in senderIDs" v-show="senderIDs.length > 0 && s_id.status == 13" :key="s_id.short_code">{{s_id.short_code}}</option>
                                
                                <option v-if="senderIDs.length == 0"  value="254709183222">254709183222</option>
                            </select>
                        </div>
                    </form>
                   
                    <div class="ui tiny left icon input datepicker-trigger margin-right-sm">
                        <i class="calendar icon"></i>
                        <input type="text" id="datepicker-trigger" placeholder="Select date range" :value="formatDates(dateOne, dateTwo)" />
                        <AirbnbStyleDatepicker :trigger-element-id="'datepicker-trigger'" :mode="'range'" :fullscreen-mobile="true" :months-to-show="2" :date-one="dateOne" :date-two="dateTwo" :close-after-select="false" @date-one-selected="val => { dateOne = val }" @date-two-selected="val => { dateTwo = val }"
                            @apply="searchContact()" />
                    </div>
                    <button class="ui right floated button margin-left-md" @click="exportContact()">Export</button>
                    <div class="ui basic right floated buttons">
                        <button class="ui icon button" @click="inboxViewTog('columns')" :class="{active: inboxView === 'columns'}"><i class="columns icon"></i></button>
                    </div>
                </div>
            </div>
            <div class="mobile only equal width middle aligned row" :class="{'mobile-header-hidden': this.$route.name == 'whatsApp-message-details'}">
                <div class="column">
                    <div class="ui search">
                        <div class="ui left icon fluid input">
                            <i class="search icon"></i>
                            <input type="text" placeholder="Search contact" v-model="msisdn" v-on:keyup="searchContact()">
                        </div>
                    </div>
                </div>
            </div>
            <div class="mobile only equal width middle aligned row" v-show="this.$route.name == 'whatsApp-message-details'">
                <div class="one wide column" @click="$router.go(-1)">
                    <i class="fa fa-chevron-left"></i>
                </div>
                <div class="column" v-if="selectedInboxMessage">
                    <small>Messages with</small>
                    <h3>{{selectedInboxMessage.msisdn}}</h3>
                </div>
            </div>
        </div>
        <div v-show="inboxView === 'columns' && shortcode !=''" class="l-app-inbox-content">
            <div class="list">
                <div class="list-title">
                    {{dataListCount}} messages
                </div>
                <div class="list-item" v-for="(dataList,index) in dataLists" v-bind:key="dataList.profile_id+index" @click="getMessageDetails(dataList)" :class="{active: selectedKey == dataList.profile_id} ">
                    <div class="status"><i class="fa fa-comment-alt status-default "></i></div>
                    <div class="meta">
                        <h4>{{dataList.name}}</h4>
                        <h5 class="margin-top-sm text-grey">{{dataList.msisdn}}</h5>
                        <small class="text-lightgrey">{{dataList.updated_at}}</small>
                    </div>
                </div>
    
                <div class="ui fluid bottom aligned button" v-show="!loading" @click="getContactList()">Load More</div>
                <loader v-show="loading"></loader>
            </div>
    
            <router-view></router-view>
        </div>
        <div v-show="inboxView === 'list'" class="l-app-inbox-content">
            <div class="ui relaxed padded grid">
                <div class="row">
                    <div class="column">
                        <div class="ui top attached secondary segment">
                            <div class="ui grid">
                                <div class="row">
                                    <div class="three wide column">
                                        Mobile
                                    </div>
                                    <div class="six wide column">
                                        Message
                                    </div>
                                    <div class="seven wide column right">
                                        Response
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div v-show="!loading" class="ui fluid bottom aligned button" @click="getDetailsMore()">Load More</div>
                        <loader v-show="loading"></loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    form {
        display: inline-block;
    }
</style>

<script>
    import format from 'date-fns/format';
    import loader from "@/components/loader";
    import MessageDetails from "./message-details";
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    import moment from "moment";
    export default {
        data() {
            return {
                inboxView: 'columns',
                messageDetails: false,
                dataLists: [],
                detailsList: [],
                dataListCount: 0,
                countN: 1,
                list_id: "",
                list_name: "",
                status: "",
                limit: 10,
                selectedKey: null,
                offset: 0,
                selectedList: '',
                order: "",
                showUploadSection: false,
                uploadFiles: null,
                processingUploadFile: false,
                //infinite scroll
                busy: false,
                totalContactLists: 0,
                loading: false,
                inboxzero: false,
                msisdn: "",
                dateOne: '',
                dateTwo: '',
                dateFormat: 'D MMM',
                dateFormatTwo: "YYYY-MM-DD",
                totalDetailsLists:0,
                offset2:0,
                limit2:10,
                selectedInboxMessage: null,
                senderIDs:[],
                shortcode:""
            }
        },
        components: {
            loader,
            MessageDetails,
        },
        mounted() {
            $(".ui.dropdown").dropdown();
            let vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            vm.inboxViewTog(vm.inboxView);
            var d = new Date();
            vm.dateOne = format(d.setDate(d.getDate() - 7), this.dateFormatTwo);
            vm.dateTwo = format(Date(), this.dateFormatTwo);
            vm.fetchData();
            //console.log(vm.dateTwo);
            
        },
        methods: {
            fetchData() {
                //console.log("TEST");
                // this.dataLists = [];
                var vmBalance = this;
    
                var vm = this;
    
                $.post({
                    url: bulkstore.state.bulksmsUrl + 'senderId/list',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        status: 13,
                        p_status: 4,
                        type_id: 5
                    }),
                    success: function(response, status, jQxhr) {
                        vm.senderIDs = response.data.data;
                    },
                    error: function(jQxhr, status, error) {
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.data.message);
                        // console.log(" status bulk " + status + " data " + JSON.stringify(jQxhr.responseJSON.statusDescription));
                    }
                });
    
            },
            getMessageDetails(dataList) {
                if (dataList) {
                    this.selectedKey = dataList.inbox_id;
                    this.selectedInboxMessage = dataList;
                    store.commit("setInboxDetails", dataList);
                    store.commit("setWhatsAppNumber", this.shortcode);
                    this.$root.$emit('refreshList');
                    this.messageDetails = true;
                    this.$router.push({name: 'whatsApp-message-details', params: {id: dataList.profile_id}});
                    //this.$ref.details.getDetails(dataList.profile_id);
                }
    
            },
            inboxViewTog(view) {
                let vm = this;
                if (view == "list") {
                    vm.inboxView = "list";
                    vm.getContactList();
                } else {
                    vm.inboxView = "columns";
                    vm.getContactList();
                }
            },
            searchContact() {
                let vm = this;
                vm.totalContactLists = 0;
                vm.dataListCount = 0;
                vm.offset = 0;
                vm.dataLists = [];
                console.log("am here");
                vm.getContactList();
            },
    
            getContactList() {
                let vm = this;
                if (vm.totalContactLists === 0 || vm.totalContactLists >= ((vm.offset - 1) * vm.limit)) {
    
                    vm.loading = true;
                    vm.offset++;
                    $.post({
                        url: store.state.rootUrl + 'whatsApp/view/mobile',
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: this.$cookies.get("key"),
                            limit: vm.limit,
                            offset: vm.offset,
                            mobile: vm.msisdn,
                            start: vm.dateOne,
                            stop: vm.dateTwo,
                            short_code:"254709183222"
    
                        }),
                        success: function(response, status, jQxhr) {
                            vm.loading = false;
                            if (response.data.data != "") {
                                vm.inboxzero = false;
                                //vm.dataLists = response.data.data;
                                vm.dataListCount = response.data.count;
                                vm.totalContactLists = (response.data.data).length;
                                var totalContacts = 0;
                                var list_c = response.data.data;
                                if (response.data.code != 404) {
                                    for (var i = 0; i < list_c.length; i++) {
                                        list_c[i].updated_at = format(list_c[i].updated_at, "DD MMM YYYY HH:mm");
                                        //console.log(list_c[i].date_recieved );
                                        vm.dataLists.push(list_c[i]);
                                        if (i == 0) {
                                            vm.selectedKey = list_c[0].profile_id;
                                            //vm.selectList(list_c[i]);
                                        }
                                    }
    
                                } else {
                                    alertify.error(response.data.message);
                                }
                            } else if (vm.offset > 1) {
                                vm.inboxzero = false;
                            } else {
                                vm.inboxzero = true;
                            }
                        },
                        error: function(jQxhr, status, error) {
                            vm.loading = false;
                            vm.dataLists = [];
                            store.commit("setInboxDetails", []);
                            alertify.error(jQxhr.responseJSON.statusDescription);
                        }
                    });
                }
            },

            exportContact(){
                let vm = this;
                var exportRecipients = [];
                $.post({
                        url: store.state.rootUrl + 'whatsApp/view/conversation',
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: this.$cookies.get("key"),
                            export: 1,
                            start: vm.dateOne,
                            stop: vm.dateTwo,
    
                        }),
                        success: function(response, status, jQxhr) {
                            alertify.success(response.data.message);
                            for (var i = 0; i < response.data.data.length; i++) {
                            var item = {};
                            item.short_code = response.data.data[i].short_code;
                            item.msisdn = response.data.data[i].msisdn;
                            item.message = response.data.data[i].message;
                            item.created_at = response.data.data[i].created_at;
                            exportRecipients.push(item);
                        }
    
    
                        var exportDate = moment().format('DD_MMMM_YYYY_h:mm');
    
                        vm.JSONToCSVConvertor(exportRecipients, "WhatsApp_Report: _" + exportDate, 1);
                        $('#button-export').html('<i class="icon download"></i> Download');

                        },
                        error: function(jQxhr, status, error) {
                            vm.loading = false;
                            vm.dataLists = [];
                            store.commit("setInboxDetails", []);
                            alertify.error(jQxhr.responseJSON.statusDescription);
                        }
                    });
            },
            JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
                //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
                var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    
                var CSV = '';
                //Set Report title in first row or line
    
                //CSV += ReportTitle + '\r\n\n';
    
                //This condition will generate the Label/Header
                if (ShowLabel) {
                    var row = "";
    
                    //This loop will extract the label from 1st index of on array
                    for (var index in arrData[0]) {
    
                        //Now convert each value to string and comma-seprated
                        row += index + ',';
                    }
    
                    row = row.slice(0, -1);
    
                    //append Label row with line break
                    CSV += row + '\r\n\n\n';
                }
    
                //1st loop is to extract each row
                for (var i = 0; i < arrData.length; i++) {
                    var row = "";
    
                    //2nd loop will extract each column and convert it in string comma-seprated
                    for (var index in arrData[i]) {
                        row += '"' + arrData[i][index] + '",';
                    }
    
                    row.slice(0, row.length - 1);
    
                    //add a line break after each row
                    CSV += row + '\r\n';
                }
    
                if (CSV == '') {
                    alert("Invalid data");
                    return;
                }
    
                //Generate a file name
                var fileName = "VasPro_";
                //this will remove the blank-spaces from the title and replace it with an underscore
                fileName += ReportTitle.replace(/ /g, "_");
    
                //Initialize file format you want csv or xls
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    
                // Now the little tricky part.
                // you can use either>> window.open(uri);
                // but this will not work in some browsers
                // or you will not get the correct file extension
    
                //this trick will generate a temp <a /> tag
                var link = document.createElement("a");
                link.href = uri;
    
                //set the visibility hidden so it will not effect on your web-layout
                link.style = "visibility:hidden";
                link.download = fileName + ".csv";
    
                //this part will append the anchor tag and remove it after automatic click
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },

            addComments(list){
                let vm = this;
                //console.log("am here");
                store.commit("setInboxDetails",list);
                $('.ui.modal#modal-inbox-comments').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            sendMessageInbox(clientDetails) {
                let vm = this;
                store.commit("setInboxDetails", clientDetails);
                $('.ui.modal#modal-inbox-new-message').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            formatDates(dateOne, dateTwo) {
                let formattedDates = ''
                if (dateOne) {
                    formattedDates = format(dateOne, this.dateFormat)
                }
                if (dateTwo) {
                    formattedDates += ' - ' + format(dateTwo, this.dateFormat)
                }
                return formattedDates
            },
            
        }
    }
</script>