<template>
    <div class="ui tiny modal" id="modal-contact-leads-clients">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">New client leads {{originalName}}</h2>
                <h4>You can create client leads</h4>
            </div>
            <div class="ui negative message" v-show="leadsStatus">{{leadsResponseMessage}}</div>
            <div class="ui centered container grid">
                <div class="column">
                    <div class="ui form">
                        <div class="required field" id="input-phone-number">
                            <label>Phone Number</label>
                            <div class="ui input">
                                <input type="text" class="" placeholder="723456780" required v-model="mobile" />
                            </div>
                        </div>
                        <div class="required field" id="input-company_name">
                            <label>Company Name</label>
                            <div class="ui input">
                                <input type="text" placeholder="Vaspro" required v-model="companyName" />
                            </div>
                        </div>
                        <div class="required field" id="input-email">
                            <label>Email</label>
                            <div class="ui input">
                                <input type="email" placeholder="john@core.com" required v-model="email" />
                            </div>
                        </div>
                        <div class="field">
                            <div class="ui toggle checkbox">
                                <input type="checkbox" name="schedule" v-model="isClient">
                                <label v-show="!isClient"><strong>Create Client</strong></label>
                                <label v-show="isClient"><strong>Create Lead</strong></label>
                                <p class="padding-top-sm"><small>Select either lead of client</small></p>
                            </div>
                        </div>
                        <p v-show="isClient">Addition Client Details</p>
                        <p v-show="!isClient">Addition Leads Details</p>
                        <div class="field" v-show="!isClient">
                            <label>Purpose</label>
                            <div class="ui input">
                                <select class="ui fluid dropdown" v-model="interest">
                                    <option value="ALL" selected>All</option>
                                    <option v-for="s_id in servicesList" :key="s_id.service_id" :value="s_id.service_name">{{s_id.service_name}}</option>
                                    
                                </select>
                            </div>
                        </div>
                        <div class="required field" v-show="isClient">
                            <label>Full Name</label>
                            <div class="ui input">
                                <input type="text" placeholder="John Doe" v-model="name" />
                            </div>
                        </div>
                         <div class="field" v-show="!isClient">
                            <label>Status</label>
                            <div class="ui input">
                                <select class="ui fluid dropdown" v-model="status">
                                    <option value="1">Follow up</option>
                                    <option value="2">Uninterested</option>
                                    <option value="3">Requests Meeting</option>
                                    <option value="4">Converted</option>
                                </select>
                            </div>
                        </div>
                        <div class="two fields" v-show="!isClient">
                        <div class="field" v-show="status==3">
                            <label>Date</label>
                            <div class="ui calendar" id="scheduleDate">
                                <div class="ui input left icon">
                                    <i class="calendar icon"></i>
                                    <input type="date" placeholder="Select date" v-model="startDate">
                                </div>
                            </div>
                        </div>
                        <div class="field" v-show="status==3">
                            <label>Time</label>
                            <div class="ui calendar" id="scheduleTime">
                                <div class="ui input left icon">
                                    <i class="time icon"></i>
                                    <input type="time" v-model="startTime" />
                                </div>
                            </div>
                        </div>
                    </div>
                        
                        <div>
                            

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui button" @click="hideModal()">Cancel</button>
            <button  @click="addLead()" class="ui bg-primary button">Create Lead</button>
        </div>
    </div>
</template>
<style lang="scss" scoped>
textarea{
    width: 100%;
    border: none;
    outline: none;
    font-size: 1.4em;
    &::placeholder{
        color: #ccc;
    }
}
.row{
    padding-top: 0 !important;
}
</style>

<script>
    import { mapGetters } from 'vuex'
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
export default {
    data() {
        return {
            //selectedList: "",
            leadsStatus: false,
            leadsResponseMessage: "",
            mobile:"",
            companyName:"",
            email:"",
            servicesList:null,
            status:1,
            startDate:null,
            startTime:null,
            interest:"ALL",
            isClient:false,
            name:"",


        }
    },
    mounted() {
        const vm = this;
        vm.fetchService();
        
    },
    computed:{
           originalName() {
               var vm =this;
               if(store.state.selectMoveClientDetails != ""){
                   vm.mobile = store.state.selectMoveClientDetails.client_msisdn;
                   vm.email = store.state.selectMoveClientDetails.client_email;
                   vm.companyName = store.state.selectMoveClientDetails.client_name;
                   vm.isClient = true;
               }
               
            }
       },
   
    
    methods: {
        
        fetchService() {
            let vm = this;
            $.post({
                url: store.state.rootURLBulkAPI + "v2/users/service/view",
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key")
                }),
                success: function(response, status, jQxhr) {
                    vm.servicesList = response.data.data;

                },
                error: function(jQxhr, status, error) {
                    //  console.log("failed to create account");
                }
            });

        },
        addLead(){
            let vm = this;
            if (!vm.email) {
                $("#input-email").addClass("error");
                return;
            } else if (!vm.mobile || vm.mobile.length === 0) {
                $("#input-phone-number").addClass("error");
                return;
            } else if (vm.mobile.length < 9 || vm.mobile.length > 13) {
                $("#input-phone-number").addClass("error");
                return;
            }
            if(!vm.isClient){
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/dsa/leads/create",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        client_name: vm.companyName,
                        client_email: vm.email,
                        mobile_no: vm.mobile,
                        interest: vm.interest,
                        status:vm.status,
                        appointmentDate:vm.startDate,
                        appointmentTime: vm.startTime
                    }),
                    success: function(response, status, jQxhr) {
                        if(response.data.code != 200){
                            vm.leadsStatus = true;
                            vm.leadsResponseMessage = response.data.message;
                            alertify.error(response.data.message);
                        }
                        else{
                            alertify.success(response.data.message);
                            vm.hideModal();
                        }
                    },
                    error: function(jQxhr, status, error) {
                        vm.leadsStatus = true;
                        vm.leadsResponseMessage = jQxhr.responseJSON.data.message;
                        alertify.error(jQxhr.responseJSON.data.message);
                        //  console.log("failed to create account");
                    }
                });
            }
            if(vm.isClient){
                $.post({
                    url: store.state.rootURLUser + 'users/create',
                    type: "POST",
                    data: JSON.stringify({
                        mobile_no: vm.mobile,
                        email_address: vm.email,
                        full_names: vm.name,
                        referrer: window.atob(vm.$cookies.get("username")),
                        client_name: vm.companyName ? vm.companyName : "",
                    }),
                    success: function(response, status, jQxhr) {
                        if (response.data.code == 202) {
                            vm.leadsStatus = true;
                            vm.leadsResponseMessage = response.data.message;
                            alertify.error(response.data.message);
                        } else {
                            alertify.success(response.data.message);
                            vm.hideModal();
                        }
                        vm.$parent.fetchDSALeads();
                        vm.$root.$emit('refreshList');
                    },
                    error: function(jQxhr, status, error) {
                        var statustext = jQxhr.responseJSON.statusDescription;
                        vm.leadsStatus = true;
                        if (!statustext) {
                            vm.leadsResponseMessage = "An error occurred. Try again later.";
                        } else {
                            vm.leadsResponseMessage= statustext;
                        }
                    }
                });
            }
                
        },
      
        hideModal() {
            $('.ui.modal#modal-contact-leads-clients').modal('hide');

        }
    }
}
</script>
