<template>
    <div class="ui tiny modal" id="modal-contact-leads-clients-delete">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md"/>
                <h2 class="padding-bottom-sm">Delete Lead</h2>
                <h4>Are you sure you want to delete lead <strong>{{originalName}}</strong>?</h4>
            </div>
        </div>
        <div class="actions">
            <button class="ui button" @click="hideModal()">Cancel</button>
            <button class="ui red button" @click="deleteLeads()">Delete Leads</button>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
export default {
    data() {
        return {
            //selectedList: "",
            leadsStatus: false,
            leadsResponseMessage: "",
            mobile:"",
            companyName:"",
            email:"",
            servicesList:null,
            status:null,
            startDate:null,
            startTime:null,
            interest:"",
            isClient:false,
            name:"",
            lead_id:""


        }
    },
    mounted() {
        
    },
    computed:{
           originalName() {
               var vm =this;
               if(store.state.selectMoveClientDetails != ""){
                   vm.lead_id = store.state.selectMoveClientDetails.lead_id;
                   vm.mobile = store.state.selectMoveClientDetails.client_msisdn;
                   vm.email = store.state.selectMoveClientDetails.client_email;
                   vm.companyName = store.state.selectMoveClientDetails.client_name;
                   vm.interest = store.state.selectMoveClientDetails.interest;
                   
               }
               
            }
       },
   
    
    methods: {
       
        deleteLeads(){
            let vm = this;
            if (!vm.email) {
                $("#input-email").addClass("error");
                return;
            } else if (!vm.mobile || vm.mobile.length === 0) {
                $("#input-phone-number").addClass("error");
                return;
            } else if (vm.mobile.length < 9 || vm.mobile.length > 13) {
                $("#input-phone-number").addClass("error");
                return;
            }
            $.post({
                url: store.state.rootURLBulkAPI + "v3/dsa/leads/edit",
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key"),
                    leads_id: vm.lead_id,
                    status: -5,
                }),
                success: function(response, status, jQxhr) {
                    if(response.data.code != 200){
                        vm.leadsStatus = true;
                        vm.leadsResponseMessage = response.data.message;
                        alertify.error(response.data.message);
                    }
                    else{
                        alertify.success(response.data.message);
                        vm.hideModal();
                    }
                    vm.$parent.fetchDSALeads();
                    vm.$root.$emit('refreshList');
                },
                error: function(jQxhr, status, error) {
                    vm.leadsStatus = true;
                    vm.leadsResponseMessage = jQxhr.responseJSON.data.message;
                    alertify.error(jQxhr.responseJSON.data.message);
                    //  console.log("failed to create account");
                }
            });
                
        },
      
        hideModal() {
            $('.ui.modal#modal-contact-leads-clients-delete').modal('hide');

        }
    }
}
</script>
