<template>
    <div class="l-app-main bg-white">
        <div class="l-app-main-inboxzero bg-primary-lighter" v-show="inboxzero">
            <h2>No Sender ID...yet</h2>
            <p>Apply for a Sender ID<br />to create blacklist</p>
            <div class="padding-sm"></div>
            <button class="ui large blue button" @click="showApplySenderID()">Apply for Sender ID</button>
            <div class="padding-xl"></div>
        </div>
        <div class="l-app-dashboard bg-white" v-show="!inboxzero">
            <div class="ui equal width relaxed grid padded bg-primary-lighter">
                <div class="middle aligned row">
                    
                    
                    <div class="column">
                        <div class="ui search link small input">
                            <input type="text" placeholder="Search Phone" v-on:keyup="getContactList()" v-model="msisdn" />
                        </div>
                    </div>
                    <div class="right aligned column">
                        <select class="ui fluid dropdown" v-model="senderId" v-on:change="getContactList()">
                            <option v-show="dataListsSenderID.length==0" value="">Sender Id</option>
                            <option v-for="s_id in dataListsSenderID" v-show="dataListsSenderID.length > 0 && s_id.status == 13" :key="s_id.short_code">{{s_id.short_code}}</option>
                        
                        </select>
                    </div>
                    <div class="column">
                        <button class="ui bg-primary button" @click="showAddBlacklist()">Add to Blacklist</button>
                    </div>
                    <div class="column">
                    <button class="ui bg-primary button" @click="showAddBlacklistUpload()">Upload Blacklist</button>
                    </div>
                </div>
                
            </div>
            <div class="ui relaxed padded grid"  v-show="inboxBalklist">
                <div class="row">
                    <div class="ui negative message">
                    No Blacklist Record found for sender {{senderId}} <span v-if="msisdn">Phone: {{msisdn}}</span>
                </div>
                </div>
            </div>
            <div class="ui relaxed padded grid" v-show="!inboxBalklist">
                <div class="row">
                    <div class="column">
                        <table class="ui styled celled unstackable table">
                            <thead>
                                <tr> 
                                    <th class="center aligned">#</th>
                                    <th>Sender Id</th>
                                    <th>Phone Number</th>
                                    <th>status</th>
                                    <th>Created By</th>
                                    <th>Created On</th>
                                    <th class="center aligned text-grey"><i class="fa fa-ellipsis-h"></i></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(dataList, index) in dataLists" :key="dataList.id">
                                    <td class="collapsing">{{index+1}}</td>
                                    <td class="">{{dataList.sender_id}}</td>
                                    <td class="">{{dataList.msisdn}}</td>
                                    <td class="collapsing " v-if="dataList.status==1"><span class="ui green label">Active</span></td>
                                    <td class="collapsing" v-if="dataList.status!=1"><span class="ui red label">Inactive</span></td>
                                    <td class="">{{dataList.createdBy}}</td>
                                    <td class="">{{dataList.created_at}}</td>
                                    <td class="center aligned collapsing" >
                                        <button class="ui icon tiny button" v-if="dataList.status==1" @click="editContactList(dataList.id,-5)"> Deactivated</button>
                                        <button class="ui icon primary tiny button" v-if="dataList.status!=1" @click="editContactList(dataList.id,1)"> Activated</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-show="!loading" @click="getContactList" class="ui icon large labeled button"><i class="icon refresh"></i> Load More</div>
                        <loader v-show="loading"></loader>
                    </div>
                </div>
            </div>
        </div>
        <ApplySenderID />
        <blacklistAdd />
        <BlacklistUpload />
    </div>
</template>

<script>
import BlacklistUpload from "@/modals/blacklist-upload";
import ApplySenderID from "@/modals/apply-senderid";
import blacklistAdd from "@/modals/blacklist-add";

import loader from "@/components/loader";
import store from "@/store";

 import alertify from 'alertifyjs';
export default {
     data() {
        return {
            inboxzero: false,
            inboxBalklist: false,
            dataLists: [],
            dataListsSenderID:[],
            dataListCount: 0,
            loading: false,
            senderId:"",
            errorDisplay:"",
            defaultSender: "",
            msisdn:"",
            totalTransactions:0,
            limit: 15,
            offset: 0,
        }
    },
     components: {
        loader,
        ApplySenderID,
        blacklistAdd,
        BlacklistUpload
    },
    computed: {
    },
    mounted() {
       let vm = this;
       if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
       vm.fetchDataSender();
       
       if(store.state.senderIDSelect){
           vm.senderId = store.state.senderIDSelect.short_code;
           vm.getContactList();
        }
    },
    methods: {
        fetchDataSender() {
            var vm = this;
            $.post({
                url: store.state.rootURLBulkAPI + 'v3/senderId/list',
                type: "POST",
                data: JSON.stringify({
                    apiKey: this.$cookies.get("key"),
                    status: 13,
                    p_status: 4
                }),
                success: function(response, status, jQxhr) {
                    if (response.data.code == 404) {
                        vm.inboxzero = true;
                    }
                    else{
                        vm.dataListsSenderID = response.data.data;
                    }
                },
                error: function(jQxhr, status, error) {
                }
            });

        },
        
        getContactList: function() {
            var vm = this;
            vm.inboxBalklist= false;
            
           
            if (vm.totalTransactions == 0 || vm.totalTransactions >= ((vm.offset - 1) * vm.limit)) {
                    vm.loading = true;
                    vm.offset = vm.offset + 1;
                    $.post({
                        url: store.state.rootUrl + 'contact/blacklist/view',
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: vm.$cookies.get("key"),
                            list_id: vm.list_id,
                            senderId: vm.senderId,
                            limit: vm.limit,
                            offset:vm.offset
    
                        }),
                        success: function(data, status, jQxhr) {
                            vm.loading = false;
                            if (data.data.code != 404) {
                                vm.totalTransactions = data.data.record_count;
                                var list_c = data.data.data;
                                
                                for (var i = 0; i < list_c.length; i++) {
                                    
                                    vm.dataLists.push(list_c[i]);
                                }
                            } else {
                                vm.inboxBalklist = true;
                                vm.offset =0;
                                vm.errorDisplay = response.statusDescription+" of Blacklist for Sender Id: "+vm.senderId;
                            }
                            setTimeout(function() {
                                $(".ui.dropdown").dropdown();
                            }, 100);
                        },
                        error: function(jQxhr, status, error) {
    
                            vm.loading = false;
                            vm.dataLists = [];
                        }
                    });
    
                }
            
        },
        editContactList(id,status) {
            let vm = this;
            vm.inboxBalklist= false;
            $.post({
                url: store.state.rootUrl + 'contact/blacklist/edit',
                type: "POST",
                data: JSON.stringify({
                    apiKey: this.$cookies.get("key"),
                    id: id,
                    status: status,
                }),
                success: function(response, status, jQxhr) {
                    vm.loading = false;
                    vm.inboxBalklist= true;
                    vm.getContactList();
                    if (response.data.code == 404) {
                        vm.inboxBalklist = true;
                        vm.errorDisplay = response.statusDescription //store.state.senderIDSelect.short_code;
                    }
                    else{
                        
                        alertify.success(response.data.message);
                        
                    }
                       
                },
                error: function(jQxhr, status, error) {
                    
                    vm.loading = false;
                    vm.dataLists = [];
                }
            });
            
        },

        showApplySenderID() {
            $(".ui.modal#modal-apply-senderid").modal({
                centered: false,
                closable: true
            }).modal("show");
        },
        showAddBlacklist() {
            $(".ui.modal#modal-blacklist-add").modal({
                centered: false,
                closable: true
            }).modal("show");
        },
        showAddBlacklistUpload() {
            console.log("Test mess")
            $(".ui.modal#modal-blacklist-upload").modal({
                centered: false,
                closable: true
            }).modal("show");
        }

    }
}
</script>
