<template>
    <div class="ui tiny modal" id="modal-whatsApp-keyword">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">Apply WhatsApp Keyword</h2>
                <h4>You can apply Keyword not more that 11<br />Characters</h4>
            </div>
            <div class="ui negative message" v-show="errorStatus">{{ErrorDesc}}</div>
            <div class="ui centered container grid">
                <div class="column">
                    <div class="ui form">
                        
                        <div class="field">
                            <label>* Title</label>
                            <div class="ui input">
                                <input type="text" v-model="title"/>
                            </div>
                        </div>
                        <div class="field">
                            <label>* Keyword</label>
                            <div class="ui input">
                                <input type="text" v-model="keyword" maxlength="11" required/>
                            </div>
                        </div>
                        <div class="field">
                            <div class="ui toggle checkbox">
                                <input type="checkbox" name="schedule" v-model="devMode">
                                <label><strong> Enable Developer Mode</strong></label>
                            </div>
                        </div>
                        <div class="field" v-show="devMode">
                            <label>Call  Back Url</label>
                            <div class="ui large input">
                               <input type="text" placeholder=""  required v-model="callbackUrl" />
                            </div>
                        </div>
                        
                        <div class="field" v-show="devMode">
                            <label>Authorization</label>
                            <div class="ui large input">
                               <input type="text" placeholder=""  required v-model="authorization" />
                            </div>
                        </div>
                        <small>*** Kindly noted you will be charge Ksh 3000 for Keyword ***</small>
                        <div class="padding-sm"></div>
                        <div class="field">
                            <div class="ui checkbox">
                                <input type="checkbox" v-model="agreeTerms"/>
                                <label>I agree to the Terms and Conditions of this service</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui large button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary large button" :disabled="!agreeTerms" @click="createWhatsAppKeyword()">Save</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    export default {
        data() {
            return {
                keyword: "",
                newContact: true,
                agreeTerms: false,
                title: "",
                msisdn:"",
                callbackUrl: "",
                companyDetails: '',
                authorization:"",
                imageURL:null,
                errorStatus:false,
                ErrorDesc:"",
                caption:"",
                devMode:false
            }
        },
        mounted() {
            let vm = this;

        },
        methods: {
            createWhatsAppKeyword(){
                
                let vm = this;
                var keywords = ["STOP", "END","CLOSE"];
                var key = vm.keyword.toUpperCase();
                console.log("am here"+key);
                if(vm.keyword == ""){
                    vm.errorStatus = true;
                    vm.ErrorDesc = "Keyword is required. Kindly type 11 Character Max";
                    alertify.set("notifier", "position", "top-right");
                    alertify.error(vm.ErrorDesc);
                }
                else if(keywords.includes(key)){
                    vm.errorStatus = true;
                    vm.ErrorDesc = vm.keyword + " is a reserved keyword. Kindly try a different keyword";
                    alertify.set("notifier", "position", "top-right");
                    alertify.error(vm.ErrorDesc);
                }
                else if(vm.keyword.length > 11){
                    vm.errorStatus = true;
                    vm.ErrorDesc = vm.keyword + " keyword, has exceeded the maximum characters. Max characters is 11";
                    alertify.set("notifier", "position", "top-right");
                    alertify.error(vm.ErrorDesc);
                }
                else{
                    $.post({
                        url: store.state.rootURLBulkAPI + 'v3/whatsApp/keyword/create',
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: vm.$cookies.get("key"),
                            keyword: vm.keyword,
                            title: vm.title,
                            msisdn: vm.msisdn,
                            callbackUrl: vm.callbackUrl,
                            authorization: vm.authorization
                        }),
                        success: function(response, status, jQxhr) {
                        alertify.set("notifier", "position", "top-right");
                        alertify.success(response.data.message);
                        vm.hideModal();
                        },
                        error: function(jQxhr, status, error) {
                            vm.errorStatus = true;
                            vm.ErrorDesc = jQxhr.responseJSON.statusDescription;
                            alertify.set("notifier", "position", "top-right");
                            alertify.error(jQxhr.responseJSON.statusDescription);
                        }
                    });
                }

            },
            
            hideModal() {
                $('.ui.modal#modal-whatsApp-keyword').modal('hide');
            }
        }
    }
</script>
