<template>
<div class="l-app-main">

    <div class="l-app-main bg-white">
        <div class="l-app-main-inboxzero bg-primary-lighter" v-show="inboxzero">
            <h2>No Transaction Yet</h2>
            <p>Make Payment to view Transaction</p>
            <div class="padding-sm"></div>
            <router-link  :to="{name: 'utilities-pay'}" class="ui large blue button" >Create a Payment List</router-link>
            <div class="padding-xl"></div>
        </div>
        <div class="l-app-dashboard bg-white" v-show="!inboxzero">
            <div class="ui equal width relaxed grid padded bg-primary-lighter">
                <div class="middle aligned row">
                    <div class="column">
                        <div class="ui search link small input margin-right-md">
                            <input type="text" placeholder="Search Account Number" v-on:keyup="getTransactionList(false)" v-model="accountNo" />
                        </div>
                        <select class="ui small dropdown input margin-right-md" v-model="status" @change="getTransactionList(false)">
                            <option value="" selected>Select Status</option>
                            <option value="200">Success</option>
                            <option value="202">Sent and Acknowledge</option>
                            <option value="201">Undefine Response</option>
                            <option value="3">Failed</option>
                            <option value="421">Failed retries after 5 mins</option>
                            <option value="402">Insufficent Balance</option>
                        </select>
                        <select v-model="utilityID" class="ui small dropdown" @change="getTransactionList(false)">
                            <option selected>Select Utility</option>
                            <option v-for="s_id in utilities" :key="s_id.id" :value="s_id.id">{{s_id.utility_name}}</option>
                        </select>
                        <div class="ui small input datepicker-trigger margin-left-xs">
                            <input type="text" id="datepicker-trigger" placeholder="Select date" :value="formatDates(dateOne, dateTwo)" >
                            <AirbnbStyleDatepicker :trigger-element-id="'datepicker-trigger'" :mode="'range'" :fullscreen-mobile="true" :months-to-show="2" :date-one="dateOne" :date-two="dateTwo" :close-after-select="false" @date-one-selected="val => { dateOne = val }" @date-two-selected="val => { dateTwo = val }"
                               @apply="getTransactionList(false)" />
                        </div>
                        <router-link :to="{name: 'utilities-pay'}" class="ui bg-primary button margin-left-md">Create Payment List</router-link>
                    </div>
                    
                </div>
            </div>
            <div class="ui relaxed padded grid">
                <div class="row">
                    <div class="column">
                        <table class="ui styled celled unstackable table">
                            <thead>
                                <tr> 
                                    <th class="center aligned">#</th>
                                    <th>Account Number</th>
                                    <th>Amount</th>
                                    <th>Utitity</th>
                                    <th>Status</th>
                                    <th>Reciept Number</th>
                                    <th>Created On</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(dataList, index) in dataLists" :key="dataList.id">
                                    <td class="collapsing">{{index+1}}</td>
                                    <td>{{dataList.account_number}}</td>
                                    <td>{{dataList.amount}}</td>
                                    <td>
                                        <span class="ui label" :class="{
                                            green: dataList.utility_id==4,
                                            orange: dataList.utility_id==5, 
                                            teal:dataList.utility_id==6, 
                                            grey:dataList.utility_id==7}">
                                            {{dataList.utility_name}}
                                        </span>
                                    </td>
                                    <td>{{dataList.status}}</td>
                                    <td>{{dataList.reciept_number}}</td>
                                    <td>{{dataList.created_at}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-show="!loading" @click="getTransactionList" class="ui icon large labeled button"><i class="icon refresh"></i> Load More</div>
                        <div @click="generateExport()" class="ui bg-accent icon large labeled button" id="button-export" :disabled="uploadStatus"><i class="icon download"></i> Download</div>
                        <loader v-show="loading"></loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import loader from "@/components/loader";
import store from "@/store";
import alertify from 'alertifyjs';
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
import format from 'date-fns/format';
 import moment from "moment";
export default {
    data(){
        return {
            accountNo:"",
            dataLists:[],
            totalList:0,
            offset: 0,
            limit: 10,
            loading: false,
            inboxzero:false,
            resp:[],
            utilityID:"",
            status:null,
            utilities:[],
            dateOne: '',
            dateTwo: '',
            dateFormat: 'D MMM',
            uploadStatus:false
        }
    },
    components: {
        loader,
    },
    mounted(){
        this.getTransactionList(true);
        this.getAllUtilities();
    },
    methods: {
        getAllUtilities(){
            let vm = this;
            vm.loading = true;
            $.post({
                url: store.state.rootURLBulkAPI + "v3/rewards/view",
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key"),

                }),
                success: function(response, status, jQxhr) {
                    vm.loading = false;
                    vm.utilities = response.data.data
                },
                error: function(jQxhr, status, error) {
                    vm.loading = false;
                    vm.inboxzero = true;
                } 
            });
        },
        getTransactionList(states){
            let vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            if(!states){
                vm.totalList = 0;
                vm.offset = 0;
                vm.dataLists = [];
            }
            
             if (vm.totalList == 0 || vm.totalList >= ((vm.offset - 1) * vm.limit)) {
                vm.offset++;
                vm.loading = true;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/rewards/view/transaction",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        offset: vm.offset,
                        limit: vm.limit,
                        account_number: vm.accountNo,
                        status:vm.status,
                        utility_id: vm.utilityID,
                        start: vm.dateOne,
                        stop: vm.dateTwo,
                    }),
                    success: function(response, status, jQxhr) {
                        vm.loading = false;
                        vm.resp = response.data.data;
                        if (response.data.code != 200) {
                            vm.offset = 0;
                            alertify.set('notifier', 'position', 'top-right');
                            alertify.error(response.data.message);
                        } else {
                            for (var i = 0; i < vm.resp.length; i++) {
                                if(vm.resp[i].DLRStatus == 201){
                                    vm.resp[i].status = 'Undefine Response';
                                }
                                if(vm.resp[i].DLRStatus == 200){
                                    vm.resp[i].status = 'Success';
                                }
                                if(vm.resp[i].DLRStatus == 202){
                                    vm.resp[i].status = 'Sent and Acknowledge';
                                }
                                if(vm.resp[i].DLRStatus == 402){
                                    vm.resp[i].status = 'Insufficent Balance';
                                }
                                if(vm.resp[i].DLRStatus == 421){
                                    vm.resp[i].status = 'Failed retries after 5 mins';
                                }
                                if(vm.resp[i].DLRStatus == 3){
                                    vm.resp[i].status = 'Failed';
                                }
                                vm.dataLists.push(vm.resp[i]);
                            }
                            vm.totalList = response.data.record_count;
                        }
                    },
                    error: function(jQxhr, status, error) {
                        vm.loading = false;
                        vm.offset = 0;
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            }
        },
         generateExport() {
                let vm = this;
                vm.uploadStatus =  true;
                $('#button-export').html('<i class="fas fa-circle-notch fa-spin"></i> Download Please Wait ...');
                var exportRecipients = [];
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/rewards/view/transaction",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        offset: vm.offset,
                        limit: vm.limit,
                        account_number: vm.accountNo,
                        status:vm.status,
                        utility_id: vm.utilityID,
                        start: vm.dateOne,
                        stop: vm.dateTwo,
                        export: 1
                    }),
                    success: function(response, status, jQxhr) {
                        $('.ui.accordion').accordion('refresh');
                        for (var i = 0; i < response.data.data.length; i++) {
                            var item = {};
                            item.account_number = response.data.data[i].account_number;
                            item.amount = response.data.data[i].amount;
                            item.reciept_number = response.data.data[i].reciept_number;
                            if(response.data.data[i].DLRStatus == 201){
                                item.status = 'Undefine Response';
                            }
                            if(response.data.data[i].DLRStatus == 200){
                                item.status = 'Success';
                            }
                            if(response.data.data[i].DLRStatus == 202){
                                item.status = 'Sent and Acknowledge';
                            }
                            if(response.data.data[i].DLRStatus == 402){
                                item.status = 'Insufficent Balance';
                            }
                            if(response.data.data[i].DLRStatus == 421){
                                item.status = 'Failed retries after 5 mins';
                            }
                            if(response.data.data[i].DLRStatus == 3){
                                item.status = 'Failed';
                            }
                            item.utility_name = response.data.data[i].utility_name;
                            item.created_at = response.data.data[i].created_at;
                            exportRecipients.push(item);
                        }
    
                        if(response.data.data.length < 1000){
                            var exportDate = moment().format('DD_MMMM_YYYY_h:mm');
    
                        vm.JSONToCSVConvertor(exportRecipients, "Utilities:_" + exportDate, 1);
                        }
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.success("Your file has been sent to your email. Kindly check your email");
                        $('#button-export').html('<i class="icon download"></i> Download');
    
                    },
                    error: function(jQxhr, status, error) {
                        vm.loading = true;
                        //console.log(jQxhr)
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                        $('#button-export').html('<i class="icon download"></i> Download');
                        //console.log("failed");
                    }
                });
            },
            JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
                //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
                var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    
                var CSV = '';
                //Set Report title in first row or line
    
                //CSV += ReportTitle + '\r\n\n';
    
                //This condition will generate the Label/Header
                if (ShowLabel) {
                    var row = "";
    
                    //This loop will extract the label from 1st index of on array
                    for (var index in arrData[0]) {
    
                        //Now convert each value to string and comma-seprated
                        row += index + ',';
                    }
    
                    row = row.slice(0, -1);
    
                    //append Label row with line break
                    CSV += row + '\r\n\n\n';
                }
    
                //1st loop is to extract each row
                for (var i = 0; i < arrData.length; i++) {
                    var row = "";
    
                    //2nd loop will extract each column and convert it in string comma-seprated
                    for (var index in arrData[i]) {
                        row += '"' + arrData[i][index] + '",';
                    }
    
                    row.slice(0, row.length - 1);
    
                    //add a line break after each row
                    CSV += row + '\r\n';
                }
    
                if (CSV == '') {
                    alert("Invalid data");
                    return;
                }
    
                //Generate a file name
                var fileName = "VasPro_";
                //this will remove the blank-spaces from the title and replace it with an underscore
                fileName += ReportTitle.replace(/ /g, "_");
    
                //Initialize file format you want csv or xls
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    
                // Now the little tricky part.
                // you can use either>> window.open(uri);
                // but this will not work in some browsers
                // or you will not get the correct file extension
    
                //this trick will generate a temp <a /> tag
                var link = document.createElement("a");
                link.href = uri;
    
                //set the visibility hidden so it will not effect on your web-layout
                link.style = "visibility:hidden";
                link.download = fileName + ".csv";
    
                //this part will append the anchor tag and remove it after automatic click
                document.body.appendChild(link);
                link.click();
                link.remove();
            },
        formatDates(dateOne, dateTwo) {
            let formattedDates = ''
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat)
            }
            if (dateTwo) {
                formattedDates += ' - ' + format(dateTwo, this.dateFormat)
            }
            return formattedDates
        },
    }
}
</script>