<template>
  <div class="l-app-main bg-primary-bg">
    <div class="l-app-dashboard bg-primary-lighter">
      <div class="ui padded relaxed grid">
        <div class="equal width middle aligned row">
          <div class="column">
            <h3>Summary</h3>
            <h4>{{ from | formatDate }} - {{ to | formatDate }}</h4>
          </div>
          <div class="right aligned column">
            <div class="ui input">
              <input type="date" placeholder="Enter date" v-model="from" />
            </div>
          </div>
        </div>
        <div class="equal width row" :class="{ loading: dataLoading }">
          <div class="column">
            <div class="ui red segment">
              <h4>SMS Consumed</h4>
              <h1>{{ totalSMSConsumed }}</h1>
            </div>
          </div>
          <div class="column">
            <div class="ui blue segment">
              <h4>Total Cost</h4>
              <h1>KES {{ totalCost | formatNumber }}</h1>
            </div>
          </div>
          <div class="column">
            <div class="ui green segment">
              <h4>Clients</h4>
              <h1>{{ totalClients }}</h1>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <div class="bg-grey-light"><canvas id="consumption-sms"></canvas></div>
          </div>
        </div>
        <div class="equal width middle aligned row">
          <div class="column">
            <h3>
              <span class="text-medium">Clients</span><br /><small
                >{{ totalClients }} clients</small
              >
            </h3>
          </div>
          <div class="column right aligned">
            <button class="ui bg-primary button" @click="createClient()">
              Add Client
            </button>
            <button class="ui bg-secondary button" @click="updateAccount()">
              Edit System
            </button>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <loader v-show="isLoading"></loader>
            <table class="ui striped celled table" v-show="!isLoading">
              <thead>
                <tr>
                  <th>Client</th>
                  <th>Last active</th>
                  <th class="center aligned">Sender IDs</th>
                  <th class="center aligned">Paybills/Tills</th>
                  <th class="center aligned">SMS Rate</th>
                  <th class="right aligned">Bonus</th>
                  <th class="right aligned">Balance</th>
                  <th class="center aligned"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(list, index) in resellerClients" :key="index + 1">
                  <td @click="selectClients(list)">
                    <h4>{{ list.client_name }}</h4>
                    <small class="text-primary" v-show="list.status == 1">Active</small>
                    <small class="text-warning" v-show="list.status == 2">Pending</small>
                  </td>
                  <td>{{ list.activated_on }}</td>
                  <td class="center aligned">{{ list.totalSenderID }}</td>
                  <td class="center aligned">{{ list.totalPaybill }}</td>
                  <td class="center aligned">{{ list.unit_cost }}</td>
                  <td class="center aligned">{{ list.bonus | formatNumber }}</td>
                  <td class="right aligned">
                    <strong>KES {{ list.balance | formatNumber }}</strong>
                  </td>
                  <td class="collapsing">
                    <div class="ui small icon dropdown button">
                      <i class="icon dropdown"></i>
                      <div class="right menu">
                        <a href="#" class="blue item" @click="transferFunds(list)"
                          >Transfer funds</a
                        >
                        <a href="#" class="item" @click="addPaybill(list)"
                          >Add Paybill/Till</a
                        >
                        <a href="#" class="item" @click="applySenderIDAction(list)"
                          >Add Sender ID</a
                        >
                        <div class="divider"></div>
                        <a class="item" @click="selectClients(list)">Manage client</a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <CreateClient />
    <TransferFunds />
    <AggregatePaybill />
    <ApplySenderID />
    <resellerUpdate />
  </div>
</template>

<script>
import CreateClient from "./create-client";
import TransferFunds from "./transfer-funds";
import AggregatePaybill from "./aggregate-paybill";
import ApplySenderID from "./apply-senderid";
import loader from "@/components/loader";
import store from "@/store";
import alertify from "alertifyjs";
import format from "date-fns/format";
import moment from "moment";
import numeral from "numeral";
import resellerUpdate from "@/modals/update-reseller";

export default {
  components: {
    CreateClient,
    TransferFunds,
    loader,
    AggregatePaybill,
    ApplySenderID,
    resellerUpdate,
  },
  data() {
    return {
      // credits: store.state.credits,
      totalCost: 0,
      totalClients: 0,
      dataLoading: false,
      isLoading: false,
      resellerClients: [],
      totalClients: 0,
      totalSMSConsumed: 0,
      from: "",
      to: "",
      dateFormatTwo: "YYYY-MM-DD",
    };
  },
  mounted() {
    var d = new Date();
    this.to = format(d, this.dateFormatTwo);
    this.from = format(d.setDate(d.getDate() - 7), this.dateFormatTwo);
    this.getSummary();
    this.fetchAllClients();
    this.getGraph();
  },
  methods: {
    updateAccount() {
      $("#modal-update-reseller")
        .modal({
          centered: true,
          closable: true,
        })
        .modal("show");
    },
    createClient() {
      $("#create-client")
        .modal({
          centered: true,
          closable: true,
        })
        .modal("show");
    },
    transferFunds(list) {
      store.commit("setResellerAccounts", list);
      $("#transfer-funds")
        .modal({
          centered: true,
          closable: true,
        })
        .modal("show");
    },
    addPaybill(list) {
      store.commit("setResellerAccounts", list);
      $("#modal-agg-paybill-till-client")
        .modal({
          centered: true,
          closable: true,
        })
        .modal("show");
    },
    applySenderIDAction(list) {
      store.commit("setResellerAccounts", list);
      $("#modal-apply-senderid")
        .modal({
          centered: true,
          closable: true,
        })
        .modal("show");
    },
    selectClients(list) {
      let vm = this;
      store.commit("setResellerAccounts", list);
      vm.$router.push({
        name: "reseller-client",
        params: { id: list.client_id },
      });
    },
    getSummary() {
      let vm = this;
      vm.dataLoading = true;
      $.post({
        url: store.state.rootUrl + "reseller/summary",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
        }),
        success: function (response, status, jQxhr) {
          vm.dataLoading = false;
          vm.totalClients = response.data.data[0].clients;
          vm.totalCost = response.data.data[0].totalAmount;
          vm.totalSMSConsumed = response.data.data[0].totalSMS;
        },
        error: function (jQxhr, status, error) {
          vm.dataLoading = false;
          alertify.error(jQxhr.responseJSON.statusDescription);
        },
      });
    },
    fetchAllClients() {
      let vm = this;
      vm.isLoading = true;
      $.post({
        url: store.state.rootUrl + "reseller/accounts/view",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
        }),
        success: function (response, status, jQxhr) {
          vm.isLoading = false;
          vm.resellerClients = response.data.data;
          setTimeout(function () {
            $(".ui.dropdown").dropdown();
          }, 100);
        },
        error: function (jQxhr, status, error) {
          vm.isLoading = false;
          alertify.error(jQxhr.responseJSON.statusDescription);
        },
      });
    },
    getGraph() {
      console.log("Test solution ");
      let vm = this;
      $.post({
        url: store.state.rootUrl + "reseller/transaction/summary",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
          start: vm.from,
        }),
        success: function (response, status, jQxhr) {
          vm.graphData = response.data.data;

          let yData = [];
          let xDate = [];
          let result = response.data.data;

          result.forEach(function (data) {
            yData.push(parseInt(data["COUNT"]));
            xDate.push(data["hourD"]);
          });

          let ctx = $("#consumption-sms");
          let myChart = new Chart(ctx, {
            type: "line",
            data: {
              labels: xDate,
              datasets: [
                {
                  data: yData,
                  backgroundColor: ["#F7FAFA"],
                  borderColor: ["#26A842"],
                  borderWidth: 1,
                },
              ],
            },
            options: {
              maintainAspectRatio: false,
              responsive: true,
              layout: {
                padding: {
                  right: 0,
                },
              },
              legend: {
                display: false,
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
              elements: {
                line: {
                  tension: 0, // disables bezier curves
                },
              },
            },
          });
        },
        error: function (jQxhr, status, error) {
          vm.loading = false;
        },
      });
    },
  },
  filters: {
    formatDate: function (value) {
      if (!value) return "";
      return moment(String(value)).format("D MMM");
    },
    formatNumber(value) {
      return numeral(value).format("0,0.00");
    },
  },
};
</script>
