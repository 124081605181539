<template>
    <div class="ui tiny modal" id="modal-verify-account-agent">
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="50" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">Verify Account</h2>
                <h4>Kindly enter your password to proceed</h4>
                <div class="ui negative message" v-show="userStatus">{{userStatusDesc}}</div>
            </div>
            <div class="ui centered container grid">
                <div class="column">
                    <div class="ui form">
                        <div class="text-center field padding-top-md padding-bottom-xl">
                            <div class="field" id="input-password">
                                <div class="ui large input">
                                    <input type="password" placeholder="Enter Password" required v-model="password" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui button" @click="cancelModal()">Back to Homepage</button>
             <button class="ui button bg-primary" @click="verifyAccount()">Verify Account</button>
        </div>
    </div>
</template>

<script>
 import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    export default {
        data: function() {
            return {
                password: "",
                userStatus: false,
                userStatusDesc:""
            }
        },
    
        methods: {
            verifyAccount() {
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + 'v3/account/view/token',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        password: btoa(vm.password)
                    }),
                    success: function(response, status, jQxhr) {
                       alertify.set("notifier", "position", "top-right");
                       alertify.success(response.data.message);
                       vm.$parent.fetchDSAClient();
                       vm.$parent.fetchDSALeads();
                       vm.hideModal();
                    },
                    error: function(jQxhr, status, error) {
                        vm.userStatus = true;
                        vm.userStatusDesc = jQxhr.responseJSON.statusDescription;
                        alertify.set("notifier", "position", "top-right");
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            },
            hideModal() {
                $('.ui.modal#modal-verify-account-agent').modal('hide');
            },
            cancelModal(){
               let vm = this;
               $('.ui.modal#modal-verify-account-agent').modal('hide'); 
               vm.$router.push({name: 'bulk-dashboard'});
            }
        }
    }
</script>
