<template>
    <div class="l-app-main-section">
        <div class="ui padded relaxed equal width grid">
            <div class="middle aligned row">
                <div class="column">
                    <h3>All Users</h3>
                    <small>{{totalUser}} users</small>
                </div>
                <div class="right aligned column">
                    <button class="ui bg-primary button" @click="addUser">Add User</button>
                </div>
            </div>
            <div class="computer only tablet only row">
                <div class="column">
                    <table class="ui celled styled unstackable table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Contact Details</th>
                                <th>Status</th>
                                <th >Last Login Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tr v-for="(transc, index) in userLists" :key="index">
                            <td class="collapsing center aligned">{{index + 1}}</td>
                            <td>
                                <h4>{{transc.full_names}}</h4>
                            </td>
                            
                            <td>
                                <div>{{transc.msisdn}}</div>
                                <small>{{transc.email_address}}</small>
                            </td>
                            <td>
                                <h4 class="ui tiny button green" v-show="transc.status == 1">Active </h4>
                                <h4 class="ui tiny button red" v-show="transc.status != 1">Disabled </h4>
                            </td>
                            <td>{{transc.last_successful_date | formatDate}}</td>
                            <td class="right aligned collapsing">
                                <button class="ui tiny basic yellow button" v-show="transc.role == 1" @click="accountMakerChecker(2,transc.user_mapId)"><i class="icon check"></i>Enable Checker</button>
                                 <button class="ui tiny basic green button" v-show="transc.role == 2" @click="accountMakerChecker(1,transc.user_mapId)"><i class="icon check"></i>Enable Maker</button>
                                <button class="ui tiny basic red button" v-show="transc.status == 1" @click="accountStatus(2,transc.profile_id,transc.user_mapId)"><i class="icon trash"></i>Deactivate Account</button>
                                 <button class="ui tiny basic green button" v-show="transc.status == 2" @click="accountStatus(1,transc.profile_id,transc.user_mapId)"><i class="icon check"></i>Activate Account</button>
                                <button class="ui tiny basic button" @click="permissionChange(transc.msisdn, transc.permission_acl, transc.user_mapId)"><i class="icon edit"></i>Manage Permission</button>
                            </td>
                        </tr>
                    </table>
                    <loader v-show="loading"></loader>
                     <div v-show="!loading" @click="fetchUsers"  class="ui icon large labeled button"><i class="icon refresh"></i> Load More</div>
                </div>
                
            </div>
            <div class="mobile only row bg-white border-top border-bottom border-light">
                <div class="column">
                    <table class="ui very basic unstackable table">
                        <tbody>
                        <tr v-for="(transc, index) in userLists" :key="index">
                            <td>
                                <div class="border-right border-light padding-right-xs">
                                    <h4>{{transc.full_names}}</h4>
                                    <small class="text-grey"><span class="padding-right-md">{{transc.msisdn}}</span> <span class="padding-right-sm">{{transc.email_address}}</span></small>
                                </div>
                            </td>
                            <td class="right aligned">
                                <button class="ui tiny button" @click="permissionChange(transc.msisdn, transc.permission_acl, transc.user_mapId)">Manage</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div v-show="!loading" @click="fetchUsers"  class="ui icon large labeled button"><i class="icon refresh"></i> Load More</div>
                </div>
                <loader v-show="loading"></loader>
            </div>
        </div>
        <UserAdd />
        <ClientPermission />
        <shareCredits />
    </div>
</template>

<script>
    import loader from "@/components/loader";
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from "alertifyjs";
    import UserAdd from "@/modals/user-add";
    import ClientPermission from "@/modals/client-permission";
    import shareCredits from "@/modals/share-credits";
    import moment from "moment";
    export default {
        data() {
            return {
                userLists: [],
                limit: 10,
                offset: 0,
                loading: true,
                totalUser:0
            }
        },
        components: {
            UserAdd,
            loader,
            ClientPermission,
            shareCredits
        },
        mounted() {
            let vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            $(".ui.dropdown").dropdown();
            vm.fetchUsers();
        },
        methods: {
            addUser() {
                $(".ui.modal#modal-user-add").modal({
                    centered: false
                }).modal('show');
            },
            share(username,user_mapId) {
                store.commit("setClientUsername",username);
                store.commit("setClientUser_mapId",user_mapId);
                $(".ui.modal#modal-share-credits").modal({
                    centered: false
                }).modal('show');
            },
            permissionChange(username,permissionDT,user_mapId) {
                //console.log("usermame "+permissionDT);
                store.commit("setClientUsername",username);
                store.commit("setPermission",permissionDT);
                store.commit("setClientUser_mapId",user_mapId);
                $(".ui.modal#modal-client-permission").modal({
                    centered: false
                }).modal('show');
            },
            fetchUsers() {
                let vm = this;
                if (vm.totalUser == 0 || vm.totalUser >= ((vm.offset - 1) * vm.limit)) {
                    vm.offset++;
                    vm.loading = true;
                    $.post({
                        url: store.state.rootURLBulkAPI + 'v2/users/view',
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: vm.$cookies.get("key"),
                            limit: vm.limit,
                            offset: vm.offset
                        }),
                        success: function(response, status, jQxhr) {
                            vm.loading = false;
                            vm.totalUser = response.data.record_count;
                            var resp = response.data.data;
                            if (response.data.code != 200) {
                                vm.offset = 0;
                            } else {
                                for (var i = 0; i < resp.length; i++) {
                                    vm.userLists.push(resp[i]);
                                }
                            }
                        },
                        error: function(jQxhr, status, error) {
                            vm.loading = false;
                            vm.offset = 0;
                        }
                    });
                }
                
            },
            accountMakerChecker(status,user_mapId){
            let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + "v2/users/permission/edit",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        userStatus: status,
                        user_mapId: user_mapId,
                        isMakerChecker: 1
                    }),
                    success: function(response, status, jQxhr) {
                        if (response.data.code != 200) {
                            alertify.set("notifier", "position", "top-right");
                            alertify.error(response.data.message);
                        } else {
                            vm.totalUser = 0
                            vm.offset = 0
                            vm.userLists = [];
                            vm.fetchUsers();
                            alertify.set("notifier", "position", "top-right");
                            alertify.success(response.data.message);
                        }
                    },
                    error: function(jQxhr, status, error) {
                       // console.log("failed "+JSON.stringify(jQxhr));
                        alertify.set("notifier", "position", "top-right");
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            },
            accountStatus(status,profile_id,user_mapId){
                
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + "v2/users/permission/edit",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        profileID: profile_id,
                        userStatus: status,
                        user_mapId: user_mapId
                    }),
                    success: function(response, status, jQxhr) {
                        if (response.data.code != 200) {
                            alertify.set("notifier", "position", "top-right");
                            alertify.error(response.data.message);
                        } else {
                            vm.totalUser = 0
                            vm.offset = 0
                            vm.userLists = [];
                            vm.fetchUsers();
                            alertify.set("notifier", "position", "top-right");
                            alertify.success(response.data.message);
                        }
                    },
                    error: function(jQxhr, status, error) {
                       // console.log("failed "+JSON.stringify(jQxhr));
                        alertify.set("notifier", "position", "top-right");
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            },
            
        },
        filters: {
            formatDate: function(value) {
                if (!value) return ''
                return moment(value).fromNow()
            },
        }
    }
</script>