<template>
    <div class="l-onboard">
        <div class="l-onboard-center">
            <div class="ui centered grid">
                <div class="row">
                    <div class="sixteen wide computer only column">
                        <div class="padding-xl"></div>
                    </div>
                    <div class="sixteen wide center aligned column padding-bottom-md">
                         <router-link class="padding-bottom-md" to="/"><img src="../assets/images/logo.png" height="40" /></router-link>
                    </div>
                    <div class="twelve wide computer fourteen wide tablet sixteen wide mobile column">
                        <h1 class="padding-bottom-lg text-medium text-center">Forgot Password</h1>
                        <div class="ui negative message" v-show="resetStatus">
                            {{resetStatusMessage}}
                        </div>
                        <form class="ui form" v-on:submit.prevent="forgotPassword">
                            <div class="field">
                                <label>Enter your phone number</label>
                                <div class="ui large input">
                                    <input type="text" placeholder="07xxxxxxxx" required v-model="username" />
                                </div>
                            </div>
                            <div class="field">
                                <button id="button-reset" type="submit" class="ui large green fluid button">Next</button>
                            </div>
                            <div class="padding-md"></div>
                            <div class="field">
                                <router-link :to="{name: 'login'}">Login</router-link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import store from "../store";
    import pageFooter from "../components/page-footer";
    import pageHeader from "../components/page-header";
    export default {
        components: {
            pageFooter,
            pageHeader
        },
        data: function() {
            return {
                username: '',
                resetStatus: false,
                resetStatusMessage: ''
            }
        },
        methods: {
            forgotPassword() {
                this.$cookies.remove("key");
                this.resetStatus = false;
                this.resetStatusMessage = "";
                if (!this.username) {
                    $("#input-username").addClass("error");
                    return;
                }
                $('#button-reset').html('<i class="fas fa-circle-notch fa-spin"></i>');
                var vm = this;
                $.post({
                    url: store.state.rootURLUser + 'login/reset',
                    type: "POST",
                    data: JSON.stringify({
                        user_name: vm.username,
                        check:1
                    }),
                    success: function(data, status, jQxhr) {
                        vm.$cookies.set("iscalled",true,"60s");
                        vm.$cookies.set("username",window.btoa(vm.username));
                        vm.$router.push({name: 'verify-account'});
                    },
                    error: function(jQxhr, status, error) {
                        vm.resetStatus = true;
                        vm.resetStatusMessage = jQxhr.responseJSON.statusDescription;
                        $('#button-reset').text('Reset');
                    }
                });
            },
        },
    
    }
</script>
