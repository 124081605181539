<template>
    <div class="l-app-dashboard inter">
        <div class="ui relaxed padded grid">
            <div class="equal width row">
                <div class="column">
                    <h3>Summary</h3>
                    <p class="text-grey">01 December 2020 - 31 December 2020</p>
                </div>
                <div class="right aligned column">

                </div>
            </div>
            <div class="row">
                <div class="six wide column">
                    <div class="m-card">
                        <div class="m-card-icon bg-primary"></div>
                        <div class="m-card-text">
                            <h4 class="margin-bottom-xs text-medium-1">Bulk SMS Sent</h4>
                            <h2 class="">2,912,910</h2>
                        </div>
                        <div class="m-card-link"><i class="icon chevron right"></i></div>
                    </div>
                    <div class="m-card">
                        <div class="m-card-icon bg-secondary"></div>
                        <div class="m-card-text">
                            <h4 class="margin-bottom-xs text-medium-1">Subscription SMS</h4>
                            <h2 class="">16,910</h2>
                        </div>
                        <div class="m-card-link"><i class="icon chevron right"></i></div>
                    </div>
                    <div class="m-card">
                        <div class="m-card-icon bg-accent"></div>
                        <div class="m-card-text">
                            <h4 class="margin-bottom-xs text-grey text-medium-1">Bulk Payments</h4>
                            <h2 class="">Ksh 12,910.00</h2>
                        </div>
                        <div class="m-card-link"><i class="icon chevron right"></i></div>
                    </div>
                    <div class="m-card">
                        <div class="m-card-icon bg-grey-dark"></div>
                        <div class="m-card-text">
                            <h4 class="margin-bottom-xs text-grey text-medium-1">Utilities</h4>
                            <h2 class=""><span>Ksh</span> 12,910.82</h2>
                        </div>
                        <div class="m-card-link"><i class="icon chevron right"></i></div>
                    </div>
                </div>
                <div class="ten wide column full-height">
                    <div class="m-card h-full">
                        <div class="m-card-content">
                            <div class="title">
                                <h3>SMS sent</h3>
                                <div>
                                    <canvas id="chart-sms" height="130"></canvas>
                                </div>
                            </div>
                        </div>
                        <div class="m-card-bottom-ext">
                            <div class="ui grid">
                                <div class="six wide column border-right ">
                                    <h5>Delivery Rate</h5>
                                    <h3 class="text-medium">92.19%</h3>
                                </div>
                                <div class="ten wide column">
                                    <div class="ui equal width grid">
                                        <div class="column">
                                            <h5 class="margin-bottom-xs">Safaricom</h5>
                                            <h4 class="">120,010</h4>
                                        </div>
                                        <div class="column">
                                            <h5 class="margin-bottom-xs">Airtel</h5>
                                            <h4 class="">120,010</h4>
                                        </div>
                                        <div class="column">
                                            <h5 class="margin-bottom-xs">Telkom</h5>
                                            <h4 class="">120,010</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="four column row margin-top-md">
                <div class="column">
                    <div class="m-card small senderids">
                        <div class="content">
                            <h5 class="margin-bottom-md text-medium-1">Sender IDs</h5>
                            <h3>829</h3>
                            <small class="text-grey">Total</small>
                        </div>
                        <div class="ext">
                            <table class="list">
                                <tr>
                                    <td>Clients</td>
                                    <td class="text-right text-medium">123</td>
                                </tr>
                                <tr>
                                    <td>Deposits</td>
                                    <td class="text-right text-medium">Ksh 120,900</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="m-card small payments">
                        <div class="content">
                            <h5 class="margin-bottom-md text-medium-1">Bulk Payments</h5>
                            <h3>Ksh 123,001.00</h3>
                            <small class="text-grey">Total payouts</small>
                        </div>
                        <div class="ext">
                            <table class="list">
                                <tr>
                                    <td>Commissions</td>
                                    <td class="text-right text-medium">Ksh 10,000</td>
                                </tr>
                                <tr>
                                    <td>-</td>
                                    <td class="text-right text-medium">-</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="m-card small utilities">
                        <div class="content">
                            <h5 class="margin-bottom-md text-medium-1">Utilities</h5>
                            <h3>Ksh 123,001.00</h3>
                            <small class="text-grey">Total sales</small>
                        </div>
                        <div class="ext">
                            <table class="list">
                                <tr>
                                    <td>Airtime</td>
                                    <td class="text-right text-medium">Ksh 210,000</td>
                                </tr>
                                <tr>
                                    <td>Electricity</td>
                                    <td class="text-right text-medium">Ksh 12,990</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="m-card small surveys">
                        <div class="content">
                            <h5 class="margin-bottom-md text-medium-1">Surveys</h5>
                            <h3>0</h3>
                            <small class="text-grey">Total responses</small>
                        </div>
                        <div class="ext">
                            <table class="list">
                                <tr>
                                    <td>Today</td>
                                    <td class="text-right text-medium">23</td>
                                </tr>
                                <tr>
                                    <td>This month</td>
                                    <td class="text-right text-medium">120</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="m-card small whatsapp">
                        <div class="content">
                            <h5 class="margin-bottom-md text-medium-1">Whatsapp</h5>
                            <h3>123</h3>
                            <small class="text-grey">Messages delivered</small>
                        </div>
                        <div class="ext">
                            <table class="list">
                                <tr>
                                    <td>Clients</td>
                                    <td class="text-right text-medium">10</td>
                                </tr>
                                <tr>
                                    <td>-</td>
                                    <td class="text-right text-medium">-</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="m-card small ussd">
                        <div class="content">
                            <h5 class="margin-bottom-md text-medium-1">USSD</h5>
                            <h3>1,920,992</h3>
                            <small class="text-grey">Total Dials</small>
                        </div>
                        <div class="ext">
                            <table class="list">
                                <tr>
                                    <td>Clients</td>
                                    <td class="text-right text-medium">10</td>
                                </tr>
                                <tr>
                                    <td>-</td>
                                    <td class="text-right text-medium">-</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import url('https://rsms.me/inter/inter.css');
.inter {
    p, h1, h2, h3, h4, h5, h6, button, table, span, div, p{
        font-family: 'Inter', sans-serif;
    }

}

@supports (font-variation-settings: normal) {
    p, h1, h2, h3, h4, h5, h6, button, table, span, div, p { font-family: 'Inter var', sans-serif; }
}
.m{
    &-card{
        cursor: pointer;
        background: #fff;
        border-radius: 12px;
        margin-bottom: 1em;
        padding: 1.25em 1em;
        box-shadow: 0 3px 12px rgba(0,0,0,.1);
        display: table;
        width: 100%;
        overflow: hidden;
        position: relative;
        &:last-child{
            margin-bottom: 0;
        }
        &.h-full{
            height: 100%;
        }
        &-icon{
            //background: rgba(0,0,0,.05);
            border-radius: 6px;
            display: table-cell;
            width: 1%;
            padding: 1em;
            vertical-align: middle;
        }
        &-link{
            display: table-cell;
            width: 1%;
            opacity: .5;
            vertical-align: middle;
        }
        &-text{
            display: table-cell;
            width: 100%;
            padding: 0 1em;
            vertical-align: middle;
        }
        &-content{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 80%;
            & .title{
                padding: 1.5em;
            }
        }
        &-bottom-ext{
            background: rgba(37, 66, 91, .05);
            padding: 1em 1.5em;
            border-top: rgba(0,0,0,.1) solid 1px;
            position: absolute;
            box-sizing: border-box;
            left: 0;
            right: 0;
            bottom: 0;
            & .list{
                width: 100%;
                border: none;
                padding: .5em 0;
                opacity: .9;
                padding-bottom: 1em;
                td{
                    padding: 4px 0;
                }
            }
        }

        &.small{
            border: #ddd solid 1px;
            border-top: #aaa solid 6px;
            margin-bottom: 2em;
            padding: 0;
            display: block;
            & .content{
                padding: 1em;
            }
            & .ext{
                background: rgba(0,0,0,.05);
                padding: 0 1em;
                border-top: rgba(0,0,0,.1) solid 1px;
                & .list{
                    width: 100%;
                    border: none;
                    padding: .5em 0;
                    opacity: .9;
                    padding-bottom: 1em;
                    td{
                        padding: 4px 0;
                    }
                }
            }
            &.whatsapp{ border-top-color: #00e200; }
            &.ussd{ border-top-color: #444; }
            &.surveys{ border-top-color: teal; }
            &.senderids{ border-top-color: forestgreen; }
            &.payments{ border-top-color: cornflowerblue; }
            &.utilities{ border-top-color: mediumvioletred; }
        }
    }
}
</style>

<script>
import Chart from 'chart.js';
import $ from "jquery";
export default {
    mounted(){
        var chartSMS = $('#chart-sms');
        var myChart = new Chart(chartSMS, {
            type: 'bar',
            responsive: false,
            maintainAspectRatio: true,
            data: {
                labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
                datasets: [{
                    label: '# of Votes',
                    data: [12, 19, 3, 5, 2, 3],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
    }
}
</script>