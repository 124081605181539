<template>
    <div class="ui tiny modal" id="modal-edit-response-paybill">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">Edit Response Message for<br /> Paybill / Till <br /><strong>{{selectPaybill}}</strong></h2>
            </div>
            <div class="ui negative message" v-show="EditPaymentStatus">
                {{EditpaymentStatusDesc}}  
            </div>
            <div class="ui centered container grid">
                <div class="column">
                    <div class="ui form">
                        <div class="field">
                            <label>Type Response Message</label>
                            <div class="ui top attached segment" id="textarea-sms">
                                 <textarea class="l-type-message" placeholder="Type your response message here" v-model="responseMessage" ></textarea>
                            </div>
                           <div class="ui bottom attached segment bg-grey-light">
                                <div class="ui equal width grid">
                                    <div class="column">
                                        <small>{{messageCount}} Characters (Depends on Placeholders) 
                                        </small>
                                    </div>
                                    <div class="right aligned column">
                                        <small>{{messagePages}} SMS</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <label>Markup</label>
                            <div class="ui large input">
                               <input type="number" placeholder=""  required v-model="markup" @change="item.quantity = Math.max(Math.min(Math.round(item.quantity),1000), 0)"/>
                            </div>
                        </div>
                        <div class="field">
                            <label>Call  Back Url</label>
                            <div class="ui large input">
                               <input type="text" placeholder=""  required v-model="callbackUrl" />
                            </div>
                        </div>
                        <div class="two field">
                            <div class="field">
                            <div class="ui toggle checkbox">
                            <input type="checkbox" name="sendResponse" v-model="sendResponseMessage">
                            <label><strong>Send Response</strong></label>
                        </div>
                        </div>
                        <div class="field" v-show="survey_appId">
                            <div class="ui toggle checkbox">
                            <input type="checkbox" name="sendResponse" v-model="disabledSurvey">
                            <label><strong>Disabled Survey</strong></label>
                        </div>
                        </div>
                        </div>
                        
                        <div class="field">
                            <label>Sender ID</label>
                            <div class="ui large input">
                                <select class="ui fluid dropdown" v-model="senderId">
                                     <option v-if="dataLists.length == 0"  value="VasPro">VasPro</option>
                                    <option v-for="s_id in dataLists" v-show="dataLists.length > 0 && s_id.status == 13" :key="s_id.short_code">{{s_id.short_code}}</option>
                                   
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
             <button class="ui cancel button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary button" @click="updatePaybill()">Update</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    
    export default {
        data() {
            return {
                callbackUrl:"",
                responseMessage:[],
                paybill:"",
                dataLists: [],
                sendResponseMessage:true,
                sendResponse:0,
                id:"",
                EditPaymentStatus:false,
                EditpaymentStatusDesc:"",
                composeTotalCharacters:0,
                senderId:"",
                markup:0.00,
                survey_appId:"",
                disabledSurvey:false
            }
        },
        mounted() {
            let vm = this;
            vm.fetchData();
        },
        computed: {
            selectPaybill() {
                var vm = this;
                var responseBillDetails = store.state.paybillSelect;
                if(responseBillDetails !=""){
                    vm.paybill = responseBillDetails.paybill;
                    vm.responseMessage = responseBillDetails.message;
                    vm.callbackUrl = responseBillDetails.pushUrl;
                    vm.id = responseBillDetails.id;
                    vm.senderId = responseBillDetails.short_code;
                    vm.markup = responseBillDetails.markup_amount;
                    vm.survey_appId = responseBillDetails.survey_appId;
                    return vm.paybill;
                }
                else{
                    return "";
                }
            },
            
             messageCount() {
                var responseBillDetails = store.state.paybillSelect;
               if(responseBillDetails !=""){
                   //console.log(JSON.stringify(responseBillDetails));
                   this.composeTotalCharacters = this.responseMessage.length;
                   return this.responseMessage.length; 
               }
               else {
                   return 0;
               }
                
            },
            messagePages() {
                 var responseBillDetails = store.state.paybillSelect;
                 if(responseBillDetails !=""){
                
                    if (this.composeTotalCharacters == 0) {
                        return 0;
                    } else if (this.composeTotalCharacters / 160 <= 1) {
                        return 1;
                    } else if (this.composeTotalCharacters / 160 <= 2) {
                        return 2;
                    } else if (this.composeTotalCharacters / 160 <= 3) {
                        return 3;
                    } else if (this.composeTotalCharacters / 160 <= 4) {
                        return 4;
                    } else if (this.composeTotalCharacters / 160 <= 5) {
                        return 5;
                    } else {
                        return " Max ";
                    }
                 }
                 else {
                     return 0;
                 }
            },
        },
   
        methods: {
            
            hideModal() {
                this.$parent.showEdit = false;
                $('.ui.modal#modal-edit-response-paybill').modal('hide');
    
            },
            fetchData() {
                var vmBalance = this;
    
                var vm = this;
           
                $.post({
                    url: bulkstore.state.bulksmsUrl + 'senderId/list',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        status:13,
                        p_status:4,
                    }),
                    success: function(response, status, jQxhr) {
                        if(response.data.code != 404){
                            vm.dataLists = response.data.data;
                            vm.senderId = vm.dataLists[0].short_code;
                        }
                    },
                    error: function(jQxhr, status, error) {
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.data.message);
                    }
                });
    
            },
            updatePaybill(){
                let vm = this;
                if(vm.sendResponseMessage){
                    vm.sendResponse =1;
                }
                $.post({
                    url: store.state.rootUrl + 'payment/update/paybill',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        response_message:vm.responseMessage,
                        paybill_id: vm.id,
                        send_response:vm.sendResponse,
                        callback_url:vm.callbackUrl,
                        sender_id:vm.senderId,
                        markup:vm.markup,
                        disabledPaybill: vm.disabledSurvey
                    }),
                    success: function(response, status, jQxhr) {
                        if(response.data.code != 200){
                            vm.EditPaymentStatus= true;
                            vm.EditpaymentStatusDesc = response.data.message;
                            alertify.set('notifier', 'position', 'top-right');
                            alertify.error(response.data.message);
                        }
                        else{
                            alertify.set("notifier", "position", "top-right");
                            alertify.success(response.data.message);
                            vm.hideModal();
                        }
                    },
                    error: function(jQxhr, status, error) {
                        vm.EditPaymentStatus= true;
                        alertify.set('notifier', 'position', 'top-right');
                        if(jQxhr.responseJSON.data.code == 422){
                            vm.EditpaymentStatusDesc = jQxhr.responseJSON.data.message;
                            alertify.error(jQxhr.responseJSON.data.message);
                        }
                        else{
                            vm.EditpaymentStatusDesc = jQxhr.responseJSON.statusDescription;
                            alertify.error(jQxhr.responseJSON.statusDescription);
                        }
                        
                        
                        
                    }
                });
            }
        }
    }
</script>
