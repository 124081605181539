<template>
  <div class="ui tiny modal" id="modal-edit-account">
    <i class="close icon"></i>
    <div class="scrolling content">
      <div class="text-center margin-bottom-lg">
        <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
        <h2 class="padding-bottom-sm">Edit Profile</h2>
        <h4>You can edit your profile details below</h4>
      </div>
      <div class="ui container grid">
        <div class="column">
          <div class="ui relaxed padded grid">
            <div class="column">
              <div class="ui form">
                <div class="field">
                  <h4 class="padding-bottom-sm">
                    <strong>Company Name {{ originalName }}</strong>
                  </h4>
                  <div class="ui large input">
                    <input
                      type="text"
                      placeholder="Enter Company Name"
                      v-model="company_name"
                    />
                  </div>
                </div>
                <div class="padding-md"></div>
                <h4 class="padding-bottom-md"><strong>Contact Details</strong></h4>

                <div class="field">
                  <label>Contact Name</label>
                  <div class="ui input">
                    <input type="text" placeholder="Enter Company Name" v-model="name" />
                  </div>
                </div>
                <div class="two fields">
                  <div class="field">
                    <label>Email</label>
                    <div class="ui input">
                      <input
                        type="text"
                        disabled
                        placeholder="Enter Email"
                        v-model="email"
                      />
                    </div>
                  </div>
                  <div class="field">
                    <label>Phone</label>
                    <div class="ui input">
                      <input
                        type="text"
                        disabled
                        placeholder="Enter Phone"
                        v-model="mobile"
                      />
                    </div>
                  </div>
                </div>

                <div class="padding-md"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <button class="ui button bg-primary" id="edit-client" @click="updateContact">
        Save Changes
      </button>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import bulkstore from "@/bulk/bulksms-state";
import alertify from "alertifyjs";
export default {
  data() {
    return {
      mobile: null,
      name: null,
      email: null,
      company_name: null,
      accountDetails: null,
      client_id: null,
      showuser: true,
    };
  },
  computed: {
    originalName() {
      var vm = this;

      if (store.state.selectResellerClient != "") {
        console.log(JSON.stringify(store.state.selectResellerClient));
        vm.company_name = store.state.selectResellerClient.account_name;
        vm.name = store.state.selectResellerClient.client_name;

        vm.client_id = vm.$route.params.id;
        vm.mobile = store.state.selectResellerClient.mobile_number;
        vm.email = store.state.selectResellerClient.client_email;
        vm.showuser = false;
      } else {
        vm.company_name = store.state.accountDetails.client_name;
        vm.name = store.state.accountDetails.account_name;
        vm.email = store.state.accountDetails.email_address;
        vm.mobile = store.state.accountDetails.mobile_number;
      }
      //return vm.company_name;
      //   // vm.accountDetails = store.state.accountDetails;
      //console.log("Custom Fil" + JSON.stringify(store.state.accountDetails));
      //    //return  store.state.accountDetails.client_name;
    },
  },

  methods: {
    updateContact() {
      let vm = this;
      $.post({
        url: store.state.rootURLBulkAPI + "/v3/account/edit",
        type: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key"),
          client_id: vm.client_id,
          company_name: vm.company_name,
          account_name: vm.name,
          client_email: vm.email,
          email_address: vm.email,
        }),
        success: function (response, status, jQxhr) {
          if (response.data.code != 200) {
            alertify.set("notifier", "position", "top-right");
            alertify.error(response.data.message);
          } else {
            vm.hideModal();
            alertify.set("notifier", "position", "top-right");
            alertify.success(response.data.message);
          }
        },
        error: function (jQxhr, status, error) {
          vm.hideModal();
          alertify.set("notifier", "position", "top-right");
          alertify.error("Failed to Update");
          //console.log("failed to create account");
        },
      });

      vm.hideModal();
    },
    hideModal() {
      $(".ui.modal#modal-edit-account").modal("hide");
    },
  },
};
</script>
