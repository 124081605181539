import Vue from 'vue'
import Router from 'vue-router';
import Home from './views/Home.vue';
import Signup from './views/Signup.vue';
import Login from './views/Login.vue';
import LoginOkta from './views/LoginOkta.vue';
import VerifyAccount from './views/Verify.vue';
import ForgotPassword from './views/ForgotPassword.vue';
import SetPassword from './views/SetPassword.vue';
import PageNotFound from './views/PageNotFound.vue';
import AppIndex from "@/components/index";
import store from "@/store";

// Bulk
import BulkDashboard from "@/bulk/dashboard";
import BulkMessages from "@/bulk/messages";
import BulkMessagesDetails from "@/bulk/message-details";
import BulkSenderIDs from "@/bulk/sender-ids";
import BulkTemplates from "@/bulk/templates";
import BulkReports from "@/bulk/reports";
import BulkCreate from "@/components/create-message";
import BlastCreate from "@/components/create-blast-message";

// Inbox
import Inbox from "@/inbox/messages";
import InboxMessageDetails from "@/inbox/message-details";
import InboxKeywords from "@/inbox/keywords";
import InboxKeywordDetails from "@/inbox/keyword-details";
import Shortcodes from "@/inbox/shortcodes";

// Contacts
import Contacts from "@/contacts/list";
import ContactsBuilder from "@/contacts/builder";
import ContactsBlacklist from "@/contacts/blacklist";
// eslint-disable-next-line no-unused-vars
import ContactsListDetails from "@/contacts/list-details";

// Payments
import Payments from "@/payments/dashboard";
import PaymentsTransactions from "@/payments/transactions";
import PaymentsBulk from "@/payments/bulk-payments";
import PaymentsCreate from "@/payments/create-bulk-payment";
import PaymentsBulkDetails from "@/payments/bulk-payments-details";
import PaymentBulkTransaction from "@/payments/bulk_transaction";

// Vipay
import Vipay from "@/payments/vipay-dashboard";
import VipayTransaction from "@/payments/vipay-transaction";
import VipayCreate from "@/payments/vipay-create";

// Surveys
import SurveyCampaigns from "@/surveys/surveys";
import SurveyReports from "@/surveys/reports";
import CreateSurvey from "@/surveys/create";
import SurveyView from "@/surveys/survey-view";
import SurveyQuestionnaires from "@/surveys/questionnaires";
import CreateQuestionnaire  from "@/surveys/questionnaire-add";
import QuestionnaireIndex from "@/surveys/index"

// API
import API from "@/api/dashboard";

// Utilities
import Utilities from "@/utilities/index";
import UtilitiesDashboard from "@/utilities/dashboard";
import UtilitiesPay from "@/utilities/pay";
import UtilitiesTransactions from "@/utilities/transactions";
import UtilitiesPayView from "@/utilities/payview";

// Account
import AccountSummary from "@/account/summary";
import AccountUsers from "@/account/users";
import AccountTransactions from "@/account/transactions";
import AccountInvoices from "@/account/invoices";

// Agents
import AgentDashboard from "@/agent/dashboard";
import AgentViewTransaction from "@/agent/viewTransaction";

// Admin
import AdminIndex  from "@/admin/index";
import AdminDashboard from "@/admin/dashboard";

// Supcription
import SubscriptionDashboard  from "@/subscription/dashboard";
import SubscriptionMessage from "@/subscription/messages";
import SubscriptionMessageDetails  from "@/subscription/message-details";
import SubscriptionKeyword from "@/subscription/keywords";
import SubscriptionKeywordList  from "@/subscription/keywords-details";

// WhatsApp
import WhatsAppHome from "@/whatsApp/home";
import WhatsAppDashboard from "@/whatsApp/dashboard";
import WhatsAppMessage from "@/whatsApp/messages";
import WhatsAppMessageDetails from "@/whatsApp/message-details";
import WhatsAppBulk from "@/components/create-whatsApp";
import WhatsAppCreate from "@/components/create-whatsApp";

// Reseller
import Reseller from "@/reseller/index";
import ResellerTransactions from "@/reseller/transactions";
import ResellerClientView from "@/reseller/account/summary"

//LoginOkta
Vue.use(Router)

const router =  new Router({
    mode: "history",
    routes: [
        { path: '/', name: 'home', component: Login },
         { path: '/login/okta', name: 'oktaLogin', component: LoginOkta },
        { path: '/login/callback', name: 'oktaLogin', component: LoginOkta },
        { path: '/signup', name: 'signup', component: Signup },
        { path: '/signup/:referral', name: 'signup', component: Signup },
        { path: '/login', name: 'login', component: Login },
        { path: '/verify', name: 'verify-account', component: VerifyAccount },
        { path: '/forgot', name: 'forgot', component: ForgotPassword },
        { path: '/set-password', name: 'set-password', component: SetPassword },
        { path: "*", component: PageNotFound },

        {path: '/bulk', component: AppIndex, children: [
            { path: '', name: 'bulk-dashboard', component: BulkDashboard, meta: { name: "Bulk SMS"} },
            { path: 'messages', name: 'bulk-messages', component: BulkMessages, meta: { name: "Messages"} },
            { path: 'messages/:id', name: 'bulk-messages-details', component: BulkMessagesDetails, meta: { name: "Message"} },
            { path: 'senderids', name: 'bulk-senderids', component: BulkSenderIDs, meta: { name: "Sender IDs"} },
            { path: 'templates', name: 'bulk-templates', component: BulkTemplates, meta: { name: "Templates"} },
            { path: 'reports', name: 'bulk-reports', component: BulkReports, meta: { name: "Reports"} },
            { path: 'create', name: 'bulk-create', component: BulkCreate, meta: { name: "Send SMS"} },
            { path: 'blast/create', name: 'blast-create', component: BlastCreate, meta: { name: "Send Blast"} },
        ]},
        {path: '/inbox', component: AppIndex, children: [
            { path: '', name: 'inbox', component: Inbox, meta: { name: "Inbox"} },
            { path: 'keyword', name: 'inbox-keywords', component: InboxKeywords, meta: { name: "Inbox Keywords"} },
            { path: '', component: Inbox, children: [
                { path: ':id', name: 'inbox-message-details', component: InboxMessageDetails, meta: { name: "Inbox"} },
            ] },
            { path: 'keyword', component: InboxKeywords, children: [
                { path: ':id', name: 'inbox-keywords-details', component: InboxKeywordDetails, meta: { name: "Inbox Keywords"} },
            ] },
        ]},
        {path: '/whatsApp', component: AppIndex, children: [
            { path: '', name: 'whatsApp-home', component: WhatsAppHome, meta: { name: "WhatsApp"} },
            { path: 'keyword', name: 'whatsApp-dashboard', component: WhatsAppDashboard, meta: { name: "WhatsApp Keyword"} },
            { path: 'message', name: 'whatsApp-message', component: WhatsAppMessage, meta: { name: "WhatsApp Messages"} },
            { path: 'bulk', name: 'bulk-whatsApp', component: WhatsAppBulk, meta: { name: "Send Bulk WhatsApp"} },
            { path: '', component: WhatsAppMessage, children: [
                { path: ':id', name: 'whatsApp-message-details', component: WhatsAppMessageDetails, meta: { name: "WhatsApp Message"} },
            ] },
            { path: 'create', name: 'whatsapp-create', component: WhatsAppCreate, meta: { name: "Send Whatsapp"} },
        ]},
        { path: '/subscription',  component: AppIndex, children: [
            { path: '', name: 'subscription-dashboard', component: SubscriptionDashboard, meta: { name: "Subscription Dashboard"} },
            { path: '/message', name: 'subscription-message', component: SubscriptionMessage, meta: { name: "Subscription Message"} },
            { path: '', component: SubscriptionMessage, children: [
                { path: ':id', name: 'subscription-message-details', component: SubscriptionMessageDetails, meta: { name: "Subscription Message"} },
            ] },
            { path: '/keywords', name: 'subscription-keywords', component: SubscriptionKeyword, meta: { name: "Subscription Keyword"} },
            { path: '', component: SubscriptionKeyword, children: [
                { path: ':id', name: 'subscription-keywords-details', component: SubscriptionKeywordList, meta: { name: "Subscription Keyword"} },
            ] },
            
        ]},
        { path: '/shortcodes',  component: AppIndex, children: [
            { path: '', name: 'shortcodes', component: Shortcodes, meta: { name: "Shortcodes"} },
        ]},
        
        {path: '/contacts', component: AppIndex, children: [
            { path: '', name: 'contacts', component: Contacts, meta: { name: "Contacts"} },
            { path: 'builder', name: 'contacts-builder', component: ContactsBuilder, meta: { name: "Database Builder"} },
            { path: 'blacklist', name: 'contacts-blacklist', component: ContactsBlacklist, meta: { name: "Blacklist"} },
            { path: 'details/:id', name: 'ListDetails', component: ContactsListDetails, meta: { name: "Contact"} },
        ]},
        {path: '/payments', component: AppIndex, children: [
            { path: '', name: 'payments', component: Payments, meta: { name: "Payments"} },
            { path: 'transactions', name: 'payments-transactions', component: PaymentsTransactions, meta: { name: "Payments"} },
            { path: 'transactions/:id', name: 'payments-transactions-details', component: PaymentsTransactions, meta: { name: "Payment"} },
            { path: 'bulk', name: 'payments-bulk', component: PaymentsBulk, meta: { name: "Bulk Payments"} },
            { path: 'bulk/transaction', name: 'payments-bulk-transaction', component: PaymentBulkTransaction, meta: { name: "Bulk Payments Transaction"} },
            { path: 'create', name: 'create-payments-bulk', component: PaymentsCreate, meta: { name: "Create Bulk Payment"} },
            { path: 'details', name: 'bulk-payments-details', component: PaymentsBulkDetails, meta: { name: "Bulk Payment Details"} },

            // VIPAY
            { path: 'vipay', name: 'vipay', component: Vipay, meta: { name: "Vipay"} },
            { path: 'vipay/:id', name: 'vipay-transaction', component: VipayTransaction, meta: { name: "Transaction Details"} },
            { path: 'vipay/create', name: 'vipay-create', component: VipayCreate, meta: { name: "Create Transaction"} },
        ]},
        {path: '/surveys', component: AppIndex, children: [
            { path: '', name: 'surveys', component: SurveyCampaigns, meta: { name: "Surveys"} },
            { path: 'view/:id', name: 'survey-view', component: SurveyView, meta: { name: "Survey"} },
            { path: 'create/:id', name: 'survey-create-new', component: CreateSurvey, meta: { name: "Create Survey"} },
            { path: 'questionnaires', component: QuestionnaireIndex, children: [
                {path: "", name: 'surveys-questionnaires', component: SurveyQuestionnaires, meta: { name: "Questionnaires"}},
                {path: "add/:id", name: "questionnaire-add", component: CreateQuestionnaire, meta: { name: "Add Questionnaire" }},
            ] },
            { path: 'reports', name: 'surveys-reports', component: SurveyReports, meta: { name: "Survey Reports"} },
        ]},
        {path: '/pay-your-bills', component: AppIndex, children: [
            { path: '', name: 'utilities', component: Utilities, meta: { name: "Pay Your Bills"} },
            { path: 'dashboard', name: 'utilities-dashboard', component: UtilitiesDashboard, meta: { name: "Paybill Stats"} },
            
            { path: 'send', component: UtilitiesPay, children: [
                { path: 'airtime', name: 'utilities-send-airtime', component: UtilitiesPay, meta: { name: "Send Airtime"} },
                { path: 'kplc-postpaid', name: 'utilities-send-kplc-post', component: UtilitiesPay, meta: { name: "Pay KPLC Postpaid"} },
                { path: 'data', name: 'utilities-send-data', component: UtilitiesPay, meta: { name: "Send Data Bundles"} }, 
                { path: 'kplc-prepaid', name: 'utilities-send-kplc-pre', component: UtilitiesPay, meta: { name: "Pay KPLC Prepaid"} },
            ] },
            { path: 'view/:id', name: 'utilities-pay-list', component: UtilitiesPayView, meta: { name: "Pay Utilities"} },
            { path: 'transactions', name: 'utilities-transactions', component: UtilitiesTransactions, meta: { name: "Transactions"} },        ]},

        {path: '/api', component: AppIndex, children: [
            { path: '', name: 'api', component: API, meta: { name: "API"} },
        ]},
        {path: '/account', component: AppIndex, children: [
            { path: '', name: 'account-summary', component: AccountSummary, meta: { name: "Account"}},
            { path: 'users', name: 'account-users', component: AccountUsers, meta: { name: "Account"}},
            { path: 'transactions', name: 'account-transactions', component: AccountTransactions, meta: { name: "Account"}},
            { path: 'invoices', name: 'account-invoices', component: AccountInvoices, meta: { name: "Invoices"}},
        ]},

        {path: '/agent', component: AppIndex, children: [
            { path: '', name: 'agent-dashboard', component: AgentDashboard, meta: { name: "Agent"} },
            { path: 'view/:id', name: 'agent-transaction-view', component: AgentViewTransaction, meta: { name: "View Transaction"} },
        ]},
        {path: '/admin', component: AdminIndex, children: [
            { path: '', name: 'admin-dashboard', component: AdminDashboard, meta: { name: "Admin"} },
        ]},
        {path: '/reseller', component: AppIndex, children: [
            { path: '', name: 'reseller', component: Reseller, meta: { name: "Reseller Dashboard"} },
            { path: 'transactions', name: 'reseller-transactions', component: ResellerTransactions, meta: { name: "Transactions"} },
            { path: '/:id', name: 'reseller-client', component: ResellerClientView, meta: { name: "View Reseller Profile"} },
            
        ]},
    ]
});
router.beforeEach((to, from, next) => {
    //document.title = to.meta.title + ' | ' + to.meta.mainTitle;
    var isCalled = $cookies.get("iscalled");
    var token=$cookies.get("key");
    var username = $cookies.get("username");
    
    if(!isCalled || token){
        
        $cookies.set("iscalled",true,"60s");
        if(store.state.user===null){
            
            if($cookies.isKey("key")){
                
               //console.log("get user data using this token");
                var token = $cookies.get("key");
                //get user data using this token
                $.post({
                    url: store.state.rootURLUser + "users/profile",
                    type: "POST",
                    data: JSON.stringify({
                      apiKey: token
                    }),
                    success: function(response, status, jQxhr) {
                        store.commit("setuser",response.data);
                        next(true);
                    },
                    error: function(jQxhr, status, error) {
                        $cookies.remove("key");
                        next({path: "/login", replace: true});
                    }
                  });

            } else {
                

              //  console.log("user not null  "+from.path+" to path "+to.path);
                if(to.path.indexOf("bulk") !== -1 || to.path.includes("survey") || to.path.includes("agent")){
                    //console.log("user not null  "+from.path+" to path "+to.path);
                    next({path: "/", replace: false});
                }
                else if(to.path == "/subscription/" || to.path=="/inbox" || to.path=="/api/"
                    || to.path == "/subscription" || to.path=="/inbox/" || to.path=="/api"){
                    next({path: "/", replace: false});
                }
                else if((from.path=="/" && to.path=="/login") || to.path=="/login" ){
                    //console.log("user not null  "+from.path+" to path "+to.path);
                    next(true);
                }
                else if((from.path=="/" || from.path=="/login") && (to.path=="/register" || to.path=="/forgotpassword") ){
                    //console.log("user not null status >0 "+from.path+" to path "+to.path);
                    next(true);
                }
                else if(to.path=="/"){
                    //console.log("user not null status >0 "+from.path+" to path "+to.path);
                    next(true);
                }
                else{
                  //  console.log("else "+from.path+" to path "+to.path);
                    next(true);
                    //next({path: "/", replace: false});
                }

            }
        } else{
            
           // console.log("outside else where");
            if(store.state.user.status<=0){
               // console.log("user not null statu<=0 "+from.path+" to path "+to.path);

                next({path:"/verify",replace:true});
            }
            else if($cookies.isKey("key") == false){

                next({path: "/", replace: false});
                
            }
            else{
                if((from.path=="/" && to.path=="/login") || to.path=="/login" ){
                   // console.log("My token exist");
                    next({path:"/bulk",replace:true});
                    
                }
                else{
                    next(true);
                }
            }
        }
    }
    else if(!username){
        
        if($cookies.isKey("key")){
            //var token=$cookies.get("token");
            $cookies.remove("key");
            
        }

        if(to.path.includes("bulk") || to.path.includes("survey") || to.path.includes("agent")){
          //  console.log("user not null  "+from.path+" to path "+to.path);
            next({path: "/", replace: false});
        }
        else if(to.path == "/subscription/" || to.path == "/subscription/message/" || to.path=="/inbox" || to.path=="/api/" || to.path == "/payments/" || to.path == "/payments/transactions"
            || to.path == "/subscription" || to.path == "/subscription/message/" ||  to.path=="/inbox/" || to.path=="/inbox/keyword" || to.path=="/contacts/" || to.path=="/shortcodes/" || to.path=="/shortcodes"
            || to.path=="/contacts" || to.path=="/contacts/blacklist" || to.path=="/contacts/builder" || to.path=="/agent/" 
            || to.path=="/agent" || to.path=="/agent/view" || to.path=="/agent/view/" || to.path=="/agent/view/:id" || to.path=="/utilities/" || to.path=="/utilities/pay" || to.path=="/utilities/pay/:id" || to.path=="/utilities/transactions" 
            || to.path=="/account/users" || to.path=="/account/invoices" || to.path=="/account/transactions" || to.path=="/api" 
            ||  to.path == "/account/" || to.path=="/account/home" || to.path=="/verify" || to.path=="/set-password"){
                
            next({path: "/", replace: false});
        }

       else if((from.path=="/" && to.path=="/login") || to.path=="/login" ){
            //console.log("user not null  "+from.path+" to path "+to.path);
            next(true);
        }

        else if((from.path=="/" || from.path=="/login") && (to.path=="/register" || to.path=="/forgotpassword") ){
            //console.log("user not null status >0 "+from.path+" to path "+to.path);
            next(true);
        }
        else if(to.path=="/"){
            //console.log("user not null status >0 "+from.path+" to path "+to.path);
            next(true);
        }
        // else if(to.path=="/verify"){
        //     next({path: "/login", replace: false});
        // }
        else{
           // console.log("else "+from.path+" to path "+to.path);
            //next(true);
            next(true);
        }

    }
    else {
       //console.log("outside else");
        //console.log("user not null  "+from.path+" to path "+to.path);

        if($cookies.isKey("key")){
            //var token=$cookies.get("token");
            $cookies.remove("key");
            
        }

        if(to.path.includes("bulk") || to.path.includes("survey") || to.path.includes("agent")){
          //  console.log("user not null  "+from.path+" to path "+to.path);
            next({path: "/", replace: false});
        }
        else if(to.path == "/subscription/" || to.path=="/inbox" || to.path=="/api/" || to.path == "/payments/" || to.path == "/payments/transactions"
            || to.path == "/subscription" || to.path=="/inbox/" ||  to.path=="/inbox/keyword" || to.path=="/contacts/" || to.path=="/contacts" 
            || to.path=="/account/users" || to.path=="/account/invoices" || to.path=="/account/transactions" || to.path=="/api" || to.path=="/utilities/" || to.path=="/utilities/pay" || to.path=="/utilities/pay/:id" || to.path=="/utilities/transactions" 
            ||  to.path == "/account/" || to.path=="/account/home"){
                
            next({path: "/", replace: false});
        }

       else if((from.path=="/" && to.path=="/login") || to.path=="/login" ){
            //console.log("user not null  "+from.path+" to path "+to.path);
            next(true);
        }

        else if((from.path=="/" || from.path=="/login") && (to.path=="/register" || to.path=="/forgotpassword" || to.path=="/verify" ) ){
            //console.log("user not null status >0 "+from.path+" to path "+to.path);
            next(true);
        }
        else if(to.path=="/"){
            //console.log("user not null status >0 "+from.path+" to path "+to.path);
            next(true);
        }
        // else if(to.path=="/verify"){
        //     next({path: "/login", replace: false});
        // }
        else{
           // console.log("else "+from.path+" to path "+to.path);
            //next(true);
            next(true);
        }
    }
});
export default router;
