<template>
  <div class="l-app-main">
    <div class="l-app-main-inboxzero bg-primary-lighter" v-show="inboxzero">
      <h2>Create Message</h2>
      <p>Click below to <br />and send messages</p>
      <div class="padding-sm"></div>
      <button class="ui large blue button" @click="showCreateTemplate(true)">
        Send Message
      </button>
      <div class="padding-xl"></div>
    </div>
    <div class="l-app-main-section" v-show="!inboxzero">
      <div class="l-app-main-section-left">
        <ul class="l-app-tab">
          <li
            class="tab green"
            :class="{ active: selectedMessageTab == 1 }"
            @click="getMessageStatusSent()"
          >
            <h4>Sent</h4>
            <h3>{{ sentTotal }}</h3>
          </li>
          <li
            class="tab orange"
            :class="{ active: selectedMessageTab == 2 }"
            @click="getMessageStatusSchedule()"
          >
            <h4>Scheduled</h4>
            <h3>{{ scheduleTotal }}</h3>
          </li>
          <li
            class="tab orange"
            :class="{ active: selectedMessageTab == 5 }"
            @click="getMessageStatusPendingApproval()"
          >
            <h5>Approval</h5>
            <h4>{{ AwaitingApproval }}</h4>
          </li>
          <li
            class="tab blue"
            :class="{ active: selectedMessageTab == 3 }"
            @click="getMessageStatusPending()"
          >
            <h4>Pending</h4>
            <h3>{{ pendingTotal }}</h3>
          </li>
          <li
            class="tab red"
            :class="{ active: selectedMessageTab == 4 }"
            @click="getMessageStatusCancel()"
          >
            <h4>Cancel</h4>
            <h3>{{ cancelMessage }}</h3>
          </li>
        </ul>
        <ul class="l-app-messages" v-show="selectedMessageTab == 1">
          <!-- Sent -->
          <li
            class="message"
            v-for="list in lists"
            :key="list.campaign_id"
            @click="selectCampaign(list)"
            :class="{ selected: selectedMessageKey == list.campaign_id }"
          >
            <div class="message-icon">
              <i class="icon green check"></i>
            </div>
            <div class="message-content">
              <div class="message-content">
                <div class="message-content-body">{{ list.message }}</div>
                <div class="message-content-meta">
                  <ul class="">
                    <li>Sent {{ list.send_time | formatDate }}</li>
                    <li><i class="icon users"></i> {{ list.campaign_sent }} contacts</li>
                    <li>
                      <i class="check green icon"></i>
                      {{ formatNumber(list.delivered_percent) }}% delivered
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <div
            v-show="!loading"
            @click="getMessageStatusSent()"
            class="ui fluid button bottom aligned button"
          >
            Load More
          </div>
          <loader v-show="loading == true && totalMessageListSent >= limitSent"></loader>
        </ul>
        <ul class="l-app-messages" v-show="selectedMessageTab == 2">
          <!-- Scheduled -->
          <li
            class="message"
            v-for="list in listsSchedule"
            :key="list.campaign_id"
            v-on:click="selectCampaign(list)"
            :class="{ selected: selectedMessageKey == list.campaign_id }"
          >
            <div class="message-icon">
              <i class="icon yellow circle"></i>
            </div>
            <div class="message-content">
              <div class="message-content-body">{{ list.message }}</div>
              <div class="message-content-meta">
                <ul class="">
                  <li>
                    <i class="icon clock outline"></i> {{ list.send_time | formatDate }}
                  </li>
                  <li><i class="icon users"></i> {{ list.campaign_sent }} contacts</li>
                </ul>
              </div>
            </div>
          </li>
          <div
            v-show="!loading"
            @click="getMessageStatusSchedule()"
            class="ui fluid button bottom aligned button"
          >
            Load More
          </div>
          <loader
            v-show="loading == true && totalMessageListSchedule >= limitSchedule"
          ></loader>
        </ul>
        <ul class="l-app-messages" v-show="selectedMessageTab == 5">
          <!-- Pending -->
          <li
            class="message"
            v-for="list in listsPendingApproval"
            :key="list.campaign_id"
            v-on:click="selectCampaign(list)"
            :class="{ selected: selectedMessageKey == list.campaign_id }"
          >
            <div class="message-icon">
              <i class="icon gray circle"></i>
            </div>
            <div class="message-content">
              <div class="message-content-body">{{ list.message }}</div>
              <div class="message-content-meta">
                <ul class="">
                  <li>
                    <i class="icon clock outline"></i> {{ list.send_time | formatDate }}
                  </li>
                  <li><i class="icon users"></i> {{ list.campaign_sent }} contacts</li>
                  <li>
                    <i class="check green icon"></i>
                    {{ formatNumber(list.delivered_percent) }}% delivered
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <div
            v-show="!loading"
            @click="getMessageStatusPendingApproval()"
            class="ui fluid button bottom aligned button"
          >
            Load More
          </div>
          <loader v-show="loading"></loader>
        </ul>
        <ul class="l-app-messages" v-show="selectedMessageTab == 3">
          <!-- Pending -->
          <li
            class="message"
            v-for="list in listsPending"
            :key="list.campaign_id"
            v-on:click="selectCampaign(list)"
            :class="{ selected: selectedMessageKey == list.campaign_id }"
          >
            <div class="message-icon">
              <i class="icon gray circle"></i>
            </div>
            <div class="message-content">
              <div class="message-content-body">{{ list.message }}</div>
              <div class="message-content-meta">
                <ul class="">
                  <li>
                    <i class="icon clock outline"></i> {{ list.send_time | formatDate }}
                  </li>
                  <li><i class="icon users"></i> {{ list.campaign_sent }} contacts</li>
                  <li>
                    <i class="check green icon"></i>
                    {{ formatNumber(list.delivered_percent) }}% delivered
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <div
            v-show="!loading"
            @click="getMessageStatusPending()"
            class="ui fluid button bottom aligned button"
          >
            Load More
          </div>
          <loader v-show="loading"></loader>
        </ul>
        <ul class="l-app-messages" v-show="selectedMessageTab == 4">
          <!-- Cancel -->
          <li
            class="message"
            v-for="list in listsCancel"
            :key="list.campaign_id"
            v-on:click="selectCampaign(list)"
            :class="{ selected: selectedMessageKey == list.campaign_id }"
          >
            <div class="message-icon">
              <i class="icon gray circle"></i>
            </div>
            <div class="message-content">
              <div class="message-content-body">{{ list.message }}</div>
              <div class="message-content-meta">
                <ul class="">
                  <li>
                    <i class="icon clock outline"></i> {{ list.send_time | formatDate }}
                  </li>
                  <li><i class="icon users"></i> {{ list.campaign_sent }} contacts</li>
                  <li>
                    <i class="check green icon"></i>
                    {{ formatNumber(list.delivered_percent) }}% delivered
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <div
            v-show="!loading"
            @click="getMessageStatusCancel()"
            class="ui fluid button bottom aligned button"
          >
            Load More
          </div>
          <loader v-show="loading"></loader>
        </ul>
      </div>
      <MessageDetails v-show="selectedCampaign" ref="details" />
    </div>
  </div>
</template>

<script>
import MessageDetails from "./message-details";
import loader from "@/components/loader";
import store from "@/store";
import bulkstore from "@/bulk/bulksms-state";
import moment from "moment";
export default {
  data() {
    return {
      inboxzero: false,
      sentTotal: 0,
      pendingTotal: 0,
      scheduleTotal: 0,
      lists: [],
      listsSchedule: [],
      listsPending: [],
      listsCancel: [],
      listsPendingApproval: [],
      status: 400,
      loading: true,
      selectedMessageTab: 1,
      selectedCampaign: false,
      limitSent: 10,
      offsetSent: 1,
      totalMessageListSent: 0,
      limitSchedule: 10,
      offsetSchedule: 1,
      totalMessageListSchedule: 0,
      limitPending: 10,
      offsetPending: 1,
      totalMessageListPending: 0,
      limitPendingApproval: 10,
      offsetPendingApproval: 1,
      totalMessageListPendingApproval: 0,
      limitCancel: 10,
      offsetCancel: 1,
      totalMessageListCancel: 0,
      loading: true,
      selectedMessageKey: null,
      cancelMessage: 0,
      AwaitingApproval: 0,
    };
  },
  components: {
    MessageDetails,
    loader,
  },

  mounted() {
    let vm = this;
    if (!vm.$cookies.get("key") || vm.$cookies.get("key") == null) {
      vm.$router.push({
        name: "home",
      });
    }

    vm.getAllessage();
    vm.getMessageStatusSent();
    console.log(vm.AwaitingApproval);
  },

  methods: {
    formatNumber(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    showCreateTemplate(v, message) {
      this.$parent.showCreateMessageFn(v, message);
      store.commit("composeMessageState", message);
    },
    getAllessage() {
      let vm = this;
      vm.inboxzero = false;
      $.post({
        url: store.state.rootURLBulkAPI + "v3/SMS/Reportview",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
          offset: "",
          short_code: "",
          message: "",
          sort: "",
          export: "",
          startDate: "",
          endDate: "",
          status: "",
        }),
        success: function (response, status, jQxhr) {
          vm.loading = false;
          vm.sentTotal = response.data.record_count;
          if (vm.sentTotal == 0) {
            vm.inboxzero = true;
          }
          vm.scheduleTotal = response.data.Scheduled;
          var pending = response.data.pending;
          vm.AwaitingApproval = response.data.AwaitingApproval;
          vm.cancelMessage = response.data.cancelMessage;
          if (pending != "") {
            vm.pendingTotal = Number(response.data.pending);
          } else {
            vm.pendingTotal = "";
          }
        },
        error: function (jQxhr, status, error) {},
      });
    },
    getMessageStatusSent() {
      let vm = this;
      vm.selectedMessageTab = 1;
      //vm.lists = [];
      if (
        vm.totalMessageListSent === 0 ||
        vm.totalMessageListSent >= (vm.offsetSent - 1) * vm.limitSent
      ) {
        vm.loading = true;
        $.post({
          url: store.state.rootURLBulkAPI + "v3/SMS/Reportview",
          type: "POST",
          data: JSON.stringify({
            apiKey: this.$cookies.get("key"),
            offset: "",
            short_code: "",
            message: "",
            sort: "",
            export: "",
            startDate: "",
            endDate: "",
            status: 400,
            offset: vm.offsetSent,
            limit: vm.limitSent,
          }),
          success: function (response, status, jQxhr) {
            vm.loading = false;
            vm.totalMessageListSent = response.data.success;
            vm.campaign_sent = response.data.data.campaign_sent;
            var list_c = response.data.data;
            for (var i = 0; i < list_c.length; i++) {
              //  vm.selectCampaign(list_c[i]);
              if (i == 0) {
                //vm.selectCampaign(list_c[i]);
              }

              vm.lists.push(list_c[i]);
            }
          },
          error: function (jQxhr, status, error) {},
        });
        vm.offsetSent++;
      }
    },
    getMessageStatusPendingApproval() {
      let vm = this;
      vm.selectedMessageTab = 5;
      //vm.lists = [];
      if (
        vm.totalMessageListPendingApproval === 0 ||
        vm.totalMessageListPendingApproval >=
          (vm.offsetPendingApproval - 1) * vm.limitPendingApproval
      ) {
        vm.loading = true;
        $.post({
          url: store.state.rootURLBulkAPI + "v3/SMS/Reportview",
          type: "POST",
          data: JSON.stringify({
            apiKey: this.$cookies.get("key"),
            offset: "",
            short_code: "",
            message: "",
            sort: "",
            export: "",
            startDate: "",
            endDate: "",
            status: 701,
            offset: vm.offsetPendingApproval,
            limit: vm.limitPendingApproval,
          }),
          success: function (response, status, jQxhr) {
            vm.loading = false;
            vm.totalMessageListPendingApproval = response.data.pending;
            vm.campaign_sent = response.data.data.campaign_sent;
            var list_c = response.data.data;
            for (var i = 0; i < list_c.length; i++) {
              vm.listsPendingApproval.push(list_c[i]);
            }
          },
          error: function (jQxhr, status, error) {
            alertify.set("notifier", "position", "top-right");
            alertify.error(jQxhr.responseJSON.statusDescription);
          },
        });
        vm.offsetPendingApproval++;
      }
    },
    getMessageStatusSchedule() {
      let vm = this;
      vm.selectedMessageTab = 2;

      //vm.lists = [];
      if (
        vm.totalMessageListSchedule === 0 ||
        vm.totalMessageListSchedule >= (vm.offsetSchedule - 1) * vm.limitSchedule
      ) {
        vm.loading = true;
        $.post({
          url: store.state.rootURLBulkAPI + "v3/SMS/Reportview",
          type: "POST",
          data: JSON.stringify({
            apiKey: this.$cookies.get("key"),
            offset: "",
            short_code: "",
            message: "",
            sort: "",
            export: "",
            startDate: "",
            endDate: "",
            status: 700,
            offset: vm.offsetSchedule,
            limit: vm.limitSchedule,
          }),
          success: function (response, status, jQxhr) {
            vm.loading = false;
            vm.totalMessageListSchedule = response.data.Scheduled;
            vm.campaign_sent = response.data.data.campaign_sent;
            var list_c = response.data.data;
            for (var i = 0; i < list_c.length; i++) {
              vm.listsSchedule.push(list_c[i]);
            }
          },
          error: function (jQxhr, status, error) {},
        });
        vm.offsetSchedule++;
      }
    },
    getMessageStatusPending() {
      let vm = this;
      vm.selectedMessageTab = 3;
      //vm.lists = [];
      if (
        vm.totalMessageListPending === 0 ||
        vm.totalMessageListPending >= (vm.offsetPending - 1) * vm.limitPending
      ) {
        vm.loading = true;
        $.post({
          url: store.state.rootURLBulkAPI + "v3/SMS/Reportview",
          type: "POST",
          data: JSON.stringify({
            apiKey: this.$cookies.get("key"),
            offset: "",
            short_code: "",
            message: "",
            sort: "",
            export: "",
            startDate: "",
            endDate: "",
            status: 200,
            offset: vm.offsetPending,
            limit: vm.limitPending,
          }),
          success: function (response, status, jQxhr) {
            vm.loading = false;
            vm.totalMessageListPending = response.data.pending;
            vm.campaign_sent = response.data.data.campaign_sent;
            var list_c = response.data.data;
            for (var i = 0; i < list_c.length; i++) {
              vm.listsPending.push(list_c[i]);
            }
          },
          error: function (jQxhr, status, error) {
            alertify.set("notifier", "position", "top-right");
            alertify.error(jQxhr.responseJSON.statusDescription);
          },
        });
        vm.offsetPending++;
      }
    },
    getMessageStatusCancel() {
      let vm = this;
      vm.selectedMessageTab = 4;
      //vm.lists = [];
      if (
        vm.totalMessageListCancel === 0 ||
        vm.totalMessageListCancel >= (vm.offsetCancel - 1) * vm.limitCancel
      ) {
        vm.loading = true;
        $.post({
          url: store.state.rootURLBulkAPI + "v3/SMS/Reportview",
          type: "POST",
          data: JSON.stringify({
            apiKey: this.$cookies.get("key"),
            offset: "",
            short_code: "",
            message: "",
            sort: "",
            export: "",
            startDate: "",
            endDate: "",
            status: 506,
            offset: vm.offsetCancel,
            limit: vm.limitCancel,
          }),
          success: function (response, status, jQxhr) {
            vm.loading = false;
            vm.totalMessageListCancel = response.data.cancelMessage;
            vm.campaign_sent = response.data.data.campaign_sent;
            var list_c = response.data.data;
            for (var i = 0; i < list_c.length; i++) {
              vm.listsCancel.push(list_c[i]);
            }
          },
          error: function (jQxhr, status, error) {
            alertify.set("notifier", "position", "top-right");
            alertify.error(jQxhr.responseJSON.statusDescription);
          },
        });
        vm.offsetPending++;
      }
    },
    selectCampaign(selectedCampaign) {
      this.selectedCampaign = selectedCampaign;
      this.selectedMessageKey = selectedCampaign.campaign_id;
      bulkstore.commit("setSelectedCampaign", selectedCampaign);
      this.$refs.details.setCampaignValue(selectedCampaign);
      if ($(window).width() < 640) {
        this.$router.push({
          name: "bulk-messages-details",
          params: { id: selectedCampaign.campaign_id },
        });
      }
    },
  },
  filters: {
    formatDate: function (value) {
      if (!value) return "";
      return moment(value).fromNow();
    },
  },
};
</script>
