<template>
    <div class="ui tiny modal" id="modal-invoice_cancel">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">Your are about to cancel {{amount}} payment<br /> for Invoice# {{originalName}}</h2>
            </div>
            <div class="ui negative message" v-show="PaymentStatus">
                {{paymentStatusDesc}}  
            </div>
        </div>
        <div class="actions">
            <button class="ui cancel button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary button" @click="cancelInvoice()">Confirm</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    export default {
        data() {
            return {
                PaymentStatus: false,
                paymentStatusDesc:"",
                invoice_id:"",
                amount:"",

            }
        },
        mounted() {
            let vm = this;
           // vm.checkServices(service);
        },
        computed: {
            originalName() {
                var vm = this;
                var responseInvoice = store.state.invoiceSelect;
                vm.invoice_id = responseInvoice.invoice_id;
                if(responseInvoice.status == 51){
                    vm.amount = responseInvoice.balance;
                }
                else{
                    vm.amount = responseInvoice.amount;
                }
                return vm.invoice_id;
    
            }
        },
        methods: {
          
           
            cancelInvoice() {
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/payment/initiate",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        invoice_id: vm.invoice_id,
                        cancel:54
                    }),
                    success: function(response, status, jQxhr) {
                        if (response.data.code == 201) {
                            alertify.set("notifier", "position", "top-right");
                            alertify.success(response.data.message);
                            vm.hideModal();
                        } 
                        else if(response.data.code != 200 ){
                            vm.$root.$emit('refreshList');
                            alertify.set("notifier", "position", "top-right");
                            alertify.error(response.data.message);
                            vm.PaymentStatus = true;
                            vm.paymentStatusDesc = response.data.message;
                        }
                        else {
                            vm.$root.$emit('refreshList');
                            vm.hideModal();
                            alertify.set("notifier", "position", "top-right");
                            alertify.success(response.data.message);
                        }
                    },
                    error: function(jQxhr, status, error) {
                       // console.log("failed "+JSON.stringify(jQxhr));
                        alertify.set("notifier", "position", "top-right");
                        alertify.error(jQxhr.responseJSON.statusDescription);
                        vm.PaymentStatus = true;
                        vm.paymentStatusDesc = jQxhr.responseJSON.statusDescription;
                    }
                });
            },
            hideModal() {
                $('.ui.modal#modal-invoice_cancel').modal('hide');
            }
        }
    }
</script>
