<template>
    <div class="l-homepage-header">
        <div class="l-homepage-header-bg">
            <div class="ui relaxed padded grid">
                <div class="equal width middle aligned row">
                    <div class="eight wide mobile only six wide tablet only column">
                        <router-link to="/"><img src="../assets/images/logo-long@3x.png" height="40" class="logo" /></router-link>
                    </div>
                    <div class="ten wide tablet only column">
                        <div class="padding-sm"></div>
                        <div class="ui basic top pointing large dropdown" v-show="loginStatus">
                            <i class="icon user circle"></i> My Account
                            <div class="right floating menu">
                                <div class="header">
                                    <h3 style="min-width: 200px;">{{full_names}}</h3>
                                    <h5>{{client_name }}</h5>
                                </div>
                                <router-link :to="{name: 'bulk-dashboard'}" class="icon item"><i class="icon home"></i> Dashboard</router-link>
                                <router-link :to="{name: 'account-summary'}" class="icon item"><i class="icon cog"></i> Account</router-link>
                                <router-link v-show="showAdminSwitch" :to="{name: 'bulk-dashboard'}" class="icon item"><i class="icon users"></i>Switch to Admin</router-link>
                                <div class="ui divider"></div>
                                <div v-on:click="logout()" class="icon item"><i class="icon red power"></i> Logout</div>
                            </div>
                        </div>
                    </div>
                    <div class="eight wide mobile only right aligned column">
                        <router-link :to="{name: 'login'}" class="ui basic inverted right floated active button" v-show="!loginStatus">Login</router-link>
                        <div class="ui basic top pointing large dropdown" v-show="loginStatus">
                            <i class="icon user circle"></i> My Account
                            <div class="right floating menu">
                                <div class="header">
                                    <h3 style="min-width: 200px;">{{full_names}}</h3>
                                    <h5>{{client_name }}</h5>
                                </div>
                                <router-link :to="{name: 'bulk-dashboard'}" class="icon item"><i class="icon home"></i> Dashboard</router-link>
                                <router-link :to="{name: 'account-summary'}" class="icon item"><i class="icon cog"></i> Account</router-link>
                                <div class="ui divider"></div>
                                <div v-on:click="logout()" class="icon item"><i class="icon red power"></i> Logout</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="four wide computer only column">
                        <router-link to="/" class="">
                            <img src="../assets/images/logo-long@3x.png" height="40" class="logo" />
                        </router-link>
                    </div>
                    <div class="twelve wide computer only right aligned column">
                        <router-link :to="{name: 'login'}" class="ui basic inverted right floated active button" v-show="!loginStatus">Login</router-link>
                        <div class="ui basic top pointing large dropdown" v-show="loginStatus">
                            <i class="icon user circle"></i> My Account
                            <div class="right floating menu">
                                <div class="header">
                                    <h3 style="min-width: 200px;">{{full_names}}</h3>
                                    <h5>{{client_name }}</h5>
                                </div>
                                <router-link :to="{name: 'bulk-dashboard'}" class="icon item"><i class="icon home"></i> Dashboard</router-link>
                                <router-link :to="{name: 'account-summary'}" class="icon item"><i class="icon cog"></i> Account</router-link>
                                <div class="ui divider"></div>
                                <div v-on:click="logout()" class="icon item"><i class="icon red power"></i> Logout</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .ui.dropdown .header {
        text-transform: capitalize !important;
    }

    .ui.dropdown .header h3{
        padding-bottom: 0 !important;
    }
    
    .ui.dropdown .header p {
        font-weight: 400 !important;
    }
</style>

   <script>
    import bulkstore from "@/bulk/bulksms-state";
    import store from "@/store";
    import DepositModal from "@/modals/deposit";
    import CreateMessage from "@/components/create-message";
    import EditProfile from "@/modals/account_edit";
    import alertify from 'alertifyjs';
    export default {
        data() {
            return {
                walletBalance: null,
                full_names: null,
                showApps: 0,
                showCreateMessage: null,
                hideCreateMessageContent: false,
                account_id: "",
                client_name: "",
                msisdn: "",
                loginStatus: false,
                 permission_acl:"",
                permission:[],
                showAdminSwitch:false

            };
        },
        watch: {
            showCreateMessage() {
                return store.state.showCreateMessageContainer;
            }
        },
        components: {
            DepositModal,
            CreateMessage,
            EditProfile
        },
        created() {
            let vm = this;
            if(vm.$cookies.get("key") != null){
                vm.showCreateMessage = store.state.showCreateMessageContainer;
                $.post({
                    url: store.state.rootURLBulkAPI + 'v3/account/view',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                    }),
                    success: function(response, status, jQxhr) {
                        var bonus = response.data.data.bonus;
                        bonus = bonus.replace(/\D/g, '');
                        vm.walletBalance = response.data.data.balance;
                        vm.full_names = response.data.data.account_name;
                        vm.msisdn = response.data.data.mobile_number;
                        vm.email_address = response.data.data.email_address;
                        vm.depositDataList = response.data.data.transaction_data;
                        vm.client_name = response.data.data.client_name;
                        vm.account_id = response.data.data.account_id;
                        vm.permission_acl = response.data.data.permission_acl;
                            vm.permission = vm.permission_acl.split(":")
                            if(vm.permission.includes("14")){
                                vm.showAdminSwitch = true;
                         }
        
                        store.commit("setWalletBalance", response.data.data.balance);
                        store.commit("setAccountDetails", response.data.data);
        
                    },
                    error: function(jQxhr, status, error) {
                        //alertify.set('notifier', 'position', 'top-right');
                        //alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });

            }
            
        },
        mounted() {
            if(this.$cookies.get("key") != null){
                this.loginStatus = true;
                
            }
            else{
                this.loginStatus = false;
            }
            $(".l-app-content-body").css("top", $(".l-app-content-header").outerHeight());
            $(".ui.dropdown").dropdown();
    
            if ($(window).width() < 480) {
                $('.l-app-nav').addClass("mobile");
            } else {
                $('.l-app-nav').removeClass("mobile");
            }
            $(".l-app-nav-label").click(function() {
                $('.l-app-nav.mobile').css({
                    display: "none"
                });
                $('.l-app-content').css({
                    opacity: "1"
                });
            });
        },
        methods: {
            showEditProfile() {
                $(".ui.modal#modal-edit-account").modal({
                    centered: false
                }).modal('show');
            },
            showDeposit() {
                $(".ui.modal#modal-deposit")
                    .modal({
                        centered: false
                    })
                    .modal("show");
            },
            switchApp() {
                $('.l-app-nav').show(300);
                $('.l-app-content').css({
                    opacity: "0.2"
                });
            },
            showCreateMessageFn(v, message) {
                store.commit("showCreateMessage", v);
                store.commit("composeMessageState", message);
                this.showCreateMessage = store.state.showCreateMessageContainer;
                //hideCreateMessageContent = false;
            },
            logout() {
                let vm = this;
                vm.$cookies.remove("key");
                vm.$cookies.remove("username");
                vm.$cookies.remove("iscalled");
                store.state.user = null;
                bulkstore.state.selectUser = null;
                vm.$forceUpdate();
                vm.$router.go();
                vm.$router.push({
                    name: 'home'
                });
            }
        }
    };
    
</script>