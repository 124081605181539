<template>
    <div class="ui tiny modal" id="modal-whatsApp-message-create">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">Create WhatsApp Message</h2>
                <h4>You can create Auto Message</h4>
            </div>
            <div class="ui negative message" v-show="errorStatus">{{ErrorDesc}}</div>
            <div class="ui centered container grid">
                <div class="column">
                    <div class="ui form">
                        <div class="field">
                            <label>* Type</label>
                                <select class="ui small dropdown" v-model="type">
                                    <option value="TEXT">TEXT</option>
                                    <option value="IMAGE">IMAGE</option>
                                    <option value="FILE">FILE</option>
                                    <option value="LOCATION">LOCATION</option>
                                    
                                </select>
                            
                        </div>
                        <div class="field" v-show="type=='LOCATION'">
                            <label>Longitude</label>
                            <div class="ui large input">
                                 <input type="text" placeholder="Longitude" v-model="longitudeNo" >
                            </div>
                            <label>Latitude</label>
                            <div class="ui large input">
                                 <input type="text" placeholder="Latitude" v-model="latitudeNo">
                            </div>
                            <label>Location</label>
                            <div class="ui large input">
                                <input type="text" placeholder="Location Name" v-model="locName" >
                            </div>
                            <label>Address</label>
                            <div class="ui large input">
                                 <input type="text" placeholder="Address" v-model="addressName" >
                            </div>
                        </div>
                        <div class="field" v-show="type == 'IMAGE' || type == 'FILE'">
                            <label v-show="type == 'IMAGE'">Image Url</label>
                            <label v-show="type == 'FILE'">File Url</label>
                            <div class="ui large input">
                               <input type="text" placeholder=""  required v-model="imageURL" />
                            </div>
                        </div>
                        <div class="required field" v-show="type == 'IMAGE' || type == 'FILE'" id="input-description-name">
                            <div class="field">
                                <label>Caption</label>
                                <div class="ui large input">
                                   <textarea class="l-type" placeholder="Type your SMS here" v-model="caption"></textarea>
                                </div>
                            </div>
                         </div>
                        <div class="required field" v-show="type == 'TEXT'" id="input-description-name">
                            <div class="field">
                                <label>Message</label>
                                <div class="ui large input">
                                   <textarea class="l-type" placeholder="Type your SMS here" v-model="message"></textarea>
                                </div>
                            </div>
                         </div>
                         <div class="field">
                             <label>Link Message ID</label>
                             <select class="ui small dropdown" v-model="SelectMessageData">
                                <option value="">Select Link Message</option>
                                <option value="ANY">None</option>
                                <option v-for="s_id in MessageData" v-show="MessageData.length > 0" :key="s_id.id" :value="s_id">{{s_id.id}}</option>
                                
                            </select>
                         </div>
                         <div class="field" v-show="LinkingKeyword">
                            <label>Keyword</label>
                            <select class="ui small dropdown" v-model="keyword">
                                <option v-for="s_id in LinkingKeyword" v-show="LinkingKeyword.length > 0" :key="s_id" :value="s_id">{{s_id}}</option>
                            </select>
                        </div>
                        <div class="field">
                            <div class="ui toggle checkbox">
                                <input type="checkbox" name="schedule" v-model="menuDrive">
                                <label><strong> Enable Menu Drive</strong></label>
                            </div>
                        </div>
                        <div class="field" v-show="menuDrive">
                            <label>Reply Keywords <br /><small>Numeric Keywords separated by full colon (:). e.g 1:2:3</small></label>
                            
                            <div class="ui input">
                                <input type="text" v-model="keywords" maxlength="11" required/>
                            </div>
                        </div>

                        <div class="padding-sm"></div>
                        <div class="field">
                            <div class="ui checkbox">
                                <input type="checkbox" v-model="agreeTerms"/>
                                <label>I agree to the Terms and Conditions of this service</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui large button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary large button" :disabled="!agreeTerms" @click="createWhatsAppKeyword()">Save</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    export default {
        data() {
            return {
                type: "",
                agreeTerms: false,
                message: "",
                imageURL:null,
                errorStatus:false,
                ErrorDesc:"",
                caption:"",
                MessageData:[],
                SelectMessageData:[],
                linkMessage:"",
                keywords:"",
                keyword:"",
                longitudeNo:"",
                latitudeNo:"",
                locName:"",
                addressName:"",
                menuDrive:false,
                testmodel: ""
            }
        },
        mounted() {
            let vm = this;
            vm.getWhatsAppMessage();

        },
        
        methods: {
            createWhatsAppKeyword(){
                
                let vm = this;
                let value ="";
                if(vm.type == "TEXT"){
                    value = vm.message;
                }
                else{
                    value = vm.imageURL;
                }
                let messageData = [{
                        type: vm.type,
                        value: value,
                        linkMessage: vm.linkMessage,
                        caption:vm.caption,
                        ifKeyword:vm.keyword,
                        keywords:vm.keywords,
                        longitudeNo:vm.longitudeNo,
                        latitudeNo:vm.latitudeNo,
                        locName:vm.locName,
                        addressName:vm.addressName
                    }];
                
                    $.post({
                        url: store.state.rootURLBulkAPI + 'v3/whatsApp/message/create',
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: vm.$cookies.get("key"),
                            messageData:messageData
                        }),
                        success: function(response, status, jQxhr) {
                        alertify.set("notifier", "position", "top-right");
                        alertify.success(response.data.message);
                        vm.hideModal();
                        },
                        error: function(jQxhr, status, error) {
                            vm.errorStatus = true;
                            vm.ErrorDesc = jQxhr.responseJSON.statusDescription;
                            alertify.set("notifier", "position", "top-right");
                            alertify.error(jQxhr.responseJSON.statusDescription);
                        }
                    });
            },
            getWhatsAppMessage(){
                let vm = this;
                $.post({
                    url: store.state.rootUrl + 'whatsApp/view/messages',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        limit:20

                    }),
                    success: function(response, status, jQxhr) {
                        vm.MessageData = response.data.data;
                    },
                    error: function(jQxhr, status, error) {
                        vm.MessageData = [];
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            },
            hideModal() {
                $('.ui.modal#modal-whatsApp-message-create').modal('hide');
            }
        },
        computed: {
            LinkingKeyword() {
                let vm = this;
                
                console.log("Array "+JSON.stringify(vm.SelectMessageData));
                var keyword =[];
                if(vm.SelectMessageData){
                    keyword = vm.SelectMessageData.keywords;
                    vm.linkMessage = vm.SelectMessageData.id;
                    if(keyword){
                        vm.SelectMessageData = [];
                        console.log(keyword.split(":"));
                        console.log("Keyword "+JSON.stringify(keyword));
                        return  keyword.split(":");

                    }
                    else{
                        vm.SelectMessageData = [];
                        console.log("Keywords "+JSON.stringify(keyword));
                        return keyword;
                    }
                    
                    
                }
                else{
                    vm.SelectMessageData = [];
                    console.log("Keywordss "+JSON.stringify(keyword));
                    return keyword;
                }
                
                
                
            }
        }
    }
</script>
