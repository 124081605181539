<template>
    <div class="l-app-dashboard">
        <div class="ui relaxed padded grid">
            
            <div class="computer only tablet only equal width row">
                <div class="column">
                    <h3 class="text-medium">Summary</h3>
                    <h4>{{formatDates(dateOne, dateTwo)}}</h4>
                </div>
                <div class="right aligned column">
                    <div class="ui input datepicker-trigger">
                        <input type="text" id="datepicker-trigger" placeholder="Select date" :value="formatDates(dateOne, dateTwo)">
                        <AirbnbStyleDatepicker :trigger-element-id="'datepicker-trigger'" :mode="'range'" :fullscreen-mobile="true" :months-to-show="2" :date-one="dateOne" :date-two="dateTwo" :close-after-select="false" @date-one-selected="val => { dateOne = val }" @date-two-selected="val => { dateTwo = val }"
                            @apply="fetchSummaryData(dateOne, dateTwo)" />
                    </div>
                </div>
            </div>
            <div class="computer only tablet only equal width row">
                <div class="sixteen wide column">
                    <div class="ui segment" :class="{loading: dataLoading}">
                        <div class="padding-sm">
                            <div class="ui grid">
                                <div class="top aligned row">
                      
                                    <div class="computer only column">
                                        <div class=" segment">
                                            <div class="padding-bottom-sm">
                                                <h3>SMS</h3>
                                                <div class="padding-bottom-md"><small>SMS Sent</small></div>
                                            </div>
                                            <div><canvas class="height-sm" id="payments" style="width: 95%;"></canvas></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="padding-sm"></div>
                                <div class="row">
                                    <div class="column">
                                        <table class="ui very basic compact unstackable table">
                                            
                                            <tbody>
                                                <tr>
                                                    <td><h4>Successful SMS</h4></td>
                                                    <td><h4 class="text-medium">{{successPercent}}%</h4></td>
                                                    <td class="six wide">
                                                        <div class="ui indicating small progress margin-bottom-none" :data-percent="successPercent">
                                                            <div class="bar"></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                 <tr class="hidden">
                                                    <td><h4>Failed SMS</h4></td>
                                                    <td><h4 class="text-medium">{{failedPercent}}%</h4></td>
                                                    <td class="six wide">
                                                        <div class="ui indicating small progress margin-bottom-none" :data-percent="failedPercent">
                                                            <div class="bar"></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="padding-top-xs"></div>
                </div>
                <div class="five wide computer five wide tablet sixteen wide mobile column">
                    <div class="ui segment" :class="{loading: dataLoading}">
                        <div class="padding-sm">
                            <h3 class="text-medium text-title">Successful SMS</h3>
                            <table class="ui very basic compact unstackable table">
                                 <thead>
                                    <tr>
                                        <th>Keyword</th>
                                        <th>Current</th>
                                        <th>This Month</th>
                                        <th>All Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(success, index)  in successList" :key="index+1">
                                        <td>{{success.name}}</td>
                                        <td>{{success.allcurrent}}</td>
                                        <td>{{success.currentMonth}}</td>
                                        <td>{{success.allTime}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div v-show="!loadingSuccess && totalSuccess>20" @click="getSuccess()" class="ui fluid small bottom attached button">Load More</div>
                        </div>
                        <loader v-show="loadingSuccess"></loader>
                    </div>
                </div>
                <div class="six wide computer six wide tablet sixteen wide mobile column">
                    <div class="ui segment" :class="{loading: dataLoading}">
                        <div class="padding-sm">
                            <h3 class="text-medium text-title">Failed SMS</h3>
                            <table class="ui very basic compact unstackable table">
                                 <thead>
                                    <tr>
                                        <th>Keyword</th>
                                        <th>Current</th>
                                        <th>This Month</th>
                                        <th>All Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(failed, index)  in failedList" :key="index+1">
                                        <td>{{failed.name}}</td>
                                        <td>{{failed.allcurrent}}</td>
                                        <td>{{failed.currentMonth}}</td>
                                        <td>{{failed.allTime}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div v-show="!loadingFailed && totalFailed>20" @click="getFailed()" class="ui fluid small bottom attached button">Load More</div>
                        </div>
                        <loader v-show="loadingFailed"></loader>
                    </div>
                </div>
                <div class="five wide computer five wide tablet sixteen wide mobile column">
                    <div class="ui segment" :class="{loading: dataLoading}">
                        <div class="padding-sm">
                            <h3 class="text-medium text-title">Subscribers</h3>
                            <table class="ui very basic compact unstackable table">
                                 <thead>
                                    <tr>
                                        <th>Keyword</th>
                                        <th>Subscribers</th>
                                        <th>Unsubscribers</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(subscriber, index)  in subscriberList" :key="index+1">
                                        <td>{{subscriber.name}}</td>
                                        <td>{{subscriber.subscriber}}</td>
                                        <td>{{subscriber.unsubscriber}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div v-show="!loadingSubscriber && totalSubscribers>20" @click="getSubscribers()" class="ui fluid small bottom attached button">Load More</div>
                        </div>
                        <loader v-show="loadingSubscriber"></loader>
                    </div>
                </div>
                
            </div>
           
        </div>
        <div class="ui relaxed padded grid">
            <div class="mobile only row margin-top-none">
                <div class="ui padded grid">
                    <div class="top aligned row bg-white">
                        <div class="column">
                            <div class="">
                                <div class="padding-bottom-sm">
                                    <h3>SMS</h3>
                                    <div class="padding-bottom-md"><small>SMS Sent</small></div>
                                </div>
                                <div><canvas width="100%" height="200" class="height-sm" id="payments-sm"></canvas></div>
                            </div>
                        </div>
                    </div>
                    <div class="row bg-white">
                        <div class="column">
                            <table class="ui very basic compact unstackable table">
                                <tbody>
                                    <tr>
                                        <td class="collapsing"><h4>Successful SMS</h4></td>
                                        <td class="collapsing"><h4 class="text-medium">{{successPercent}}%</h4></td>
                                        <td class="">
                                            <div class="ui indicating small progress margin-bottom-none" :data-percent="successPercent">
                                                <div class="bar"></div>
                                            </div>
                                        </td>
                                    </tr>
                                        <tr class="hidden">
                                        <td><h4>Failed SMS</h4></td>
                                        <td><h4 class="text-medium">{{failedPercent}}%</h4></td>
                                        <td class="">
                                            <div class="ui indicating small progress margin-bottom-none" :data-percent="failedPercent">
                                                <div class="bar"></div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row margin-top-md bg-white">
                        <div class="column">
                            <div class="">
                                <h3 class="text-medium text-title">Successful SMS</h3>
                                <table class="ui very basic compact unstackable table">
                                    <thead>
                                        <tr>
                                            <th>Keyword</th>
                                            <th>Current</th>
                                            <th>This Month</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(success, index)  in successList" :key="index+1">
                                            <td>{{success.name}}</td>
                                            <td>{{success.allcurrent}}</td>
                                            <td>{{success.currentMonth}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div v-show="!loadingSuccess && totalSuccess>20" @click="getSuccess()" class="ui fluid small bottom attached button">Load More</div>
                            </div>
                            <loader v-show="loadingSuccess"></loader>
                        </div>
                    </div>
                    <div class="row margin-top-md bg-white">
                        <div class="column">
                            <div class="">
                                <h3 class="text-medium text-title">Failed SMS</h3>
                                <table class="ui very basic compact unstackable table">
                                    <thead>
                                        <tr>
                                            <th>Keyword</th>
                                            <th>Current</th>
                                            <th>This Month</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(failed, index)  in failedList" :key="index+1">
                                            <td>{{failed.name}}</td>
                                            <td>{{failed.allcurrent}}</td>
                                            <td>{{failed.currentMonth}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div v-show="!loadingFailed && totalFailed>20" @click="getFailed()" class="ui fluid small bottom attached button">Load More</div>
                            </div>
                            <loader v-show="loadingFailed"></loader>`
                        </div>
                    </div>
                    <div class="row margin-top-md bg-white">
                        <div class="column">
                            <div class="">
                                <h3 class="text-medium text-title">Subscribers</h3>
                                <table class="ui very basic compact unstackable table">
                                    <thead>
                                        <tr>
                                            <th>Keyword</th>
                                            <th>Subscribers</th>
                                            <th>Unsubscribers</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(subscriber, index)  in subscriberList" :key="index+1">
                                            <td>{{subscriber.name}}</td>
                                            <td>{{subscriber.subscriber}}</td>
                                            <td>{{subscriber.unsubscriber}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div v-show="!loadingSubscriber && totalSubscribers>20" @click="getSubscribers()" class="ui fluid small bottom attached button">Load More</div>
                            </div>
                            <loader v-show="loadingSubscriber"></loader>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import loader from "@/components/loader";
    import store from "@/store";
    import pageFooter from "@/components/page-footer";
    import pageHeader from "@/components/page-header";
    import Chart from "chart.js";
    import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
    import format from "date-fns/format";
    import Vue from "vue";
    import alertify from "alertifyjs";
    import {mapActions} from 'vuex';
    import _ from "lodash";
    import moment from "moment";
    export default {
        data() {
            return {
                dateFormat: "D MMM",
                dateFormatTwo: "YYYY-MM-DD",
                dateOne: "",
                dateTwo: "",
                limit: 20,
                offset: 0,
                offsetFailed:0,
                offsetSubscriber:0,
                loading: true,
                days: 7,
                graphData: [],
                dataLoading: false,
                totalSuccess:0,
                totalFailed:0,
                totalSubscribers:0,
                successList:[],
                failedList:[],
                subscriberList:[],
                loadingSuccess:true,
                loadingFailed:true,
                loadingSubscriber:true,
                successPercent:0,
                failedPercent:0
            };
        },
        components: {
            // pageFooter,
            pageHeader,
            loader,
        },
        mounted() {
            let vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            const datepickerOptions = {};
            Vue.use(AirbnbStyleDatepicker, datepickerOptions);
            vm.dateTwo = format(Date(), this.dateFormatTwo);
            var d = new Date();
            vm.dateOne = format(d.setDate(d.getDate() - 14), this.dateFormatTwo);
             $(".ui.dropdown").dropdown();
            vm.getGraphData(vm.dateOne, vm.dateTwo);
            vm.getSuccess();
            vm.getFailed();
            vm.getSubscribers();
        },
        methods: {
            ...mapActions(['testAction','selectCampaign']),
            formatDates(dateOne, dateTwo) {
                let formattedDates = "";
                if (dateOne) {
                    formattedDates = format(dateOne, this.dateFormat);
                }
                if (dateTwo) {
                    formattedDates += " - " + format(dateTwo, this.dateFormat);
                }
                return formattedDates;
            },
           fetchSummaryData(dateOne, dateTwo){
               let vm  = this
               vm.dateOne = dateOne;
               vm.dateTwo = dateTwo;
               vm.successPercent = 0;
               vm.totalSuccess =0;
               vm.totalFailed = 0;
               vm.totalSubscribers =  0;
               vm.successList = []
               vm.failedList = []
               vm.subscriberList = []
               vm.getGraphData(dateOne,dateTwo)
               vm.getSuccess()
               vm.getFailed()
               vm.getSubscribers()

           },
            getGraphData(from, to) {
               
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + 'subscription/v3/graph',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        start:from,
                        end:to,
                    }),
                    success: function(response, status, jQxhr) {
                       
                        vm.graphData = response.data.data;

                        
                        

                        let yData = [];
                        let xDate = [];
                        let result = response.data.data;
                        let cont = 0;
                        result.forEach(function(data){
                            yData.push(parseInt(data["count"]));
                            xDate.push(data["date"]);
                           vm.failedPercent = vm.failedPercent + parseInt(data["failedPercent"]);
                           vm.successPercent = vm.successPercent+ parseInt(data["successPercent"]);
                           cont++;
                        });
                        if(response.data.record_count != 0){
                            vm.failedPercent =vm.failedPercent / cont;
                            vm.successPercent = vm.successPercent / cont;
                        }
                        else{
                            vm.failedPercent =0;
                            vm.successPercent = 0;
                        }
                        let ctx = $("#payments");
                        let ctxsm = $("#payments-sm");
                        let myChart = new Chart(ctx, {
                            type: "line",
                            data: {
                                labels: xDate,
                                datasets: [{
                                    data: yData,
                                    backgroundColor: ["#26A842"],
                                    borderColor: ["#26A842"],
                                    borderWidth: 1
                                }]
                            },
                            options: {
                                maintainAspectRatio: false,
                                responsive: false,
                                layout: {
                                    padding: {
                                        right: 0
                                    }
                                },
                                legend: {
                                    display: false
                                },
                                scales: {
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                                },
                                elements: {
                                    line: {
                                        tension: 0 // disables bezier curves
                                    }
                                }
                            }
                        });
                        let myChartSM = new Chart(ctxsm, {
                            type: "line",
                            data: {
                                labels: xDate,
                                datasets: [{
                                    data: yData,
                                    backgroundColor: ["#26A842"],
                                    borderColor: ["#26A842"],
                                    borderWidth: 1
                                }]
                            },
                            options: {
                                maintainAspectRatio: false,
                                responsive: true,
                                layout: {
                                    padding: {
                                        right: 0
                                    }
                                },
                                legend: {
                                    display: false
                                },
                                scales: {
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                                },
                                elements: {
                                    line: {
                                        tension: 0 // disables bezier curves
                                    }
                                }
                            }
                        });
                        setTimeout(function() {
                            $(".ui.progress").progress();
                        }, 100)
                    },
                    error: function(jQxhr, status, error) {
                        vm.loading = false;
                    }
                });
    
            },
            getSuccess() {
                var vm = this;
                if (vm.totalSuccess === 0 || vm.totalSuccess >= ((vm.offset - 1) * vm.limit)) {
                    vm.offset++;
                    vm.loadingSuccess = true;
                    $.post({
                        url: store.state.rootURLBulkAPI + 'subscription/v3/keyword/status',
                        type: "POST",
                        data: JSON.stringify({
                            token: this.$cookies.get("key"),
                            limit: vm.limit,
                            start: vm.dateOne,
                            end: vm.dateTwo,
                            offset: vm.offset,
                            status: 1
                        }),
                        success: function(data, status, jQxhr) {
                            vm.loadingSuccess = false;
                            var code = data.data.code;
                            vm.totalSuccess = data.data.count;
                            if (code == 404) {
                                vm.totalSuccess = 0;
                            } else {
                                for (var i = 0; i < data.data.data.length; i++) {
                                    vm.successList.push(data.data.data[i])
                                }
                            }
                        },
                        error: function(jQxhr, status, error) {

                            vm.loadingSuccess = false;
                            var error = jQxhr.responseJSON.statusDescription;
                            if (error) {
                                alertify.error(error);
                            } else {
                                alertify.error("An error occurred ");
                            }
                        }
                    });
                }
        
            },
            getFailed(){
                let vm = this;
                if (vm.totalFailed === 0 || vm.totalFailed >= ((vm.offsetFailed - 1) * vm.limit)) {
                    vm.offsetFailed++;
                    vm.loadingFailed = true;
                    $.post({
                        url: store.state.rootURLBulkAPI + 'subscription/v3/keyword/status',
                        type: "POST",
                        data: JSON.stringify({
                            token: this.$cookies.get("key"),
                            limit: vm.limit,
                            start: vm.dateOne,
                            end: vm.dateTwo,
                            offset: vm.offsetFailed,
                            status: 2
                        }),
                        success: function(data, status, jQxhr) {
                            vm.loadingFailed = false;
                            var code = data.data.code;
                            vm.totalFailed = data.data.count;
                            if (code == 404) {
                                vm.totalFailed = 0;
                            } else {
                                for (var i = 0; i < data.data.data.length; i++) {
                                    vm.failedList.push(data.data.data[i])
                                }
                            }
                        },
                        error: function(jQxhr, status, error) {

                            vm.loadingFailed = false;
                            var error = jQxhr.responseJSON.statusDescription;
                            if (error) {
                                alertify.error(error);
                            } else {
                                alertify.error("An error occurred ");
                            }
                        }
                    });
                }
            },
             getSubscribers(){
                let vm = this;
                if (vm.totalSubscribers === 0 || vm.totalSubscribers >= ((vm.offsetSubscriber - 1) * vm.limit)) {
                    vm.offsetSubscriber++;
                    vm.loadingSubscriber = true;
                    $.post({
                        url: store.state.rootURLBulkAPI + 'subscription/v3/subscriber',
                        type: "POST",
                        data: JSON.stringify({
                            token: this.$cookies.get("key"),
                            limit: vm.limit,
                            start: vm.dateOne,
                            end: vm.dateTwo,
                            offset: vm.offsetSubscriber,
                            status: 2
                        }),
                        success: function(data, status, jQxhr) {
                            vm.loadingSubscriber = false;
                            var code = data.data.code;
                            vm.totalSubscribers = data.data.count;
                            if (code == 404) {
                                vm.totalSubscribers = 0;
                            } else {
                                for (var i = 0; i < data.data.data.length; i++) {
                                    vm.subscriberList.push(data.data.data[i])
                                }
                            }
                        },
                        error: function(jQxhr, status, error) {

                            vm.loadingSubscriber = false;
                            var error = jQxhr.responseJSON.statusDescription;
                            if (error) {
                                alertify.error(error);
                            } else {
                                alertify.error("An error occurred ");
                            }
                        }
                    });
                }
            },
            numberWithCommas(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        
        filters: {
            formatDate: function(value) {
                if (!value) return ''
                return moment(value).fromNow()
            },
            truncate: function(text, length, clamp) {
                clamp = clamp || '...';
                var node = document.createElement('div');
                node.innerHTML = text;
                var content = node.textContent;
                return content.length > length ? content.slice(0, length) + clamp : content;
            }
        }
    };
</script>