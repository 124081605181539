<template>
    <div class="ui tiny modal" id="modal-Paybill_status">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">Your are about to {{statusMessage}} <br /> Paybill / Till {{originalName}} <br />status</h2>
                
            </div>
            <div class="ui negative message" v-show="PaymentStatus">
                {{paymentStatusDesc}}  
            </div>
        </div>
        <div class="actions">
            <button class="ui cancel button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary button" @click="paybillStatus()">Confirm</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    export default {
        data() {
            return {
                PaymentStatus: false,
                paymentStatusDesc:"",
                paybill:"",
                id:"",
                status:0,
                statusMessage:""

            }
        },
        mounted() {
            let vm = this;
           // vm.checkServices(service);
        },
        computed: {
            originalName() {
                var vm = this;
                var responseBillDetails = store.state.paybillSelect;
                vm.paybill = responseBillDetails.paybill;
                vm.id = responseBillDetails.id;
                var statusNew = responseBillDetails.pStatus;
                if(statusNew == 1){
                    vm.statusMessage ="Deactivate";
                    vm.status = 5;
                }
                else{
                    vm.statusMessage ="Activate";
                    vm.status = 6;
                }
                //5 deactivate 6 activate
                return vm.paybill;
    
            }
        },
        methods: {
          
            paybillStatus(){
                let vm = this;
                $.post({
                    url: store.state.rootUrl + 'payment/update/paybill',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        paybill_id: vm.id,
                        activate: vm.status,
                    }),
                    success: function(response, status, jQxhr) {
                        if(response.data.code != 200){
                            vm.PaymentStatus= true;
                            vm.paymentStatusDesc = response.data.message;
                            alertify.set('notifier', 'position', 'top-right');
                            alertify.error(response.data.message);
                        }
                        else{
                            alertify.set("notifier", "position", "top-right");
                            alertify.success(response.data.message);
                            vm.hideModal();
                        }
                    },
                    error: function(jQxhr, status, error) {
                        vm.PaymentStatus= true;
                        vm.paymentStatusDesc = jQxhr.responseJSON.statusDescription;
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });

            },
            hideModal() {
                $('.ui.modal#modal-Paybill_status').modal('hide');
            }
        }
    }
</script>
