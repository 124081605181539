<template>
  <div class="l-app-main-section-right">
    <div class="section">
      <div class="section-title">
        <div class="ui equal width grid">
          <div class="column">
            <h2>#ID: {{ campaignId }}</h2>
            <small>Sent on {{ sentTimeDate }} via {{ senderID }}</small>
          </div>
          <div
            class="computer only tablet only right aligned column"
            v-show="exportStatus"
          >
            <button class="ui button" @click="generateExport()" id="exportData">
              Export
            </button>
          </div>
          <div class="aligned column" v-show="Scheduled && !customMenuChecker">
            <button class="ui button" @click="editMessage(selectedCampaign)">Edit</button>
          </div>

          <div
            class="computer only tablet only right aligned column"
            v-show="pending && customMenuChecker"
          >
            <button class="ui primary button" @click="messageApproval(200)">
              Approve
            </button>
            <button class="ui secondary button" @click="messageApproval(500)">
              Reject
            </button>
          </div>
        </div>
      </div>
      <div class="section-content">
        <div class="ui grid">
          <div class="row">
            <div class="column">
              <div class="ui raised segment">
                <div class="ui grid">
                  <div class="equal width row">
                    <div class="sixteen wide column padding-bottom-sm">
                      <h3 class="text-medium text-primary">Delivery</h3>
                    </div>
                    <div class="column">
                      <h3 class="text-medium">{{ totalSent }}</h3>
                      <h5 class="text-grey">Sent</h5>
                    </div>
                    <div class="column">
                      <h3 class="text-medium">{{ totalDelivered }}</h3>
                      <h5 class="text-grey">Delivered</h5>
                    </div>
                    <div class="column">
                      <h3 class="text-medium">{{ totalFailed }}</h3>
                      <h5 class="text-grey">Failed</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="column height-md">
                      <canvas width="100%" id="sent-messages"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-content">
        <div class="ui grid">
          <div class="column">
            <h3 class="text-title text-medium">Message</h3>
            <div class="ui top attached padded segment">
              <h3>{{ message }}</h3>
            </div>
            <div class="ui primmary bottom attached tiny message">
              {{ totalCharacter }} characters &bull; {{ messagePages }} SMS
            </div>
          </div>
        </div>
      </div>
      <div class="padding-lg"></div>
      <div class="ui equal width middle aligned grid">
        <div class="row">
          <div class="column">
            <h3 class="text-medium text-title">Message Status</h3>
            <h4 class="text-grey">Last 5 Phone Numbers</h4>
          </div>
          <div class="computer only tablet only right aligned column">
            <div class="left aligned column">
              <div class="ui search link tiny input margin-right-md">
                <input
                  type="text"
                  placeholder="Search Phone Number"
                  v-on:keyup="setCampaignValue()"
                  v-model="mobile"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="computer only tablet only column">
            <table class="ui styled celled unstackable table" v-show="statusRecordValid">
              <thead>
                <tr>
                  <th>Phone</th>
                  <th>Status Description</th>
                  <th>Network</th>
                  <th>Sent On</th>
                  <th>Delivered On</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="list in lists" :key="list.outbox_id">
                  <td>{{ list.msisdn }}</td>
                  <td>{{ list.state_description }}</td>
                  <td>{{ list.network }}</td>
                  <td>{{ list.created_at }}</td>
                  <td>{{ list.received_on }}</td>
                </tr>
              </tbody>
            </table>
            <loader v-show="loading"></loader>
            <div class="ui negative message" v-show="statusRecords">
              {{ recordsResponseMessage }}
            </div>
          </div>
          <div class="mobile only column">
            <table class="ui very basic unstackable table" v-show="statusRecordValid">
              <thead>
                <tr>
                  <th>Phone</th>
                  <th class="right aligned">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="list in lists" :key="list.outbox_id">
                  <td class="top aligned">
                    <h4>{{ list.msisdn }}</h4>
                    <small>{{ list.network }}</small>
                  </td>
                  <td class="right aligned">
                    <h5
                      v-show="
                        list.state_description ==
                        'Message Submitted Successfully and Delivered to User Mobile'
                      "
                    >
                      Delivered
                    </h5>
                    <h5
                      v-show="
                        list.state_description !=
                        'Message Submitted Successfully and Delivered to User Mobile'
                      "
                    >
                      {{ list.state_description }}
                    </h5>
                    <small>{{ list.received_on }}</small>
                  </td>
                </tr>
              </tbody>
            </table>
            <loader v-show="loading"></loader>
            <div class="ui negative message" v-show="statusRecords">
              {{ recordsResponseMessage }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <MessageEdit />
  </div>
</template>

<script>
import MessageEdit from "@/modals/message-edit";
import MessageDetails from "./message-details";
import loader from "@/components/loader";
import store from "@/store";
import bulkstore from "@/bulk/bulksms-state";
import alertify from "alertifyjs";
import moment from "moment";
export default {
  data() {
    return {
      inboxzero: false,
      selectedCampaign: "",
      status: "",
      message: "",
      totalDelivered: "",
      totalFailed: "",
      totalSent: "",
      sentDate: "",
      campaignId: "",
      totalCharacter: "",
      messagePages: "",
      sentTimeDate: "",
      Scheduled: false,
      exportStatus: true,
      lists: [],
      limit: 5,
      loading: true,
      totalRecords: 0,
      offset: 0,
      statusRecordValid: false,
      statusRecords: false,
      recordsResponseMessage: "",
      mobile: "",
      senderID: "",
      pending: false,
      customMenuChecker: false,
    };
  },
  components: {
    MessageDetails,
    MessageEdit,
    loader,
  },

  mounted() {
    let vm = this;
    if (!vm.$cookies.get("key") || vm.$cookies.get("key") == null) {
      vm.$router.push({
        name: "home",
      });
    }
    vm.setCampaignValue();
    if (vm.$cookies.get("auth_roleId") == 2) {
      vm.customMenuChecker = true;
    }
    if (vm.$cookies.get("key") == "cb9a0746634b9815bad6c9064a071718") {
      vm.customMenuChecker = true;
    }
  },
  methods: {
    messageApproval(state) {
      let vm = this;
      $.post({
        url: store.state.rootURLBulkAPI + "v3/BulkSMS/approve/messages",
        type: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key"),
          campaign_id: vm.selectedCampaign.campaign_id,
          approval_state: state,
        }),
        success: function (response, status, jQxhr) {
          alertify.set("notifier", "position", "top-right");
          alertify.success(response.data.message);
        },
        error: function (jQxhr, status, error) {
          vm.loading = true;
          alertify.set("notifier", "position", "top-right");
          alertify.error(
            jQxhr.responseJSON.statusDescription + " " + jQxhr.responseJSON.data.message
          );
        },
      });
    },
    editMessage(selectedCampaign) {
      let vm = this;
      //this.selectedCampaign = selectedCampaign;
      bulkstore.commit("setSelectedCampaign", selectedCampaign);
      $(".ui.modal#modal-edit-message")
        .modal({
          closable: true,
        })
        .modal("show");
    },
    generateExport() {
      $("#exportData").html(
        '<i class="fa fa-circle-notch fa-spin"></i> Exporting Please Wait'
      );
      let vm = this;
      var exportRecipients = [];
      $.post({
        url: store.state.rootURLBulkAPI + "v3/SMS/Reportoutbox",
        type: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key"),
          campaignId: vm.selectedCampaign.campaign_id,
          export: 1,
        }),
        success: function (response, status, jQxhr) {
          $(".ui.accordion").accordion("refresh");
          for (var i = 0; i < response.data.data.length; i++) {
            var item = {};
            item.msisdn = response.data.data[i].msisdn;
            item.state_description = response.data.data[i].state_description;
            item.description = response.data.data[i].description;
            item.message = response.data.data[i].message;
            item.received_on = response.data.data[i].received_on;
            item.created_at = response.data.data[i].created_at;

            exportRecipients.push(item);
          }

          var exportDate = moment().format("DD_MMMM_YYYY_h:mm");

          vm.JSONToCSVConvertor(
            exportRecipients,
            "Campaign ID: " + vm.selectedCampaign.campaign_id + "_" + exportDate,
            1
          );
          $("#exportData").html("Export");
        },
        error: function (jQxhr, status, error) {
          $("#exportData").html("Export");
          vm.loading = true;
          alertify.set("notifier", "position", "top-right");
          alertify.error(jQxhr.responseJSON.statusDescription);
        },
      });
    },
    JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
      //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
      var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;

      var CSV = "";
      //Set Report title in first row or line

      //CSV += ReportTitle + '\r\n\n';

      //This condition will generate the Label/Header
      if (ShowLabel) {
        var row = "";

        //This loop will extract the label from 1st index of on array
        for (var index in arrData[0]) {
          //Now convert each value to string and comma-seprated
          row += index + ",";
        }

        row = row.slice(0, -1);

        //append Label row with line break
        CSV += row + "\r\n\n\n";
      }

      //1st loop is to extract each row
      for (var i = 0; i < arrData.length; i++) {
        var row = "";

        //2nd loop will extract each column and convert it in string comma-seprated
        for (var index in arrData[i]) {
          row += '"' + arrData[i][index] + '",';
        }

        row.slice(0, row.length - 1);

        //add a line break after each row
        CSV += row + "\r\n";
      }

      if (CSV == "") {
        alert("Invalid data");
        return;
      }

      //Generate a file name
      var fileName = "VasPro_";
      //this will remove the blank-spaces from the title and replace it with an underscore
      fileName += ReportTitle.replace(/ /g, "_");

      //Initialize file format you want csv or xls
      var uri = "data:text/csv;charset=utf-8," + escape(CSV);

      // Now the little tricky part.
      // you can use either>> window.open(uri);
      // but this will not work in some browsers
      // or you will not get the correct file extension

      //this trick will generate a temp <a /> tag
      var link = document.createElement("a");
      link.href = uri;

      //set the visibility hidden so it will not effect on your web-layout
      link.style = "visibility:hidden";
      link.download = fileName + ".csv";

      //this part will append the anchor tag and remove it after automatic click
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    messageTab(val) {
      this.selectedMessageTab = val;
    },
    setCampaignValue() {
      let vm = this;
      console.log("new status " + vm.$route.params.id);
      if (vm.$route.params.id != "" && !isNaN(vm.$route.params.id)) {
        vm.campaignId = vm.$route.params.id;
        $.post({
          url: store.state.rootURLBulkAPI + "v3/SMS/Reportview",
          type: "POST",
          data: JSON.stringify({
            apiKey: this.$cookies.get("key"),
            campaignId: vm.campaignId,
          }),
          success: function (response, status, jQxhr) {
            if (response.data.code != 200) {
              alertify.set("notifier", "position", "top-right");
              alertify.error("Not Found");
            } else {
              vm.selectedCampaign = response.data.data[0];
              vm.message = vm.selectedCampaign.message;
              vm.totalDelivered = vm.selectedCampaign.campaign_delivered;
              vm.totalFailed = vm.selectedCampaign.campaign_failed;
              vm.totalSent = vm.selectedCampaign.campaign_sent;
              vm.sentDate = vm.selectedCampaign.created_at;
              vm.campaignId = vm.selectedCampaign.campaign_id;
              vm.senderID = vm.selectedCampaign.short_code;
              vm.sentTimeDate = vm.selectedCampaign.send_time;
              if (vm.message) {
                vm.totalCharacter = vm.message.length;
                if (vm.message.length == 0) {
                  vm.messagePages = 0;
                } else if (vm.message.length / 160 <= 1) {
                  vm.messagePages = 1;
                } else if (vm.message.length / 160 <= 2) {
                  vm.messagePages = 2;
                } else if (vm.message.length / 160 <= 3) {
                  vm.messagePages = 3;
                } else if (vm.message.length / 160 <= 4) {
                  vm.messagePages = 4;
                } else if (vm.message.length / 160 <= 5) {
                  vm.messagePages = 5;
                } else {
                  vm.messagePages = " Max ";
                }
              }
              vm.status = vm.selectedCampaign.status;
              var newStatus = parseInt(vm.status, 10);
              console.log("Status " + newStatus);
              if (newStatus == 700) {
                vm.Scheduled = true;
                vm.exportStatus = false;
                vm.pending = false;
              } else if (newStatus == 506) {
                vm.Scheduled = true;
                vm.exportStatus = false;
                vm.pending = false;
              } else if (newStatus == 701) {
                vm.Scheduled = true;
                vm.exportStatus = false;
                vm.pending = true;
              } else {
                vm.Scheduled = false;
                vm.exportStatus = true;
                vm.pending = false;
              }

              $.post({
                url: store.state.rootURLBulkAPI + "v3/SMS/Reportoutbox",
                type: "POST",
                data: JSON.stringify({
                  apiKey: vm.$cookies.get("key"),
                  offset: "0",
                  short_code: "",
                  message: "",
                  sort: "",
                  export: 0,
                  startDate: "",
                  endDate: "",
                  msisdn: vm.mobile,
                  campaignId: vm.selectedCampaign.campaign_id,
                  limit: vm.limit,
                }),
                success: function (response, status, jQxhr) {
                  vm.loading = false;
                  vm.totalRecords = response.data.record_count;
                  if (response.data.code != 200) {
                    // alertify.set('notifier', 'position', 'top-right');
                    // alertify.error(response.data.message);
                    vm.statusRecords = true;
                    vm.statusRecordValid = false;
                    vm.recordsResponseMessage = response.data.message;
                  } else {
                    vm.statusRecordValid = true;
                    vm.statusRecords = false;
                    vm.lists = response.data.data;
                    if (vm.selectedCampaign.campaign_id != "" && vm.sentTimeDate != "") {
                      //vm.getGraphData();
                    }
                  }
                },
                error: function (jQxhr, status, error) {
                  vm.loading = true;
                  alertify.set("notifier", "position", "top-right");
                  alertify.error(jQxhr.responseJSON.statusDescription);
                },
              });
            }
          },
          error: function (jQxhr, status, error) {
            alertify.set("notifier", "position", "top-right");
            alertify.error("Not Found");
          },
        });
      } else {
        vm.selectedCampaign = bulkstore.state.selectedCampaign;
        vm.message = vm.selectedCampaign.message;
        vm.totalDelivered = vm.selectedCampaign.campaign_delivered;
        vm.totalFailed = vm.selectedCampaign.campaign_failed;
        vm.totalSent = vm.selectedCampaign.campaign_sent;
        vm.sentDate = vm.selectedCampaign.created_at;
        vm.campaignId = vm.selectedCampaign.campaign_id;
        vm.senderID = vm.selectedCampaign.short_code;
        vm.sentTimeDate = vm.selectedCampaign.send_time;
        if (vm.message) {
          vm.totalCharacter = vm.message.length;
          if (vm.totalCharacter == 0) {
            vm.messagePages = 0;
          } else if (vm.totalCharacter / 160 <= 1) {
            vm.messagePages = 1;
          } else if (vm.totalCharacter / 160 <= 2) {
            vm.messagePages = 2;
          } else if (vm.totalCharacter / 160 <= 3) {
            vm.messagePages = 3;
          } else if (vm.totalCharacter / 160 <= 4) {
            vm.messagePages = 4;
          } else if (vm.totalCharacter / 160 <= 5) {
            vm.messagePages = 5;
          } else {
            vm.messagePages = " Max ";
          }
        }
        vm.status = vm.selectedCampaign.status;
        var newStatus = parseInt(vm.status, 10);
        if (newStatus == 700 || newStatus == 701) {
          vm.Scheduled = true;
          vm.exportStatus = false;
        } else if (newStatus == 506) {
          vm.Scheduled = false;
          vm.exportStatus = false;
        } else if (vm.status == 200) {
          vm.Scheduled = false;
          vm.exportStatus = false;
          vm.pending = true;
        } else {
          vm.Scheduled = false;
          vm.exportStatus = true;
        }

        $.post({
          url: store.state.rootURLBulkAPI + "v3/SMS/Reportview",
          type: "POST",
          data: JSON.stringify({
            apiKey: this.$cookies.get("key"),
            campaignId: vm.campaignId,
          }),
          success: function (response, status, jQxhr) {
            if (response.data.code != 200) {
              alertify.set("notifier", "position", "top-right");
              alertify.error("Not Found");
            } else {
              vm.selectedCampaign = response.data.data[0];
              vm.message = vm.selectedCampaign.message;
              vm.totalDelivered = vm.selectedCampaign.campaign_delivered;
              vm.totalFailed = vm.selectedCampaign.campaign_failed;
              vm.totalSent = vm.selectedCampaign.campaign_sent;
              vm.sentDate = vm.selectedCampaign.created_at;
              vm.campaignId = vm.selectedCampaign.campaign_id;
              vm.senderID = vm.selectedCampaign.short_code;
              vm.sentTimeDate = vm.selectedCampaign.send_time;
              if (vm.message) {
                vm.totalCharacter = vm.message.length;
                if (vm.message.length == 0) {
                  vm.messagePages = 0;
                } else if (vm.message.length / 160 <= 1) {
                  vm.messagePages = 1;
                } else if (vm.message.length / 160 <= 2) {
                  vm.messagePages = 2;
                } else if (vm.message.length / 160 <= 3) {
                  vm.messagePages = 3;
                } else if (vm.message.length / 160 <= 4) {
                  vm.messagePages = 4;
                } else if (vm.message.length / 160 <= 5) {
                  vm.messagePages = 5;
                } else {
                  vm.messagePages = " Max ";
                }
              }
              vm.status = vm.selectedCampaign.status;
              var newStatus = parseInt(vm.status, 10);
              console.log("Status " + newStatus);
              if (newStatus == 700) {
                vm.Scheduled = true;
                vm.exportStatus = false;
                vm.pending = false;
              } else if (newStatus == 506) {
                vm.Scheduled = true;
                vm.exportStatus = false;
                vm.pending = false;
              } else if (newStatus == 701) {
                vm.Scheduled = true;
                vm.exportStatus = false;
                vm.pending = true;
              } else {
                vm.Scheduled = false;
                vm.exportStatus = true;
                vm.pending = false;
              }

              $.post({
                url: store.state.rootURLBulkAPI + "v3/SMS/Reportoutbox",
                type: "POST",
                data: JSON.stringify({
                  apiKey: vm.$cookies.get("key"),
                  offset: "0",
                  short_code: "",
                  message: "",
                  sort: "",
                  export: 0,
                  startDate: "",
                  endDate: "",
                  msisdn: vm.mobile,
                  campaignId: vm.selectedCampaign.campaign_id,
                  limit: vm.limit,
                }),
                success: function (response, status, jQxhr) {
                  vm.loading = false;
                  vm.totalRecords = response.data.record_count;
                  if (response.data.code != 200) {
                    // alertify.set('notifier', 'position', 'top-right');
                    // alertify.error(response.data.message);
                    vm.statusRecords = true;
                    vm.statusRecordValid = false;
                    vm.recordsResponseMessage = response.data.message;
                  } else {
                    vm.statusRecordValid = true;
                    vm.statusRecords = false;
                    vm.lists = response.data.data;
                    if (vm.selectedCampaign.campaign_id != "" && vm.sentTimeDate != "") {
                      //vm.getGraphData();
                    }
                  }
                },
                error: function (jQxhr, status, error) {
                  vm.loading = true;
                  alertify.set("notifier", "position", "top-right");
                  alertify.error(jQxhr.responseJSON.statusDescription);
                },
              });
            }
          },
          error: function (jQxhr, status, error) {
            alertify.set("notifier", "position", "top-right");
            alertify.error("Not Found");
          },
        });
      }
    },

    selectCampaign(selectedCampaign) {
      bulkstore.commit("setSelectedCampaign", selectedCampaign);
      this.$refs.details.setCampaignValue(selectedCampaign);
      //vm.getGraphData();
    },
    getGraphData() {
      let vm = this;
      $.post({
        url: store.state.rootURLBulkAPI + "v3/SMS/Reportoutbox/graph",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
          campaign_id: vm.selectedCampaign.campaign_id,
          start: vm.sentTimeDate,
        }),
        success: function (response, status, jQxhr) {
          vm.graphData = response.data.data;

          let yData = [];
          let xDate = [];
          let result = response.data.data;

          result.forEach(function (data) {
            yData.push(parseInt(data["COUNT"]));
            xDate.push(data["DATE_FORMAT(a.Date,'%H')"]);
          });

          let ctx = $("#sent-messages");
          let myChart = new Chart(ctx, {
            type: "bar",
            data: {
              labels: xDate,
              datasets: [
                {
                  data: yData,
                  backgroundColor: ["#26A842"],
                  borderColor: ["#26A842"],
                  borderWidth: 1,
                },
              ],
            },
            options: {
              maintainAspectRatio: false,
              responsive: true,
              layout: {
                padding: {
                  right: 0,
                },
              },
              legend: {
                display: false,
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
              elements: {
                line: {
                  tension: 0, // disables bezier curves
                },
              },
            },
          });
        },
        error: function (jQxhr, status, error) {
          vm.loading = false;
        },
      });
    },
  },
};
</script>
