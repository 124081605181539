<template>
    <div class="l-app-main-section-right">
        <div class="section">
            <div class="section-title">
                <div class="ui equal width grid">
                    <div class="column">
                        <h2>#ID: {{campaignId}}</h2>
                        <small>Sent on {{sentTimeDate}} via {{senderID}}</small>
                    </div>
                    
                </div>
            </div>
            <div class="section-content">
                <div class="ui grid">
                    <div class="row">
                        <div class="column">
                            <div class="ui raised segment">
                                <div class="ui grid">
                                    <div class="equal width row">
                                        <div class="sixteen wide column padding-bottom-sm">
                                            <h3 class="text-medium text-primary">Delivery</h3>
                                        </div>
                                        <div class="column">
                                            <h3 class="text-medium">{{totalSent}}</h3>
                                            <h5 class="text-grey">Sent</h5>
                                        </div>
                                        <div class="column">
                                            <h3 class="text-medium">{{totalDelivered}}</h3>
                                            <h5 class="text-grey">Success</h5>
                                        </div>
                                        <div class="column">
                                            <h3 class="text-medium">{{totalFailed}}</h3>
                                            <h5 class="text-grey">Failed</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="column height-md">
                                            <canvas width="100%" id="sent-messages"></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-content">
                <div class="ui grid">
                    <div class="column">
                        <h3 class="text-title text-medium">Message</h3>
                        <div class="ui top attached padded segment">
                            <h3>{{message}}</h3>
                        </div>
                        <div class="ui primmary bottom attached tiny message">
                            {{totalCharacter}} characters &bull; {{messagePages}} SMS
                        </div>
                    </div>
                </div>
            </div>
            <div class="padding-lg"></div>
           
        </div>
        <MessageEdit />
    </div>
</template>

<script>
    import MessageEdit from "@/modals/message-edit";
    import MessageDetails from "./message-details";
    import loader from "@/components/loader";
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    import moment from "moment";
    export default {
        data() {
            return {
                inboxzero: false,
                selectedCampaign: "",
                status: "",
                message: "",
                totalDelivered: "",
                totalFailed: "",
                totalSent: "",
                sentDate: "",
                campaignId: "",
                totalCharacter: "",
                messagePages: "",
                sentTimeDate: "",
                Scheduled: false,
                exportStatus: true,
                lists: [],
                limit: 5,
                loading: true,
                totalRecords: 0,
                offset: 0,
                statusRecordValid: false,
                statusRecords: false,
                recordsResponseMessage: "",
                mobile: "",
                senderID: ""
            }
        },
        components: {
            MessageDetails,
            MessageEdit,
            loader
        },
    
        mounted() {
            let vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            //vm.setCampaignValue();
            //vm.getGraphData();
        },
        methods: {
            editMessage(selectedCampaign) {
                let vm = this;
                //this.selectedCampaign = selectedCampaign;
                bulkstore.commit("setSelectedCampaign", selectedCampaign);
                $('.ui.modal#modal-edit-message').modal({
                    closable: true
                }).modal('show');
            },
            
            messageTab(val) {
                this.selectedMessageTab = val;
            },
            setCampaignMessageValue() {
                let vm = this;
                vm.selectedCampaign = bulkstore.state.selectedCampaign;
                vm.message = vm.selectedCampaign.message;
                vm.totalDelivered = vm.selectedCampaign.success;
                vm.totalFailed = vm.selectedCampaign.failed;
                vm.totalSent = vm.selectedCampaign.sent;
                vm.sentDate = vm.selectedCampaign.created_at;
                vm.campaignId = vm.selectedCampaign.subReportId;
                vm.senderID = vm.selectedCampaign.short_code;
                if (vm.message) {
                    vm.totalCharacter = vm.message.length;
                    if (vm.totalCharacter  == 0) {
                        vm.messagePages = 0;
                    } else if (vm.totalCharacter  / 160 <= 1) {
                        vm.messagePages = 1;
                    } else if (vm.totalCharacter  / 160 <= 2) {
                        vm.messagePages = 2;
                    } else if (vm.totalCharacter  / 160 <= 3) {
                        vm.messagePages = 3;
                    } else if (vm.totalCharacter  / 160 <= 4) {
                        vm.messagePages = 4;
                    } else if (vm.totalCharacter  / 160 <= 5) {
                        vm.messagePages = 5;
                    } else {
                        vm.messagePages = " Max ";
                    }
                }
                        let yData = [vm.totalSent,vm.totalFailed,vm.totalDelivered];
                        let xDate = ["Sent","Failed","Success"];
                        

                        //console.log(ydata);
    
                        let ctx = $("#sent-messages");
                        let myChart = new Chart(ctx, {
                            type: "bar",
                            data: {
                                labels: xDate,
                                datasets: [{
                                    data: yData,
                                    backgroundColor: ["#26A842"],
                                    borderColor: ["#26A842"],
                                    borderWidth: 1
                                }]
                            },
                            options: {
                                maintainAspectRatio: false,
                                responsive: true,
                                layout: {
                                    padding: {
                                        right: 0
                                    }
                                },
                                legend: {
                                    display: false
                                },
                                scales: {
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                                },
                                elements: {
                                    line: {
                                        tension: 0 // disables bezier curves
                                    }
                                }
                            }
                        });
            },

            getGraphData() {
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/SMS/Reportoutbox/graph",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        campaign_id: vm.selectedCampaign.subReportId,
                        start: vm.sentTimeDate
                    }),
                    success: function(response, status, jQxhr) {
    
                        vm.graphData = response.data.data;
    
                        
                    },
                    error: function(jQxhr, status, error) {
                        vm.loading = false;
                    }
                });
    
            }
    
        }
    }
</script>