<template>
    <div class="l-app-main-section-container two">
        <div class="ui relaxed padded grid">
            <div class="middle aligned row">
                <div class="six wide column">
                    <h4>Product ID {{list_id}}</h4>
                    Billing Type: <small> {{billingType}}</small>
                    <br />
                    <button class="ui small green button" @click="editKeywords()">Edit Keyword</button>
                </div>
                
                <div class="ten wide right aligned column">
                    <button class="ui small button " :disabled="totalContacts == 0" @click="showContactListSendMessage()">Send MT SMS</button>
                   
                </div>
                
            </div>
            <div class="row">
                <div class="column">
                    <div class="ui top attached segment">
                        <h2> {{totalContacts}} <small class="text-lightgrey text-normal">contacts</small></h2>
                    </div>
                    <div class="ui bottom attached secondary segment">
                        <div class="ui equal width grid">
                            <div class="column border-right">
                                <h4 class="text-medium">{{saf}}</h4>
                                <h5>Safaricom</h5>
                            </div>
                            <div class="column border-right">
                                <h4 class="text-medium">{{airtel}}</h4>
                                <h5>Airtel</h5>
                            </div>
                            <div class="column border-right">
                                <h4 class="text-medium">{{telcom}}</h4>
                                <h5>Telkom</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <div class="section-content">
                        <div class="ui grid">
                            <div class="column">
                                <h3 class="text-title text-medium">Subscription Message</h3>
                                <div class="ui top attached padded segment">
                                    <h3>{{submessage}}</h3>
                                </div>
                                <div class="ui primmary bottom attached tiny message">
                                    {{subtotalCharacter}} characters &bull; {{submessagePages}} SMS
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="row">
                <div class="column">
                    <div class="section-content">
                        <div class="ui grid">
                            <div class="column">
                                <h3 class="text-title text-medium">Unsubscription Message</h3>
                                <div class="ui top attached padded segment">
                                    <h3>{{unsubmessage}}</h3>
                                </div>
                                <div class="ui primmary bottom attached tiny message">
                                    {{unsubtotalCharacter}} characters &bull; {{unsubmessagePages}} SMS
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <div class="padding-top-md"></div>
                    <div class="ui equal width middle aligned grid">
                        <div class="right aligned column">
                            <div class="ui search link small input">
                                <input type="text" placeholder="Search Phone Number" v-on:keyup="setListValue(selectedList,true)" v-model="mobile" />
                            </div>
                        </div>
                    </div>
                    <table class="ui styled celled top attached table">
                        <thead>
                            <tr>
                                <th>Phone Number</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th class="center aligned"><i class="fas fa-ellipsis-h"></i></th>
   
                            </tr>
                        </thead>
                        <tbody>
                           
                            <tr v-for="contact in contactList" :key="contact.contactsID">
                                <td>{{contact.msisdn}}</td>
                                <td>{{contact.subscribed_on}}</td>
                                <td v-show="contact.status == 1">Active</td>
                                <td v-show="contact.status == 2">Inactive</td>
                                <td>
                                    <button v-show="contact.status==1" class="ui icon mini button" @click="muteContact(contact.subscriber_id,2)">Mute</button>
                                    <button v-show="contact.status==2" class="ui icon basic mini button" @click="muteContact(contact.subscriber_id,1)">Unmute</button>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-show="!loading" @click="getDetails()" class="ui fluid small bottom attached button">Load More</div>
                </div>
            </div>
        </div>
        <loader v-show="loading"></loader>
        <ContactListSendMessage />
        <EditKeywordSub />
    </div>

</template>

<script>
    import loader from "@/components/loader";
    import ContactListSendMessage from "@/modals/subscription-mt-message";
    import EditKeywordSub from "@/modals/subscription-edit-keyword";
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    import Dropzone from "dropzone";
    import moment from "moment";
    
    
    
    export default {
        components: {
            ContactListSendMessage,
            EditKeywordSub,
            loader
        },
        data() {
            return {
                listData: [],
                client_id: "",
                list_id: "",
                token: "",
                list_name: "",
                status: "",
                limit: 10,
                offset: 0,
                order: "",
                contactList: [],
                selectedList: [],
                totalContacts: 0,
                loading: false,
                new_mobile: '',
                mobile: '',
                profileTags: '',
                customTags: '',
                showUploadSection: false,
                uploadFiles: null,
                processingUploadFile: false,
                showContactMessageModal: false,
                UploadStatus:false,
                UploadStatusDesc:"",
                group_name:"",
                updated_at:"",
                description:"",
                uploadStatus:"",
                total:0,
                saf:0,
                airtel:0,
                telcom:0,
                mtnUg:0,
                mtnRWD:0,
                filename:"",
                submessage:"",
                subtotalCharacter:0,
                submessagePages:0,
                unsubmessage:"",
                unsubtotalCharacter:0,
                unsubmessagePages:0,
                billingType:""
            }
        },
        mounted() {
            let vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            if (vm.$route.params.id != "" && !isNaN(vm.$route.params.id)) {
                console.log("am here to");
                vm.list_id = vm.$route.params.id;
                vm.getDetails();
            }
            else if(store.state.selectKeywordList != null && store.state.selectKeywordList != ""){
                console.log("am here the");
                vm.list_id = store.state.selectKeywordList.product_id;
                vm.billingType = store.state.selectKeywordList.billing;
                
                vm.getDetails();
            }
            else {
                console.log("am here")
                vm.list_id = "";
                vm.submessage="";
            }
        },
        methods: {
            editKeywords(){
                let vm = this;
                $('.ui.modal#modal-edit-keyword-subscription').modal({
                    closable: true
                }).modal('show');

            },
            showContactListSendMessage() {
                //this.showContactMessageModal = true;
                $('.ui.modal#modal-contact-list-message').modal({
                    closable: true
                }).modal('show');
                $('#modal-scheduleDate').calendar({
                    type: 'date'
                });
                $('#modal-scheduleTime').calendar({
                    type: 'time'
                });
            },
            muteContact($subscriber,status){
                let vm  = this;
                $.post({
                    url: store.state.rootURLBulkAPI + 'subscription/v3/subscriber/edit',
                    type: "POST",
                    data: JSON.stringify({
                        token: vm.$cookies.get("key"),
                        subscriber_id: $subscriber,
                        status: status,
                    }),
                    success: function(data, status, jQxhr) {
                        alertify.success(data.statusDescription);
                        vm.totalContacts = 0;
                        vm.contactList=[];
                        vm.offset = 0;
                        vm.getDetails();
                    },
                    error: function(jQxhr, status, error) {
                        var error = jQxhr.responseJSON.statusDescription;
                        if (error) {
                            alertify.error(error);
                        } else {
                            alertify.error("An error occurred ");
                        }
                    }
                });

            },
            getDetails() {
                var vm = this;
                if (vm.totalContacts === 0 || vm.totalContacts >= ((vm.offset - 1) * vm.limit)) {
                    vm.offset++;
                    vm.loading = true;
                    $.post({
                        url: store.state.rootURLBulkAPI + 'subscription/v3/fetch/subscribers',
                        type: "POST",
                        data: JSON.stringify({
                            token: this.$cookies.get("key"),
                            product_id: vm.list_id,
                            limit: vm.limit,
                            offset: vm.offset,
                            msisdn: vm.mobile
                        }),
                        success: function(data, status, jQxhr) {
                            vm.loading = false;
                            var code = data.data.code;
                            vm.totalContacts = data.data.count;
                            console.log("contact "+vm.totalContacts);
                            if (code == 404) {
                                vm.totalContacts = 0;
                            } else {
                                for (var i = 0; i < data.data.data.length; i++) {
                                    vm.contactList.push(data.data.data[i])
                                    if(data.data.data[i].network == 'SAFARICOM'){
                                        vm.saf = vm.saf + 1;
                                    }
                                    if(data.data.data[i].network == 'AIRTEL' || data.data.data[i].network == 'AIRTEL_KE'){
                                        vm.airtel = vm.airtel + 1;
                                    }
                                    if(data.data.data[i].network == 'TELKOM_KE'){
                                        vm.telcom = vm.telcom + 1;
                                    }
                                }
                            }
                        },
                        error: function(jQxhr, status, error) {
    
                            vm.loading = false;
                            // vm.contactList=[];
                            //vm.totalContacts=0;
                            var error = jQxhr.responseJSON.statusDescription;
                            if (error) {
                                alertify.error(error);
                            } else {
                                alertify.error("An error occurred ");
                            }
                        }
                    });
    
                }
    
            },
            setListValue(selectedList, is_loadmore) {
                let vm = this;
                if(is_loadmore){
                    console.log("you have clicked me "+vm.list_id);
                    vm.getDetails();
                }
                else{
                    vm.list_id =  selectedList.product_id;
                    vm.submessage = selectedList.subscription_msg;
                    vm.subtotalCharacter = vm.submessage.length;
                    if (vm.submessage.length == 0) {
                    vm.submessagePages = 0;
                    } else if (vm.submessage.length  / 160 <= 1) {
                        vm.submessagePages =  1;
                    } else if (vm.submessage.length / 160 <= 2) {
                        vm.submessagePages =  2;
                    } else if (vm.submessage.length  / 160 <= 3) {
                        vm.submessagePages =  3;
                    } else if (vm.submessage.length  / 160 <= 4) {
                        vm.submessagePages =  4;
                    } else if (vm.submessage.length  / 160 <= 5) {
                        vm.submessagePages =  5;
                    } else {
                        vm.submessagePages =  " Max ";
                    }
                    vm.unsubmessage = selectedList.unsubscription_msg;
                    vm.unsubtotalCharacter = vm.unsubmessage.length;
                    if (vm.unsubmessage.length == 0) {
                    vm.unsubmessagePages = 0;
                    } else if (vm.unsubmessage.length  / 160 <= 1) {
                        vm.unsubmessagePages =  1;
                    } else if (vm.unsubmessage.length / 160 <= 2) {
                        vm.unsubmessagePages =  2;
                    } else if (vm.unsubmessage.length  / 160 <= 3) {
                        vm.unsubmessagePages =  3;
                    } else if (vm.unsubmessage.length  / 160 <= 4) {
                        vm.unsubmessagePages =  4;
                    } else if (vm.unsubmessage.length  / 160 <= 5) {
                        vm.unsubmessagePages =  5;
                    } else {
                        vm.unsubmessagePages =  " Max ";
                    }
                    vm.totalContacts =0;
                    vm.offset=0;
                    vm.saf = 0;
                    vm.airtel = 0;
                    vm.telcom = 0;
                    vm.contactList=[];
                    vm.getDetails();
                }
            },
            selectList(selectedList) {
                this.selectedKey = selectedList.list_id;
                this.selectedList = selectedList;
                bulkstore.commit("setSelectedList", selectedList);
            }
        }
    }
</script>
