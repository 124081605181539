<template>
    <div class="ui tiny modal" id="modal-Survey_status">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">Your are about to {{statusMessage}} <br /> Survey {{originalName}} <br />status</h2>
                
            </div>
            <div class="ui negative message" v-show="SurveyStatus">
                {{SurveyStatusDesc}}  
            </div>
        </div>
        <div class="actions">
            <button class="ui cancel button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary button" @click="surveyStatus()">Confirm</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    export default {
        data() {
            return {
                SurveyStatus: false,
                SurveyStatusDesc:"",
                survey:"",
                id:"",
                status:0,
                statusMessage:""

            }
        },
        mounted() {
            let vm = this;
            console.log(JSON.stringify(store.state.selectSurvey))
           // vm.checkServices(service);
        },
        computed: {
            originalName() {
                var vm = this;
                var surveyDetails = store.state.selectSurvey;
                vm.survey = surveyDetails.campaign_name;
                vm.id = store.state.surveyID;
                vm.status = store.state.surveyStatus;
                //console.log("Id: "+vm.id + " status: "+vm.status);
                if(vm.status  == 1){

                    vm.statusMessage ="Activated";

                }
                else if(vm.status == 2){

                    vm.statusMessage ="Stop";

                }
                else if(vm.status == 3){

                    vm.statusMessage ="Discard";
                    vm.status = '-4';
                }
                else{
                    vm.statusMessage ="Deactivate";
                }
                return vm.survey;
    
            }
        },
        methods: {
          
            surveyStatus(){
                let vm = this;
                $.post({
                    url: store.state.rootUrl + 'survey/update',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        status: vm.status,
                        app_id: vm.id
                    }),
                    success: function(response, status, jQxhr) {
                        if(response.data.code != 200){
                            vm.SurveyStatus= true;
                            vm.SurveyStatusDesc = response.data.message;
                            alertify.set('notifier', 'position', 'top-right');
                            alertify.error(response.data.message);
                        }
                        else{
                            alertify.set("notifier", "position", "top-right");
                            alertify.success(response.data.message);
                            vm.$parent.suverys = [];
                            vm.$parent.resp = [];
                            vm.$parent.surveySummary = [];
                            vm.$parent.totalSurvey = 0;
                            vm.$parent.offset = 0;
                            vm.$parent.fetchSurveySummary();
                            vm.$parent.fetchSummaryTop();
                            vm.$parent.fetchCompleteGraphData();
                            vm.hideModal();
                        }
                    },
                    error: function(jQxhr, status, error) {
                        vm.SurveyStatus= true;
                        vm.SurveyStatusDesc = jQxhr.responseJSON.statusDescription;
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });

            },
            hideModal() {
                $('.ui.modal#modal-Survey_status').modal('hide');
            }
        }
    }
</script>
