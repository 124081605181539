<template>
    <div class="l-app-main-section">
        <div class="ui padded relaxed equal width grid">
            <div class="middle aligned row">
                
                <div class="eight wide computer sixteen wide tablet sixteen wide mobile column">
                    <h3>All Invoices</h3>
                    <span>{{totalInvoice}} Invoices</span>
                </div>
                <div class="right aligned computer only column">
                    <button class="ui button" @click="exportData()">Export all</button>
                </div>
            </div>
            
            <div class="computer only tablet only row">
                <div class="column">
                    <table class="ui celled styled unstackable table">
                        <thead>
                            <tr>
                                <th>#ID</th>
                                <th>Date</th>
                                <th>Description</th>
                                <th class="computer only">Invoice ID</th>
                                <th class="right aligned">Amount</th>
                                <th class="right aligned">Balance</th>
                                <th class="center aligned collapsing"><i class="fa fa-ellipsis-h"></i></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(invoice, index) in depositDataList" :key="invoice.invoice_id">
                                <td class="collapsing">{{index+1}}</td>
                                <td>{{invoice.created_at}}</td>
                                <td>{{invoice.description}}</td>
                                <td>{{invoice.invoice_id}}</td>
                                <td>{{invoice.amount}}</td>
                                <td>{{invoice.balance}}</td>
                                <td class="left aligned collapsing">
                                    <h3 class="text-green" v-if="invoice.status == 52"><i class="icon check"></i> Paid</h3>
                                    <h3 class="text-red" v-if="invoice.status == 54"><i class="icon cancel"></i> Cancelled</h3>
                                    <button class="ui tiny button" @click="completePaymentInvoice(invoice)" v-if="invoice.status == 50 || invoice.status == 51 ">Complete Payment</button>
                                    <button class="ui tiny primary button" @click="cancelPaymentInvoice(invoice)" v-if="invoice.status == 50">Cancel Payment</button>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="6">
                                    <div class="ui negative message" v-show="descStatus"><span class="center">{{descMessage}}</span></div>
                                </td>
                            </tr>
                        </tbody>
                        
                    </table>
                    <div v-show="!loading" @click="fetchTransaction()" class="ui fluid button">Load More</div>
                    <loader v-show="loading"></loader>

                    <div class="padding-xl"></div>
                </div>
            </div>
            <div class="mobile only row bg-white border-top border-bottom border-light">
                <div class="column">
                    <table class="ui very basic unstackable table">
                        <thead>
                        <tr>
                            <th>Description</th>
                            <th class="right aligned">Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(invoice, index) in depositDataList" :key="invoice.invoice_id">
                            <td>
                                <div class="border-right border-light">
                                    <h4>{{index+1}} {{invoice.description}}</h4>
                                    <small><span class="padding-right-sm">#{{invoice.invoice_id}}</span> <span class="padding-right-sm">{{invoice.created_at}}</span></small>
                                </div>
                            </td>
                            <td class="right aligned">
                                <h4>{{invoice.amount}}</h4>
                                <small class="text-red" v-if="invoice.status == 51">Bal: {{invoice.balance}} </small>
                                <small class="text-green" v-if="invoice.status == 52"><i class="icon check"></i> Paid</small>
                                <small class="text-orange" v-if="invoice.status == 50 || invoice.status == 51" @click="completePaymentInvoice(invoice)"><i class="icon cart"></i> Pay Now </small>
                                <small class="text-red" v-if="invoice.status == 50" @click="cancelPaymentInvoice(invoice)"><i class="icon cancel"></i> Cancel</small>
                            </td>
                        </tr>
                       
                        </tbody>
                    </table>
                    <div v-show="!loading" @click="fetchTransaction()" class="ui fluid button">Load More</div>
                    <loader v-show="loading"></loader>
                </div>
            </div>
            <InvoicePay />
            <InvoiceCancel />
        </div>
    </div>
    
</template>

<script>
    import loader from "@/components/loader";
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    import InvoicePay from "@/modals/invoice_payment";
    import InvoiceCancel from "@/modals/invoice_cancel";
    import moment from "moment";
    export default {
        data() {
            return {
                depositDataList: [],
                limit: 10,
                offset: 1,
                loading: false,
                totalInvoice:0,
                descStatus:false,
                descMessage:""
            }
        },
        components: {
            loader,
            InvoiceCancel,
            InvoicePay
        },
        mounted() {
            let vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            
            this.$root.$on('refreshList',(data) => {
                vm.depositDataList = [];
                vm.fetchTransaction();

            });
            
            vm.fetchTransaction();
    
        },
    
    
        methods: {
    
            fetchTransaction() {
                
                let vm = this;
                
                if (vm.totalInvoice === 0 || vm.totalInvoice >= ((vm.offset - 1) * vm.limit)) {
                    console.log("am here "+vm.totalInvoice);
                    vm.loading = true;
                    
                    $.post({
                        url: store.state.rootURLBulkAPI + 'v3/payment/invoice/list',
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: vm.$cookies.get("key"),
                            invoice_status:"",
                            invoice_id:"",
                            amount:"",
                            service_id:"",
                            client_id:"",
                            limit: vm.limit,
                            offset: vm.offset,
                        }),
                        success: function(response, status, jQxhr) {
                            vm.loading = false;
                            var list_c = response.data.data;
                                for (var i = 0; i < list_c.length; i++) {
                                     vm.totalInvoice = list_c[i].count;
                                    list_c[i].invoice_id = Number(list_c[i].invoice_id); //moment.format(list_c.payment_date,"DD MMM YYYY")
                                    vm.depositDataList .push(list_c[i]);
                             }
                            //console.log(JSON.stringify(vm.totalInvoice));
                        },
                        error: function(jQxhr, status, error) {
                            vm.loading = false;
                            vm.descStatus= true;
                            vm.descMessage = jQxhr.responseJSON.statusDescription;
                             alertify.error(jQxhr.responseJSON.statusDescription);
                            //console.log(" status" + status + " data " + JSON.stringify(jQxhr.responseJSON.statusDescription));
                        }
                    });
                    vm.offset++;
                }
            },
            exportData() {
                var vm = this;
                var exportRecipients = [];
    
                // console.log("messageData "+JSON.stringify(selectedList));
                vm.token = vm.$cookies.get("key");
    
    
                $.post({
                    url: store.state.rootURLBulkAPI + 'v3/payment/invoice/list',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        export:1,
                    }),
                    success: function(data, status, jQxhr) {
    
                        $('.ui.accordion').accordion('refresh');
                        for (var i = 0; i < data.data.data.length; i++) {
                            var item = {};
                            data.data.data[i].invoice_id = Number(data.data.data[i].invoice_id) + 20000;
                            item.invoice_id = data.data.data[i].invoice_id;
                            item.amount = data.data.data[i].amount;
                            item.status = data.data.data[i].status_description;
                            item.description = data.data.data[i].description;
                            item.created_at = data.data.data[i].created_at;
    
                            exportRecipients.push(item);
                        }
    
                        var exportDate = moment().format('DD_MMMM_YYYY_h:mm');
    
                        vm.JSONToCSVConvertor(exportRecipients, "All_Transaction "+exportDate, 1);
    
                    },
                    error: function(jQxhr, status, error) {
    
                        var error = jQxhr.responseJSON.statusDescription;
                        if (error) {
                            alertify.error(error);
                        } else {
                            alertify.error("An error occurred please try again  ");
                        }
    
                        return;
                    }
                });
            },
            JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
                //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
                var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    
                var CSV = '';
                //Set Report title in first row or line

                if (ShowLabel) {
                    var row = "";
    
                    //This loop will extract the label from 1st index of on array
                    for (var index in arrData[0]) {
    
                        //Now convert each value to string and comma-seprated
                        row += index + ',';
                    }
    
                    row = row.slice(0, -1);
    
                    //append Label row with line break
                    CSV += row + '\r\n\n\n';
                }
    
                //1st loop is to extract each row
                for (var i = 0; i < arrData.length; i++) {
                    var row = "";
    
                    //2nd loop will extract each column and convert it in string comma-seprated
                    for (var index in arrData[i]) {
                        row += '"' + arrData[i][index] + '",';
                    }
    
                    row.slice(0, row.length - 1);
    
                    //add a line break after each row
                    CSV += row + '\r\n';
                }
    
                if (CSV == '') {
                    alert("Invalid data");
                    return;
                }
    
                //Generate a file name
                var fileName = "VasPro_";
                //this will remove the blank-spaces from the title and replace it with an underscore
                fileName += ReportTitle.replace(/ /g, "_");
    
                //Initialize file format you want csv or xls
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    
                // Now the little tricky part.
                // you can use either>> window.open(uri);
                // but this will not work in some browsers
                // or you will not get the correct file extension
    
                //this trick will generate a temp <a /> tag
                var link = document.createElement("a");
                link.href = uri;
    
                //set the visibility hidden so it will not effect on your web-layout
                link.style = "visibility:hidden";
                link.download = fileName + ".csv";
    
                //this part will append the anchor tag and remove it after automatic click
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            completePaymentInvoice(invoice){
                console.log(JSON.stringify(invoice));
                store.commit("setInvoiceSelect",invoice);

                $('.ui.modal#modal-invoice_pay').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
             cancelPaymentInvoice(invoice){
                console.log(JSON.stringify(invoice));
                store.commit("setInvoiceSelect",invoice);

                $('.ui.modal#modal-invoice_cancel').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
        }
    }
</script>
