<template>
    <div>
        <div class="q-responses">{{responseCount}} of {{totalContacts}} people answered this question</div>
        <div class="q-option text">
            <div class="option" v-if="questionTypeId ==1">
                <span class="t">T</span>
                <span class="w">Open ended input</span>
            </div>
        </div>
        <div class="q-option options res" v-if="questionTypeId == 2">
            <div class="option" v-for="(option, index) in optionList" :key="option.id+index">
                <div class="s">
                <span class="p"  :style="{width: option.progressRate + '%'}"></span>
                <span class="t">{{index + 1}}</span>
                <span class="w">{{option.answer}}</span>
                <span class="r">{{option.totalresponse}} Responses</span>
                </div>
                <div class="c">{{option.progressRate}}%</div>
            </div>
        </div>
         <div class="q-option bool block" v-if="questionTypeId == 3">
            <div class="option" v-for="(option, index) in optionList" :key="option.id+index">
                <span class="p" :style="{width: option.progressRate + '%'}"></span>
                <span class="t">{{option.symbol}}</span>
                <span class="w">{{option.response}}</span>
                <span class="r">{{option.totalresponse}} responses</span>
            </div>
            
        </div>
        <div class="q-option bool block" v-if="questionTypeId == 4">
            <div class="option" v-for="(option, index) in optionList" :key="option.id+index">
                <span class="p" :style="{width: option.progressRate + '%'}"></span>
                <span class="t">{{option.answer}}</span>
                <span class="w"></span>
                <span class="r" >{{option.totalresponse}}  responses</span>
            </div>
        </div>
         <div class="q-option bool block" v-if="questionTypeId == 5">
            <div class="option" v-for="(option, index) in optionList" :key="option.id+index">
                <div class="s">
                <span class="p"  :style="{width: option.progressRate + '%'}"></span>
                <span class="t">{{index + 1}}</span>
                <span class="w">{{option.answer}}</span>
                <span class="r">{{option.totalresponse}} Responses</span>
                </div>
                <div class="c">{{option.progressRate}}%</div>
            </div>
        </div>
    </div>
</template>
<script>
    import store from "@/store";
    import alertify from 'alertifyjs';
    import loader from "@/components/loader";
    import format from 'date-fns/format';
    import moment from "moment";
import { stringify } from 'querystring';

    export default {
        data() {
            return {
                questionnaireID: "",
                totalQuestion: 0,
                offset: 0,
                limit: 10,
                loading: false,
                optionList: [],
                optionRate:[],
                totalResponse:0,
                totalRespondent:0,
                appId:""
                
                
            }
        },
        props: ['options','questionID','questionTypeId','indexN','question','appIDS','totalContacts','responseCount'],
        components: {
            loader,
        },
        
        mounted() {
            let vm = this;
            vm.appId = store.state.selectSurvey.app_id;
            vm.getAllResponse();
            vm.questionnaireID = store.state.selectSurvey.questionnare_id;
            
           console.log("Option List "+JSON.stringify(vm.optionList));
        },
       
        methods: {
           getAllResponse(){
               let vm = this;
               vm.totalResponse = 0;
               $.post({
                url: store.state.rootURLBulkAPI + "v3/survey/response/data",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        questionID: vm.questionID,
                        app_id: vm.appId
                }),
                success: function(response, status, jQxhr) {

                    var questionsArray = "";
                    if (response.data.code != 200) {
                        alertify.set('notifier', 'position', 'top-right');
                        //alertify.error(response.data.message);

                    } else {
                        var resp =response.data.data;
                        vm.totalRespondent  = resp[0].totalContacts;
                        if(vm.questionTypeId ==4){
                            if(resp[0].totalresponse > resp[0].totalContacts ){
                                resp[0].progressRate = 100;
                            }
                            else{
                                resp[0].progressRate = resp[0].totalContacts !=0 ? ((parseInt(resp[0].totalresponse) / parseInt(vm.totalRespondent)) *100).toFixed(0) :0;
                                console.log("Rate % "+ resp[0].progressRate)
                            }
                            vm.optionRate.push(resp[0]);
                        }
                        for (var i = 0; i < resp.length; i++) {
   
                            resp[i].progressRate = resp[i].totalContacts !=0 ? ((parseInt(resp[i].totalresponse) / parseInt(vm.totalRespondent)) *100).toFixed(0) :0;
                            
                            console.log(JSON.stringify("Progress "+((parseInt(resp[i].totalresponse) / parseInt(vm.totalRespondent)) *100).toFixed(0)));
                               var symbol = resp[i].answer;
                               resp[i].symbol = symbol.substring(0, 1);

                           vm.totalResponse = vm.totalResponse + parseInt(resp[i].totalresponse)
                           vm.optionList.push(resp[i]);
                        }
                        
                        //console.log(JSON.stringify(vm.optionList));
                       // console.log(vm.optionList[0].totalContacts);

                        setTimeout(function() {
                            $(".ui.dropdown").dropdown('refresh');
                        }, 100)

                    }
                },
                error: function(jQxhr, status, error) {
                   // alertify.set('notifier', 'position', 'top-right');
                   // alertify.error(jQxhr.responseJSON.statusDescription);
                }
            });
           }
            
        },
        filters: {
            formatDate: function(value) {
                if (!value) return ''
                return moment(value).fromNow()
            }
        }
    
    }
</script>
