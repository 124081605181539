<template>
  <div class="ui relaxed padded grid">
    <div class="padding-top-md"></div>
    <div class="ui negative message" v-show="MessangeStatus">
      {{ StatusMessageDesc }}
    </div>
    <div class="row">
      <div class="column">
        <select class="ui fluid dropdown" v-model="senderId">
          <option value="">From</option>
          <option
            v-for="s_id in dataLists"
            v-show="dataLists.length > 0 && s_id.status == 13"
            :key="s_id.short_code"
            :value="s_id.short_code"
          >
            {{ s_id.short_code }}
          </option>
          <option v-if="dataLists.length == 0" value="VasPro">VasPro</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <select
          id="contactsBlock"
          required
          multiple="multiple"
          placeholder="Type contact or select from list"
          v-model="contactListData"
        ></select>
      </div>
    </div>
    <div class="row" v-show="showUploadSection">
      <div class="column" id="droppedFiles">
        <div class="ui right labeled icon label">
          {{ filename }}<i class="icon close" @click="closeUpload()"></i>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <div class="ui top attached segment">
          <textarea
            rows="5"
            placeholder="Type your SMS here."
            v-model="composedMessage"
          ></textarea>
        </div>
        <div class="ui bottom attached segment bg-grey-light">
          <div class="ui equal width grid">
            <div class="column">
              <small>{{ messageCount }} characters </small>
            </div>
            <div class="right aligned column">
              <small>{{ messagePages }} SMS</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <div class="ui form">
          <div class="two fields">
            <div class="field">
              <div class="ui toggle checkbox">
                <input type="checkbox" name="schedule" v-model="scheduleMessage" />
                <label><strong>Schedule Message </strong></label>
              </div>
            </div>
          </div>
          <div class="two fields">
            <div class="field" v-show="scheduleMessage">
              <label>Date</label>
              <div class="ui calendar" id="scheduleDate">
                <div class="ui input left icon">
                  <i class="calendar icon"></i>
                  <input type="text" placeholder="Select date" ref="scheduledDate" />
                </div>
              </div>
            </div>
            <div class="field" v-show="scheduleMessage">
              <label>Time</label>
              <div class="ui calendar" id="scheduleTime">
                <div class="ui input left icon">
                  <i class="time icon"></i>
                  <input type="text" ref="scheduledTime" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ui equal width grid">
          <div class="computer only column">
            <button
              v-show="scheduleMessage && !saveTamplate"
              @click="sendMessage"
              :disabled="!sendStatus"
              class="ui bg-accent icon right floated labeled button"
              id="button-schedule-message-compute"
            >
              <i class="icon send"></i> Schedule Blast Message
            </button>
            <button
              v-show="!scheduleMessage && !saveTamplate"
              @click="sendMessage"
              :disabled="!sendStatus"
              class="ui bg-secondary text-white right floated button"
              id="button-send-message-compute"
            >
              Send Blast Message
            </button>
          </div>
          <div class="tablet only mobile only column">
            <div class="padding-md"></div>
            <button
              v-show="scheduleMessage && !saveTamplate"
              @click="sendMessage"
              :disabled="!sendStatus"
              class="ui bg-accent fluid button"
              id="button-schedule-message-mobile"
            >
              Schedule Blast Message
            </button>
            <button
              v-show="!scheduleMessage && !saveTamplate"
              @click="sendMessage"
              :disabled="!sendStatus"
              class="ui fluid button bg-secondary text-white"
              id="button-send-message-mobile"
            >
              Send Blast Message
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
textarea {
  width: 100%;
  border: none;
  outline: none;
  font-size: 1.4em;
  &::placeholder {
    color: #ccc;
  }
}

.row {
  padding-top: 0 !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import bulkstore from "@/bulk/bulksms-state";
import Choices from "choices.js";
import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
import format from "date-fns/format";
import moment from "moment";
import Vue from "vue";
import alertify from "alertifyjs";
import _ from "lodash";
import Dropzone from "dropzone";

export default {
  data() {
    return {
      scheduleMessage: false,
      sendWhatsApp: false,
      saveTamplate: false,
      contactsOptions: null,
      dateFormat: "D MMM",
      whatsAppType: "TEXT",
      composedMessage: "",
      composeTotalCharacters: 0,
      templateTitle: "",
      dateOne: "",
      dateTwo: "",
      senderId: "",
      contactListData: [],
      contactGroup: [],
      phoneGroup: [],
      numberContainer: [],
      formattedDates: "",
      schedule_time: "",
      isScheduled: 0,
      scheduledDate: "",
      totalSenderID: 0,
      uploadFiles: null,
      uploadWhatsAppFiles: null,
      dataLists: [],
      templateLists: [],
      showUploadSection: false,
      contactListBlock: null,
      contactListBlockElement: null,
      arrayList: null,
      responseStatus: false,
      responseStatusMessage: "",
      scheduledTime: "",
      placeholders: "",
      showPlaceholders: false,
      holder: "",
      contactNumber: [],
      contactList: [],
      sendCount: 0,
      MessangeStatus: false,
      StatusMessageDesc: "",
      selectTemplate: null,
      template: "",
      saveTemplateStatus: true,
      filename: "",
      endMessage: "",
      sendStatus: true,
      whatsAppKeyword: [],
      showWhatsAppOption: false,
      whatsAppLink: false,
      templateID: "",
      latitude: "",
      longitude: "",
      address: "",
    };
  },
  beforeCreate() {
    let vm = this;
    $.post({
      url: store.state.rootUrl + "/blast/get/groups",
      type: "POST",
      data: JSON.stringify({
        apiKey: this.$cookies.get("key"),
      }),
      success: function (response, status, jQxhr) {
        var arrayList = [];
        var customList = [];
        var customListAdd = "";
        if (response.data.data != null) {
          var lists = response.data.data;
          var i = 0;
          lists.forEach((list) => {
            var listData = {
              value: list,
              label: list,
              selected: false,
              disabled: false,
            };

            var placeHd = {
              value: list,
              custom: list,
            };
            customList.push(placeHd);

            arrayList.push(listData);
            list = "";
            i = i + 1;
          });
          vm.contactListBlockElement = document.getElementById("contactsBlock");
          vm.contactListBlock = new Choices(vm.contactListBlockElement, {
            duplicateItemsAllowed: false,
            renderChoiceLimit: 3,
            delimiter: ",",
            removeItemButton: true,
            addItems: true,
            addChoices: true,
            choices: arrayList,
          });
          var contactNumbersArray = [];
          var contactListArray = [];
          vm.contactListBlockElement.addEventListener(
            "addItem",
            function (event) {
              vm.showPlaceholders = false;
              var data = event.detail.value;
              vm.contactListData.push(data);
              var listContactData = vm.contactListData;
              listContactData.forEach(function (element) {
                if (element.length >= 9 && element.length <= 12) {
                  vm.showPlaceholders = false;
                  contactNumbersArray.push(element);
                } else {
                  contactListArray.push(element);
                }
              });
            },
            false
          );
          vm.contactListBlockElement.addEventListener(
            "removeItem",
            function (event) {
              let valIndex = _.indexOf(vm.contactGroup, event.detail.value);
              vm.phoneGroup = vm.contactGroup.splice(valIndex, 1);
              var listContactData = vm.contactListData;
              listContactData.forEach(function (element) {
                if (element.length >= 9 && element.length <= 12) {
                  vm.showPlaceholders = false;
                  contactNumbersArray.push(element);
                } else {
                  contactListArray.push(element);
                  console.log("Contact list lenght: " + vm.contactListData.length);
                }
              });

              console.log("new length " + vm.contactListData.length);
            },
            false
          );
        }
      },
      error: function (jQxhr, status, error) {
        return [];
      },
    });
  },
  mounted() {
    let vm = this;
    if (!vm.$cookies.get("key") || vm.$cookies.get("key") == null) {
      vm.$router.push({
        name: "home",
      });
    }

    if (store.getters.compMessage) {
      this.composedMessage = store.getters.compMessage;
    }
    // template list

    $(".ui.dropdown").dropdown();

    vm.fetchData();

    const datepickerOptions = {};
    Vue.use(AirbnbStyleDatepicker, datepickerOptions);

    $("#scheduleDate").calendar({
      type: "date",
    });
    $("#scheduleTime").calendar({
      type: "time",
    });
  },
  computed: {
    messageCount() {
      //var defaultCount = 14;
      var phoneNu = "";
      phoneNu = window.atob(this.$cookies.get("username"));
      var phone = "";

      if (this.dataLists.length > 0) {
        this.endMessage = "";
        this.composeTotalCharacters =
          this.endMessage.length + this.composedMessage.length;
      } else {
        this.endMessage = "";
        this.composeTotalCharacters =
          this.endMessage.length + 18 + this.composedMessage.length;
      }

      return this.composeTotalCharacters;
    },
    messagePages() {
      if (this.composedMessage.length > 0) {
        $("#textarea-sms").removeClass("red");
      }

      if (this.composeTotalCharacters == 0) {
        return 0;
      } else if (this.composeTotalCharacters / 160 <= 1) {
        return 1;
      } else if (this.composeTotalCharacters / 160 <= 2) {
        return 2;
      } else if (this.composeTotalCharacters / 160 <= 3) {
        return 3;
      } else if (this.composeTotalCharacters / 160 <= 4) {
        return 4;
      } else if (this.composeTotalCharacters / 160 <= 5) {
        return 5;
      } else {
        return " Max ";
      }
    },
  },
  methods: {
    closeUpload() {
      let vm = this;
      vm.showUploadSection = false;
      vm.fileToUpload = "";
      $("#droppedFiles").html();
    },
    formatDates(dateOne, dateTwo) {
      let formattedDates = "";
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat);
      }
      if (dateTwo) {
        formattedDates += " - " + format(dateTwo, this.dateFormat);
      }
      return formattedDates;
    },
    sendMessage() {
      let vm = this;
      if (vm.scheduleMessage) {
        $("#button-schedule-message-mobile").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Scheduling Message ...'
        );
        $("#button-schedule-message-compute-save").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Scheduling Message ...'
        );
        $("#button-schedule-message-compute").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Scheduling Message ...'
        );
        $("#button-schedule-message-mobile-save").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Scheduling Message ...'
        );
      } else {
        $("#button-send-message-mobile").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Sending Message ...'
        );
        $("#button-send-message-mobile-save").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Sending Message ...'
        );
        $("#button-send-message-compute").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Sending Message ...'
        );
        $("#button-send-message-compute-save").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Sending Message ...'
        );
      }
      vm.sendStatus = false;

      if (!vm.composedMessage) {
        alertify.error("Message is required");
        vm.MessangeStatus = true;
        vm.StatusMessageDesc = "Message is required. Kindly enter Message";
      }
      if (!vm.contactListData) {
        alertify.error("Contact or select from list is required");
        vm.MessangeStatus = true;
        vm.StatusMessageDesc = "Contact or select from list is required";
      }
      var listContactData = vm.contactListData;

      if (vm.scheduleMessage) {
        vm.isScheduled = 1;
      }

      // slice array based on size
      var contactNumbersArray = [];
      var contactListArray = [];
      listContactData.forEach(function (element) {
        contactListArray.push(element);
      });

      // phone number list
      var contactNumber = contactNumbersArray.join();
      var contactList = contactListArray.join();
      if (contactList.length != "") {
        var dt = new Date();
        vm.composedMessage = vm.composedMessage.split("<br>").join("\n");
        var messageText = !vm.composedMessage.endsWith(vm.endMessage)
          ? vm.composedMessage + "" + vm.endMessage
          : vm.composedMessage;
        var messageEdited = messageText.split("\n").join("{line}");
        $.post({
          url: bulkstore.state.bulksmsUrl + "/blast/send/group",
          type: "POST",
          data: JSON.stringify({
            apiKey: vm.$cookies.get("key"),
            shortCode: vm.senderId,
            message: messageEdited,
            approval: "",
            uniqueId: dt.getUTCMilliseconds(),
            blast_name: contactList,
            scheduleDate: moment(this.$refs.scheduledDate.value).format("YYYY-MM-DD"),
            scheduleTime: moment(this.$refs.scheduledTime.value, "h:mm A").format(
              "HH:mm:ss"
            ),
            isScheduled: vm.isScheduled,
            callbackURL: "",
            templateID: vm.templateID,
          }),
          success: function (data, status, jQxhr) {
            vm.contactListData = [];
            vm.composedMessage = "";
            alertify.set("notifier", "position", "top-right");
            alertify.success(data.data.message);
            vm.$router.push({
              name: "bulk-messages",
            });
            vm.$parent.showCreateBlast = false;
            if (vm.showUploadSection == false) {
              vm.$router.push({
                name: "bulk-messages",
              });
            }
          },
          error: function (jQxhr, status, error) {
            alertify.set("notifier", "position", "top-right");
            vm.MessangeStatus = true;
            alertify.error(jQxhr.responseJSON.statusDescription);
            if (jQxhr.responseJSON.data.message) {
              vm.StatusMessageDesc =
                " " +
                jQxhr.responseJSON.statusDescription +
                ":  " +
                jQxhr.responseJSON.data.message;
            } else {
              vm.StatusMessageDesc = jQxhr.responseJSON.statusDescription;
            }
            vm.getMessangeStatus();
          },
        });
      }

      if (contactNumber.length == "" && contactList.length == "") {
        vm.MessangeStatus = true;
        vm.StatusMessageDesc = "Error Contact Required: Kindly enter or Select Contact";
        alertify.error(vm.StatusMessageDesc);
        vm.getMessangeStatus();
      }
    },
    fetchData() {
      var vmBalance = this;
      var vm = this;
      $.post({
        url: bulkstore.state.bulksmsUrl + "senderId/list",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
          status: 13,
          p_status: 4,
          type_id: 3,
        }),
        success: function (response, status, jQxhr) {
          vm.dataLists = response.data.data;
          console.log(JSON.stringify(vm.dataLists));
        },
        error: function (jQxhr, status, error) {
          alertify.set("notifier", "position", "top-right");
          alertify.error(jQxhr.responseJSON.data.message);
        },
      });
    },

    getMessangeStatus() {
      let vm = this;
      if (vm.MessangeStatus == true) {
        $("#button-send-message-mobile").html("Send Message");
        $("#button-send-message-mobile-save").html("Send Message");
        $("#button-send-message-compute").html("Send Message");
        $("#button-send-message-compute-save").html("Send Message");

        $("#button-schedule-message-mobile").html("Schedule Message");
        $("#button-schedule-message-compute-save").html(
          '<i class="icon send"></i> Schedule Message'
        );
        $("#button-schedule-message-compute").html(
          '<i class="icon send"></i> Schedule Message'
        );
        $("#button-schedule-message-mobile-save").html("Schedule Message");
        vm.sendStatus = true;
      }
    },
  },

  watch: {
    contactGroup: function (val, old) {
      let vm = this;
      console.log("Val | " + val);
      vm.contactGroup = val;
    },
  },
};
</script>
