<template>
    <div class="l-app-main bg-white">
        <div class="l-app-dashboard bg-white">
            <div class="ui equal width relaxed grid padded bg-primary-lighter">
                <div class="middle aligned row">
                    <div class="column">
                        <h3 class="text-medium">All Payments</h3>
                    </div>
                    <div class="right aligned column">
                        <router-link :to="{name: 'create-payments-bulk'}" class="ui bg-primary button">Create Bulk Payment</router-link>
                    </div>
                </div>
                <div class="row hidden">
                    <div class="column">
                        <div class="ui horizontal segments">
                            <div class="ui relaxed padded segment">
                                <h3>Total Paid</h3>
                                <h2>Ksh. 38,910</h2>
                            </div>
                            <div class="ui relaxed padded segment">
                                <h3>Total Paid</h3>
                                <h2>Ksh. 38,910</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ui secondary pointing fluid menu">
                    <a href="#" class="active item">All Payments</a>
                    <a href="#" class="item">Scheduled</a>
                    <a href="#" class="item">Pending</a>
                    <a href="#" class="item">Drafts</a>
                </div>
            </div>
            <div class="ui relaxed padded grid">
                <div class="row">
                    <div class="column">
                        <table class="ui styled celled table">
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Transaction ID</th>
                                    <th>Date</th>
                                    <th>Customers</th>
                                    <th>Invoice</th>
                                    <th class="right aligned">Total Payout</th>
                                    <th class="right aligned">Payment Made</th>
                                    <th class="center aligned text-grey"><i class="fa fa-ellipsis-h"></i></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr @click="bulkPaymentsDetails()">
                                    <td class="collapsing"><span class="ui green label">Paid</span></td>
                                    <td class="">12345</td>
                                    <td class="">23 May 2019</td>
                                    <td class="">345</td>
                                    <td class="">#12345</td>
                                    <td class="right aligned">Ksh. 192,991</td>
                                    <td class="right aligned">Ksh. 123,920</td>
                                    <td class="center aligned collapsing"><button class="ui icon tiny button"><i class="dropdown icon"></i></button></td>
                                </tr>
                                <tr @click="bulkPaymentsDetails()">
                                    <td class="collapsing"><span class="ui orange label">Scheduled</span></td>
                                    <td class="">12345</td>
                                    <td class="">23 May 2019</td>
                                    <td class="">345</td>
                                    <td class="">#12345</td>
                                    <td class="right aligned">Ksh. 192,991</td>
                                    <td class="right aligned">Ksh. 123,920</td>
                                    <td class="center aligned collapsing"><button class="ui icon tiny button"><i class="dropdown icon"></i></button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        bulkPaymentsDetails(){
            let vm = this;
            vm.$router.push({name: 'bulk-payments-details'});
        }
    }
}
</script>
