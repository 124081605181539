<template>
  <div class="l-app-main">
    <div class="l-app-main-inboxzero bg-primary-lighter" v-show="inboxzero">
      <h2>No Paybills</h2>
      <p>Aggregate a paybill or till number you own<br />to view transactions here</p>
      <div class="padding-sm"></div>
      <button class="ui large bg-primary button">Aggregate Paybill / Till</button>
      <div class="padding-xl"></div>
    </div>
    <div class="l-app-main-content" v-show="!inboxzero">
      <div class="ui grid">
        <div class="row">
          <div class="column">
            <loader v-show="loadingGet == true"></loader>
            <div class="ui grid" v-show="loadingGet != true">
              <div class="row">
                <div
                  class="four wide computer eight wide tablet eight wide mobile column"
                >
                  <h3>All Transactions</h3>
                  <h5 class="text-lightgrey">from all accounts</h5>
                  <small>{{ formatDates(dateOne, dateTwo) }}</small>
                </div>
                <div class="twelve wide computer only right aligned column">
                  <select
                    class="ui dropdown"
                    v-model="selectPaybill"
                    @change="changedValue()"
                  >
                    <option value="">Select Paybill</option>
                    <option value="ALL">ALL</option>
                    <option
                      v-for="paybill in paybills"
                      v-bind:value="paybill.paybill"
                      v-bind:key="paybill.paybill"
                    >
                      <span v-if="paybill.pStatus != -5">{{ paybill.paybill }}</span>
                    </option>
                  </select>
                  <div class="ui small input datepicker-trigger margin-left-xs">
                    <input
                      type="text"
                      id="datepicker-trigger"
                      placeholder="Select date"
                      :value="formatDates(dateOne, dateTwo)"
                    />
                    <AirbnbStyleDatepicker
                      :trigger-element-id="'datepicker-trigger'"
                      :mode="'range'"
                      :fullscreen-mobile="true"
                      :months-to-show="2"
                      :date-one="dateOne"
                      :date-two="dateTwo"
                      :close-after-select="false"
                      @date-one-selected="
                        (val) => {
                          dateOne = val;
                        }
                      "
                      @date-two-selected="
                        (val) => {
                          dateTwo = val;
                        }
                      "
                      @apply="loadDataMain()"
                    />
                  </div>
                </div>
                <div class="eight wide tablet only eight wide mobile only column">
                  <select
                    class="ui fluid dropdown"
                    v-model="selectPaybill"
                    @change="changedValue()"
                  >
                    <option value="ALL">ALL</option>
                    <option
                      v-for="paybill in paybills"
                      v-bind:value="paybill.paybill"
                      v-bind:key="paybill.paybill"
                    >
                      <span v-if="paybill.pStatus != -5">{{ paybill.paybill }}</span>
                    </option>
                  </select>
                </div>
              </div>
              <loader v-show="loading == true"></loader>
              <div class="ui negative message" v-show="displayTransStatus">
                {{ displayTransDesc }}
              </div>
              <div class="equal width row" v-show="displayRecordStatus">
                <div class="computer only tablet only column">
                  <div class="ui equal width middle aligned grid">
                    <div class="column">
                      <h3 class="text-medium">{{ totalTransactions }} transactions</h3>
                    </div>
                    <div class="right aligned column">
                      <div class="ui search link small input">
                        <input
                          type="text"
                          placeholder="Search First Name"
                          v-on:keyup="loadDataMain(false)"
                          v-model="s_first_name"
                        />
                      </div>
                    </div>
                    <div class="right aligned column">
                      <div class="ui search link small input">
                        <input
                          type="text"
                          placeholder="Search Last Name"
                          v-on:keyup="loadDataMain(false)"
                          v-model="s_surname"
                        />
                      </div>
                    </div>
                    <div class="right aligned column">
                      <div class="ui search link small input">
                        <input
                          type="text"
                          placeholder="Search Amount"
                          v-on:keyup="loadDataMain(false)"
                          v-model="s_amount"
                        />
                      </div>
                    </div>
                    <div class="right aligned column">
                      <div class="ui search link small input">
                        <input
                          type="text"
                          placeholder="Search Transation Id"
                          v-on:keyup="loadDataMain(false)"
                          v-model="s_receipt_number"
                        />
                      </div>
                    </div>
                    <div class="right aligned column">
                      <div class="ui search link small input">
                        <input
                          type="text"
                          placeholder="Search Account Number"
                          v-on:keyup="loadDataMain(false)"
                          v-model="s_account_number"
                        />
                      </div>
                    </div>
                    <div class="right aligned column">
                      <div class="ui search link small input">
                        <input
                          type="text"
                          placeholder="Search Phone Number"
                          v-on:keyup="loadDataMain(false)"
                          v-model="s_msisdn"
                        />
                      </div>
                    </div>
                  </div>
                  <table class="ui celled styled unstackable table">
                    <thead>
                      <tr>
                        <th class="center aligned">#</th>
                        <th>Name</th>
                        <th>Paybill/Till</th>
                        <th>Transaction&nbsp;ID</th>
                        <th>Account</th>
                        <th>Amount</th>
                        <th>Payment Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(transaction, index) in transactions"
                        :key="transaction.payment_reference"
                      >
                        <td class="collapsing">{{ index + 1 }}</td>
                        <td>
                          <h4 class="text-medium">
                            {{ transaction.first_name }} {{ transaction.surname }}
                            {{ transaction.other_name }}
                          </h4>
                          <small>{{ transaction.msisdn }}</small>
                        </td>
                        <td>{{ transaction.paybill }}</td>
                        <td>{{ transaction.reciept_number }}</td>
                        <td>{{ transaction.account_number }}</td>
                        <td>
                          <h4 class="text-medium text-primary">
                            {{ transaction.currency }} {{ transaction.amount }}
                          </h4>
                        </td>
                        <td>{{ transaction.created_at }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    v-show="!loading"
                    @click="loadData"
                    class="ui icon large labeled button"
                  >
                    <i class="icon refresh"></i> Load More
                  </div>
                  <div
                    @click="generateExport()"
                    class="ui bg-accent icon large labeled button"
                    id="button-export"
                    :disabled="downloadStatus"
                  >
                    <i class="icon download"></i> Download
                  </div>
                  <loader v-show="loading"></loader>
                </div>
                <div class="mobile only column">
                  <div class="right aligned column">
                    <div class="ui search link small input">
                      <input
                        type="text"
                        placeholder="Search First Name"
                        v-on:keyup="loadDataMain(false)"
                        v-model="s_first_name"
                      />
                    </div>
                  </div>
                  <div class="right aligned column">
                    <div class="ui search link small input">
                      <input
                        type="text"
                        placeholder="Search Transation Id"
                        v-on:keyup="loadDataMain(false)"
                        v-model="s_receipt_number"
                      />
                    </div>
                  </div>
                  <div class="right aligned column">
                    <div class="ui search link small input">
                      <input
                        type="text"
                        placeholder="Search Account Number"
                        v-on:keyup="loadDataMain(false)"
                        v-model="s_account_number"
                      />
                    </div>
                  </div>
                  <div class="right aligned column">
                    <div class="ui search link small input">
                      <input
                        type="text"
                        placeholder="Search Second Name"
                        v-on:keyup="loadDataMain(false)"
                        v-model="s_surname"
                      />
                    </div>
                  </div>
                  <div class="right aligned column">
                    <div class="ui search link small input">
                      <input
                        type="text"
                        placeholder="Search Amount"
                        v-on:keyup="loadDataMain(false)"
                        v-model="s_amount"
                      />
                    </div>
                  </div>
                  <div class="right aligned column">
                    <div class="ui search link small input">
                      <input
                        type="text"
                        placeholder="Search Phone Number"
                        v-on:keyup="loadDataMain(false)"
                        v-model="s_msisdn"
                      />
                    </div>
                  </div>

                  <table class="ui celled styled unstackable table">
                    <thead>
                      <tr>
                        <th class="center aligned collapsing">#</th>
                        <th>Details</th>
                        <th class="right aligned">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(transaction, index) in transactions"
                        :key="transaction.payment_reference"
                      >
                        <td class="collapsing">{{ index + 1 }}</td>
                        <td>
                          <h4 class="text-medium">
                            {{ transaction.first_name }} {{ transaction.surname }}
                            {{ transaction.other_name }}
                          </h4>
                          <div>{{ transaction.msisdn }}</div>
                          <small>{{ transaction.paybill }}</small>
                          <small> | {{ transaction.account_number }}</small>
                          <small> | {{ transaction.reciept_number }}</small>
                        </td>
                        <td class="right aligned">
                          <h4 class="text-medium text-primary">
                            {{ transaction.currency }} {{ transaction.amount }}
                          </h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    v-show="!loading"
                    @click="loadData"
                    class="ui icon large labeled button"
                  >
                    <i class="icon refresh"></i> Load More
                  </div>
                  <div
                    @click="generateExport()"
                    class="ui bg-accent icon large labeled button"
                    id="button-export"
                    :disabled="downloadStatus"
                  >
                    <i class="icon download"></i> Download
                  </div>
                  <loader v-show="loading"></loader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import format from "date-fns/format";
import moment from "moment";
import store from "@/store";
import paymentstore from "@/payments/payments-state";
import loader from "@/components/loader";
import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
import alertify from "alertifyjs";

export default {
  data() {
    return {
      inboxzero: false,
      client_id: "",
      start: "",
      date_range: "",
      end: "",
      start_: "",
      end_: "",
      dateOne: "",
      dateTwo: "",
      dateFormat: "DD MMM",
      displayRecordStatus: false,
      totalTransactions: 0,
      transactions: [],
      paybills: [],
      limit: 10,
      offset: 0,
      order: "",
      displayTransStatus: false,
      loadingGet: false,
      busy: false,
      displayTransDesc: "",
      search_filter: "",
      paybill: "",
      loading: false,
      s_msisdn: "",
      s_first_name: "",
      s_receipt_number: "",
      s_invoice_type: "",
      s_surname: "",
      s_amount: "",
      selectPaybill: "",
      s_account_number: "",
      downloadStatus: false,
    };
  },
  components: {
    loader,
  },
  mounted() {
    let vm = this;
    if (!vm.$cookies.get("key") || vm.$cookies.get("key") == null) {
      vm.$router.push({
        name: "home",
      });
    }
    $(".ui.dropdown").dropdown();
    var d = new Date();
    this.dateTwo = format(d.setDate(d.getDate()), this.dateFormatTwo);
    this.dateOne = format(d.setDate(d.getDate() - 7), this.dateFormatTwo);

    //this.getDateRange(this.start_,this.end_);
    this.getPaybills();
    if (this.$route.params.id != "" && !isNaN(this.$route.params.id)) {
      this.selectPaybill = this.$route.params.id;
      this.loadDataMain();
    }
  },
  computed: {
    time_span() {
      if (this.group_by === "day") {
        return "Today";
      } else {
        return " This " + this.group_by[0].toUpperCase() + this.group_by.slice(1);
      }
    },
  },
  watch: {
    group_by(val, oldVal) {
      this.loadData();
    },
    date_range(val, oldVal) {
      var daterange = val + "";

      this.start_ = moment(daterange.substring(0, daterange.indexOf(","))).format(
        "DD MMM YYYY"
      );
      this.end_ = moment(daterange.substring(daterange.indexOf(",") + 1)).format(
        "DD MMM YYYY"
      );

      this.getDateRange(this.start, this.end);
    },
  },
  methods: {
    changedValue: function (value) {
      let vm = this;
      vm.loadDataMain();
      //receive the value selected (return an array if is multiple)
    },
    search() {
      var vm = this;
      if (vm.search_filter.length > 0) {
        vm.transactions = [];
        vm.totalTransactions = 0;
        if (isNaN(vm.search_filter)) {
          vm.s_msisdn = "";
          vm.s_first_name = vm.search_filter;
          vm.s_receipt_number = vm.search_filter;
          vm.s_invoice_type = vm.search_filter;
        } else {
          vm.s_msisdn = vm.search_filter;
          vm.s_first_name = "";
          vm.s_receipt_number = "";
          vm.s_invoice_type = "";
        }
        this.loadData();
      }
    },
    getPaybills() {
      var vm = this;
      vm.loadingGet = true;
      if (!vm.token) {
        vm.token = vm.$cookies.get("key");
      }
      $.post({
        url: store.state.rootUrl + "payment/paybill/list",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
        }),
        success: function (response, status, jQxhr) {
          if (response.data.code == 404) {
            vm.inboxzero = true;
          }
          vm.paybills = response.data.data;
          vm.loadingGet = false;
        },
        error: function (jQxhr, status, error) {
          vm.loadingGet = false;
        },
      });
    },

    getDateRange(start, end) {
      var ranges = [];

      //creating JS date objects
      var start = new Date(start);
      var end = new Date(end);

      this.start = moment(start).format("DD MMM YYYY");
      this.end = moment(end).format("DD MMM YYYY");

      while (start < end) {
        ranges.push(moment(start).format(this.dateFormat));
        var newDate = start.setDate(start.getDate() + 1);
        start = new Date(newDate);
      }

      this.day_x_axis = ranges;
      this.loadData();
    },
    formatDates(dateOne, dateTwo) {
      let formattedDates = "";
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat);
      }
      if (dateTwo) {
        formattedDates += " - " + format(dateTwo, this.dateFormat);
      }

      return formattedDates;
    },
    loadDataMain(statusData) {
      var vm = this;
      vm.loading = true;
      vm.busy = true;
      vm.displayTransStatus = false;
      var list_c = [];
      if (!statusData) {
        list_c = [];
        vm.transactions = [];
        vm.totalTransactions = 0;
        //vm.displayRecordStatus = false;
        //vm.displayTransStatus = true;
      }
      if (vm.selectPaybill != "") {
        vm.offset = 1;
        $.post({
          url: store.state.rootURLBulkAPI + "v3/payment/mpesa/payments/view",
          type: "POST",
          data: JSON.stringify({
            apiKey: vm.$cookies.get("key"),
            start: vm.dateOne,
            end: vm.dateTwo,
            limit: vm.limit,
            offset: vm.offset,
            client_id: vm.client_id,
            reciept_number: vm.s_receipt_number,
            first_name: vm.s_first_name,
            msisdn: vm.s_msisdn,
            paybill: vm.selectPaybill,
            surname: vm.s_surname,
            amount: vm.s_amount,
            account_number: vm.s_account_number,
          }),
          success: function (data, status, jQxhr) {
            vm.loading = false;
            vm.busy = false;
            if (data.data.code != 404) {
              vm.displayRecordStatus = true;
              vm.displayTransStatus = false;
              vm.totalTransactions = data.data.record_count;
              list_c = data.data.data;

              for (var i = 0; i < list_c.length; i++) {
                list_c[i].payment_date = format(
                  list_c[i].payment_date,
                  "HH:mm a, DD MMM YYYY"
                ); //moment.format(list_c.payment_date,"DD MMM YYYY")
                vm.transactions.push(list_c[i]);
              }
            } else {
              // vm.displayRecordStatus = false;
              vm.displayTransStatus = true;
              if (!statusData && vm.s_msisdn != "") {
                vm.displayTransDesc =
                  "Paybill: " +
                  vm.selectPaybill +
                  " " +
                  data.statusDescription +
                  " for msisdn " +
                  vm.s_msisdn +
                  " for the date between " +
                  vm.dateOne +
                  " and " +
                  vm.dateTwo;
              } else if (!statusData && vm.s_first_name != "") {
                vm.displayTransDesc =
                  "Paybill: " +
                  vm.selectPaybill +
                  " " +
                  data.statusDescription +
                  " for name " +
                  vm.s_first_name +
                  " for the date between " +
                  vm.dateOne +
                  " and " +
                  vm.dateTwo;
              } else if (!statusData && vm.s_receipt_number != "") {
                vm.displayTransDesc =
                  "Paybill: " +
                  vm.selectPaybill +
                  " " +
                  data.statusDescription +
                  " for Trans# " +
                  vm.s_receipt_number +
                  " for the date between " +
                  vm.dateOne +
                  " and " +
                  vm.dateTwo;
              } else {
                vm.displayTransDesc =
                  "Paybill: " +
                  vm.selectPaybill +
                  " " +
                  data.statusDescription +
                  " for date between " +
                  vm.dateOne +
                  " and " +
                  vm.dateTwo;
              }

              //alertify.set('notifier', 'position', 'top-right');
              //alertify.error(data.statusDescription);
            }
            setTimeout(function () {
              $(".ui.dropdown").dropdown();
            }, 100);
          },
          error: function (jQxhr, status, error) {
            vm.loading = false;
            vm.busy = false;
            $(".ui.dropdown").dropdown();
          },
        });
      }
    },
    loadData() {
      var vm = this;
      if (
        vm.totalTransactions == 0 ||
        vm.totalTransactions >= (vm.offset - 1) * vm.limit
      ) {
        vm.loading = true;
        vm.offset++;

        $.post({
          url: store.state.rootURLBulkAPI + "v3/payment/mpesa/payments/view",
          type: "POST",
          data: JSON.stringify({
            apiKey: vm.$cookies.get("key"),
            start: vm.dateOne,
            end: vm.dateTwo,
            limit: vm.limit,
            offset: vm.offset,
            client_id: vm.client_id,
            reciept_number: vm.s_receipt_number,
            invoice_type: vm.s_invoice_type,
            first_name: vm.s_first_name,
            msisdn: vm.s_msisdn,
            paybill: vm.selectPaybill,
            surname: vm.s_surname,
            amount: vm.s_amount,
          }),
          success: function (data, status, jQxhr) {
            vm.loading = false;
            vm.busy = false;

            if (data.data.code != 404) {
              vm.displayRecordStatus = true;
              vm.displayTransStatus = false;
              vm.totalTransactions = data.data.record_count;
              var list_c = data.data.data;
              for (var i = 0; i < list_c.length; i++) {
                list_c[i].payment_date = format(
                  list_c[i].payment_date,
                  "HH:mm a, DD MMM YYYY"
                ); //moment.format(list_c.payment_date,"DD MMM YYYY")
                vm.transactions.push(list_c[i]);
              }
            } else {
              // vm.displayRecordStatus = false;
              vm.displayTransStatus = true;

              vm.displayTransDesc =
                "No more Record found for the date between " +
                vm.dateOne +
                " and " +
                vm.dateTwo;
              alertify.set("notifier", "position", "top-right");
              alertify.error(data.statusDescription);
            }
            setTimeout(function () {
              $(".ui.dropdown").dropdown();
            }, 100);
          },
          error: function (jQxhr, status, error) {
            vm.loading = false;
            vm.busy = false;
            $(".ui.dropdown").dropdown();
          },
        });
      }
    },

    onOpenedMethod() {
      this.dateOne = "";
      this.dateTwo = "";
    },
    onCancelMethod() {
      this.dateOne = "";
      this.dateTwo = "";
    },
    onApplyMethod() {
      if (this.dateOne && this.dateTwo) {
        //this.selectedmessage='';
        this.start_ = this.dateOne;
        this.end_ = this.dateTwo;
        this.start = format(this.dateOne, "DD MMM YYYY");
        this.end = format(this.dateTwo, "DD MMM YYYY");
        this.offset = 0;
        this.limit = 10;
        this.transactions = [];
        this.totalTransactions = 0;
        this.loadData();
      }
    },
    generateExport() {
      $("#button-export").html(
        '<i class="fas fa-circle-notch fa-spin"></i> Download Please Wait ...'
      );
      let vm = this;
      vm.downloadStatus = true;
      var exportRecipients = [];
      $.post({
        url: store.state.rootURLBulkAPI + "v3/payment/mpesa/payments/view",
        type: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key"),
          start: vm.dateOne,
          end: vm.dateTwo,
          export: 1,
          offset: vm.offset,
          client_id: vm.client_id,
          reciept_number: vm.s_receipt_number,
          invoice_type: vm.s_invoice_type,
          first_name: vm.s_first_name,
          msisdn: vm.s_msisdn,
          paybill: vm.selectPaybill,
          surname: vm.s_surname,
          amount: vm.s_amount,
        }),
        success: function (response, status, jQxhr) {
          $(".ui.accordion").accordion("refresh");
          for (var i = 0; i < response.data.data.length; i++) {
            var item = {};
            item.msisdn = response.data.data[i].msisdn;
            item.first_name = response.data.data[i].first_name;
            item.surname = response.data.data[i].surname;
            item.other_name = response.data.data[i].other_name;
            item.reciept_number = response.data.data[i].reciept_number;
            item.account_number = response.data.data[i].account_number;
            item.currency = response.data.data[i].currency;
            item.amount = response.data.data[i].amount;
            item.payment_date = response.data.data[i].payment_date;
            item.payer_narration = response.data.data[i].payer_narration;
            item.organisation_name = response.data.data[i].organisation_name;
            item.paybill = response.data.data[i].paybill;
            item.org_acc_balance = response.data.data[i].org_acc_balance;
            exportRecipients.push(item);
          }

          var exportDate = moment().format("DD_MMMM_YYYY_h:mm");

          vm.JSONToCSVConvertor(
            exportRecipients,
            "Paybill: " + vm.selectPaybill,
            +"_" + exportDate,
            1
          );
          $("#button-export").html('<i class="icon download"></i> Download');
          vm.downloadStatus = false;
        },
        error: function (jQxhr, status, error) {
          vm.loading = false;
          vm.busy = false;
          $(".ui.dropdown").dropdown();
          $("#button-export").html('<i class="icon download"></i> Download');
          vm.downloadStatus = false;
        },
      });
    },
    JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
      //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
      var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;

      var CSV = "";
      //Set Report title in first row or line

      //CSV += ReportTitle + '\r\n\n';

      //This condition will generate the Label/Header
      if (ShowLabel) {
        var row = "";

        //This loop will extract the label from 1st index of on array
        for (var index in arrData[0]) {
          //Now convert each value to string and comma-seprated
          row += index + ",";
        }

        row = row.slice(0, -1);

        //append Label row with line break
        CSV += row + "\r\n\n\n";
      }

      //1st loop is to extract each row
      for (var i = 0; i < arrData.length; i++) {
        var row = "";

        //2nd loop will extract each column and convert it in string comma-seprated
        for (var index in arrData[i]) {
          row += '"' + arrData[i][index] + '",';
        }

        row.slice(0, row.length - 1);

        //add a line break after each row
        CSV += row + "\r\n";
      }

      if (CSV == "") {
        alert("Invalid data");
        return;
      }

      //Generate a file name
      var fileName = "VasPro_";
      //this will remove the blank-spaces from the title and replace it with an underscore
      fileName += ReportTitle.replace(/ /g, "_");

      //Initialize file format you want csv or xls
      var uri = "data:text/csv;charset=utf-8," + escape(CSV);

      // Now the little tricky part.
      // you can use either>> window.open(uri);
      // but this will not work in some browsers
      // or you will not get the correct file extension

      //this trick will generate a temp <a /> tag
      var link = document.createElement("a");
      link.href = uri;

      //set the visibility hidden so it will not effect on your web-layout
      link.style = "visibility:hidden";
      link.download = fileName + ".csv";

      //this part will append the anchor tag and remove it after automatic click
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>
