<template>
    <div class="ui tiny modal" id="modal-Questionnaire-status">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">Your are about to Discard <br /> Questionnaire {{originalName}} <br />status</h2>
                
            </div>
            <div class="ui negative message" v-show="QuestionnaireStatus">
                {{QuestionnaireStatusDesc}}  
            </div>
        </div>
        <div class="actions">
            <button class="ui cancel button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary button" @click="questionnaireStatus()">Confirm</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    export default {
        data() {
            return {
                QuestionnaireStatus: false,
                QuestionnaireStatusDesc:"",
                Questionnaire:"",
                id:"",
                status:0,
                statusMessage:"",
                questionnaireName:""

            }
        },
        mounted() {
            let vm = this;
            console.log(JSON.stringify(store.state.selectSurveyQuestionnareID))
           // vm.checkServices(service);
        },
        computed: {
            originalName() {
                var vm = this;
                var QuestionnaireID = store.state.selectSurveyQuestionnareID;
                 $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/view/questionaire",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        questionnaireID:QuestionnaireID
                    }),
                    success: function(response, status, jQxhr) {
                        
                        vm.questionnaireName = response.data.data[0].questionnare_name

                    },
                    error: function(jQxhr, status, error) {
                        alertify.set('notifier', 'position', 'top-right');
                        // alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
                return vm.questionnaireName;
    
            }
        },
        methods: {
          
            questionnaireStatus(){
                let vm = this;
                $.post({
                    url: store.state.rootUrl + 'survey/questoinnaire/update',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        questionnare_id: store.state.selectSurveyQuestionnareID,
                        status: "-4"
                    }),
                    success: function(response, status, jQxhr) {
                        if(response.data.code != 200){
                            vm.SurveyStatus= true;
                            vm.SurveyStatusDesc = response.data.message;
                            alertify.set('notifier', 'position', 'top-right');
                            alertify.error(response.data.message);
                        }
                        else{
                            alertify.set("notifier", "position", "top-right");
                            alertify.success(response.data.message);
                            vm.$router.push({name: 'surveys'});
                            vm.hideModal();
                        }
                    },
                    error: function(jQxhr, status, error) {
                        vm.QuestionnaireStatus= true;
                        vm.QuestionnaireStatusDesc = jQxhr.responseJSON.statusDescription;
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });

            },
            hideModal() {
                $('.ui.modal#modal-Questionnaire-status').modal('hide');
            }
        }
    }
</script>
