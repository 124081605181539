<template>
  <div class="l-onboard">
    <div class="l-onboard-center">
      <div class="ui centered grid">
        <div class="row">
          <div class="sixteen wide computer only column">
            <div class="padding-xl"></div>
          </div>
          <div class="sixteen wide center aligned column padding-bottom-md hidden">
            <router-link to="/"
              ><img src="../assets/images/logo.png" height="40"
            /></router-link>
          </div>
          <div class="ten wide computer fourteen wide tablet sixteen wide mobile column">
            <h1 class="text-center padding-bottom-lg text-medium">Login</h1>
            <div class="ui negative message" v-show="loginStatus">
              {{ loginStatusMessage }}
            </div>
            <form class="ui form" v-on:submit.prevent="login">
              <div class="field" id="input-username">
                <label>Enter Phone Number / Email</label>
                <div class="ui large input">
                  <input
                    type="text"
                    placeholder="Enter Phone Number / Email"
                    required
                    v-model="username"
                  />
                </div>
              </div>
              <div class="field" id="input-password">
                <label>Enter Password</label>
                <div class="ui large input">
                  <input
                    type="password"
                    placeholder="Enter Password"
                    required
                    v-model="password"
                  />
                </div>
              </div>
              <div class="field">
                <button id="login" type="submit" class="ui large green fluid button">
                  Login
                </button>
                 
              </div>
            
              <div class="padding-top-md"></div>
              <div class="two middle aligned fields">
                <div class="field">
                  <router-link class="ui basic button" :to="{ name: 'forgot' }"
                    >Forgot password?</router-link
                  >
                </div>
                <div class="field">
                  <router-link :to="{ name: 'signup' }" class="ui fluid primary button"
                    >Sign Up</router-link
                  >
                </div>
              </div>
            </form>

            <div class="ui horizontal divider margin-top-lg">OR</div>


              <div class="field margin-top-lg">
                <router-link class="ui basic fluid button" to="/login/okta">
                      Login Via Okta
                    </router-link>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import store from "../store";
export default {
  data: function () {
    return {
      username: "",
      password: "",
      user: "",
      loginStatus: false,
      loginStatusMessage: "",
    };
  },
  methods: {
    login() {
      this.loginStatus = false;
      this.loginStatusMessage = "";
      $("#input-username, #input-password").removeClass("error");
      if (!this.username) {
        $("#input-username").addClass("error");
        return;
      } else if (!this.password) {
        $("#input-password").addClass("error");
        return;
      }

      $("#login").html('<i class="fa fa-circle-notch fa-spin"></i> Login');
      var vmLogin = this;

      $.post({
        url: store.state.rootURLUser + "login/auth",
        type: "POST",
        data: JSON.stringify({
          user_name: this.username,
          password: btoa(this.password),
          salt_token: "jhdsjYhg2983k",
        }),
        success: function (response) {
          // console.log(JSON.stringify(response.data.data));
          vmLogin.$cookies.set("key", response.data.data.key);
          vmLogin.$cookies.set("username", window.btoa(vmLogin.username));

          store.commit("setUserKey", response.data.data.key);
          vmLogin.$cookies.set("accessGrant", response.data.data.accessGrant);
          if (response.data.data.auth_roleId != null) {
            vmLogin.$cookies.set("auth_roleId", response.data.data.auth_roleId);
          } else {
            vmLogin.$cookies.set("auth_roleId", 0);
          }

          if (response.data.data.loginStatus == 2) {
            vmLogin.$router.push({ name: "bulk-dashboard" });
          } else {
            if (response.data.data.reseller) {
              vmLogin.$cookies.set("reseller", response.data.data.reseller);
              vmLogin.$router.push({ name: "reseller" });
            } else {
              if (response.data.data.reseller_account) {
                vmLogin.$cookies.set(
                  "reseller-account",
                  response.data.data.reseller_account
                );
              }
              if (response.data.data.base_color) {
                vmLogin.$cookies.set("reseller-baseColor", response.data.data.base_color);
              }
              if (response.data.data.secondary_color) {
                vmLogin.$cookies.set(
                  "reseller-secondary_color",
                  response.data.data.secondary_color
                );
              }
              if (response.data.data.system_name) {
                vmLogin.$cookies.set(
                  "reseller-system_name",
                  response.data.data.system_name
                );
              }
              if (response.data.data.logo_url) {
                vmLogin.$cookies.set("reseller-logo_url", response.data.data.logo_url);
              }
              vmLogin.$router.push({ name: "bulk-dashboard" });
            }
          }
        },
        error: function (jQxhr) {
          var statustext = jQxhr.responseJSON.statusDescription;
          vmLogin.loginStatus = true;
          $("#login").text("login");
          if (!statustext) {
            vmLogin.loginStatusMessage = "An error occurred. Try again later.";
          } else {
            vmLogin.loginStatusMessage = statustext;
          }
        },
      });
    },
  },
};
</script>
