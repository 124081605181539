<template>
    <div class="ui tiny modal" id="modal-blacklist-upload">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md">
                <h2 class="padding-bottom-sm">Blacklist Add</h2>
                <h4>You can add contact to blacklist</h4>
                <div class="ui negative message" v-show="createListStatus">
                    {{createListDesc}}
                </div>
            </div>
            <div class="ui centered container grid">
                <div class="column">
                    <div class="ui form">
                        <div class="required field" id="input-description-name">
                            <div class="field">
                                <label>Select Sender Id</label>
                                <div class="ui large input">
                                    <select class="ui fluid dropdown" v-model="senderId">
                                        <option value="">Sender Id</option>
                                        <option v-for="s_id in dataListsSenderID" v-show="dataListsSenderID.length > 0 && s_id.status == 13" :key="s_id.short_code">{{s_id.short_code}}</option>
                                    
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column padding-bottom-md">
                                <router-link target="_blank" to="../../files/sample_sms.csv" class="text-underline">Download Sample File</router-link>
                            </div>
                        </div>
                         <div class="row" v-show="showUploadSection">
                            <div class="column" id="droppedFiles">
                                <div class='ui right labeled icon label'>{{filename}}<i class='icon close' @click="closeUpload()"></i></div>
                            </div>
                        </div>
                        <div class="required field">
                            <div class="field">
                               <button class="ui small button" id="upload-file-btn"><i class="file icon"></i> Upload Contacts File</button>
                            </div>
                        </div>
    
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary button"  id="uploadList" @click="createList()">Upload Contact List</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import Dropzone from "dropzone";
     import alertify from "alertifyjs";
    export default {
        data() {
            return {
                inboxzero: false,
                phone: "",
                senderId: "",
                createListStatus: false,
                createListDesc: "",
                dataListsSenderID:[],
                uploadFiles: null,
                filename:"",
                showUploadSection:false
            };
        },
        mounted() {
            let vm = this;
            vm.fetchData();
            Dropzone.autoDiscover = false;
            vm.uploadFiles = new Dropzone('#upload-file-btn', {
                url: store.state.rootUrl + 'contact/blacklist/upload',
                paramName: "file", // The name that will be used to transfer the file
                maxFilesize: 1,
                createImageThumbnails: false,
                acceptedFiles: ".csv",
                autoProcessQueue: false,
                params: {
                    "apiKey": this.$cookies.get("key"),
                    "senderId": vm.senderId
                },
                previewTemplate: "<div style='display: none;'></div>"
            });
            vm.uploadFiles.on("addedfile", function(file) {
                // console.log(file);
                vm.showUploadSection = true;
                vm.filename = file.name;
            });
        },
        methods: {
            hideModal() {
                $(".ui.modal#modal-blacklist-upload").modal("hide");
            },
            createList(){
                let vm = this
                var myFile = vm.uploadFiles.files[0];
                    $('#uploadList').html('<i class="fas fa-circle-notch fa-spin"></i> Uploading Please wait...');
                    var data = new FormData();
                    data.append("apiKey", vm.$cookies.get("key"));
                    data.append("senderId", vm.senderId);
                    data.append("file", myFile);
                    $.ajax({
                        url: store.state.rootUrl + 'contact/blacklist/upload',
                        type: "POST",
                        data: data, //JSON.stringify({"file":myFile}),
                        cache: false,
                        contentType: false,
                        processData: false,
    
                        success: function(data, status, jQxhr) {
                            $('#uploadList').html('Upload Contact List');
                            alertify.set('notifier', 'position', 'top-right');
                            alertify.success(data.data.message);
                             $(".ui.modal#modal-blacklist-upload").modal("hide");
                            //console.log("Success");
                        },
                        error: function(jQxhr, status, error) {
                            $('#uploadList').html('Upload Contact List');
                            //alertify.error("Failed to upload");
                            alertify.error(jQxhr.responseJSON.statusDescription);
                           
                        }
                    });
            },
            fetchData() {
                var vm = this;
    
                $.post({
                    url: store.state.rootURLBulkAPI + 'v3/senderId/list',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key")
                    }),
                    success: function(response, status, jQxhr) {
                        if (response.data.code == 404) {
                            vm.inboxzero = true;
                        } else {
                            vm.dataListsSenderID = response.data.data;
                        }
                    },
                    error: function(jQxhr, status, error) {}
                });
    
            },
        }
    };
</script>
