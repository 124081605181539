<template>
    <div class="">
        <div class="body">
            <div class="body-messages">
                <div v-for="(list,index) in listData" :key="list.inbox_id + index">
                    <div class="message m1">
                        <div class="message-container">
                            <div class="message-body">{{list.incomming}}</div>
                            <div class="message-meta">
                                {{list.date_recieved}}
                                <span class="text-link text-medium margin-left-md" @click="addComments(list)">Add Comment</span>
                                <div v-if="list.comments.length != 0">
                                    <div class="margin-top-sm border-radius bg-yellow padding-top-sm padding-left-md padding-right-md padding-bottom-xs border"  v-for="(comment,index) in list.comments" :key="index">
                                        <strong>Comment:</strong> {{comment}}
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
                    <div v-if="list.responseParams.length != 0">
                        <div class="message m0" v-for="(outbox,index) in list.responseParams" :key="index">
                            <div class="message-container">
                                <div class="message-body">{{outbox.reply}}</div> 
                                <div class="message-meta">{{outbox.created_on}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <loader v-show="loading"></loader>
           <div class="body-create">
               {{messageCount}} <span class="text-green text-link text-medium"> Characters</span>  <span class="right aligned column"> {{messagePages}} <span class="text-green text-link text-medium"> SMS</span></span> <br /><br />
                <div class="ui action fluid input">
                    <input type="text" placeholder="Type your message here" v-model="composedMessage">
                    <select class="ui compact selection dropdown dropup" v-model="senderId">
                        <option value="">From</option>
                        <option v-for="s_id in dataLists" v-show="dataLists.length > 0 && s_id.status == 13" :key="s_id.short_code">{{s_id.short_code}}</option>
                        <option v-if="dataLists.length == 0"  value="VasPro">VasPro</option>
                    </select>
                    <div class="ui bg-primary button" id="button-reply" @click="sendmessage()">Reply</div>
                </div>
            </div>
        </div>
        <div class="info">
            <div class="info-header">
                <div class="ui middle aligned relaxed padded equal width grid">
                    <div class="row">
                        <div class="column">
                            <div class="info-user">
                                <span class="fa-stack fa-2x text-grey">
                                                <i class="fas fa-circle fa-stack-2x"></i>
                                                <i class="fas fa-user fa-stack-1x fa-inverse"></i>
                                            </span>
                                <h3 class="padding-top-md">{{msisdn}}</h3>
                            </div>
                            <div class="ui divider"></div>
                            <h4>Bio Information</h4>
                            <small>{{name}}</small><br />
                            <small>{{age_bracket}}</small>
    
                            <div class="ui divider"></div>
                            <h4>Activity</h4>
                            <small>Origin: {{origin}}</small> <br />
                            <small>Frequency: {{useFreq}} </small><br />
                            <small>Last Use: {{lastUse}}</small>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <inboxReplymessage />
        <inboxComments />
    </div>
</template>

<script>
    import inboxReplymessage from '@/modals/inbox-reply-message';
    import inboxComments from '@/modals/inbox-comment';
    import format from 'date-fns/format';
    import loader from "@/components/loader";
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    import Dropzone from "dropzone";
    import moment from "moment";
    export default {
        data() {
            return {
                listData: [],
                status: "",
                limit: 10,
                offset: 0,
                order: "",
                profile_id: "",
                name: "",
                age_bracket: "",
                origin: "",
                useFreq: "",
                msisdn: "",
                loading: false,
                lastUse: null,
                senderId:"",
                dataLists:[],
                composedMessage:"",
                inbox_id:"",
                endMessage:"",
                composeTotalCharacters:0,

            }
        },
        components: {
            loader,
            inboxReplymessage,
            inboxComments
        },
        computed: {
    
            messageCount() {
                //var defaultCount = 14;
                var phoneNu = "";
                phoneNu = window.atob(this.$cookies.get("username"));
                var phone = "";
    
                if (this.dataLists.length > 0) {
                    this.endMessage = "";
                    this.composeTotalCharacters = this.endMessage.length + this.composedMessage.length;
                } else {
                    this.endMessage = "";
                    this.composeTotalCharacters = this.endMessage.length  + this.composedMessage.length;
                }
    
                return this.composeTotalCharacters;
            },
            messagePages() {
    
                if (this.composedMessage.length > 0) {
                    $('#textarea-sms').removeClass("red");
                }
    
                if (this.composeTotalCharacters == 0) {
                    return 0;
                } else if (this.composeTotalCharacters / 160 <= 1) {
                    return 1;
                } else if (this.composeTotalCharacters / 160 <= 2) {
                    return 2;
                } else if (this.composeTotalCharacters / 160 <= 3) {
                    return 3;
                } else if (this.composeTotalCharacters / 160 <= 4) {
                    return 4;
                } else if (this.composeTotalCharacters / 160 <= 5) {
                    return 5;
                } else {
                    return " Max ";
                }
            },
        },
        mounted() {
            let vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            if (store.state.selectInboxDetails) {
                //console.log(JSON.stringify(store.state.selectInboxDetails));
                vm.msisdn = store.state.selectInboxDetails.msisdn;
                vm.profile_id = store.state.selectInboxDetails.profile_id;
                vm.inbox_id = store.state.selectInboxDetails.inbox_id;
                vm.getDetails(vm.profile_id);
    
            }
            vm.$root.$on('refreshList', (data) => {
                vm.profile_id = "";
                vm.name = "";
                vm.age_bracket = "";
                vm.origin = "";
                vm.useFreq = "";
                vm.listData = [];
                vm.msisdn = store.state.selectInboxDetails.msisdn;
                vm.profile_id = store.state.selectInboxDetails.profile_id;
                vm.getDetails(vm.profile_id);
            });
            $(".ui.dropdown").dropdown();
            vm.fetchData();
        },
        methods: {
            getDetails(profileID) {
                let vm = this;
                vm.loading = true;
                //console.log(profileID);
                $.post({
                    url: store.state.rootUrl + 'inboxmain/view/details',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        profileId: profileID
    
                    }),
                    success: function(response, status, jQxhr) {
    
                        var list_c = response.data.data;
                        vm.loading = false;
    
                        for (var i = 0; i < list_c.length; i++) {
                            list_c[i].date_recieved = format(list_c[i].date_recieved, "hh:mma DD MMM YYYY");
                            list_c[i].repliedOn = format(list_c[i].repliedOn, "hh:mma DD MMM YYYY");
                            //var data = list_c[i].responseParams.split("||");
                            //var dateRec = 
                            var requestParamsString = list_c[i].responseParams;
                            var requestParamsArray = requestParamsString ? requestParamsString.split('||') : [];
                            var outboxList = [];

                            $.each(requestParamsArray,function(index,item){
                                var outboxArray = item ? item.split('--') : [];
                                var outbox = {
                                    reply:outboxArray[0],
                                    created_on:format(outboxArray[1], "hh:mma DD MMM YYYY")
                                };
                                outboxList.push(outbox);
                            });

                            //console.log('outboxList | '+JSON.stringify(outboxList));
                            list_c[i].responseParams = outboxList;
                            
                            var commentsInbox = list_c[i].comments;
                            var commentsArray = commentsInbox ? commentsInbox.split('|') : [];

                            list_c[i].comments = commentsArray;

                            console.log(list_c[i].comments);
                            vm.listData.push(list_c[i]);
                            if (i == 0) {
                                vm.name = list_c[0].name;
                                vm.age_bracket = list_c[0].age_bracket;
                                vm.origin = list_c[0].origin;
                                vm.useFreq = list_c[0].useFreq;
                                vm.lastUse = list_c[0].lastUse;
                            }
                        }
    
                    },
                    error: function(jQxhr, status, error) {
                         alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            },
            fetchData() {
                //console.log("TEST");
                // this.dataLists = [];
                var vmBalance = this;
    
                var vm = this;
    
                $.post({
                    url: bulkstore.state.bulksmsUrl + 'senderId/list',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        status: 13,
                        p_status: 4,
                        type_id: 3
                    }),
                    success: function(response, status, jQxhr) {
                        vm.dataLists = response.data.data;
                        //console.log(JSON.stringify(vm.dataLists));
                        //alertify.set('notifier', 'position', 'top-right');
                        //alertify.success(response.data.message);
                        //console.log("test message " + JSON.stringify(response.data.data));
                    },
                    error: function(jQxhr, status, error) {
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.data.message);
                        // console.log(" status bulk " + status + " data " + JSON.stringify(jQxhr.responseJSON.statusDescription));
                    }
                });
    
            },
            sendMessageInbox(clientDetails) {
                let vm = this;
                //console.log("am here");
                store.commit("setInboxDetails",clientDetails);
                $('.ui.modal#modal-inbox-new-message').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            addComments(list){
                let vm = this;
                //console.log("am here");
                store.commit("setInboxDetails",list);
                $('.ui.modal#modal-inbox-comments').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            sendmessage() {
                //console.log("jSON SELECTLIST  "+JSON.stringify(vm.selectedList));
                var vm = this;
                
                $.post({
                    url: bulkstore.state.bulksmsUrl + 'notify/reply',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        shortCode: vm.senderId,
                        message: !vm.composedMessage.endsWith(vm.endMessage) ?
                            vm.composedMessage + "" + vm.endMessage : vm.composedMessage,
                        inbox_id:vm.inbox_id 
                    }),
                    success: function(data, status, jQxhr) {
                        vm.sendStatus = false;
                        vm.composedMessage = "";
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.success(data.data.message);
                        vm.getDetails(vm.profile_id);

                    },
                    error: function(jQxhr, status, error) {
                        vm.sendStatus = false;
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                        vm.sendSMSStatus = true;
                        vm.sendSMSResponseMessage = jQxhr.responseJSON.statusDescription;

    
                    }
                });
            },
        }
    }
</script>
