<template>
    <div class="l-app-main">
        <div class="l-app-main-inboxzero bg-primary-lighter" v-show="inboxzero">
            <h2>Enable Bulk Payment Settings</h2>
            <p>To be able to use Bulk Payment API. Kindly Enbale your seetings</p>
            <div class="padding-sm"></div>
            <button class="ui large bg-primary button">Enable Bulk Payment Settings</button>
            <div class="padding-xl"></div>
        </div>
        <div class="l-app-main-content" v-show="!inboxzero">
            <div class="ui grid">
                <div class="row">
                     <div class="col-8 text-right">
                        <div class="ui search link small input">
                            <input v-on:keyup.enter="searchRecord" type="text" placeholder="Search..." v-model="search_match">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <loader v-show="loading == true"></loader>
                         <div class="row">
            <div class="overflow">
                <vuetable ref="vuetable"
                      :api-url="url"
                      :fields="columns"
                      :css="css.table"
                      :sort-order="sortOrder"
                      track-by="mpesa_paybill"
                      :append-params="moreParams"
                      :per-page="10"
                      @vuetable:pagination-data="onPaginationData"
                      @vuetable:loading="onLoading"
                      @vuetable:loaded="onLoaded">
                </vuetable>
            </div>
            <div class="p-top"></div>
            <div class="vuetable-pagination row">
                <div class="col text-center">
                    <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
                </div>
                <div class="col">
                    <vuetable-pagination 
                        ref="pagination" 
                        @vuetable-pagination:change-page="onChangePage">
                    </vuetable-pagination>
                </div>
            </div>

        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
    td.vuetable-slot{
        overflow: visible !important;
    }
</style>
<script>
    import moment from 'moment';
    import store from "@/store";
    import Vuetable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
    import loader from "@/components/loader"
    import tableCss from "@/components/Table.js";
    import DatePicker from 'vue2-datepicker';
    export default {
        data() {
            return {
                inboxzero: false,
                url:"https://api.vaspro.co.ke/v3/payment/bulk?api_key="+ this.$cookies.get("key"),
                css: tableCss,
                loading: true,
                selected_data: {},
                columns: [
                     {
                        name: 'request_id',
                        title: '<span class="orange glyphicon glyphicon-user"></span> Request Id',
                        sortField: 'request_id',
                        titleClass: '',
                        dataClass: ''
                    },
                    
                    {
                        name: "mpesa_transaction_id",
                        title: 'Mpesa Code',
                        sortField: 'mpesa_transaction_id',
                    },
                   
                    {
                        name: "unique_id",
                        title: 'Unique Id',
                        sortField: 'unique_id',
                    },
                    {
                        name: 'response_description',
                        title: 'Narration',
                        sortField: 'response_description',
                        titleClass: '',
                        dataClass: ''

                    },
                    {
                        name: 'transaction_amount',
                        title: 'Amount',
                        sortField: 'transaction_amount',
                        titleClass: '',
                        dataClass: ''

                    },
                    
                    {
                        name: "response_status",
                        title: 'Status',
                        sortField: 'response_status',
                    },
                    
                   
                    {
                        name: 'initiated_by',
                        title: 'Initiated By',
                        sortField: 'initiated_by',
                        titleClass: '',
                        dataClass: ''

                    },
                    {
                        name: 'authorization_date',
                        title: 'Authorization Date',
                        sortField: 'authorization_date',
                        titleClass: '',
                        dataClass: ''

                    },
                    {
                        name: 'completed_on',
                        title: 'Complete',
                        sortField: 'completed_on',
                        titleClass: '',
                        dataClass: ''

                    },
                    
                ],
                range:'',
                search_match:'',
                loadOnStart: true,
                sortOrder: [{
                    field: 'created',
                    direction: 'desc'
                }],
                is_partial_resulting: false,

                moreParams: {
                    start: '',
                    filter: '',
                    end: ''
                },
            }
        },
        components: {
            Vuetable, VuetablePagination, VuetablePaginationInfo,loader,DatePicker
        },
        mounted() {
            let vm = this;
            vm.getPaybills();
            if(!this.$cookies.get("key")){
                this.$router.push({
                name: 'login'
            });
            }
            vm.url = "https://api.vaspro.co.ke/v3/payment/bulk?api_key="+ vm.$cookies.get("key");
            var start = moment();
            var end = moment();

            function cb(start, end) {
                vm.dateFilter(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
                $('input[name="dates"]').val(start.format('D MMMM, YYYY') + ' - ' + end.format('D MMMM, YYYY'));
            }

           

            cb(start, end);
           
        },
        computed: {
           
        },
        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.refresh();
            },
            range(){

                var start = this.range[0];
                var end = this.range[1];
                start= moment(start).format('YYYY-MM-DD');
                end= moment(end).format('YYYY-MM-DD');
                this.moreParams.start = start;
                this.moreParams.end = end;
                this.$refs.vuetable.refresh();
            }
        },
        methods: {
           
            getPaybills() {
                var vm = this;
                vm.loading =  true;
                if (!vm.token) {
                    vm.token = vm.$cookies.get("key");
                }
                $.post({
                    url: store.state.rootUrl + 'payment/paybill/list',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key")
    
                    }),
                    success: function(response, status, jQxhr) {
                        
                        if (response.data.code == 404) {
                            vm.inboxzero = true;
                        }
                        vm.paybills = response.data.data;
                        vm.loading =  false;
                    },
                    error: function(jQxhr, status, error) {
                        vm.loading =  false;
                    }
                });
            },
            dateFilter: function (start, end) {

                var vm = this;


                vm.start = start;
                vm.end = end;


                vm.moreParams.start = start;
                vm.moreParams.end = end;

                this.$refs.vuetable.refresh();

                console.log("start date filter",start)
                console.log("end date filter ",end)
            },
            createdAt: function (value) {
                if (!value) {
                    return '-';
                } else {
                    return moment(value).format('h:mm a,DD MMM YYYY');
                }

            },

            searchRecord:function(){
                this.moreParams.filter = this.search_match;
                this.$refs.vuetable.refresh();
            },

            dataManager(sortOrder, pagination) {
                if (this.data.length < 1) return;

                let local = this.data;

                // sortOrder can be empty, so we have to check for that as well
                if (sortOrder.length > 0) {
                    console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
                    local = _.orderBy(
                        local,
                        sortOrder[0].sortField,
                        sortOrder[0].direction
                    );
                }

                pagination = this.$refs.vuetable.makePagination(
                    local.length,
                    this.perPage
                );
                console.log('pagination:', pagination)
                let from = pagination.from - 1;
                let to = from + this.perPage;

                return {
                    pagination: pagination,
                    data: _.slice(local, from, to)
                };
            },


            onPaginationData(paginationData) {
                this.$refs.paginationInfo.setPaginationData(paginationData)
                this.$refs.pagination.setPaginationData(paginationData)
            },


            onChangePage(page) {
                this.$refs.vuetable.changePage(page)
            },

            onLoading() {
                this.loading = true
                console.log('loading... show your spinner here')
            },
            onLoaded() {
                this.loading = false
                console.log('loaded! .. hide your spinner here');
                $(".ui.dropdown").dropdown();
            }
        }
    }
</script>

