<template>
  <div class="ui modal" id="create-client">
    <i class="close icon"></i>
    <div class="header">
      <h2>Create Client</h2>
    </div>
    <loader v-show="loading"></loader>
    <div class="ui negative message" v-show="isError">
      {{ errorMessage }}
    </div>
    <div class="scrolling content">
      <div class="ui form">
        <div class="field">
          <h4 class="padding-bottom-sm"><strong>Company Name</strong></h4>
          <div class="ui large input">
            <input
              type="text"
              placeholder="Enter Company Name"
              v-model="client_name"
              required
            />
          </div>
        </div>
        <div class="padding-md"></div>
        <h4 class="padding-bottom-md"><strong>Contact Details</strong></h4>

        <div class="field">
          <label>Contact Name</label>
          <div class="ui input">
            <input
              type="text"
              placeholder="Enter Company Name"
              v-model="full_names"
              required
            />
          </div>
        </div>
        <div class="two fields">
          <div class="field">
            <label>Email</label>
            <div class="ui input">
              <input
                type="text"
                placeholder="Enter Email"
                v-model="email_address"
                required
              />
            </div>
          </div>
          <div class="field">
            <label>Phone</label>
            <div class="ui input">
              <input type="text" placeholder="Enter Phone" v-model="mobile_no" required />
            </div>
          </div>
        </div>
        <div class="padding-md"></div>
        <h4 class="padding-bottom-md"><strong>Rates</strong></h4>
        <div class="four fields">
          <div class="field">
            <label>SMS Rate</label>
            <div class="ui input">
              <input
                type="number"
                step="0.01"
                min="0.20"
                placeholder="Enter rate"
                v-model="smsRate"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <button class="ui cancel button">Cancel</button>
      <button class="ui button bg-primary" id="add-client" @click="addClient()">
        Add Client
      </button>
    </div>
  </div>
</template>
<script>
import loader from "@/components/loader";
import store from "@/store";
import alertify from "alertifyjs";
export default {
  components: {
    loader,
  },
  data() {
    return {
      // credits: store.state.credits,
      email_address: "",
      mobile_no: "",
      client_name: "",
      full_names: "",
      smsRate: 0.8,
      loading: false,
      isError: false,
      errorMessage: "",
    };
  },
  methods: {
    addClient() {
      $("#add-client").html('<i class="fas fa-circle-notch fa-spin"></i> Please wait...');
      let vm = this;
      vm.loading = true;
      $.post({
        url: store.state.rootUrl + "reseller/add/account",
        type: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key"),
          email_address: vm.email_address,
          mobile_no: vm.mobile_no,
          client_name: vm.client_name,
          full_names: vm.full_names,
          smsRate: vm.smsRate,
        }),
        success: function (response, status, jQxhr) {
          vm.loading = false;
          $("#add-client").html("Add Client");
          if (response.data.code != 200) {
            vm.isError = true;
            vm.errorMessage = response.data.message;
            alertify.error(response.data.message);
          } else {
            alertify.success(response.data.message);
          }
        },
        error: function (jQxhr, status, error) {
          vm.loading = false;
          $("#add-client").html("Add Client");
          vm.isError = true;
          vm.errorMessage = jQxhr.responseJSON.statusDescription;
          alertify.error(jQxhr.responseJSON.statusDescription);
        },
      });
    },
  },
};
</script>
