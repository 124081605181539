<template>
<div class="l-app-main-segment-container-4">
            <div class="ui equal width middle aligned relaxed padded grid">
                <div class="row">
                    <div class="column">
                        <h3 class="text-title">Question {{selectedQuestionID}} </h3>
                        <h5 class="text-grey">{{totalQuestion}} responses</h5>
                        <small>{{question}}</small>
                    </div>
                    <div class="right aligned column">
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <table class="ui celled table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Phone Numer</th>
                                    <th>Date</th>
                                    <th>Response</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(list, index) in lists" :key="list.id">
                                    <td class="center aligned collapsing">{{index + 1}}</td>
                                    <td class="collapsing">{{list.msisdn}}</td>
                                    <td class="collapsing">{{list.created_at}}</td>
                                    <td>{{list.response}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-show="!loading" class="ui icon large labeled button"><i class="icon refresh"></i> Load More</div>
                        <loader v-show="loading"></loader>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
    import loader from "@/components/loader";
    import store from "@/store";
    import format from 'date-fns/format';
    import moment from "moment";
    import alertify from 'alertifyjs';
import { scrypt } from 'crypto';

    export default {
    data() {
        return {
            questionID: "",
            selectedList:"",
            appId:"",
            offset:0,
            totalQuestion:0,
            totalResponse:0,
            loading: false,
            limit:10,
            lists:[],
            question:"",
            questionnnaireType:""
        }
    },
    mounted(){
    console.log("Selected "+this.selectedQuestionID)
    },
    computed:{
        selectedQuestionID(){
            this.questionID = store.state.selectQuestion.id;
            this.question = store.state.selectQuestion.question;
            this.appId = store.state.selectQuestion.app_id;
            if(store.state.selectQuestion != null || store.state.selectQuestion != ""){
                this.totalQuestion = 0;
                this.totalResponse = 0;
                this.offset = 0;
                this.lists = [];
                this.selectListValue()
            }
            return this.questionID;
        }
    },
    components: {
        loader
    },
    methods: {
        selectListValue(){
            let vm = this;
            //if (vm.totalQuestion == 0 || vm.totalQuestion >= ((vm.offset - 1) * vm.limit)) {
                vm.offset++;
                vm.loading = true;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/view/response",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        sort: vm.sort,
                        offset: vm.offset,
                        app_id: vm.appId,
                        questionID: vm.questionID,
                        limit: vm.limit,
                    }),
                    success: function(response, status, jQxhr) {
                        vm.loading = false;
                        vm.totalQuestion = response.data.count;
                        if(vm.totalQuestion != 0){
                            vm.totalResponse = response.data.data[0].Totalresponse;
                            vm.lists = response.data.data;
                        }
                        
                       

                    },
                    error: function(jQxhr, status, error) {
                        vm.loading = false;
                        vm.offset = 0;
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
           // }
            
        },
        

    }
}
</script>