<template>
<div class="l-app-main">

    <div class="l-app-main bg-white">
        <div class="l-app-dashboard bg-white">
            <div class="ui equal width relaxed grid padded">
                <div class="row bg-primary-lighter border-bottom">
                    <div class="column">
                        <h3 class="padding-bottom-md text-medium text-title">Choose Bill to pay</h3>
                        <ul class="l-app-util-block">
                             <loader v-show="loadingList"></loader>
                             <li>
                                 <a href="https://bai.co.ke/" target="_blank">
                                 <div class="icon"></div>
                                 <div class="label">Bai Credo</div>
                                </a>
                             </li>
                            <li v-for="(utility,index ) in utilities" :key="index+1"  :class="{ inactive: utility.status ==0 }">
                                <router-link v-if=" utility.status == 1 " :to="{name: utility.utility_description}">
                                    <div class="icon"></div>
                                    <div class="label">{{utility.utility_name}}</div>
                                </router-link>
                                <router-link v-if=" utility.status == 0 " :to="{}">
                                    <div class="icon"></div>
                                    <div class="label">{{utility.utility_name}}</div>
                                </router-link>
                                
                            </li>
                            
                        </ul>
                    </div>
                </div>
                <div class="middle aligned row">
                    <div class="column">
                        <h3 class="text-medium text-title">Saved Lists</h3>
                        <h5>{{totalList}} lists</h5>
                    </div>
                    <div class="computer only tablet only right aligned column">
                        <div class="ui search link input margin-right-md">
                            <input type="text" placeholder="Search List Name" v-on:keyup="getSaveList(false)" v-model="listName" />
                        </div>
                        <select class="ui small dropdown" v-model="utilityId" @change="getSaveList(false)">
                            <option value="" selected>Select Bill</option>
                            <option value="4">Airtime</option>
                            <option value="5">Mobile Data Bundles</option>
                            <option value="6">Electricity Token(KPLC)</option>
                            <option value="7">Electricity Post-Paid(KPLC)</option>
                        </select>
                    </div>
                    <div class="mobile only right aligned column">
                        <select class="ui small dropdown" v-model="utilityId" @change="getSaveList(false)">
                            <option value="" selected>Select Utility</option>
                            <option value="4">Airtime</option>
                            <option value="5">Mobile Data Bundles</option>
                            <option value="6">Electricity Token (KPLC)</option>
                            <option value="7">Electricity Post-Paid (KPLC)</option>
                        </select>
                    </div>
                    <!--<router-link :to="{name: 'utilities-pay'}" class="ui bg-primary button margin-left-md">Create Payment List</router-link>-->
                </div>
                
            </div>
            <div class="ui relaxed padded grid">
                <div class="computer only tablet only row">
                    <div class="column">
                        <table class="ui styled celled unstackable table">
                            <thead>
                                <tr> 
                                    <th class="center aligned collapsing">#</th>
                                    <th>List Name</th>
                                    <th>Utility</th>
                                    <th>Prepared By</th>
                                    <th>Total Contacts</th>
                                    <th>Amount</th>
                                    <th>Total Cost</th>
                                    <th>Created On</th>
                                    <th class="center aligned text-grey"><i class="fa fa-ellipsis-h"></i></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(dataList, index) in dataLists" :key="dataList.id">
                                    <td class="center aligned collapsing">{{index+1}}</td>
                                    <td>{{dataList.list_name}}</td>
                                    <td>
                                        <span class="ui label" :class="{
                                            green: dataList.utility_id==4,
                                            orange: dataList.utility_id==5, 
                                            teal:dataList.utility_id==6, 
                                            grey:dataList.utility_id==7}">
                                            {{dataList.utility}}
                                        </span>
                                    </td>
                                    <td>{{dataList.full_names}}</td>
                                    <td>{{dataList.totalRecord}}</td>
                                    <td>{{dataList.amount}}</td>
                                    <td>{{dataList.amount}}</td>
                                    <td>{{dataList.created_at}}</td>
                                    <td class="center aligned collapsing" >
                                        <button class="ui icon tiny button" @click="confirmPayment(dataList)"> Pay Now!</button>
                                        <button class="ui icon primary tiny button" @click="viewList(dataList)"> View List</button>
                                        <button class="ui icon red tiny button" @click="cancelList(dataList)"><i class="icon trash"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-show="!loading" @click="getSaveList(true)" class="ui icon large labeled button"><i class="icon refresh"></i> Load More</div>
                        <loader v-show="loading"></loader>
                    </div>
                </div>
                <div class="mobile only row">
                    <div class="column">
                        <table class="ui very basic celled unstackable table">
                            <thead>
                                <tr> 
                                    <th>List Name</th>
                                    <th class="center aligned">Utility</th>
                                    <th class="right aligned">Total Cost</th>
                                    <th class="center aligned text-grey"><i class="fa fa-ellipsis-h"></i></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="dataList in dataLists" :key="dataList.id">
                                    <td>
                                        <h4>{{dataList.list_name}}</h4>
                                        <div class="text-grey"><small>{{dataList.created_at}}</small></div>
                                    </td>
                                    <td class="center aligned">
                                        <span class="ui label" :class="{
                                            green: dataList.utility_id==4,
                                            orange: dataList.utility_id==5, 
                                            teal:dataList.utility_id==6, 
                                            grey:dataList.utility_id==7}">
                                            {{dataList.utility}}
                                        </span>
                                    </td>
                                    <td class="right aligned">
                                        <h4>Ksh. {{dataList.totalCost}}</h4>
                                        <small>{{dataList.totalRecord}} contacts</small>
                                    </td>
                                    <td class="center aligned collapsing" >
                                        <div class="ui icon dropdown tiny button">
                                            <i class="icon dropdown"></i>
                                            <div class="menu">
                                                <div class="item" @click="confirmPayment(dataList)"> Pay Now</div>
                                                <div class="item" @click="viewList(dataList)"> View List</div>
                                                <div class="item" @click="cancelList(dataList)"><i class="icon trash"></i> Delete</div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-show="!loading" @click="getSaveList(true)" class="ui icon large labeled button"><i class="icon refresh"></i> Load More</div>
                        <loader v-show="loading"></loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
     <UtilitiesPay />
     <UtilitiesCancel />
</div>

</template>
<script>
import loader from "@/components/loader";
import store from "@/store";
import alertify from 'alertifyjs'
import UtilitiesPay from "@/modals/utilitiesPayConfirm";
import UtilitiesCancel from "@/modals/utilitiesSaveCancel";
export default {
    data(){
        return {
            listName:"",
            dataLists:[],
            totalList:0,
            offset: 0,
            limit: 10,
            loading: false,
            loadingList:false,
            inboxzero:true,
            utilityId:null,
            utilities:[]
        }
    },
    components: {
        loader,
        UtilitiesPay,
        UtilitiesCancel
    },
    mounted(){
        let vm = this;
        if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
        this.getSaveList(true);
        this.getAllUtilities();
    },
    methods: {
        getSaveList(states){
            let vm = this;
            if(!states){
                vm.totalList = 0;
                vm.offset = 0;
                vm.dataLists = [];
            }
            
            if (vm.totalList == 0 || vm.totalList >= ((vm.offset - 1) * vm.limit)) {
                vm.offset++;
                vm.loading = true;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/rewards/view/lists",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        offset: vm.offset,
                        limit: vm.limit,
                        listName: vm.listName,
                        utilityId:vm.utilityId
                    }),
                    success: function(response, status, jQxhr) {
                        vm.loading = false;
                        var resp = response.data.data;
                        
                        if (response.data.code != 200) {
                            vm.offset = 0;
                            alertify.set('notifier', 'position', 'top-right');
                            alertify.error(response.data.message);
                            
                        } else {
                            for (var i = 0; i < resp.length; i++) {
                                vm.dataLists.push(resp[i]);
                            }
                            vm.inboxzero = false;
                            vm.totalList = response.data.record_count;
                        }
                        setTimeout(function(){
                            $(".ui.dropdown").dropdown();
                        }, 100);
                    },
                    error: function(jQxhr, status, error) {
                        vm.loading = false;
                        vm.offset = 0;
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            }
        },
         getAllUtilities(){
            let vm = this;
            vm.loadingList = true;
            $.post({
                url: store.state.rootURLBulkAPI + "v3/rewards/view",
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key"),

                }),
                success: function(response, status, jQxhr) {
                    vm.loading = false;
                    var les = response.data.data;
                    if(les.length == 0){
                        vm.inboxzero = true;
                    }
                    for(var i=0; i< les.length; i++){
                        
                        vm.utilities.push(les[i]);
                    }
                    vm.loadingList = false;
                    setTimeout(function(){
                        $(".l-app-util-content").css({top: $(".l-app-util-header").outerHeight()});
                    }, 300);
                },
                error: function(jQxhr, status, error) {
                    vm.loadingList = false;
                    vm.inboxzero = true;
                } 
            });
        },
        
        confirmPayment(dataList) {
            store.commit("setUtilities", dataList);

            $('.ui.modal#modal-utility-pay').modal({
                centered: false,
                closable: true
            }).modal("show");

        },
        viewList(dataList){
            store.commit("setUtilities", dataList);
            //console.log(JSON.stringify(dataList));
            this.$router.push({
                name: 'utilities-pay-list',
                params: {
                    id: dataList.id
                }
            });
        },
         cancelList(dataList){
            store.commit("setUtilities", dataList);

            $('.ui.modal#modal-utility-save-cancel').modal({
                centered: false,
                closable: true
            }).modal("show");
        }
        
    }
}
</script>