<template>
<div class=" ">
    <div class="bg-white">
        <div class="ui padded relaxed grid bg-white l-app-main-top">
            <div class="middle aligned row bg-primary-lighter border-bottom padding-bottom-none">
                <div class="column">
                    <div class="ui equal width grid">
                        <div class="eight wide computer eight wide tablet twelve wide mobile column">
                            <h2 class="text-medium text-title">{{surveyName}}</h2>
                            <small class="text-grey-light hidden">Created a few seconds ago</small>
                        </div>
                        <div class="computer only tablet only right aligned column">
                            <button class="ui button"  :disabled="status != 1 " @click="sendSurveyMessage()">Send Message</button>
                            <button class="ui green button" v-if="status!= 1" @click="updateSurveyStatus(1)">Start Survey</button>
                             <button class="ui red button" v-if="status== 1" @click="updateSurveyStatus(2)">Stop Survey</button>
                             <div class="ui icon floating dropdown button">
                                <i class="icon dropdown"></i>
                                <div class="menu">
                                    <div class="item" @click="showFullscreen()">Fullscreen</div>
                                    <div class="item" @click="generateExport()">Export data</div>
                                </div>
                             </div>
                        </div>
                        <div class="mobile only right aligned column">
                            <div class="ui icon pointing dropdown small button">
                                <i class="icon dropdown"></i>
                                <div class="menu">
                                    <div class="item">Edit Questionnaire</div>
                                    <div class="item" :class="{'disabled': status != 1}" @click="sendSurveyMessage()">Send Message</div>
                                    <div class="item" @click=" generateExport()">Export data</div>
                                    <div class="item hidden"><i class="expand icon"></i></div>
                                    <div class="item" v-if="status!= 1" @click="updateSurveyStatus(1)">Start Survey</div>
                                    <div class="item" v-if="status== 1" @click="updateSurveyStatus(2)">Stop Survey</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ul class="l-tabs">
                        <li :class="{active: selectedTab == 'report'}" class="item" @click="selectedTab = 'report'">Survey Report</li>
                        <li :class="{active: selectedTab == 'responses'}" class="item" @click="selectedTab = 'responses'">Responses</li>
                        <li :class="{active: selectedTab == 'questions'}" class="item " @click="selectedTab = 'questions'">Questionnaire</li>
                        <li :class="{active: selectedTab == 'settings'}" class="item" @click="selectedTab = 'settings'">Settings</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="l-app-main-segment">
            <createSurveyQuestions ref="createSurveyQuestions" v-if="selectedTab == 'questions'" />

            <createSurveySettings v-if="selectedTab == 'settings'" />

            <createSurveyReport id="survey-report" style="overflow-y: auto" ref="createSurveyReport" v-if="selectedTab == 'report'" />

            <createSurveyResponses v-if="selectedTab == 'responses'" />
        
        </div>
    </div>
    <surveyQuestionsOptions />
</div>
    
</template>
<script>
import store from "@/store";
import surveyQuestionsOptions from "@/modals/survey-questions-options";
import createSurveyQuestions from "./create-questions"
import createSurveySettings from "./create-settings"
import createSurveyReport from "./survey-report"
import createSurveyResponses from "./survey-responses"
import alertify from 'alertifyjs';
import moment from "moment";
import loader from "@/components/loader";
import { setTimeout } from 'timers';

export default {
    data(){
        return {
            selectedTab: 'report',
            surveyName:"",
            app_id:"",
            status:"",
            questionnnaireType:""
        }
    },
    components: {
        loader
     },
    components:{
        surveyQuestionsOptions, createSurveyQuestions, createSurveySettings, createSurveyReport, createSurveyResponses
    },
    mounted(){
        let vm = this;
        vm.surveyName = store.state.selectSurvey.campaign_name;
        vm.app_id = store.state.selectSurvey.app_id;
        vm.status = store.state.selectSurvey.status;
        vm.questionnnaireType = store.state.selectSurvey.questionnnaireType;
        if(vm.surveyName ==null){
            //console.log("not set");
            vm.getCampaignRecords(vm.$route.params.id);
            vm.app_id =vm.$route.params.id;
            //vm.$router.push({name: 'surveys'});
        }
        setTimeout(function(){
            $(".l-app-main-segment").css({
                top: $(".l-app-main-top").height()
            });
        }, 100);
    },
    methods:{
        showFullscreen(){
            var elem = document.getElementById("survey-report");
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) { /* Firefox */
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE/Edge */
                elem.msRequestFullscreen();
            }
        },
        sendSurveyMessage(){
            this.$parent.showCreateSurveyMessage = true;
            console.log("clicked " + this.$parent.showCreateSurveyMessage);
        },
        showQuestionsOptions(){
            $("#modal-survey-questions-options").modal({centered: false}).modal("show");
        },
        getCampaignRecords(appID){
            let vm = this
            $.post({
                url: store.state.rootURLBulkAPI + "v3/survey/query",
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key"),
                    app_id:appID
                }),
                success: function(response, status, jQxhr) {
                    
                    var survey = response.data.data;
                    vm.surveyName = survey[0].campaign_name;
                    vm.questionnnaireType = survey[0].questionnnaireType
                    store.commit("setSurveySelect", survey[0]);
                    setTimeout(function(){
                        $(".l-app-main-segment").css({
                            top: $(".l-app-main-top").height()
                        });
                    }, 1000);

                },
                error: function(jQxhr, status, error) {
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.error(jQxhr.responseJSON.statusDescription);
                }
            });

        },
        updateSurveyStatus(state){
            let vm = this;
            $.post({
                url: store.state.rootUrl + 'survey/update',
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key"),
                    status: state,
                    app_id: vm.app_id
                }),
                success: function(response, status, jQxhr) {
                    if(response.data.code != 200){
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(response.data.message);
                    }
                    else{
                        alertify.set("notifier", "position", "top-right");
                        alertify.success(response.data.message);
                         vm.questionnaireID = store.state.selectSurvey.questionnare_id;
                      
                        vm.$router.push({name: 'surveys'});
                    }
                },
                error: function(jQxhr, status, error) {
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.error(jQxhr.responseJSON.statusDescription);
                }
            });

            
        },
        generateExport() {
                let vm = this;
                vm.uploadStatus =  true;
                $('#button-export').html('<i class="fas fa-circle-notch fa-spin"></i> Download Please Wait ...');
                var exportRecipients = [];
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/view/response",
                        type: "POST",
                        data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        app_id: vm.app_id,
                        export:1
                    }),
                    success: function(response, status, jQxhr) {
                        $('.ui.accordion').accordion('refresh');
                        for (var i = 0; i < response.data.data.length; i++) {
                            var item = {};
                            item.msisdn = response.data.data[i].msisdn;
                            item.campaign_name = response.data.data[i].campaign_name;
                            item.questionnare_name = response.data.data[i].questionnare_name;
                            item.question = response.data.data[i].question;
                            item.response = response.data.data[i].response;
                            item.objectives = response.data.data[i].objectives;
                            item.targeted_respondents = response.data.data[i].targeted_respondents;
                            item.created_at = response.data.data[i].created_at;
                            item.start_date = response.data.data[i].start_date;
                            item.stop_date = response.data.data[i].stop_date;
                            exportRecipients.push(item);
                        }
    
    
                        var exportDate = moment().format('DD_MMMM_YYYY_h:mm');
    
                        vm.JSONToCSVConvertor(exportRecipients, "Suvery Response Report _" + exportDate, 1);
                        $('#button-export').html('<i class="icon download"></i> Download');
    
                    },
                    error: function(jQxhr, status, error) {
                        vm.loading = true;
                        //console.log(jQxhr)
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                        $('#button-export').html('<i class="icon download"></i> Download');
                        //console.log("failed");
                    }
                });
            },
            JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
                //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
                var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    
                var CSV = '';
                //Set Report title in first row or line
    
                //CSV += ReportTitle + '\r\n\n';
    
                //This condition will generate the Label/Header
                if (ShowLabel) {
                    var row = "";
    
                    //This loop will extract the label from 1st index of on array
                    for (var index in arrData[0]) {
    
                        //Now convert each value to string and comma-seprated
                        row += index + ',';
                    }
    
                    row = row.slice(0, -1);
    
                    //append Label row with line break
                    CSV += row + '\r\n\n\n';
                }
    
                //1st loop is to extract each row
                for (var i = 0; i < arrData.length; i++) {
                    var row = "";
    
                    //2nd loop will extract each column and convert it in string comma-seprated
                    for (var index in arrData[i]) {
                        row += '"' + arrData[i][index] + '",';
                    }
    
                    row.slice(0, row.length - 1);
    
                    //add a line break after each row
                    CSV += row + '\r\n';
                }
    
                if (CSV == '') {
                    alert("Invalid data");
                    return;
                }
    
                //Generate a file name
                var fileName = "VasPro_";
                //this will remove the blank-spaces from the title and replace it with an underscore
                fileName += ReportTitle.replace(/ /g, "_");
    
                //Initialize file format you want csv or xls
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    
                // Now the little tricky part.
                // you can use either>> window.open(uri);
                // but this will not work in some browsers
                // or you will not get the correct file extension
    
                //this trick will generate a temp <a /> tag
                var link = document.createElement("a");
                link.href = uri;
    
                //set the visibility hidden so it will not effect on your web-layout
                link.style = "visibility:hidden";
                link.download = fileName + ".csv";
    
                //this part will append the anchor tag and remove it after automatic click
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
    }
}
</script>
