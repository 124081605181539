<template>
    <div class="l-onboard">
        <div class="l-onboard-center">
            <div class="ui centered grid">
                <div class="row">
                    <div class="sixteen wide center aligned column padding-bottom-md">
                        <div class="padding-md"></div>
                        <router-link to="/"><img src="../assets/images/logo.png" height="40" /></router-link>
                    </div>
                    <div class="twelve wide computer fourteen wide tablet sixteen wide mobile column">
                        <h1 class="padding-bottom-sm text-center">Verify Account</h1>
                        <h4 class="padding-bottom-lg text-center">
                            Enter the code sent to your phone number
                        </h4>
                        <div class="ui negative message" v-show="loginStatus">
                            {{ StatusMessage }}
                        </div>
                        <router-link :to="{ name: 'login' }" v-show="loginRiderrect">Click here to login</router-link>
                        <div class="ui form">
                            <div class="field padding-top-md padding-bottom-md text-one">
                                <label>Enter Verification Code</label>
                                <div class="ui large input">
                                  <input type="password" placeholder="enter Verification"  v-model="verify1" />
                                </div>
                            </div>
                            <div class="field">
                                <label>Enter password</label>
                                <div class="ui large input">
                                    <input type="password" placeholder="enter password" required v-model="newPassword" />
                                </div>
                            </div>
                            <div class="field" id="input-confirm-passowrd">
                                <label>Confirm password</label>
                                <div class="ui large input">
                                    <input type="password" placeholder="confirm password" required v-model="verifyPassword" />
                                </div>
                            </div>
                            <div class="field">
                                <button class="ui fluid large button bg-primary" @click="verifyAccount" id="verifyBTN">
                          Verify Account
                        </button>
                            </div>
                            <div class="padding-top-md field">
                                <router-link :to="{ name: 'login' }" class="ui very basic button">Login</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from "../store";
import pageFooter from "../components/page-footer";
import pageHeader from "../components/page-header";
import alertify from "alertifyjs";
export default {
    components: {
        pageFooter,
        pageHeader,
    },
    data() {
        return {
            username: "",
            verify1: "",
            user: "",
            loginStatus: false,
            loginRiderrect: false,
            StatusMessage: "",
            newPassword: "",
            verifyPassword: ""
        };
    },
    methods: {
        verifyAccount() {
            var vm = this;
            var verificationcode = vm.verify1;
            $('#verifyBTN').html("Please Wait..")

            if (!this.newPassword) {
                this.loginStatus = true;
                $("#input-new-password").addClass("error");
                this.StatusMessage = "New Password is required";
                return;
            } else if (!this.verifyPassword) {
                this.loginStatus = true;
                $("#input-verify-password").addClass("error");
                this.StatusMessage = "Confirm Password is required";
                return;
            } else if (this.newPassword != this.verifyPassword) {
                this.loginStatus = true;
                this.StatusMessage = "New and Confirm Password should be the same";
                $("#input-new-password").addClass("error");
                $("#input-verify-password").addClass("error");
                return;
            } else {
                $.post({
                    url: store.state.rootURLUser + "users/verify",
                    type: "POST",
                    data: JSON.stringify({
                        user_name: window.atob(vm.$cookies.get("username")),
                        verification_code: verificationcode,
                        password:vm.newPassword,
                        salt_token: "jhdsjYhg2983k",
                    }),
                    success: function(response, status, jQxhr) {
                        $('#verifyBTN').html("Verify Account")
                        if (response.data.code != 200) {
                            vm.$cookies.remove("key");
                            vm.$cookies.remove("username");
                            vm.$cookies.remove("iscalled");
                            vm.loginRiderrect = true;
                            vm.$router.push({
                                name: "login",
                            });
                            alertify.set("notifier", "position", "top-right");
                            alertify.error("Your account is active kindly login");
                        } else {
                            vm.$cookies.remove("key");
                            vm.$cookies.remove("username");
                            vm.$cookies.remove("iscalled");
                            alertify.set("notifier", "position", "top-right");
                            alertify.success("Password reset successfull. Kindly login");
                            vm.$router.push({
                                name: "login",
                            });
                        }
                    },
                    error: function(jQxhr, status, error) {
                        $('#verifyBTN').html("Verify Account")
                        var statustext = jQxhr.responseJSON.statusDescription;
                        alertify.error(statustext);
                    },
                });
            }


        },
    },
};
</script>
