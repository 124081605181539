<template>
  <div class="l-app-main bg-primary-bg">
    <div class="l-app-dashboard bg-primary-lighter">
      <div class="ui padded relaxed grid">
        <div class="equal width aligned row">
          <div class="column">
            <h3>Summary</h3>
            <h4>{{ from | formatDate }} - {{ to | formatDate }}</h4>
          </div>

          <div class="right alignedcolumn">
            <div class="ui input datepicker-trigger">
              <input
                type="text"
                id="datepicker-trigger"
                placeholder="Select date"
                :value="formatDates(from, to)"
              />
              <AirbnbStyleDatepicker
                :trigger-element-id="'datepicker-trigger'"
                :mode="'range'"
                :fullscreen-mobile="true"
                :months-to-show="2"
                :date-one="from"
                :date-two="to"
                :close-after-select="false"
                @date-one-selected="
                  (val) => {
                    from = val;
                  }
                "
                @date-two-selected="
                  (val) => {
                    to = val;
                  }
                "
                @apply="getSummary()"
              />
            </div>
          </div>
        </div>
        <loader v-show="dataLoading"> Please wait...</loader>
        <div
          class="equal width row"
          :class="{ loading: dataLoading }"
          v-show="!dataLoading"
        >
          <div class="column" v-for="(list, index) in utilitiesConsumed" :key="index + 1">
            <div
              class="ui segment"
              :class="{
                red: list.utility_name == 'Airtime',
                green: list.utility_name != 'Airtime',
              }"
            >
              <h4>{{ list.utility_name }} Consumed</h4>
              <h1>{{ list.totalAmount | formatNumber }}</h1>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <table class="ui striped celled table" v-show="!dataLoading">
              <thead>
                <tr>
                  <th>Client</th>
                  <th>Total Consumed</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(list, index) in clientsConsumption" :key="index + 1">
                  <td>{{ list.client_name }}</td>
                  <td class="right aligned">
                    <strong>KES {{ list.totalAmount | formatNumber }}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loader from "@/components/loader";
import store from "@/store";
import alertify from "alertifyjs";
import format from "date-fns/format";
import moment from "moment";
import numeral from "numeral";
import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
import Vue from "vue";
export default {
  components: {
    loader,
  },
  data() {
    return {
      // credits: store.state.credits,
      totalCost: 0,
      totalClients: 0,
      dataLoading: false,
      isLoading: false,
      clientsConsumption: [],
      utilitiesConsumed: [],
      totalSMSConsumed: 0,
      from: "",
      to: "",
      dateFormatTwo: "YYYY-MM-DD",
    };
  },
  mounted() {
    var d = new Date();
    this.to = format(d, this.dateFormatTwo);
    this.from = format(d.setDate(d.getDate() - 7), this.dateFormatTwo);
    const datepickerOptions = {};
    Vue.use(AirbnbStyleDatepicker, datepickerOptions);
    this.getSummary();
  },
  methods: {
    formatDates(dateOne, dateTwo) {
      let formattedDates = "";
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormatTwo);
      }

      if (dateTwo) {
        formattedDates += " - " + format(dateTwo, this.dateFormatTwo);
      }
      return formattedDates;
    },
    getSummary() {
      let vm = this;
      vm.dataLoading = true;
      $.post({
        url: store.state.rootURLBulkAPI + "utilities/v3/summary",
        type: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key"),
          start: vm.from,
          end: vm.to,
        }),
        success: function (response) {
          vm.dataLoading = false;
          vm.clientsConsumption = response.data.summary_utilities_clients;
          vm.utilitiesConsumed = response.data.summary_utility;
        },
        error: (jQxhr) => {
          vm.dataLoading = false;
          alertify.error(jQxhr.responseJSON.statusDescription);
        },
      });
    },
  },
  filters: {
    formatDate: function (value) {
      if (!value) return "";
      return moment(String(value)).format("D MMM");
    },
    formatNumber(value) {
      return numeral(value).format("0,0.00");
    },
  },
};
</script>
