<template>
    <!-- Responses tab-->
    <div class="l-app-main-segment-container bg-white">
        <div class="l-app-main-segment-container-3">
            <ul class="l-app-question-list">
                <li class="active" v-for="(question, index) in questionList" :key="question.id" @click="selectList(question)">
                    <span class="num">
                        <span  v-if="question.question_typeID ==1" class="ui grey label">{{index + 1}}</span>
                        <span  v-if="question.question_typeID ==2" class="ui green label">{{index + 1}}</span>
                        <span  v-if="question.question_typeID ==3" class="ui teal label">{{index + 1}}</span>
                        <span  v-if="question.question_typeID ==4" class="ui blur label">{{index + 1}}</span>
                    </span>
                    <span class="info">
                        <span class="title">{{question.question}}</span>
                        <span class="sum">{{question.totalResponse}} of {{question.totalContacts}} people answered this question</span>
                    </span>
                </li>
            </ul>
        </div>
        <!--<surveyResponseData v-show="list_selected" ref="details"/> -->
        <surveyResponseData v-show="list_selected" :questionsID="selectedListKey"></surveyResponseData>
    </div>
</template>

<script>
import surveyResponseData from "./survey-responses-data";
import loader from "@/components/loader";
import store from "@/store";
import format from 'date-fns/format';
export default {
    data(){
        return{
            questionnaireID:"",
            survey:[],
            app_id:[],
            questionList:[],
            totalQuestion:0,
            offset:0,
            limit:10,
            showRecords:false,
            selectListRe:{},
            list_selected:false,
            selectedQ: null,
            selectedListKey:null,
            appId:"",
            questionnnaireType:""
        }
    },
    components: {
        surveyResponseData,
        loader,
    },
    mounted(){
        let vm = this;
        vm.survey= store.state.selectSurvey
        if(vm.survey ==null || vm.survey==""){
            vm.getCampaignRecords(vm.$route.params.id);
        }
        else {
            
            vm.questionnaireID = vm.survey.questionnare_id;
            vm.appId = store.state.selectQuestion.app_id;
            vm.questionnnaireType = vm.survey.questionnnaireType
            vm.getQuestion();
        }
        
    },
    methods:{
        getQuestion() {
            let vm = this;
            console.log("Survey Test "+vm.$route.params.id)
            if (vm.totalQuestion == 0 || vm.totalQuestion >= ((vm.offset - 1) * vm.limit)) {
                vm.offset++;
                vm.loading = true;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/view/question",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        questionnare_id: vm.questionnaireID,
                        offset: vm.offset,
                        limit: vm.limit,
                        offset: vm.offset,
                        appID: vm.$route.params.id,
                        questionnnaireType: vm.questionnnaireType
                    }),
                    success: function(response, status, jQxhr) {
                        vm.loading = false;
                        var resp = response.data.data;
                        if (response.data.code != 200) {
                            vm.offset = 0;
                            alertify.set('notifier', 'position', 'top-right');
                            alertify.error(response.data.message);
                            //vm.totalMessage = 0;

                        } else {
                            vm.totalQuestion = response.data.total;
                            var quest = [];
                            for (var i = 0; i < resp.length; i++) {
                                // console.log(" loop " + i + " " + JSON.stringify(resp[i]));
                                resp[i].created_at = format(resp[i].created_at, "DD MMM YYYY");
                                var options = resp[i].options;
                                if(options != null){
                                    var optionArry = options.split('||');
                                    var n = 0;
                                    var optionList = [];
                                    optionArry.forEach(element => {
                                        element = element.split('|');
                                        var list = {
                                            "id": element[1],
                                            "value": element[0]
                                        }
                                        
                                        optionList.push(list);
                                    });
                                    resp[i].options = optionList;
                                }
                                
                                vm.questionList.push(resp[i]);
                            }
                            
                            vm.selectListRe = resp[0];
                            vm.selectedListKey = resp[0].id;
                            setTimeout(function() {
                                $(".ui.small.dropdown").dropdown();
                                $(".question-box").on('input', function() {
                                    var scroll_height = $(".question-box").get(0).scrollHeight;
                                    $(".question-box").css('height', scroll_height + 'px');
                                });
                            }, 100)
                        }
                    },
                    error: function(jQxhr, status, error) {
                        vm.loading = false;
                        vm.offset = 0;
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            }
        },
         getCampaignRecords(appID){
                let vm = this
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/query",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        app_id:appID
                    }),
                    success: function(response, status, jQxhr) {
                        
                        var survey = response.data.data;
                        vm.surveyName = survey[0].campaign_name;
                        vm.appId = appID;
                        vm.questionnnaireType = survey[0].questionnnaireType;
                        store.commit("setSurveySelect", survey[0]);
                        vm.survey= store.state.selectSurvey
                        vm.questionnaireID = vm.survey.questionnare_id;
                        vm.getQuestion();
                        setTimeout(function(){
                            $(".l-app-main-segment").css({
                                top: $(".l-app-main-top").height()
                            });
                        }, 1000);

                    },
                    error: function(jQxhr, status, error) {
                        alertify.set('notifier', 'position', 'top-right');
                       // alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });

            },
    
        selectList(list) {
            this.selectListRe = list;
            this.selectedListKey = list.id;
            this.list_selected=true;
          //  this.$refs.details.setSelectedList(list);
           store.commit("setSelectQuestion", list);
        }
    },
    filters: {
            formatDate: function(value) {
                if (!value) return ''
                return moment(value).fromNow()
            }
        }
}
</script>
