var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"l-homepage"},[_c('div',{staticClass:"l-homepage-top"},[_c('div',{staticClass:"padding-sm"}),_c('pageHeader')],1),_c('div',{staticClass:"l-homepage-features"},[_c('div',{staticClass:"ui grid bg-primary-lighter"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"center aligned column",attrs:{"id":"products"}},[_c('div',{staticClass:"padding-lg"}),_vm._m(0),_c('div',{staticClass:"padding-lg"}),_c('div',{staticClass:"padding-lg"}),_c('router-link',{staticClass:"ui big red button",attrs:{"to":{name: 'home'}}},[_vm._v("Go Back Home")]),_c('div',{staticClass:"padding-lg"})],1)])])]),_c('div',{staticClass:"l-homepage-api"},[_c('div',{staticClass:"padding-lg"}),_c('div',{staticClass:"ui container grid"},[_c('div',{staticClass:"text-white row"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"four wide computer sixteen wide mobile middle aligned column"},[_c('router-link',{staticClass:"ui big red button fluid",attrs:{"to":{name: 'signup'}}},[_vm._v("_Get your API key here")])],1)])]),_c('div',{staticClass:"padding-lg"})]),_c('pageFooter')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"text-title-lead"},[_vm._v("Error "),_c('strong',{staticClass:"text-primary"},[_vm._v("404")]),_vm._v(" Page Not Found ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"one wide computer two wide mobile column"},[_c('i',{staticClass:"fa fa-code fa-2x"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"three wide computer fourteen wide mobile column"},[_c('h3',{},[_vm._v("Use our APIs to integrate with your platform")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sixteen wide mobile only column"},[_c('div',{staticClass:"padding-top-lg"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"four wide computer eight wide mobile column"},[_c('ul',{staticClass:"ui list"},[_c('li',[_vm._v("Transactional SMS sending")]),_c('li',[_vm._v("Bulk SMS sending")]),_c('li',[_vm._v("Paybill & till integration")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"four wide computer eight wide mobile column"},[_c('ul',{staticClass:"ui list"},[_c('li',[_vm._v("Contacts management")]),_c('li',[_vm._v("Shortcode integrations")]),_c('li',[_vm._v("MPESA STK Push")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sixteen wide mobile only column"},[_c('div',{staticClass:"padding-lg"})])
}]

export { render, staticRenderFns }