<template>
    <div class="ui tiny modal" id="modal-contact-list-message">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">Send MT Message</h2>
                <h4>You can can send message to below contacts</h4>
            </div>
            <div class="ui negative message" v-show="sendSMSStatus">{{sendSMSResponseMessage}}</div>
            <div class="ui centered container grid">
                <div class="column">
                    <div class="ui form">
                        <div class="field">
                            <label>Short Code: {{selectedList.short_code}}</label>
                            
                        </div>
                        
                        <div class="field">
                            <label>Type your message</label>
                            <div class="ui top attached segment" id="textarea-sms" >
                                <textarea class="l-type-message" rows="3" placeholder="Type your SMS here" v-model="composedMessage"></textarea>
                            </div>
                            <div class="ui bottom attached tiny secondary segment">
                                <div class="ui equal width relaxed grid">
                                    <div class="row">
                                        <div class="column"> 
                                            <small>{{messageCount}} characters 
                                                <div id="textarea-sms" v-show="dataLists.length > 0">(14 include OPT Out)</div>
                                                <div id="textarea-sms" v-show="dataLists.length == 0">(32 include OPT and Sent By)</div>
                                            </small>
                                        </div>
                                        <div class="right aligned column"> <small>{{messagePages}}</small> Pages</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <div class="ui toggle checkbox">
                                <input type="checkbox" name="schedule" v-model="isScheduled">
                                <label><strong>Schedule Message</strong></label>
                                <p class="padding-top-sm"><small>Select date and time to send message</small></p>
                            </div>
                        </div>
    
                        <div class="two fields"  v-show="isScheduled">
                            <div class="field" v-show="isScheduled">
                                <label>Date</label>
                                <div class="ui calendar" >
                                    <div class="ui input left icon">
                                        <i class="calendar icon"></i>
                                        <input type="date" placeholder="Select date" v-model="scheduleDate">
                                    </div>
                                </div>
                            </div>
                            <div class="field" v-show="isScheduled">
                                <label>Time</label>
                                <div class="ui calendar" >
                                    <div class="ui input left icon">
                                        <i class="time icon"></i>
                                        <input type="time" v-model="scheduledTime"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui button" @click="hideModal()">Cancel</button>
            <button  @click="sendmessage(1)" v-show="isScheduled" class="ui bg-primary button" id="button-schedule-message" :disabled="!sendStatus">Schedule Message</button>
            <button @click="sendmessage(0)" v-show="!isScheduled" class="ui bg-primary button" id="button-send-message" :disabled="!sendStatus">Send Message</button>
        </div>
    </div>
</template>
<style lang="scss" scoped>
textarea{
    width: 100%;
    border: none;
    outline: none;
    font-size: 1.4em;
    &::placeholder{
        color: #ccc;
    }
}
.row{
    padding-top: 0 !important;
}
</style>

<script>
    import { mapGetters } from 'vuex'
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import Choices from "choices.js";
    import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
    import format from 'date-fns/format';
    import moment from "moment";
    import Vue from "vue";
    import alertify from 'alertifyjs';
    import _ from "lodash";
    import Dropzone from "dropzone";

export default {
    data() {
        return {
            //selectedList: "",
            composedMessage: '',
            listName: "",
            messagePrefix: "CO",
            droppedFile: null,
            schedule_date: new Date(),
            schedule_time: '',
            isScheduled: false,
            dateOne:null,
            senderId: "",
            token: "",
            list_id: "",
            list_name: "",
            status: "",
            limit: "",
            offset: "",
            order: "",
            sendSMSStatus: false,
            dateTwo:"",
            dateOne:"",
            sendSMSResponseMessage: "",
            dataLists:[],
            endMessage:"",
            composeTotalCharacters:0,
            templateLists:[],
            template:"",
            scheduledTime:null,
            scheduleDate:null,
            sendStatus:true,
            is_scheduled:0


        }
    },
    watch:{
        scheduleDate(){
            let vm = this;
            console.log(vm.scheduleDate);
            return 
        }
    },
    mounted() {
        const vm = this;
        $(".ui.dropdown").dropdown('refresh');
        vm.token = vm.$cookies.get("key");
       
    },
    computed: {
        selectedList(){
            return store.state.selectKeywordList;
        },
         messageCount() {
            //var defaultCount = 14;
            var phoneNu = "";
            phoneNu = window.atob(this.$cookies.get("username"));
            var phone= "";
            
            if(this.dataLists.length > 0){
                this.endMessage = " STOP*456*9*5#";
                this.composeTotalCharacters = this.endMessage.length + this.composedMessage.length;
            }
            else{
                this.endMessage = " STOP*456*9*5#";
                this.composeTotalCharacters = this.endMessage.length + 18 + this.composedMessage.length;
            }
            
            return this.composeTotalCharacters; 
        },
        messagePages() {
            
            if (this.composedMessage.length > 0) {
                $('#textarea-sms').removeClass("red");
            }

            if (this.composeTotalCharacters == 0) {
                return 0;
            } else if (this.composeTotalCharacters / 160 <= 1) {
                return 1;
            } else if (this.composeTotalCharacters / 160 <= 2) {
                return 2;
            } else if (this.composeTotalCharacters / 160 <= 3) {
                return 3;
            } else if (this.composeTotalCharacters / 160 <= 4) {
                return 4;
            } else if (this.composeTotalCharacters / 160 <= 5) {
                return 5;
            } else {
                return " Max ";
            }
        },
    },
    methods: {
        formatDates(dateOne, dateTwo) {
            let formattedDates = ''
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat)
            }
            if (dateTwo) {
                formattedDates += ' - ' + format(dateTwo, this.dateFormat)
            }
            return formattedDates
        },
        sendmessage(is_scheduled) {
            //console.log("jSON SELECTLIST  "+JSON.stringify(vm.selectedList));
            var vm = this;
            vm.sendStatus = false;
            if (!vm.composedMessage) {
                $('#textarea-sms').addClass("red");
                alertify.error("Message is required");
                return;
            }
            if (vm.isScheduled) {
                $('#button-schedule-message').html('<i class="fas fa-circle-notch fa-spin"></i> Scheduling Message ...');
                vm.is_scheduled = 1;
            }
            else {
                $('#button-send-message').html('<i class="fas fa-circle-notch fa-spin"></i> Sending Message ...');
                vm.is_scheduled = 0;
            }
            var contact_groupId = [];
                contact_groupId.push(store.state.selectKeywordList.product_id);

            $.post({
                url: store.state.rootURLBulkAPI + 'subscription/sms/v3/create/broadcast',
                type: "POST",
                data: JSON.stringify(
                    {
                        token: vm.$cookies.get("key"),
                        origin: "web_mt",
                        message: !vm.composedMessage.endsWith(vm.endMessage)?
                                    vm.composedMessage+""+vm.endMessage:vm.composedMessage,
                        product_id:store.state.selectKeywordList.product_id,
                        scheduled_date: vm.scheduleDate,
                        scheduled_time: vm.scheduledTime,
                        is_scheduled: vm.is_scheduled,
                        unique_id:vm.schedule_date

                    }
                ),
                success: function (data, status, jQxhr) {
                    $('#contacts-list-message').modal('hide');
                    $('#button-send-message').text('Send Message');
                    $('#button-schedule-message').text('Schedule Message');
                    vm.composedMessage = "";
                    vm.hideModal();
                    if(data.data.message){
                        alertify.set("notifier", "position", "top-right");
                        alertify.success(data.data.message);
                    }
                    else{
                       // console.log(JSON.stringify(data));
                        alertify.set("notifier", "position", "top-right");
                        alertify.success("Message successfully sent");
                    }
                    

                },
                error: function (jQxhr, status, error) {
                    $('#button-send-message').text('Send Message');
                    $('#button-schedule-message').text('Schedule Message');
                    alertify.set("notifier", "position", "top-right");
                    alertify.error(jQxhr.responseJSON.statusDescription);
                    vm.sendSMSStatus= true;
                    vm.sendSMSResponseMessage = jQxhr.responseJSON.statusDescription;
                   
                    
                }
            });
            vm.sendStatus=true;
        },
        
        setComposeMessage(template){
            let vm  = this;
            vm.saveTemplateStatus = false;
            vm.composedMessage = template;

        },
        hideModal() {
            $('.ui.modal#modal-contact-list-message').modal('hide');

        }
    }
}
</script>
