<template>
    <div class="ui tiny modal" id="modal-apply-keyword-subscription">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2>Apply Subscription Keyword</h2>
                <h4>To effectively utilize MT<br />You need to apply catch keywords</h4>
            </div>
            <div class="ui negative message" v-show="applyKeywordStatus">{{applyKeywordStatusDesc}}</div>
            <div class="ui centered container grid">
                <div class="column">
                    <div class="ui form">
                        <div class="field">
                            <label>Services Name</label>
                            <div class="ui input">
                                <input type="text" placeholder="e.g. VASPRO" required v-model="serviceName" />
                            </div>
                        </div>
                        <div class="field columns">
                             <div class="ui column margin-right-md">
                                <label>Start Keyword</label>
                                <div class="ui input">
                                    <input type="text" placeholder="e.g GH" required v-model="startKeyword" />
                                </div>
                            </div>
                            <div class="ui column">
                                <label>Stop Keyword</label>
                                <div class="ui input">
                                    <input type="text" placeholder="e.g STOP" required v-model="stopKeyword" />
                                </div>
                            </div>
                        </div>
                        <div class="padding-md"></div>
                        <div class="field columns">
                            <div class="column">
                                <label>Shortcode</label>
                                <select class="ui fluid dropdown" v-model="shortcode">
                                    <option value="">Select Shortcode</option>
                                    <option v-for="s_id in shortcodes" v-show="shortcodes.length > 0" :key="s_id.send_type_id+1" :value="s_id.short_code">{{s_id.short_code}}</option>
                                </select>
                            </div>
                            <div class="column">
                                <label>Cost</label>
                                <div class="ui input">
                                    <input type="number" placeholder="e.g 5" required v-model="cost" />
                                </div>
                            </div>
                        </div>
                        <div class="padding-md"></div>
                        <div class="field columns">
                            <div class="column">
                                <label>Billing Type</label>
                                <select class="ui fluid dropdown" v-model="billingType">
                                    <option value="">Select Billing Type</option>
                                    <option v-for="s_id in billingTypes" v-show="billingTypes.length > 0" :key="s_id.id+1" :value="s_id.id">{{s_id.billing}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="padding-md"></div>
                        <div class="field">
                            <label>Subscription Message</label>
                            <div class="ui top attached segment" id="textarea-sms" >
                                <textarea class="l-type-message" rows="3" placeholder="Type your SMS here" v-model="subMessage"></textarea>
                            </div>
                            <div class="ui bottom attached tiny secondary segment">
                                <div class="ui equal width relaxed grid">
                                    <div class="row">
                                        <div class="column"> 
                                            <small>{{messageCount}} characters 
                                            </small>
                                        </div>
                                        <div class="right aligned column"> <small>{{messagePages}}</small> Pages</div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="field">
                            <label>UnSubscription Message</label>
                            <div class="ui top attached segment" id="textarea-sms" >
                                <textarea class="l-type-message" rows="3" placeholder="Type your SMS here" v-model="unsubMessage"></textarea>
                            </div>
                            <div class="ui bottom attached tiny secondary segment">
                                <div class="ui equal width relaxed grid">
                                    <div class="row">
                                        <div class="column"> 
                                            <small>{{unmessageCount}} characters 
                                            </small>
                                        </div>
                                        <div class="right aligned column"> <small>{{unmessagePages}}</small> Pages</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <div class="ui checkbox">
                                <input type="checkbox" v-model="agreeTerms" />
                                <label>I agree to the Terms and Conditions of this service</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <a class="ui button" @click="hideModal()">Cancel</a>
            <button class="ui bg-primary button" @click="applyKeyword()" :disabled="!agreeTerms"><i id="keywordApply"></i> Apply Keyword</button>
        </div>
    </div>
</template>

<script>
    import loader from "@/components/loader";
    import store from "@/store";
    import alertify from 'alertifyjs';
    export default {
        data() {
            return {
                serviceName: '',
                agreeTerms: false,
                applyKeywordStatus:false,
                applyKeywordStatusDesc:"",
                startKeyword:"",
                stopKeyword:"",
                shortcodes:[],
                billingTypes:[],
                shortcode:'',
                billingType:'',
                cost:10,
                subMessage:"",
                unsubMessage:""
            }
        },
        mounted() {
            this.getAllShortCode();
            this.getAllBillingTypes();
        },
        computed: {
            messageCount() {
                return this.subMessage.length
           },
           unmessageCount() {
                return this.unsubMessage.length
           },
            messagePages() {
                if (this.subMessage.length == 0) {
                    return 0;
                } else if (this.subMessage.length  / 160 <= 1) {
                    return 1;
                } else if (this.subMessage.length / 160 <= 2) {
                    return 2;
                } else if (this.subMessage.length  / 160 <= 3) {
                    return 3;
                } else if (this.subMessage.length  / 160 <= 4) {
                    return 4;
                } else if (this.subMessage.length  / 160 <= 5) {
                    return 5;
                } else {
                    return " Max ";
                }
            },
            unmessagePages() {
                if (this.unsubMessage.length == 0) {
                    return 0;
                } else if (this.unsubMessage.length  / 160 <= 1) {
                    return 1;
                } else if (this.unsubMessage.length / 160 <= 2) {
                    return 2;
                } else if (this.unsubMessage.length  / 160 <= 3) {
                    return 3;
                } else if (this.unsubMessage.length  / 160 <= 4) {
                    return 4;
                } else if (this.unsubMessage.length  / 160 <= 5) {
                    return 5;
                } else {
                    return " Max ";
                }
            }
        },
        methods: {
            applyKeyword(){
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + 'subscription/v3/add/products',
                    type: "POST",
                    data: JSON.stringify (
                        {
                            token: vm.$cookies.get("key"),
                            short_code: vm.shortcode,
                            service_name: vm.serviceName,
                            subscription_msg: vm.subMessage,
                            unsubscription_msg: vm.unsubMessage,
                            service_cost: vm.cost,
                            start_keyword: vm.startKeyword,
                            stop_keyword: vm.stopKeyword,
                            billing_type:vm.billingType,
                            service_id: Math.floor(Math.random() * 23000000),
                            operator_id:Math.floor(Math.random() * 23000000)
                        }
                    ),
                    success: function (data, status, jQxhr) {
                        vm.hideModal();
                        vm.$parent.getContactList();
                        if(data.data.message){
                            alertify.set("notifier", "position", "top-right");
                            alertify.success(data.data.message);
                        }
                        else{
                            alertify.set("notifier", "position", "top-right");
                            alertify.success("Message successfully sent");
                        }
                    },
                    error: function (jQxhr, status, error) {
                        $('#button-send-message').text('Send Message');
                        $('#button-schedule-message').text('Schedule Message');
                        alertify.set("notifier", "position", "top-right");
                        alertify.error(jQxhr.responseJSON.statusDescription);
                        vm.applyKeywordStatus= true;
                        vm.applyKeywordStatusDesc = jQxhr.responseJSON.statusDescription;
                    
                        
                    }
                });
            },
            getAllShortCode(){
                let vm = this;
                  $.post({
    
                    url: store.state.rootURLBulkAPI + 'v3/senderId/list',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        type_id: "2"
                    }),
                    success: function(response, status, jQxhr) {
                        if (response.data.data != "") {

                            vm.shortcodes = response.data.data;

                        } 
                        else {

                           vm.shortcodes = [];
                        }
                    },
                    error: function(jQxhr, status, error) {

                        vm.shortcodes = [];
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
    
                });
            },
            getAllBillingTypes(){
                let vm = this;
                $.post({
    
                    url: store.state.rootURLBulkAPI + 'subscription/v3/billing/type',
                    type: "POST",
                    data: JSON.stringify({
                        token: this.$cookies.get("key"),
                    }),
                    success: function(response, status, jQxhr) {
                        if (response.data.data != "") {
                            vm.billingTypes = response.data.data; 
                        } 
                        else {
                            vm.billingTypes = [];
                        }
                    },
                    error: function(jQxhr, status, error) {

                        vm.billingTypes = [];
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
    
                });

            },
            hideModal() {
                $('.ui.modal#modal-apply-keyword-subscription').modal('hide');
            }
        }
    }
</script>
