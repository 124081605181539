<template>
<div class="l-app-main">
    <div class="l-app-main-inboxzero bg-primary-lighter" v-show="inboxzero">
        <h2>Create Message</h2>
        <p>Click below to <br />and send messages</p>
        <div class="padding-sm"></div>
        <button class="ui large blue button" @click="showCreateTemplate(true)">Send Message</button>
        <div class="padding-xl"></div>
    </div>
    <div class="l-app-main-section" v-show="!inboxzero">
        <div class="l-app-main-section-left">
            <ul class="l-app-tab">
                <li class="tab green" :class="{active: selectedMessageTab == 1}" @click="getMessageStatusSent()">
                    <h4>Sent Message</h4>
                    <h2>{{sentTotal}}</h2>
                </li>
                <li class="tab orange hidden" :class="{active: selectedMessageTab == 2}" @click="getMessageStatusSchedule()">
                    <h4>Failed Message</h4>
                    <h2>{{scheduleTotal}}</h2>
                </li>
            </ul>
            <ul class="l-app-messages" v-show="selectedMessageTab == 1"><!-- Sent -->
                <li class="message" v-for="list in lists" :key="list.campaign_id" @click="selectCampaign(list)" :class="{selected: selectedMessageKey == list.campaign_id}">
                    <div class="message-icon">
                        <i class="icon green check"></i>
                    </div>
                    <div class="message-content">
                        <div class="message-content">
                            <div class="message-content-body">{{list.message}}</div>
                            <div class="message-content-meta">
                                <ul class="">
                                    <li>Sent {{list.created_at | formatDate}}</li>
                                    <li><i class="icon home"></i> {{list.success}} Success</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <div v-show="!loading" @click="getMessageStatusSent()" class="ui fluid button bottom aligned button">Load More</div>
                <loader v-show="loading == true && totalMessageListSent >= limitSent"></loader>
            </ul>
            <ul class="l-app-messages" v-show="selectedMessageTab == 2"><!-- Draft Message -->
                <li class="message" v-for="list in listsSchedule" :key="list.campaign_id" v-on:click="selectCampaign(list)" :class="{selected: selectedMessageKey == list.campaign_id}">
                    <div class="message-icon">
                        <i class="icon yellow circle"></i>
                    </div>
                    <div class="message-content">
                        <div class="message-content-body">{{list.message}}</div>
                        <div class="message-content-meta">
                            <ul class="">
                                <li><i class="icon clock outline"></i> {{list.send_time | formatDate}}</li>
                                <li><i class="icon users"></i> {{list.campaign_sent}} contacts</li>
                            </ul>
                        </div>
                    </div>
                </li>
                <div v-show="!loading" @click="getMessageStatusSchedule()" class="ui fluid button bottom aligned button">Load More</div>
                <loader v-show="loading == true && totalMessageListSchedule >=limitSchedule"></loader>
            </ul>
            
        </div>
        <MessageDetails v-show="selectedCampaign" ref="details"/>
    </div>
</div>
</template>


<script>
import MessageDetails from "./message-details";
import loader from "@/components/loader";
import store from "@/store";
import bulkstore from "@/bulk/bulksms-state";
import moment from "moment";
export default {
    data() {
        return {
            inboxzero: false,
            sentTotal: 0,
            pendingTotal: 0,
            scheduleTotal: 0,
            lists: [],
            listsSchedule: [],
            listsPending: [],
            status: 400,
            loading: true,
            selectedMessageTab: 1,
            selectedCampaign:false,
            limitSent: 10,
            offsetSent: 1,
            totalMessageListSent:0,
            limitSchedule: 10,
            offsetSchedule: 1,
            totalMessageListSchedule:0,
            limitPending: 10,
            offsetPending: 1,
            totalMessageListPending:0,
            loading: true,
            selectedMessageKey: null
        }
    },
    components: {
        MessageDetails, loader
    },
    
   
    mounted() {
        let vm = this;
        if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
        vm.getMessageStatusSent();
    },
   
    methods: {

        formatNumber(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        
        showCreateTemplate(v, message) {
    
            this.$parent.showCreateMessageFn(v, message);
            store.commit("composeMessageState", message);
        },
        getMessageStatusSent() {
            let vm = this;
            vm.selectedMessageTab = 1;
             //vm.lists = [];
            if (vm.totalMessageListSent === 0 || vm.totalMessageListSent >= ((vm.offsetSent -1) * vm.limitSent)) {
                //console.log(vm.offset +" "+(vm.offset - 1) * vm.limit + " vm.limit "+vm.limit+" vm.totalMessageList "+vm.totalMessageList);
            
            vm.loading = true;
            $.post({
                url: store.state.rootURLBulkAPI + 'subscription/sms/v3/view',
                type: "POST",
                data: JSON.stringify({
                    token: this.$cookies.get("key"),
                    sort: "",
                    export: "",
                    start: "",
                    end: "",
                    status: "",
                    offset: vm.offsetSent,
                    limit: vm.limitSent

                }),
                success: function(response, status, jQxhr) {
                    vm.loading = false;
                    //console.log(response.data.length);
                    vm.totalMessageListSent = response.data.count;
                    vm.campaign_sent = response.data.data.campaign_sent;
                    vm.sentTotal = response.data.count;
                    var list_c = response.data.data;
                    for (var i = 0; i < list_c.length; i++) {
                        
                          //  vm.selectCampaign(list_c[i]);
                            if(i==0){
                                //vm.selectCampaign(list_c[i]);
                                //console.log(vm.$route.params.id);
                                
                            }
                        
                        vm.lists.push(list_c[i]);
                    }
                },
                error: function(jQxhr, status, error) {
                    ///console.log(JSON.stringify(jQxhr.responseJSON.statusDescription));
                } 
            });
            vm.offsetSent++;
            }
        },
        getMessageStatusSchedule() {
            let vm = this;
            vm.selectedMessageTab = 2;
            
             //vm.lists = [];
            if (vm.totalMessageListSchedule === 0 || vm.totalMessageListSchedule >= ((vm.offsetSchedule -1) * vm.limitSchedule)) {
                console.log(vm.totalMessageListSchedule + " "+vm.limitSchedule);
                //console.log(vm.offset +" "+(vm.offset - 1) * vm.limit + " vm.limit "+vm.limit+" vm.totalMessageList "+vm.totalMessageList);
            
            vm.loading = true;
            $.post({
                url: store.state.rootURLBulkAPI + "v3/SMS/Reportview",
                type: "POST",
                data: JSON.stringify({
                    apiKey: this.$cookies.get("key"),
                    offset: "",
                    short_code: "",
                    message: "",
                    sort: "",
                    export: "",
                    startDate: "",
                    endDate: "",
                    status: 700,
                    offset: vm.offsetSchedule,
                    limit: vm.limitSchedule

                }),
                success: function(response, status, jQxhr) {
                    vm.loading = false;
                    //console.log(response.data.length);
                    vm.totalMessageListSchedule = response.data.Scheduled;
                    vm.campaign_sent = response.data.data.campaign_sent;
                    var list_c = response.data.data;
                    for (var i = 0; i < list_c.length; i++) {
                        vm.listsSchedule.push(list_c[i]);
                        
                        
                    }
                },
                error: function(jQxhr, status, error) {
                    ///console.log(JSON.stringify(jQxhr.responseJSON.statusDescription));
                } 
            });
            vm.offsetSchedule++;
            
            }
            console.log(vm.totalMessageListSchedule);
        },
        getMessageStatusPending() {
            let vm = this;
            vm.selectedMessageTab = 3;
             //vm.lists = [];
            if (vm.totalMessageListPending === 0 || vm.totalMessageListPending >= ((vm.offsetPending -1) * vm.limitPending)) {
                //console.log(vm.offset +" "+(vm.offset - 1) * vm.limit + " vm.limit "+vm.limit+" vm.totalMessageList "+vm.totalMessageList);
            
            vm.loading = true;
            $.post({
                url: store.state.rootURLBulkAPI + "v3/SMS/Reportview",
                type: "POST",
                data: JSON.stringify({
                    apiKey: this.$cookies.get("key"),
                    offset: "",
                    short_code: "",
                    message: "",
                    sort: "",
                    export: "",
                    startDate: "",
                    endDate: "",
                    status: 200,
                    offset: vm.offsetPending,
                    limit: vm.limitPending

                }),
                success: function(response, status, jQxhr) {
                    vm.loading = false;
                    vm.totalMessageListPending = response.data.pending;
                    vm.campaign_sent = response.data.data.campaign_sent;
                    var list_c = response.data.data;
                    for (var i = 0; i < list_c.length; i++) {
                        vm.listsPending.push(list_c[i]);
                    }
                },
                error: function(jQxhr, status, error) {
                    alertify.set('notifier', 'position', 'top-right');
                   alertify.error(jQxhr.responseJSON.statusDescription);
                } 
            });
            vm.offsetPending++;
            }
        },
        selectCampaign(selectedCampaign){
            this.selectedCampaign = selectedCampaign;
            this.selectedMessageKey = selectedCampaign.subReportId;
            bulkstore.commit("setSelectedCampaign", selectedCampaign);
            this.$refs.details.setCampaignMessageValue(selectedCampaign);
            if ($(window).width() < 640){
                this.$router.push({name: 'subscription-message-details', params: { id: selectedCampaign.subReportId}});
            }
        }
    },
    filters: {
        formatDate: function (value) {
            if (!value) return ''
            return moment(value).fromNow()
        }
    }
}
</script>

