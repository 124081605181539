<template>
    <div class="ui tiny modal" id="modal-inbox-comments">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">Add Comment</h2>
                <h4>You can add comment to {{originalName}}</h4>
            </div>
            <div class="ui negative message" v-show="sendSMSStatus">{{sendSMSResponseMessage}}</div>
            <div class="ui centered container grid">
                <div class="column">
                    <div class="ui form">
                        
                        <div class="field">
                            <div v-show="templateLists.length > 0">
                                <label>Select Template</label>
                                <div class="column">
                                    <select class="ui fluid dropdown" v-model="template" v-on:change="setComposeMessage(template)">
                                        <option value="">Select Template</option>
                                        <option v-for="s_id in templateLists" v-show="templateLists.length > 0" :key="s_id.id" :value="s_id.template">{{s_id.template_title}}</option>
    
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <label>Type your Comments</label>
                            <div class="ui top attached segment" id="textarea-sms">
                                <textarea class="l-type-message" rows="3" placeholder="Type your comment here" v-model="composedMessage"></textarea>
                            </div>
                            <div class="ui bottom attached tiny secondary segment">
                                <div class="ui equal width relaxed grid">
                                    <div class="row">
                                        <div class="column">
                                            <small>{{messageCount}} characters 
                                                    <div id="textarea-sms" v-show="dataLists.length > 0"></div>
                                                </small>
                                        </div>
                                        <div class="right aligned column"> <small>{{messagePages}}</small> Pages</div>
                                    </div>
    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui button" @click="hideModal()">Cancel</button>
            <button @click="addComment()" class="ui bg-primary button" id="button-send-message" :disabled="!sendStatus">Add Comment</button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    textarea {
        width: 100%;
        border: none;
        outline: none;
        font-size: 1.4em;
        &::placeholder {
            color: #ccc;
        }
    }
    
    .row {
        padding-top: 0 !important;
    }
</style>

<script>
    import {
        mapGetters
    } from 'vuex'
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import Choices from "choices.js";
    import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
    import format from 'date-fns/format';
    import moment from "moment";
    import Vue from "vue";
    import alertify from 'alertifyjs';
    import _ from "lodash";
    import Dropzone from "dropzone";
    
    export default {
        data() {
            return {
                //selectedList: "",
                composedMessage: '',
                listName: "",
                messagePrefix: "CO",
                droppedFile: null,
                isScheduled: false,
                dateOne: null,
                senderId: "",
                token: "",
                list_id: "",
                list_name: "",
                status: "",
                limit: "",
                offset: "",
                order: "",
                sendSMSStatus: false,
                sendSMSResponseMessage: "",
                dataLists: [],
                endMessage: "",
                composeTotalCharacters: 0,
                templateLists: [],
                template: "",
                sendStatus: true,
                contactNumber: [],
                inbox_id:""
    
    
            }
        },
        
        mounted() {
            const vm = this;
            $(".ui.dropdown").dropdown('refresh');
            vm.token = vm.$cookies.get("key");
    
            vm.getTemplateList();
            vm.fetchData();
        },
        computed: {
    
            originalName() {
                var vm = this;
                if (store.state.selectInboxDetails) {
                    vm.msisdn = store.state.selectInboxDetails.msisdn;
                    var profileID = store.state.selectInboxDetails.profile_id;
                    vm.inbox_id = store.state.selectInboxDetails.inbox_id;
    
            }
    
                return vm.msisdn;
            },
            messageCount() {
                //var defaultCount = 14;
                var phoneNu = "";
                phoneNu = window.atob(this.$cookies.get("username"));
                var phone = "";
    
                if (this.dataLists.length > 0) {
                    this.endMessage = "";
                    this.composeTotalCharacters = this.endMessage.length + this.composedMessage.length;
                } else {
                    this.endMessage = "";
                    this.composeTotalCharacters = this.endMessage.length + this.composedMessage.length;
                }
    
                return this.composeTotalCharacters;
            },
            messagePages() {
    
                if (this.composedMessage.length > 0) {
                    $('#textarea-sms').removeClass("red");
                }
    
                if (this.composeTotalCharacters == 0) {
                    return 0;
                } else if (this.composeTotalCharacters / 160 <= 1) {
                    return 1;
                } else if (this.composeTotalCharacters / 160 <= 2) {
                    return 2;
                } else if (this.composeTotalCharacters / 160 <= 3) {
                    return 3;
                } else if (this.composeTotalCharacters / 160 <= 4) {
                    return 4;
                } else if (this.composeTotalCharacters / 160 <= 5) {
                    return 5;
                } else {
                    return " Max ";
                }
            },
        },
        methods: {
            
            addComment(){
                var vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + "/v3/notify/comments",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        inbox_id: vm.inbox_id,
                        comment: vm.composedMessage,
    
                    }),
                    success: function(response, status, jQxhr) {
                        vm.sendStatus = false;
                        vm.composedMessage = "";
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.success(response.data.message);
                        vm.hideModal();
                    },
                    error: function(jQxhr, status, error) {
                        vm.sendStatus = false;
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                        vm.sendSMSStatus = true;
                        vm.sendSMSResponseMessage = jQxhr.responseJSON.statusDescription;
                    }
                });

            },
            getTemplateList() {
                var vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/account/templates/list",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        template_title: "",
                        template: "",
                        template_status: 1,
    
                    }),
                    success: function(response, status, jQxhr) {
                        if (response.data.data != "") {
                            vm.templateLists = response.data.data;
                        }
                    },
                    error: function(jQxhr, status, error) {
                        vm.templateLists = [];
                    }
                });
            },
            setComposeMessage(template) {
                let vm = this;
                vm.saveTemplateStatus = false;
                vm.composedMessage = template;
                //console.log(vm.saveTamplate);
    
            },
            hideModal() {
                $('.ui.modal#modal-inbox-comments').modal('hide');
            }
        }
    }
</script>
