<template>
    <div class="l-app-main">
        <div class="l-app-main-inboxzero bg-primary-lighter" v-show="inboxzero">
            <h2>No Sender ID...yet</h2>
            <p>Apply for a Sender ID<br />and send branded messages</p>
            <div class="padding-sm"></div>
            <button class="ui large blue button" @click="showApplySenderID()">Apply for Sender ID</button>
            <div class="padding-xl"></div>
        </div>
        <div class="l-app-main-content" v-show="!inboxzero">
            <div class="ui equal width grid eight wide computer only eight wide tablet only">
                <div class="middle aligned row">
                    <div class="column">
                        <h3>All Sender IDs</h3>
                    </div>
                    <div class="right aligned column">
                        <button class="ui bg-primary small button" @click="showApplySenderID()">Apply Sender ID</button>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <table class="ui celled styled unstackable table">
                            <thead>
                                <tr>
                                    <th class="center aligned collapsing">#</th>
                                    <th>Sender ID</th>
                                    <th>Description</th>
                                    <th class="">Status</th>
                                    <th class="center aligned">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(senderid, index) in dataLists" :key="index">
                                    <td class="center aligned">{{index+1}}</td>
                                    <td>
                                        <h4>{{senderid.short_code}}</h4><small></small></td>
                                    <td>{{senderid.purpose}}</td>
                                    <td class="">
                                        <label class="ui red label" v-show="senderid.status == 14">Cancelled</label>
                                        <label class="ui yellow label" v-show="senderid.status == 11">Pending Approval</label>
                                        <label class="ui green label" v-show="senderid.status == 13">Online Sender</label>
                                        <label class="ui gray label" v-show="senderid.status == 12">Pending Approval</label>
                                    </td>
                                    <td class="right aligned collapsing">
                                        <button class="ui tiny blue button" v-show="senderid.status == 11" @click="makePayment(senderid)">Complete Payment</button>
                                        <button class="ui tiny button" v-show="senderid.status == 11" @click="changeStatusSenderID(senderid.sender_id,3)">Cancel Application</button>
                                        <button class="ui tiny red button" v-show="senderid.status == 13">Activated Code</button>
                                        <button class="ui tiny button" v-show="senderid.status == 12"> Pending Approval</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- <div v-show="!loading" @click="fetchData" class="ui fluid button">Load More</div> -->
                    </div>
                </div>
            </div>
            <div class="mobile only ui relaxed padded grid row">
                <div class="bg-white border-top border-bottom border-light ">
                    <div class="" v-if="dataLists !=0">
                        <div class="padding-md"></div>
                        <div class="ui equal width grid">
                            <div class="middle aligned row">
                                <div class="column">
                                    <h3>All Sender IDs</h3>
                                </div>
                                <div class="right aligned column">
                                    <button class="ui blue small button" @click="showApplySenderID()">Apply Sender ID</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="column">
                                    <table class="ui very basic unstackable table">
                                        <tbody>
                                        <tr v-for="(senderid, index) in dataLists" :key="index">
                                            <td>
                                                <div class="border-right border-light padding-right-xs">
                                                    <h4>{{senderid.short_code}}</h4>
                                                    <small>
                                                        <span class="text-red" v-show="senderid.status == 14">Cancelled</span>
                                                        <span class="text-red" v-show="senderid.status == 11">Pending Approval</span>
                                                        <span class="text-green" v-show="senderid.status == 13">Online Sender</span>
                                                        <span class="text-gray" v-show="senderid.status == 12">Pending Approval</span>
                                                    </small>
                                                </div>
                                            </td>
                                            <td class="right aligned">
                                                <button class="ui tiny green button" v-show="senderid.status == 11" @click="makePayment(senderid)">Complete Payment</button>
                                                <button class="ui tiny button" v-show="senderid.status == 11" @click="changeStatusSenderID(senderid.sender_id,3)">Cancel </button>
                                                <button class="ui tiny red button" v-show="senderid.status == 13">Activated Code</button>
                                                <button class="ui tiny button" v-show="senderid.status == 12"> Pending Approval</button>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <loader v-show="loading"></loader>
        <ApplySenderID />
        <senderIDPay />
    </div>
</template>
<script>
    import loader from "@/components/loader";
    import ApplySenderID from "@/modals/apply-senderid";
    import senderIDPay from "@/modals/senderIDPay";
    import store from "@/store";
    import alertify from 'alertifyjs';
    
    export default {
        data() {
            return {
                inboxzero: false,
                dataLists: [],
                dataAmount: '',
                loading: true,
                totalSenderID: 0,
                countN: 1,
                limit: 10,
                offset: 0
            }
        },
        components: {
            ApplySenderID,
            loader,
            senderIDPay
        },
        mounted() {
            let vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            this.fetchData();
            this.$root.$on('refresh', (data) => {
            this.dataLists = [],
            this.dataAmount = '',
            this.selectedList = '';
            this.fetchData();
            });
        },
        methods: {
            changeStatusSenderID(id, status) {
                var vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + 'v3/senderId/activate',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        senderId: id,
                        status: status
                    }),
                    success: function(response, status, jQxhr) {
    
                        alertify.success("Success " + JSON.stringify(response.data.message));
                        vm.offset = 0;
                        vm.dataLists = [];
                        vm.totalSenderID = 0;
    
                        vm.fetchData();
                        //vm.$root.$emit('refresh');
    
                    },
                    error: function(jQxhr, status, error) {
    
                        alertify.error("Error " + JSON.stringify(jQxhr.responseJSON.statusDescription));
                    }
                });
            },
            showApplySenderID() {
                $(".ui.modal#modal-apply-senderid").modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            fetchData() {
    
                var vm = this;
    
                $.post({
                    url: store.state.rootURLBulkAPI + 'v3/senderId/list',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        //type_id: 3
                    }),
                    success: function(response, status, jQxhr) {
                        vm.loading = false;
                        if (response.data.code == 404) {
                            vm.inboxzero = true;
                        }
                        if (response.data.data != null) {
    
                            vm.dataLists = response.data.data;
                            //console.log(JSON.stringify(vm.dataLists));
                        }
                    },
                    error: function(jQxhr, status, error) {
                        vm.loading = false;
                        alertify.error("Error " + JSON.stringify(jQxhr.responseJSON.statusDescription));
                       // console.log(" status bulk " + status + " data " + JSON.stringify(jQxhr.responseJSON.statusDescription));
                    }
                });
    
            },
            makePayment(senderID) {
                store.commit("setSenderIDSelect",senderID);
                $(".ui.modal#modal-senderID-Pay").modal({
                    centered: false
                }).modal('show');
            },
        }
    }
</script>
