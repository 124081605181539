<template>
    <div class="l-app-main-section-container two">
        <div class="ui relaxed padded grid">
            <div class="middle aligned row">
                <div class="six wide column">
                    <h2 class="text-green text-medium">{{keyword}}</h2>
                    <h5 class="text-lightgrey">Alternative Words:  {{alternative_words}}</h5>
                    <h6 class="text-lightgrey">Updated: {{updateDate}}  </h6>
                    
                </div>
                
                <div class="ten wide right aligned column">
                    <button class="ui small button" @click="editKeyword()">Edit keyword List</button>
                     <button class="ui small green button" id="button-export" @click="generateExport()"><i class="icon download"></i>Export</button>
                     <button class="ui small red button hidden" @click="editKeyword()">Send Message</button>
                </div>
                
            </div>
            <div class="row">
                <div class="column">
                    <div class="padding-top-md"></div>
                    <div class="ui equal width middle aligned grid">
                        <div class="column">
                            <h3 class="text-medium">  {{total}} Record</h3>
                        </div>
                        <div class="right aligned column">
                            <div class="ui search link small input">
                                <input type="text" placeholder="Search Phone Number" v-on:keyup="setListValue(selectedList, false)" v-model="mobile" />
                            </div>
                        </div>
                    </div>
                    <table class="ui styled celled top attached table">
                        <thead>
                            <tr>
                                <th>Phone Number</th>
                                <th>Message</th>
                                <th class="center aligned" colspan="2"><i class="fas fa-ellipsis-h"></i></th>
   
                            </tr>
                        </thead>
                        <tbody>
                            
                            <tr v-for="list in listData" :key="list.inbox_id">
                                <td>{{list.msisdn}}</td>
                                <td>{{list.message}}</td>
                                <td class="center aligned collapsing">
                                    <button class="ui icon primary mini button" @click="sendMessageInbox(list)">Reply</button>
                                    <button class="ui icon green mini button" @click="addComments(list)">Add Comment</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-show="!loading" @click="setListValue(selectedList, false)" class="ui fluid small bottom attached button">Load More</div>
                </div>
            </div>
        </div>
        <loader v-show="loading"></loader>
        <inboxReplymessage />
        <EditKeywordList />
        <inboxComments />
    </div>

</template>

<script>
    import EditKeywordList from "@/modals/editInboxKeyword";
    import inboxReplymessage from '@/modals/inbox-reply-message';
    import inboxComments from '@/modals/inbox-comment';
    import loader from "@/components/loader";
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    import Dropzone from "dropzone";
    import moment from "moment";
    
    
    
    export default {
        components: {
            loader,
            inboxReplymessage,
            EditKeywordList,
            inboxComments
        },
        data() {
            return {
                listData: [],
                keyword:"",
                total:0,
                client_id:"",
                short_code:"",
                mobile:"",
                alternative_words:"",
                loading:false,
                totalKeywords:0,
                updateDate:"",
                selectedList:[]

            }
        },
        
        mounted() {
    
            let vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            
            if (vm.$route.params.id != "" && !isNaN(vm.$route.params.id)) {
                var listid = vm.$route.params.id;
            }
            vm.selectedList = bulkstore.state.selectKeywordInbox;
            
        },
        methods: {
            setListValue(selectedList, is_loadmore) {
                var vm = this;
                 if (!is_loadmore) {
                    
                    if (selectedList.keyword_id != vm.listData.keyword_id) {
                        
                        vm.totalKeywords = 0;
                        vm.listData = [];
                        vm.offset = 0;
                    } else {
                       
                        vm.totalKeywords = 0;
                        vm.listData = [];
                        vm.offset = 0;
                    }

    
                }
                
                if (vm.$route.params.id != "" && !isNaN(vm.$route.params.id)) {
                    vm.keyword = vm.listData[0].keyword; 
                    vm.total = vm.listData[0].message_count; 
                    vm.client_id = vm.listData[0].client_id; 
                    vm.short_code = vm.listData[0].short_code; 
                    vm.alternative_words = vm.listData[0].alternative_words; 
                    vm.updateDate = vm.listData[0].created_at;
                }
                else{
                    vm.keyword = bulkstore.state.selectKeywordInbox.keyword;
                    vm.total = bulkstore.state.selectKeywordInbox.message_count;
                    vm.client_id = bulkstore.state.selectKeywordInbox.client_id;
                    vm.short_code = bulkstore.state.selectKeywordInbox.short_code;
                    vm.alternative_words = bulkstore.state.selectKeywordInbox.alternative_words; 
                    vm.updateDate = bulkstore.state.selectKeywordInbox.created_at;
                }

    
                if (vm.totalKeywords === 0 || vm.totalKeywords >= ((vm.offset - 1) * vm.limit)) {
                    vm.offset++;
                    vm.loading = true;
                    $.post({
                        url: store.state.rootUrl + 'notify/keyword/view',
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: this.$cookies.get("key"),
                            alternative_words: vm.alternative_words,
                            short_code: vm.short_code,
                            client_id: vm.client_id,
                            msisdn: vm.mobile
                        }),
                        success: function(data, status, jQxhr) {
                            vm.loading = false;
                            var code = data.data.code;
    
                            if (code == 404) {
    
                            } else {
                                for (var i = 0; i < data.data.data.length; i++) {
                                    vm.listData.push(data.data.data[i])
                                }
                                vm.totalKeywords = data.data.data[0].total;
                            }
                        },
                        error: function(jQxhr, status, error) {
    
                            vm.loading = false;
                            // vm.contactList=[];
                            //vm.totalContacts=0;
                            var error = jQxhr.responseJSON.statusDescription;
                            if (error) {
                                alertify.error(error);
                            } else {
                                alertify.error("An error occurred ");
                            }
    
    
                        }
                    });
    
                }
    
    
    
            },
            generateExport() {
                let vm = this;
                vm.uploadStatus =  true;
                $('#button-export').html('<i class="fas fa-circle-notch fa-spin"></i> Download Please Wait ...');
                var exportRecipients = [];
                $.post({
                    url: store.state.rootUrl + 'notify/keyword/view',
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: this.$cookies.get("key"),
                            alternative_words: vm.alternative_words,
                            short_code: vm.short_code,
                            client_id: vm.client_id,
                            msisdn: vm.mobile,
                            export: 1
                        }),
                    success: function(response, status, jQxhr) {
                        $('.ui.accordion').accordion('refresh');
                        for (var i = 0; i < response.data.data.length; i++) {
                            var item = {};
                            item.msisdn = response.data.data[i].msisdn;
                            item.message = response.data.data[i].message;
                            item.created_at = response.data.data[i].created_at;
                            exportRecipients.push(item);
                        }
    
                        if(response.data.data.length < 1000){
                            var exportDate = moment().format('DD_MMMM_YYYY_h:mm');
    
                        vm.JSONToCSVConvertor(exportRecipients, "Inbox: " + vm.short_code, +"_" + exportDate, 1);
                        }
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.success("Your file has been downloaded Successful");
                        $('#button-export').html('<i class="icon download"></i> Export');
    
                    },
                    error: function(jQxhr, status, error) {
                        vm.loading = true;
                        //console.log(jQxhr)
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                        $('#button-export').html('<i class="icon download"></i> Export');
                        //console.log("failed");
                    }
                });
            },
            JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
                //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
                var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    
                var CSV = '';
                //Set Report title in first row or line
    
                //CSV += ReportTitle + '\r\n\n';
    
                //This condition will generate the Label/Header
                if (ShowLabel) {
                    var row = "";
    
                    //This loop will extract the label from 1st index of on array
                    for (var index in arrData[0]) {
    
                        //Now convert each value to string and comma-seprated
                        row += index + ',';
                    }
    
                    row = row.slice(0, -1);
    
                    //append Label row with line break
                    CSV += row + '\r\n\n\n';
                }
    
                //1st loop is to extract each row
                for (var i = 0; i < arrData.length; i++) {
                    var row = "";
    
                    //2nd loop will extract each column and convert it in string comma-seprated
                    for (var index in arrData[i]) {
                        row += '"' + arrData[i][index] + '",';
                    }
    
                    row.slice(0, row.length - 1);
    
                    //add a line break after each row
                    CSV += row + '\r\n';
                }
    
                if (CSV == '') {
                    alert("Invalid data");
                    return;
                }
    
                //Generate a file name
                var fileName = "VasPro_";
                //this will remove the blank-spaces from the title and replace it with an underscore
                fileName += ReportTitle.replace(/ /g, "_");
    
                //Initialize file format you want csv or xls
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    
                // Now the little tricky part.
                // you can use either>> window.open(uri);
                // but this will not work in some browsers
                // or you will not get the correct file extension
    
                //this trick will generate a temp <a /> tag
                var link = document.createElement("a");
                link.href = uri;
    
                //set the visibility hidden so it will not effect on your web-layout
                link.style = "visibility:hidden";
                link.download = fileName + ".csv";
    
                //this part will append the anchor tag and remove it after automatic click
                document.body.appendChild(link);
                link.click();
                link.remove();
            },
            sendMessageInbox(clientDetails) {
                let vm = this;
                store.commit("setInboxDetails", clientDetails);
                $('.ui.modal#modal-inbox-new-message').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            addComments(list){
                let vm = this;
                //console.log("am here");
                store.commit("setInboxDetails",list);
                $('.ui.modal#modal-inbox-comments').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            editKeyword() {
                $(".ui.modal#modal-edit-keyword-inbox").modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },

        }
    }
</script>
