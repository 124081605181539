<template>
    <div class="ui tiny modal" id="modal-shortcode-response">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">Edit Response Message for<br /> Short Code <strong>{{selectShortcode}}</strong></h2>
            </div>
            <div class="ui negative message" v-show="EditPaymentStatus">
                {{EditpaymentStatusDesc}}  
            </div>
            <div class="ui centered container grid">
                <div class="column">
                    <div class="ui form">
                        <div class="field">
                            <label>Type Response Message</label>
                            <div class="ui top attached segment" id="textarea-sms">
                                 <textarea class="l-type-message" placeholder="Type your response message here" v-model="responseMessage" rows="3" ></textarea>
                            </div>
                           <div class="ui bottom attached segment bg-grey-light">
                                <div class="ui equal width grid">
                                    <div class="column">
                                        <small>{{messageCount}} Characters (Depends on Placeholders) 
                                        </small>
                                    </div>
                                    <div class="right aligned column">
                                        <small>{{messagePages}} SMS</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <label>Response Url</label>
                            <div class="ui large input">
                               <input type="text" placeholder=""  required v-model="deliveryURL" />
                            </div>
                        </div>
                        <div class="field">
                            <label>Call  Back Url</label>
                            <div class="ui large input">
                               <input type="text" placeholder=""  required v-model="callbackUrl" />
                            </div>
                        </div>
                        <div class="field">
                            <div class="ui toggle checkbox">
                            <input type="checkbox" name="sendResponse" v-model="sendResponseMessage">
                            <label><strong>Send Response</strong></label>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
             <button class="ui cancel button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary button" @click="updatePaybill()">Update</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    
    export default {
        data() {
            return {
                callbackUrl:"",
                responseMessage:"",
                deliveryURL:"",
                dataLists: [],
                sendResponseMessage:true,
                sendResponse:0,
                id:"",
                EditPaymentStatus:false,
                EditpaymentStatusDesc:"",
                composeTotalCharacters:0,
                senderId:"",
                markup:0.00
            }
        },
        mounted() {
            let vm = this;
        },
        computed: {
            selectShortcode() {
                var vm = this;
                var responseShortcodeDetails = store.state.shortCodeSelect;
                if(responseShortcodeDetails !=""){
                    vm.deliveryURL = responseShortcodeDetails.dlr_callbackUrl;
                    vm.responseMessage = responseShortcodeDetails.auto_respond;
                    vm.callbackUrl = responseShortcodeDetails.callbackUrl;
                    vm.id = responseShortcodeDetails.id;
                    vm.senderId = responseShortcodeDetails.short_code;
                    if(responseShortcodeDetails.auto_status == "1")
                    {
                        vm.sendResponseMessage = true;
                    }
                    else{
                        vm.sendResponseMessage = false;
                    }
                    return vm.senderId;
                }
                else{
                    return "";
                }
            },
            
             messageCount() {
                var responseBillDetails = store.state.paybillSelect;
               if(responseBillDetails !=""){
                   console.log(JSON.stringify(responseBillDetails));
                   this.composeTotalCharacters = this.responseMessage.length;
                   return this.responseMessage.length; 
               }
               else {
                   return 0;
               }
                
            },
            messagePages() {
                 var responseBillDetails = store.state.paybillSelect;
                 if(responseBillDetails !=""){
                
                    if (this.composeTotalCharacters == 0) {
                        return 0;
                    } else if (this.composeTotalCharacters / 160 <= 1) {
                        return 1;
                    } else if (this.composeTotalCharacters / 160 <= 2) {
                        return 2;
                    } else if (this.composeTotalCharacters / 160 <= 3) {
                        return 3;
                    } else if (this.composeTotalCharacters / 160 <= 4) {
                        return 4;
                    } else if (this.composeTotalCharacters / 160 <= 5) {
                        return 5;
                    } else {
                        return " Max ";
                    }
                 }
                 else {
                     return 0;
                 }
            },
        },
   
        methods: {
            
            hideModal() {
                this.$parent.showEdit = false;
                $('.ui.modal#modal-shortcode-response').modal('hide');
    
            },
            updatePaybill(){
                let vm = this;
                if(vm.sendResponseMessage){
                    vm.sendResponse =1;
                }
                $.post({
                    url: store.state.rootUrl + 'senderId/response',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        shortcode:vm.senderId,
                        response: vm.responseMessage,
                        shortCodeStatus:1,
                        callback_url:vm.callbackUrl,
                        delivery_url:vm.deliveryURL,
                        callStatus:vm.sendResponse
                    }),
                    success: function(response, status, jQxhr) {
                        if(response.data.code != 200){
                            vm.EditPaymentStatus= true;
                            vm.EditpaymentStatusDesc = response.data.message;
                            alertify.set('notifier', 'position', 'top-right');
                            alertify.error(response.data.message);
                        }
                        else{
                            alertify.set("notifier", "position", "top-right");
                            alertify.success(response.data.message);
                            vm.hideModal();
                        }
                    },
                    error: function(jQxhr, status, error) {
                        vm.EditPaymentStatus= true;
                        alertify.set('notifier', 'position', 'top-right');
                        if(jQxhr.responseJSON.data.code == 422){
                            vm.EditpaymentStatusDesc = jQxhr.responseJSON.data.message;
                            alertify.error(jQxhr.responseJSON.data.message);
                        }
                        else{
                            vm.EditpaymentStatusDesc = jQxhr.responseJSON.statusDescription;
                            alertify.error(jQxhr.responseJSON.statusDescription);
                        }
                        
                        
                        
                    }
                });
            }
        }
    }
</script>
