<template>
    <div class="l-app-main">
        <div class="l-app-main-inboxzero bg-primary-lighter" v-show="inboxzero">
            <h2>Create a survey</h2>
            <p>Get feedback from your customers<br />and and improve your services</p>
            <div class="padding-sm"></div>
            <button class="ui large blue button" v-if="surveyPermission" @click="goToCreateSurvey()">Create a survey</button>
            <button class="ui button" v-if="!surveyPermission" @click="enableServices()">Enable Service</button>
            <div class="padding-xl"></div>
        </div>
        <div class="l-app-main-content bg-white" v-show="!inboxzero">
            <div class="ui grid">
                <div class="column">
                    <div class="l-app-campaign">
                        <div class="ui equal width grid">
                            <div class="mobile only row">
                                <div class="column">
                                    <button class="ui bg-primary fluid button" @click="goToCreateSurvey()">Create a survey</button>
                                </div>
                            </div>
                            <div class="top aligned row">
                                <div class="column l-app-survey-tab" :class="{active: activeTab == 'responses'}" @click="fetchGraphData()">
                                    <h1>{{totalResponse}}</h1>
                                    <h4>Responses</h4>
                                    <div class="padding-md"></div>
                                </div>
                                <div class="column l-app-survey-tab" :class="{active: activeTab == 'contacts'}" @click="fetchContactGraphData()">
                                    <h1>{{totalContacts}}</h1>
                                    <h4>Contacts</h4>
                                    <div class="padding-md"></div>
                                </div>
                                <div class="column l-app-survey-tab" :class="{active: activeTab == 'rate'}" @click="fetchCompleteGraphData()">
                                    <h1>{{totalCompletionRate}}%</h1>
                                    <h4>Completion</h4>
                                    <div class="padding-md"></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="column">
                                    <div class="" v-if="activeTab == 'responses'">
                                        <div class="ui equal width middle aligned grid">
                                            <div class="column">
                                                <h3 class=""><span>Responses</span> </h3>
                                                <span class="text-grey"> in the last {{dayData}} days</span>
                                            </div>

                                            <div class="computer only tablet only right aligned column">
                                                 <select class="ui dropdown" v-model="dayData" @change="fetchGraphData()">
                                                    <option value="30">Last 30 days</option>
                                                    <option value="7">Last 7 days</option>
                                                </select>
                                                <button class="ui bg-primary button margin-left-sm" @click="goToCreateSurvey()">Create a survey</button>
                                            </div>
                                            <div class="mobile only right aligned column">
                                                <select class="ui fluid dropdown" v-model="dayData" @change="fetchGraphData()">
                                                    <option value="30">Last 30 days</option>
                                                    <option value="7">Last 7 days</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="padding-md"></div>
                                        <div class="" :class="{loading: loading}">
                                            <canvas id="chart-responses" height="250"></canvas>
                                        </div>
                                    </div>
                                    <div class="" v-if="activeTab == 'contacts'">
                                        <div class="ui equal width middle aligned grid">
                                            <div class="column">
                                                <h3 class=""><span>Contacts</span> </h3>
                                                <span class="text-grey"> in the last {{dayData}} days</span>
                                            </div>

                                            <div class="computer only tablet only right aligned column">
                                                 <select class="ui dropdown" v-model="dayData" @change="fetchContactGraphData()">
                                                    <option value="30">Last 30 days</option>
                                                    <option value="7">Last 7 days</option>
                                                </select>
                                                <button class="ui bg-primary button margin-left-sm" @click="goToCreateSurvey()">Create a survey</button>
                                            </div>
                                            <div class="mobile only right aligned column">
                                                <select class="ui fluid dropdown" v-model="dayData" @change="fetchContactGraphData()">
                                                    <option value="30">Last 30 days</option>
                                                    <option value="7">Last 7 days</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="padding-md"></div>
                                        <div class="" :class="{loading: loading}">
                                            <canvas id="chart-contacts" height="250"></canvas>
                                        </div>
                                    </div>
                                    <div class="" v-if="activeTab == 'rate'">
                                        <div class="ui equal width middle aligned grid">
                                            <div class="column">
                                                <h3 class=""><span>Completion Rate</span> </h3>
                                                <span class="text-grey"> in the last {{dayData}} days</span>
                                            </div>

                                            <div class="computer only tablet only right aligned column">
                                                 <select class="ui dropdown" v-model="dayData" @change="fetchCompleteGraphData()">
                                                    <option value="30">Last 30 days</option>
                                                    <option value="7">Last 7 days</option>
                                                </select>
                                                <button class="ui bg-primary button margin-left-sm" @click="goToCreateSurvey()">Create a survey</button>
                                            </div>
                                            <div class="mobile only right aligned column">
                                                <select class="ui fluid dropdown" v-model="dayData" @change="fetchCompleteGraphData()">
                                                    <option value="30">Last 30 days</option>
                                                    <option value="7">Last 7 days</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="padding-md"></div>
                                        <div class="" :class="{loading: loading}">
                                            <canvas id="chart-rate" height="250"></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" >
                                <div class="computer only tablet only column">
                                    <div class="l-app-survey">
                                        <div class="survey" v-for="survey in surveySummary" :key="survey.app_id">
                                            <div class="survey-title" @click="selectSurvey(survey)">
                                                <h3 class="padding-bottom-xs text-title">{{survey.campaign_name}}</h3>
                                                <span class="lab" v-if="survey.status==2">Inactive</span>
                                                <span class="lab orange" v-if="survey.status==0">Pending</span>
                                                 <span class="lab green" v-if="survey.status==1">Active</span><br />
                                                <small class="text-grey"><span v-if="survey.updated_at">Last response: {{survey.updated_at | formatDate}} </span>  Created:   {{survey.surveyDate | formatDate}}</small>
                                            </div>
                                            <div class="survey-metric" @click="selectSurvey(survey)"></div>
                                            <div class="survey-sent" @click="selectSurvey(survey)">
                                                <h5 class="text-grey-light" v-if="survey.complete==0">No data</h5>
                                                <h3 class="" v-if="survey.complete > 0">{{survey.complete}}</h3>
                                                <h5 class="text-grey" v-if="survey.complete > 0">Completed</h5>
                                            </div>
                                            <div class="survey-responses" @click="selectSurvey(survey)">
                                                <h3 class="">{{survey.contacts}}</h3>
                                                <h5 class="text-grey">Contacts</h5>
                                            </div>
                                            <div class="survey-rate" @click="selectSurvey(survey)">
                                                <h3 class="">
                                                    <span class="text-grey-light text-medium" v-if="survey.progressRate<=0">{{survey.progressRate}}% </span> 
                                                    <span class="text-primary text-medium" v-if="survey.progressRate >= 80">{{survey.progressRate}}% </span> 
                                                    <span class="text-orange text-medium" v-if="survey.progressRate>0 && survey.progressRate< 80">{{survey.progressRate}}% </span> 
                                                    <span class="text-grey-light">completion</span>
                                                </h3>
                                                <div class="ui tiny progress margin-bottom-xs margin-top-sm" :data-value="survey.complete" :data-total="survey.contacts">
                                                    <div class="bar"></div>
                                                </div>
                                            </div>
                                            <div class="survey-options">
                                                <div class="ui icon dropdown top right pointing button">
                                                    <i class="icon dropdown"></i>
                                                    <div class="menu">
                                                        <div class="item" v-if="survey.status!=1" @click=" updateStatusSurvey(survey, '1')">Start survey</div>
                                                        <div class="ui divider" v-if="survey.status!=1"></div>
                                                        <div class="item" @click="selectSurvey(survey)">Edit survey</div>
                                                        <div class="item" v-if="survey.status==0" @click=" updateStatusSurvey(survey, '0')">Deactivate survey</div>
                                                        <div class="item" v-if="survey.status!=1" @click=" updateStatusSurvey(survey, '3')">Delete survey</div>
                                                        <div class="item" v-if="survey.status==1" @click=" updateStatusSurvey(survey, '2')">Stop survey</div>
                                                        <div class="item" v-if="survey.status==0" @click="updateSurvey(survey)">Edit name</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-show="!loading" @click="fetchSurveySummary" class="ui button">Load More</div>
                                    <loader v-show="loading"></loader>
                                </div>
                            </div>
                            <div class="row bg-grey-lighter"></div>
                            <div class="mobile only row border-bottom border-light">
                                <div class="column">
                                    <table class="ui celled very basic unstackable table">
                                        <thead>
                                            <tr>
                                                <td class="border-bottom border-light">Surveys</td>
                                                <td class="border-bottom border-light right aligned">Response</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="survey in surveySummary" :key="survey.app_id">
                                                <td @click="selectSurvey(survey)">
                                                    <h4 class="text-title text-medium">{{survey.campaign_name}}</h4>
                                                    <h6 class="text-grey padding-bottom-xs" v-if="survey.updated_at">Last response {{survey.updated_at | formatDate}} | Created  {{survey.surveyDate | formatDate}}</h6>
                                                    <span class="lab" v-if="survey.status==2">Inactive</span>
                                                    <span class="lab orange" v-if="survey.status==0">Pending</span>
                                                    <span class="lab green" v-if="survey.status==1">Active</span>
                                                </td>
                                                <td class="right aligned">
                                                    <h4>{{survey.progressRate}}%</h4>
                                                    <small>{{survey.complete}} of {{survey.contacts}} completed</small>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <createSurvey />
        <surveyStatus />
        <surveyEdit />
    </div>
</template>

<script>
    import surveyStatus from "@/modals/surveyStatus";
    import surveyEdit from "@/modals/survey-name-edit";
    import Chart from 'chart.js';
    import store from "@/store";
    import Choices from "choices.js";
    import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
    import format from 'date-fns/format';
    import Vue from "vue";
    import alertify from 'alertifyjs';
    import loader from "@/components/loader";
    import moment from "moment";
    import createSurvey from "@/modals/survey-create"
    import { setTimeout } from 'timers';
    export default {
        data() {
            return {
                inboxzero: false,
                suverys: [],
                surveySummary: [],
                resp:[],
                totalSurvey: 0,
                offset: 0,
                limit: 5,
                loading: false,
                activeTab: 'responses',
                totalContacts:0,
                totalResponse:0,
                totalCompletionRate:0,
                dayData:30,
                surveyPermission: null,
                memberPermission: null
            }
        },
        components: {
            loader,
            createSurvey,
            surveyStatus,
            surveyEdit
        },
        mounted() {
            let vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            $('.ui.toggle.checkbox').checkbox();
            $('.ui.progress').progress();
            this.fetchSummaryTop();
            this.fetchSurveySummary();
            this.fetchGraphData();
            this.fetchContactGraphData();
            this.fetchCompleteGraphData();

        },
        methods: {
            goToCreateSurvey() {
                $("#modal-create-survey").modal({
                    centered: false
                }).modal("show");
            },
            sendSurveyMessage(){
            this.$parent.showCreateSurveyMessage = true;
                console.log("clicked " + this.$parent.showCreateSurveyMessage);
            },
            enableServices(){
                let vm = this;
                 $.post({
                    url: store.state.rootURLBulkAPI + 'v2/users/profile',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                    }),
                    success: function(response, status, jQxhr) {
                        vm.memberPermission = response.data.data.permission_acl;
                        var user_mapId = response.data.data.user_mapId;
                        vm.updateServices(user_mapId,vm.memberPermission+":47:49:37:38:39:50:51:52:53");
                    },
                    error: function(jQxhr, status, error) {
                    }
                });
            },
            updateServices(user_mapId,permission){
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + "v2/users/permission/edit",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        user_mapId: user_mapId,
                        permission_acl: permission
                    }),
                    success: function(response, status, jQxhr) {
                        if (response.data.code != 200) {
                            alertify.set("notifier", "position", "top-right");
                            alertify.error(response.data.message);
                        } else {
                            vm.fetchSummaryTop();
                            vm.fetchSurveySummary();
                            vm.fetchCompleteGraphData();
                            alertify.set("notifier", "position", "top-right");
                            alertify.success(response.data.message);
                        }
                    },
                    error: function(jQxhr, status, error) {
                        alertify.set("notifier", "position", "top-right");
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            },
            selectSurvey(survey) {
                let vm = this;
                setTimeout(function(){
                    store.commit("setSurveySelect", survey);
                    vm.$router.push({name: 'survey-view', params: {id: survey.app_id}});
                }, 100);
            },
            updateStatusSurvey(survey, status) {
                store.commit("setSurveySelect", survey);
                store.commit("setSurveyStatus",status);
                store.commit("setSurveyID",survey.app_id);
                
                $('.ui.modal#modal-Survey_status').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            updateSurvey(survey) {
                store.commit("setSurveySelect", survey);
                $('.ui.modal#modal-Survey-Edit-Name').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            fetchSurveySummary() {
                let vm = this;
                
                if (vm.totalSurvey == 0 || vm.totalSurvey >= ((vm.offset - 1) * vm.limit)) {
                    vm.offset++;
                    vm.loading = true;
                    //console.log("Total survey: "+vm.totalSurvey);
                    $.post({
                        url: store.state.rootURLBulkAPI + "v3/survey/view/summary",
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: vm.$cookies.get("key"),
                            limit: vm.limit,
                            offset: vm.offset
                        }),
                        success: function(response, status, jQxhr) {
                            vm.loading = false;
                            vm.resp = response.data.data;
                            if (response.data.code != 200) {
                                //alertify.set('notifier', 'position', 'top-right');
                                //alertify.error(response.data.message);
        
                            } else {
                                vm.totalSurvey = response.data.data[0].count;

                                for (var i = 0; i < vm.resp.length; i++) {
                                    ///console.log(" loop " + i + " " + JSON.stringify(resp[i]));
                                    vm.resp[i].progressRate = vm.resp[i].contacts !=0 ? ((parseInt(vm.resp[i].complete) / parseInt(vm.resp[i].contacts)) *100).toFixed(0) :0;
                                    vm.resp[i].response = vm.resp[i].response !=null ? vm.resp[i].response : 0;
                                    vm.resp[i].complete = vm.resp[i].complete !=null ? vm.resp[i].complete : 0;
                                    vm.resp[i].progress = vm.resp[i].progress !=null ? vm.resp[i].progress : 0;
                                    vm.surveySummary.push(vm.resp[i]);
                                }
                                setTimeout(function() {
                                    $(".ui.dropdown").dropdown('refresh');
                                    $('.ui.progress').progress();
                                    $('.ui.toggle.checkbox').checkbox();
                                }, 100)
                                
                            }
                        },
                        error: function(jQxhr, status, error) {
                            vm.loading = false;
                            vm.offset = 0;
                            alertify.set('notifier', 'position', 'top-right');
                           // alertify.error(jQxhr.responseJSON.statusDescription);
                        }
                    });
                 }
            },
            
            fetchSummaryTop(){
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/view/summary",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        export: 1
                    }),
                    success: function(response, status, jQxhr) {
                        var resp = response.data.data;
                        //console.log(response);
                        if (response.data.code == 200) {
                            vm.inboxzero = false;
                            vm.surveyPermission = true;
                            var totalRecord = response.data.data[0].count;
                            var responseRate = 0;
                            for (var i = 0; i < resp.length; i++) {
                                // console.log(" loop " + i + " " + JSON.stringify(resp[i]));
                                resp[i].progressRate = resp[i].contacts !=0 ? ((parseInt(resp[i].complete) / parseInt(resp[i].contacts)) *100).toFixed(0) :0;
                                resp[i].response = resp[i].response !=null ? resp[i].response : 0;
                                resp[i].complete = resp[i].complete !=null ? resp[i].complete : 0;
                                resp[i].progress = resp[i].progress !=null ? resp[i].progress : 0;
                               
                                vm.totalContacts = Number(vm.totalContacts) + Number(resp[i].contacts);
                                vm.totalResponse = Number(vm.totalResponse) + Number(resp[i].response);
                                responseRate = Number(responseRate) + Number(resp[i].progressRate);
                            }
                            vm.totalCompletionRate = (responseRate / totalRecord).toFixed(0);
                        } else {
                            vm.inboxzero = true;
                            vm.surveyPermission = true;
                        }
    
                    },
                    error: function(jQxhr, status, error) {
                        if (jQxhr.responseJSON.data.code == 403){
                            console.log("forbidden");
                            vm.inboxzero = true;
                            vm.surveyPermission = false;
                        } 
                    }
                });
            },
            fetchGraphData(){
                let vm = this;
                vm.activeTab = 'responses'
                vm.loading = true;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/response/graph",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        days: vm.dayData
                    }),
                    success: function(response, status, jQxhr) {
                       
                        let yData = [];
                        let xDate = [];
                        let result = response.data.data;

                        result.forEach(function(data){
                            yData.push(parseInt(data["COUNT"]));
                            xDate.push(data["day"]);
                        });
                        // Charts
                        var responseContainer = $('#chart-responses');
                        var responseChart = new Chart(responseContainer, {
                            type: 'bar',
                            data: {
                                labels: xDate,
                                datasets: [{
                                    label: 'Responses',
                                    data: yData,
                                    backgroundColor: '#aaa',
                                    borderColor: '#aaa',
                                    hoverBackgroundColor: '#26A842',
                                    hoverBorderColor: '#26A842',
                                    borderWidth: 1
                                }]
                            },
                            options: {
                                maintainAspectRatio: false,
                                responsive: true,
                                legend: {
                                    display: false
                                },
                                scales: {
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        },
                                    }],
                                    xAxes: [{
                                        gridLines: {
                                            display: false,
                                        }
                                    }]
                                }
                            }
                        });
                        vm.loading = false;
                        
                    },
                    error: function(jQxhr, status, error) {
                        vm.loading = false;
                    }
                });

            },
            fetchContactGraphData(){
                let vm = this;
                vm.activeTab = 'contacts'
                vm.loading = true;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/contacts/graph",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        days: vm.dayData
                    }),
                    success: function(response, status, jQxhr) {
                       
                        let yData = [];
                        let xDate = [];
                        let result = response.data.data;

                        result.forEach(function(data){
                            yData.push(parseInt(data["COUNT"]));
                            xDate.push(data["day"]);
                        });
                        // Charts
                        var responseContainer = $('#chart-contacts');
                        var responseChart = new Chart(responseContainer, {
                            type: 'bar',
                            data: {
                                labels: xDate,
                                datasets: [{
                                    label: 'Contacts',
                                    data: yData,
                                    backgroundColor: '#aaa',
                                    borderColor: '#aaa',
                                    hoverBackgroundColor: '#26A842',
                                    hoverBorderColor: '#26A842',
                                    borderWidth: 1
                                }]
                            },
                            options: {
                                maintainAspectRatio: false,
                                responsive: true,
                                legend: {
                                    display: false
                                },
                                scales: {
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        },
                                    }],
                                    xAxes: [{
                                        gridLines: {
                                            display: false,
                                        }
                                    }]
                                }
                            }
                        });
                        vm.loading = false;
                        
                    },
                    error: function(jQxhr, status, error) {
                        vm.loading = false;
                    }
                });

            },
            fetchCompleteGraphData(){
                let vm = this;
                vm.activeTab = 'rate'
                vm.loading = true;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/completion/graph",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        days: vm.dayData
                    }),
                    success: function(response, status, jQxhr) {
                       
                        let yData = [];
                        let xDate = [];
                        let result = response.data.data;

                        result.forEach(function(data){
                            yData.push(parseInt(data["COUNT"]));
                            xDate.push(data["day"]);
                        });
                        // Charts
                        var responseContainer = $('#chart-rate');
                        var responseChart = new Chart(responseContainer, {
                            type: 'bar',
                            data: {
                                labels: xDate,
                                datasets: [{
                                    label: 'rate',
                                    data: yData,
                                    backgroundColor: '#aaa',
                                    borderColor: '#aaa',
                                    hoverBackgroundColor: '#26A842',
                                    hoverBorderColor: '#26A842',
                                    borderWidth: 1
                                }]
                            },
                            options: {
                                maintainAspectRatio: false,
                                responsive: true,
                                legend: {
                                    display: false
                                },
                                scales: {
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        },
                                    }],
                                    xAxes: [{
                                        gridLines: {
                                            display: false,
                                        }
                                    }]
                                }
                            }
                        });
                        vm.loading = false;
                        
                    },
                    error: function(jQxhr, status, error) {
                        vm.loading = false;
                    }
                });

            },
            reload(){
                let vm = this;
                console.log("survey reload took place");
                vm.suverys = [];
                vm.surveySummary = [];
                vm.offset= 0;
                vm.fetchSurveySummary();
                vm.fetchSummaryTop();
            }
        },
        filters: {
            formatDate: function(value) {
                if (!value) return ''
                return moment(value).fromNow()
            }
        }
    }
</script>
