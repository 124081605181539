<template>
    <div class="ui tiny modal" id="modal-edit-contact">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md"/>
                <h2 class="padding-bottom-sm">Edit Contact {{originalName}}</h2>
            </div>
            <div class="ui centered container grid">
                <div class="column">
                    <div class="ui form">
                        <div class="field">
                            <label>Phone</label>
                            <div class="ui large input">
                               <input type="text" disabled placeholder=""  required v-model="phone" />
                            </div>
                        </div>
                        <div class="field">
                            <label>Names</label>
                            <div class="ui large input">
                               <input type="text" placeholder=""  required v-model="custom_field1" />
                            </div>
                        </div>
                        <div class="field" v-show="custom2">
                            <label>{{custom2}}</label>
                            <div class="ui large input">
                               <input type="text" placeholder=""  required v-model="custom_field2" />
                            </div>
                        </div>
                        <div class="field" v-show="custom3">
                            <label>{{custom3}}</label>
                            <div class="ui large input">
                               <input type="text" placeholder=""  required v-model="custom_field3" />
                            </div>
                        </div>
                        <div class="field" v-show="custom4">
                            <label>{{custom4}}</label>
                            <div class="ui large input">
                               <input type="text" placeholder=""  required v-model="custom_field4" />
                            </div>
                        </div>
                        <div class="field" v-show="custom5">
                            <label>{{custom5}}</label>
                            <div class="ui large input">
                               <input type="text" placeholder=""  required v-model="custom_field5" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary button" @click="updateContact()">Save</button>
        </div>
    </div>
</template>

<script>
import store from "@/store";
import bulkstore from "@/bulk/bulksms-state";
import alertify from 'alertifyjs';

export default {
     data(){
        return {
            selectedList:bulkstore.state.selectedContact,
            customList: bulkstore.state.customs,
            phone:'',
            custom_field1:'',
            custom_field2:'',
            custom_field3:'',
            custom_field4:'',
            custom_field5:'',
            custom_field: "",
            custom2:"",
            custom3:"",
            custom4:"",
            custom5:"",
            contact_id:null,
            profile_id:null
           // inboxzero: false,
        }
    },
    computed:{
           originalName() {
               var vm =this;
               vm.phone = bulkstore.state.selectedContact.mobile;
               vm.custom_field1 = bulkstore.state.selectedContact.custom1;
               vm.custom_field2 = bulkstore.state.selectedContact.custom2;
               vm.custom_field3 = bulkstore.state.selectedContact.custom3;
               vm.custom_field4 = bulkstore.state.selectedContact.custom4;
               vm.custom_field5 = bulkstore.state.selectedContact.custom5;
               vm.custom2 = bulkstore.state.customs.custom2;
               vm.custom3 = bulkstore.state.customs.custom3;
               vm.custom4 = bulkstore.state.customs.custom4;
               vm.custom5 = bulkstore.state.customs.custom5;
               vm.contact_id = bulkstore.state.selectedContact.contact_id;
               vm.profile_id = bulkstore.state.selectedContact.profile_id;
               console.log("Custom Fil" +JSON.stringify(bulkstore.state.selectedList));
               return vm.phone;
               
            }
       },
    methods: {
        hideModal(){
            $('.ui.modal#modal-edit-contact').modal('hide');
            
        },
         updateContact(){
                var vm = this;
                $.post({
                    url:bulkstore.state.bulksmsUrl+'contact/contact/update',
                    type:"POST",
                    data:JSON.stringify(
                        {   apiKey: vm.$cookies.get("key"),
                            contact_id: vm.contact_id,
                            profile_id: vm.profile_id,
                            custom1: vm.custom_field1,
                            custom2: vm.custom_field2,
                            custom3: vm.custom_field3,
                            custom4: vm.custom_field4,
                            custom5: vm.custom_field5,
                            
                        }
                    ),
                    success:function(data,status,jQxhr){
                        //mixpanel.track("Updated contact list");
                       // console.log("list success "+JSON.stringify(data.statusDescription));
                        $('#btn-save-edit').text('Save').removeAttr("disabled");
                        if(data.statusDescription){
                            alertify.success(data.statusDescription + " End");
                        }
                        else{
                            alertify.success("Alert Edit successful");
                        }
                        vm.hideModal();
                        vm.$root.$emit('refreshList');
                        
                    },
                    error:function (jQxhr,status,error) {
                        $('#btn-save-edit').text('Save').removeAttr("disabled");
                        var  error =jQxhr.responseJSON.statusDescription;
                        if(error){
                            alertify.error(error);

                        }
                        else {
                            alertify.error("An error occurred ");
                        }

                    }
                });
            }
        
    }
}
</script>
