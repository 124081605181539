<template>
    <div class="bg-white">
        <div class="ui padded relaxed grid bg-white l-app-main-top">
            <div class="middle aligned row bg-primary-lighter border-bottom padding-bottom-none">
                <div class="six wide column">
                    <h2 class="text-medium text-title">{{questionnaireName}}</h2>
                    <small class="hidden">Created a few seconds ago</small>
                    <div class="padding-sm"></div>
                </div>
                <div class="right aligned column"></div>
            </div>
        </div>
        <div class="l-app-main-segment">
            <createQuestionnaire />
        </div>
    </div>
</template>


<script>
import createQuestionnaire from "./create-questions";
import store from "@/store";
import alertify from 'alertifyjs';
import loader from "@/components/loader";
export default {
     data() {
        return {
            questionnaireID: "",
            questionnaireName:""
        }
    },
    components: {
        createQuestionnaire
    },
    mounted() {
        let vm = this;
        console.log(JSON.stringify(vm.$route.name))
        vm.questionnaireID = vm.$route.params.id;
        vm.getCampaignRecords();
    },
    methods: {
        getCampaignRecords(){
        let vm = this
        $.post({
            url: store.state.rootURLBulkAPI + "v3/survey/view/questionaire",
            type: "POST",
            data: JSON.stringify({
                apiKey: vm.$cookies.get("key"),
                questionnaireID:vm.questionnaireID
            }),
            success: function(response, status, jQxhr) {
                
                vm.questionnaireName = response.data.data[0].questionnare_name

            },
            error: function(jQxhr, status, error) {
                alertify.set('notifier', 'position', 'top-right');
                // alertify.error(jQxhr.responseJSON.statusDescription);
            }
        });

    },
    }
}
</script>
