<template>
    <div class="ui tiny modal" id="modal-utility-save-cancel">
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="50" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">You are about to cancel saved utility {{originalName}}</h2>
                <div class="ui negative message" v-show="userStatus">{{userStatusDesc}}</div>
            </div>
        </div>
        <div class="actions">
            <button class="ui button" @click="cancelModal()">Cancel</button>
             <button class="ui button bg-primary" @click="confirmCancel()">Confirm</button>
        </div>
    </div>
</template>

<script>
 import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    export default {
        data: function() {
            return {
                password: "",
                userStatus: false,
                userStatusDesc:"",
                csvAirtime:[],
                totalCashAirtime:0,
                totalAirtimeFee:0,
                totalVATAirtime:0,
                totalAirtimeCost:0,
                unique_id:"",
                list_name:"",
                saveId:""
            }
        },
        computed:{
           originalName() {
               var vm =this;
               vm.totalAirtimeFee = store.state.selectedUtilites.processing_fee;
               vm.totalVATAirtime = store.state.selectedUtilites.tax_amount;
               vm.totalAirtimeCost = store.state.selectedUtilites.totalCost;
               vm.unique_id = store.state.selectedUtilites.unique_id;
               vm.csvAirtime = store.state.selectedUtilites.payload;
               vm.list_name = store.state.selectedUtilites.list_name;
               vm.saveId = store.state.selectedUtilites.id;
               vm.utility_id = store.state.selectedUtilites.utility_id;

               return vm.list_name;
               console.log("Custom Fil" +store.state.selectedUtilites);
            }
        },
        methods: {
            confirmCancel() {
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + 'v3/rewards/update',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        status: 2,
                        saveId:vm.saveId,
                        utilityId:vm.utility_id,
                    }),
                    success: function(response, status, jQxhr) {
                       alertify.set("notifier", "position", "top-right");
                       alertify.success(response.data.message);
                       vm.hideModal()
                       vm.$parent.getSaveList(false);
                    },
                    error: function(jQxhr, status, error) {
                        vm.userStatus = true;
                        vm.userStatusDesc = jQxhr.responseJSON.statusDescription;
                        alertify.set("notifier", "position", "top-right");
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            },
           
            hideModal() {
                $('.ui.modal#modal-utility-save-cancel').modal('hide');
            },
            cancelModal(){
               let vm = this;
               $('.ui.modal#modal-utility-save-cancel').modal('hide');
            }
        }
    }
</script>
