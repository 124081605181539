<template>
  <div class="l-app-main">
    <div class="l-app-dashboard bg-primary-lighter">
      <div class="ui equal width relaxed padded grid">
        <div class="middle aligned row">
          <div class="column">
            <h3>All Contacts</h3>
            <h4>from shortcode</h4>
          </div>
          <div class="right aligned column">
            <button class="ui bg-primary button" @click="addKeyword">Create</button>
          </div>
        </div>
        <div class="computer only tablet only row">
          <div class="column">
            <div class="ui relaxed padded segment" :class="{ loading: dataLoading }">
              <div class="ui grid" :class="{ loading: dataLoading }">
                <div
                  class="five wide computer six wide tablet sixteen wide mobile column"
                >
                  <h3 class="text-medium">Contacts</h3>
                  <h1 class="text-green">
                    <strong>{{ totalContacts }}</strong>
                  </h1>
                  <h4 class="text-grey">added through Shortcode</h4>
                  <div class="ui divider"></div>
                </div>
                <div
                  class="eleven wide computer ten wide tablet sixteen wide mobile column"
                >
                  <h3 class="padding-bottom-md">Contacts Added</h3>
                  <div><canvas id="contacts"></canvas></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="computer only tablet only equal width row">
          <div class="column">
            <div class="ui segment height-lg" :class="{ loading: dataLoading }">
              <h3 class="text-title text-medium">By Keywords</h3><br />
              <div class="ui search link input">
                <input
                  type="text"
                  placeholder="Search Keywords"
                  v-on:keyup="getAllkeyword()"
                  v-model="searchKeyword"
                />
              </div>
              <table class="ui very basic table">
                <tr v-for="dataList in dataLists" v-bind:key="dataList.list_id">
                  <td>
                    <h4>{{ dataList.keyword }}</h4>
                    <small class="text-grey">{{ dataList.title }}</small
                    ><br />
                    <small class="text-red" v-show="dataList.prime == 1">Prime</small>
                  </td>
                  <td class="left aligned">{{ dataList.total }}</td>

                  <td class="right aligned">
                    <div class="ui icon floating dropdown button">
                      <i class="icon dropdown"></i>
                      <div class="menu">
                        <a @click="selectList(dataList)" class="item">View</a>
                        <a @click="editKeyword(dataList)" class="item">Edit</a>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="column">
            <div class="ui segment height-md" :class="{ loading: dataLoadingHr }">
              <h3 class="text-title text-medium">By Time of Day</h3>
              <div class="padding-top-sm">
                <canvas id="contacts-2" class="height-sm"></canvas>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="ui segment height-md" :class="{ loading: dataLoadingNet }">
              <h3 class="text-title text-medium">By Mobile Network</h3>
              <table class="ui very basic table">
                <tr v-for="networkList in networkLists" v-bind:key="networkList.total">
                  <td>{{ networkList.network }}</td>
                  <td class="right aligned">{{ networkList.total }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div class="mobile only equal width row">
          <div
            class="sixteen wide column padding-top-md bg-white border-light border-top border-bottom"
            :class="{ loading: dataLoading }"
          >

            <h3 class="text-title text-medium">By Keywords</h3>
            <div class="ui search link input">
            <input
              type="text"
              placeholder="Search Keywords"
              v-on:keyup="getAllkeyword()"
              v-model="searchKeyword"
            />
          </div>
            <table class="ui very basic unstackable table">
              <tr v-for="dataList in dataLists" v-bind:key="dataList.list_id">
                <td>
                  <h4>{{ dataList.keyword }}</h4>
                  <small class="text-grey">{{ dataList.title }}</small>
                </td>
                <td>{{ dataList.total }}</td>

                <td class="right aligned">
                  <div class="ui icon floating dropdown button">
                    <i class="icon dropdown"></i>
                    <div class="menu">
                      <a @click="selectList(dataList)" class="item">View</a>
                      <a @click="editKeyword(dataList)" class="item">Edit</a>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div
            class="sixteen wide column padding-top-md bg-white border-light border-top border-bottom hidden"
          >
            <h3 class="text-title text-medium">By Time of Day</h3>
            <div class="padding-top-sm"></div>
            <div class="height-md">
              <canvas id="contacts-2"></canvas>
            </div>
          </div>
          <div
            class="sixteen wide column padding-top-md bg-white border-light border-top border-bottom height-md margin-top-sm"
          >
            <h3 class="text-title text-medium">By Mobile Network</h3>
            <table class="ui very basic unstackable table">
              <tr v-for="networkList in networkLists" v-bind:key="networkList.total">
                <td>{{ networkList.network }}</td>
                <td class="right aligned">{{ networkList.total }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="padding-md"></div>
      </div>
    </div>
    <AddKeyword />
    <EditKeyword />
  </div>
</template>

<script>
import AddKeyword from "../modals/add-keyword";
import EditKeyword from "../modals/edit-keyword";
import bulkstore from "@/bulk/bulksms-state";
import store from "../store";
export default {
  data() {
    return {
      offset: 0,
      busy: false,
      totalKeyword: 0,
      dataLists: [],
      totalContacts: 0,
      networkLists: [],
      selectedList: "",
      selectedKey: null,
      dataLoading: false,
      dataLoadingHr: false,
      dataLoadingNet: false,
      searchKeyword:"",
    };
  },
  components: {
    AddKeyword,
    EditKeyword,
  },
  mounted() {
    this.getGraphDataDay();
    this.getGraphDataHr();
    this.getAllkeyword();
    this.getAllNetwork();
    this.$root.$on("refreshKeyword", (data) => {
      vm.dataLists = [];
      vm.getAllkeyword();
    });
  },
  methods: {
    addKeyword() {
      $("#modal-add-keyword")
        .modal({
          centered: false,
        })
        .modal("show");
    },
    getGraphDataDay() {
      let vm = this;
      vm.dataLoading = true;
      $.post({
        url: store.state.rootURLBulkAPI + "v3/contact/keyword/graph",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
          type: 1,
        }),
        success: function (response, status, jQxhr) {
          vm.graphData = response.data.data;
          vm.dataLoading = false;

          let yData = [];
          let xDate = [];
          let result = response.data.data;

          result.forEach(function (data) {
            yData.push(parseInt(data["total"]));
            xDate.push(data["ddate"]);
          });
          let ctx2 = $("#contacts");
          let myChart2 = new Chart(ctx2, {
            type: "line",
            data: {
              labels: xDate,
              datasets: [
                {
                  data: yData,
                  backgroundColor: ["#26A842"],
                  borderColor: ["#26A842"],
                  borderWidth: 1,
                },
              ],
            },
            options: {
              maintainAspectRatio: false,
              responsive: true,
              layout: {
                padding: {
                  right: 0,
                },
              },
              legend: {
                display: false,
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
              elements: {
                line: {
                  tension: 0, // disables bezier curves
                },
              },
            },
          });
        },
        error: function (jQxhr, status, error) {
          vm.loading = false;
        },
      });
    },
    getGraphDataHr() {
      let vm = this;
      vm.dataLoadingHr = true;
      $.post({
        url: store.state.rootURLBulkAPI + "v3/contact/keyword/graph",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
        }),
        success: function (response, status, jQxhr) {
          vm.graphData = response.data.data;
          vm.dataLoadingHr = false;
          let yData = [];
          let xDate = [];
          let result = response.data.data;

          result.forEach(function (data) {
            yData.push(parseInt(data["total"]));
            xDate.push(data["ddate"]);
          });
          let ctx2 = $("#contacts-2");
          let myChart2 = new Chart(ctx2, {
            type: "line",
            data: {
              labels: xDate,
              datasets: [
                {
                  data: yData,
                  backgroundColor: ["#26A842"],
                  borderColor: ["#26A842"],
                  borderWidth: 1,
                },
              ],
            },
            options: {
              maintainAspectRatio: false,
              responsive: true,
              layout: {
                padding: {
                  right: 0,
                },
              },
              legend: {
                display: false,
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
              elements: {
                line: {
                  tension: 0, // disables bezier curves
                },
              },
            },
          });
        },
        error: function (jQxhr, status, error) {
          vm.loading = false;
        },
      });
    },
    getAllkeyword() {
      var vm = this;
      vm.dataLoading = true;
      vm.dataLists =[]
      vm.totalContacts = 0;
      $.post({
        url: store.state.rootUrl + "contact/keyword/view",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
          limit: 30,
          keyword: vm.searchKeyword,
          search_words: vm.searchKeyword
        }),
        success: function (response, status, jQxhr) {
          vm.dataLoading = false;
          setTimeout(function () {
            $(".ui.dropdown").dropdown();
          }, 100);
          if (response.data.data != "") {
            vm.dataLists = response.data.data;
            vm.totalContacts = response.data.total_contacts;
          }
        },
        error: function (jQxhr, status, error) {
          vm.dataLoading = false;
          vm.dataLists = [];
        },
      });
    },
    getAllNetwork() {
      var vm = this;
      vm.dataLoadingNet = true;
      $.post({
        url: store.state.rootUrl + "contact/keyword/network",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
        }),
        success: function (response, status, jQxhr) {
          vm.dataLoadingNet = false;
          if (response.data.data != "") {
            vm.networkLists = response.data.data;
          }
        },
        error: function (jQxhr, status, error) {
          vm.networkLists = [];
        },
      });
    },
    editKeyword(dataList) {
      store.commit("setKeywordDetails", dataList);
      $("#modal-edit-keyword")
        .modal({
          centered: false,
        })
        .modal("show");
    },
    selectList(list) {
      console.log(JSON.stringify(list));
      if ($(window).width() < 640) {
        this.$router.push({ name: "ListDetails", params: { id: list.contact_groupId } });
      } else {
        this.$router.push({ name: "contacts", params: { id: list.contact_groupId } });
      }
    },
  },
};
</script>
