<template>
    <div class="l-app-main bg-primary-bg">
        <div class="l-app-main-inboxzero bg-primary-lighter" v-show="inboxzero">
            <h2>No Paybill/Till Number</h2>
            <p>Apply for Paybill Number<br />View transactions</p>
            <div class="padding-sm"></div>
            <button class="ui large bg-primary button" @click="showAggPaybillTill()">Aggregate Paybill or Till</button>
            <div class="padding-xl"></div>
        </div>
        <div class="l-app-dashboard bg-primary-lighter" v-show="!inboxzero">
            <div class="ui equal width relaxed grid padded">
                <div class="row">
                    <div class="column">
                        <div class="ui segment">
                            <div class="ui grid">
                                <div class="middle aligned row">
                                    <div class="six wide computer eight wide tablet sixteen wide mobile column">
                                        <h3>All Payments</h3>
                                        <!-- <h5 class="text-lightgrey">17 Mar - 24 Mar</h5> -->
                                    </div>
                                    <div class="ten wide computer only eight wide tablet only right aligned column">
                                        <div class="ui small input datepicker-trigger margin-left-xs">
                                                        <input type="text" id="datepicker-trigger" placeholder="Select date" :value="formatDates(dateOne, dateTwo)">
                                                        <AirbnbStyleDatepicker :trigger-element-id="'datepicker-trigger'" :mode="'range'" :fullscreen-mobile="true" 
                                                        :months-to-show="2" :date-one="dateOne" :date-two="dateTwo" :close-after-select="false" 
                                                        @date-one-selected="val => { dateOne = val }" @date-two-selected="val => { dateTwo = val }"
                                                        @apply="getGraphData(dateOne,dateTwo,selectPaybill)" />
                                                    </div>
                                        <div class="ui buttons margin-right-md">
                                            <div class="computer only tablet only right aligned column">
                                                
                                                <div class="ui tiny buttons"> 
                                                    
                                                    <select class="ui dropdown" v-model="selectPaybill" @change="getGraphData(dateOne,dateTwo,selectPaybill)">
                                                        <option value="">Select Paybill</option>
                                                        <option value="ALL">ALL</option>
                                                        <option v-for="paybill in paybills" v-bind:value="paybill.paybill" v-bind:key="paybill.paybill">
                                                            <span v-if="paybill.pStatus != -5">{{ paybill.paybill }}</span>
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="column">
                                        <canvas class="height-md" id="payments" height="300"></canvas>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="computer only column">
                        <button class="ui bg-primary button" @click="showAggPaybillTill()">Aggregate Paybill or Till</button>
                    </div>
                </div>
                <div class="row">
                    <div class="sixteen wide mobile only column">
                        <button class="ui fluid bg-primary button" @click="showAggPaybillTill()">Aggregate Paybill or Till</button>
                    </div>
                </div>
                <div class="row">
                    <div class="computer only column">
                        <h3 class="text-medium">Paybills & Tills</h3>
                        <table class="ui celled styled unstackable table">
                            <thead>
                                <tr>
                                    <th>Paybill / Till</th>
                                    <th class="center aligned">Status</th>
                                    <th>Transactions</th>
                                    <th>Contacts</th>
                                    <th class="right aligned">Balance</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
    
                                <tr v-for="paybill in paybills" :key="paybill.paybill">
    
                                    <td v-if="paybill.pStatus != -5">
                                        <h3>{{paybill.paybill}}</h3>
                                        <div>{{paybill.organisation_name}}</div>
                                        <small><span class="text-green" v-if="paybill.type_id == 1">Till</span><span class="text-red" v-if="paybill.type_id == 2">Paybill</span></small></td>
                                    <td class="center aligned" v-if="paybill.pStatus != -5">
                                        <span class="text-green" v-if="paybill.pStatus == 1 && paybill.aggregated_on != null">Active</span>
                                        <span class="text-gray" v-if="paybill.pStatus == 0 && paybill.aggregated_on != null">Inactive</span>
                                        <span class="text-red" v-if="paybill.aggregated_on == null && paybill.pStatus != 0">Awaiting Verification</span>
                                        <span class="text-gray" v-if="paybill.pStatus == 0 && paybill.aggregated_on == null">Inactive</span>
                                    </td>
                                    <td v-if="paybill.pStatus != -5">{{paybill.transaction_count }}</td>
                                    <td v-if="paybill.pStatus != -5">{{paybill.contact_count }}</td>
                                    <td class="right aligned" v-if="paybill.pStatus != -5">
                                        <h3 class="text-green text-medium" v-show="showBalance" >KES {{paybill.org_acc_balance}}</h3>
                                        <h3 class="text-red text-medium" v-show="!showBalance" >****</h3>
                                    </td>
                                    <td class="center aligned collapsing" v-if="paybill.pStatus != -5">
                                        <div class="ui icon floating dropdown button">
                                            <i class="icon dropdown"></i>
                                            <div class="menu">
                                                <a class="item" @click="updateStatusPaybill(paybill)">
                                                    <span class="text-red" v-if="paybill.pStatus == 1">Deactivate</span>
                                                    <span class="text-green" v-if="paybill.pStatus == 0">Activate</span>
                                                </a>
                                                <a class="item" @click="responseMessage(paybill)">Response Message</a>
                                                <a class="item" @click="updateStatusPaybillDelete(paybill)">Delete Paybill</a>
                                                <a class="item" @click="loadDetails(paybill.paybill)">View Transaction</a>
                                                <a class="item" @click="sdkPush(paybill)">STK Push</a>
                                                <a class="item" @click="reconMpesa(paybill)">Payment Recon</a>
                                            </div>
                                        </div>
                                    </td>
    
                                </tr>
    
    
    
                            </tbody>
                        </table>
    
                    </div>
                    <loader v-show="loading"></loader>
                    <div class="mobile only column bg-white">
                        <h3>Paybills & Tills</h3>
                        <table class="ui very basic unstackable table">
                            <tr v-for="paybill in paybills" :key="paybill.paybill">
                                <td>
                                    <h3 class="text-medium">{{paybill.paybill}}</h3>
                                    <h5 class="margin-bottom-sm" v-if="paybill.pStatus != -5">{{paybill.organisation_name}}</h5>
                                    <small :class="{green: paybill.pStatus == 1 && paybill.aggregated_on != null}" class="margin-right-md" v-if="paybill.pStatus != -5">
                                        <span v-if="paybill.pStatus == 1 && paybill.aggregated_on != null"><i class="green circle icon"></i> Active</span>
                                        <span v-if="paybill.pStatus == 0 && paybill.aggregated_on != null">Inactive</span>  
                                        <span v-if="paybill.aggregated_on == null && paybill.pStatus != 0"><i class="red circle icon"></i> Awaiting Verification</span>
                                        <span v-if="paybill.pStatus == 0 && paybill.aggregated_on == null">Inactive</span> 
                                        <span v-if="paybill.type_id == 1"> Till </span> 
                                        <span v-if="paybill.type_id == 2"> Paybill </span> 
                                    </small>
                                </td>
                                <td class="right aligned top aligned">
                                    <h4>KES {{paybill.org_acc_balance}}</h4>
                                </td>
                                <td class="collapsing top aligned">
                                    <div class="ui icon floating dropdown tiny button">
                                        <i class="icon dropdown"></i>
                                        <div class="menu">
                                            <a class="item" @click="updateStatusPaybill(paybill)">
                                                <span class="text-red" v-if="paybill.pStatus == 1">Deactivate</span>
                                                <span class="text-green" v-if="paybill.pStatus == 0">Activate</span>
                                            </a>
                                            <a class="item" @click="responseMessage(paybill)">Response Message</a>
                                            <a class="item" @click="updateStatusPaybillDelete(paybill)">Delete Paybill</a>
                                            <a class="item" @click="loadDetails(paybill)">View</a>
                                            <a class="item" @click="sdkPush(paybill)">STK Push</a>
                                            <a class="item" @click="reconMpesa(paybill)">Payment Recon</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="padding-bottom-xl"></div>
            </div>
            <AggPaybillTill />
            <PayEditResponse />
            <PayStatus />
            <PayStatusDelete />
            <sdkPUSH />
            <paymentRecon />
        </div>
    </div>
</template>

<script>
    import PayEditResponse from "@/modals/pay_edit_response";
    import PayStatus from "@/modals/pay_updateStatus";
    import AggPaybillTill from "@/modals/aggregate-paybill-till";
    import PayStatusDelete from "@/modals/pay_deleteStatus";
    import paymentRecon from "@/modals/paymentRecon";
    import sdkPUSH from "@/modals/sdkPush";
    import Chart from 'chart.js';
    import numeral from "numeral";
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import moment from 'moment';
    import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
    import loader from "@/components/loader";
    import format from 'date-fns/format';
    export default {
        components: {
            AggPaybillTill,
            PayEditResponse,
            loader,
            PayStatus,
            PayStatusDelete,
            sdkPUSH,
            paymentRecon
            
        },
    
        data() {
            return {
                // credits: store.state.credits,
                paybills: [],
                loading: true,
                dateFormat: "D MMM",
                dateFormatTwo: "YYYY-MM-DD",
                dateOne: "",
                dateTwo: "",
                days: 7,
                graphData: [],
                permission_acl:"",
                permission:"",
                inboxzero: false,
                showBalance:false,
                selectPaybill:""
    
            }
        },
        mounted() {
            const vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            const datepickerOptions = {};
    
            var d = new Date();
            var to = format(d.setDate(d.getDate()), this.dateFormatTwo);
            var from = format(d.setDate(d.getDate() - 7), this.dateFormatTwo);
            vm.getGraphData(from, to);
            vm.getPaybills();
            vm.viewProfile();
    
        },
    
        methods: {
            getPaybills() {
                var vm = this;
                if (!vm.token) {
                    vm.token = vm.$cookies.get("key");
                }
                vm.loading = true;
                $.post({
                    url: store.state.rootUrl + 'payment/paybill/list',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key")
    
                    }),
                    success: function(response, status, jQxhr) {
                        vm.loading = false;
                        if (response.data.code == 404) {
                            vm.inboxzero = true;
                        } else {
                            vm.inboxzero = false;
                            vm.paybills = response.data.data;
                        }
                        setTimeout(function() {
                            $(".ui.dropdown").dropdown();
                        }, 100)
                    },
                    error: function(jQxhr, status, error) {
                        vm.loading = false;
    
                    }
                });
    
            },
            responseMessage(paybill) {
                store.commit("setpayBill", paybill);
    
                $('.ui.modal#modal-edit-response-paybill').modal({
                    centered: false,
                    closable: true
                }).modal("show");
    
            },
            sdkPush(paybill){
                store.commit("setpayBill", paybill);
    
                $('.ui.modal#modal-sdk-push').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            reconMpesa(paybill){
                store.commit("setpayBill", paybill);
    
                $('.ui.modal#modal-payment-recon').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            updateStatusPaybill(paybill) {
                store.commit("setpayBill", paybill);
    
                $('.ui.modal#modal-Paybill_status').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            updateStatusPaybillDelete(paybill) {
                store.commit("setpayBill", paybill);
    
                $('.ui.modal#modal-Paybill_status_delete').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            showAggPaybillTill() {
                $('.ui.modal#modal-agg-paybill-till').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            loadDetails(path) {
                this.$router.push({
                    name: 'payments-transactions-details',
                    params: {
                        id: path
                    }
                });
            },
            getGraphData(from, to, paybill= null) {
    
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/payment/mpesa/payments/graph",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        start: from,
                        end: to,
                        paybill: paybill
                    }),
                    success: function(response, status, jQxhr) {
    
                        vm.graphData = response.data.data;
    
                        let yData = [];
                        let xDate = [];
                        let result = response.data.data;
    
                        result.forEach(function(data) {
                            yData.push(parseInt(data["amount_collected"]));
                            xDate.push(data["summary_date"]);
                        });
    
                        let ctx = $("#payments");
                        let myChart = new Chart(ctx, {
                            type: "line",
                            data: {
                                labels: xDate,
                                datasets: [{
                                    data: yData,
                                    backgroundColor: ["#F7FAFA"],
                                    borderColor: ["#26A842"],
                                    borderWidth: 1
                                }]
                            },
                            options: {
                                maintainAspectRatio: false,
                                responsive: true,
                                layout: {
                                    padding: {
                                        right: 0
                                    }
                                },
                                legend: {
                                    display: false
                                },
                                scales: {
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                                },
                                elements: {
                                    line: {
                                        tension: 0 // disables bezier curves
                                    }
                                }
                            }
                        });
                    },
                    error: function(jQxhr, status, error) {
                        vm.loading = false;
                    }
                });
    
            },
            formatDates(dateOne, dateTwo) {
                let formattedDates = "";
                if (dateOne) {
                    formattedDates = format(dateOne, this.dateFormat);
                }
                if (dateTwo) {
                    formattedDates += " - " + format(dateTwo, this.dateFormat);
                }
                return formattedDates;
            },
            viewProfile() {
            let vm = this;
            if(vm.$cookies.get("key") != null){
                $.post({
                    url: store.state.rootURLBulkAPI + 'v3/account/view',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                    }),
                    success: function(response, status, jQxhr) {
                            vm.permission_acl = response.data.data.permission_acl
                            vm.permission = vm.permission_acl.split(":")
                            if(vm.permission.includes("58")){
                                vm.showBalance = true;
                            }
                    },
                    error: function(jQxhr, status, error) {
                        //alertify.set('notifier', 'position', 'top-right');
                        //alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });

            }
            
        },
        },
        filters: {
            formatNumber(value) {
                return numeral(value).format("0,0.00");
            },
            formatInt(value) {
                return numeral(value).format("0,0");
            }
        }
    }
</script>
