<template>
  <div class="ui tiny modal" id="modal-edit-threshold">
    <i class="close icon"></i>
    <div class="scrolling content">
      <div class="text-center margin-bottom-lg">
        <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
        <h2 class="padding-bottom-sm">Edit Threshold</h2>
        <h4>You can edit your account alert threshold.</h4>
        <h4>Current threshold: {{ threshold }}</h4>
      </div>
      <div class="ui container grid">
        <div class="column">
          <div class="ui relaxed padded grid">
            <div class="column">
              <div class="ui form">
                <div class="field">
                  <h4 class="padding-bottom-sm"><strong>Threshold Amount</strong></h4>
                  <div class="ui large input">
                    <input type="number" placeholder="1000" v-model="amount" />
                  </div>
                </div>
                <div class="padding-md"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <button class="ui button" @click="hideModal()">Cancel</button>
      <button class="ui button bg-primary" id="edit-client" @click="updateThreshold">
        Save Changes
      </button>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import bulkstore from "@/bulk/bulksms-state";
import alertify from "alertifyjs";
export default {
  data() {
    return {
      amount: null,
    };
  },
  computed: {
    threshold() {
      var vm = this;
      console.log(
        "am here test alert_threshold " + JSON.stringify(store.state.selectResellerClient)
      );
      return store.state.accountDetails.alert_threshold;
    },
  },

  methods: {
    updateThreshold() {
      let vm = this;
      $.post({
        url: store.state.rootURLBulkAPI + "/v3/account/set/alert",
        type: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key"),
          threshold: vm.amount,
        }),
        success: function (response, status, jQxhr) {
          if (response.data.code != 200) {
            alertify.set("notifier", "position", "top-right");
            alertify.error(response.data.message);
          } else {
            vm.hideModal();
            alertify.set("notifier", "position", "top-right");
            alertify.success(response.data.message);
            vm.$router.go();
          }
        },
        error: function (jQxhr, status, error) {
          alertify.set("notifier", "position", "top-right");
          alertify.error(jQxhr.responseJSON.statusDescription);
          //console.log("failed to update threshold");
        },
      });

      vm.hideModal();
    },
    hideModal() {
      $(".ui.modal#modal-edit-threshold").modal("hide");
    },
  },
};
</script>
