<template>
  <div class="l-app-main bg-white">
    <div class="l-app-main-content">
      <div class="ui equal width grid">
        <div class="row">
          <div class="column">
            <h3 class="text-medium">API Token</h3>
            <p>
              You can use the API to send bulk messages, manage and update your contacts,
              get callback messages, and more. Check the
              <a @click="getAPIToken()" class="text-green text-underline">API docs</a>
              below on how to integrate with your system
            </p>
          </div>
          <div class="right aligned column">
            <button class="ui orange button" @click="getAPIToken()">Get API Token</button>
          </div>
        </div>
        <div class="row" v-show="showToken">
          <div class="column">
            <div class="ui secondary segment">
              <small
                >You will see your API token once you reveal it by clicking the button
                above</small
              >
              <div class="ui middle aligned grid" v-show="showToken">
                <div class="twelve wide column">
                  <h3 id="tokeID">{{ token }}</h3>
                </div>
                <div class="four wide right aligned column">
                  <button class="ui small button" @click="copyTestingCode()">Copy</button>
                </div>
              </div>
            </div>

            <!--<small v-show="showToken">Last generated: 23 March 2019</small> -->
          </div>
        </div>
        <div class="row" v-show="showToken">
          <div class="column">
            <div class="ui secondary segment">
              <div class="column">
                <h3><strong> API Doc </strong></h3>
              </div>
              <p class="row">
                $curl = curl_init();<br />

                curl_setopt_array($curl, array(<br />
                CURLOPT_URL => "https://api.vaspro.co.ke/v3/BulkSMS/api/create",<br />
                CURLOPT_RETURNTRANSFER => true,<br />
                CURLOPT_ENCODING => "",<br />
                CURLOPT_MAXREDIRS => 10,<br />
                CURLOPT_TIMEOUT => 30,<br />
                CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,<br />
                CURLOPT_CUSTOMREQUEST => "POST",<br />
                CURLOPT_POSTFIELDS => "{<br />
                "apiKey": "{{ token }}", <br />
                "shortCode": "VasPro",<br />
                "message": " Message Here",<br />
                "recipient": "254704050143 ",<br />
                "callbackURL": " ", <br />
                "enqueue":0 <br />
                }",<br />
                CURLOPT_HTTPHEADER => array(<br />
                "content-type: application/json",<br />
                ), <br />
                )); <br />
                $response = curl_exec($curl);<br />
                $err = curl_error($curl); <br />
                curl_close($curl);<br />
                if ($err) { <br />
                echo "cURL Error #:" . $err; <br />
                } else { <br />
                echo $response; <br />
                } <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <ApiAccountVerification />
    </div>
  </div>
</template>

<script>
import ApiAccountVerification from "@/modals/api-account-verification";
import store from "@/store";
import copy from "copy-to-clipboard";
import alertify from "alertifyjs";
export default {
  data() {
    return {
      showToken: false,
      token: null,
    };
  },
  components: {
    ApiAccountVerification,
  },

  methods: {
    getAPIToken() {
      $(".ui.modal#modal-verify-account")
        .modal({
          closable: false,
        })
        .modal("show");
    },
    showAPIToken() {
      let vm = this;
      vm.showToken = true;
    },
    copyTestingCode() {
      if (copy(this.token)) {
        alertify.success("Token Copied to Clipboard");
      }
    },
  },
};
</script>
