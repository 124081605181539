<template>
    <div class="ui tiny modal" id="modal-create-contact-list">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md">
                <h2 class="padding-bottom-sm">Create Contact List</h2>
                <h4>You can use the contact list to send bulk messages</h4>
                <div class="ui negative message" v-show="createListStatus">
                            {{createListDesc}} 
                </div>
            </div>
            <div class="ui centered container grid">
                <div class="column">
                    <div class="ui form">
                         <div class="required field" id="input-list-name">
                            <div class="field">
                                <label>List name</label>
                                <div class="ui large input">
                                    <input type="text" placeholder="e.g. customers list" required v-model="listName">
                                </div>
                            </div>
                         </div>
                         <div class="required field" id="input-description-name">
                           <!-- <div class="field">
                                <label>List Description</label>
                                <div class="ui large input">
                                    <input type="text" placeholder="e.g. all our customers" required v-model="descDetails">
                                </div>
                            </div> -->
                         </div>
                        <div class="padding-sm"></div>
                        <div class="field">
                            <label>Add Custom Fields <strong> NB Name Already Exist </strong></label>
                        </div>
                        <div class="ui negative message" v-show="customStatus">
                            {{StatusMessage}} 
                        </div>
                        <div class="field">
                            <div class="ui input">
                                <input placeholder="custom field 1" v-model="custom_field">
                            </div>
                        </div>
                        <button class="ui button" @click="populateCustomFields()">Add custom field.</button>
                        <div class="padding-sm"></div>
                        <div class="custom-fields" v-show="custom_field2 || custom_field3 || custom_field4 || custom_field5">
                            
                            <span class="ui blue label" v-show="custom_field2" @click="removeCustomField(2)">
                            {{custom_field2}}
                            <i class="fas fa-times"></i>
                            </span>
                                        <span class="ui purple label" v-show="custom_field3" @click="removeCustomField(3)">
                                {{custom_field3}}
                                <i class="fas fa-times"></i>
                            </span>
                                        <span class="ui orange label" v-show="custom_field4" @click="removeCustomField(4)">
                                {{custom_field4}}
                                <i class="fas fa-times"></i>
                            </span>
                                        <span class="ui teal label" v-show="custom_field5" @click="removeCustomField(5)">
                                {{custom_field5}}
                                <i class="fas fa-times"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary button" @click="createList()">Create Contact List</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import alertify from "alertifyjs";
    
    export default {
        data() {
            return {
                inboxzero: false,
                listName: "",
                descDetails: "",
                custom_field2: "",
                custom_field3: "",
                custom_field4: "",
                custom_field5: "",
                custom_field: "",
                StatusMessage: "",
                customStatus: false,
                createListStatus:false,
                createListDesc:""
            };
        },
        methods: {
            hideModal() {
                $(".ui.modal#modal-create-contact-list").modal("hide");
            },
            removeCustomField(key) {
                if (key == 2) {
                    this.custom_field2 = "";
    
                } else if (key == 3) {
                    this.custom_field3 = "";
    
                } else if (key == 4) {
                    this.custom_field4 = "";
    
                } else if (key == 5) {
                    this.custom_field5 = "";
                }
    
            },
            populateCustomFields() {
                if (!this.custom_field2) {
                    this.custom_field2 = this.custom_field;
                    this.custom_field = "";
                } else if (!this.custom_field3) {
                    this.custom_field3 = this.custom_field;
                    this.custom_field = "";
                } else if (!this.custom_field4) {
                    this.custom_field4 = this.custom_field;
                    this.custom_field = "";
                } else if (!this.custom_field5) {
                    this.custom_field5 = this.custom_field;
                    this.custom_field = "";
                } else {
                    this.customStatus = true;
                    this.StatusMessage = "Maximum fields reached";
                    console.log("Maximum fields reached");
                }
    
            },
            createList() {
                let vm = this;
                if(vm.listName ==""){
                   $("#input-list-name").addClass("error");
                      //console.log("phone number is required");
                    //return;
                }
                $.post({
                    url: store.state.rootUrl + "contact/create",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        list_name: this.listName,
                        list_description: "Group Contact",
                        custom_field2: vm.custom_field2,
                        custom_field3: vm.custom_field3,
                        custom_field4: vm.custom_field4,
                        custom_field5: vm.custom_field5,
                    }),
                    success: function(response, status, jQxhr) {
                        alertify.success("Contact List has been created");
                        $(".ui.modal#modal-create-contact-list").modal("hide");
                        vm.$parent.getContactList();
                        vm.$root.$emit('refreshList');
                    },
                    error: function(jQxhr, status, error) {
                        vm.createListStatus = true;
                        vm.createListDesc = jQxhr.responseJSON.statusDescription;
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            }
        }
    };
</script>
