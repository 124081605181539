<template>
    <div class="ui tiny modal" id="modal-share-credits">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">Share Credits</h2>
                <h4>Share your credits with<br />[{{originalName}}]</h4>
            </div>
            <div class="ui equal width centered container grid">
                <div class="column">
                    <div class="ui form">
                        <div class="field">
                            <label>Amount</label>
                            <div class="ui input">
                                <input  v-model="amount" placeholder="Enter amount to share" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui cancel button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary button" @click="updateWallet()">Share</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    export default {
         data() {
            return {
                msisdn: null,
                amount: 1000,
                user_mapId:"",
                username:"",
                walletStatus:false,
                walletStatusDesc:""
            }
        },
        computed:{
             originalName() {
                var vm = this;
                vm.user_mapId = store.state.user_mapId;
                vm.username = store.state.clientUsername;
                
                return vm.username;
    
            }
       },
        methods: {
            hideModal() {
                $('.ui.modal#modal-share-credits').modal('hide');
            },
            updateWallet(){
                let vm = this;
                 $.post({
                    url: store.state.rootURLBulkAPI + "v3/account/transfer",
                    type: "POST",
                    data: JSON.stringify({
                    apiKey: vm.$cookies.get("key"),
                    amount: vm.amount,
                    user_mapId: vm.user_mapId,
                    }),
                    success: function(response, status, jQxhr) {
                    if (response.data.code != 200) {
                        alertify.set("notifier", "position", "top-right");
                        alertify.error(response.data.message);
                        vm.walletStatus = true;
                        vm.walletStatusDesc = response.data.message;
                    } else {
                        vm.hideModal();
                        alertify.set("notifier", "position", "top-right");
                        alertify.success(response.data.message);
                    }
                    },
                    error: function(jQxhr, status, error) {
                    alertify.set("notifier", "position", "top-right");
                    var responseMessage = jQxhr.responseJSON.data.message;
                    if(responseMessage != ""){
                        alertify.error(jQxhr.responseJSON.data.message); 
                    
                    }
                    else{
                       alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                    
                    vm.walletStatus = true;
                    vm.walletStatusDesc = jQxhr.responseJSON.data.message;
                    }
                });
            }
        }
    }
</script>
