<template>
    <div class="l-app-main">
        <div class="l-app-main-inboxzero bg-primary-lighter" v-show="inboxzero">
            <h2>No Keywords</h2>
            <p>Create a keyword to start using<br />MT Services</p>
            <div class="padding-sm"></div>
            <button class="ui large bg-primary button" @click="applyKeyword()">Create Keyword</button>
            <div class="padding-xl"></div>
        </div>
        
        <div class="l-app-main-section" v-show="!inboxzero">
            <div class="l-app-main-section-container one">
                
                <div class="l-app-list-title">
                    <div class="ui middle aligned grid">
                        <div class="column">
                            <button class="ui bg-primary small fluid button" @click="applyKeyword()"> Add Keyword</button>
                        </div>
                    </div>
                </div>
                
                <ul class="l-app-list">
                    <li v-for="dataList in dataLists" v-bind:key="dataList.product_id + 1" @click="selectList(dataList)" 
                    v-bind:class="{selected: selectedKey == dataList.product_id}">
                        <div class="status hidden"><i class="circle green icon"></i></div>
                        <div class="info">
                            <span class="info-title">{{dataList.product_name}}</span>
                            <span class="info-meta">
                                <span v-show="dataList.status == 1"> <i class="circle green icon"></i>Active | </span>
                                <span v-show="dataList.status == 2"><i class="circle yellow icon"></i>Progress | </span>
                                {{dataList.short_code}}  >>>  {{dataList.product_name}}
                            </span>
                        </div>
                     
                    </li>
                </ul>
    
                <div class="padding-md">
                    <div v-show="!loading" @click="getContactList()" class="ui fluid button bottom aligned button">Load More</div>
                </div>
                <loader v-show="loading"></loader>
            </div>
            
        <ListDetails v-show="selectedList" ref="details" />
        <ApplyKeywordSubscription />
        <EditKeywordSub />
        </div>
    </div>
</template>
<script>
    import EditKeywordSub from "@/modals/subscription-edit-keyword";
    import loader from "@/components/loader";
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import Dropzone from "dropzone";
    import ListDetails from "./keywords-details";
    import ApplyKeywordSubscription from "@/modals/subscription-add-keyword";
    export default {
        data() {
            return {
                inboxzero: false,
                dataLists: [],
                dataListCount: 0,
                countN: 1,
                list_id: "",
                list_name: "",
                status: "",
                limit: 50,
                selectedKey: null,
                offset: 0,
                selectedList: '',
                order: "",
                showUploadSection: false,
                uploadFiles: null,
                processingUploadFile: false,
                //infinite scroll
                busy: false,
                totalContactLists: 0,
                loading: true,
                allContacts:[],
                totalAllContacts:0,
                product_id:null,
                short_code:null,
                start:null,
                end:null
            }
        },
        components: {
            ListDetails,
            loader,
            ApplyKeywordSubscription,
            EditKeywordSub
        },
        mounted() {
            let vm = this;
          if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            $('.ui.dropdown').dropdown();
            $('.ui.progress').progress();
            vm.getContactList();
            this.$root.$on('refreshList',(data) => {
                vm.dataLists= [];
                vm.dataListCount= 0;
                vm.countN= 1;
                vm.list_id= "";
                vm.list_name= "";
                vm.status= "";
                vm.limit= 10;
                vm.offset= 0;
                vm.totalContactLists = 0;
                vm.selectedList='';
                vm.getContactList();

            });
            this.$root.$on('refreshSelected',(data) => {
               
               vm.dataLists= [];
                vm.dataListCount= 0;
                vm.countN= 1;
                vm.list_id= "";
                vm.list_name= "";
                vm.status= "";
                vm.limit= 10;
                vm.offset= 0;
                vm.totalContactLists = 0;
                vm.getContactList();
            });
            this.totalContactLists = 0;
            this.dataLists = [];
            this.selectedList = '';
            bulkstore.commit("setSelectedList", '');
            
        },
        methods: {
            applyKeyword() {
                console.log("we have clicked");
                $(".ui.modal#modal-apply-keyword-subscription").modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            editKeywords(list){
                let vm = this;
                store.commit("setKeywordList", list);
                $('.ui.modal#modal-edit-keyword-subscription').modal({
                    closable: true
                }).modal('show');

            },
            getContactList: function() {
                var vm = this;
                if (vm.totalContactLists === 0 || vm.totalContactLists >= ((vm.offset - 1) * vm.limit)) {
                    vm.loading = true;
                    vm.offset++;
                    $.post({
                        url: store.state.rootURLBulkAPI + 'subscription/v3/fetch/products',
                        type: "POST",
                        data: JSON.stringify({
                            token: this.$cookies.get("key"),
                            product_id: vm.product_id,
                            short_code: vm.short_code,
                            start: vm.start,
                            end:vm.end,
                            limit: vm.limit,
                            offset: vm.offset
                        }),
                        success: function(response, status, jQxhr) {
                            vm.loading = false;
                            if (response.data.data != "") {
                                vm.inboxzero = false;
                                //vm.dataLists = response.data.data;
                                vm.dataListCount = response.data.count;
                                vm.totalContactLists = response.data.data[0].total;
                                var totalContacts = 0;
                                var list_c = response.data.data;
    
                                for (var i = 0; i < list_c.length; i++) 
                                {
                                    totalContacts = totalContacts + parseInt(list_c[i].total, 10);
                                    vm.dataLists.push(list_c[i]);
                                    if(i==0){
                                       // vm.selectList(list_c[i]);
                                    }
                                    
                                }
                                bulkstore.commit("setContactList", vm.dataLists);
                            }
                            else if(vm.offset > 1){
                                vm.inboxzero = false;
                            } else {
                                vm.inboxzero = true;
                            }
                        },
                        error: function(jQxhr, status, error) {
                            vm.loading = false;
                            vm.dataLists = [];
                            bulkstore.commit("setContactList", []);
                        }
                    });
                }
            },

            selectList(list) {
                
                this.selectedKey="";
                this.selectedList=[];
                this.$route.params.id ="";
                
                if(list != null){
                    this.selectedKey = list.product_id;
                    this.selectedList = list;
                    store.commit("setKeywordList", list);
                    this.$refs.details.setListValue(list);
                   
                    if ($(window).width() < 640){
                         console.log("am here selected list kevin");
                        this.$router.push({name: 'subscription-keywords-details', params: { id: list.product_id}});
                    }
                }
                else{
                    this.selectedKey = "";
                    this.selectedList = "";
                }
                
                
            }
        }
    };
</script>