<template>
    <!-- Summary tab-->
    <div class="l-app-main-segment-container bg-white">
        <div class="ui relaxed padded centered grid">
            <div class="row">
                <div class="ten wide computer ten wide tablet sixteen wide mobile column">
                    <div class="padding-sm"></div>
                    <h4 class="text-medium padding-bottom-md">Questionnaire</h4>
                    <div class="l-app-question">
                        <div class="q" v-for="(question, index) in questionList" :key="question.id">
                            <div class="q-no">
                                <span class="ui orange label" v-if="question.question_typeID == 1">{{index+1}}</span>
                                <span class="ui blue label" v-if="question.question_typeID == 2">{{index+1}}</span>
                                <span class="ui teal label" v-if="question.question_typeID == 3">{{index+1}}</span>
                                <span class="ui black label" v-if="question.question_typeID == 4">{{index+1}}</span>
                            </div>
                            <div class="q-info">
                                <div class="q-title">{{question.question}}</div>
                                <div class="q-option text" v-if="question.question_typeID == 1">
                                    <div class="option">
                                        <span class="t">T</span>
                                        <span class="w">Open ended input</span>
                                    </div>
                                </div>
                                <div class="q-option options" v-if="question.question_typeID == 2">
                                    <div class="option" v-for="(option, index) in question.options"  :key="option.id+index">
                                        <span class="t">{{index+1}}</span>
                                        <span class="w">{{option.value}}</span>
                                    </div>
                                </div>
                                <div class="q-option bool" v-if="question.question_typeID == 3">
                                    <div class="option">
                                        <span class="t">Y</span>
                                        <span class="w">Yes</span>
                                    </div>
                                    <div class="option">
                                        <span class="t">N</span>
                                        <span class="w">No</span>
                                    </div>
                                </div>
                                <div class="q-option rate" v-if="question.question_typeID == 4">
                                    <div class="option">
                                        <span class="t">Min rating</span>
                                        <span class="w">1</span>
                                    </div>
                                    <div class="option">
                                        <span class="t">Max rating</span>
                                        <span class="w">10</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <loader v-show="loading"></loader>
                    </div>
                    <div class="ui divider"></div>
                </div>
                <div class="six wide computer six wide tablet sixteen wide mobile column">
                    <div class="padding-sm"></div>
                    <h4 class="text-medium">Settings</h4>
                    <div class="ui segment">
                        <h4 class="text-medium">Channels </h4><br/>
                        <div class="column">
                            <div class="ui checkbox">
                                <input type="checkbox" name="example" checked v-model="smsState">
                                <label>SMS</label>
                            </div>
                        </div>
                        <p class="text-grey-light" v-show="!smsState">No channels have been selected</p>
                    </div>

                    <div class="ui segment">
                        <h4 class="text-medium">Integrations</h4><br />
                        <div class="row">
                            <div class="column" v-show="mpesaState">
                                <select class="ui fluid dropdown" v-model="selectPaybill">
                                    <option value="">Select Paybill/Till</option>
                                    <option v-for="paybill in paybills" :key="paybill.paybill">
                                        <span class="description">{{paybill.type}} | </span>
                                        <span class="text">{{paybill.paybill}}</span> 
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column" v-show="keywordState" >
                                <p>ShortCode: {{selectShortcode}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column" v-show="keywordState">
                                <p>Keyword: {{keywords}}</p>
                            </div>
                        </div>
                        <p class="text-grey-light" v-show="!keywordState">No integrations have been selected</p>
                    </div>

                    <div class="ui segment hidden">
                        <h4 class="text-medium">Rewards</h4>
                        <p class="text-grey-light">No rewards will be sent to customers</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="right aligned column">
                    <button class="ui button">Save</button>
                    <button class="ui bg-primary button" @click="updateSurveyStatus()">Save & Start Survey</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import store from "@/store";
import alertify from 'alertifyjs';
import loader from "@/components/loader";
import format from 'date-fns/format';
import moment from "moment";
export default {
     data() {
        return {
            questionnaireID: "",
            totalQuestion: 0,
            offset: 0,
            limit: 10,
            loading: false,
            questionList: [],
            questions:[],
            choiceOption:"",
            questionTypes:"",
            currentInputIndex:0,
            survey:[],
            smsState: false,
            mpesaState:false,
            keywordState:true,
            selectPaybill:"",
            selectShortcode:"",
            selectKeyword:"",
            paybills:[],
            keywords:[],
            shortcode:[],
            app_id:"",
            questionnnaireType:""

        }
    },
    mounted() {
        let vm = this;
        
        
        vm.survey= store.state.selectSurvey
        vm.questionnaireID = vm.survey.questionnare_id;
        vm.questionnnaireType = vm.survey.questionnnaireType

        var channel = vm.survey.channel;
        if(channel == 'SMS'){
            vm.smsState = true;
        }
        vm.selectKeyword =  vm.survey.keyword_id;
        vm.selectPaybill = vm.survey.paybill_id;
        vm.selectShortcode =  vm.survey.short_code;
        vm.app_id = vm.survey.app_id;
        //console.log(JSON.stringify(vm.selectKeyword ))
        vm.getQuestion();
        vm.getPaybills();
        vm.getAllkeyword();
        vm.getAllShortcode();
        if(vm.survey ==null || vm.survey==""){
            vm.getCampaignRecords(vm.$route.params.id);
        }
    },
    components: {
        loader,
    },
    methods: {
        
        getQuestion() {
            let vm = this;
            if (vm.totalQuestion == 0 || vm.totalQuestion >= ((vm.offset - 1) * vm.limit)) {
                vm.offset++;
                vm.loading = true;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/view/question",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        questionnare_id: vm.questionnaireID,
                        offset: vm.offset,
                        limit: vm.limit,
                        offset: vm.offset,
                        appID: vm.app_id,
                        questionnnaireType: vm.questionnnaireType

                    }),
                    success: function(response, status, jQxhr) {
                        vm.loading = false;
                        var resp = response.data.data;
                        if (response.data.code != 200) {
                            vm.offset = 0;
                            alertify.set('notifier', 'position', 'top-right');
                            alertify.error(response.data.message);
                            //vm.totalMessage = 0;

                        } else {
                            vm.totalQuestion = response.data.total;
                            var quest = [];
                            for (var i = 0; i < resp.length; i++) {
                                // console.log(" loop " + i + " " + JSON.stringify(resp[i]));
                                resp[i].created_at = format(resp[i].created_at, "DD MMM YYYY");
                                var options = resp[i].options;
                                if(options != null){
                                    var optionArry = options.split('||');
                                    var n = 0;
                                    var optionList = [];
                                    optionArry.forEach(element => {
                                        element = element.split('|');
                                        var list = {
                                            "id": element[1],
                                            "value": element[0]
                                        }
                                        
                                        optionList.push(list);
                                    });
                                    resp[i].options = optionList;
                                }
                                
                                vm.questionList.push(resp[i]);
                            }
                            store.commit("setSelectQuestion", vm.questionList);
                            
                            setTimeout(function() {
                                $(".ui.small.dropdown").dropdown();
                                $(".question-box").on('input', function() {
                                    var scroll_height = $(".question-box").get(0).scrollHeight;
                                    $(".question-box").css('height', scroll_height + 'px');
                                });
                            }, 100)
                        }
                    },
                    error: function(jQxhr, status, error) {
                        vm.loading = false;
                        vm.offset = 0;
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            }
        },
        getPaybills() {
            let vm = this; 
            vm.loading = true;
            $.post({
                url: store.state.rootUrl + 'payment/paybill/list',
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key")
                }),
                success: function(response, status, jQxhr) {
                    vm.loading = false;
                    if (response.data.code == 404) {
                        vm.paybills=[]
                        vm.displayMpesaOption = false;
                    } else {
                        vm.displayMpesaOption = true;
                        vm.paybills = response.data.data;
                    }
                    setTimeout(function() {
                        $(".ui.dropdown").dropdown();
                    }, 100)
                },
                error: function(jQxhr, status, error) {
                    vm.loading = false;

                }
            });

        },
        getAllkeyword() {
            var vm = this;
            $.post({
                url: store.state.rootUrl + 'contact/keyword/view',
                type: "POST",
                data: JSON.stringify({
                    apiKey: this.$cookies.get("key"),
                    keywordID: vm.selectKeyword 

                }),
                success: function(response, status, jQxhr) {
                    if (response.data.data != "") {
                        vm.keywords = response.data.data[0].keyword;
                    }
                    setTimeout(function() {
                        $(".ui.dropdown").dropdown();
                    }, 100)
                },
                error: function(jQxhr, status, error) {
                    
                }
            });

        },
        getAllShortcode(){
            let vm  = this;
            $.post({
                url: store.state.rootURLBulkAPI + 'v3/inboxmain/view/shortcode',
                type: "POST",
                data: JSON.stringify({
                    apiKey: this.$cookies.get("key"),
                    category:1
                }),
                success: function(response, status, jQxhr) {
                    vm.shortcode = response.data.data;
                    setTimeout(function() {
                        $(".ui.dropdown").dropdown();
                    }, 100)

                },
                error: function(jQxhr, status, error) {

                    //alertify.error(jQxhr.responseJSON.statusDescription);
                }
            });
        },
        updateSurveyStatus(){
            let vm = this;
            $.post({
                url: store.state.rootUrl + 'survey/update',
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key"),
                    status: 1,
                    app_id: vm.app_id
                }),
                success: function(response, status, jQxhr) {
                    if(response.data.code != 200){
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(response.data.message);
                    }
                    else{
                        alertify.set("notifier", "position", "top-right");
                        alertify.success(response.data.message);
                        vm.$router.push({name: 'survey-view', params: {id: vm.app_id}});
                        
                    }
                },
                error: function(jQxhr, status, error) {
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.error(jQxhr.responseJSON.statusDescription);
                }
            });

            
        },
        getCampaignRecords(appID){
            let vm = this
            $.post({
                url: store.state.rootURLBulkAPI + "v3/survey/query",
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key"),
                    app_id:appID
                }),
                success: function(response, status, jQxhr) {
                    
                    var survey = response.data.data;
                    vm.surveyName = survey[0].campaign_name;
                    vm.app_id = appID;
                    vm.questionnnaireType = survey[0].questionnnaireType;
                    store.commit("setSurveySelect", survey[0]);
                    vm.survey= store.state.selectSurvey
                    vm.questionnaireID = vm.survey.questionnare_id;
                    vm.getQuestion();
                    setTimeout(function(){
                        $(".l-app-main-segment").css({
                            top: $(".l-app-main-top").height()
                        });
                    }, 1000);

                },
                error: function(jQxhr, status, error) {
                    alertify.set('notifier', 'position', 'top-right');
                    // alertify.error(jQxhr.responseJSON.statusDescription);
                }
            });

        },

    }
    
}
</script>
