<template>
    <div class="">
        <div class="l-app-inbox-header ui relaxed padded grid">
            <div class="computer only tablet only equal width middle aligned row">
                <div class="column">
                    <span class="hidden">Filter by &nbsp;</span>
                    <select class="ui dropdown margin-right-sm hidden">
                                    <option value="" selected>Short Code</option>
                                </select>
                    <form class="ui search margin-right-sm">
                        <div class="ui left icon input">
                            <i class="search icon"></i>
                            <input type="text" placeholder="Search contact" v-model="msisdn" v-on:keyup="searchContact()">
                        </div>
                    </form>
                    <div class="ui tiny left icon input datepicker-trigger margin-right-sm">
                        <i class="calendar icon"></i>
                        <input type="text" id="datepicker-trigger" placeholder="Select date range" :value="formatDates(dateOne, dateTwo)" />
                        <AirbnbStyleDatepicker :trigger-element-id="'datepicker-trigger'" :mode="'range'" :fullscreen-mobile="true" :months-to-show="2" :date-one="dateOne" :date-two="dateTwo" :close-after-select="false" @date-one-selected="val => { dateOne = val }" @date-two-selected="val => { dateTwo = val }"
                            @apply="searchContact()" />
                    </div>
                    <button class="ui right floated button margin-left-md" @click="exportContact()">Export</button>
                    <div class="ui basic right floated buttons">
                        <button class="ui icon button" @click="inboxViewTog('columns')" :class="{active: inboxView === 'columns'}"><i class="columns icon"></i></button>
                        <button class="ui icon button" @click="inboxViewTog('list')" :class="{active: inboxView === 'list'}"><i class="th list icon"></i></button>
                    </div>
                </div>
            </div>
            <div class="mobile only equal width middle aligned row" :class="{'mobile-header-hidden': this.$route.name == 'inbox-message-details'}">
                <div class="column">
                    <div class="ui search">
                        <div class="ui left icon fluid input">
                            <i class="search icon"></i>
                            <input type="text" placeholder="Search contact" v-model="msisdn" v-on:keyup="searchContact()">
                        </div>
                    </div>
                </div>
            </div>
            <div class="mobile only equal width middle aligned row" v-show="this.$route.name == 'inbox-message-details'">
                <div class="one wide column" @click="$router.go(-1)">
                    <i class="fa fa-chevron-left"></i>
                </div>
                <div class="column" v-if="selectedInboxMessage">
                    <small>Messages with</small>
                    <h3>{{selectedInboxMessage.msisdn}}</h3>
                </div>
            </div>
        </div>
        <div v-show="inboxView === 'columns'" class="l-app-inbox-content">
            <div class="list">
                <div class="list-title">
                    {{dataListCount}} messages
                </div>
                <div class="list-item" v-for="(dataList,index) in dataLists" v-bind:key="dataList.inbox_id+index" @click="getMessageDetails(dataList)" :class="{active: selectedKey == dataList.inbox_id} ">
                    <div class="status"><i class="fa fa-comment-alt status-default "></i></div>
                    <div class="meta">
                        <h4>{{dataList.message}}</h4>
                        <h5 class="margin-top-sm text-grey">{{dataList.msisdn}}</h5>
                        <small class="text-lightgrey">{{dataList.date_recieved}}</small>
                    </div>
                </div>
    
                <div class="ui fluid bottom aligned button" v-show="!loading" @click="getContactList()">Load More</div>
                <loader v-show="loading"></loader>
            </div>
    
            <router-view></router-view>
        </div>
        <div v-show="inboxView === 'list'" class="l-app-inbox-content">
            <div class="ui relaxed padded grid">
                <div class="row">
                    <div class="column">
                        <div class="ui top attached secondary segment">
                            <div class="ui grid">
                                <div class="row">
                                    <div class="three wide column">
                                        Mobile
                                    </div>
                                    <div class="six wide column">
                                        Message
                                    </div>
                                    <div class="seven wide column right">
                                        Response
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ui attached segment" v-for="(details ,index) in detailsList" v-bind:key="details.inbox_id + index">
                            <div class="ui top aligned equal height grid">
                                <div class="three wide column">
                                    <h4 class="text-medium">{{details.msisdn}}</h4>
                                </div>
                                <div class="seven wide column border-left">
                                    <h4>{{details.incomming}}</h4>
                                    <small>Sent on {{details.date_recieved}}</small>
                                    <span class="text-green text-link text-medium" @click="addComments(list)"> Add Comment</span>
                                </div>
                                <div class="six wide top aligned right aligned column border-left">
                                    <div v-if="details.reply">
                                        <h4>{{details.reply}}</h4>
                                        <small>Sent on {{details.repliedOn}}</small>
                                    </div>
                                    <div v-if="!details.reply">
                                        <button id="button-approve" class="ui green tiny button" @click="sendMessageInbox(details)">Reply</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-show="!loading" class="ui fluid bottom aligned button" @click="getDetailsMore()">Load More</div>
                        <loader v-show="loading"></loader>
                    </div>
                </div>
            </div>
        </div>
        <inboxReplymessage />
        <inboxComments />
    </div>
</template>

<style scoped>
    form {
        display: inline-block;
    }
</style>

<script>
    import inboxReplymessage from '@/modals/inbox-reply-message';
    import inboxComments from '@/modals/inbox-comment';
    import format from 'date-fns/format';
    import loader from "@/components/loader";
    import MessageDetails from "./message-details";
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    import moment from "moment";
    export default {
        data() {
            return {
                inboxView: 'columns',
                messageDetails: false,
                dataLists: [],
                detailsList: [],
                dataListCount: 0,
                countN: 1,
                list_id: "",
                list_name: "",
                status: "",
                limit: 10,
                selectedKey: null,
                offset: 0,
                selectedList: '',
                order: "",
                showUploadSection: false,
                uploadFiles: null,
                processingUploadFile: false,
                //infinite scroll
                busy: false,
                totalContactLists: 0,
                loading: false,
                inboxzero: false,
                msisdn: "",
                dateOne: '',
                dateTwo: '',
                dateFormat: 'D MMM',
                dateFormatTwo: "YYYY-MM-DD",
                totalDetailsLists:0,
                offset2:0,
                limit2:10,
                selectedInboxMessage: null,
            }
        },
        components: {
            loader,
            MessageDetails,
            inboxReplymessage,
            inboxComments
        },
        mounted() {
            $(".ui.dropdown").dropdown();
            let vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            vm.inboxViewTog(vm.inboxView);
            var d = new Date();
            vm.dateOne = format(d.setDate(d.getDate() - 7), this.dateFormatTwo);
            vm.dateTwo = format(Date(), this.dateFormatTwo);
            //console.log(vm.dateTwo);
            
        },
        methods: {
            getMessageDetails(dataList) {
                if (dataList) {
                    this.selectedKey = dataList.inbox_id;
                    this.selectedInboxMessage = dataList;
                    store.commit("setInboxDetails", dataList);
                    this.$root.$emit('refreshList');
                    this.messageDetails = true;
                    this.$router.push({name: 'inbox-message-details', params: {id: dataList.profile_id}});
                    //this.$ref.details.getDetails(dataList.profile_id);
                }
    
            },
            inboxViewTog(view) {
                let vm = this;
                if (view == "list") {
                    vm.inboxView = "list";
                    vm.getDetailsMore();
                } else {
                    vm.inboxView = "columns";
                    vm.getContactList();
                }
            },
            searchContact() {
                let vm = this;
                vm.totalContactLists = 0;
                vm.dataListCount = 0;
                vm.offset = 0;
                vm.dataLists = [];
                vm.getContactList();
            },
    
            getContactList() {
                let vm = this;
                if (vm.totalContactLists === 0 || vm.totalContactLists >= ((vm.offset - 1) * vm.limit)) {
    
                    vm.loading = true;
                    vm.offset++;
                    $.post({
                        url: store.state.rootUrl + 'inboxmain/view/mobile',
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: this.$cookies.get("key"),
                            limit: vm.limit,
                            offset: vm.offset,
                            msisdn: vm.msisdn,
                            start: vm.dateOne,
                            stop: vm.dateTwo,
    
                        }),
                        success: function(response, status, jQxhr) {
                            vm.loading = false;
                            if (response.data.data != "") {
                                vm.inboxzero = false;
                                //vm.dataLists = response.data.data;
                                vm.dataListCount = response.data.count;
                                vm.totalContactLists = response.data.data[0].total;
                                var totalContacts = 0;
                                var list_c = response.data.data;
                                if (response.data.code != 404) {
                                    for (var i = 0; i < list_c.length; i++) {
                                        list_c[i].date_recieved = format(list_c[i].date_recieved, "hh:mma DD MMM YYYY");
                                        list_c[i].repliedOn = format(list_c[i].repliedOn, "hh:mma DD MMM YYYY");
                                        //console.log(list_c[i].date_recieved );
                                        vm.dataLists.push(list_c[i]);
                                        if (i == 0) {
                                            vm.selectedKey = list_c[0].profile_id;
                                            //vm.selectList(list_c[i]);
                                        }
                                    }
    
                                } else {
                                    alertify.error(response.data.message);
                                }
                            } else if (vm.offset > 1) {
                                vm.inboxzero = false;
                            } else {
                                vm.inboxzero = true;
                            }
                        },
                        error: function(jQxhr, status, error) {
                            vm.loading = false;
                            vm.dataLists = [];
                            store.commit("setInboxDetails", []);
                            alertify.error(jQxhr.responseJSON.statusDescription);
                        }
                    });
                }
            },

            exportContact(){
                let vm = this;
                var exportRecipients = [];
                $.post({
                        url: store.state.rootUrl + 'inboxmain/view/mobile',
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: this.$cookies.get("key"),
                            export: 1,
                            start: vm.dateOne,
                            stop: vm.dateTwo,
    
                        }),
                        success: function(response, status, jQxhr) {
                            alertify.success(response.data.message);
                            for (var i = 0; i < response.data.data.length; i++) {
                            var item = {};
                            item.short_code = response.data.data[i].short_code;
                            item.msisdn = response.data.data[i].msisdn;
                            item.incoming = response.data.data[i].incoming;
                            item.network_id = response.data.data[i].network_id;
                            item.date_recieved = response.data.data[i].date_recieved;
                            item.replyMessage = response.data.data[i].replyMessage;
                            item.description = response.data.data[i].description;
                            item.replyedOn = response.data.data[i].replyedOn;
                            exportRecipients.push(item);
                        }
    
    
                        var exportDate = moment().format('DD_MMMM_YYYY_h:mm');
    
                        vm.JSONToCSVConvertor(exportRecipients, "Campaign ID: " + vm.senderId, +"_" + exportDate, 1);
                        $('#button-export').html('<i class="icon download"></i> Download');

                        },
                        error: function(jQxhr, status, error) {
                            vm.loading = false;
                            vm.dataLists = [];
                            store.commit("setInboxDetails", []);
                            alertify.error(jQxhr.responseJSON.statusDescription);
                        }
                    });
            },
            JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
                //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
                var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    
                var CSV = '';
                //Set Report title in first row or line
    
                //CSV += ReportTitle + '\r\n\n';
    
                //This condition will generate the Label/Header
                if (ShowLabel) {
                    var row = "";
    
                    //This loop will extract the label from 1st index of on array
                    for (var index in arrData[0]) {
    
                        //Now convert each value to string and comma-seprated
                        row += index + ',';
                    }
    
                    row = row.slice(0, -1);
    
                    //append Label row with line break
                    CSV += row + '\r\n\n\n';
                }
    
                //1st loop is to extract each row
                for (var i = 0; i < arrData.length; i++) {
                    var row = "";
    
                    //2nd loop will extract each column and convert it in string comma-seprated
                    for (var index in arrData[i]) {
                        row += '"' + arrData[i][index] + '",';
                    }
    
                    row.slice(0, row.length - 1);
    
                    //add a line break after each row
                    CSV += row + '\r\n';
                }
    
                if (CSV == '') {
                    alert("Invalid data");
                    return;
                }
    
                //Generate a file name
                var fileName = "VasPro_";
                //this will remove the blank-spaces from the title and replace it with an underscore
                fileName += ReportTitle.replace(/ /g, "_");
    
                //Initialize file format you want csv or xls
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    
                // Now the little tricky part.
                // you can use either>> window.open(uri);
                // but this will not work in some browsers
                // or you will not get the correct file extension
    
                //this trick will generate a temp <a /> tag
                var link = document.createElement("a");
                link.href = uri;
    
                //set the visibility hidden so it will not effect on your web-layout
                link.style = "visibility:hidden";
                link.download = fileName + ".csv";
    
                //this part will append the anchor tag and remove it after automatic click
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
    
            getDetailsMore() {
                let vm = this;
                if(vm.totalDetailsLists === 0 || vm.totalDetailsLists >= ((vm.offset2 - 1) * vm.limit2)){
                vm.loading = true;
                vm.offset2++;
                $.post({
    
                    url: store.state.rootUrl + 'inboxmain/view/details',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        limit: vm.limit2,
                        offset: vm.offset2
    
                    }),
                    success: function(response, status, jQxhr) {
    
                        vm.loading = false;
    
                        if (response.data.data != "") {
                                vm.inboxzero = false;
                                vm.totalDetailsLists = response.data.data[0].total;

                                var list_c = response.data.data;
                                if (response.data.code != 404) {
                                    for (var i = 0; i < list_c.length; i++) {
                                        list_c[i].date_recieved = format(list_c[i].date_recieved, "hh:mma DD MMM YYYY");
                                        list_c[i].repliedOn = format(list_c[i].repliedOn, "hh:mma DD MMM YYYY");
                                        //console.log(list_c[i].date_recieved );
                                        vm.detailsList.push(list_c[i]);
                                    }
    
                                } else {
                                    alertify.error(response.data.message);
                                }
                            } else if (vm.offset > 1) {
                                vm.inboxzero = false;
                            } else {
                                vm.inboxzero = true;
                            }
    
                    },
                    error: function(jQxhr, status, error) {

                        vm.detailsList = [];
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
    
                });
                }
                
            },
            addComments(list){
                let vm = this;
                //console.log("am here");
                store.commit("setInboxDetails",list);
                $('.ui.modal#modal-inbox-comments').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            sendMessageInbox(clientDetails) {
                let vm = this;
                store.commit("setInboxDetails", clientDetails);
                $('.ui.modal#modal-inbox-new-message').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            formatDates(dateOne, dateTwo) {
                let formattedDates = ''
                if (dateOne) {
                    formattedDates = format(dateOne, this.dateFormat)
                }
                if (dateTwo) {
                    formattedDates += ' - ' + format(dateTwo, this.dateFormat)
                }
                return formattedDates
            },
            
        }
    }
</script>