<template>
    <div class="ui tiny modal" id="modal-create-questionnaire">
        <i class="close icon"></i>
        <div class="">
            <div class="ui equal width relaxed padded grid">
                <div class="row">
                    <div class="column">
                        <h2 class="text-primary">Create Questionnaire</h2>
                        <p>Fill in the details below</p>
                    </div>
                </div>
                <div class="row bg-grey-lighter border-top">
                    <div class="column">
                        <h3 class="text-title hidden">Questionnaire</h3>
                       <div class="ui form">
                           <div class="required field hidden"  >
                               <select class="ui dropdown" v-model="questionnaire" disabled>
                                   <option value="">Select a questionnaire or create new *</option>
                                   <option value="account" class="text-accent"><i class="icon plus"></i> Create new questionnaire</option>
                               </select>
                           </div>
                           <div class="required field" v-show="questionnaire == 'account'">
                                <label>Questionnaire Name</label>
                                <div class="ui fluid large input">
                                    <input placeholder="eg. Customer Questionnaire" v-model="questionnareName" />
                                </div>
                           </div>
                           <h4 class="padding-bottom-md padding-top-md" v-show="questionnaire == 'account'">Type of questionnaire</h4>
                           <div class="required field " v-show="questionnaire == 'account'">
                               <select class="ui dropdown" v-model="questionType">
                                   <option value="">Select a questionnaire type</option>
                                   <option v-for="s_id in suverysType" v-show="suverysType.length > 0 && s_id.status == 1" :key="s_id.type_id" :value="s_id.type_id">{{s_id.survey_name}}</option>
                               </select>
                           </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui left floated button" @click="hideModal()">Discard</button>
            <button id="button-create-survey-questionnaire"  :disabled="createStatus" class="ui bg-primary close button" v-show="questionnaire == 'account'" @click="applyquestionnaire()">Create Questionnaire</button>
        </div>
    </div>
    
</template>
<script>
import Router from 'vue-router';
import alertify from 'alertifyjs';
import store from "@/store";
import Choices from "choices.js";
import format from 'date-fns/format';
export default {
    data() {
        return {
            SurveyStatus: false,
            SurveyStatusDesc:"",
            suverysType: [],
            questionnaireList:[],
            questionnaire:"account",
            questionnaireNew:null,
            questionType:"",
            questionnareName:"",
            surveyName:"",
            surveyObjectives:"",
            dateOne: "",
            dateTwo: "",
            dateFormat: 'D MMM',
            surveySummary:[],
            app_id:"",
            startDate:null,
            createStatus:false
        }
        },
    mounted() {
        $(".ui.dropdown").dropdown();
        this.getSurveyType();
        this.getQuestionnaire()
        var d = new Date();
        this.startDate = format(d.setDate(d.getDate() + 1), this.dateFormatTwo);
    },
    methods:{
        getSurveyType() {
            let vm = this;
            $.post({
                url: store.state.rootURLBulkAPI + "v3/survey/types",
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key")
                }),
                success: function(response, status, jQxhr) {
                    vm.loading = false;
                    var resp = response.data.data;
                    if (response.data.code != 200) {
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(response.data.message);

                    } else {
                        for (var i = 0; i < resp.length; i++) {
                            vm.suverysType.push(resp[i]);
                        }
                    }
                    setTimeout(function() {
                        $(".ui.dropdown").dropdown();
                    }, 100)
                },
                error: function(jQxhr, status, error) {
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.error(jQxhr.responseJSON.statusDescription);
                }
            });
        },
        getQuestionnaire() {
            let vm = this;
            $.post({
                url: store.state.rootURLBulkAPI + "v3/survey/view/questionaire",
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key")
                }),
                success: function(response, status, jQxhr) {
                    vm.loading = false;
                    var resp = response.data.data;
                    if (response.data.code != 200) {
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(response.data.message);

                    } else {
                        for (var i = 0; i < resp.length; i++) {
                            vm.questionnaireList.push(resp[i]);
                        }
                    }
                    setTimeout(function() {
                        $(".ui.dropdown").dropdown();
                    }, 100)
                },
                error: function(jQxhr, status, error) {
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.error(jQxhr.responseJSON.statusDescription);
                }
            });
        },
        applyquestionnaire(){
            let vm = this;
           
                $('#button-create-survey').html('<i class="fas fa-circle-notch fa-spin"></i> Create Survey...');
                $('#button-create-survey-questionnaire').html('<i class="fas fa-circle-notch fa-spin"></i> Create Survey & Questionnaire ...');
                vm.createStatus = true;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/questoinnaire/create",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        questionnare_name:vm.questionnareName,
                        type_id:vm.questionType,
                        description: "Questionnaire Application :"+vm.questionnareName
                    }),
                    success: function(response, status, jQxhr) {
                       
                        vm.$router.push({name: 'questionnaire-add', params: {id: response.data.data.id}});
                        vm.hideModal();
                    },
                    error: function(jQxhr, status, error) {
                        vm.closeLoader();
                        vm.SurveyStatus = true;
                        vm.SurveyStatusDesc = jQxhr.responseJSON.statusDescription;
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            
        },

        hideModal(){
            $(".ui.modal#modal-create-questionnaire").modal("hide");
        },
        formatDates(dateOne, dateTwo) {
            let formattedDates = ''
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat)
            }
            if (dateTwo) {
                formattedDates += ' - ' + format(dateTwo, this.dateFormat)
            }
            return formattedDates
        },
        getsurveyData(){
            let vm = this;
            $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/view/summary",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        app_id: vm.app_id
                    }),
                    success: function(response, status, jQxhr) {
                        var resp = response.data.data;
                        if (response.data.code != 200) {
                            alertify.set('notifier', 'position', 'top-right');
                            alertify.error(response.data.message);
    
                        } else {
                            var responseRate = 0;
                            for (var i = 0; i < resp.length; i++) {
                                // console.log(" loop " + i + " " + JSON.stringify(resp[i]));
                                resp[i].progressRate = resp[i].contacts !=0 ? ((parseInt(resp[i].complete) / parseInt(resp[i].contacts)) *100).toFixed(0) :0;
                                resp[i].response = resp[i].response !=null ? resp[i].response : 0;
                                resp[i].complete = resp[i].complete !=null ? resp[i].complete : 0;
                                resp[i].progress = resp[i].progress !=null ? resp[i].progress : 0;
                               vm.surveySummary.push(resp[i]);
                            }
                            store.commit("setSurveySelect", vm.surveySummary);
                            
                           

                        }
    
                    },
                    error: function(jQxhr, status, error) {
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
        },
        closeLoader(){
            let vm = this;
            vm.createStatus = false;
            $('#button-create-survey').html('Create Survey');
            $('#button-create-survey-questionnaire').html('Create Survey & Questionnaire');
        }
    }
}
</script>
