<template>
    <div class="ui tiny modal" id="modal-blacklist-add">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md">
                <h2 class="padding-bottom-sm">Blacklist Add</h2>
                <h4>You can add contact to blacklist</h4>
                <div class="ui negative message" v-show="createListStatus">
                    {{createListDesc}}
                </div>
            </div>
            <div class="ui centered container grid">
                <div class="column">
                    <div class="ui form">
                        <div class="required field" id="input-list-name">
                            <div class="field">
                                <label>Phone</label>
                                <div class="ui large input">
                                    <input type="text" placeholder="0725560980" required v-model="phone">
                                </div>
                            </div>
                        </div>
                        <div class="required field" id="input-description-name">
                            <div class="field">
                                <label>Selct Sender Id</label>
                                <div class="ui large input">
                                    <select class="ui fluid dropdown" v-model="senderId">
                                        <option value="">Sender Id</option>
                                        <option v-for="s_id in dataListsSenderID" v-show="dataListsSenderID.length > 0 && s_id.status == 13" :key="s_id.short_code">{{s_id.short_code}}</option>
                                    
                                    </select>
                                </div>
                            </div>
                        </div>
    
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary button" @click="createList()">Create Contact List</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import alertify from "alertifyjs";
    
    export default {
        data() {
            return {
                inboxzero: false,
                phone: "",
                senderId: "",
                createListStatus: false,
                createListDesc: "",
                dataListsSenderID:[]
            };
        },
        mounted() {
            let vm = this;
            vm.fetchData();
        },
        methods: {
            hideModal() {
                $(".ui.modal#modal-blacklist-add").modal("hide");
            },
            createList() {
                let vm = this;
               
                $.post({
                    url: store.state.rootUrl + 'contact/blacklist/create',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        msisdn: vm.phone,
                        senderId: vm.senderId
                    }),
                    success: function(response, status, jQxhr) {
                        alertify.success("Contact List has been created");
                        $(".ui.modal#modal-blacklist-add").modal("hide");
                        vm.$parent.getContactList();
                        vm.$root.$emit('refreshList');
                    },
                    error: function(jQxhr, status, error) {
                        vm.createListStatus = true;
                        vm.createListDesc = jQxhr.responseJSON.statusDescription;
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            },
            fetchData() {
                var vm = this;
    
                $.post({
                    url: store.state.rootURLBulkAPI + 'v3/senderId/list',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key")
                    }),
                    success: function(response, status, jQxhr) {
                        if (response.data.code == 404) {
                            vm.inboxzero = true;
                        } else {
                            vm.dataListsSenderID = response.data.data;
                        }
                    },
                    error: function(jQxhr, status, error) {}
                });
    
            },
        }
    };
</script>
