<template>
    <div class="ui tiny modal" id="modal-create-keyword-inbox">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md">
                <h2 class="padding-bottom-sm">Create Inbox Keyword </h2>
                <h4>You can use the Inbox Keywords</h4>
                <div class="ui negative message" v-show="createListStatus">
                            {{createListDesc}} 
                </div>
            </div>
            <div class="ui centered container grid">
                <div class="column">
                    <div class="ui form">
                         <div class="required field" id="input-list-name">
                            <div class="field">
                                <label>keyword</label>
                                <div class="ui large input">
                                    <input type="text" placeholder="e.g. Vaspro Tech" required v-model="keywordName">
                                </div>
                            </div>
                            <div class="field">
                                <label>Select Short Codes</label>
                                <select class="ui fluid dropdown" v-model="shortCodeNum">
                                    <option value="">Select Short Codes</option>
                                    <option value="40399">40399</option>
                                    <option v-for="s_id in shortcodes" v-show="shortcodes.length > 0" :key="s_id.id" :value="s_id.short_code">{{s_id.short_code}}</option>
                                </select>
                            </div>
                         </div>
                        
                        <div class="padding-sm"></div>
                        <div class="field">
                            <label>Add Alternative Words</label>
                        </div>
                        <div class="ui negative message" v-show="customStatus">
                            {{StatusMessage}} 
                        </div>
                        <div class="field">
                            <div class="ui action input">
                                <input placeholder="Vas" v-model="custom_field">
                                <button class="ui button" @click="populateCustomFields()">Add</button>
                            </div>
                        </div>
                        
                        <div class="padding-sm"></div>
                        <div class="custom-fields" v-show="custom_field2 || custom_field3 || custom_field4 || custom_field5">
                            
                            <span class="ui blue label" v-show="custom_field2" @click="removeCustomField(2)">
                            {{custom_field2}}
                            <i class="fas fa-times"></i>
                            </span>
                                        <span class="ui purple label" v-show="custom_field3" @click="removeCustomField(3)">
                                {{custom_field3}}
                                <i class="fas fa-times"></i>
                            </span>
                                        <span class="ui orange label" v-show="custom_field4" @click="removeCustomField(4)">
                                {{custom_field4}}
                                <i class="fas fa-times"></i>
                            </span>
                                        <span class="ui teal label" v-show="custom_field5" @click="removeCustomField(5)">
                                {{custom_field5}}
                                <i class="fas fa-times"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary button" @click="createList()">Create Keywords</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import alertify from "alertifyjs";
    
    export default {
        data() {
            return {
                inboxzero: false,
                keywordName: "",
                custom_field2: "",
                custom_field3: "",
                custom_field4: "",
                custom_field5: "",
                custom_field: "",
                StatusMessage: "",
                customStatus: false,
                createListStatus:false,
                createListDesc:"",
                alt_keywords:"",
                shortcodes:[],
                shortCodeNum:""
            };
        },
        mounted() {
            let vm = this;
            vm.getShortCodes();
        },
        methods: {
            hideModal() {
                $(".ui.modal#modal-create-keyword-inbox").modal("hide");
            },
            removeCustomField(key) {
                if (key == 2) {
                    this.custom_field2 = "";
    
                } else if (key == 3) {
                    this.custom_field3 = "";
    
                } else if (key == 4) {
                    this.custom_field4 = "";
    
                } else if (key == 5) {
                    this.custom_field5 = "";
                }
    
            },
            populateCustomFields() {
                if (!this.custom_field2) {
                    this.custom_field2 = this.custom_field;
                    if(this.alt_keywords !=""){
                        this.alt_keywords = this.alt_keywords +'|'+ this.custom_field;
                    }
                    else{
                        this.alt_keywords = this.custom_field;
                    }
                    
                    this.custom_field = "";
                } else if (!this.custom_field3) {
                    this.custom_field3 = this.custom_field;
                    if(this.alt_keywords !=""){
                        this.alt_keywords = this.alt_keywords +'|'+ this.custom_field;
                    }
                    else{
                        this.alt_keywords = this.custom_field;
                    }
                    this.custom_field = "";
                } else if (!this.custom_field4) {
                    this.custom_field4 = this.custom_field;
                    if(this.alt_keywords !=""){
                        this.alt_keywords = this.alt_keywords +'|'+ this.custom_field;
                    }
                    else{
                        this.alt_keywords = this.custom_field;
                    }
                    this.custom_field = "";
                } else if (!this.custom_field5) {
                    this.custom_field5 = this.custom_field;
                    if(this.alt_keywords !=""){
                        this.alt_keywords = this.alt_keywords +'|'+ this.custom_field;
                    }
                    else{
                        this.alt_keywords = this.custom_field;
                    }
                    this.custom_field = "";
                } else {
                    this.customStatus = true;
                    this.StatusMessage = "Maximum fields reached";
                    console.log("Maximum fields reached");
                }
    
            },
            createList() {
                let vm = this;
                if(vm.keywordName ==""){
                   $("#input-list-name").addClass("error");
                }
                $.post({
                    url: store.state.rootUrl + "notify/keyword/create",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        keyword: vm.keywordName,
                        alt_keywords: vm.alt_keywords,
                        short_code:vm.shortCodeNum
                    }),
                    success: function(response, status, jQxhr) {
                        alertify.success(response.data.message);
                        $(".ui.modal#modal-create-keyword-inbox").modal("hide");
                        vm.$parent.getKeywords();
                        vm.$root.$emit('refreshList');
                    },
                    error: function(jQxhr, status, error) {
                        vm.createListStatus = true;
                        vm.createListDesc = jQxhr.responseJSON.statusDescription;
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            },
            getShortCodes(){
                let vm =this;
                $.post({
    
                    url: store.state.rootURLBulkAPI + 'v3/inboxmain/view/shortcode',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        category:1
                    }),
                    success: function(response, status, jQxhr) {
                        if (response.data.data != "") {
                            vm.shortcodes = response.data.data;
                        }
                    },
                    error: function(jQxhr, status, error) {

                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
    
                });
            },
        }
    };
</script>
