<template>
    <div class="ui tiny modal" id="modal-Survey-Edit-Name">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">Survey {{originalName}}</h2>
                
            </div>
            <div class="row">
                <div class="column">
                    <div class="ui form">
                        <div class="required field">
                            <label>Survey title</label>
                            <div class="ui fluid large input">
                                <input placeholder="eg. Customer satisfaction" v-model="surveyName"/>
                            </div>
                        </div>
                        <div class="required field">
                            <label>Purpose of the survey</label>
                            <select class="ui dropdown" v-model="objectives">
                                <option value="Customer Satisfaction">Customer Satisfaction</option>
                                <option value="Data Collection">Data collection</option>
                                <option value="Product Reviews">Product Reviews</option>
                            </select>
                        </div>
                        <div class="required field">
                            <label>Survey Target</label>
                            <div class="ui fluid large input">
                                <input type="number" placeholder="eg. 1000" v-model="targeted_respondents"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ui negative message" v-show="SurveyStatus">
                {{SurveyStatusDesc}}  
            </div>
        </div>
        <div class="actions">
            <button class="ui cancel button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary button" @click="Updatesurvey()">Confirm</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    export default {
        data() {
            return {
                SurveyStatus: false,
                SurveyStatusDesc:"",
                surveyName:"",
                id:"",
                status:0,
                statusMessage:"",
                objectives:"",
                targeted_respondents:""


            }
        },
        mounted() {
            let vm = this;
           // vm.checkServices(service);
        },
        computed: {
            originalName() {
                var vm = this;
                var surveyDetails = store.state.selectSurvey;
                vm.surveyName = surveyDetails.campaign_name;
                vm.id = surveyDetails.app_id;
                vm.objectives = surveyDetails.objectives;
                vm.targeted_respondents = surveyDetails.targeted_respondents;
                //5 deactivate 6 activate
                return vm.survey;
    
            }
        },
        methods: {
          
            Updatesurvey(){
                let vm = this;
                $.post({
                    url: store.state.rootUrl + 'survey/update',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        app_id: vm.id,
                        campaign_name:vm.surveyName,
                        objectives:vm.objectives,
                        targeted_respondents:vm.targeted_respondents
                    }),
                    success: function(response, status, jQxhr) {
                        if(response.data.code != 200){
                            vm.SurveyStatus= true;
                            vm.SurveyStatusDesc = response.data.data.message;
                            alertify.set('notifier', 'position', 'top-right');
                            alertify.error(response.data.message);
                        }
                        else{
                            alertify.set("notifier", "position", "top-right");
                            alertify.success(response.data.message);
                            vm.$parent.reload();
                            vm.hideModal();
                        }
                    },
                    error: function(jQxhr, status, error) {
                        vm.SurveyStatus= true;
                        vm.SurveyStatusDesc = jQxhr.responseJSON.statusDescription;
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });

            },
            hideModal() {
                $('.ui.modal#modal-Survey-Edit-Name').modal('hide');
            }
        }
    }
</script>
