<template>
    <div class="ui tiny modal" id="modal-agg-paybill-till">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">Aggregate Paybill or Till</h2>
                <h4>Get realtime reports from your MPESA/Airtel Money<br />Paybill or Till Number</h4>
            </div>
            <div class="ui centered container grid">
                <div class="column">
                    <div class="ui form">
                        <div class="field">
                            <label>Company Name</label>
                            <div class="ui input">
                                <input type="text" v-model="companyName"/>
                            </div>
                        </div>
                        
                        <div class="field">
                            <label>Select Paybill Type</label>
                            <select class="ui fluid dropdown"  @change="onChange($event)" v-model="typePaybill">
                                    <option value="" selected>Select Paybill Type</option>
                                    <option value="1">Safaricom Till Number</option>
                                    <option value="2" >Paybill Number</option>
                            </select> 
                        </div>
                        <div class="field" v-show="paybillTypeStatus">
                            <label>{{paybillType}}</label>
                            <div class="ui input">
                                <input type="text" pattern="\d*" maxlength="7" minlength="7" v-model="paybillTypeNumber"/>
                            </div>
                        </div>
                        <div class="field" v-show="paybillTypeStatus">
                            <label>{{paybillTypeName}}</label>
                            <div class="ui input">
                                <input type="text" pattern="\d*" maxlength="7" minlength="7" v-show="typePaybill==1" v-model="TillStoreNumber"/>
                            </div>
                        </div>
                        
                        <div class="field">
                            <label> Download Sample Application Letter </label>
                            <div class="margin-right-md"> <a target="_blank" href="../../files/Till No_Paybill.docx" class="ui tiny left floated icon labeled button"><i class="download icon"></i> Sample Letter</a></div>
                        </div>
                        <div class="padding-sm"></div>
                        <div class="field">
                            <label>Upload Application Letter (Only PDF File)</label>
                            <div class="ui file input">
                                <input type="file" id="file" accept="application/pdf" ref="file" v-on:change="handleFileUpload()" />
                            </div>
                        </div>
                        <div class="padding-sm"></div>
                        <div class="field">
                            <div class="ui checkbox">
                                <input type="checkbox" v-model="agreeTerms"/>
                                <label>I agree to the Terms and Conditions of this service</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui large button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary large button" :disabled="!agreeTerms" @click="aggPaybillTill()">Save</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    export default {
        data() {
            return {
                typePaybill: "",
                paybillTypeStatus: false,
                agreeTerms: false,
                paybillType: "",
                safaricomUsername:"",
                dataAmount: 0,
                sendID: '',
                max:6,
                min:6,
                paybillTypeNumber:"",
                type: "",
                companyName: "",
                TillStoreNumber:null,
                paybillTypeName:""
            }
        },
        mounted() {
            let vm = this;
            vm.fetchPaybiillType();

        },
        methods: {
            fetchPaybiillType(){
                $.get({
                    url:store.state.rootURLBulkAPI + 'v3/payment/paybill/types',
                    type:"GET",
                    success:function(data,status,jQxhr){
                        

                    },
                    error:function (jQxhr,status,error) {
                      // console.log("ERROR "+JSON.stringify(jQxhr.responseJSON.error));
                    }
                });
            },
            onChange(e){
            let vm = this;''
            vm.paybillTypeStatus = true;
            vm.type = e.target.value;
             if(vm.type == 1){
                 vm.paybillType = "Till Number";
                 vm.paybillTypeName = "Store Number";
             }
             else{
                 vm.paybillType = "Paybill Number";
                 vm.paybillTypeName = "";
             }
            },
            handleFileUpload() {
                this.file = this.$refs.file.files[0];
            },
            aggPaybillTill(){
                let vm = this;
               // console.log(vm.network_saf, vm.network_airtel, vm.network_telkom)
                var form = new FormData();
                form.append("uploadedFiles", vm.file);
                form.append("apiKey", vm.$cookies.get("key"));
                form.append("paybill_typeId", vm.type);
                form.append("paybill",vm.paybillTypeNumber);
                form.append("organisation_name",vm.companyName);
                form.append("client_id", "");
                form.append("callback", "");
                form.append("message", "");
                form.append("store_number",vm.TillStoreNumber);
                $.post({
                    url: store.state.rootURLBulkAPI + 'v3/payment/paybill/register',
                    type: "POST",
                    data: form, //JSON.stringify({"file":myFile}),
                    cache: false,
                    contentType: false,
                    processData: false,
                    success: function(response, status, jQxhr) {
                        if(response.data.code ==202){
                            alertify.set("notifier", "position", "top-right");
                            alertify.error(response.data.message);
                        }
                        else{

                            alertify.set("notifier", "position", "top-right");
                            
                            alertify.success(response.data.message);
                            vm.$parent.getPaybills();
                            vm.hideModal();
                        }
                        
                        
                    },
                    error: function(jQxhr, status, error) {
                        alertify.set("notifier", "position", "top-right");
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            },
            hideModal() {
                $('.ui.modal#modal-agg-paybill-till').modal('hide');
            }
        }
    }
</script>
