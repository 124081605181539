<template>
    <div class="ui tiny modal" id="modal-edit-keyword">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">Edit Keyword {{originalName}}</h2>
                <h4>Your customers will use this keyword to <br />send an SMS to shortcode 40399</h4>
                <div class="ui negative message" v-show="keywordStatus">
                    {{keywordDesc}}
                </div>
            </div>
            <div class="ui form">
                <div class="field">
                    <label>Title</label>
                    <div class="ui input">
                        <input type="text" v-model="keyword_title" >
                    </div>
                </div>
                 <div class="field">
                    <label>Type Response Message</label>
                    <div class="ui top attached segment" id="textarea-sms">
                            <textarea class="l-type-message" placeholder="Type your response message here" v-model="responseMessage" ></textarea>
                    </div>
                    <div class="ui bottom attached segment bg-grey-light">
                        <div class="ui equal width grid">
                            <div class="column">
                                <small>{{messageCount}} Characters (Depends on Placeholders) 
                                </small>
                            </div>
                            <div class="right aligned column">
                                <small>{{messagePages}} SMS</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui cancel button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary button" @click="createKeyword()">Update Keyword</button>
        </div>
    </div>
</template>
<script>
    import store from "@/store";
    import alertify from "alertifyjs";
    import bulkstore from "@/bulk/bulksms-state";
    export default {
        data() {
            return {
                inboxzero: false,
                keyword_title: "",
                keywordStatus:false,
                keywordDesc: "",
                responseMessage:"",
                keywordId:""
                
            };
        },
        computed: {
            originalName() {
                var vm = this;
                console.log(JSON.stringify(store.state.selectKeyword));
                vm.keyword_title = store.state.selectKeyword.title;
                vm.responseMessage = store.state.selectKeyword.auto_response;
                vm.keywordId = store.state.selectKeyword.id;
            },
            messageCount() {
                return  0;//this.responseMessage.length +14; 
            },
            messagePages() {
                /** if (this.responseMessage.length == 0) {
                    return 0;
                } else if ((this.responseMessage.length +14) / 160 <= 1) {
                    return 1;
                } else if ((this.responseMessage.length +14)  / 160 <= 2) {
                    return 2;
                } else if ((this.responseMessage.length +14)  / 160 <= 3) {
                    return 3;
                } else if ((this.responseMessage.length +14)  / 160 <= 4) {
                    return 4;
                } else if ((this.responseMessage.length +14) / 160 <= 5) {
                    return 5;
                } else {
                    return " Max ";
                } */
                return 0;
            },
        },
        methods: {
            hideModal() {
                $(".ui.modal#modal-edit-keyword").modal("hide");
            },
            createKeyword() {
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/contact/keyword/edit",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        title:vm.keyword_title,
                        response: vm.responseMessage,
                        keywordId: vm.keywordId
                    }),
                    success: function(response, status, jQxhr) {
                        alertify.success(response.statusDescription);
                        vm.$parent.getAllkeyword();
                        vm.$root.$emit('refreshKeyword');
                        vm.hideModal();
                    },
                    error: function(jQxhr, status, error) {
                        vm.keywordStatus = true;
                        vm.keywordDesc = jQxhr.responseJSON.statusDescription;
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            }
        }
    };
</script>