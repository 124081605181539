<template>
<div class="l-onboard">
    <div class="l-onboard-center">
        <div class="ui centered grid">
            <div class="row">
                <div class="sixteen wide center aligned column padding-bottom-md">
                    <router-link to="/"><img src="../assets/images/logo.png" height="40" /></router-link>
                </div>
                <div class="twelve wide computer fourteen wide tablet sixteen wide mobile column">
                    <h1 class="text-center text-medium">Sign up</h1>
                    <p class="text-center">Sign up for an account.</p>
                    <div class="padding-bottom-lg"></div>
                    <div class="ui negative message" v-show="registerStatus">
                        {{ registerStatusMessage }}
                        <a href="/login" v-show="loginRiderrect">Click here to login</a>
                    </div>

                    <div class="ui form">
                        <div class="required field" id="input-full-name">
                            <label>Full Name</label>
                            <div class="ui large fluid input">
                                <input type="text" placeholder="John Pro" required v-model="fullName" />
                            </div>
                        </div>
                        <br />
                        <div class="field" id="input-company-name">
                            <label>Business Name</label>
                            <div class="ui large fluid large input">
                                <input type="text" placeholder="enter company name" v-model="companyName" />
                            </div>
                        </div>
                        <br />
                        <div class="required field" id="input-email">
                            <label>Enter Email Address</label>
                            <div class="ui large fluid input">
                                <input type="email" placeholder="john@core.com" required v-model="email" />
                            </div>
                        </div>
                        <div class="required field" id="input-phone-number">
                            <label>Enter Phone Number</label>
                            <div class="ui large fluid left labeled input">
                                <input type="tel" class="" placeholder="254723456780" required v-model="mobile" />
                            </div>
                        </div>
                        <div class="field" id="input-referral-code">
                            <label>Referral Code</label>
                            <div class="ui large fluid large input">
                                <input type="text" placeholder="enter referral code" v-model="referral" />
                            </div>
                        </div>
                        <br />
                        <div class="field">
                            <div class="ui checkbox">
                                <input type="checkbox" v-model="allowLoginViaOkta" />
                                <label>Allow login via Okta</label>
                            </div>
                        </div>
                        <br />
                        <div class="field">
                            <div class="ui checkbox">
                                <input type="checkbox" v-model="agreeTerms" />
                                <label>By signing up, you agree to our
                                    <router-link to="/terms" class="text-underline">Terms of Use</router-link>.</label>
                            </div>
                        </div>
                        <br />
                        <div class="field">
                            <button id="button-next" class="ui large bg-primary fluid button" @click="register" :disabled="!agreeTerms">
                                Next
                            </button>
                        </div>
                        <div class="padding-md"></div>
                        <div class="field">
                            <router-link :to="{ name: 'login' }">or Log in</router-link>
                        </div>
                    </div>
                    <div class="padding-lg"></div>
                </div>
            </div>
        </div>
    </div>
    <verifyAccount />
</div>
</template>

<script>
import store from "../store";
import verifyAccount from "../modals/verify-account";
import $ from 'jquery'
export default {
    data: function () {
        return {
            fullName: null,
            email: null,
            referral: null,
            mobile: null,
            companyName: null,
            registerStatus: false,
            registerStatusMessage: null,
            agreeTerms: false,
            loginRiderrect: false,
            allowLoginViaOkta: false
        };
    },
    mounted() {
        let vm = this;
        if (vm.$route.params.referral) {
            vm.referral = vm.$route.params.referral;
        }
    },
    components: {
        verifyAccount,
    },
    methods: {
        register() {
            var vmRegister = this;
            $(
                "#input-full-name, #input-last-name, #input-email, #input-phone-number"
            ).removeClass("error");
            if (!vmRegister.fullName) {
                $("#input-full-name").addClass("error");
                return;
            } else if (!vmRegister.email) {
                $("#input-email").addClass("error");
                return;
            } else if (!vmRegister.mobile || vmRegister.mobile.length === 0) {
                $("#input-phone-number").addClass("error");
                return;
            } else if (vmRegister.mobile.length < 9 || vmRegister.mobile.length > 13) {
                $("#input-phone-number").addClass("error");
                return;
            }

            vmRegister.registerStatus = false;
            $("#button-next").html('<i class="fas fa-circle-notch fa-spin"></i>');

            $.post({
                url: store.state.rootURLUser + "users/create",
                type: "POST",
                data: JSON.stringify({
                    mobile_no: vmRegister.mobile,
                    email_address: vmRegister.email,
                    full_names: vmRegister.fullName,
                    referrer: vmRegister.referral,
                  client_name: vmRegister.companyName ? vmRegister.companyName : "",
                    allowLoginViaOkta: vmRegister.allowLoginViaOkta
                }),
                success: function (response) {
                    if (response.data.code == 202) {
                        vmRegister.registerStatus = true;
                        vmRegister.loginRiderrect = true;
                        vmRegister.registerStatusMessage = response.data.message;
                        $("#button-next").html("Next");
                    } else {
                        vmRegister.$cookies.set("iscalled", true, "60s");
                        vmRegister.$cookies.set("username", window.btoa(vmRegister.mobile));
                        vmRegister.$router.push({
                            name: "verify-account",
                        });
                    }
                },
                error: function (jQxhr) {
                    var statustext = jQxhr.responseJSON.statusDescription;
                    vmRegister.registerStatus = true;
                    if (!statustext) {
                        vmRegister.registerStatusMessage = "An error occurred. Try again later.";
                    } else {
                        vmRegister.registerStatusMessage = statustext;
                    }
                    $("#button-next").html("Next");
                },
            });
        },
        verifcationModal() {
            $(".ui.modal#modal-verify-account")
                .modal({
                    closable: true,
                })
                .modal("show");
        },
    },
};
</script>
