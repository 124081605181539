<template>
  <div class="l-app-main">
    <div class="l-app-main-inboxzero bg-primary-lighter" v-show="inboxzero">
      <h2>No contacts</h2>
      <p>Create a contact list to send SMS and<br />manage your contacts</p>
      <div class="padding-sm"></div>
      <button class="ui large bg-primary button" @click="createContactList()">
        Create Contact List
      </button>
      <div class="padding-xl"></div>
    </div>
    <div class="l-app-main-section" v-show="!inboxzero">
      <div class="l-app-main-section-container one">
        <div class="l-app-list-title">
          <div class="ui middle aligned grid">
            <div class="column">
              <button
                class="ui bg-primary small fluid button"
                @click="createContactList()"
              >
                Add Contact List
              </button>
            </div>
          </div>
          <br />
          <div class="ui search link input">
            <input
              type="text"
              placeholder="Search Contact"
              v-on:keyup="getContactList(list_name)"
              v-model="list_name"
            />
          </div>
        </div>

        <ul class="l-app-list">
          <li @click="selectList()">
            <div class="status hidden"><i class="circle green icon"></i></div>
            <div class="info">
              <span class="info-title">All Contacts</span>
              <span class="info-meta"> {{ totalAllContacts }} contacts </span>
            </div>
          </li>
          <li
            v-for="dataList in dataLists"
            v-bind:key="dataList.contact_groupId + 1"
            @click="selectList(dataList)"
            v-bind:class="{ selected: selectedKey == dataList.contact_groupId }"
          >
            {{ dataList.list_name }}
            <div class="status hidden"><i class="circle green icon"></i></div>
            <div class="info">
              <span class="info-title">{{ dataList.group_name }}</span>
              <span class="info-meta">{{ dataList.total }} contacts</span><br />
              <span class="info-meta">{{ dataList.description }}</span>
            </div>
          </li>
        </ul>

        <div class="padding-md">
          <div
            v-show="!loading"
            @click="getContactList()"
            class="ui fluid button bottom aligned button"
          >
            Load More
          </div>
        </div>
        <loader v-show="loading"></loader>
      </div>

      <ListDetails v-show="selectedList" ref="details" />
    </div>
    <CreateContactList />
    <EditContactList />
  </div>
</template>

<script>
import loader from "@/components/loader";
import store from "@/store";
import bulkstore from "@/bulk/bulksms-state";
import CreateContactList from "@/modals/contact-list-create";
import EditContactList from "@/modals/contact-list-edit";
import Dropzone from "dropzone";
// import CreateContactList from "@/bulk/modals/contacts-list-create";

import ListDetails from "./list-details";
export default {
  data() {
    return {
      inboxzero: false,
      dataLists: [],
      dataListCount: 0,
      countN: 1,
      list_id: "",
      list_name: "",
      status: "",
      limit: 20,
      selectedKey: null,
      offset: 0,
      selectedList: "",
      order: "",
      showUploadSection: false,
      uploadFiles: null,
      processingUploadFile: false,
      //infinite scroll
      busy: false,
      totalContactLists: 0,
      loading: true,
      allContacts: [],
      totalAllContacts: 0,
    };
  },
  components: {
    ListDetails,
    loader,
    CreateContactList,
    EditContactList,
  },
  mounted() {
    let vm = this;
    if (!vm.$cookies.get("key") || vm.$cookies.get("key") == null) {
      vm.$router.push({
        name: "home",
      });
    }
    if (vm.$route.params.id != "" && !isNaN(vm.$route.params.id)) {
      var listid = vm.$route.params.id;
      vm.selectedKey = listid;
      vm.getDetails(listid);
      // this.$router.push({name: 'ListDetails', params: { id: listid}});
    }
    $(".ui.dropdown").dropdown();
    $(".ui.progress").progress();
    vm.getContactList();
    this.$root.$on("refreshList", (data) => {
      vm.dataLists = [];
      vm.dataListCount = 0;
      vm.countN = 1;
      vm.list_id = "";
      vm.list_name = "";
      vm.status = "";
      vm.limit = 10;
      vm.offset = 0;
      vm.totalContactLists = 0;
      vm.selectedList = "";
      vm.getContactList();
    });
    this.$root.$on("refreshSelected", (data) => {
      vm.dataLists = [];
      vm.dataListCount = 0;
      vm.countN = 1;
      vm.list_id = "";
      vm.list_name = "";
      vm.status = "";
      vm.limit = 10;
      vm.offset = 0;
      vm.totalContactLists = 0;
      vm.getContactList();
    });
    this.totalContactLists = 0;
    this.dataLists = [];
    this.selectedList = "";
    bulkstore.commit("setSelectedList", "");
  },
  methods: {
    createContactList() {
      $(".ui.modal#modal-create-contact-list")
        .modal({
          centered: false,
          closable: true,
        })
        .modal("show");
    },
    editContactList() {
      $(".ui.modal#modal-edit-contact-list")
        .modal({
          centered: false,
          closable: true,
        })
        .modal("show");
    },
    showApplySenderID() {
      $(".ui.modal#modal-apply-senderid")
        .modal({
          centered: false,
          closable: true,
        })
        .modal("show");
    },
    getContactList(getList = null) {
      var vm = this;
      if (getList != null) {
        vm.totalContactLists = 0;
        vm.dataLists = [];
        vm.offset = 0;
      }

      if (
        vm.totalContactLists === 0 ||
        vm.totalContactLists >= (vm.offset - 1) * vm.limit
      ) {
        vm.loading = true;
        vm.offset++;
        $.post({
          url: store.state.rootUrl + "contact/get/group",
          type: "POST",
          data: JSON.stringify({
            apiKey: this.$cookies.get("key"),
            list_id: vm.list_id,
            list_name: vm.list_name,
            status: 1,
            limit: vm.limit,
            offset: vm.offset,
          }),
          success: function (response, status, jQxhr) {
            vm.loading = false;
            if (response.data.data != "") {
              vm.inboxzero = false;
              //vm.dataLists = response.data.data;
              vm.dataListCount = response.data.count;
              vm.totalContactLists = response.data.data[0].total;
              var totalContacts = 0;
              var list_c = response.data.data;

              for (var i = 0; i < list_c.length; i++) {
                totalContacts = totalContacts + parseInt(list_c[i].total, 10);
                vm.dataLists.push(list_c[i]);
                if (i == 0) {
                  // vm.selectList(list_c[i]);
                }
              }
              bulkstore.commit("setContactList", vm.dataLists);
            } else if (vm.offset > 1) {
              vm.inboxzero = false;
            } else {
              vm.inboxzero = true;
            }
          },
          error: function (jQxhr, status, error) {
            vm.loading = false;
            vm.dataLists = [];
            bulkstore.commit("setContactList", []);
          },
        });
      }
    },
    getDetails(listid) {
      let vm = this;
      $.post({
        url: store.state.rootUrl + "contact/get/list",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
          list_id: listid,
          list_name: "",
          status: "",
          limit: "",
          offset: "",
        }),
        success: function (response, status, jQxhr) {
          var list_c = response.data.data;
          vm.selectedList = list_c;
          bulkstore.commit("setSelectedList", list_c);
          vm.$refs.details.setListValue(list_c);
        },
        error: function (jQxhr, status, error) {},
      });
    },

    selectList(list) {
      //console.log("am here "+JSON.stringify(list));

      this.selectedKey = "";
      this.selectedList = [];
      this.$route.params.id = "";
      if (list != null) {
        this.selectedKey = list.contact_groupId;
        this.selectedList = list;
        bulkstore.commit("setSelectedList", list);
        this.$refs.details.setListValue(list);
        if ($(window).width() < 640) {
          this.$router.push({
            name: "ListDetails",
            params: { id: list.contact_groupId },
          });
        }
      } else {
        this.selectedKey = "";
        this.selectedList = "";
        console.log("check list");
        if ($(window).width() < 640) {
          this.$router.push({ name: "ListDetails" });
        }
      }
    },
  },
};
</script>
