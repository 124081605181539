var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"l-app-main bg-primary-bg"},[_c('div',{staticClass:"l-app-dashboard bg-primary-lighter"},[_c('div',{staticClass:"ui padded relaxed grid"},[_c('div',{staticClass:"equal width aligned row"},[_c('div',{staticClass:"column"},[_c('h3',[_vm._v("Summary")]),_c('h4',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.from))+" - "+_vm._s(_vm._f("formatDate")(_vm.to)))])]),_c('div',{staticClass:"right alignedcolumn"},[_c('div',{staticClass:"ui input datepicker-trigger"},[_c('input',{attrs:{"type":"text","id":"datepicker-trigger","placeholder":"Select date"},domProps:{"value":_vm.formatDates(_vm.from, _vm.to)}}),_c('AirbnbStyleDatepicker',{attrs:{"trigger-element-id":'datepicker-trigger',"mode":'range',"fullscreen-mobile":true,"months-to-show":2,"date-one":_vm.from,"date-two":_vm.to,"close-after-select":false},on:{"date-one-selected":(val) => {
                  _vm.from = val;
                },"date-two-selected":(val) => {
                  _vm.to = val;
                },"apply":function($event){return _vm.getSummary()}}})],1)])]),_c('loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataLoading),expression:"dataLoading"}]},[_vm._v(" Please wait...")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.dataLoading),expression:"!dataLoading"}],staticClass:"equal width row",class:{ loading: _vm.dataLoading }},_vm._l((_vm.utilitiesConsumed),function(list,index){return _c('div',{key:index + 1,staticClass:"column"},[_c('div',{staticClass:"ui segment",class:{
              red: list.utility_name == 'Airtime',
              green: list.utility_name != 'Airtime',
            }},[_c('h4',[_vm._v(_vm._s(list.utility_name)+" Consumed")]),_c('h1',[_vm._v(_vm._s(_vm._f("formatNumber")(list.totalAmount)))])])])}),0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"column"},[_c('table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.dataLoading),expression:"!dataLoading"}],staticClass:"ui striped celled table"},[_vm._m(0),_c('tbody',_vm._l((_vm.clientsConsumption),function(list,index){return _c('tr',{key:index + 1},[_c('td',[_vm._v(_vm._s(list.client_name))]),_c('td',{staticClass:"right aligned"},[_c('strong',[_vm._v("KES "+_vm._s(_vm._f("formatNumber")(list.totalAmount)))])])])}),0)])])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Client")]),_c('th',[_vm._v("Total Consumed")])])])
}]

export { render, staticRenderFns }