<template>
    <div>
        <div class="ui top attached tabular menu">
            <a @click="isClicked('uploadContacts')" :class="{active: selectedTab == 'uploadContacts'}" class="item">
                Upload Contacts
            </a>
            <div class="right menu">
                <a href="../../../static/files/contacts_sample.csv" class="item">
                    Download sample file
                </a>
            </div>
        </div>

        <div v-show="selectedTab == 'uploadContacts'" class="ui attached segment">
            <div class="ui grid">
                <div class="center aligned column" id="contacts-upload">
                    Drop files here or  &nbsp;&nbsp;&nbsp;&nbsp;
                    <button class="ui tiny button" id="file-upload-button">add files</button>
                    <div class="fallback"><input type="file"/></div>
                </div>
            </div>
        </div>

        <div class="ui bottom attached secondary segment" id="contacts-container">
            <span v-if="fileToUpload" class="ui label file-uploaded" @click="removeFile()">{{fileToUpload}} <i class="fas fa-times"></i></span>
           <div v-if="quickcontactslist">
            <span  v-for="(contact, key) in quickcontactslist" v-bind:key="key" class="ui label contact" @click="removeContact(key)">{{contact}} <i class="fas fa-times"></i></span>
           </div>
        </div>
    </div>
</template>
<script>
    import store from "../bulk/bulksms-state";
    export default {
        data(){
            return {
                selectedTab: 'uploadContacts',
                quickcontactslist: [],
                fileToUpload: '',
                singlecontact:'',
                contactsUpload: null,
                uploadOptions: null,
                samplefile:'',
            }
        },
        mounted() {
            $(".ui.dropdown").dropdown();
            let vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
        },
        methods: {
            isClicked(tab){
                this.selectedTab = tab;
            },
            populateQuickContactList(){
                if(!this.singlecontact){
                    return false;
                }
                else{
                    if(this.quickcontactslist){
                        this.quickcontactslist.push(this.singlecontact);
                        this.singlecontact='';
                    }
                    else{
                        this.quickcontactslist =this.singlecontact;
                        this.singlecontact='';
                    }
                }
            },

            removeFile() {
                this.fileToUpload = '';
                this.contactsUpload=null;
            },
            removeContact(key){
                this.quickcontactslist.splice( key, 1 );
            },
            cancel(){
                this.quickcontactslist = "";
                this.composedMessage = "";
                this.singlecontact = "";
                this.fileToUpload="";
                this.$router.go("-1");
            }
        },
    }
</script>
