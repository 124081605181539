import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import alertify from 'alertifyjs';
import "../node_modules/choices.js/public/assets/styles/choices.min.css";

import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css';
import * as VueGoogleMaps from "vue2-google-maps";

//import jquery from 'jquery';

import 'semantic-ui-css/semantic.min.css';

import dropzone from '../node_modules/dropzone/dist/dropzone.js';
import '../node_modules/dropzone/dist/dropzone.css';

import "./assets/sass/app.scss";
import "../node_modules/alertifyjs/build/css/alertify.min.css";
import "../node_modules/alertifyjs/build/css/themes/semantic.min.css";
import "../node_modules/vue-form-wizard/dist/vue-form-wizard.min.css";



import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
import format from 'date-fns/format';
import { ColourPicker } from "vue-colour-picker";

Vue.config.productionTip = false
Vue.use(alertify);
Vue.use(AirbnbStyleDatepicker);

alertify.set('notifier','position', 'bottom-center');

Vue.use(VueGoogleMaps, {
    load: {
      key: "AIzaSyCZwb_0Ei1mSVem2J6hWrwRwwXWkSJJa2k",
      libraries: "places" // necessary for places input
    }
});

Vue.use(VueCookies)
new Vue({
    router,
    store,
    render: h => h(App),
    template: '<app />'
}).$mount('#app');