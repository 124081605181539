<template>
    <div class="l-app">
        <div class="l-app-nav">
            <div class="l-app-nav-top">
                <router-link :to="{name: 'bulk-dashboard'}" class="l-app-nav-icon main-icon">
                    <img src="../assets/images/logo-long@2x.png" class="logo">
                </router-link>
                <div class="l-app-nav-label">
                    <div class="main-title"></div>
                    <div class="options">
                        <h4><i class="icon close"></i></h4>
                    </div>
                </div>
            </div>
            <div class="l-app-nav-list">
                <div class="l-app-nav-section" :class="{'active': $route.name == 'admin-dashboard'}">
                    <div class="l-app-nav-icon">
                        <i class="material-icons">home</i>
                    </div>
                    <div class="l-app-nav-label">
                        <router-link :to="{name: 'admin-dashboard'}" class="title">Dashboard</router-link>
                        <ul class="branch">
                            <li :class="{'active': $route.name == 'admin-dashboard'}">
                                <router-link :to="{name: 'admin-dashboard'}">Dashboard</router-link>
                            </li>
                            
                        </ul>
                    </div>
                </div>
                
                
                <div class="l-app-nav-section" v-show="!customMenuChecker && !customMenuMaker" :class="{'active': $route.name == 'payments' || $route.name == 'payments-transactions' || $route.name == 'payments-bulk' || $route.name == 'create-payments-bulk' || $route.name == 'bulk-payments-details' || $route.name == 'payments-bulk-transaction'}">
                    <router-link :to="{name: 'payments'}" class="l-app-nav-icon">
                        <i class="material-icons">payment</i>
                    </router-link>
                    <div class="l-app-nav-label">
                        <router-link :to="{name: 'payments'}" class="title">Payments</router-link>
                        <ul class="branch">
                            <li :class="{'active': $route.name == 'payments'}">
                                <router-link :to="{name: 'payments'}">Dashboard</router-link>
                            </li>
                            <li class="hidden" :class="{'active': $route.name == 'payments-bulk' || $route.name == 'create-payments-bulk' || $route.name == 'bulk-payments-details' || $route.name == 'payments-bulk-transaction'}">
                                <router-link :to="{name: 'payments-bulk'}">Reports</router-link>
                            </li>
                            
                        </ul>
                    </div>
                </div>
                
                <div class="l-app-nav-section" :class="{'active': $route.name == 'shortcodes'}">
                    <router-link :to="{name: 'shortcodes'}" class="l-app-nav-icon">
                        <i class="material-icons">assignment</i>
                    </router-link>
                    <div class="l-app-nav-label">
                        <router-link :to="{name: 'shortcodes'}" class="title">Shortcodes</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="l-app-content">
            <div class="l-app-content-header">
                <div class="ui equal width middle aligned grid">
                    <div class="two wide mobile only column" @click="switchApp()" v-show="$route.name != 'bulk-messages-create' || $route.name != 'bulk-create' || $route.name != 'survey-create' || $route.name != 'survey-view'">
                        <i class="fa fa-bars text-primary"></i>
                    </div>
    
                    <div class="two wide mobile only column" v-show="$route.name == 'bulk-create' || $route.name == 'survey-create' || $route.name == 'bulk-messages-details' || $route.name == 'survey-details' || $route.name == 'ListDetails' || $route.name == 'payments-transactions-details' || $route.name == 'create-bulk-payment' || $route.name == 'survey-view' || $route.name =='utilities-pay-list'" @click="$router.go(-1)">
                        <i class="fa fa-arrow-left text-primary"></i>
                    </div>
    
                    <div class="computer only column">
                        <button class="ui small icon button margin-right-md" @click="$router.go(-1)" v-show="$route.name == 'create-payments-bulk' ||  $route.name == 'agent-transaction-view' || $route.name == 'survey-create' || $route.name == 'survey-details' || $route.name == 'bulk-payments-details' || $route.name == 'survey-view' || $route.name =='utilities-pay-list'"><i class="fa fa-chevron-left"></i></button> <span class="h2">{{$route.meta.name}}</span>
                    </div>
                    <div class="tablet only mobile only column">
                        <h3 class="text-medium">{{$route.meta.name}}</h3>
                    </div>
                    <div class="right aligned column">
                        
                        <div class="ui top right pointing dropdown profile">
                            <i class="icon user circle text-2x"></i>
                            <div class="right floating menu">
                                <div class="header">
                                    <h3 style="min-width: 200px;">{{full_names}}</h3>
                                    <h5>{{client_name }}</h5>
                                </div>
                                <div class="ui divider"></div>
                                <div @click="showEditProfile()" class="icon item"><i class="icon user"></i> Edit Profile</div>
                                <router-link :to="{name: 'account-summary'}" class="icon item"><i class="icon cog"></i> Account</router-link>
                                <div v-show="showAdminSwitch" class="ui divider"></div>
                                <router-link v-show="showAdminSwitch" :to="{name: 'bulk-dashboard'}" class="icon item"><i class="icon green users"></i>Switch to Admin</router-link>
                                <router-link v-show="showSalesSwitch" :to="{name: 'agent-dashboard'}" class="icon item"><i class="icon user"></i> Agent Account</router-link>
                                <div class="ui divider"></div>
                                <div v-on:click="logout()" class="icon item"><i class="icon red power"></i> Logout</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="l-app-content-body">
                <div class="l-app-notice">
                    <div class="notice success">
                        <div class="l">Your sender ID has been created successfully</div>
                        <div class="r"><i class="close icon"></i></div>
                    </div>
                    <div class="notice fail">
                        <div class="l">Fail notice</div>
                        <div class="r"><i class="close icon"></i></div>
                    </div>
                    <div class="notice">
                        <div class="l">Info</div>
                        <div class="r"><i class="close icon"></i></div>
                    </div>
                </div>
                <div class="l-app-main">
                    <div class="l-app-main-header account" v-if="this.$route.name == 'account-summary' || this.$route.name == 'account-users' || this.$route.name == 'account-transactions' || this.$route.name == 'account-invoices'">
                        <div class="padding-xs"></div>
                        <h1 class="text-white">{{client_name}}</h1>
                        <div class="ui mobile only grid hidden">
                            <div class="column">
                                <h4 class="padding-bottom-sm"></h4>
                                <div class="ui horizontal text-white list">
                                    <div class="item"><i class="icon user"></i> {{full_names}}</div>
                                    <div class="item"><i class="icon phone"></i> {{msisdn}}</div>
                                    <div class="item"></div>
                                    <div class="item"><a href="#" class="text-white text-underline-w" @click="showEditModal()">Edit</a></div>
                                </div>
                                <div class="padding-top-sm"></div>
                            </div>
                        </div>
                        <div class="padding-sm"></div>
                        <ul class="pills">
                            <li :class="{active: this.$route.name == 'account-summary'}">
                                <router-link :to="{name: 'account-summary'}">Summary</router-link>
                            </li>
                            <li :class="{active: this.$route.name == 'account-users'}">
                                <router-link :to="{name: 'account-users'}">Users</router-link>
                            </li>
                            <li :class="{active: this.$route.name == 'account-transactions'}">
                                <router-link :to="{name: 'account-transactions'}">Transactions</router-link>
                            </li>
                            <li :class="{active: this.$route.name == 'account-invoices'}">
                                <router-link :to="{name: 'account-invoices'}">Invoices</router-link>
                            </li>
                        </ul>
                    </div>
                    <router-view></router-view>
                </div>
            </div>
        </div>
        <div class="l-app-footer mobile">
            <div class="l-app-footer-fab" v-show="this.$route.name != 'inbox-message-details' && (this.$route.name != 'whatsApp-home' || this.$route.name != 'whatsApp-dashboard' || this.$route.name != 'whatsApp-message' || this.$route.name != 'bulk-whatsApp' || this.$route.name == 'whatsApp-message-details')">
                <router-link v-show="$route.name != 'bulk-create'" :to="{name: 'bulk-create'}" class="fab"><i class="material-icons">message</i></router-link>
            </div>


            <div class="l-app-footer-fab" v-show="this.$route.name == 'whatsApp-home' || this.$route.name == 'whatsApp-dashboard' || this.$route.name == 'whatsApp-message'|| this.$route.name == 'bulk-whatsApp' || this.$route.name == 'whatsApp-message-details'">
                <router-link v-show="$route.name != 'bulk-create'" :to="{name: 'whatsapp-create'}" class="fab" style="background: #25D366;"><img src="@/assets/images/whatsapp-logo.svg" width="28"/></router-link>
            </div>



            <ul class="l-app-footer-nav hidden">
                <!-- Bulk -->
                <li v-show="this.$route.name == 'bulk-dashboard' || this.$route.name == 'bulk-messages' || this.$route.name == 'bulk-create' || this.$route.name == 'bulk-senderids' || this.$route.name == 'contacts' || this.$route.name == 'account-summary'" :class="{active: this.$route.name == 'bulk-dashboard'}">
                    <router-link :to="{name: 'bulk-dashboard'}">
                        <span class="nav-icon"><i class="fas fa-columns"></i></span>
                        <span class="nav-label">Dashboard</span>
                    </router-link>
                </li>
                <li v-show="this.$route.name == 'bulk-dashboard' || this.$route.name == 'bulk-messages' || this.$route.name == 'bulk-create' || this.$route.name == 'bulk-senderids' || this.$route.name == 'contacts' || this.$route.name == 'account-summary'" :class="{active: this.$route.name == 'bulk-messages'}">
                    <router-link :to="{name: 'bulk-messages'}">
                        <span class="nav-icon"><i class="fa fa-envelope"></i></span>
                        <span class="nav-label">Messages</span>
                    </router-link>
                </li>
    
                <!-- Payments -->
                <li v-show="this.$route.name == 'payments' || this.$route.name == 'payments-transactions'" :class="{active: this.$route.name == 'payments'}">
                    <router-link :to="{name: 'payments'}">
                        <span class="nav-icon"><i class="fa fa-money-bill-alt"></i></span>
                        <span class="nav-label">Dashboard</span>
                    </router-link>
                </li>
                <li v-show="this.$route.name == 'payments' || this.$route.name == 'payments-transactions'" :class="{active: this.$route.name == 'payments-transactions'}">
                    <router-link :to="{name: 'payments-transactions'}">
                        <span class="nav-icon"><i class="fa fa-receipt"></i></span>
                        <span class="nav-label">Transactions</span>
                    </router-link>
                </li>
                <li :class="{active: this.$route.name == 'bulk-create'}" class="create">
                    <router-link :to="{name: 'bulk-create'}">
                        <span class="nav-icon"><i class="fa fa-paper-plane"></i></span>
                        <span class="nav-label">Send</span>
                    </router-link>
                </li>
                <li :class="{active: this.$route.name == 'contacts'}">
                    <router-link :to="{name: 'contacts'}">
                        <span class="nav-icon"><i class="fa fa-address-card"></i></span>
                        <span class="nav-label">Contacts</span>
                    </router-link>
                </li>
                <li :class="{active: this.$route.name == 'account-summary'}">
                    <router-link :to="{name: 'account-summary'}">
                        <span class="nav-icon"><i class="fa fa-user-circle"></i></span>
                        <span class="nav-label">Account</span>
                    </router-link>
                </li>
            </ul>
        </div>
    
        <div class="l-app-create" v-if="showCreateWhatsApp">
            <div class="l-app-create-title bg-primary">
                <div class="ui middle aligned equal width grid">
                    <div class="column" @click="hideCreateWhatsAppContent = false">
                        <h3 class="text-medium">Send WhatsApp</h3>
                    </div>
                    <div class="right aligned column">
                        <i class="chevron down white icon" v-show="hideCreateMessageContent == false" @click="hideCreateMessageContent = true"></i>
                        <i class="chevron up white icon" v-show="hideCreateMessageContent == true" @click="hideCreateMessageContent = false"></i>
                        <i class="close white icon" @click="composerWhatsAppState(false)"></i>
                    </div>
                </div>
            </div>
            <div class="l-app-create-content" v-show="hideCreateWhatsAppContent == false">
                <CreateWhatsAppMessage />
            </div>
        </div>

        <div class="l-app-create" v-if="showCreateMessage">
            <div class="l-app-create-title bg-secondary">
                <div class="ui middle aligned equal width grid">
                    <div class="column" @click="hideCreateMessageContent = false">
                        <h3 class="text-medium">Send Message</h3>
                    </div>
                    <div class="right aligned column">
                        <i class="chevron down white icon" v-show="hideCreateMessageContent == false" @click="hideCreateMessageContent = true"></i>
                        <i class="chevron up white icon" v-show="hideCreateMessageContent == true" @click="hideCreateMessageContent = false"></i>
                        <i class="close white icon" @click="showCreateMessageFn(false)"></i>
                    </div>
                </div>
            </div>
            <div class="l-app-create-content" v-show="hideCreateMessageContent == false">
                <CreateMessage />
            </div>
        </div>

        <div class="l-app-create" v-if="showCreateBlast">
            <div class="l-app-create-title bg-primary">
                <div class="ui middle aligned equal width grid">
                    <div class="column" @click="hideCreateBlastMessageContent = false">
                        <h3 class="text-medium">Send Blast</h3>
                    </div>
                    <div class="right aligned column">
                        <i class="chevron down white icon" v-show="hideCreateBlastMessageContent == false" @click="hideCreateBlastMessageContent = true"></i>
                        <i class="chevron up white icon" v-show="hideCreateBlastMessageContent == true" @click="hideCreateBlastMessageContent = false"></i>
                        <i class="close white icon" @click="showCreateBlastMessageFn(false)"></i>
                    </div>
                </div>
            </div>
            <div class="l-app-create-content" v-show="hideCreateBlastMessageContent == false">
                <CreateBlastMessage />
            </div>
        </div>

        <div class="l-app-create" v-if="showCreateSurveyMessage">
            <div class="l-app-create-title bg-primary">
                <div class="ui middle aligned equal width grid">
                    <div class="column" @click="hideCreateSurveyMessageContent = false">
                        <h3 class="text-medium">Send Survey Message</h3>
                    </div>
                    <div class="right aligned column">
                        <i class="chevron down white icon" v-show="hideCreateSurveyMessageContent == false" @click="hideCreateSurveyMessageContent = true"></i>
                        <i class="chevron up white icon" v-show="hideCreateSurveyMessageContent == true" @click="hideCreateSurveyMessageContent = false"></i>
                        <i class="close white icon" @click="showCreateSurveyMessageFn(false)"></i>
                    </div>
                </div>
            </div>
            <div class="l-app-create-content" v-show="hideCreateSurveyMessageContent == false">
                <CreateSurveyMessage />
            </div>
        </div>
        <DepositModal/>
        <EditProfile />
    </div>
</template>

<style scoped>
    .ui.dropdown .header {
        text-transform: capitalize !important;
    }
    
    .ui.dropdown .header p {
        font-weight: 400 !important;
    }
</style>

<script>
    import bulkstore from "@/bulk/bulksms-state";
    import store from "@/store";
    import DepositModal from "@/modals/deposit";
    import CreateMessage from "@/components/create-message";
    import CreateSurveyMessage from "@/components/create-survey-message";
    import CreateWhatsAppMessage from "@/components/create-whatsApp";
    import CreateBlastMessage from "@/components/create-blast-message";
    import EditProfile from "@/modals/account_edit";
    import alertify from "alertifyjs";
    export default {
        data() {
            return {
                walletBalance: null,
                full_names: null,
                showApps: 0,
                showCreateMessage: null,
                showCreateWhatsApp: false,
                showCreateBlast: false,
                hideCreateMessageContent: false,
                hideCreateWhatsAppContent: false,
                hideCreateSurveyMessageContent:false,
                hideCreateBlastMessageContent:false,
                showCreateSurveyMessage: false,
                account_id: "",
                client_name: "",
                msisdn: "",
                profileDetails:[],
                account_status:0,
                permission_acl:"",
                permission:[],
                showAdminSwitch:false,
                showSalesSwitch:false,
                clientID:"",
                customMenuMaker:false,
                customMenuChecker:false
            };
        },
        watch: {
            showCreateMessage() {
                let vm = this;
                vm.checkCookies();
                return store.state.showCreateMessageContainer;
            }
        },
        components: {
            DepositModal,
            CreateMessage,
            EditProfile,
            CreateSurveyMessage,
            CreateWhatsAppMessage,
            CreateBlastMessage
        },
        created() {
            let vm = this;
            vm.showCreateMessage = store.state.showCreateMessageContainer;
            $.post({
                url: store.state.rootURLBulkAPI + 'v3/account/view',
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key"),
                }),
                success: function(response, status, jQxhr) {
                    var bonus = response.data.data.bonus;
                    bonus = bonus.replace(/\D/g, '');
                    vm.walletBalance = response.data.data.balance;
                    vm.full_names = response.data.data.account_name;
                    vm.msisdn = response.data.data.mobile_number;
                    vm.email_address = response.data.data.email_address;
                    vm.depositDataList = response.data.data.transaction_data;
                    vm.client_name = response.data.data.client_name;
                    vm.account_id = response.data.data.account_id;
                    vm.account_status =  response.data.data.account_status;
                    if(vm.account_status == 2){
                        vm.$router.push({
                                name: 'set-password'
                        });
                    }
                    vm.permission_acl = response.data.data.permission_acl;
                    vm.permission = vm.permission_acl.split(":")
                    if(vm.permission.includes("14")){
                        // super admin
                        vm.showAdminSwitch = true;
                    }
                    if(vm.permission.includes("34") || vm.permission.includes("33")){
                        //sales agent
                        vm.showSalesSwitch = true;

                    }

                    store.commit("setWalletBalance", response.data.data.balance);
                    store.commit("setAccountDetails", response.data.data);
    
                },
                error: function(jQxhr, status, error) {
                   // alertify.set('notifier', 'position', 'top-right');
                   // alertify.error(jQxhr.responseJSON.statusDescription);
                }
            });
        },
       

        mounted() {
            let vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            
            if(!vm.showAdminSwitch){
                vm.$router.push({
                    name: 'bulk-dashboard'
                });
            } 
            if(vm.$cookies.get("auth_roleId") != 0){
                if(vm.$cookies.get("auth_roleId") ==1){
                    vm.customMenuMaker = true;
                }
                if(vm.$cookies.get("auth_roleId") ==2){
                    vm.customMenuChecker = true;
                }
            }
            $(".l-app-content-body").css("top", $(".l-app-content-header").outerHeight());
            $(".ui.dropdown").dropdown();
    
            if ($(window).width() < 480) {
                $('.l-app-nav').addClass("mobile");
            } else {
                $('.l-app-nav').removeClass("mobile");
            }
            $(".l-app-nav-label").click(function() {
                $('.l-app-nav.mobile').css({
                    display: "none"
                });
                $('.l-app-content').css({
                    opacity: "1"
                });
            });
            
            this.profileView();
            //this.fetchData();
        },
        methods: {
            showEditProfile() {
                $(".ui.modal#modal-edit-account").modal({
                    centered: false
                }).modal('show');
            },
            showDeposit() {
                $(".ui.modal#modal-deposit")
                    .modal({
                        centered: false
                    })
                    .modal("show");
            },
            switchApp() {
                $('.l-app-nav').show(300);
                $('.l-app-content').css({
                    opacity: "0.2"
                });
            },
            fetchData() {
                var vm = this;

                $.post({
                    url: store.state.rootURLBulkAPI + 'v3/senderId/list',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        type_id: 3
                    }),
                    success: function(response, status, jQxhr) {
                        var dataList = response.data.data[0];
                       store.commit("setSenderIDSelect", dataList);
                    },
                    error: function(jQxhr, status, error) {
                    }
                });

            },
            composerWhatsAppState(v, message) {
                store.commit("showCreateWhatsApp", v);
                store.commit("composerWhatsAppState", message);
                this.showCreateWhatsApp =  store.state.showWhatsAppBulkContaioner;

                //hideCreateMessageContent = false;
            },
            showCreateMessageFn(v, message) {
                store.commit("showCreateMessage", v);
                store.commit("composeMessageState", message);
                this.showCreateMessage = store.state.showCreateMessageContainer;

                //hideCreateMessageContent = false;
            },
            showCreateSurveyMessageFn(v, message) {
                store.commit("showCreateSurveyMessage", v);
                store.commit("composeSurveyMessageState", message);
                this.showCreateSurveyMessage = store.state.showCreateSurveyMessageContainer;

                //hideCreateMessageContent = false;
            },
            showCreateBlastMessageFn(v, message) {
                store.commit("showCreateBlastMessage", v);
                store.commit("composerBlastState", message);
                this.showCreateBlast = store.state.showCreateBlastMessageContainer;

                //hideCreateMessageContent = false;
            },
            logout() {
                let vm = this;
                vm.$cookies.remove("key");
                vm.$cookies.remove("username");
                vm.$cookies.remove("iscalled");
                store.state.user = null;
                bulkstore.state.selectUser = null;
                vm.$forceUpdate();
                // vm.$router.go();
                // window.location.href = "https://vaspro.co";
                vm.$router.push({
                    path: '/'
                });
            },
            checkCookies(){
                let vm = this;
                if(!vm.$cookies.get("key")){
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.error("Your Session has Expired. Kindly Login to proceed");
                    vm.$router.push({
                        name: 'login'
                    });
                }
            },
            profileView() {
            let vm = this;
            $.post({
                url: store.state.rootURLBulkAPI + 'v2/users/profile',
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key"),
                }),
                success: function(response, status, jQxhr) {
                    vm.clientID =  response.data.data.client_id;
                    store.commit("setSelectProfileDetails", response.data.data);
                    
                },
                error: function(jQxhr, status, error) {
                   // alertify.set('notifier', 'position', 'top-right');
                   // alertify.error(jQxhr.responseJSON.statusDescription);
                }
            });
        },
        }
    };

</script>
