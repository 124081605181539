import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const bulksmsStore = new Vuex.Store({
    state: {

        bulksmsUrl:  "https://sms.vaspro.co/v3/", //"http://localhost/vaspro-api/v2/",
        rootURLBulkAPI :  "https://sms.vaspro.co/v3/", //"http://localhost/vaspro-api/",
        selectedList:"",
        contactList:[],
        senderIds:[{"short_code":"VasPro"}],
        shortcodes:[],
        selectUser:{},
        inboxMessage:'',
        selectedCampaign: "",
        summaryData:0,
        summaryDataCost:0,
        selectedContact:"",
        customs:"",
        selectedCampaignData:[],
        selectedMessageKey:"",
        selectKeywordInbox:[]

    },
    mutations: {
        setSelectedList(state,selectedList){
            //console.log("state am here"+JSON.stringify(selectedList));
            state.selectedList=selectedList;
        },
        setSelectedCampaign(state,data){
            state.selectedCampaign = data
        },
        setContactList(state,contactList){
            state.contactList=contactList;
        },
        setSenderIds(state,senderIds){
           // console.log(senderIds.length+" set sender id "+JSON.stringify(senderIds));
            if(senderIds.length>0){
                state.senderIds=senderIds;
            }
        },
        setShortCode(state,shortcodes){
            state.shortcodes=shortcodes;
        },
        setUser(state,user){
            state.selectUser=user;
        },
        setInboxMessage(state,inboxMessage){
            state.inboxMessage = inboxMessage;
        },
        setSummaryBulkData(state,data){
            state.summaryData = data;
        },
        setSummaryDataCost(state,data){
            state.summaryDataCost = data;
        },
        setSelectedContact(state,data){
            state.selectedContact = data;
        },
        setCustomsContact(state,data){
            state.customs = data;
        },
        setKeywordInbox(state,data){
            state.selectKeywordInbox = data

        }


    },

});
export  default  bulksmsStore;
