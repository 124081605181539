<template>
  <div class="ui tiny modal" id="transfer-funds">
    <i class="close icon"></i>
    <div class="header"><h2>Transfer funds to</h2></div>
    <loader v-show="loading"></loader>
    <div class="ui negative message" v-show="isError">
      {{ errorMessage }}
    </div>
    <div class="scrolling content">
      <div class="ui secondary segment">
        <h3 class="text-medium">{{ clientData }}</h3>
        <span>Account ID: {{ account_number }}</span>
        <div class="ui divider"></div>
        <div class="ui equal width grid">
          <div class="column">
            <span class="text-medium">Client Balance:</span> KES {{ balance }}
          </div>
          <div class="column">
            <span class="text-medium">Rate:</span> KES {{ smsRate }}
          </div>
        </div>
      </div>
      <div class="ui form">
        <div class="field">
          <label class="padding-bottom-sm"><strong>SMS Unit to transfer</strong></label>
          <div class="ui large input">
            <input
              type="number"
              placeholder="0.00"
              v-model="amount"
              @keyup="amountUpdate()"
            />
          </div>
          <div class="ui yellow message" v-show="isBalance">
            You do not have enough cash to perform this transfer. Your account balance is
            {{ myBalance }}
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <button class="ui cancel button">Cancel</button>
      <button class="ui button bg-primary" id="transfer" @click="transferFunds()">
        Transfer Funds
      </button>
    </div>
  </div>
</template>
<script>
import loader from "@/components/loader";
import store from "@/store";
import alertify from "alertifyjs";
export default {
  components: {
    loader,
  },
  data() {
    return {
      // credits: store.state.credits,
      email_address: "",
      mobile_no: "",
      client_name: "",
      balance: 0,
      smsRate: 0.8,
      account_number: "",
      loading: false,
      isError: false,
      errorMessage: "",
      myBalance: 0,
      isBalance: false,
      amount: 0,
      reseller_accountId: "",
    };
  },
  computed: {
    clientData() {
      var vm = this;
      console.log("Select Reseller Account" + store.state.walletBalance);
      vm.client_name = store.state.selectResellerClient.client_name;
      vm.account_number = store.state.selectResellerClient.account_number;
      vm.smsRate = store.state.selectResellerClient.unit_cost;
      vm.balance = store.state.selectResellerClient.balance;
      vm.myBalance = store.state.walletBalance;
      vm.reseller_accountId = store.state.selectResellerClient.reseller_accountId;
      return vm.client_name;
    },
  },
  methods: {
    amountUpdate() {
      let vm = this;
      console.log(
        "am here test " +
          parseInt(vm.myBalance.replace(/,/g, "").substring(4), 10) +
          " amount " +
          vm.amount
      );

      var accounBalance = parseInt(vm.myBalance.replace(/,/g, "").substring(4), 10);
      if (vm.amount * vm.smsRate > accounBalance) {
        vm.isBalance = true;
      } else {
        vm.isBalance = false;
      }
    },
    transferFunds() {
      $("#transfer").html('<i class="fas fa-circle-notch fa-spin"></i> Please wait...');
      let vm = this;
      vm.loading = true;
      $.post({
        url: store.state.rootUrl + "reseller/funds/transfer",
        type: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key"),
          reseller_accountId: vm.reseller_accountId,
          amount: vm.amount,
        }),
        success: function (response, status, jQxhr) {
          vm.loading = false;
          $("#transfer").html("Transfer Funds");
          if (response.data.code != 200) {
            vm.isError = true;
            vm.errorMessage = response.data.message;
            alertify.error(response.data.message);
          } else {
            vm.$parent.fetchAllClients();
            alertify.success(response.data.message);
            vm.hideModal();
          }
        },
        error: function (jQxhr, status, error) {
          vm.loading = false;
          $("#transfer").html("Transfer Funds");
          vm.isError = true;
          vm.errorMessage = jQxhr.responseJSON.statusDescription;
          alertify.error(jQxhr.responseJSON.statusDescription);
        },
      });
    },
    hideModal() {
      $(".ui.tiny.modal#transfer-funds").modal("hide");
    },
  },
};
</script>
