<template>
    <!-- Questions tab-->
    <div class="l-app-main-segment-container bg-white">
        <div class="l-app-main-segment-container-2">
            <div class="l-questions">
                <div class="question" v-for="(question, index) in questionList" :key="question.id">
                    <div class="question-container">
                        <div class="question-title">
                            <div class="num">
                                <label class="ui large blue label">{{index+1}}</label>
                            </div>
                            <div class="text">
                                <textarea class="question-box" v-if="question.question_typeID != 1" rows="1" v-model="question.question" placeholder="Type your question here" ></textarea>
                                <textarea id="question-box" v-if="question.question_typeID == 1" rows="3" v-model="question.question" placeholder="Type your question here" ></textarea>
                                 <div class="option">
                                    <div class="select hidden" @change="updateType()">
                                        <div class="ui small dropdown">
                                            <input type="hidden" v-model="choiceOption">
                                                <div class="text"  v-if="question.question_typeID == 1"><i class="orange icon edit"></i> Text <i class="icon dropdown"></i></div>
                                                <div class="text"  v-if="question.question_typeID == 2"><i class="icon blue list"></i> Choices <i class="icon dropdown"></i></div>
                                                <div class="text"  v-if="question.question_typeID == 3"><i class="icon teal adjust"></i> Yes/No <i class="icon dropdown"></i></div>
                                                <div class="text"  v-if="question.question_typeID == 4"><i class="icon star"></i> Rating <i class="icon dropdown"></i></div>
                                                <div class="text"  v-if="question.question_typeID == 5"><i class="icon check"></i> Linear <i class="icon dropdown"></i></div>
                                            <div class="menu">
                                               <div :class="{'item':true, 'active':(question.question_typeID == 1)}" value="1" ><i class="orange icon edit"></i> Text</div>
                                                <div :class="{'item':true, 'active':(question.question_typeID == 2)}" value="2" ><i class="icon blue list"></i> Choices</div>
                                                <div :class="{'item':true, 'active':(question.question_typeID == 3)}" value="3" selected><i class="icon teal adjust"></i> Yes/No</div>
                                                <div :class="{'item':true, 'active':(question.question_typeID == 4)}" value="4"><i class="icon star"></i> Rating</div>
                                                <div :class="{'item':true, 'active':(question.question_typeID == 5)}" value="5"><i class="icon check"></i> Linear</div>
                                            </div>
                                        </div>
                                    </div>
                                    <questionCreate :options="question.options" :questionID="question.id" :questionTypeId="question.question_typeID" :indexN="index" :question="question.question"></questionCreate>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="l-questions-add" v-if="status !=1">
                    <div class="ui pointing dropdown l-questions-add-btn" @click="addQuestion(2)">
                        <i class="icon plus"></i> Add a question
                    </div>
                </div>
                
                <loader v-show="loading"></loader>
            </div>
        </div>
        <div class="l-app-main-segment-container-1">
            <div class="padding-lg">
                <div class="ui grid">
                    <div class="computer only tablet only row">
                        <div class="column">
                            <h3>Preview</h3>
                        </div>
                    </div>
                    <div class="computer only tablet only row">
                        <div class="column">
                            <div class="l-app-question">
                                <div class="q" v-for="(question, index) in questionList" :key="question.id">
                                    <div class="q-no">
                                        <span class="ui orange label" v-if="question.question_typeID == 1">{{index+1}}</span>
                                        <span class="ui blue label" v-if="question.question_typeID == 2">{{index+1}}</span>
                                        <span class="ui teal label" v-if="question.question_typeID == 3">{{index+1}}</span>
                                        <span class="ui black label" v-if="question.question_typeID == 4">{{index+1}}</span>
                                        <span class="ui green label" v-if="question.question_typeID == 5">{{index+1}}</span>
                                    </div>
                                    <div class="q-info">
                                        <div class="q-title">{{question.question}}</div>
                                        <div class="q-option text" v-if="question.question_typeID == 1">
                                            <div class="option">
                                                <span class="t">T</span>
                                                <span class="w">Open ended input</span>
                                            </div>
                                        </div>
                                        <div class="q-option options" v-if="question.question_typeID == 2 || question.question_typeID == 5">
                                            <div class="option" v-for="(option, index) in question.options"  :key="option.id+index">
                                                <span class="t">{{index + 1}}</span>
                                                <span class="w">{{option.value}}</span>
                                            </div>
                                        </div>
                                        <div class="q-option bool" v-if="question.question_typeID == 3">
                                            <div class="option">
                                                <span class="t">Y</span>
                                                <span class="w">Yes</span>
                                            </div>
                                            <div class="option">
                                                <span class="t">N</span>
                                                <span class="w">No</span>
                                            </div>
                                        </div>
                                        <div class="q-option rate" v-if="question.question_typeID == 4">
                                            <div class="option">
                                                <span class="t">Min rating</span>
                                                <span class="w">1</span>
                                            </div>
                                            <div class="option">
                                                <span class="t">Max rating</span>
                                                <span class="w">10</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="right aligned column">
                            <button class="ui very basic left floated red button" @click="surveyDiscard(survey,'-4')" v-if="surveyStatusN"> Discard Survey </button>
                             
                            <button class="ui bg-primary button" @click="$parent.selectedTab = 'settings'" v-if="surveyStatusN">Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <surveyStatus />
        <surveyQuestionnaireStatus />
    </div>
</template>

<script>
    import surveyStatus from "@/modals/surveyStatus";
    import surveyQuestionnaireStatus from "@/modals/questionnaireStatus";
    import questionCreate from "./questionCreate";
    import store from "@/store";
    import alertify from 'alertifyjs';
    import loader from "@/components/loader";
    import format from 'date-fns/format';
    import moment from "moment";
    import _ from "lodash"

    export default {
        data() {
            return {
                questionnaireID: "",
                totalQuestion: 0,
                offset: 0,
                limit: 100,
                loading: false,
                questionList: [],
                questions:[],
                choiceOption:"",
                questionTypes:"",
                currentInputIndex:0,
                survey:[],
                status:"",
                surveyStatusN:false,
                 app_id:"",
                questionnnaireType:""
            }
        },
        components: {
            loader,
            questionCreate,
            surveyStatus,
            surveyQuestionnaireStatus
        },
        computed: {

        },
        mounted() {
            let vm = this;
            //console.log(JSON.stringify(vm.$route.name))

            if (vm.$route.name == "survey-create-new" || vm.$route.name == 'survey-view'){
                
                vm.getCampaignRecords(vm.$route.params.id);
                vm.survey= store.state.selectSurvey;
                vm.questionnaire= store.state.selectQuestionnaire
                vm.app_id = store.state.selectSurvey.app_id;
                vm.questionnnaireType = store.state.selectSurvey.questionnnaireType;
                vm.surveyStatusN = true
            } 
            else if(vm.$route.name == "questionnaire-add"){
                vm.questionnaireID = vm.$route.params.id;
                vm.getQuestion();
                vm.questionType();
                vm.surveyStatusN = false;
                //console.log("Questionnaire Id "+vm.$route.params.id);
            }
            
            vm.status = store.state.selectSurvey.status;
            if(vm.survey ==null || vm.survey==""){
             vm.getCampaignRecords(vm.$route.params.id);
           }
             else{
                vm.questionnaireID = vm.survey.questionnare_id;
                vm.getQuestion();
                vm.questionType();
            } 
            
        },
        methods: {
            getQuestion() {
                let vm = this;
                console.log("parent get question total:" +vm.totalQuestion+" offest"+vm.offse+ " questionnaire id"+vm.questionnaireID);
                if(vm.questionnaireID == ""){
                    this.$router.go() 
                }
                if (vm.totalQuestion == 0 || vm.totalQuestion >= ((vm.offset - 1) * vm.limit)) {
                    vm.offset++;
                    vm.loading = true;
                    $.post({
                        url: store.state.rootURLBulkAPI + "v3/survey/view/question",
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: vm.$cookies.get("key"),
                            questionnare_id: vm.questionnaireID,
                            offset: vm.offset,
                            limit: vm.limit,
                            offset: vm.offset,
                            appID: vm.app_id,
                            questionnnaireType: vm.questionnnaireType
                        }),
                        success: function(response, status, jQxhr) {
                            vm.loading = false;
                            var resp = response.data.data;
                            if (response.data.code != 200) {
                                vm.offset = 0;
                               // alertify.set('notifier', 'position', 'top-right');
                               // alertify.error(response.data.message);
                                //vm.totalMessage = 0;
    
                            } else {
                                vm.totalQuestion = response.data.total;
                                var quest = [];
                                for (var i = 0; i < resp.length; i++) {
                                    // console.log(" loop " + i + " " + JSON.stringify(resp[i]));
                                    resp[i].created_at = format(resp[i].created_at, "DD MMM YYYY");
                                    var options = resp[i].options;
                                    if(options != null){
                                        var optionArry = options.split('||');
                                        var n = 0;
                                        var optionList = [];
                                        optionArry.forEach(element => {
                                            element = element.split('|');
                                            var list = {
                                                "id": element[1],
                                                "value": element[0],
                                                "valueQuestionID": element[2],
                                                "numbering": parseInt(element[3], 10)
                                            }
                                            
                                            optionList.push(list);
                                        });
                                        var orderedList = _.orderBy(optionList, 'numbering');
                                        //console.log(orderedList);
                                        resp[i].options = orderedList;
                                    }
                                    
                                    vm.questionList.push(resp[i]);
                                }
                                store.commit("setSelectQuestion", vm.questionList);
                                
                                setTimeout(function() {
                                    $(".ui.small.dropdown").dropdown();
                                    $(".question-box").on('input', function() {
                                        var scroll_height = $(".question-box").get(0).scrollHeight;
                                        $(".question-box").css('height', scroll_height + 'px');
                                    });
                                }, 100)
                            }
                        },
                        error: function(jQxhr, status, error) {
                            vm.loading = false;
                            vm.offset = 0;
                            alertify.set('notifier', 'position', 'top-right');
                            //alertify.error(jQxhr.responseJSON.statusDescription);
                        }
                    });
                }
            },
            questionType(){
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/question/type",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                    }),
                    success: function(response, status, jQxhr) {
                        vm.questionTypes = response.data.data;
                    },
                    error: function(jQxhr, status, error) {
                        alertify.set('notifier', 'position', 'top-right');
                        //alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            },
            addQuestion(type){
                
                this.questionList.push({id: "N"+this.currentInputIndex, question: "",choiceOption: this.choiceOption});
                this.currentInputIndex++;
            },
            surveyDiscard(survey, status) {
                
                store.commit("setSurveySelect", survey);
                store.commit("setSurveyStatus",status);
                store.commit("setSurveyID",this.survey.app_id);
                
                $('.ui.modal#modal-Survey_status').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            QuestionnaireDiscard(){
                $('.ui.modal#modal-Questionnaire-status').modal({
                    centered: false,
                    closable: true
                }).modal("show");
                store.commit("setSurveyQuestionnaireID",this.questionnaireID);

            },

            getCampaignRecords(appID){
                let vm = this
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/query",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        app_id:appID
                    }),
                    success: function(response, status, jQxhr) {
                        
                        var survey = response.data.data;
                        vm.surveyName = survey[0].campaign_name;
                        vm.app_id = appID;
                        vm.questionnnaireType = survey[0].questionnnaireType;
                        store.commit("setSurveySelect", survey[0]);
                        vm.survey= store.state.selectSurvey
                        vm.questionnaireID = vm.survey.questionnare_id;
                        vm.getQuestion();
                        vm.questionType();
                        setTimeout(function(){
                            $(".l-app-main-segment").css({
                                top: $(".l-app-main-top").height()
                            });
                        }, 1000);

                    },
                    error: function(jQxhr, status, error) {
                        alertify.set('notifier', 'position', 'top-right');
                       // alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });

            },
        },
        filters: {
            formatDate: function(value) {
                if (!value) return ''
                return moment(value).fromNow()
            },
            orderOption: function(value){
                if (!value) return '';
                return _.orderBy(value, 'numbering', 'desc');
            }
        }
    
    }
</script>
