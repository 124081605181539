<template>
    <div class="l-app-main bg-primary-bg">
        <div class="l-app-dashboard bg-primary-lighter" v-show="!inboxzero">
            <div class="ui equal width relaxed grid padded">
                <div class="middle aligned row">
                    <div class="column">
                        <h3>Summary</h3>
                        <div class="">12 May 2021 - 12 June 2021</div>
                    </div>
                    <div class="right aligned column">
                        <div class="ui dropdown button">
                            <div class="default text">KES</div>
                            <i class="icon dropdown"></i>
                            <div class="menu">
                                <a href="#" class="item">KES</a>
                                <a href="#" class="item">USD</a>
                            </div>
                        </div>
                        <button class="margin-left-md ui bg-primary right labelled button" @click="goToCreate()"><i class="icon plus"></i> Add payment</button>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <div class="ui bg-primary segment">
                            <h4>Amount disbursed</h4>
                            <h2>KES 123,900.00</h2>
                        </div>
                    </div>
                    <div class="column">
                        <div class="ui bg-primary-dark text-white segment">
                            <h4>Transactions</h4>
                            <h2>129</h2>
                        </div>
                    </div>
                    <div class="column">
                        <div class="ui bg-grey-dark text-white segment">
                            <h4>Contacts</h4>
                            <h2>34</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <table class="ui striped celled selectable hovered table">
                            <thead>
                                <tr>
                                    <th class="collapsing"></th>
                                    <th class="collapsing">Status</th>
                                    <th>Transaction ID</th>
                                    <th>Date sent</th>
                                    <th class="center aligned">Contacts</th>
                                    <th>Payment mode</th>
                                    <th>Delivery</th>
                                    <th class="right aligned">Cost</th>
                                    <th class="collapsing"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="cursor: pointer" @click="goToDetails()">
                                    <td>1</td>
                                    <td class="collapsing">
                                        <div class="ui yellow label">Draft</div>
                                    </td>
                                    <td>
                                        <h4 class="text-medium">#127899</h4>
                                        <small>Created 23 April 2021</small>
                                    </td>
                                    <td>23 April 2021</td>
                                    <td class="center aligned">23</td>
                                    <td>Bank deposit</td>
                                    <td>
                                        <div>Mobile Money</div>
                                        <small></small>
                                    </td>
                                    <td class="right aligned">KES 112,900</td>
                                    <td>
                                        <div class="ui small icon dropdown button">
                                            <i class="icon dropdown"></i>
                                            <div class="left menu">
                                                <a href="#" class="blue item" @click="transferFunds">Transfer funds</a>
                                                <a href="#" class="item">Add Paybill/Till</a>
                                                <a href="#" class="item">Add Sender ID</a>
                                                <div class="divider"></div>
                                                <a href="#" class="item">Edit client</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td class="collapsing">
                                        <div class="ui red label">Failed payment</div>
                                    </td>
                                    <td>
                                        <h4 class="text-medium">#127899</h4>
                                        <small>Created 23 April 2021</small>
                                    </td>
                                    <td>23 April 2021</td>
                                    <td class="center aligned">3</td>
                                    <td>Card</td>
                                    <td>
                                        <div>Multiple</div>
                                        <small>Mobile Money, Bank</small>
                                    </td>
                                    <td class="right aligned">KES 12,900</td>
                                    <td>
                                        <div class="ui small icon dropdown button">
                                            <i class="icon dropdown"></i>
                                            <div class="left menu">
                                                <a href="#" class="blue item" @click="transferFunds">Transfer funds</a>
                                                <a href="#" class="item">Add Paybill/Till</a>
                                                <a href="#" class="item">Add Sender ID</a>
                                                <div class="divider"></div>
                                                <a href="#" class="item">Edit client</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td class="collapsing">
                                        <div class="ui blue label">Processing</div>
                                    </td>
                                    <td>
                                        <h4 class="text-medium">#127899</h4>
                                        <small>Created 23 April 2021</small>
                                    </td>
                                    <td>23 April 2021</td>
                                    <td class="center aligned">23</td>
                                    <td>Bank deposit</td>
                                    <td>
                                        <div>Mobile Money</div>
                                        <small></small>
                                    </td>
                                    <td class="right aligned">KES 112,900</td>
                                    <td>
                                        <div class="ui small icon dropdown button">
                                            <i class="icon dropdown"></i>
                                            <div class="left menu">
                                                <a href="#" class="blue item" @click="transferFunds">Transfer funds</a>
                                                <a href="#" class="item">Add Paybill/Till</a>
                                                <a href="#" class="item">Add Sender ID</a>
                                                <div class="divider"></div>
                                                <a href="#" class="item">Edit client</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td class="collapsing">
                                        <div class="ui green label">Delivered</div>
                                    </td>
                                    <td>
                                        <h4 class="text-medium">#127899</h4>
                                        <small>Created 23 April 2021</small>
                                    </td>
                                    <td>23 April 2021</td>
                                    <td class="center aligned">23</td>
                                    <td>Bank deposit</td>
                                    <td>
                                        <div>Mobile Money</div>
                                        <small></small>
                                    </td>
                                    <td class="right aligned">KES 112,900</td>
                                    <td>
                                        <div class="ui small icon dropdown button">
                                            <i class="icon dropdown"></i>
                                            <div class="left menu">
                                                <a href="#" class="blue item" @click="transferFunds">Transfer funds</a>
                                                <a href="#" class="item">Add Paybill/Till</a>
                                                <a href="#" class="item">Add Sender ID</a>
                                                <div class="divider"></div>
                                                <a href="#" class="item">Edit client</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>


<script>
export default {
    mounted(){
        $('.ui.dropdown').dropdown();
    },
    methods: {
        goToDetails(){
            this.$router.push({name: 'vipay-transaction'})
        },
        goToCreate(){
            this.$router.push({name: 'vipay-create'})
        }
    }
}
    
</script>
