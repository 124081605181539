<template>
    <div class="ui tiny modal" id="modal-survey-questions-options">
        <i class="close icon"></i>
        <div class="">
            <div class="ui equal width relaxed padded grid">
                <div class="row">
                    <div class="column">
                        <h2 class="">Add Question</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="ui relaxed secondary pointing fluid menu">
                        <div class="active item">One</div>
                        <div class="item">Tow</div>
                    </div>
                    <div class="ui bottom attached active tab">
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="left aligned actions">
            <button class="ui cancel very basic button">Cancel</button>
        </div>
    </div>
    
</template>
<script>
import Router from 'vue-router';
export default {
    mounted() {
        $(".ui.dropdown").dropdown();
    },
    methods:{
        createSurveyDoc(){
            $(".ui.modal#modal-create-survey").modal("hide");
            this.$router.push({name: 'survey-add'});
        }
    }
}
</script>
