<template>
<div class="l-app-dashboard">
    <div class="ui relaxed padded grid">
        <div class="computer only tablet only equal width row">
            <div class="column">
                <div class="ui segment">
                    <h4>Commissions Wallet</h4>
                    <h2 class="text-medium text-primary">Ksh. {{walletCommission}}</h2>
                    <div class="ui divider"></div>
                    <button class="ui fluid blue button" disabled>Withdraw</button>
                </div>
            </div>
            <div class="column">
                <div class="ui horizontal segments margin-top-none">
                    <div class="ui segment">
                        <h4>Clients</h4>
                        <h2 class="text-medium text-primary">{{totalclients}}</h2>
                        <div class="ui divider"></div>
                        <button class="ui fluid button" @click="createLeads">Add Client or Lead</button>
                    </div>
                     <div class="ui segment">
                        <h4>Sender IDs</h4>
                        <h2 class="text-medium text-primary">{{totalSenderId}}</h2>
                        <div class="ui divider"></div>
                    </div>
                    <div class="ui segment">
                        <h4>Paybills/Tills</h4>
                        <h2 class="text-medium text-primary">{{totalPaybill}}</h2>
                        <div class="ui divider"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="computer only tablet only row" v-show="showClientAll">
            <div class="column">
                <h3 class="text-title text-medium">Clients Consumption</h3> <br />
                <div class="column">
                    <div class="ui search link small input">
                        <input type="text" placeholder="Search Phone Number"  v-model="s_msisdn" />
                    </div>
                    <div class="ui search link small input">
                        <input type="text" placeholder="Search Client Name"  v-model="s_clientName" />
                    </div>
                    <div class="ui search link small input">
                        <input type="text" placeholder="Search Account No"  v-model="s_clientAccount" />
                    </div>
                    <div class="ui search link small input">
                        <input type="text" placeholder="Search Sender Name"  v-model="s_clientSender" />
                    </div>
                    <div class="ui small input datepicker-trigger margin-left-xs">
                        <input type="text" id="datepicker-trigger" placeholder="Select date" :value="formatDates(dateOne, dateTwo)">
                        <AirbnbStyleDatepicker :trigger-element-id="'datepicker-trigger'" :mode="'range'" :fullscreen-mobile="true" :months-to-show="2" :date-one="dateOne" :date-two="dateTwo" :close-after-select="false" @date-one-selected="val => { dateOne = val }" @date-two-selected="val => { dateTwo = val }"
                        @apply="fetchAllClient()"  />
                    </div>
                    <button class="ui small bg-primary button" @click="fetchAllClient()">Filter</button>
                </div>
                <div class="columns">
                     <loader v-show="loadingNew"></loader>
                    <div class="ui negative message" v-show="ErrorStatus">
                            {{StatusMessageDesc}}
                    </div>
                    <div class="ui success message" v-show="SuccessStatus">
                            {{StatusMessageDesc}}
                    </div>
                </div>
               
                <table class="ui styled celled table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Client</th>
                            <th>Contact Details</th>
                            <th class="center aligned collapsing">Consumptions</th>
                            <th>Current Balance</th>
                            <th>Current Bonus</th>
                            <th>SMS Rate</th>
                            <th>Last Date Top Up</th>
                            <th class="center aligned">Start Date</th>
                            <th class="center aligned">End Date</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-show="!clients">
                            <td colspan="6" class="center aligned">
                                <i class="fa fa-circle-notch fa-spin"></i>
                            </td>
                        </tr>
                        <tr v-for="(client, index) in clients" :key="client.total + index">
                            <td class="center aligned collapsing">{{index + 1}}</td>
                            <td>
                                <h4 class="text-medium">{{client.client_name}} <br /><small>Contact: {{client.full_names}}</small></h4>
                            </td>
                            <td><h4>{{client.msisdn}}</h4><small>{{client.email_address}}</small></td>
                            <td class="center aligned">{{client.total}}</td>
                            <td class="center aligned">{{client.balance}}</td>
                            <td class="center aligned">{{client.bonus}}</td>
                            <td class="center aligned">{{client.unit_cost}}</td>
                            <td class="center aligned">{{client.updated_at}}</td>
                            <td class="center aligned">{{dateOne}}</td>
                            <td class="right aligned">{{dateTwo}}</td>
                            <td class="collapsing">
                                <div class="ui icon floating dropdown button">
                                    <i class="dropdown icon"></i>
                                    <div class="menu">
                                        <div class="item" @click="sendMessageClient(client)">Send Message</div>
                                        <div class="item" @click="emailTransaction(client.client_id)">Email Consumption Report</div>
                                        <div class="item" @click="selectClients(client)">View All Transaction</div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="computer only tablet only row">
            <div class="column">
                <h3 class="text-title text-medium">My Clients</h3> <br />
                <div class="actions column">
                <button class="ui large bg-primary button" @click="calculateClientDetails()">Update Sender Ids & Paybill</button>
                </div>
                <table class="ui styled celled table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Client</th>
                            <th>Contact Details</th>
                            <th class="center aligned collapsing">Sender IDs</th>
                            <th class="center aligned collapsing">Paybills</th>
                            <th class="center aligned">SMS Credits</th>
                            <th class="center aligned">Rate</th>
                            <th class="right aligned">Commission</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(clientsList, index) in clientsLists" :key="clientsList.id">
                            <td class="center aligned collapsing">{{index + 1}}</td>
                            <td>
                                <h4 class="text-medium">{{clientsList.client_name}}</h4>
                                <span class="text-grey">Created: {{clientsList.created_at}}</span>
                            </td>
                            <td><h4>{{clientsList.msisdn}}</h4><small>{{clientsList.client_email}}</small></td>
                            <td class="center aligned">{{clientsList.sender_ids}}</td>
                            <td class="center aligned">{{clientsList.paybills_aggregated}}</td>
                            <td class="center aligned">{{clientsList.balance}}</td>
                            <td class="center aligned">{{clientsList.rates}}</td>
                            <td class="right aligned"><h4 class="text-primary">Ksh. {{clientsList.commision_earned}}</h4></td>
                            <td class="collapsing">
                                <div class="ui icon floating dropdown button">
                                    <i class="dropdown icon"></i>
                                    <div class="menu">
                                        <div class="item" @click="sendMessageClient(clientsList)">Send Message</div>
                                        <div class="item" @click="showAggPaybillTill(clientsList)">Add Paybill/Till</div>
                                        <div class="item" @click="showSenderIDApply(clientsList)">Add Sender ID</div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                 <div class="padding-md">
                    <div v-show="!loading" @click="fetchDSAClient()" class="ui fluid button bottom aligned button">Load More</div>
                </div>
                <loader v-show="loading"></loader>
            </div>
        </div>
        <div class="computer only tablet only row">
            <div class="column">
                <h3 class="text-title text-medium">My Leads</h3>
                <table class="ui styled celled table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Client</th>
                            <th>Contact Details</th>
                            <th>Interests</th>
                            <th>Status</th>
                            <th>Next Follow up</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(dsaLead, index) in dsaLeads" :key="dsaLead.lead_id">
                            <td class="center aligned collapsing">{{index+1}}</td>
                            <td>
                                <h4 class="text-medium">{{dsaLead.client_name}}</h4>
                                <span class="text-grey"></span>
                            </td>
                            <td>
                                <h4>{{dsaLead.client_msisdn}}</h4>
                                <small>{{dsaLead.client_msisdn.client_email}}</small>
                            </td>
                            <td>{{dsaLead.interest}}</td>
                            <td>
                                <span class="ui orange label" v-if="dsaLead.status=='1'">Follow up</span>
                                <span class="ui label text-grey" v-if="dsaLead.status=='2'">Uninterested</span>
                                <span class="ui teal label" v-if="dsaLead.status=='3'">Requests Meeting</span>
                                <span class="ui green label" v-if="dsaLead.status=='4'">
                                    <i class="check icon"></i> Converted
                                </span>
                            </td>
                            
                            <td>{{dsaLead.appointment_date}}</td>
                            <td class="collapsing">
                                <div class="ui floating icon dropdown button">
                                    <i class="dropdown icon"></i>
                                    <div class="menu">
                                        <div class="item" @click="moveclient(dsaLead)">Move to client</div>
                                        <div class="item" @click="editClientLeads(dsaLead)">Edit Leads</div>
                                        <div class="item" @click="deleteClientLeads(dsaLead)">Delete Leads</div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="computer only tablet only row">
            <div class="column">
                <h3 class="text-title text-medium">New Signups</h3>
                <select class="ui small dropdown" v-model="days" @change="fetchSignups()">
                    <option value="">Select</option>
                    <option value="0">Today</option>
                    <option value="1">1 Day Ago</option>
                    <option value="5">5 Day Ago</option>
                    <option value="10">10 Day Ago</option>
                    <option value="30">30 Day Ago</option>
                </select>
                <div class="ui search link small input">
                     <input type="text" placeholder="Search Phone Number" v-on:keyup="fetchSignups()" v-model="s_msisdn" />
                </div>
                <table class="ui styled celled table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Client</th>
                            <th>Status</th>
                            <th>Successful Attempts</th>
                            <th>Cumlative Failed Attempts</th>
                            <th>Last Successful Attempts</th>
                            <th>Last Failed Attempts</th>
                            <th>Signup Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(signUp, index) in signUpList" :key="index">
                            <td class="center aligned collapsing">{{index+1}}</td>
                            <td>
                                <h4 class="text-medium">{{signUp.full_names}} <br /><small>Email: {{signUp.email_address}} Phone:{{signUp.msisdn}}</small></h4>
                            </td>
                           <td>{{signUp.account_status}}</td>
                           <td>{{signUp.successful_attempts}}</td>
                           <td>{{signUp.cumlative_failed_attempts}}</td>
                           <td>{{signUp.last_successful_date}}</td>
                           <td>{{signUp.last_failed_attempt}}</td>
                           <td>{{signUp.created_at}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <AccountVerify />
        <leadsClients />
        <leadsClientsEdit />
        <leadsClientsDelete />
        <AggPaybillTill />
        <senderIdClient />
        <clientMessage />
    </div>
</div>
</template>

<script>
import clientMessage from "@/modals/client-send-message";
import leadsClients from "@/modals/leadsClients";
import AccountVerify from "@/modals/verifyAgent";
import leadsClientsEdit from "@/modals/leadsClientsEdit";
import leadsClientsDelete from "@/modals/leadsClientsDelete";
import AggPaybillTill from "@/modals/aggregate-paybillClient";
import senderIdClient from  "@/modals/apply-senderIdClient";
import Choices from "choices.js";
import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
import format from "date-fns/format";
import store from "@/store";
import alertify from 'alertifyjs';
 import loader from "@/components/loader";
export default {
    data() {
        return {
            limit: 5,
            offset: 0,
            dsaLeads:null,
            clientsLists:[],
            signUpList:null,
            clients:null,
            totalclients:0,
            totalcommision:0,
            totalSenderId:0,
            totalPaybill:0,
            walletCommission:"",
            showClientAll:false,
            permission:[],
            permission_acl:"",
            dateFormat: "D MMM",
            dateFormatTwo: "YYYY-MM-DD",
            dateOne: "",
            dateTwo: "",
            s_msisdn:"",
            s_clientName:"",
            s_clientAccount:"",
            s_clientSender:"",
            days:1,
            ErrorStatus:false,
            SuccessStatus:false,
            StatusMessageDesc:"",
            loadingNew:false,
            loading:false,
            totalDSAClients:0,
            offset: 0,

        }
    },
    components: {
        AccountVerify,
        leadsClients,
        leadsClientsEdit,
        leadsClientsDelete,
        AggPaybillTill,
        senderIdClient,
        clientMessage,
        loader
    },
    
    mounted(){
        let vm = this;
        if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
        const datepickerOptions = {};
        vm.dateTwo = format(Date(), this.dateFormatTwo);
        var d = new Date();
        vm.dateOne = format(d.setDate(d.getDate() - 7), this.dateFormatTwo);
        
        vm.verifyAccount();
        vm.viewProfile();
        vm.fetchSignups();
        this.$root.$on('refreshList',(data) => {
            vm.totalclients= [];
            vm.totalclients= 0;
            vm.totalcommision= 0;
            vm.totalSenderId= 0;
            vm.totalPaybill = 0;
            vm.fetchDSALeads();
            vm.viewProfile();
            vm.fetchSignups();

        });
        //$(".ui.dropdown").dropdown();
    },
   
    methods: {
        verifyAccount(){
            $('.ui.modal#modal-verify-account-agent').modal({
                    closable: false
            }).modal('show');
            
        },
        
        createLeads(){
            $('.ui.modal#modal-contact-leads-clients').modal({
                    closable: false
            }).modal('show');
            
        },
        emailTransaction(clientID){
            let vm = this;
            vm.loadingNew =  true;
            //vm.clients = [];
            $.post({
                url: store.state.rootURLBulkAPI + "v3/SMS/Reportdelivery",
                type: "POST",
                data: JSON.stringify({
                    apiKey: this.$cookies.get("key"),
                    export: 1,
                    clientId: clientID,
                    start: vm.dateOne,
                    stop: vm.dateTwo,
                }),
                success: function(response, status, jQxhr) {
                    vm.loadingNew = false;
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.success(response.data.message);
                    vm.SuccessStatus = true;
                    vm.StatusMessageDesc = response.data.message;
                },
                error: function(jQxhr, status, error) {
                    vm.loadingNew = false;
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.error(jQxhr.responseJSON.data.message);
                    vm.ErrorStatus = true;
                    vm.StatusMessageDesc =jQxhr.responseJSON.data.message;
                }
            });
        },
        fetchDSAClient(){
            let vm = this;
             if (vm.totalclients === 0 || vm.totalclients >= ((vm.offset - 1) * vm.limit)) {
                    vm.loading = true;
                    vm.offset++;
                    $.post({
                        url: store.state.rootURLBulkAPI + "v3/dsa/leads/clients",
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: this.$cookies.get("key"),
                            offset: vm.offset,
                            limit: vm.limit
                        }),
                        success: function(response, status, jQxhr) {
                             vm.loading = false;
                             var list_c = response.data.data;
                            for (var i = 0; i < list_c.length; i++) 
                            {
                                vm.clientsLists.push(list_c[i]);
                            }
                            vm.totalclients = list_c[0].total;
                            vm.totalcommision = list_c[0].totalCommision;
                            vm.totalSenderId = list_c[0].totalSenderId;
                            vm.totalPaybill = list_c[0].totalPaybill;
                            vm.walletCommission = response.data.walletCommission;
                            setTimeout(function(){
                                $(".ui.dropdown").dropdown();
                            }, 200)
                        },
                        error: function(jQxhr, status, error) {
                            vm.loading = false;
                        }
                    });
             }
        },
        fetchAllClient(){
            let vm = this;
            vm.loadingNew =  true;
            //vm.clients = [];
            $.post({
                url: store.state.rootURLBulkAPI + "v3/dsa/view/clients",
                type: "POST",
                data: JSON.stringify({
                    apiKey: this.$cookies.get("key"),
                    stop: vm.dateTwo+" 00:00:00",
                    start: vm.dateOne+" 23:59:59",
                    msisdn: vm.s_msisdn,
                    client_name: vm.s_clientName,
                    sender_id: vm.s_clientSender,
                    accountID: vm.s_clientAccount
                }),
                success: function(response, status, jQxhr) { 
                    vm.loadingNew = false;
                    vm.clients = response.data.data;
                    setTimeout(function(){
                        $(".ui.dropdown").dropdown();
                    }, 200)
                },
                error: function(jQxhr, status, error) {
                    vm.loadingNew = false;
                }
            });
        },
        fetchDSALeads(){
            let vm = this;
            $.post({
                url: store.state.rootURLBulkAPI + "v3/dsa/leads/view",
                type: "POST",
                data: JSON.stringify({
                    apiKey: this.$cookies.get("key"),
                    offset: vm.offset,
                    limit: vm.limit
                }),
                success: function(response, status, jQxhr) {
                    vm.dsaLeads = response.data.data;
                    //$(".ui.dropdown").dropdown("refresh");
                },
                error: function(jQxhr, status, error) {
                    vm.loading = false;
                }
            });
        },
        fetchSignups(){
            let vm = this;
            $.post({
                url: store.state.rootURLBulkAPI + "v3/dsa/view/signups",
                type: "POST",
                data: JSON.stringify({
                    apiKey: this.$cookies.get("key"),
                    days: vm.days,
                    msisdn: vm.s_msisdn
                }),
                success: function(response, status, jQxhr) {
                    vm.signUpList = response.data.data;
                    //$(".ui.dropdown").dropdown("refresh");
                },
                error: function(jQxhr, status, error) {
                    vm.loading = false;
                }
            });
        },
        calculateClientDetails(){
            let vm = this;
            $.post({
                url: store.state.rootURLBulkAPI + "v3/dsa/actions",
                type: "POST",
                data: JSON.stringify({
                    apiKey: this.$cookies.get("key"),
                    action: "client_details",
                }),
                success: function(response, status, jQxhr) {
                    alertify.set("notifier", "position", "top-right");
                    alertify.success(response.data.message);
                    
                },
                error: function(jQxhr, status, error) {
                    alertify.set("notifier", "position", "top-right");
                    alertify.error(jQxhr.responseJSON.statusDescription);
                   
                }
            });
        },
        selectClients(client) {
            let vm = this;
            console.log("select");
            vm.$router.push({name: 'agent-transaction-view', params: {id: client.user_mapId}});
            
        },
        viewProfile() {
            let vm = this;
            if(vm.$cookies.get("key") != null){
                $.post({
                    url: store.state.rootURLBulkAPI + 'v3/account/view',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                    }),
                    success: function(response, status, jQxhr) {
                            vm.permission_acl = response.data.data.permission_acl
                            vm.permission = vm.permission_acl.split(":")
                            if(vm.permission.includes("57")){
                                vm.showClientAll = true;
                                vm.fetchAllClient()
                            }
                            console.log(vm.permission);
                    },
                    error: function(jQxhr, status, error) {
                        //alertify.set('notifier', 'position', 'top-right');
                        //alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });

            }
            
        },
        deleteClientLeads(clientDetails){
            
            let vm = this;
            store.commit("setMoveClientDetails",clientDetails);
            $('.ui.modal#modal-contact-leads-clients-delete').modal({
                    closable: false
            }).modal('show');
        },
        editClientLeads(clientDetails){
            let vm = this;
            store.commit("setMoveClientDetails",clientDetails);
            $('.ui.modal#modal-contact-leads-clients-edit').modal({
                    closable: false
            }).modal('show');
        },
        moveclient(clientDetails){
            let vm = this;
            store.commit("setMoveClientDetails",clientDetails);
            $('.ui.modal#modal-contact-leads-clients').modal({
                    closable: false
            }).modal('show');
        },
        showAggPaybillTill(clientDetails) {
            let vm = this;
            store.commit("setMoveClientDetails",clientDetails);
            $('.ui.modal#modal-agg-paybill-till-client').modal({
                centered: false,
                closable: true
            }).modal("show");
        },
        showSenderIDApply(clientDetails) {
            let vm = this;
            store.commit("setMoveClientDetails",clientDetails);
            $('.ui.modal#modal-apply-senderid-client').modal({
                centered: false,
                closable: true
            }).modal("show");
        },
        sendMessageClient(clientDetails) {
            let vm = this;
            store.commit("setMoveClientDetails",clientDetails);
            $('.ui.modal#modal-client-new-message').modal({
                centered: false,
                closable: true
            }).modal("show");
        },
        formatDates(dateOne, dateTwo) {
                let formattedDates = ''
                if (dateOne) {
                    formattedDates = format(dateOne, this.dateFormat)
                }
                if (dateTwo) {
                    formattedDates += ' - ' + format(dateTwo, this.dateFormat)
                }
                return formattedDates
            },
    },
    filters: {
            formatDate: function(value) {
                if (!value) return ''
                return moment(value).fromNow()
            },
        }
}
</script>
