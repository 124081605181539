<template>
    <div class="l-app-main bg-primary-bg">
     

        <div class="l-app-dashboard bg-primary-lighter" >
            <div class="ui equal width relaxed grid padded">
                    <div class="computer only tablet only equal width row">
                        <div class="column">
                            <div  class="ui green inverted segment margin-bottom-sm" :class="{loading: dataLoading}">
                                <div class="padding-sm">
                                    <h3>Message Sent</h3>
                                    <h1 class="text-medium text-2x"><strong>{{totalSent}}</strong></h1>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="ui teal inverted segment margin-bottom-sm" :class="{loading: dataLoading}">
                                <div class="padding-sm">
                                    <h3>Contacts</h3>
                                    <h1 class="text-medium text-2x"><strong>{{totalAccount}}</strong></h1>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="ui bg-primary-dark inverted segment margin-bottom-sm" :class="{loading: dataLoading}">
                                <div class="padding-sm">
                                    <h3>Amount Spent</h3>
                                    <h1 class="text-medium text-2x"><strong>KES {{totalCost}}</strong></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mobile only equal width row bg-white margin-bottom-xs">
                        <div class="eight wide column">
                            <h3 class="text-medium">Summary</h3>
                        </div>
                        
                        <div class="sixteen wide column">
                            <div class="l-app-card-3">
                                <div class="l-app-card">
                                    <div class="bg-primary">
                                        <h5 class="text-medium padding-bottom-sm">Message Sent</h5>
                                        <h2>{{totalSent}}</h2>
                                    </div>
                                </div>
                                <div class="l-app-card">
                                    <div class="teal">
                                        <h5 class="text-medium padding-bottom-sm">Contacts</h5>
                                        <h2>{{totalAccount}}</h2>
                                    </div>
                                </div>
                                <div class="l-app-card">
                                    <div class="bg-primary-dark">
                                        <h5 class="text-medium padding-bottom-sm">Amount Spent</h5>
                                        <h2>{{totalCost}}/-</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="width row">
                        <div class="column">
                            <div class="ui segment">
                                <div class="ui grid">
                                    <div class="middle aligned row">
                                        <div class="six wide computer eight wide tablet sixteen wide mobile column">
                                            <h3>WhatsApp Message</h3>
                                            <!-- <h5 class="text-lightgrey">17 Mar - 24 Mar</h5> -->
                                        </div>
                                        <div class="ten wide computer only eight wide tablet only right aligned column">
                                            <div class="ui buttons margin-right-md">
                                                <div class="computer only tablet only right aligned column">
                                                    <div class="ui tiny buttons">
                                                        <div class="ui input datepicker-trigger">
                                                            <div class="field" @click="getGraphData(day)">
                                                                <label>Select Last </label>
                                                                <select class="ui small dropdown" v-model="day">
                                                                    <option value="7">7 Day</option>
                                                                    <option value="30">30 Days</option>
                                                                    <option value="90">3 Months</option>
                                                                    <option value="180">6 Months</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="column">
                                            <canvas class="height-md" id="payments" height="300"></canvas>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                <div class="padding-bottom-xl"></div>
            </div>
            <whatsAppkeyword />
        </div>
         
    </div>
</template>

<script>
  
    import numeral from "numeral";
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import moment from 'moment';
    import loader from "@/components/loader";
    import format from 'date-fns/format';
    import Chart from 'chart.js';
    import alertify from 'alertifyjs';
      import whatsAppkeyword from "@/modals/whatsApp-keyword";
    export default {
        components: {
            whatsAppkeyword,
            loader,
        },
    
        data() {
            return {
                // credits: store.state.credits,
                paybills: [],
                loading: true,
                dateFormat: "D MMM",
                dateFormatTwo: "YYYY-MM-DD",
                dateOne: "",
                dateTwo: "",
                keywords:[],
                messages:[],
                limit: 10,
                offset: 0,
                offsettr: 0,
                totalKeywords:0,
                totalMessage:0,
                inboxzero:true,
                dataLoading:false,
                totalSent:0,
                totalCost:0,
                totalAccount:0,
                day:7
    
            }
        },
        mounted() {
            const vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            var d = new Date();
            var to = format(d.setDate(d.getDate()), this.dateFormatTwo);
            var from = format(d.setDate(d.getDate() - vm.day), this.dateFormatTwo);
            vm.getGraphData(vm.day);
            vm.getAllCount();
        },
    
        methods: {
           
            
            getGraphData(day) {
    
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/whatsApp/view/graph",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        day: day
                    }),
                    success: function(response, status, jQxhr) {
                        
                        vm.graphData = response.data.data;
    
                        let yData = [];
                        let xDate = [];
                        let result = response.data.data;
                        
    
                        result.forEach(function(data) {
                            yData.push(parseInt(data["total"]));
                            xDate.push(data["Date"]);
                        });
    
                        let ctx = $("#payments");
                        let myChart = new Chart(ctx, {
                            type: "line",
                            data: {
                                labels: xDate,
                                datasets: [{
                                    data: yData,
                                    backgroundColor: ["#F7FAFA"],
                                    borderColor: ["#26A842"],
                                    borderWidth: 1
                                }]
                            },
                            options: {
                                maintainAspectRatio: false,
                                responsive: true,
                                layout: {
                                    padding: {
                                        right: 0
                                    }
                                },
                                legend: {
                                    display: false
                                },
                                scales: {
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                                },
                                elements: {
                                    line: {
                                        tension: 0 // disables bezier curves
                                    }
                                }
                            }
                        });
                        vm.inboxzero = false;
                        // console.log(vm.graphData);
                    },
                    error: function(jQxhr, status, error) {
                        vm.inboxzero = true;
                        vm.loading = false;
                        //console.log(JSON.stringify(jQxhr.responseJSON.statusDescription));
                    }
                });
    
            },
           
            getAllCount(){
                let vm = this;
                 vm.dataLoading = true;
                 $.post({
                        url: store.state.rootUrl + 'whatsApp/view/count',
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: this.$cookies.get("key"),
    
                        }),
                        success: function(response, status, jQxhr) {
                            vm.dataLoading = false;
                            vm.totalSent = response.data.data[0].totalMessage;
                            vm.totalCost = response.data.data[0].totalCost;
                            vm.totalAccount = response.data.data[0].totalAccount;

                        },
                        error: function(jQxhr, status, error) {
                            vm.dataLoading = false;
                            vm.dataLists = [];
                            store.commit("setInboxDetails", []);
                            alertify.error(jQxhr.responseJSON.statusDescription);
                        }
                    });

            },
            showWhatsAppKeyword() {
                $('.ui.modal#modal-whatsApp-keyword').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            showWhatsAppEditMessage(val) {
                store.commit("setWhatsAppMessage", val);
                $('.ui.modal#modal-whatsApp-edit-message').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            showWhatsAppCreateMessage() {
                $('.ui.modal#modal-whatsApp-message-create').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            formatDates(dateOne, dateTwo) {
                let formattedDates = "";
                if (dateOne) {
                    formattedDates = format(dateOne, this.dateFormat);
                }
                if (dateTwo) {
                    formattedDates += " - " + format(dateTwo, this.dateFormat);
                }
                return formattedDates;
            },
        
        },
        filters: {
            formatNumber(value) {
                return numeral(value).format("0,0.00");
            },
            formatInt(value) {
                return numeral(value).format("0,0");
            }
        }
    }
</script>
