<template>
    <div class="l-app-main bg-primary-bg">
        <div class="l-app-main-inboxzero bg-primary-lighter" v-show="inboxzero">
            <h2>No Shortcode Number</h2>
            <p>Apply for Shortcode Number<br />View transactions</p>
            <div class="padding-sm"></div>
            <button class="ui large bg-primary button" @click="showApplyShortcode()">Apply Shortcode</button>
            <div class="padding-xl"></div>
        </div>
        <div class="l-app-dashboard bg-primary-lighter" v-show="!inboxzero">
            <div class="ui equal width relaxed grid padded">
                <div class="middle aligned row bg-white">
                    <div class="column">
                        <h3>Shortcode Traffic</h3>
                        <small>{{traffic}} Traffic</small>
                    </div>
                    <div class="right aligned column">
                        <button class="ui bg-primary button margin-left-md" @click="showApplyShortcode()">Apply Shortcode</button>
                    </div>
                </div>
                <div class="row bg-white border-bottom">
                    <div class="column margin-bottom-md">
                        <canvas class="height-md" id="payments" height="300"></canvas>
                    </div>
                </div>
                <div class="row">
                    <div class="computer only column">
                        <table class="ui celled styled unstackable table">
                            <thead>
                                <tr>
                                    <th>Shortcode</th>
                                    <th class="center aligned">Status</th>
                                    <th>Traffic</th>
                                    <th>Contacts</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(dataList,index) in dataLists" v-bind:key="dataList.id+index">
                                    <td>
                                        <h3>{{dataList.short_code}}</h3>
                                        <div class="text-grey">{{dataList.extra_data.company}}</div>
                                        <small class="text-grey"><span v-if="dataList.send_type_id==1">MO</span> <span v-if="dataList.send_type_id==2">MT</span> @ KES{{dataList.extra_data.smsRate}} / SMS</small>
                                    </td>
                                    <td class="center aligned">
                                        <small>
                                            <span class="text-red" v-show="dataList.status == 14">Cancelled</span>
                                            <span class="text-red" v-show="dataList.status == 11">Pending Approval</span>
                                            <span class="text-green" v-show="dataList.status == 13">Online Shortcode</span>
                                            <span class="text-gray" v-show="dataList.status == 12">Pending Approval</span>
                                        </small>
                                    </td>
                                    <td>{{dataList.trans_count}}</td>
                                    <td>{{dataList.contacts_total}}</td>
                                    
                                    <td class="center aligned collapsing">
                                        <div class="ui icon floating dropdown button ">
                                            <i class="icon dropdown"></i>
                                            <div class="menu">
                                                 <router-link :to="{name: 'inbox'}" class="item">Inbox</router-link>
                                                <a class="item">
                                                    <span v-if="dataList.status==13 && dataList.short_code == 40399 && showResponse ==true" @click="changeStatusSenderID(dataList.short_code,1)">Deactivate</span>
                                                    <span v-if="dataList.status==13 && dataList.short_code != 40399" @click="changeStatusSenderID(dataList.short_code,1)">Deactivate</span>
                                                    <span class="text-green" v-if="dataList.status==12 && dataList.short_code == 40399 && showResponse ==true" @click="changeStatusSenderID(dataList.short_code,2)">Activate</span>
                                                    <span class="text-green" v-if="dataList.status==12 && dataList.short_code != 40399" @click="changeStatusSenderID(dataList.short_code,2)">Activate</span>
                                                </a>
                                                <a class="item" @click="showResponseMessage(dataList.short_code)" v-if="dataList.short_code == 40399 && showResponse ==true" >Response Message</a>
                                                <a class="item" @click="showResponseMessage(dataList.short_code)" v-if="dataList.short_code != 40399" >Response Message</a>
                                                <a class="item" v-if="dataList.status==11" @click="changeStatusSenderID(dataList.short_code,3)"><span class="text-red">Cancel Shortcode</span></a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                        <div v-show="!loading" class="ui fluid bottom aligned button" @click="fetchData()">Load More</div>
                         <loader v-show="loading"></loader>
                    </div>
                    <div class="mobile only column bg-white padding-top-md border-top border-bottom">
                        <h3 class="text-title text-medium">Shortcodes</h3>
                        <table class="ui very basic unstackable table">
                            <thead>
                                <tr>
                                    <th>Shortcode</th>
                                    <th>Traffic</th>
                                    <th>Contact</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tr v-for="(dataList,index) in dataLists" v-bind:key="dataList.id+index">
                                <td>
                                    <div class="border-right border-light">
                                        <h3 class="text-medium">{{dataList.short_code}}</h3>
                                        <h5 class="text-grey">{{dataList.extra_data.company}}</h5>
                                         <small class="text-grey"><span v-if="dataList.send_type_id==1">MO</span> <span v-if="dataList.send_type_id==2">MT</span> @ KES{{dataList.extra_data.smsRate}} / SMS</small>
                                    </div>
                                </td>
                                <td class="top aligned">
                                    <h3>{{dataList.trans_count}}</h3>
                                </td>
                                <td class="top aligned">
                                    <h3>{{dataList.contacts_total}}</h3>
                                </td>
                                <td class="collapsing top aligned">
                                    <div class="ui icon floating dropdown tiny button">
                                        <i class="icon dropdown"></i>
                                        <div class="menu">
                                                 <router-link :to="{name: 'inbox'}" class="item">Inbox</router-link>
                                                <a class="item">
                                                    <span v-if="dataList.status==13 && dataList.short_code == 40399 && showResponse ==true" @click="changeStatusSenderID(dataList.short_code,1)">Deactivate</span>
                                                    <span v-if="dataList.status==13 && dataList.short_code != 40399" @click="changeStatusSenderID(dataList.short_code,1)">Deactivate</span>
                                                    <span class="text-green" v-if="dataList.status==12 && dataList.short_code == 40399 && showResponse ==true" @click="changeStatusSenderID(dataList.short_code,2)">Activate</span>
                                                    <span class="text-green" v-if="dataList.status==12 && dataList.short_code != 40399" @click="changeStatusSenderID(dataList.short_code,2)">Activate</span>
                                                </a>
                                                <a class="item" @click="showResponseMessage(dataList.short_code)" v-if="dataList.short_code == 40399 && showResponse ==true" >Response Message</a>
                                                <a class="item" @click="showResponseMessage(dataList.short_code)" v-if="dataList.short_code != 40399" >Response Message</a>
                                                <a class="item" v-if="dataList.status==11" @click="changeStatusSenderID(dataList.short_code,3)">Cancel Shortcode</a>
                                            </div>
                                    </div>
                                </td>
                            </tr>
                            
                        </table>
                        <div v-show="!loading" class="ui fluid bottom aligned button" @click="fetchData()">Load More</div>
                            <loader v-show="loading"></loader>
                    </div>
                    
                </div>
                <div class="padding-bottom-xl"></div>
            </div>
            <shortCodeModal />
            <shortCodeResponse />
        </div>
    </div>
</template>

<script>
    import shortCodeResponse from '@/modals/shortcode_response';
    import shortCodeModal from '@/modals/appy-shortCode';
    import Chart from 'chart.js';
    import numeral from "numeral";
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import moment from 'moment';
    import Vue from "vue";
    import loader from "@/components/loader";
    import format from 'date-fns/format';
    import alertify from 'alertifyjs';
    export default {
        components: {
            loader,
            shortCodeModal,
            shortCodeResponse
        },
    
        data() {
            return {
                // credits: store.state.credits,
                paybills: [],
                loading: false,
                dateFormat: "D MMM",
                dateFormatTwo: "YYYY-MM-DD",
                dateOne: "",
                dateTwo: "",
                days: 7,
                graphData: [],
                inboxzero: false,
                dataLists:[],
                totalDetailsLists:0,
                offset:0,
                limit:10,
                traffic:0,
                showResponse:false

    
            }
        },
        mounted() {
            const vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            vm.fetchData();
            vm.getGraphData();
            vm.displayResponse();
        },
    
        methods: {
            showApplyShortcode() {
                $(".ui.modal#modal-apply-shortcode").modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            fetchData() {
    
                var vm = this;
                if(vm.totalDetailsLists === 0 || vm.totalDetailsLists >= ((vm.offset - 1) * vm.limit)){
                vm.loading = true;
                vm.offset++;
                $.post({
    
                    url: store.state.rootURLBulkAPI + 'v3/inboxmain/view/shortcode',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        category:1
                    }),
                    success: function(response, status, jQxhr) {
    
                        vm.loading = false;
                        var l = [];
    
                        if (response.data.data != "") {
                                vm.inboxzero = false;
                                vm.totalDetailsLists = response.data.data[0].total;

                                var list_c = response.data.data;
                                if (response.data.code != 404) {
                                    for (var i = 0; i < list_c.length; i++) {
                                        vm.traffic = list_c[i].trans_count;
                                        list_c[i].date_recieved = format(list_c[i].created_at, "hh:mma DD MMM YYYY");
                                        list_c[i].extra_data = JSON.parse(list_c[i].extra_data);
                                        l.push(list_c[i]);
                                    }
    
                                } else {
                                    alertify.error(response.data.message);
                                }
                            } else if (vm.offset > 1) {
                                vm.inboxzero = false;
                            } else {
                                vm.inboxzero = true;
                            }

                            vm.dataLists = l;
                            setTimeout(function() {
                            $(".ui.dropdown").dropdown();
                        }, 100)
    
                    },
                    error: function(jQxhr, status, error) {

                        vm.dataLists = [];
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
    
                });
                }
    
            },
            getGraphData(from, to) {
    
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/inboxmain/view/shortcode",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        start: from,
                        end: to,
                        paybill: "",
                        category:2
                    }),
                    success: function(response, status, jQxhr) {
    
                        vm.graphData = response.data.data;
    
                        let yData = [];
                        let xDate = [];
                        let result = response.data.data;
    
                        result.forEach(function(data) {
                            yData.push(parseInt(data["transaction_count"]));
                            xDate.push(data["summary_date"]);
                        });
    
                        let ctx = $("#payments");
                        let myChart = new Chart(ctx, {
                            type: "line",
                            data: {
                                labels: xDate,
                                datasets: [{
                                    data: yData,
                                    backgroundColor: ["#F7FAFA"],
                                    borderColor: ["#26A842"],
                                    borderWidth: 1
                                }]
                            },
                            options: {
                                maintainAspectRatio: false,
                                responsive: true,
                                layout: {
                                    padding: {
                                        right: 0
                                    }
                                },
                                legend: {
                                    display: false
                                },
                                scales: {
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                                },
                                elements: {
                                    line: {
                                        tension: 0 // disables bezier curves
                                    }
                                }
                            }
                        });
                        // console.log(vm.graphData);
                    },
                    error: function(jQxhr, status, error) {
                        vm.loading = false;
                        //console.log(JSON.stringify(jQxhr.responseJSON.statusDescription));
                    }
                });
    
            },
            displayResponse(){
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + 'v3/senderId/list',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        short_code: 40399,
                        shortCodeStatus:1,
                    }),
                    success: function(response, status, jQxhr) {
                        var dataList = response.data.data[0];
                        if(response.data.code == 200){
                           vm.showResponse = true
                        }
                        else{
                            vm.showResponse=false;
                        }
                        //vm.dataLists = l;
                            setTimeout(function() {
                            $(".ui.dropdown").dropdown();
                        }, 100)
                       
                    },
                    error: function(jQxhr, status, error) {
                    }
                });
            },
            showResponseMessage(shortcode){
                $.post({
                    url: store.state.rootURLBulkAPI + 'v3/senderId/list/shortcodes',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        short_code: shortcode,
                        shortCodeStatus:1,
                    }),
                    success: function(response, status, jQxhr) {
                        var dataList = response.data.data;
                        if(response.data.code == 200){
                            store.commit("setShortcodeSelect", dataList);
                                $(".ui.modal#modal-shortcode-response").modal({
                                centered: false,
                                closable: true
                            }).modal("show");
                        }
                        else{
                            alertify.error(response.data.message);
                        }
                       
                    },
                    error: function(jQxhr, status, error) {
                    }
                });
                
            },
            changeStatusSenderID(shortcode, status) {
                var vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + 'v3/senderId/edit/shortcode',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        short_code: shortcode,
                        status: status
                    }),
                    success: function(response, status, jQxhr) {
    
                        alertify.success("Success " + JSON.stringify(response.data.message));
                        vm.offset = 0;
                        vm.dataLists = [];
                        vm.totalDetailsLists = 0;
    
                        vm.fetchData();

                        vm.dataLists = l;
                            setTimeout(function() {
                            $(".ui.dropdown").dropdown();
                        }, 100)
                        //vm.$root.$emit('refresh');
    
                    },
                    error: function(jQxhr, status, error) {
    
                        alertify.error("Error " + JSON.stringify(jQxhr.responseJSON.statusDescription));
                    }
                });
            },
        },
        filters: {
            formatNumber(value) {
                return numeral(value).format("0,0.00");
            },
            formatInt(value) {
                return numeral(value).format("0,0");
            }
        }
    }
</script>
