<template>
  <div class="ui relaxed padded grid">
    <div class="padding-top-md"></div>
    <div class="ui negative message" v-show="MessangeStatus">
      {{ StatusMessageDesc }}
    </div>
    <div class="row">
      <div class="column">
        <select class="ui fluid dropdown" v-model="senderId">
          <option value="">From</option>
          <option
            v-for="s_id in dataLists"
            v-show="dataLists.length > 0 && s_id.status == 13"
            :key="s_id.short_code"
            :value="s_id.short_code"
          >
            {{ s_id.short_code }}
          </option>

          <option v-if="dataLists.length == 0" value="254709183222">254709183222</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <div class="field">
          <div class="ui toggle checkbox">
            <input type="checkbox" name="schedule" v-model="Sendtemplate" />
            <label><strong>Send Template Message </strong></label>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-show="Sendtemplate">
      <div class="column">
        <select
          class="ui fluid dropdown"
          v-model="templateID"
          v-on:change="setComposeMessage(templateID)"
        >
          <option value="">Select Templates</option>
          <option
            v-for="s_id in templates"
            v-show="templates.length > 0"
            :key="s_id.id"
            :value="s_id.id"
          >
            {{ s_id.templateName }}
          </option>
          <option v-if="templates.length == 0" value="5">Default Template Message</option>
        </select>
      </div>
    </div>
    <div class="row" v-show="!Sendtemplate">
      <div class="column">
        <select class="ui fluid dropdown" v-model="whatsAppType">
          <option value="">Select Type</option>
          <option value="TEXT">TEXT</option>
          <option value="FILE">FILE</option>
          <option value="IMAGE">IMAGE</option>
          <option value="AUDIO">AUDIO</option>
          <option value="VIDEO">VIDEO</option>
          <option value="LOCATION">LOCATION</option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="column">
        <select
          id="contactsBlock"
          required
          multiple="multiple"
          placeholder="Type contact or select from list"
          v-model="contactListData"
        ></select>
      </div>
    </div>

    <div class="row" v-show="showUploadSection">
      <div class="column" id="droppedFiles">
        <div class="ui right labeled icon label">
          {{ filename }}<i class="icon close" @click="closeUpload()"></i>
        </div>
      </div>
    </div>
    <div class="row" v-show="showPlaceholders && whatsAppType != 'LOCATION'">
      <div class="column">
        <div class="ui segments">
          <div class="ui segment">
            <strong>Custom Fields</strong>
          </div>
          <div class="ui horizontal segments">
            <div class="ui segment">{{ placeholders }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-show="whatsAppType != 'LOCATION'">
      <div class="column">
        <div class="ui top attached segment">
          <textarea
            rows="5"
            placeholder="Type your WhatsApp message here..."
            v-model="composedMessage"
            :disabled="Sendtemplate"
          ></textarea>
        </div>
        <div class="ui bottom attached segment bg-grey-light">
          <div class="ui equal width grid">
            <div class="column">
              <small>{{ messageCount }} characters </small>
            </div>
            <div class="right aligned column">
              <small>{{ messagePages }} Page</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-show="Sendtemplate">
      <div class="column">
        <div class="ui form">
          <div class="three fields">
            <div class="field" v-show="template_custom_field >= 1">
              <label>Placeholder 1</label>
              <input
                type="text"
                placeholder="Enter Placeholder 1"
                v-model="placeHolder1"
              />
            </div>
            <div class="field" v-show="template_custom_field > 1">
              <label>Placeholder 2</label>
              <input
                type="text"
                placeholder="Enter Placeholder 2"
                v-model="placeHolder2"
              />
            </div>
            <div class="field" v-show="template_custom_field > 2">
              <label>Placeholder 3</label>
              <input
                type="text"
                placeholder="Enter Placeholder 3"
                v-model="placeHolder3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <div class="ui form">
          <div class="two fields hidden">
            <div class="field">
              <div class="ui toggle checkbox">
                <input type="checkbox" name="schedule" v-model="scheduleMessage" />
                <label><strong>Schedule Message </strong></label>
              </div>
            </div>
          </div>
          <div class="two fields hidden">
            <div class="field" v-show="scheduleMessage">
              <label>Date</label>
              <div class="ui calendar" id="scheduleDate">
                <div class="ui input left icon">
                  <i class="calendar icon"></i>
                  <input type="text" placeholder="Select date" ref="scheduledDate" />
                </div>
              </div>
            </div>
            <div class="field" v-show="scheduleMessage">
              <label>Time</label>
              <div class="ui calendar" id="scheduleTime">
                <div class="ui input left icon">
                  <i class="time icon"></i>
                  <input type="text" ref="scheduledTime" />
                </div>
              </div>
            </div>
          </div>
          <div class="two fields" v-show="whatsAppType == 'LOCATION'">
            <div class="field">
              <label>
                <gmap-autocomplete @place_changed="setPlace"> </gmap-autocomplete>
              </label>
            </div>
            <div class="field">
              <button
                class="ui bg-secondary text-white right floated button"
                @click="addMarker"
              >
                Add
              </button>
            </div>
          </div>
          <div class="fields" v-show="whatsAppType == 'LOCATION'">
            <br />
            <gmap-map :center="center" :zoom="12" style="width: 100%; height: 400px">
              <gmap-marker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                @click="center = m.position"
              ></gmap-marker>
            </gmap-map>
          </div>
        </div>
        <div class="ui equal width grid">
          <div class="computer only column">
            <input
              :accept="uploadAccept"
              v-show="
                !Sendtemplate &&
                (whatsAppType == 'FILE' ||
                  whatsAppType == 'IMAGE' ||
                  whatsAppType == 'VIDEO' ||
                  whatsAppType == 'AUDIO')
              "
              type="file"
              name="files"
              id="files"
              ref="files"
              class="inputfile inputfile-6"
              multiple
              v-on:change="handleFileUploads()"
            />

            <button
              v-show="scheduleMessage && saveTamplate"
              @click="sendMessage"
              :disabled="!sendStatus"
              class="ui bg-accent icon right floated labeled button"
              id="button-schedule-message-compute-save"
            >
              <i class="icon send"></i> Schedule & Save Message
            </button>
            <button
              v-show="scheduleMessage && !saveTamplate"
              @click="sendMessage"
              :disabled="!sendStatus"
              class="ui bg-accent icon right floated labeled button"
              id="button-schedule-message-compute"
            >
              <i class="icon send"></i> Schedule Message
            </button>
            <button
              v-show="!scheduleMessage && saveTamplate"
              @click="sendMessage"
              :disabled="!sendStatus"
              class="ui bg-primary text-white icon right floated button"
              id="button-send-message-compute-save"
            >
              Send & Save Message
            </button>
            <button
              v-show="!scheduleMessage && !saveTamplate"
              @click="sendMessage"
              :disabled="!sendStatus"
              class="ui bg-primary text-white right floated button"
              id="button-send-message-compute"
            >
              Send WhatsApp Message
            </button>
          </div>
          <div class="tablet only mobile only column">
            <div class="padding-md"></div>
            <br />
            <button
              v-show="scheduleMessage && saveTamplate"
              @click="sendMessage"
              :disabled="!sendStatus"
              class="ui bg-accent fluid button"
              id="button-schedule-message-mobile-save"
            >
              Schedule & Save Message
            </button>
            <button
              v-show="scheduleMessage && !saveTamplate"
              @click="sendMessage"
              :disabled="!sendStatus"
              class="ui bg-accent fluid button"
              id="button-schedule-message-mobile"
            >
              Schedule Message
            </button>
            <button
              v-show="!scheduleMessage && !saveTamplate"
              @click="sendMessage"
              :disabled="!sendStatus"
              class="ui fluid button bg-primary text-white"
              id="button-send-message-mobile"
            >
              Send WhatsApp Message
            </button>
            <button
              v-show="!scheduleMessage && saveTamplate"
              @click="sendMessage"
              :disabled="!sendStatus"
              class="ui fluid button bg-primary text-white"
              id="button-send-message-mobile-save"
            >
              Send & Save Message
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
textarea {
  width: 100%;
  border: none;
  outline: none;
  font-size: 1.4em;
  &::placeholder {
    color: #ccc;
  }
}

.row {
  padding-top: 0 !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import VueProgressBar from "vue-progressbar";
import store from "@/store";
import bulkstore from "@/bulk/bulksms-state";
import Choices from "choices.js";
import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
import format from "date-fns/format";
import moment from "moment";
import Vue from "vue";
import alertify from "alertifyjs";
import _ from "lodash";
import Dropzone from "dropzone";

export default {
  data() {
    return {
      templateplaceholders: [],
      placeHolder3: "",
      placeHolder2: "",
      placeHolder3: "",
      templateName: "",
      Sendtemplate: false,
      templates: [],
      template_custom_field: 0,
      scheduleMessage: false,
      sendWhatsApp: false,
      saveTamplate: false,
      contactsOptions: null,
      dateFormat: "D MMM",
      whatsAppType: "TEXT",
      composedMessage: "",
      composeTotalCharacters: 0,
      templateTitle: "",
      dateOne: "",
      dateTwo: "",
      senderId: "",
      contactListData: [],
      contactGroup: [],
      phoneGroup: [],
      numberContainer: [],
      formattedDates: "",
      schedule_time: "",
      isScheduled: 0,
      scheduledDate: "",
      totalSenderID: 0,
      uploadFiles: null,
      uploadWhatsAppFiles: null,
      dataLists: [],
      templateLists: [],
      showUploadSection: false,
      contactListBlock: null,
      contactListBlockElement: null,
      arrayList: null,
      responseStatus: false,
      responseStatusMessage: "",
      scheduledTime: "",
      placeholders: "",
      showPlaceholders: false,
      holder: "",
      contactNumber: [],
      contactList: [],
      sendCount: 0,
      MessangeStatus: false,
      StatusMessageDesc: "",
      selectTemplate: null,
      template: "",
      saveTemplateStatus: true,
      filename: "",
      endMessage: "",
      sendStatus: true,
      whatsAppKeyword: [],
      showWhatsAppOption: false,
      whatsAppLink: false,
      templateID: "",
      latitude: "",
      longitude: "",
      address: "",
      formatted_address: "",
      urlWhatsApp: "",
      center: { lat: -1.286389, lng: 36.817223 },
      markers: [],
      places: [],
      currentPlace: null,
      uploadState: false,
      placeHolder1: "",
      placeHolder2: "",
      placeHolder3: "",
    };
  },
  beforeCreate() {
    let vm = this;
    $.post({
      url: store.state.rootUrl + "contact/get/group",
      type: "POST",
      data: JSON.stringify({
        apiKey: this.$cookies.get("key"),
        offset: 0,
        order: 10,
        limit: 2000,
      }),
      success: function (response, status, jQxhr) {
        var arrayList = [];
        var customList = [];
        var customListAdd = "";
        if (response.data.data != null) {
          var lists = response.data.data;
          var i = 0;
          lists.forEach((list) => {
            var listData = {
              value: response.data.data[i].contact_groupId,
              label: response.data.data[i].group_name,
              selected: false,
              disabled: false,
            };
            if (
              response.data.data[i].custom_field1 != "" &&
              response.data.data[i].custom_field1 != null
            ) {
              customListAdd = " | [" + response.data.data[i].custom_field1 + "]";
            }
            if (response.data.data[i].custom_field2 != "") {
              customListAdd += " | [" + response.data.data[i].custom_field2 + "]";
            }
            if (response.data.data[i].custom_field3 != "") {
              customListAdd += " | [" + response.data.data[i].custom_field3 + "]";
            }
            if (response.data.data[i].custom_field4 != "") {
              customListAdd += " | [" + response.data.data[i].custom_field4 + "]";
            }
            if (response.data.data[i].custom_field5 != "") {
              customListAdd += " | [" + response.data.data[i].custom_field5 + "]";
            }

            var placeHd = {
              value: response.data.data[i].contact_groupId,
              custom: customListAdd,
            };
            customList.push(placeHd);

            arrayList.push(listData);
            customListAdd = "";
            i = i + 1;
          });
          vm.contactListBlockElement = document.getElementById("contactsBlock");
          vm.contactListBlock = new Choices(vm.contactListBlockElement, {
            duplicateItemsAllowed: false,
            renderChoiceLimit: 3,
            delimiter: ",",
            removeItemButton: true,
            addItems: true,
            addChoices: true,
            choices: arrayList,
          });
          var contactNumbersArray = [];
          var contactListArray = [];
          vm.contactListBlockElement.addEventListener(
            "addItem",
            function (event) {
              vm.showPlaceholders = false;
              var data = event.detail.value;
              vm.contactListData.push(data);
              var listContactData = vm.contactListData;
              listContactData.forEach(function (element) {
                if (element.length >= 9 && element.length <= 12) {
                  vm.showPlaceholders = false;
                  contactNumbersArray.push(element);
                } else {
                  contactListArray.push(element);
                  if (vm.contactListData.length == 1) {
                    customList.forEach(function (newElemet) {
                      if (newElemet.value == element) {
                        vm.showPlaceholders = true;
                        vm.placeholders = "[full_names] [mobile] " + newElemet.custom;
                      }
                    });
                  } else {
                    vm.showPlaceholders = true;
                    vm.placeholders = "[full_names] [mobile]";
                  }
                }
              });
            },
            false
          );
          vm.contactListBlockElement.addEventListener(
            "removeItem",
            function (event) {
              let valIndex = _.indexOf(vm.contactGroup, event.detail.value);
              vm.phoneGroup = vm.contactGroup.splice(valIndex, 1);
              var listContactData = vm.contactListData;
              listContactData.forEach(function (element) {
                if (element.length >= 9 && element.length <= 12) {
                  vm.showPlaceholders = false;
                  contactNumbersArray.push(element);
                } else {
                  contactListArray.push(element);
                  console.log("Contact list lenght: " + vm.contactListData.length);

                  if (vm.contactListData.length <= 1) {
                    vm.showPlaceholders = false;
                    vm.placeholders = "";
                  } else {
                    if (vm.contactListData.length == 2) {
                      customList.forEach(function (newElemet) {
                        if (newElemet.value == element) {
                          vm.showPlaceholders = true;
                          vm.placeholders = "[full_names] [mobile]  " + newElemet.custom;
                        }
                      });
                    } else {
                      vm.showPlaceholders = true;
                      vm.placeholders = "[full_names] [mobile]";
                    }
                  }
                }
              });

              console.log("new length " + vm.contactListData.length);
            },
            false
          );
        }
      },
      error: function (jQxhr, status, error) {
        return [];
      },
    });
  },
  mounted() {
    let vm = this;
    if (!vm.$cookies.get("key") || vm.$cookies.get("key") == null) {
      vm.$router.push({
        name: "home",
      });
    }

    if (store.getters.compMessage) {
      this.composedMessage = store.getters.compMessage;
    }
    // template list
    vm.getTemplateList();
    vm.getWhatsAppKeywords();
    vm.getTemplates();

    $(".ui.dropdown").dropdown();

    vm.fetchData();

    const datepickerOptions = {};
    Vue.use(AirbnbStyleDatepicker, datepickerOptions);

    $("#scheduleDate").calendar({
      type: "date",
    });
    $("#scheduleTime").calendar({
      type: "time",
    });
  },
  computed: {
    messageCount() {
      //var defaultCount = 14;
      var phoneNu = "";
      phoneNu = window.atob(this.$cookies.get("username"));
      var phone = "";

      if (this.dataLists.length > 0) {
        this.endMessage = "";
        this.composeTotalCharacters =
          this.endMessage.length + this.composedMessage.length;
      } else {
        this.endMessage = "";
        this.composeTotalCharacters =
          this.endMessage.length + this.composedMessage.length;
      }

      return this.composeTotalCharacters;
    },
    messagePages() {
      if (this.composedMessage.length > 0) {
        $("#textarea-sms").removeClass("red");
      }

      if (this.composeTotalCharacters == 0) {
        return 0;
      } else if (this.composeTotalCharacters / 160 <= 1) {
        return 1;
      } else if (this.composeTotalCharacters / 160 <= 2) {
        return 2;
      } else if (this.composeTotalCharacters / 160 <= 3) {
        return 3;
      } else if (this.composeTotalCharacters / 160 <= 4) {
        return 4;
      } else if (this.composeTotalCharacters / 160 <= 5) {
        return 5;
      } else {
        return " Max ";
      }
    },
    uploadAccept() {
      if (this.whatsAppType == "IMAGE") {
        return "image/*";
      } else if (this.whatsAppType == "AUDIO") {
        return "audio/*";
      } else if (this.whatsAppType == "VIDEO") {
        return "video/*";
      } else {
        return "file_extension";
      }
    },
  },
  methods: {
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.latitude = this.currentPlace.geometry.location.lat();
        this.longitude = this.currentPlace.geometry.location.lng();
        this.address = this.currentPlace.name;
        this.formatted_address = this.currentPlace.formatted_address;
        this.currentPlace = null;
      }
    },
    geolocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    IncludeWhatsAppMessage() {
      let vm = this;
      if (vm.whatsAppLink == false) {
        var whatsAppPhone = vm.whatsAppKeyword[0]["msisdn"];
        var keywords = vm.whatsAppKeyword[0]["keyword"];
        vm.composedMessage = vm.composedMessage.concat(
          "https://wa.me/" + whatsAppPhone + "?text=" + keywords
        );
      } else {
        vm.composedMessage = "";
      }
    },
    handleFileUploads() {
      let vm = this;
      vm.sendStatus = false;
      vm.uploadState = true;
      var myFile = this.$refs.files.files[0];
      var data = new FormData();
      data.append("apiKey", this.$cookies.get("key"));
      data.append("fileType", vm.whatsAppType);
      data.append("file", myFile);
      $.ajax({
        url: bulkstore.state.bulksmsUrl + "/whatsApp/upload",
        type: "POST",
        data: data,
        cache: false,
        contentType: false,
        processData: false,
        success: function (response, status, jQxhr) {
          vm.urlWhatsApp = response.data.data.file_url;
          vm.sendStatus = true;
          vm.uploadState = false;
          alertify.set("notifier", "position", "top-right");
          alertify.success(response.data.message);
        },
        error: function (jQxhr, status, error) {
          alertify.error(jQxhr.responseJSON.statusDescription);
        },
      });
    },
    closeUpload() {
      let vm = this;
      vm.showUploadSection = false;
      vm.fileToUpload = "";
      $("#droppedFiles").html();
    },
    formatDates(dateOne, dateTwo) {
      let formattedDates = "";
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat);
      }
      if (dateTwo) {
        formattedDates += " - " + format(dateTwo, this.dateFormat);
      }
      return formattedDates;
    },
    sendMessage() {
      let vm = this;
      if (vm.scheduleMessage) {
        $("#button-schedule-message-mobile").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Scheduling Message ...'
        );
        $("#button-schedule-message-compute-save").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Scheduling Message ...'
        );
        $("#button-schedule-message-compute").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Scheduling Message ...'
        );
        $("#button-schedule-message-mobile-save").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Scheduling Message ...'
        );
      } else {
        $("#button-send-message-mobile").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Sending Message ...'
        );
        $("#button-send-message-mobile-save").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Sending Message ...'
        );
        $("#button-send-message-compute").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Sending Message ...'
        );
        $("#button-send-message-compute-save").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Sending Message ...'
        );
      }
      vm.sendStatus = false;

      if (!vm.composedMessage && vm.whatsAppType == "TEXT") {
        alertify.error("Message is required");
        vm.MessangeStatus = true;
        vm.StatusMessageDesc = "Message is required. Kindly enter Message";
      }
      if (!vm.contactListData) {
        alertify.error("Contact or select from list is required");
        vm.MessangeStatus = true;
        vm.StatusMessageDesc = "Contact or select from list is required";
      }
      var listContactData = vm.contactListData;

      if (vm.scheduleMessage) {
        vm.isScheduled = 1;
      }

      var contactNumbersArray = [];
      var contactListArray = [];
      listContactData.forEach(function (element) {
        if (element.length >= 9 && element.length <= 12) {
          contactNumbersArray.push(element);
        } else {
          contactListArray.push(element);
        }
      });
      var contactNumber = contactNumbersArray.join();
      var contactList = contactListArray.join();
      var whatsExtra = {
        type: vm.whatsAppType,
        url: vm.urlWhatsApp,
        lt: vm.latitude,
        la: vm.longitude,
        loc: vm.formatted_address,
        add: vm.address,
      };
      if (vm.Sendtemplate) {
        var placeholderTemp = [];
        if (vm.placeHolder1 != "") {
          placeholderTemp.push(vm.placeHolder1);
        }
        if (vm.placeHolder2 != "") {
          placeholderTemp.push(vm.placeHolder2);
        }
        if (vm.placeHolder3 != "") {
          placeholderTemp.push(vm.placeHolder3);
        }
        var whatsExtra = {
          tempName: vm.templateName,
          placehld: placeholderTemp,
        };
      }
      if (vm.whatsAppType == "LOCATION") {
        vm.composedMessage = vm.formatted_address;
      }

      if (contactNumber.length != "") {
        vm.composedMessage = vm.composedMessage.split("<br>").join("\n");
        $.post({
          url: bulkstore.state.bulksmsUrl + "BulkSMS/create",
          type: "POST",
          data: JSON.stringify({
            apiKey: vm.$cookies.get("key"),
            shortCode: vm.senderId,
            message: vm.composedMessage,
            contacts: {
              recipients: contactNumber,
            },
            scheduleDate: moment(this.$refs.scheduledDate.value).format("YYYY-MM-DD"),
            scheduleTime: moment(this.$refs.scheduledTime.value, "h:mm A").format(
              "HH:mm:ss"
            ),
            isScheduled: vm.isScheduled,
            callbackURL: "",
            origin: "WEB",
            enqueue: 1,
            isRcs: true,
            isRcsType: vm.whatsAppType,
            extraData: whatsExtra,
          }),
          success: function (data, status, jQxhr) {
            vm.composedMessage = "";
            vm.contactListData = [];
            alertify.set("notifier", "position", "top-right");
            alertify.success(data.data.message);
            vm.numberContainer = [];
            vm.$parent.showCreateWhatsApp = false;
            if (contactList.length == "" && vm.showUploadSection == false) {
              vm.$router.push({
                name: "bulk-messages",
              });
            }
          },
          error: function (jQxhr, status, error) {
            alertify.set("notifier", "position", "top-right");
            alertify.error(jQxhr.responseJSON.data.message);
            vm.MessangeStatus = true;
            vm.StatusMessageDesc = jQxhr.responseJSON.data.message;
            vm.getMessangeStatus();
          },
        });
      }
      if (contactList.length != "") {
        vm.composedMessage = vm.composedMessage.split("<br>").join("\n");
        var messageText = !vm.composedMessage.endsWith(vm.endMessage)
          ? vm.composedMessage + "" + vm.endMessage
          : vm.composedMessage;
        var messageEdited = messageText.split("\n").join("{line}");
        $.post({
          url: bulkstore.state.bulksmsUrl + "BulkSMS/group/create",
          type: "POST",
          data: JSON.stringify({
            apiKey: vm.$cookies.get("key"),
            shortCode: vm.senderId,
            message: messageEdited,
            approval: "",
            listId: contactList,
            scheduleDate: moment(this.$refs.scheduledDate.value).format("YYYY-MM-DD"),
            scheduleTime: moment(this.$refs.scheduledTime.value, "h:mm A").format(
              "HH:mm:ss"
            ),
            isScheduled: vm.isScheduled,
            callbackURL: "",
            templateID: vm.templateID,
            isRcs: true,
            isRcsTemplate: vm.Sendtemplate,
            isRcsType: vm.whatsAppType,
            extraData: whatsExtra,
          }),
          success: function (data, status, jQxhr) {
            vm.contactListData = [];
            vm.composedMessage = "";
            alertify.set("notifier", "position", "top-right");
            alertify.success(data.data.message);

            vm.$parent.showCreateWhatsApp = false;
            if (vm.showUploadSection == false) {
              vm.$router.push({
                name: "bulk-messages",
              });
            }
          },
          error: function (jQxhr, status, error) {
            alertify.set("notifier", "position", "top-right");
            vm.MessangeStatus = true;
            alertify.error(jQxhr.responseJSON.statusDescription);
            vm.StatusMessageDesc = jQxhr.responseJSON.statusDescription;
            vm.getMessangeStatus();
          },
        });
      }

      if (
        contactNumber.length == "" &&
        contactList.length == "" &&
        !vm.showUploadSection
      ) {
        vm.MessangeStatus = true;
        vm.StatusMessageDesc = "Error Contact Required: Kindly enter or Select Contact";
        alertify.error(vm.StatusMessageDesc);
        vm.getMessangeStatus();
      }
    },
    fetchData() {
      //console.log("TEST");
      // this.dataLists = [];
      var vmBalance = this;

      var vm = this;

      $.post({
        url: bulkstore.state.bulksmsUrl + "senderId/list",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
          status: 13,
          p_status: 4,
          type_id: 5,
        }),
        success: function (response, status, jQxhr) {
          vm.dataLists = response.data.data;
          console.log(JSON.stringify(vm.dataLists));
          //alertify.set('notifier', 'position', 'top-right');
          //alertify.success(response.data.message);
          //console.log("test message " + JSON.stringify(response.data.data));
        },
        error: function (jQxhr, status, error) {
          alertify.set("notifier", "position", "top-right");
          alertify.error(jQxhr.responseJSON.data.message);
          // console.log(" status bulk " + status + " data " + JSON.stringify(jQxhr.responseJSON.statusDescription));
        },
      });
    },
    getTemplates() {
      var vm = this;
      $.post({
        url: bulkstore.state.bulksmsUrl + "whatsApp/template/view",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
        }),
        success: function (response, status, jQxhr) {
          vm.templates = response.data.data;
        },
        error: function (jQxhr, status, error) {},
      });
    },
    getTemplateList() {
      var vm = this;
      $.post({
        url: store.state.rootURLBulkAPI + "v3/account/templates/list",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
          template_title: "",
          template: "",
          template_status: 1,
        }),
        success: function (response, status, jQxhr) {
          if (response.data.data != "") {
            vm.inboxzero = false;
            vm.templateLists = response.data.data;
          }
        },
        error: function (jQxhr, status, error) {
          vm.templateLists = [];
        },
      });
    },
    getWhatsAppKeywords() {
      let vm = this;
      $.post({
        url: store.state.rootUrl + "whatsApp/view/keywords",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
        }),
        success: function (response, status, jQxhr) {
          if (response.data.code == 200) {
            vm.showWhatsAppOption = true;
            vm.whatsAppKeyword = response.data.data;
          }
        },
        error: function (jQxhr, status, error) {},
      });
    },
    setComposeMessage(templateID) {
      let vm = this;
      vm.template_custom_field = 0;
      $.post({
        url: store.state.rootURLBulkAPI + "v3/whatsApp/template/view",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
          templateID: templateID,
        }),
        success: function (response, status, jQxhr) {
          if (response.data.data != "") {
            vm.saveTemplateStatus = false;
            vm.templateName = response.data.data[0].templateName;
            vm.composedMessage = response.data.data[0].template;
            vm.template_custom_field = response.data.data[0].custom_field;
          }
        },
        error: function (jQxhr, status, error) {
          vm.saveTemplateStatus = true;
          vm.composedMessage = "";
        },
      });
    },
    getMessangeStatus() {
      let vm = this;
      if (vm.MessangeStatus == true) {
        $("#button-send-message-mobile").html("Send Message");
        $("#button-send-message-mobile-save").html("Send Message");
        $("#button-send-message-compute").html("Send Message");
        $("#button-send-message-compute-save").html("Send Message");

        $("#button-schedule-message-mobile").html("Schedule Message");
        $("#button-schedule-message-compute-save").html(
          '<i class="icon send"></i> Schedule Message'
        );
        $("#button-schedule-message-compute").html(
          '<i class="icon send"></i> Schedule Message'
        );
        $("#button-schedule-message-mobile-save").html("Schedule Message");
        vm.sendStatus = true;
      }
    },
  },

  watch: {
    contactGroup: function (val, old) {
      let vm = this;
      console.log("Val | " + val);
      vm.contactGroup = val;
    },
  },
};
</script>
