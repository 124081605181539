<template>
    <div class="l-app-main">
        <div class="l-app-main-inboxzero bg-primary-lighter" v-show="inboxzero">
            <h2>No Inbox Keywords</h2>
            <p>Create a keyword to be able filter message<br /></p>
            <div class="padding-sm"></div>
            <button class="ui large bg-primary button" @click="createKeyword()">Create Keywords</button>
            <div class="padding-xl"></div>
        </div>
        <div class="l-app-main-section" v-show="!inboxzero">
            <div class="l-app-main-section-container one">
                <div class="l-app-list-title">
                    <div class="ui middle aligned grid">
                        <div class="column">
                            <button class="ui bg-primary small fluid button" @click="createKeyword()"> Add Keyword </button>
                        </div>
                    </div>
                </div>
    
                <ul class="l-app-list">
                    <li v-for="dataList in dataLists" v-bind:key="dataList.list_id" @click="selectList(dataList)" v-bind:class="{selected: selectedKey == dataList.contact_groupId}">{{dataList.list_name}}
                        <div class="status hidden"><i class="circle green icon"></i></div>
                        <div class="info">
                            <span class="info-title">{{dataList.keyword}}</span>
                            <span class="info-meta">{{dataList.message_count}} Message - <small>{{dataList.short_code}} Shortcode</small></span>
                        </div>
                    </li>
                </ul>
    
                <div class="padding-md">
                    <div v-show="!loading" @click="getKeywords()" class="ui fluid button bottom aligned button">Load More</div>
                </div>
                <loader v-show="loading"></loader>
            </div>
          
        <KeywordDetails v-show="selectedList" ref="details" />
    
        </div>
        <CreateContactList />
        <EditContactList />
    </div>
</template>

<script>
    import loader from "@/components/loader";
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import CreateContactList from "@/modals/createInboxKeyword";
    import EditContactList from "@/modals/contact-list-edit";
    import Dropzone from "dropzone";
    // import CreateContactList from "@/bulk/modals/contacts-list-create";
    
    import KeywordDetails from "./keyword-details";
    export default {
        data() {
            return {
                inboxzero: false,
                dataLists: [],
                dataListCount: 0,
                countN: 1,
                list_id: "",
                list_name: "",
                status: "",
                limit: 10,
                selectedKey: null,
                offset: 0,
                selectedList: '',
                order: "",
                showUploadSection: false,
                uploadFiles: null,
                processingUploadFile: false,
                //infinite scroll
                busy: false,
                totalContactLists: 0,
                loading: true,
            }
        },
        components: {
            loader,
            CreateContactList,
            EditContactList,
            KeywordDetails
        },
        mounted() {
            let vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            if (vm.$route.params.id != "" && !isNaN(vm.$route.params.id)) {
                   
                var listid = vm.$route.params.id;
                vm.selectedKey = listid;
               // this.$router.push({name: 'ListDetails', params: { id: listid}});
            }
            $('.ui.dropdown').dropdown();
            $('.ui.progress').progress();
            vm.getKeywords();
            this.$root.$on('refreshList',(data) => {
                vm.dataLists= [];
                vm.dataListCount= 0;
                vm.countN= 1;
                vm.list_id= "";
                vm.list_name= "";
                vm.status= "";
                vm.limit= 10;
                vm.offset= 0;
                vm.totalContactLists = 0;
                vm.selectedList='';
                vm.getKeywords();

            });
            this.$root.$on('refreshSelected',(data) => {
               
               vm.dataLists= [];
                vm.dataListCount= 0;
                vm.countN= 1;
                vm.list_id= "";
                vm.list_name= "";
                vm.status= "";
                vm.limit= 10;
                vm.offset= 0;
                vm.totalContactLists = 0;
                vm.getKeywords();
            });
            this.totalContactLists = 0;
            this.dataLists = [];
            this.selectedList = '';
            bulkstore.commit("setSelectedList", '');
            
        },
        methods: {
            createKeyword() {
                $(".ui.modal#modal-create-keyword-inbox").modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            editKeywords() {
                $(".ui.modal#modal-edit-contact-list").modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            
            getKeywords: function() {
                var vm = this;
                if (vm.totalContactLists === 0 || vm.totalContactLists >= ((vm.offset - 1) * vm.limit)) {
                    vm.loading = true;
                    vm.offset++;
                    $.post({
                        url: store.state.rootUrl + 'notify/keyword/list',
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: this.$cookies.get("key"),
                            limit: vm.limit,
                            offset: vm.offset

                        }),
                        success: function(response, status, jQxhr) {
                            vm.loading = false;
                            if (response.data.data != "") {
                                vm.inboxzero = false;
                                var responseData = response.data.data;
                                console.log('Response Data: '+JSON.stringify(responseData));
                                vm.dataListCount = response.data.count;
                                vm.totalContactLists = response.data.data.record_count;
                                var totalContacts = 0;
                                var list_c = response.data.data;
    
                                for (var i = 0; i < list_c.length; i++) 
                                {
                                    totalContacts = totalContacts + parseInt(list_c[i].record_count, 10);
                                    vm.dataLists.push(list_c[i]);
                                    if(i==0){
                                       // vm.selectList(list_c[i]);
                                    }
                                    
                                }
                                //bulkstore.commit("setContactList", vm.dataLists);
                            }
                            else if(vm.offset > 1){
                                vm.inboxzero = false;
                            } else {
                                vm.inboxzero = true;
                            }
                        },
                        error: function(jQxhr, status, error) {
                            vm.loading = false;
                            vm.dataLists = [];
                            bulkstore.commit("setContactList", []);
                        }
                    });
                }
            },
            selectList(list) {
                //console.log("am here "+JSON.stringify(list));
                this.selectedKey="";
                this.selectedList=[];
                this.$route.params.id ="";
                this.selectedKey = list.keyword_id;
                this.selectedList = list;
                bulkstore.commit("setKeywordInbox", list);
                this.$refs.details.setListValue(list);
                if ($(window).width() < 640){
                this.$router.push({name: 'inbox-keywords-details', params: { id: list.keyword_id}});
                }
            }
        }
    };
</script>


