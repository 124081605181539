import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const paymentStore = new Vuex.Store({
    state:{
        paymentsUrl:"http://localhost/api_main/v2/",
        selectedPayment:{},
    },
    mutations:{
        setSelectedPayment(state,selectedPayment){
            state.selectedPayment=selectedPayment;
        }
    }

});


export  default  paymentStore;
