<template>
    <div class="l-app-main">
        <div class="l-app-main-inboxzero bg-primary-lighter" v-show="inboxzero">
            <h2>Create a template</h2>
            <p>Use templates to send auto-response<br />messages when actions are<br />triggered</p>
            <div class="padding-sm"></div>
            <button class="ui large bg-primary button" @click="showCreateTemplatesMessage()">Create Template</button>
            <div class="padding-xl"></div>
        </div>
        <div class="l-app-main-content" v-show="!inboxzero">
            <div class="ui equal width grid">
                <div class="middle aligned row">
                    <div class="column">
                        <h3>All Templates</h3>
                    </div>
                    <div class="computer only tablet only right aligned column">
                        <button class="ui blue small button" @click="showCreateTemplatesMessage()">Create template</button>
                    </div>
                    <div class="right aligned mobile only column">
                        <button class="ui blue small button" @click="showCreateTemplatesMessage()">Create</button>
                    </div>
                </div>
              <!--  <div class="left aligned column">
                    <div class="ui search link input margin-right-md">
                        <input type="text" placeholder="Search Template Title" v-on:keyup=" getTemplateList()" v-model="title" />
                    </div>
                </div> -->
                <div class="computer only tablet only row">
                    <div class="column">
                        <table class="ui celled styled unstackable table">
                            <thead>
                                <tr>
                                    <th class="center aligned collapsing">#</th>
                                    <th>Title</th>
                                    <th>Template</th>
                                    <th>Status</th>
                                    <th class="center aligned">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(transc, index) in dataLists" :key="index">
                                    <td class="center aligned">{{index+1}}</td>
                                    <td>
                                        <h4>{{transc.template_title}}</h4>
                                    </td>

                                    <td>{{transc.template}}</td>
                                    <td>{{transc.status}}</td>
                                    <td class="collapsing">
                                        <button class="ui small green button" v-if="transc.status == 'Active'" @click="showCreateTemplate(true,transc.template)">Use template</button>
                                        <button class="ui small default button" v-if="transc.status == 'Inactive'" @click="statusTemplate(transc,1)">Activate template</button>
                                        <div class="ui floating dropdown icon small button">
                                            <i class="icon dropdown"></i>
                                            <div class="menu">
                                                <div class="item" @click="editTemplateList(transc)">Edit Template</div>
                                                <div class="item" v-if="transc.status == 'Active'" @click="statusTemplate(transc,0)">Deactivate Template</div>
                                                <div class="item" v-if="transc.status == 'Inactive'" @click="statusTemplate(transc,1)">Activate Template</div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-show="!loading" @click="getTemplateList" class="ui fluid button">Load More</div>
                        <loader v-show="loading"></loader>
                    </div>
                </div>
                <div class="mobile only row bg-white border-top border-bottom border-light">
                    <div class="column">
                        <table class="ui very basic celled unstackable table">
                            <thead>
                            <tr>
                                <th>Title</th>
                                <th class="right aligned">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(transc, index) in dataLists" :key="index">
                                <td class="center aligned">{{index+1}}</td>
                                <td>
                                    <h4>{{transc.template_title}}</h4>
                                    <p class="text-grey">{{transc.template}}</p>
                                    <small>{{transc.status}}</small>
                                </td>
                                <td class="right aligned">
                                    <div class="ui floating dropdown icon small button">
                                        <i class="icon dropdown"></i>
                                        <div class="menu">
                                            <div class="item" v-if="transc.status == 'Active'" @click="showCreateTemplate(true,transc.template)">Use</div>
                                            <div class="item" @click="editTemplateList(transc)">Edit Template</div>
                                            <div class="item" v-if="transc.status == 'Active'" @click="statusTemplate(transc,0)">Deactivate Template</div>
                                            <div class="item" v-if="transc.status == 'Inactive'" @click="statusTemplate(transc,1)">Activate Template</div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-show="!loading" @click="getTemplateList" class="ui fluid button">Load More</div>
                        <loader v-show="loading"></loader>
                    </div>
                </div>
            </div>
        </div>
        <CreateTemplate />
        <EditTemplate />
        <StatusTemplate />
    </div>
</template>

<script>
    import loader from "@/components/loader";
    import store from "@/store";
    import CreateTemplate from "@/modals/template-create";
    import EditTemplate from "@/modals/template-edit";
    import StatusTemplate from "@/modals/template-status";
    import bulkstore from "@/bulk/bulksms-state";
    export default {
        data() {
            return {
                inboxzero: false,
                totalTemplate: 0,
                dataLists: [],
                offset: 0,
                limit: 10,
                loading: false,
                title:""
            }
        },
        mounted() {
            let vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            $('.ui.dropdown').dropdown();
            vm.getTemplateList();
        },
        components: {
            CreateTemplate,
            loader,
            EditTemplate,
            StatusTemplate,
        },
        methods: {
            showCreateTemplate(v, message) {
    
                this.$parent.showCreateMessageFn(v, message);
                store.commit("composeMessageState", message);
            },
            showCreateTemplatesMessage() {
                //console.log("test message main");
                $(".ui.modal#modal-create-template").modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            getTemplateList() {
                //console.log("test am here");
                //this.dataAmount = "";
                var vm = this;
                if (vm.totalTemplate === 0 || vm.totalTemplate >= ((vm.offset - 1) * vm.limit)) {
                    vm.loading = true;
                    vm.offset++;
                   // console.log(vm.offset);
                    $.post({
                        url: store.state.rootURLBulkAPI + "v3/account/templates/list",
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: this.$cookies.get("key"),
                            template_title: vm.title,
                            template: "",
                            status: "",
                            limit: vm.limit,
                            offset: vm.offset
    
                        }),
                        success: function(response, status, jQxhr) {
                            vm.loading = false;
                            if (response.data.data != "") {
                                vm.inboxzero = false;
                                //vm.dataLists = response.data.data;
                                vm.totalTemplate = response.data.record_count;
    
                                var totalContacts = 0;
                                var list_c = response.data.data;
    
                                for (var i = 0; i < list_c.length; i++) {
                                    var statusMess = "";
                                    if(list_c[i].status == 1){
                                        statusMess = "Active";
                                    }
                                    else{
                                        statusMess = "Inactive"
                                    }
                                    
                                    list_c[i].status = statusMess;

                                    vm.dataLists.push(list_c[i]);
                                }
                            } else if (vm.offset > 1) {
                                vm.inboxzero = false;
                            } else {
                                vm.inboxzero = true;
                            }
                            setTimeout(() => {
                                $('.ui.dropdown').dropdown();
                            }, 100);
                           // console.log(JSON.stringify(vm.dataLists));
                            
                        },
                        error: function(jQxhr, status, error) {
                            vm.loading = false;
                            vm.dataLists = [];
                        }
                    });
                }
            },
            editTemplateList(data) {
                store.commit("setSelectTemplate", data);
                $(".ui.modal#modal-edit-template").modal({
                    centered: false,
                    closable: true
                }).modal("show");
    
            },
            statusTemplate(data,status) {
    
                store.commit("setSelectTemplate", data);
                store.commit("setSelectTemplateStatus",status);
                $(".ui.modal#modal-status-template").modal({
                    centered: false,
                    closable: true
                }).modal("show");
            }
        }
    }
</script>

