<template>
    <div class="ui tiny modal" id="modal-payment-recon">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">Reconciliation</h2>
                <h4>Mpesa Reconciliation for<br />Paybill or Till Number {{originalName}}</h4>
            </div>
            <div class="ui centered container grid">
                <div class="column">
                    <div class="ui form">
                        <div class="padding-sm"></div>
                        <div class="field">
                            <label>Upload Mpesa Reconciliation File (Only CSV File)</label>
                            <div class="ui file input">
                                <button class="ui small button" id="contacts-upload-file-btn" :disabled="uploadStatus==1">Upload Mpesa File</button>
                            </div>
                             <div class="row" v-show="showUploadSection">
                                <div class="center aligned column">
                                    <div class="ui negative message" v-show="UploadStatus">
                                        {{UploadStatusDesc}}  
                                        
                                    </div>
                                    <div class="ui secondary center aligned segment">
                                        <div class="ui top aligned grid">
                                            <div class="twelve wide left aligned column" id="droppedFiles">
                                                <div class='ui right labeled icon label'>{{filename}}<i class='icon close i-file' @click='closeUpload()'></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="padding-sm"></div>
                        <div class="field">
                            <div class="ui checkbox">
                                <input type="checkbox" v-model="agreeTerms"/>
                                <label>I agree to the Terms and Conditions of this service</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui large button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary large button" :disabled="!agreeTerms" @click="aggPaybillTill()" id="button-export">Upload</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    import Dropzone from "dropzone";
    export default {
        data() {
            return {
                typePaybill: "",
                paybillTypeStatus: false,
                agreeTerms: false,
                paybillType: "",
                safaricomUsername:"",
                dataAmount: 0,
                sendID: '',
                max:6,
                min:6,
                paybillTypeNumber:"",
                type: "",
                companyName: "",
                TillStoreNumber:null,
                paybillTypeName:"",
                showContactMessageModal: false,
                UploadStatus:false,
                UploadStatusDesc:"",
                filename:"",
                uploadStatus:"",
                showUploadSection: false,
                uploadFiles: null,
            }
        },
        computed: {
            originalName() {
                var vm = this;
                var responseBillDetails = store.state.paybillSelect;
                vm.paybillTypeNumber = responseBillDetails.paybill;
                
                return vm.paybillTypeNumber;
    
            }
        },
        mounted() {
            let vm = this;
            if(store.state.selectMoveClientDetails != ""){
                  console.log(JSON.stringify(store.state.selectMoveClientDetails));
            }
            vm.fetchPaybiillType();
             vm.uploadFiles = new Dropzone('#contacts-upload-file-btn', {
                url: store.state.rootUrl + 'file/uploadcontacts',
                paramName: "file", // The name that will be used to transfer the file
                maxFilesize: 1,
                createImageThumbnails: false,
                acceptedFiles: ".csv",
                autoProcessQueue: false,
                params: {
                    "apiKey": this.$cookies.get("key"),
                    "paybill_number": vm.paybillTypeNumber
                },
                previewTemplate: "<div style='display: none;'></div>"
            });
            vm.uploadFiles.on("addedfile", function(file) {
                vm.showUploadSection = true;
                vm.filename = file.name;
                //$('#droppedFiles').html("")
            });
            vm.uploadFiles.on("removedFile", function(file) {
              
                vm.showUploadSection = false;
                $('#droppedFiles').html("");
            });
            $(".icon.close.i-file").click(function(e){
                vm.closeUpload();
            });

        },
        methods: {
            fetchPaybiillType(){
                $.get({
                    url:store.state.rootURLBulkAPI + 'v3/payment/paybill/types',
                    type:"GET",
                    success:function(data,status,jQxhr){
                        

                    },
                    error:function (jQxhr,status,error) {
                      // console.log("ERROR "+JSON.stringify(jQxhr.responseJSON.error));
                    }
                });
            },
            onChange(e){
            let vm = this;''
            vm.paybillTypeStatus = true;
            vm.type = e.target.value;
             if(vm.type == 1){
                 vm.paybillType = "Till Number";
                 vm.paybillTypeName = "Store Number";
             }
             else{
                 vm.paybillType = "Paybill Number";
                 vm.paybillTypeName = "";
             }
            },
            closeUpload(){
                let vm = this;
                vm.uploadFiles.removeAllFiles(true);
                vm.UploadStatusDesc = "";
                vm.UploadStatus =  false;
                vm.showUploadSection = false;
            },
            handleFileUpload() {
                this.file = this.$refs.file.files[0];
            },
            aggPaybillTill(){
                let vm = this;
                $('#button-export').html('<i class="fas fa-circle-notch fa-spin"></i> Uploading Please Wait ...');
                vm.agreeTerms = false;
                var myFile = vm.uploadFiles.files[0];
               console.log(vm.uploadFiles.files[0]);
                var form = new FormData();
                form.append("uploaded_file", myFile);
                form.append("apiKey", vm.$cookies.get("key"));
                form.append("paybill_number",vm.paybillTypeNumber);
                $.post({
                    url: store.state.rootURLBulkAPI + 'v3/payment/mpesa/file/upload',
                    type: "POST",
                    data: form, //JSON.stringify({"file":myFile}),
                    cache: false,
                    contentType: false,
                    processData: false,
                    success: function(response, status, jQxhr) {
                        if(response.data.code ==202){
                            alertify.set("notifier", "position", "top-right");
                            alertify.error(response.data.message);
                        }
                        else{

                            alertify.set("notifier", "position", "top-right");
                            
                            alertify.success(response.data.message);
                            vm.$parent.getPaybills();
                            vm.hideModal();
                        }
                        vm.agreeTerms = true;
                        $('#button-export').html('<i class="icon download"></i> Upload');
                    },
                    error: function(jQxhr, status, error) {
                        alertify.set("notifier", "position", "top-right");
                        alertify.error(jQxhr.responseJSON.statusDescription);
                        alertify.error(jQxhr.responseJSON.data.message);
                        vm.agreeTerms = true;
                        $('#button-export').html('<i class="icon download"></i> Upload');
                    }
                });
            },
            hideModal() {
                $('.ui.modal#modal-payment-recon').modal('hide');
            }
        }
    }
</script>
