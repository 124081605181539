<template>
<div class="l-onboard">
    <div class="l-onboard-center">
        <div class="ui centered grid">
            <div class="row">
                <div class="login">
                    <div id="okta-signin-container"></div>

                </div>
       


              
            </div>
            <div class="row">
                    <div class="field margin-top-lg">
                        <router-link class="ui basic fluid button" to="/login">
                              Login Via Vaspro
                            </router-link>
                      </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import OktaSignIn from '@okta/okta-signin-widget'
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'

import sampleConfig from '../config'
import $ from 'jquery'
export default {
    name: 'Login',
    mounted: function () {
        this.$nextTick(function () {
            let vmLogin = this
            const {
                issuer,
                clientId,
                redirectUri,
                scopes,
                useInteractionCodeFlow
            } = sampleConfig.oidc

            this.widget = new OktaSignIn({
                /**
                 * Note: when using the Sign-In Widget for an OIDC flow, it still
                 * needs to be configured with the base URL for your Okta Org. Here
                 * we derive it from the given issuer for convenience.
                 * 
                 */

                baseUrl: "https://dev-53711850.okta.com", //issuer.split('/oauth2')[0],
                clientId,
                redirectUri,
                // eslint-disable-next-line no-undef
                logo: require('@/assets/images/logo.png'),
                i18n: {
                    en: {
                        'primaryauth.title': 'Okta Login'
                    }
                },
                authParams: {
                    issuer,
                    scopes,
                },
                useInteractionCodeFlow
            })

            this.widget.showSignInToGetTokens({
                el: '#okta-signin-container',
                scopes
            }).then(tokens => {
                // console.log("Claims: " + JSON.stringify(tokens.idToken.claims))
                // console.log("ClientID: " + tokens.idToken.clientId)

                $.post({
                    url: "https://api.vaspro.co.ke/v2/login/okta",
                    type: "POST",

                    data: JSON.stringify({
                        claimData: btoa(JSON.stringify(tokens.idToken.claims)),
                        ClientID: tokens.idToken.clientId
                    }),
                    success: function (response) {

                        vmLogin.$cookies.set("key", response.data.data.key);
                        vmLogin.$cookies.set("username", window.btoa(vmLogin.username));

                        vmLogin.$cookies.set("accessGrant", response.data.data.accessGrant);
                        if (response.data.data.auth_roleId != null) {
                            vmLogin.$cookies.set("auth_roleId", response.data.data.auth_roleId);
                        } else {
                            vmLogin.$cookies.set("auth_roleId", 0);
                        }

                        if (response.data.data.loginStatus == 2) {
                            vmLogin.$router.push({
                                name: "set-password"
                            });
                        } else {
                            if (response.data.data.reseller) {
                                vmLogin.$cookies.set("reseller", response.data.data.reseller);
                                vmLogin.$router.push({
                                    name: "reseller"
                                });
                            } else {
                                if (response.data.data.reseller_account) {
                                    vmLogin.$cookies.set(
                                        "reseller-account",
                                        response.data.data.reseller_account
                                    );
                                }
                                if (response.data.data.base_color) {
                                    vmLogin.$cookies.set("reseller-baseColor", response.data.data.base_color);
                                }
                                if (response.data.data.secondary_color) {
                                    vmLogin.$cookies.set(
                                        "reseller-secondary_color",
                                        response.data.data.secondary_color
                                    );
                                }
                                if (response.data.data.system_name) {
                                    vmLogin.$cookies.set(
                                        "reseller-system_name",
                                        response.data.data.system_name
                                    );
                                }
                                if (response.data.data.logo_url) {
                                    vmLogin.$cookies.set("reseller-logo_url", response.data.data.logo_url);
                                }
                                vmLogin.$router.push({
                                    name: "bulk-dashboard"
                                });
                            }
                        }
                    },
                    error: function () {
                        //var statustext = jQxhr.responseJSON.statusDescription;
                        //console.log(statustext)
                    }
                });
                // this.$auth.handleLoginRedirect(tokens)
            }).catch(err => {
                throw err
            })
        })
    },
    destroyed() {
        // Remove the widget from the DOM on path change
        this.widget.remove()
    }
}
</script>
