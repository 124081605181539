<template>
    <div class="ui tiny modal" id="modal-sdk-push">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">Send STK Push</h2>
                <h4>Paybill/Till {{originalName}}</h4>
                <div class="ui negative message" v-show="depositPayment">
                            <div v-html= "depositPaymentStatus"></div>
                </div>
            </div>
            <div class="ui equal width centered container grid">
                <div class="column">
                    <div class="ui form">
                        <div class="field">
                            <label>Phone Number</label>
                            <div class="ui input">
                                <input  v-model="msisdn" />
                            </div>
                        </div>
                        <div class="field">
                            <label>Amount</label>
                            <div class="ui input">
                                <input type= "number" placeholder="" v-model="amount"/>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui cancel button">Cancel</button>
            <button class="ui bg-primary button" :disabled="!agreeTerms" @click="deposit()"><i id="applyDeposit"></i> SDK Push</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    export default {
         data() {
            return {
                msisdn: null,
                amount: 1000,
                account:null,
                depositPayment:false,
                depositPaymentStatus:null,
                paybill:null,
                callbackUrl:null,
                agreeTerms:true
            }
        },
         mounted() {
            let vm = this;
            console.log("Am here");
        },
        computed:{
           originalName() {
               var vm = this;
                var responseBillDetails = store.state.paybillSelect;
                if(responseBillDetails !=""){
                    vm.paybill = responseBillDetails.paybill;
                    vm.callbackUrl = responseBillDetails.pushUrl;
                    return vm.paybill;
                }
                else{
                    return "";
                }
               $(".ui.toggle.checkbox").checkbox();
               //return window.atob(this.$cookies.get("username"));
               //Console.log("Custom Fil" +bulkstore.state.selectedList.list_name);
            }
       },
       
        methods: {
            deposit() {
                this.dataAmount = 0;
                let vm = this;
                vm.agreeTerms = false;
                $('#applyDeposit').html('<i class="fas fa-circle-notch fa-spin"></i>');
                $.post({
                    url: store.state.rootURLBulkAPI + 'v3/checkout/mpesa',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        phoneNumber: vm.msisdn,
                        amount: vm.amount,
                        callBackURL: vm.callbackUrl,
                        paybillNumber: vm.paybill,
                        transactionDesc:""
                    }),
                    success: function(response, status, jQxhr) {
                    
                    if(response.data.code != 200){
                        vm.depositPayment = true;
                        vm.agreeTerms = true;
                         $('#applyDeposit').html('');
                        vm.depositPaymentStatus = response.data.data.text;
                        alertify.set("notifier", "position", "top-right");
                        alertify.error(response.data.data.text);
                        alertify.error(response.data.message);
                        //console.log("failed"+response.data.message);
                    }
                    else {
                        vm.agreeTerms = true;
                        alertify.success(response.data.message);
                        vm.hideModal();
                    }
                        
                    },
                    error: function(jQxhr, status, error) {
                        alertify.set("notifier", "position", "top-right");
                        alertify.error(jQxhr.responseJSON.statusDescription);
                        alertify.error(jQxhr.responseJSON.data.message);
                    }
                });
            
                
            },
            hideModal() {
                $('.ui.modal#modal-sdk-push').modal('hide');
            }
        }
    }
</script>
