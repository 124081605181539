<template>
  <div class="ui larger modal" id="modal-user-add">
      <i class="close icon"></i>
    <div class="scrolling content">
      <div class="text-center margin-bottom-lg">
        <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md">
        <h2 class="padding-bottom-sm">
          Add User to Organization
        </h2>
      </div>
      <div class="ui negative message" v-show="userStatus">{{userStatusDesc}}</div>
      <div class="ui equal width centered container grid">
        <div class="column">
          <div class="ui form">
            <div class="required field">
              <label>Full Name</label>
              <div class="ui input">
                <input placeholder v-model="full_name" required>
              </div>
            </div>
            <div class="two required fields">
                <div class="field">
                    <label>Email</label>
                    <div class="ui input">
                        <input type="email" placeholder v-model="email" required>
                    </div>
                </div>
                <div class="required field">
                    <label>Phone Number</label>
                    <div class="ui input">
                        <input type="tel" placeholder v-model="msisdn" required>
                    </div>
                </div>
            </div>
            <div class="field">
              <label>Select Role</label>
              <select class="ui dropdown" v-model="userRole">
                <option value="1">Admin</option>
                <option value="2">Bulk User</option>
                <option value="3">Custom User</option>
                <option value="4">Maker</option>
                <option value="5">Checker</option>
                <option value="6">Auditor</option>
              </select>
            </div>
            <div class="field" v-show="userRole == 3">
              <div class="padding-sm"></div>
              <h4 class="text-medium">Select Permissions</h4>
              <div class="padding-xs"></div>
              <div class="ui grid">
                <div class="six wide column">
                  <div class="ui vertical fluid tabular menu">
                      
                    <a :class="{active: service.service_id == selectedServiceId}" class="item" v-for="service in servicesList" :key="service.service_id" :data-tab="service.service_name" @click="checkServices(service)">{{service.service_name}}</a>
                      
                  </div>
                </div>
                <div class="ten wide stretched column">
                    
                    <div v-for="service in servicesList" :key="service.service_name" :class="{active: service.service_id == selectedServiceId}" class="ui tab" :data-tab="service.service_id">
                        <div class="ui checkbox" style="display: block; padding-bottom: .75em" v-for="permission in permissionList" :key="permission.service_id">
                            <input type="checkbox" :value="permission.permission_id" v-model="permissionSelected">
                            <label>{{permission.permission}}</label>
                        </div>
                    </div>
                    
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
        <button class="ui cancel button" @click="hideModal()">Cancel</button>
        <button class="ui bg-primary button" @click="addUser()">Add User</button>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import bulkstore from "@/bulk/bulksms-state";
import alertify from "alertifyjs";
export default {
  data() {
    return {
      full_name: null,
      email: null,
      msisdn: null,
      userRole: "2",
      userStatus: false,
      userStatusDesc: null,
      selectedRole: null,
      servicesList: [],
      permissionList: [],
      selectedService: null,
      selectedServiceId: 2,
      permissionSelected: [],
    };
  },
  mounted() {
      let vm = this;
      vm.fetchService();
      vm.checkServices(2);
      
    $(".menu .item").tab();
  },
  methods: {
    fetchService(){
        let vm = this;
        $.post({
        url: store.state.rootURLBulkAPI + "v2/users/service/view",
        type: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key")
        }),
        success: function(response, status, jQxhr) {
            vm.servicesList = response.data.data;
            console.log(JSON.stringify(response.data.data));

        },
        error: function(jQxhr, status, error) {
          console.log("failed to create account");
        }
      });

    },
    checkServices(service){
        let vm =this;
        vm.selectedServiceId = service.service_id;
         $.ajax({
            url: store.state.rootURLBulkAPI + "v2/users/permission/view",
            type: "POST",
            data: JSON.stringify({
            apiKey: vm.$cookies.get("key"),
            service_id: vm.selectedServiceId
            }),
            success: function(response, status, jQxhr) {
                vm.permissionList =response.data.data;
                console.log(JSON.stringify(response.data.data));
            },
            error: function(jQxhr, status, error) {
            console.log("failed to create account");
            }
        });

    },
    addUser() {
      let vm = this;

      $.post({
        url: store.state.rootURLBulkAPI + "v3/account/add/users",
        type: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key"),
          full_names: vm.full_name,
          user_flag: vm.userRole,
          email_address: vm.email,
          mobile_number: vm.msisdn,
          permissions_acl: vm.permissionSelected.join(":"),
          region: "",
          date_of_birth: "",
          national_id: "",
          billing_modeId: ""
        }),
        success: function(response, status, jQxhr) {
          if (response.data.code != 200) {
            alertify.set("notifier", "position", "top-right");
            alertify.error(response.data.message);
            vm.userStatus = true;
            vm.userStatusDesc = response.data.message;
          } else {
            vm.hideModal();
            alertify.set("notifier", "position", "top-right");
            alertify.success(response.data.message);
          }
        },
        error: function(jQxhr, status, error) {
          //console.log("failed to create account");
          alertify.set("notifier", "position", "top-right");
          alertify.error(jQxhr.responseJSON.statusDescription);
          vm.userStatus = true;
          vm.userStatusDesc = jQxhr.responseJSON.statusDescription;
        }
      });
    },
    hideModal() {
      $(".ui.modal#modal-user-add").modal("hide");
    }
  }
};
</script>
