<template>
  <div class="ui relaxed padded grid">
    <div class="padding-top-md"></div>
    <div class="ui negative message" v-show="MessangeStatus">
      {{ StatusMessageDesc }}
    </div>
    <div class="row">
      <div class="column">
        <select class="ui fluid dropdown" v-model="senderId">
          <option value="">From</option>
          <option
            v-for="s_id in dataLists"
            v-show="dataLists.length > 0 && s_id.status == 13"
            :key="s_id.short_code"
            :value="s_id.short_code"
          >
            {{ s_id.short_code }}
          </option>
          <option v-if="dataLists.length == 0" value="VasPro">VasPro</option>
        </select>
      </div>
    </div>
    <div class="row" v-show="templateLists.length > 0">
      <div class="column">
        <select
          class="ui fluid dropdown"
          v-model="templateID"
          v-on:change="setComposeMessage(templateID)"
        >
          <option value="">Select Template</option>
          <option
            v-for="s_id in templateLists"
            v-show="templateLists.length > 0"
            :key="s_id.id"
            :value="s_id.id"
          >
            {{ s_id.template_title }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <select
          id="contactsBlock"
          required
          multiple="multiple"
          placeholder="Type contact or select from list"
          v-model="contactListData"
        ></select>
      </div>
      
    </div>
    <div class="row">
      <div class="column">
        <tags-input element-id="tags"
    v-model="selectedTags"
    placeholder="Enter Phone Number"
  
    :typeahead="true"></tags-input>
      </div>
    </div>

    <div class="row" v-show="showUploadSection">
      <div class="column" id="droppedFiles">
        <div class="ui right labeled icon label">
          {{ filename }}<i class="icon close" @click="closeUpload()"></i>
        </div>
      </div>
    </div>
    <div class="row" v-show="showPlaceholders">
      <div class="column">
        <div class="ui segments">
          <div class="ui segment">
            <strong>Custom Fields</strong>
          </div>
          <div class="ui horizontal segments">
            <div class="ui segment">{{ placeholders }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="column">
        <div class="ui top attached segment">
          <textarea
            rows="5"
            placeholder="Type your SMS here. Opt out option 'STOP*456*9*5#' is included"
            v-model="composedMessage"
          ></textarea>
        </div>
        <div class="ui bottom attached segment bg-grey-light">
          <div class="ui equal width grid">
            <div class="column">
              <small
                >{{ messageCount }} characters
                <div id="textarea-sms" v-show="dataLists.length > 0">
                  (14 include OPT Out)
                </div>
                <div id="textarea-sms" v-show="dataLists.length == 0">
                  (32 include OPT and Sent By)
                </div>
              </small>
            </div>
            <div class="right aligned column">
              <small>{{ messagePages }} SMS</small>
            </div>
          </div>
        </div>
        <div class="ui form">
          <div class="field" v-show="showWhatsAppOption">
            <div class="ui checkbox">
              <input
                type="checkbox"
                name="schedule"
                v-model="whatsAppLink"
                @click="IncludeWhatsAppMessage"
              />
              <label>Include WhatsApp Link</label>
            </div>
          </div>
        </div>
        <div class="ui form">
          <div class="field" v-show="saveTemplateStatus">
            <div class="ui checkbox">
              <input type="checkbox" name="schedule" v-model="saveTamplate" />
              <label>Save this message as template</label>
            </div>
          </div>
          <div class="field" v-show="saveTamplate">
            <label>Template Title</label>
            <div class="ui small input">
              <input type="text" placeholder="Enter title" v-model="templateTitle" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="column padding-bottom-md">
        <router-link
          target="_blank"
          to="../../files/sample_sms.csv"
          class="text-underline"
          >Download Sample File</router-link
        >
      </div>
    </div>
    <div class="row">
      <div class="column">
        <div class="ui form">
          <div class="two fields">
            <div class="field">
              <div class="ui toggle checkbox">
                <input type="checkbox" name="schedule" v-model="scheduleMessage" />
                <label><strong>Schedule Message </strong></label>
              </div>
            </div>
          </div>
          <div class="two fields">
            <div class="field" v-show="scheduleMessage">
              <label>Date</label>
              <div class="ui calendar" id="scheduleDate">
                <div class="ui input left icon">
                  <i class="calendar icon"></i>
                  <input type="date" placeholder="Select date" ref="scheduledDate" />
                </div>
              </div>
            </div>
            <div class="field" v-show="scheduleMessage">
              <label>Time</label>
              <div class="ui calendar" id="scheduleTime">
                <div class="ui input left icon">
                  <i class="time icon"></i>
                  <input type="time" ref="scheduledTime" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ui equal width grid">
          <div class="computer only column">
            <button class="ui samll button" id="upload-file-btn" v-show="!sendWhatsApp">
              <i class="file icon"></i> Upload Contacts File
            </button>
            <button
              class="ui samll button"
              id="upload-file-whatsApp"
              v-show="
                sendWhatsApp && whatsAppType != 'TEXT' && whatsAppType != 'LOCATION'
              "
            >
              <i class="file icon"></i> UPLOAD {{ whatsAppType }}
            </button>
            <button
              v-show="scheduleMessage && saveTamplate"
              @click="sendMessage"
              :disabled="!sendStatus"
              class="ui bg-accent icon right floated labeled button"
              id="button-schedule-message-compute-save"
            >
              <i class="icon send"></i> Schedule & Save Message
            </button>
            <button
              v-show="scheduleMessage && !saveTamplate"
              @click="sendMessage"
              :disabled="!sendStatus"
              class="ui bg-accent icon right floated labeled button"
              id="button-schedule-message-compute"
            >
              <i class="icon send"></i> Schedule Message
            </button>
            <button
              v-show="!scheduleMessage && saveTamplate"
              @click="sendMessage"
              :disabled="!sendStatus"
              class="ui bg-secondary text-white icon right floated button"
              id="button-send-message-compute-save"
            >
              Send & Save Message
            </button>
            <button
              v-show="!scheduleMessage && !saveTamplate"
              @click="sendMessage"
              :disabled="!sendStatus"
              class="ui bg-secondary text-white right floated button"
              id="button-send-message-compute"
            >
              Send Message
            </button>
          </div>
          <div class="tablet only mobile only column">
            <div class="padding-md"></div>
            <button
              v-show="scheduleMessage && saveTamplate"
              @click="sendMessage"
              :disabled="!sendStatus"
              class="ui bg-accent fluid button"
              id="button-schedule-message-mobile-save"
            >
              Schedule & Save Message
            </button>
            <button
              v-show="scheduleMessage && !saveTamplate"
              @click="sendMessage"
              :disabled="!sendStatus"
              class="ui bg-accent fluid button"
              id="button-schedule-message-mobile"
            >
              Schedule Message
            </button>
            <button
              v-show="!scheduleMessage && !saveTamplate"
              @click="sendMessage"
              :disabled="!sendStatus"
              class="ui fluid button bg-secondary text-white"
              id="button-send-message-mobile"
            >
              Send Message
            </button>
            <button
              v-show="!scheduleMessage && saveTamplate"
              @click="sendMessage"
              :disabled="!sendStatus"
              class="ui fluid button bg-secondary text-white"
              id="button-send-message-mobile-save"
            >
              Send & Save Message
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
textarea {
  width: 100%;
  border: none;
  outline: none;
  font-size: 1.4em;
  &::placeholder {
    color: #ccc;
  }
}

.row {
  padding-top: 0 !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import bulkstore from "@/bulk/bulksms-state";
import Choices from "choices.js";
import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
import format from "date-fns/format";
import moment from "moment";
import Vue from "vue";
import alertify from "alertifyjs";
import _ from "lodash";
import Dropzone from "dropzone";
import VoerroTagsInput from '@voerro/vue-tagsinput';

export default {
  data() {
    return {
      scheduleMessage: false,
      sendWhatsApp: false,
      saveTamplate: false,
      contactsOptions: null,
      dateFormat: "D MMM",
      whatsAppType: "TEXT",
      composedMessage: "",
      composeTotalCharacters: 0,
      templateTitle: "",
      dateOne: "",
      dateTwo: "",
      senderId: "",
      contactListData: [],
      contactGroup: [],
      phoneGroup: [],
      numberContainer: [],
      formattedDates: "",
      schedule_time: "",
      isScheduled: 0,
      scheduledDate: "",
      totalSenderID: 0,
      uploadFiles: null,
      uploadWhatsAppFiles: null,
      dataLists: [],
      templateLists: [],
      showUploadSection: false,
      contactListBlock: null,
      contactListBlockElement: null,
      arrayList: null,
      responseStatus: false,
      responseStatusMessage: "",
      scheduledTime: "",
      placeholders: "",
      showPlaceholders: false,
      holder: "",
      contactNumber: [],
      contactList: [],
      sendCount: 0,
      MessangeStatus: false,
      StatusMessageDesc: "",
      selectTemplate: null,
      template: "",
      saveTemplateStatus: true,
      filename: "",
      endMessage: "",
      sendStatus: true,
      whatsAppKeyword: [],
      showWhatsAppOption: false,
      whatsAppLink: false,
      templateID: "",
      latitude: "",
      longitude: "",
      address: "",
      selectedTags: [
        ],
     
    };
  },
  components: {
    "tags-input": VoerroTagsInput,
  },
  beforeCreate() {
    let vm = this;
    $.post({
      url: store.state.rootUrl + "contact/get/group",
      type: "POST",
      data: JSON.stringify({
        apiKey: this.$cookies.get("key"),
        offset: 0,
        order: 10,
        limit: 2000,
      }),
      success: function (response, status, jQxhr) {
        var arrayList = [];
        var customList = [];
        var customListAdd = "";
        if (response.data.data != null) {
          var lists = response.data.data;
          var i = 0;
          lists.forEach((list) => {
           
            var listData = {
              value: response.data.data[i].contact_groupId,
              label: response.data.data[i].group_name,
              selected: false,
              disabled: false,
            };
            if (
              response.data.data[i].custom_field1 != "" &&
              response.data.data[i].custom_field1 != null
            ) {
              customListAdd = " | [" + response.data.data[i].custom_field1 + "]";
            }
            if (response.data.data[i].custom_field2 != "") {
              customListAdd += " | [" + response.data.data[i].custom_field2 + "]";
            }
            if (response.data.data[i].custom_field3 != "") {
              customListAdd += " | [" + response.data.data[i].custom_field3 + "]";
            }
            if (response.data.data[i].custom_field4 != "") {
              customListAdd += " | [" + response.data.data[i].custom_field4 + "]";
            }
            if (response.data.data[i].custom_field5 != "") {
              customListAdd += " | [" + response.data.data[i].custom_field5 + "]";
            }

            var placeHd = {
              value: response.data.data[i].contact_groupId,
              custom: customListAdd,
            };
            customList.push(placeHd);

            arrayList.push(listData);
            customListAdd = "";
            i = i + 1;
          });
          vm.contactListBlockElement = document.getElementById("contactsBlock");
          vm.contactListBlock = new Choices(vm.contactListBlockElement, {
            duplicateItemsAllowed: false,
            renderChoiceLimit: 3,
            delimiter: ",",
            removeItemButton: true,
            addItems: true,
            addChoices: true,
            choices: arrayList,
          });
          var contactNumbersArray = [];
          var contactListArray = [];
          vm.contactListBlockElement.addEventListener(
            "addItem",
            function (event) {
              vm.showPlaceholders = false;
              var data = event.detail.value;
              vm.contactListData.push(data);
              var listContactData = vm.contactListData;
              listContactData.forEach(function (element) {
                if (element.length >= 9 && element.length <= 12) {
                  vm.showPlaceholders = false;
                  contactNumbersArray.push(element);
                } else {
                  contactListArray.push(element);
                  if (vm.contactListData.length == 1) {
                    customList.forEach(function (newElemet) {
                      if (newElemet.value == element) {
                        vm.showPlaceholders = true;
                        vm.placeholders = "[full_names] [mobile] " + newElemet.custom;
                      }
                    });
                  } else {
                    vm.showPlaceholders = true;
                    vm.placeholders = "[full_names] [mobile]";
                  }
                }
              });
            },
            false
          );
          vm.contactListBlockElement.addEventListener(
            "removeItem",
            function (event) {
              let valIndex = _.indexOf(vm.contactGroup, event.detail.value);
              vm.phoneGroup = vm.contactGroup.splice(valIndex, 1);
              var listContactData = vm.contactListData;
              listContactData.forEach(function (element) {
                if (element.length >= 9 && element.length <= 12) {
                  vm.showPlaceholders = false;
                  contactNumbersArray.push(element);
                } else {
                  contactListArray.push(element);
                  console.log("Contact list lenght: " + vm.contactListData.length);

                  if (vm.contactListData.length <= 1) {
                    vm.showPlaceholders = false;
                    vm.placeholders = "";
                  } else {
                    if (vm.contactListData.length == 2) {
                      customList.forEach(function (newElemet) {
                        if (newElemet.value == element) {
                          vm.showPlaceholders = true;
                          vm.placeholders = "[full_names] [mobile]  " + newElemet.custom;
                        }
                      });
                    } else {
                      vm.showPlaceholders = true;
                      vm.placeholders = "[full_names] [mobile]";
                    }
                  }
                }
              });

              console.log("new length " + vm.contactListData.length);
            },
            false
          );
        }
      },
      error: function (jQxhr, status, error) {
        return [];
      },
    });
  },
  mounted() {
    let vm = this;
    if (!vm.$cookies.get("key") || vm.$cookies.get("key") == null) {
      vm.$router.push({
        name: "home",
      });
    }

    if (store.getters.compMessage) {
      this.composedMessage = store.getters.compMessage;
    }
    // template list
    vm.getTemplateList();
    vm.getWhatsAppKeywords();

    Dropzone.autoDiscover = false;
    vm.uploadFiles = new Dropzone("#upload-file-btn", {
      url: bulkstore.state.bulksmsUrl + "BulkSMS/bulk/upload",
      paramName: "file", // The name that will be used to transfer the file
      maxFilesize: 1,
      createImageThumbnails: false,
      acceptedFiles: ".csv",
      autoProcessQueue: false,
      params: {
        apiKey: this.$cookies.get("key"),
      },
      previewTemplate: "<div style='display: none;'></div>",
    });
    vm.uploadWhatsAppFiles = new Dropzone("#upload-file-whatsApp", {
      url: bulkstore.state.bulksmsUrl + "BulkSMS/bulk/upload",
      paramName: "file", // The name that will be used to transfer the file
      maxFilesize: 1,
      createImageThumbnails: false,
      autoProcessQueue: false,
      params: {
        apiKey: this.$cookies.get("key"),
      },
      previewTemplate: "<div style='display: none;'></div>",
    });
    vm.uploadWhatsAppFiles.on("addedfile", function (file) {
      console.log(file);
    });
    vm.uploadFiles.on("addedfile", function (file) {
      // console.log(file);
      vm.showUploadSection = true;
      vm.filename = file.name;
    });

    $(".ui.dropdown").dropdown();

    vm.fetchData();

    const datepickerOptions = {};
    Vue.use(AirbnbStyleDatepicker, datepickerOptions);

    $("#scheduleDate").calendar({
      type: "date",
    });
    $("#scheduleTime").calendar({
      type: "time",
    });
  },
  computed: {
    messageCount() {
      //var defaultCount = 14;
      var phoneNu = "";
      phoneNu = window.atob(this.$cookies.get("username"));
      var phone = "";

      if (this.dataLists.length > 0) {
        this.endMessage = " STOP*456*9*5#";
        this.composeTotalCharacters =
          this.endMessage.length + this.composedMessage.length;
      } else {
        this.endMessage = " STOP*456*9*5#";
        this.composeTotalCharacters =
          this.endMessage.length + 18 + this.composedMessage.length;
      }

      return this.composeTotalCharacters;
    },
    messagePages() {
      if (this.composedMessage.length > 0) {
        $("#textarea-sms").removeClass("red");
      }

      if (this.composeTotalCharacters == 0) {
        return 0;
      } else if (this.composeTotalCharacters / 160 <= 1) {
        return 1;
      } else if (this.composeTotalCharacters / 160 <= 2) {
        return 2;
      } else if (this.composeTotalCharacters / 160 <= 3) {
        return 3;
      } else if (this.composeTotalCharacters / 160 <= 4) {
        return 4;
      } else if (this.composeTotalCharacters / 160 <= 5) {
        return 5;
      } else {
        return " Max ";
      }
    },
    filteredItems() {
      return this.autocompleteItems.filter(i => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },
  },
  methods: {
    IncludeWhatsAppMessage() {
      let vm = this;
      if (vm.whatsAppLink == false) {
        var whatsAppPhone = vm.whatsAppKeyword[0]["msisdn"];
        var keywords = vm.whatsAppKeyword[0]["keyword"];
        vm.composedMessage = vm.composedMessage.concat(
          "https://wa.me/" + whatsAppPhone + "?text=" + keywords
        );
      } else {
        vm.composedMessage = "";
      }
    },
    closeUpload() {
      let vm = this;
      vm.showUploadSection = false;
      vm.fileToUpload = "";
      $("#droppedFiles").html();
    },
    formatDates(dateOne, dateTwo) {
      let formattedDates = "";
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat);
      }
      if (dateTwo) {
        formattedDates += " - " + format(dateTwo, this.dateFormat);
      }
      return formattedDates;
    },
    sendMessage() {
      let vm = this;
      
      if (vm.scheduleMessage) {
        $("#button-schedule-message-mobile").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Scheduling Message ...'
        );
        $("#button-schedule-message-compute-save").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Scheduling Message ...'
        );
        $("#button-schedule-message-compute").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Scheduling Message ...'
        );
        $("#button-schedule-message-mobile-save").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Scheduling Message ...'
        );
      } else {
        $("#button-send-message-mobile").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Sending Message ...'
        );
        $("#button-send-message-mobile-save").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Sending Message ...'
        );
        $("#button-send-message-compute").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Sending Message ...'
        );
        $("#button-send-message-compute-save").html(
          '<i class="fas fa-circle-notch fa-spin"></i> Sending Message ...'
        );
      }
      vm.sendStatus = false;

      if (!vm.composedMessage) {
        alertify.error("Message is required");
        vm.MessangeStatus = true;
        vm.StatusMessageDesc = "Message is required. Kindly enter Message";
      }
      if (!vm.contactListData) {
        alertify.error("Contact or select from list is required");
        vm.MessangeStatus = true;
        vm.StatusMessageDesc = "Contact or select from list is required";
      }
      var listContactData = vm.contactListData;

      if (vm.saveTamplate) {
        $.post({
          url: store.state.rootURLBulkAPI + "v3/account/templates/create",
          type: "POST",
          data: JSON.stringify({
            apiKey: this.$cookies.get("key"),
            template_title: vm.templateTitle,
            template: vm.composedMessage,
          }),
          success: function (response, status, jQxhr) {
            alertify.success("Template has been created");
            vm.hideModal();
          },
          error: function (jQxhr, status, error) {
            vm.templateListStatus = true;
            vm.createListDesc = jQxhr.responseJSON.statusDescription;
            alertify.error(jQxhr.responseJSON.statusDescription);
          },
        });
      }

      if (vm.scheduleMessage) {
        vm.isScheduled = 1;
      }
      // slice array based on size
      var contactNumbersArray = [];
      var contactListArray = [];

      if(vm.selectedTags.length > 0){
        vm.selectedTags.forEach(function (element) {
          contactNumbersArray.push(element.value);
        })
      }
     

      
      listContactData.forEach(function (element) {
        if (element.length >= 9 && element.length <= 12) {
          contactNumbersArray.push(element);
        } else {
          contactListArray.push(element);
        }
      });

      
      // phone number list
      var contactNumber = contactNumbersArray.join();
      var contactList = contactListArray.join();
      console.log("Phone numbers: "+contactNumber)

      if (contactNumber.length != "") {
        vm.composedMessage = vm.composedMessage.split("<br>").join("\n");
        $.post({
          url: bulkstore.state.bulksmsUrl + "BulkSMS/create",
          type: "POST",
          data: JSON.stringify({
            apiKey: vm.$cookies.get("key"),
            shortCode: vm.senderId,
            message: !vm.composedMessage.endsWith(vm.endMessage)
              ? vm.composedMessage + "" + vm.endMessage
              : vm.composedMessage,
            contacts: {
              recipients: contactNumber,
            },
            scheduleDate: moment(this.$refs.scheduledDate.value).format("YYYY-MM-DD"),
            scheduleTime: moment(this.$refs.scheduledTime.value, "h:mm A").format(
              "HH:mm:ss"
            ),
            isScheduled: vm.isScheduled,
            callbackURL: "",
            origin: "WEB",
            enqueue: 1,
            templateID: vm.templateID,
          }),
          success: function (data, status, jQxhr) {
            vm.composedMessage = "";
            vm.contactListData = [];
            alertify.set("notifier", "position", "top-right");
            alertify.success(data.data.message);
            vm.numberContainer = [];
            vm.$parent.showCreateMessage = false;
            if (contactList.length == "" && vm.showUploadSection == false) {
              vm.$router.push({
                name: "bulk-messages",
              });
              //console.log("change route");
            }
          },
          error: function (jQxhr, status, error) {
            //console.log("Am"+JSON.stringify(jQxhr));
            alertify.set("notifier", "position", "top-right");

            alertify.error(jQxhr.responseJSON.statusDescription);

            vm.MessangeStatus = true;
            if (jQxhr.responseJSON.data.message) {
              vm.StatusMessageDesc =
                " " +
                jQxhr.responseJSON.statusDescription +
                ":  " +
                jQxhr.responseJSON.data.message;
            } else {
              vm.StatusMessageDesc = jQxhr.responseJSON.statusDescription;
            }
            vm.getMessangeStatus();
            //console.log("failed");
          },
        });
      }
      if (contactList.length != "") {
        vm.composedMessage = vm.composedMessage.split("<br>").join("\n");
        var messageText = !vm.composedMessage.endsWith(vm.endMessage)
          ? vm.composedMessage + "" + vm.endMessage
          : vm.composedMessage;
        var messageEdited = messageText.split("\n").join("{line}");
        $.post({
          url: bulkstore.state.bulksmsUrl + "BulkSMS/group/create",
          type: "POST",
          data: JSON.stringify({
            apiKey: vm.$cookies.get("key"),
            shortCode: vm.senderId,
            message: messageEdited,
            approval: "",
            listId: contactList,
            scheduleDate: moment(this.$refs.scheduledDate.value).format("YYYY-MM-DD"),
            scheduleTime: moment(this.$refs.scheduledTime.value, "h:mm A").format(
              "HH:mm:ss"
            ),
            isScheduled: vm.isScheduled,
            callbackURL: "",
            templateID: vm.templateID,
          }),
          success: function (data, status, jQxhr) {
            vm.contactListData = [];
            vm.composedMessage = "";
            alertify.set("notifier", "position", "top-right");
            alertify.success(data.data.message);
            vm.$router.push({
              name: "bulk-messages",
            });
            vm.$parent.showCreateMessage = false;
            if (vm.showUploadSection == false) {
              vm.$router.push({
                name: "bulk-messages",
              });
            }
          },
          error: function (jQxhr, status, error) {
            alertify.set("notifier", "position", "top-right");
            vm.MessangeStatus = true;
            alertify.error(jQxhr.responseJSON.statusDescription);
            if (jQxhr.responseJSON.data.message) {
              vm.StatusMessageDesc =
                " " +
                jQxhr.responseJSON.statusDescription +
                ":  " +
                jQxhr.responseJSON.data.message;
            } else {
              vm.StatusMessageDesc = jQxhr.responseJSON.statusDescription;
            }
            vm.getMessangeStatus();

            /**
                              if(jQxhr.responseJSON.data.code == 4039){
                                vm.StatusMessageDesc = " "+jQxhr.responseJSON.statusDescription +":  "+jQxhr.responseJSON.data.message;
                                alertify.error(vm.StatusMessageDesc);
                            }
                            else{
    
                            alertify.error(jQxhr.responseJSON.statusDescription);
                            vm.StatusMessageDesc = jQxhr.responseJSON.statusDescription;
    
                            } */
          },
        });
      }
      // check if the file has been uploaded
      if (vm.showUploadSection) {
        vm.composedMessage = vm.composedMessage.split("<br>").join("\n");

        var myFile = vm.uploadFiles.files[0];

        var data = new FormData();
        data.append("apiKey", vm.$cookies.get("key"));
        data.append("shortCode", vm.senderId);
        data.append(
          "message",
          !vm.composedMessage.endsWith(vm.endMessage)
            ? vm.composedMessage + "" + vm.endMessage
            : vm.composedMessage
        );
        data.append("approval", "");
        data.append("isScheduled", vm.isScheduled);
        data.append(
          "scheduleDate",
          moment(this.$refs.scheduledDate.value).format("YYYY-MM-DD")
        );
        data.append(
          "scheduleTime",
          moment(this.$refs.scheduledTime.value, "h:mm A").format("HH:mm:ss")
        );
        data.append("callbackURL", "");
        data.append("templateID", vm.templateID);
        data.append("uploadedFile", myFile);
        $.ajax({
          url: bulkstore.state.bulksmsUrl + "BulkSMS/bulk/upload",
          type: "POST",
          data: data, //JSON.stringify({"file":myFile}),
          cache: false,
          contentType: false,
          processData: false,

          success: function (data, status, jQxhr) {
            alertify.set("notifier", "position", "top-right");
            alertify.success(data.data.message);
            vm.$router.push({
              name: "bulk-messages",
            });
            vm.$parent.showCreateMessage = false;
            //console.log("Success");
          },
          error: function (jQxhr, status, error) {
            //alertify.error("Failed to upload");
            alertify.error(jQxhr.responseJSON.statusDescription);
            if (jQxhr.responseJSON.data.message) {
              vm.StatusMessageDesc =
                " " +
                jQxhr.responseJSON.statusDescription +
                ":  " +
                jQxhr.responseJSON.data.message;
            } else {
              vm.StatusMessageDesc = jQxhr.responseJSON.statusDescription;
            }
            vm.getMessangeStatus();

            /**
                              if(jQxhr.responseJSON.data.code == 4039){
                                vm.StatusMessageDesc = " "+jQxhr.responseJSON.statusDescription +":  "+jQxhr.responseJSON.data.message;
                                alertify.error(vm.StatusMessageDesc);
                            }
                            else{
    
                            alertify.error(jQxhr.responseJSON.statusDescription);
                            vm.StatusMessageDesc = jQxhr.responseJSON.statusDescription;
    
                            } */
          },
        });
      }
      if (
        contactNumber.length == "" &&
        contactList.length == "" &&
        !vm.showUploadSection
      ) {
        vm.MessangeStatus = true;
        vm.StatusMessageDesc = "Error Contact Required: Kindly enter or Select Contact";
        alertify.error(vm.StatusMessageDesc);
        vm.getMessangeStatus();
      }
    },
    fetchData() {
      //console.log("TEST");
      // this.dataLists = [];
      var vmBalance = this;

      var vm = this;

      $.post({
        url: bulkstore.state.bulksmsUrl + "senderId/list",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
          status: 13,
          p_status: 4,
          type_id: 3,
        }),
        success: function (response, status, jQxhr) {
          vm.dataLists = response.data.data;
          console.log(JSON.stringify(vm.dataLists));
          //alertify.set('notifier', 'position', 'top-right');
          //alertify.success(response.data.message);
          //console.log("test message " + JSON.stringify(response.data.data));
        },
        error: function (jQxhr, status, error) {
          alertify.set("notifier", "position", "top-right");
          alertify.error(jQxhr.responseJSON.data.message);
          // console.log(" status bulk " + status + " data " + JSON.stringify(jQxhr.responseJSON.statusDescription));
        },
      });
    },
    getTemplateList() {
      var vm = this;
      $.post({
        url: store.state.rootURLBulkAPI + "v3/account/templates/list",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
          template_title: "",
          template: "",
          template_status: 1,
        }),
        success: function (response, status, jQxhr) {
          if (response.data.data != "") {
            vm.inboxzero = false;
            vm.templateLists = response.data.data;
          }
        },
        error: function (jQxhr, status, error) {
          vm.templateLists = [];
        },
      });
    },
    getWhatsAppKeywords() {
      let vm = this;
      $.post({
        url: store.state.rootUrl + "whatsApp/view/keywords",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
        }),
        success: function (response, status, jQxhr) {
          if (response.data.code == 200) {
            vm.showWhatsAppOption = true;
            vm.whatsAppKeyword = response.data.data;
          }
        },
        error: function (jQxhr, status, error) {},
      });
    },
    setComposeMessage(templateID) {
      let vm = this;
      $.post({
        url: store.state.rootURLBulkAPI + "v3/account/templates/list",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
          template_title: "",
          template: "",
          templateID: templateID,
          template_status: 1,
        }),
        success: function (response, status, jQxhr) {
          if (response.data.data != "") {
            vm.saveTemplateStatus = false;
            vm.composedMessage = response.data.data[0].template;
          }
        },
        error: function (jQxhr, status, error) {
          vm.saveTemplateStatus = true;
          vm.composedMessage = "";
        },
      });
    },
    getMessangeStatus() {
      let vm = this;
      if (vm.MessangeStatus == true) {
        $("#button-send-message-mobile").html("Send Message");
        $("#button-send-message-mobile-save").html("Send Message");
        $("#button-send-message-compute").html("Send Message");
        $("#button-send-message-compute-save").html("Send Message");

        $("#button-schedule-message-mobile").html("Schedule Message");
        $("#button-schedule-message-compute-save").html(
          '<i class="icon send"></i> Schedule Message'
        );
        $("#button-schedule-message-compute").html(
          '<i class="icon send"></i> Schedule Message'
        );
        $("#button-schedule-message-mobile-save").html("Schedule Message");
        vm.sendStatus = true;
      }
    },
  },

  watch: {
    contactGroup: function (val, old) {
      let vm = this;
      console.log("Val | " + val);
      vm.contactGroup = val;
    },
  },
};
</script>
<style>
/* The input */
.tags-input {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.tags-input input {
    flex: 1;
    background: transparent;
    border: none;
}

.tags-input input:focus {
    outline: none;
}

.tags-input input[type="text"] {
    color: #495057;
}

.tags-input-wrapper-default {
    padding: .5em .25em;

    background: #fff;

    border: 1px solid transparent;
    border-radius: .25em;
    border-color: #dbdbdb;
}

.tags-input-wrapper-default.active {
    border: 1px solid #8bbafe;
    box-shadow: 0 0 0 0.2em rgba(13, 110, 253, 0.25);
    outline: 0 none;
}

/* The tag badges & the remove icon */
.tags-input span {
    margin-right: 0.3em;
}

.tags-input-remove {
    cursor: pointer;
    position: absolute;
    display: inline-block;
    right: 0.3em;
    top: 0.3em;
    padding: 0.5em;
    overflow: hidden;
}

.tags-input-remove:focus {
    outline: none;
}

.tags-input-remove:before, .tags-input-remove:after {
    content: '';
    position: absolute;
    width: 75%;
    left: 0.15em;
    background: #5dc282;
    
    height: 4px;
    margin-top: -1px;
}

.tags-input-remove:before {
    transform: rotate(45deg);
}
.tags-input-remove:after {
    transform: rotate(-45deg);
}

/* Tag badge styles */
.tags-input-badge {
    position: relative;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tags-input-badge-pill {
    padding-right: 1.25em;
    padding-left: 0.6em;
    border-radius: 10em;
}
.tags-input-badge-pill.disabled {
    padding-right: 0.6em;
}

.tags-input-badge-selected-default {
    color: #212529;
    background-color: #f0f1f2;
}

/* Typeahead */
.typeahead-hide-btn {
    color: #999 !important;
    font-style: italic;
}

/* Typeahead - badges */
.typeahead-badges > span {
    margin-top: .5em;
}

.typeahead-badges > span {
    cursor: pointer;
    margin-right: 0.3em;
}

/* Typeahead - dropdown */
.typeahead-dropdown {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    z-index: 1000;
}

.typeahead-dropdown li {
    padding: .25em 1em;
    cursor: pointer;
}

/* Typeahead elements style/theme */
.tags-input-typeahead-item-default {
    color: #fff;
    background-color: #343a40;
}

.tags-input-typeahead-item-highlighted-default {
    color: #fff;
    background-color: #007bff !important;
}
</style>