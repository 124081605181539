<template>
    <div class="l-app-main">
       <div class="l-app-main-inboxzero bg-primary-lighter" v-show="inboxzero">
            <h2>Create a survey</h2>
            <p>Get feedback from your customers<br />and and improve your services</p>
            <div class="padding-sm"></div>
            <button class="ui large blue button" @click="goToCreateSurvey()">Create a survey</button>
            <div class="padding-xl"></div>
        </div>
        <div class="l-app-main-content" v-show="!inboxzero">
            <div class="ui grid">
                <div class="row">
                    <div class="column">
                        <div class="ui grid">
                            <div class="four wide column">
                                <h3>All Responses</h3>
                                <h5 class="text-lightgrey">from all surveys</h5>
                            </div>
                            <div class="twelve wide right aligned column">
                                <select class="ui dropdown" v-model="app_id" @change="onChange($event)">
                                        <option value="">All Surveys</option>
                                        <option v-for="s_id in surveyList" v-show="surveyList.length > 0 && s_id.status == 1" :key="s_id.app_id" :value="s_id.app_id">{{s_id.campaign_name}}</option>
                                    </select>
                               <div class="ui small input datepicker-trigger margin-left-xs">
                                    <input type="text" id="datepicker-trigger" placeholder="Select date" :value="formatDates(dateOne, dateTwo)">
                                    <AirbnbStyleDatepicker :trigger-element-id="'datepicker-trigger'" :mode="'range'" :fullscreen-mobile="true" :months-to-show="2" :date-one="dateOne" :date-two="dateTwo" :close-after-select="false" @date-one-selected="val => { dateOne = val }" @date-two-selected="val => { dateTwo = val }"
                                    @apply="onChange()"  />
                                </div>
                                <button class="ui icon button margin-left-md" @click="generateExport()">Export</button>
                                <button class="ui bg-primary button margin-left-md" @click="goToCreateSurvey()">Create Survey</button>
                            </div>
                        </div>
                        <table class="ui celled styled top aligned striped compact table">
                            <thead>
                                <tr>
                                    <th class="center aligned">#</th>
                                    <th>Phone</th>
                                    <th>Survey</th>
                                    <th>Question</th>
                                    <th>Answer</th>
                                    <th>Date Sent</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(list, index) in lists" :key="list.outbox_id">
                                    <td class="collapsing center aligned">{{index +1}}</td>
                                    <td>{{list.msisdn}}</td>
                                    <td>
                                        {{list.campaign_name}} <br/>
                                        <small>{{list.questionnare_name}}</small>
                                    </td>
                                    <td class="seven wide computer">{{list.question}}</td>
                                    <td>{{list.response}}</td>
                                    <td class="collapsing">{{list.created_at}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-show="!loading" @click="getAllMessage" class="ui icon large labeled button"><i class="icon refresh"></i> Load More</div>
                        <loader v-show="loading"></loader>
                    </div>
                </div>
            </div>
            
        </div>
        <createSurvey />
    </div>
</template>

<script>
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import Choices from "choices.js";
    import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
    import format from 'date-fns/format';
    import Vue from "vue";
    import alertify from 'alertifyjs';
    import loader from "@/components/loader";
    import moment from "moment";
    import createSurvey from "@/modals/survey-create"
    export default {
        data() {
            return {
                inboxzero: false,
                totalSurvey: 0,
                offset: 0,
                limit: 10,
                dateOne: null,
                dateTwo: null,
                displayRecordStatus: false,
                app_id: "",
                sort: "",
                questionnaire_id: "",
                lists: [],
                loading: false,
                dateFormat: 'D MMM',
                surveyList: []
            }
        },
        mounted() {
            $('.ui.dropdown').dropdown();
            let vm = this;
            vm.getAllMessage();
            vm.getSurvey();
        },
        components: {
            loader,
            createSurvey
        },
        methods: {
            goToCreateSurvey() {
                $("#modal-create-survey").modal({
                    centered: false
                }).modal("show");
            },
            onChange(event) {
                let vm = this;
                vm.lists = [];
                vm.offset = 0;
                vm.getAllMessage();
            },
            getAllMessage() {
                let vm = this;
                if (vm.totalSurvey == 0 || vm.totalSurvey >= ((vm.offset - 1) * vm.limit)) {
                    vm.offset++;
                    vm.loading = true;
                    $.post({
                        url: store.state.rootURLBulkAPI + "v3/survey/view/response",
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: vm.$cookies.get("key"),
                            sort: vm.sort,
                            offset: vm.offset,
                            app_id: vm.app_id,
                            questionnaire_id: vm.questionnaire_id,
                            limit: vm.limit,
                            start: vm.dateOne,
                            stop: vm.dateTwo
                        }),
                        success: function(response, status, jQxhr) {
                            vm.loading = false;
                            var resp = response.data.data;
                            if (response.data.code != 200) {
                                vm.offset = 0;
                                vm.totalRecords = [];
                                //alertify.set('notifier', 'position', 'top-right');
                                //alertify.error(response.data.message);
    
                            } else {
                                vm.totalSurvey = response.data.count;
                                if (vm.totalSurvey == 0) {
                                   
    
                                }
                                vm.displayRecordStatus = true;
                                for (var i = 0; i < resp.length; i++) {
                                    vm.lists.push(resp[i]);
                                }
    
                            }
    
                        },
                        error: function(jQxhr, status, error) {
                            vm.loading = false;
                            vm.offset = 0;
                            //alertify.set('notifier', 'position', 'top-right');
                          //  alertify.error(jQxhr.responseJSON.statusDescription);
                        }
                    });
                }
            },
            getSurvey() {
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/query",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key")
                    }),
                    success: function(response, status, jQxhr) {
                        vm.loading = false;
                        var resp = response.data.data;
                        if (response.data.code != 200) {
                             vm.inboxzero = true;
                            //alertify.set('notifier', 'position', 'top-right');
                           // alertify.error(response.data.message);
    
                        } else {
                            for (var i = 0; i < resp.length; i++) {
                                vm.surveyList.push(resp[i]);
                            }
                        }
                    },
                    error: function(jQxhr, status, error) {
                        //alertify.set('notifier', 'position', 'top-right');
                       // alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            },
            generateExport() {
                let vm = this;
                vm.uploadStatus =  true;
                $('#button-export').html('<i class="fas fa-circle-notch fa-spin"></i> Download Please Wait ...');
                var exportRecipients = [];
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/view/response",
                        type: "POST",
                        data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        sort: vm.sort,
                        app_id: vm.app_id,
                        start: vm.dateOne,
                        stop: vm.dateTwo,
                        export:1
                    }),
                    success: function(response, status, jQxhr) {
                        $('.ui.accordion').accordion('refresh');
                        for (var i = 0; i < response.data.data.length; i++) {
                            var item = {};
                            item.msisdn = response.data.data[i].msisdn;
                            item.campaign_name = response.data.data[i].campaign_name;
                            item.questionnare_name = response.data.data[i].questionnare_name;
                            item.question = response.data.data[i].question;
                            item.response = response.data.data[i].response;
                            item.objectives = response.data.data[i].objectives;
                            item.targeted_respondents = response.data.data[i].targeted_respondents;
                            item.created_at = response.data.data[i].created_at;
                            item.start_date = response.data.data[i].start_date;
                            item.stop_date = response.data.data[i].stop_date;
                            exportRecipients.push(item);
                        }
    
    
                        var exportDate = moment().format('DD_MMMM_YYYY_h:mm');
    
                        vm.JSONToCSVConvertor(exportRecipients, "Suvery Response Report _" + exportDate, 1);
                        $('#button-export').html('<i class="icon download"></i> Download');
    
                    },
                    error: function(jQxhr, status, error) {
                        vm.loading = true;
                        //console.log(jQxhr)
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                        $('#button-export').html('<i class="icon download"></i> Download');
                        //console.log("failed");
                    }
                });
            },
            JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
                //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
                var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    
                var CSV = '';
                //Set Report title in first row or line
    
                //CSV += ReportTitle + '\r\n\n';
    
                //This condition will generate the Label/Header
                if (ShowLabel) {
                    var row = "";
    
                    //This loop will extract the label from 1st index of on array
                    for (var index in arrData[0]) {
    
                        //Now convert each value to string and comma-seprated
                        row += index + ',';
                    }
    
                    row = row.slice(0, -1);
    
                    //append Label row with line break
                    CSV += row + '\r\n\n\n';
                }
    
                //1st loop is to extract each row
                for (var i = 0; i < arrData.length; i++) {
                    var row = "";
    
                    //2nd loop will extract each column and convert it in string comma-seprated
                    for (var index in arrData[i]) {
                        row += '"' + arrData[i][index] + '",';
                    }
    
                    row.slice(0, row.length - 1);
    
                    //add a line break after each row
                    CSV += row + '\r\n';
                }
    
                if (CSV == '') {
                    alert("Invalid data");
                    return;
                }
    
                //Generate a file name
                var fileName = "VasPro_";
                //this will remove the blank-spaces from the title and replace it with an underscore
                fileName += ReportTitle.replace(/ /g, "_");
    
                //Initialize file format you want csv or xls
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    
                // Now the little tricky part.
                // you can use either>> window.open(uri);
                // but this will not work in some browsers
                // or you will not get the correct file extension
    
                //this trick will generate a temp <a /> tag
                var link = document.createElement("a");
                link.href = uri;
    
                //set the visibility hidden so it will not effect on your web-layout
                link.style = "visibility:hidden";
                link.download = fileName + ".csv";
    
                //this part will append the anchor tag and remove it after automatic click
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            formatDates(dateOne, dateTwo) {
                let formattedDates = ''
                if (dateOne) {
                    formattedDates = format(dateOne, this.dateFormat)
                }
                if (dateTwo) {
                    formattedDates += ' - ' + format(dateTwo, this.dateFormat)
                }
                return formattedDates
            },
        }
    }
</script>
