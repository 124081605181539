<template>
 <div class="option">
 <select class="ui dropdown" v-model="choiceOption" @change="updateType()">
      <option :class="{'item':true, 'active':(questionTypeId == 1)}" :selected="questionTypeId == 1" value="1"><i class="orange icon edit"></i> Text</option>
        <option :class="{'item':true, 'active':(questionTypeId == 2)}" :selected="questionTypeId == 2" value="2"><i class="icon blue list"></i> Choices</option>
        <option :class="{'item':true, 'active':(questionTypeId == 3)}" :selected="questionTypeId == 3" value="3"><i class="icon teal adjust"></i> Yes/No</option>
        <option :class="{'item':true, 'active':(questionTypeId == 4)}" :selected="questionTypeId == 4" value="4"><i class="icon star"></i> Rating</option>
        <option :class="{'item':true, 'active':(questionTypeId == 5)}" :selected="questionTypeId == 5" value="5"><i class="icon check"></i> Linear</option>
    </select>

    <div class="action">
        <div class="action-text" >
            <div class="ui form">

                <div class="field" v-show="choiceOption== 1">
                    <div class="ui fluid small link icon input"><input type="text" class="bg-grey-lighter" placeholder="User will enter text" disabled />
                    <i class="icon link close" ></i>
                </div>
                </div>
                
                 <div :class="{'three':choiceOption== 5}" class="field"  v-for="(option, index) in optionDataList "  :key="option.id+index" v-show="choiceOption== 2 || choiceOption== 5">
                    <div class="ui fluid small link icon input" >
                        <div class="field">
                        <input type="text" placeholder="Option " :name="option.id" v-model="option.value"/> 
                        </div>
                        <div class="field"  v-show="choiceOption== 5">
                        <select class="ui dropdown" v-model="option.valueQuestionID">
                            <option value="">Select a Questionnaire</option>
                            <option v-for="s_id in questionnaireList" v-show="questionnaireList.length > 0 && s_id.status == 1" :key="s_id.id" :value="s_id.id">{{s_id.questionnare_name}}</option>
                            
                        </select>
                        </div>
                        <div class="field">
                    
                            <button class="ui red button small" @click="removeOption(option)"><i class="icon link close" ></i></button>
                        </div>
                    </div>
                </div>
               <div class="field" v-for="(option, index) in options"  :key="option.id+index" v-show="choiceOption== 2 || choiceOption== 5">
                    <div class="ui fluid small link icon input">
                        <input type="text" placeholder="Option " :name="option.id" v-model="option.value"/>
                        <span v-show="choiceOption== 5">
                             <select class="ui dropdown" v-model="option.valueQuestionID">
                                <option value="">Select a Questionnaire</option>
                                <option v-for="s_id in questionnaireList" v-show="questionnaireList.length > 0 && s_id.status == 1" :key="s_id.id" :value="s_id.id">{{s_id.questionnare_name}}</option>
                                
                            </select>
                        </span>
                        
                    <i class="icon link close" @click="removeOption(option)"></i>
                    </div>
                </div>
                <div class="two fields" v-show="choiceOption== 3">
                    <div class="field">
                        <div class="ui fluid small input"><input type="text" placeholder="Yes" value="Yes" v-model="trueOption" /></div>
                    </div>
                    <div class="field">
                        <div class="ui fluid small input"><input type="text" placeholder="No" value="No" v-model="falseOption" /></div>
                    </div>
                </div>
                <div class="middle aligned two fields" v-show="choiceOption== 4">
                    <div class="field">
                        <div class="ui fluid small labeled input">
                            <div class="ui label">Min. rate</div><input type="number" min="1" max="10" placeholder="1" value="1" v-model="minOption"></div>
                    </div>
                    <div class="field">
                        <div class="ui small labeled fluid input">
                            <div class="ui label">Max. rate</div><input type="number" min="1" max="10" placeholder="1" value="10" v-model="maxOption"></div>
                    </div>
                </div>
                 <div class="l-questions-add" v-if="status!= 1">
                     <div class="three fields">
                         <div class="field">
                            <div @click="addOption"  class="ui pointing dropdown l-questions-add-btn" v-show="choiceOption== 2 ||choiceOption== 5">
                                <i class="icon plus"></i> Add Option
                            </div>
                         </div>
                         <div class="field">
                            <div @click="removeQuestion" class="ui pointing dropdown float right">
                                <i class="icon red delete"></i>  Discard
                            </div>
                         </div>
                         <div class="field">
                            <div @click="saveQuestionAnswer" class="ui pointing dropdown float right">
                                <i class="icon green save"></i>  Save
                            </div>
                         </div>
                     </div>
                    
                    
                </div>
                
            </div>
        </div>
    </div>
</div>
</template>

<script>
    import store from "@/store";
    import alertify from 'alertifyjs';
    import loader from "@/components/loader";
    import format from 'date-fns/format';
    import moment from "moment";
    import { isNull } from 'util';

    export default {
        data() {
            return {
                questionnaireID: "",
                totalQuestion: 0,
                offset: 0,
                limit: 10,
                loading: false,
                questionList: [],
                questions:[],
                choiceOption:"",
                questionTypes:"",
                optionList:[],
                currentInputIndex: 0,
                inputs: [],
                norecord:false,
                optionData:"",
                optionQuestionID:null,
                optionDataList:[],
                questionnaireID:"",
                trueOption:"Yes",
                falseOption:"No",
                minOption:1,
                maxOption:10,
                status:"",
                responseOption:"",
                num:0,
                questionnaireList:[]
                
                
            }
        },
        props: ['options','questionID','questionTypeId','indexN','question'],
        components: {
            loader,
        },
        
        mounted() {
            let vm = this;
            
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            vm.questionType();
            vm.getQuestionnaire();
            
            if(isNaN(vm.questionID)){
                vm.choiceOption = 2;
                vm.norecord = true;
            }
            else{
                if(vm.options != null){
                    vm.currentInputIndex = vm.options.length;
                }
                vm.choiceOption = this.questionTypeId;
                if(vm.choiceOption == 3){
                    var num = 1;
                    console.log("Options List "+JSON.stringify(vm.options))
                    vm.options.forEach(element => {
                        if(num==1){
                            vm.trueOption = element.value;
                        }
                        else{
                            vm.falseOption = element.value
                        }
                        console.log("Number "+num);
                        num = num + 1;
                    });
                }
                if(vm.choiceOption == 4){
                    var num = 1;
                    vm.options.forEach(element => {
                        if(num==1){
                            vm.minOption = element.value;
                        }
                        else{
                            vm.maxOption = element.value
                        }
                        num = num + 1;
                    });
                }
                console.log("Test "+ JSON.stringify(vm.choiceOption));
                //console.log(JSON.stringify(vm.options));
            }
            if (vm.$route.name == "survey-create-new" || vm.$route.name == 'survey-view'){
                
                if(vm.survey ==null || vm.survey==""){
                vm.getCampaignRecords(vm.$route.params.id);
                }
                else{
                    vm.questionnaireID = store.state.selectSurvey.questionnare_id;
                    vm.status = store.state.selectSurvey.status;
                }
            }
            else if(vm.$route.name == "questionnaire-add"){
                vm.questionnaireID = vm.$route.params.id;
                console.log("Questionnaire ID Questo "+vm.$route.params.id);
            }
            else {
                vm.getCampaignRecords(vm.$route.params.id);
            }
            
           
        },
       
        methods: {
            getQuestionnaire() {
            let vm = this;
            $.post({
                url: store.state.rootURLBulkAPI + "v3/survey/view/questionaire",
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key")
                }),
                success: function(response, status, jQxhr) {
                    vm.loading = false;
                    var resp = response.data.data;
                    if (response.data.code != 200) {
                       // alertify.set('notifier', 'position', 'top-right');
                        //alertify.error(response.data.message);

                    } else {
                        for (var i = 0; i < resp.length; i++) {
                            vm.questionnaireList.push(resp[i]);
                        }
                    }
                    setTimeout(function() {
                        $(".ui.dropdown").dropdown();
                    }, 100)
                },
                error: function(jQxhr, status, error) {
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.error(jQxhr.responseJSON.statusDescription);
                }
            });
        },
             getCampaignRecords(appID){
                let vm = this
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/query",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        app_id:appID
                    }),
                    success: function(response, status, jQxhr) {
                        
                        var survey = response.data.data;
                        vm.surveyName = survey[0].campaign_name;
                        store.commit("setSurveySelect", survey[0]);
                        vm.questionnaireID = survey[0].questionnare_id;
                        vm.status = survey[0].status;

                    },
                    error: function(jQxhr, status, error) {
                        alertify.set('notifier', 'position', 'top-right');
                       // alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });

            },
           
            questionType(){
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/question/type",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                    }),
                    success: function(response, status, jQxhr) {
                        vm.questionTypes = response.data.data;
                    },
                    error: function(jQxhr, status, error) {
                        alertify.set('notifier', 'position', 'top-right');
                        //alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            },
            updateType(){
                let vm  = this;
                if(!isNaN(vm.questionID)){
                    $.post({
                        url: store.state.rootURLBulkAPI + "v3/survey/update/question",
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: vm.$cookies.get("key"),
                            question_id:vm.questionID,
                            question_typeID: vm.choiceOption
                        }),
                        success: function(response, status, jQxhr) {
                            console.log("successfull updated "+vm.choiceOption);
                        },
                        error: function(jQxhr, status, error) {
                           // alertify.set('notifier', 'position', 'top-right');
                            //alertify.error(jQxhr.responseJSON.statusDescription);
                        }
                    });
                }  
            },
            addOption(){
                this.getCampaignRecords(this.$route.params.id);
                if(isNaN(this.questionID)){
                    this.optionDataList.push({id: "N"+this.currentInputIndex, value: this.optionData, valueQuestionID: this.optionQuestionID});
                    this.currentInputIndex++;
                }
                else{
                    if(isNull(this.options)){
                        
                    this.optionDataList.push({id: "N"+this.currentInputIndex, value: "", valueQuestionID: null});
                    this.currentInputIndex++;
                    }
                    else{
                        this.options.push({id: "N"+this.options.numbering, value: "", valueQuestionID: null});
                    this.options.numbering++;
                    }
                    
                }
                
                
                
            },
            removeOption(option){
                 if(isNaN(this.questionID)){
                      console.log(JSON.stringify(this.optionDataList));
                     var index = this.optionDataList.indexOf(option); 
                    this.optionDataList.splice(index, 1);
                   
                 }
                 else{
                var index = this.options.indexOf(option);
                this.options.splice(index, 1);
                console.log(JSON.stringify(this.optionDataList));
                
                if(option){
                    $.post({
                        url: store.state.rootURLBulkAPI + "v3/survey/update/option",
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: this.$cookies.get("key"),
                            question_id:this.questionID,
                            option_id: option,
                            status: -5
                        }),
                        success: function(response, status, jQxhr) {
                            alertify.success(response.data.message);
                        },
                        error: function(jQxhr, status, error) {
                            alertify.set('notifier', 'position', 'top-right');
                            //alertify.error(jQxhr.responseJSON.statusDescription);
                        }
                    });
                }
                 }
                
            },
            removeQuestion(){
                let vm = this;
                
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/update/question",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        question_id:this.questionID,
                        status: -4
                    }),
                    success: function(response, status, jQxhr) {
                        alertify.success(response.data.message);
                        vm.$parent.questionList = [];
                        vm.$parent.offset = 0;
                        vm.$parent.questionnaireID = vm.questionnaireID;
                        vm.$parent.totalQuestion =0;
                        vm.$parent.getQuestion();
                        
                    },
                    error: function(jQxhr, status, error) {
                        vm.$parent.questionList = [];
                        vm.$parent.offset = 0;
                        vm.$parent.questionnaireID = vm.questionnaireID;
                        vm.$parent.totalQuestion =0;
                        vm.$parent.getQuestion();
                        
                        alertify.set('notifier', 'position', 'top-right');
                        //alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            },
            saveQuestionAnswer(){
                let vm = this;
                if(isNaN(this.questionID)){
                    
                    vm.createQuestion();
                }
                else{
                    
                    var elements = [];
                // update question
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/update/question",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        question_id:vm.questionID,
                        question: vm.question,
                        question_typeID: vm.choiceOption 
                    }),
                    success: function(response, status, jQxhr) {
                        //update question
                        vm.saveOptions();
                    },
                    error: function(jQxhr, status, error) {
                        alertify.set('notifier', 'position', 'top-right');
                       // alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });

                }
                

            },
            saveOptions(){
                let vm = this;
                var num = 1;
               if(vm.options == null){
                    vm.createOptions();
               }
               else{
                   vm.options.forEach(element => {
                     $.post({
                        url: store.state.rootURLBulkAPI + "v3/survey/update/option",
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: vm.$cookies.get("key"),
                            option_id:element.id,
                            option: element.value,
                            numbering: num,
                            question_id:vm.questionID,
                            questionnaireID:element.valueQuestionID,
                        }),
                        success: function(response, status, jQxhr) {
                            vm.responseOption = response.data.message;
                            
                        },
                        error: function(jQxhr, status, error) {
                            alertify.set('notifier', 'position', 'top-right');
                           // alertify.error(jQxhr.responseJSON.statusDescription);
                        }
                    });
                    num++;
                    
                    });
                    alertify.success("Successful updated");
                    //  vm.$parent.questionList = [];
                    // vm.$parent.offset = 0;
                    // vm.$parent.questionnaireID = vm.questionnaireID;
                    // vm.$parent.totalQuestion =0;
                    // vm.$parent.getQuestion();
                   // vm.$parent.getQuestion();
               }
            },
            createQuestion(){
                let vm = this;
                // update question
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/add/questions",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        questionnare_id:vm.questionnaireID,
                        question: vm.question,
                        question_typeID: vm.choiceOption 
                    }),
                    success: function(response, status, jQxhr) {
                        //update question
                        vm.questionID = response.data.data.id;
                        vm.saveOptions();
                    },
                    error: function(jQxhr, status, error) {
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            },
            async createOptions(){
                let vm = this;
                var num = 1;
                 if(vm.choiceOption == 2 || vm.choiceOption == 5){
                     vm.options = vm.optionDataList;

                 }
                 if(vm.choiceOption ==3){
                    vm.options = [{
                        "id":"1",
                        "value": vm.trueOption,
                        "valueQuestionID":null
                    },
                    {
                        "id":"N2",
                        "value": vm.falseOption,
                        "valueQuestionID":null
                    }];
                }
                if(vm.choiceOption == 4 ){
                    var count = vm.maxOption - vm.minOption;
                    vm.options = [];
                    if(count != 0){
                        for(var i= vm.minOption; i<= vm.maxOption; i++){
                            vm.options.push({"id":i,"value":i,"valueQuestionID":i})
                        }
                    }
                    else{
                        vm.options = [{
                        "id":"1",
                        "value": vm.minOption,
                        "valueQuestionID":null
                        },
                        {
                            "id":"10",
                            "value": vm.maxOption,
                            "valueQuestionID":null
                        }];
                    }
                    
                }
                var numCount = 1;
                 vm.options.forEach(element => {
                     console.log(" option count: "+ numCount);
                     
                    $.post({
                        url: store.state.rootURLBulkAPI + "v3/survey/add/option",
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: vm.$cookies.get("key"),
                            answer: element.value,
                            numbering: numCount,
                            question_id: vm.questionID,
                            questionnaireID: element.valueQuestionID,
                            appID: store.state.selectSurvey.app_id 
                        }),
                        success: function(response, status, jQxhr) {
                            alertify.success(response.data.message);
                            
                        },
                        error: function(jQxhr, status, error) {
                            alertify.set('notifier', 'position', 'top-right');
                            //alertify.error(jQxhr.responseJSON.statusDescription);
                        }
                    });
                    numCount++;
                });
                alertify.success("Successful updated");
            },
           
        },
        filters: {
            formatDate: function(value) {
                if (!value) return ''
                return moment(value).fromNow()
            }
        },
        watch: {
            num: function() {
                let vm = this
                  vm.num = val;
                  console.log("value of "+vm.num);
                  console.log("array length "+vm.options.length);
                  if(vm.num == vm.options.length){
                      //vm.checkArray();
                  }
               },
        },
    
    }
</script>
