<template>
    <div class="l-homepage">
        <div class="l-homepage-top">
            <div class="padding-sm"></div>
            <pageHeader/>
        </div>

      

    
    
        <div class="l-homepage-features">
            <div class="ui grid bg-primary-lighter">
                <div class="row">
                    <div class="center aligned column" id="products">
                        <div class="padding-lg"></div>
                        <h1 class="text-title-lead">Error <strong class="text-primary">404</strong> Page Not Found </h1>
                        <div class="padding-lg"></div>
                       
                        <div class="padding-lg"></div>
                        <router-link :to="{name: 'home'}" class="ui big red button">Go Back Home</router-link>
                        <div class="padding-lg"></div>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="l-homepage-api">
            <div class="padding-lg"></div>
            <div class="ui container grid">
                <div class="text-white row">
                    <div class="one wide computer two wide mobile column">
                        <i class="fa fa-code fa-2x"></i>
                    </div>
                    <div class="three wide computer fourteen wide mobile column">
                        <h3 class="">Use our APIs to integrate with your platform</h3>
                    </div>
                    <div class="sixteen wide mobile only column">
                        <div class="padding-top-lg"></div>
                    </div>
                    <div class="four wide computer eight wide mobile column">
                        <ul class="ui list">
                            <li>Transactional SMS sending</li>
                            <li>Bulk SMS sending</li>
                            <li>Paybill & till integration</li>
                        </ul>
                    </div>
                    <div class="four wide computer eight wide mobile column">
                        <ul class="ui list">
                            <li>Contacts management</li>
                            <li>Shortcode integrations</li>
                            <li>MPESA STK Push</li>
                        </ul>
                    </div>
                    <div class="sixteen wide mobile only column">
                        <div class="padding-lg"></div>
                    </div>
                    <div class="four wide computer sixteen wide mobile middle aligned column">
                        <router-link :to="{name: 'signup'}" class="ui big red button fluid">_Get your API key here</router-link>
                    </div>
                </div>
            </div>
            <div class="padding-lg"></div>
        </div>
    
        <pageFooter/>
    
    </div>
</template>

<script>
    import pageFooter from "@/components/page-footer";
    import pageHeader from "@/components/page-header";
    export default {
        data: function() {
            return {
            }
        },
        components: {
            pageHeader, pageFooter
        },
        methods: {
            
        }
    
    }
</script>
