<template>
    <div class="ui tiny modal" id="modal-verify-account">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="50" class="margin-bottom-md"/>
                <h2 class="padding-bottom-sm">Verify Account</h2>
                <h4>Enter the four-digit code sent to your mobile phone</h4>
            </div>
            <div class="ui centered container grid">
                <div class="column">
                    <div class="ui form">
                        <div class="text-center field padding-top-md padding-bottom-xl">
                            <div class="text-one-digit">
                                <input class="text-center" maxlength="1" />
                            </div>
                            <div class="text-one-digit">
                                <input class="text-center" maxlength="1" />
                            </div>
                            <div class="text-one-digit">
                                <input class="text-center" maxlength="1" />
                            </div>
                            <div class="text-one-digit">
                                <input class="text-center" maxlength="1" />
                            </div>
                        </div>
                        <div class="field">
                            
                        </div>
                        <div class="text-center padding-top-lg field">
                            <button class="ui very basic button" @click="hideModal()">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui cancel button" @click="hideModal()">Cancel</button>
            <button class="ui button bg-primary">Verify Account</button>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        hideModal(){
            $('.ui.modal#modal-verify-account').modal('hide');
        }
    }
}
</script>
