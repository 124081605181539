<template>
  <div class="ui tiny modal" id="modal-update-reseller">
    <i class="close icon"></i>
    <div class="scrolling content">
      <div class="text-center margin-bottom-lg">
        <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
        <h2 class="padding-bottom-sm">Edit Account White Label</h2>
        <h4>You can now change logo, System name and base color code</h4>
        <div class="ui negative message" v-show="keywordStatus">
          {{ keywordDesc }}
        </div>
      </div>
      <div class="ui form">
        <div class="field">
          <label>System Name</label>
          <div class="ui large input">
            <input type="text" placeholder="" required v-model="systemName" />
          </div>
        </div>
        <div class="field">
          <label><strong>Upload Logo </strong></label>
          <input
            accept="image/*"
            type="file"
            name="files"
            id="files"
            ref="files"
            class="inputfile inputfile-6"
            multiple
          />
        </div>
        <div class="field">
          <label><strong>Base Color Code</strong></label>
          <verte display="widget" value="#004D4C" v-model="color"></verte>
        </div>
      </div>
    </div>
    <div class="actions">
      <button class="ui cancel button" @click="hideModal()">Cancel</button>
      <button class="ui bg-primary button" @click="updateReseller()">
        Update Account
      </button>
    </div>
  </div>
</template>
<script>
//import { ColorPicker } from "vue-color-kit";
//import "vue-color-kit/dist/vue-color-kit.css";
import store from "@/store";
import alertify from "alertifyjs";
import verte from "verte";
import "verte/dist/verte.css";
export default {
  data() {
    return {
      inboxzero: false,
      keyword_title: "",
      keywordStatus: false,
      keywordDesc: "",
      responseMessage: "",
      keyword: "",
      createPrime: false,
      callbackUrl: "",
      color: "",
      suckerCanvas: null,
      suckerArea: [],
      isSucking: false,
      systemName: "",
    };
  },
  components: { verte },
  computed: {
    messageCount() {
      return this.responseMessage.length + 14;
    },
    messagePages() {
      if (this.responseMessage.length == 0) {
        return 0;
      } else if ((this.responseMessage.length + 14) / 160 <= 1) {
        return 1;
      } else if ((this.responseMessage.length + 14) / 160 <= 2) {
        return 2;
      } else if ((this.responseMessage.length + 14) / 160 <= 3) {
        return 3;
      } else if ((this.responseMessage.length + 14) / 160 <= 4) {
        return 4;
      } else if ((this.responseMessage.length + 14) / 160 <= 5) {
        return 5;
      } else {
        return " Max ";
      }
    },
  },

  methods: {
    hideModal() {
      $(".ui.modal#modal-update-reseller").modal("hide");
    },
    updateReseller() {
      let vm = this;
      var myFile = this.$refs.files.files[0];
      var data = new FormData();
      data.append("apiKey", this.$cookies.get("key"));
      data.append("baseColor", vm.color);
      data.append("system_name", vm.systemName);
      data.append("file", myFile);
      $.ajax({
        url: store.state.rootUrl + "reseller/update",
        type: "POST",
        data: data,
        cache: false,
        contentType: false,
        processData: false,
        success: function (response, status, jQxhr) {
          alertify.set("notifier", "position", "top-right");
          alertify.success(response.data.message);
          vm.hideModal();
        },
        error: function (jQxhr, status, error) {
          alertify.error(jQxhr.responseJSON.statusDescription);
        },
      });
    },
  },
};
</script>
