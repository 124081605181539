<template>
    <div class="l-app-main bg-primary-bg">
        <div class="l-app-dashboard bg-primary-lighter" v-show="!inboxzero">
            <div class="ui equal width relaxed grid padded">
                <div class="row">
                    <div class="column">
                        <h3>Transaction #123456</h3>
                        <small>Date created: 12 May 2921</small>
                    </div>
                    <div class="right aligned column">
                        <button class="ui button">Edit</button>
                        <button class="ui red button">Cancel</button>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <div class="ui segment">
                            <div class="ui grid">
                                <div class="equal width row">
                                    <div class="column">
                                        <h5 class="text-grey">Transaction amount</h5>
                                        <h3>USD 129.90</h3>
                                    </div>
                                    <div class="column">
                                        <h4 class="text-grey">Currency Rate</h4>
                                        <h3>129.90</h3>
                                    </div>
                                    <div class="column">
                                        <h4 class="text-grey">Fees</h4>
                                        <h3>USD 129.90</h3>
                                    </div>
                                    <div class="column">
                                        <h4 class="text-grey">Total Amount</h4>
                                        <h3 class="text-medium">USD 159.90</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="ui divider"></div>
                            <p>
                                <span class="margin-right-lg">Payment mode: Bank Transfer</span><span class="margin-right-lg">Payment account: 112987981</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <div class="ui top attached tabular menu">
                            <div class="active item">Mobile money</div>
                            <div class="item">Bank</div>
                            <div class="item">Cash Pickup</div>
                        </div>
                        <div class="ui bottom attached segment" style="padding-left: 0; padding-right: 0;">
                            <div class="ui grid">
                                <table class="ui basic celled top aligned table">
                                    <thead>
                                        <tr class="bg-grey-lighter">
                                            <th class="collapsing"></th>
                                            <th>Name</th>
                                            <th>Contacts</th>
                                            <th>Country</th>
                                            <th>Mode</th>
                                            <th>Network</th>
                                            <th>Account</th>
                                            <th class="right aligned">Exchange rate</th>
                                            <th class="right aligned">Fee</th>
                                            <th class="right aligned">Amount Received</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Wilson Gichuki</td>
                                            <td>+254712345678<br /><small>wilg@example.com</small></td>
                                            <td>NG</td>
                                            <td><span class="ui tiny label">CASH</span></td>
                                            <td>Cash pickup</td>
                                            <td>1234</td>
                                            <td class="right aligned">110.90</td>
                                            <td class="right aligned">USD 2.5</td>
                                            <td class="right aligned">KES 10890</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Kevin Mwando</td>
                                            <td>+254712345678<br /><small>wilg@example.com</small></td>
                                            <td>NG</td>
                                            <td><span class="ui tiny label">MOBILE</span></td>
                                            <td>MTN Togo</td>
                                            <td>254712345678</td>
                                            <td class="right aligned">110.90</td>
                                            <td class="right aligned">USD 2.5</td>
                                            <td class="right aligned">KES 10890</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Greg Amoshe</td>
                                            <td>+254712345678<br /><small>wilg@example.com</small></td>
                                            <td>NG</td>
                                            <td><span class="ui tiny label">MOBILE</span></td>
                                            <td>MTN Togo</td>
                                            <td>254712345678</td>
                                            <td class="right aligned">110.90</td>
                                            <td class="right aligned">USD 2.5</td>
                                            <td class="right aligned">KES 10890</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>James Njuguna</td>
                                            <td>+254712345678<br /><small>wilg@example.com</small></td>
                                            <td>TG</td>
                                            <td><span class="ui tiny label">MOBILE</span></td>
                                            <td>MTN Togo</td>
                                            <td>254712345678</td>
                                            <td class="right aligned">110.90</td>
                                            <td class="right aligned">USD 2.5</td>
                                            <td class="right aligned">KES 10890</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>Steve Jobs Thiga</td>
                                            <td>+254712345678<br /><small>wilg@example.com</small></td>
                                            <td>KE</td>
                                            <td><span class="ui tiny label">SWIFT</span></td>
                                            <td>KCB Kenya</td>
                                            <td>10028298819</td>
                                            <td class="right aligned">110.90</td>
                                            <td class="right aligned">USD 2.5</td>
                                            <td class="right aligned">KES 10890</td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>Wilson Gichuki</td>
                                            <td>+254712345678<br /><small>wilg@example.com</small></td>
                                            <td>TZ</td>
                                            <td><span class="ui tiny label">EFT</span></td>
                                            <td>MTN Togo</td>
                                            <td>254712345678</td>
                                            <td class="right aligned">110.90</td>
                                            <td class="right aligned">USD 2.5</td>
                                            <td class="right aligned">KES 10890</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>

export default {
    
}
</script>
