<template>
    <div class="l-app-main-section">
        <div class="ui padded relaxed equal width grid">
            <div class="middle aligned row">
                <div class="eight wide computer sixteen wide tablet sixteen wide mobile column">
                    <h3>All Transactions</h3>
                    <small>{{totalTrans}} Transactions</small>
                </div>
                
                <div class="right aligned computer only column">
                    <div class="ui small input datepicker-trigger margin-right-xs">
                    <input type="text" id="datepicker-trigger" placeholder="Select date" :value="formatDates(dateOne, dateTwo)">
                    <AirbnbStyleDatepicker :trigger-element-id="'datepicker-trigger'" :mode="'range'" 
                    :fullscreen-mobile="true" :months-to-show="2" :date-one="dateOne" :date-two="dateTwo" 
                    :close-after-select="false" @date-one-selected="val => { dateOne = val }" @date-two-selected="val => { dateTwo = val }"
                        />
                </div>
                    <button class="ui button" @click="exportData()" :disabled="uploadStatus" id="button-export" >Export all</button>
                </div>
            </div>
            <div class="computer only tablet only row">
                <div class="column">
                    <table class="ui celled styled unstackable table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Description</th>
                                <th class="computer only">Transaction ID</th>
                                <th class="right aligned">Amount</th>
                            </tr>
                        </thead>
                        <tr v-for="(transc, index) in depositDataList" :key="index">
                            <td class="collapsing center aligned">{{index+1}}</td>
                            <td>{{transc.created_at | formatDate}}</td>
                            <td>{{transc.description}}</td>
                            <td class="computer only">{{transc.transaction_id}}</td>
                            <td class="right aligned">{{transc.amount}}</td>
                        </tr>
                    </table>
                    <div v-show="!loading" @click="fetchTransaction" class="ui fluid button">Load More</div>
                    <loader v-show="loading"></loader>

                    <div class="padding-xl"></div>
                </div>
            </div>

            <div class="mobile only row bg-white border-top border-bottom border-light">
                <div class="column">
                    <table class="ui very basic unstackable table">
                        <thead>
                        <tr>
                            <th>Description</th>
                            <th class="right aligned">Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(transc, index) in depositDataList" :key="index">
                            <td>
                                <div class="border-right border-light padding-right-xs">
                                    <h4>{{transc.description}}</h4>
                                    <small class="text-grey"><span class="padding-right-md">{{transc.created_at | formatDate}}</span> <span class="padding-right-sm">#{{transc.transaction_id}}</span></small>
                                </div>
                            </td>
                            <td class="right aligned">
                                <h4>Ksh.&nbsp;{{transc.amount}}</h4>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div v-show="!loading" @click="fetchTransaction" class="ui fluid button">Load More</div>
                    <loader v-show="loading"></loader>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
    import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
    import loader from "@/components/loader";
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    import moment from "moment";
    import format from 'date-fns/format';
    export default {
        data() {
            return {
                depositDataList: [],
                limit: 10,
                offset: 0,
                loading: true,
                totalTrans:0,
                dateOne: '',
                dateTwo: '',
                dateFormat: 'D MMM',
                uploadStatus:false,
            }
        },
        components: {
            loader
        },
        mounted() {
            let vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            vm.fetchTransaction();
    
        },
    
    
        methods: {
    
            fetchTransaction() {
                let vm = this;
                if (vm.totalTrans === 0 || vm.totalTrans >= ((vm.offset - 1) * vm.limit)) {
                    vm.loading = true;
                $.post({
                    url: store.state.rootURLBulkAPI + 'v3/account/view',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        limit: vm.limit,
                        start: vm.dateOne,
                        stop: vm.dateTwo,
                        offset: vm.offset
                    }),
                    success: function(response, status, jQxhr) {
                        //console.log(response.data.records_count);
                         vm.loading = false;
                            var list_c = response.data.data.transaction_data;
                            vm.totalTrans = response.data.records_count;
                            for (var i = 0; i < list_c.length; i++) {
                                vm.depositDataList .push(list_c[i]);
                            }
                    },
                    error: function(jQxhr, status, error) {
                        vm.loading = false;
                        //console.log(" status" + status + " data " + JSON.stringify(jQxhr.responseJSON.statusDescription));
                    }
                });

                vm.offset++;

                }
            },
            exportData() {
                var vm = this;
                var exportRecipients = [];
                vm.uploadStatus =  true;
                $('#button-export').html('<i class="fas fa-circle-notch fa-spin"></i> Download Please Wait ...');
    
                // console.log("messageData "+JSON.stringify(selectedList));
                vm.token = vm.$cookies.get("key");
    
    
                $.post({
                    url: store.state.rootURLBulkAPI + 'v3/account/view',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        limit: vm.limit,
                        start: vm.dateOne,
                        stop: vm.dateTwo,
                        export:1
                    }),
                    success: function(data, status, jQxhr) {
    
                        $('.ui.accordion').accordion('refresh');
                        for (var i = 0; i < data.data.data.transaction_data.length; i++) {
                            var item = {};
                            
                            item.transaction_id = data.data.data.transaction_data[i].transaction_id;
                            item.amount = data.data.data.transaction_data[i].amount;
                            item.source = data.data.data.transaction_data[i].source;
                            item.description = data.data.data.transaction_data[i].description;
                            item.created_at = data.data.data.transaction_data[i].created_at;
    
                            exportRecipients.push(item);
                        }
    
                        var exportDate = moment().format('DD_MMMM_YYYY_h:mm');
    
                        vm.JSONToCSVConvertor(exportRecipients, "All_Transaction "+exportDate, 1);
                        $('#button-export').html('Export All');
    
                    },
                    error: function(jQxhr, status, error) {
    
                        var error = jQxhr.responseJSON.data.message;
                        if (error) {
                            alertify.error(error);
                        } else {
                            alertify.error("An error occurred please try again  ");
                        }
    
                        return;
                    }
                });
            },
            JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
                //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
                var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    
                var CSV = '';
                //Set Report title in first row or line
    
                //CSV += ReportTitle + '\r\n\n';
    
                //This condition will generate the Label/Header
                if (ShowLabel) {
                    var row = "";
    
                    //This loop will extract the label from 1st index of on array
                    for (var index in arrData[0]) {
    
                        //Now convert each value to string and comma-seprated
                        row += index + ',';
                    }
    
                    row = row.slice(0, -1);
    
                    //append Label row with line break
                    CSV += row + '\r\n\n\n';
                }
    
                //1st loop is to extract each row
                for (var i = 0; i < arrData.length; i++) {
                    var row = "";
    
                    //2nd loop will extract each column and convert it in string comma-seprated
                    for (var index in arrData[i]) {
                        row += '"' + arrData[i][index] + '",';
                    }
    
                    row.slice(0, row.length - 1);
    
                    //add a line break after each row
                    CSV += row + '\r\n';
                }
    
                if (CSV == '') {
                    alert("Invalid data");
                    return;
                }
    
                //Generate a file name
                var fileName = "VasPro_";
                //this will remove the blank-spaces from the title and replace it with an underscore
                fileName += ReportTitle.replace(/ /g, "_");
    
                //Initialize file format you want csv or xls
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    
                // Now the little tricky part.
                // you can use either>> window.open(uri);
                // but this will not work in some browsers
                // or you will not get the correct file extension
    
                //this trick will generate a temp <a /> tag
                var link = document.createElement("a");
                link.href = uri;
    
                //set the visibility hidden so it will not effect on your web-layout
                link.style = "visibility:hidden";
                link.download = fileName + ".csv";
    
                //this part will append the anchor tag and remove it after automatic click
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.uploadStatus =  false;
            },
            formatDates(dateOne, dateTwo) {
                let formattedDates = ''
                if (dateOne) {
                    formattedDates = format(dateOne, this.dateFormat)
                }
                if (dateTwo) {
                    formattedDates += ' - ' + format(dateTwo, this.dateFormat)
                }
                return formattedDates
            },
        },
        filters: {
            formatDate: function(value) {
                if (!value) return '';
                return moment(value).fromNow()
            },
        }
    }
</script>
