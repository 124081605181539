<template>
  <div class="ui tiny modal" id="modal-apply-senderid">
    <i class="close icon"></i>
    <div class="scrolling content">
      <div class="text-center margin-bottom-lg">
        <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
        <h2>Apply SenderID for {{ originalName }}</h2>
        <h4>
          Apply for a sender ID andlt your client use it to<br />send branded messages
        </h4>
      </div>
      <div class="ui negative message" v-show="applySenderIDStatus">
        {{ applySenderIDStatusDesc }}
      </div>
      <div class="ui centered container grid">
        <div class="column">
          <div class="ui form">
            <div class="field">
              <label>Name of Sender ID</label>
              <div class="ui input">
                <input type="text" placeholder="e.g. VASPRO" required v-model="sendID" />
              </div>
            </div>
            <div class="field">
              <label> Download Sample Application Letter </label>
              <router-link
                target="_blank"
                to="../../files/AuthorizationLetterSafaricom.docx"
                class="text-underline margin-right-md"
                >Safaricom Sample Letter</router-link
              >
              <router-link
                target="_blank"
                to="../../files/AuthorizationLetterAirtel.docx"
                class="text-underline"
                >Airtel Sample Letter</router-link
              >
            </div>
            <div class="padding-md"></div>
            <div class="field">
              <label>Upload Application Letter (Only PDF File)</label>
              <div class="ui file input">
                <input
                  type="file"
                  id="file"
                  accept="application/pdf"
                  ref="file"
                  v-on:change="handleFileUpload()"
                />
              </div>
            </div>
            <div class="field">
              <label>Apply Sender ID in these networks</label>
              <div class="ui checkbox margin-right-md">
                <input
                  type="checkbox"
                  v-model="network_saf"
                  @click="checkBoxNetwork(1)"
                />
                <label>Safaricom </label>
              </div>
              <div class="ui checkbox margin-right-md">
                <input
                  type="checkbox"
                  v-model="network_airtel"
                  @click="checkBoxNetwork(2)"
                />
                <label>Airtel </label>
              </div>
              <div class="ui checkbox">
                <input
                  type="checkbox"
                  v-model="network_telkom"
                  @click="checkBoxNetwork(3)"
                />
                <label>Telkom </label>
              </div>
            </div>
            <div class="padding-md"></div>
            <div class="ui equal width grid">
              <div class="column">
                <h4 class="text-medium">Cost</h4>
                <small>This is a one-time fee for the Sender ID</small>
              </div>
              <div class="right aligned column">
                <h3 class="">KES {{ dataAmount }}</h3>
              </div>
            </div>
            <div class="padding-md"></div>
            <div class="field">
              <div class="ui checkbox">
                <input type="checkbox" v-model="agreeTerms" />
                <label>I agree to the Terms and Conditions of this service</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <a class="ui button" @click="hideModal()">Cancel</a>
      <button
        class="ui bg-primary button"
        @click="applySenderID()"
        :disabled="!agreeTerms"
      >
        <i id="applyID"></i> Sender ID
      </button>
    </div>
  </div>
</template>

<script>
import loader from "@/components/loader";
import store from "@/store";
import bulkstore from "@/bulk/bulksms-state";
import alertify from "alertifyjs";
export default {
  data() {
    return {
      inboxzero: false,
      countN: 1,
      dataAmount: 0,
      sendID: "",
      network_saf: false,
      network_airtel: false,
      agreeTerms: false,
      network_telkom: false,
      uploadedfilename: null,
      dataAmountSaf: 0,
      dataAmountAirtel: 0,
      applySenderIDStatus: false,
      applySenderIDStatusDesc: "",
      dataAmountTelkom: 0,
      client_id: null,
    };
  },
  computed: {
    originalName() {
      var vm = this;
      if (store.state.selectResellerClient != "") {
        console.log(JSON.stringify(store.state.selectResellerClient));
        vm.client_id = store.state.selectResellerClient.client_id;
        return store.state.selectResellerClient.client_name;
      }
      return;
    },
  },
  mounted() {
    this.fetchData();
    let vm = this;

    //console.log("mount test");
  },
  methods: {
    checkBoxNetwork(e) {
      let vm = this;
      if (e == 1) {
        if (vm.network_saf) {
          vm.dataAmount = vm.dataAmount - vm.dataAmountSaf;
        } else {
          vm.dataAmount = vm.dataAmount + vm.dataAmountSaf;
        }
      }
      if (e == 2) {
        if (vm.network_airtel) {
          vm.dataAmount = vm.dataAmount - vm.dataAmountAirtel;
        } else {
          vm.dataAmount = vm.dataAmount + vm.dataAmountAirtel;
        }
      }
      if (e == 3) {
        if (vm.network_telkom) {
          vm.dataAmount = vm.dataAmount - vm.dataAmountTelkom;
        } else {
          vm.dataAmount = vm.dataAmount + vm.dataAmountTelkom;
        }
      }
    },
    fetchData: function () {
      //console.log("TEST");
      this.dataAmount = 0;
      let vmBalance = this;

      $.post({
        url: store.state.rootURLBulkAPI + "v3/senderId/charges",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
        }),
        success: function (response, status, jQxhr) {
          //console.log("success");
          response.data.data.forEach((element) => {
            if (element.network_id == 1) {
              vmBalance.dataAmountSaf = element.total;
              //console.log(vmBalance.dataAmountSaf);
            }
            if (element.network_id == 2) {
              vmBalance.dataAmountAirtel = element.total;
              //console.log(vmBalance.dataAmountAirtel);
            }
            if (element.network_id == 3) {
              vmBalance.dataAmountTelkom = element.total;
              //console.log(vmBalance.dataAmountTelkom);
            }
          });
        },
        error: function (jQxhr, status, error) {
          console.log(" status bulk new");
        },
      });
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },

    applySenderID() {
      let vm = this;
      vm.agreeTerms = false;
      $("#applyID").html('<i class="fas fa-circle-notch fa-spin"></i>');
      var net1 = "1";
      var net2 = "2";
      var net3 = "3";
      let network = "";
      if (vm.network_saf & vm.network_airtel & vm.network_telkom) {
        network = net1 + "," + net2 + "," + net3;
      } else if (vm.network_saf & vm.network_airtel) {
        network = net1 + "," + net2;
      } else if (vm.network_saf & vm.network_telkom) {
        network = net1 + "," + net3;
      } else if (vm.network_airtel & vm.network_telkom) {
        network = net2 + "," + net3;
      } else if (vm.network_saf) {
        network = net1;
      } else if (vm.network_airtel) {
        network = net2;
      } else if (vm.network_telkom) {
        network = net3;
      } else {
        network = "1";
      }
      var form = new FormData();
      form.append("uploadedFiles", vm.file);
      form.append("apiKey", vm.$cookies.get("key"));
      form.append("shortCode", vm.sendID);
      form.append("client_id", vm.client_id);
      form.append("admin", true);
      form.append("senderTypeId", "3");
      form.append("cost", vm.dataAmount);
      form.append("purpose", "Sending customer notifications");
      form.append("dlrCallback", "");
      form.append("networks", network);
      form.append("appCallback", "");
      $.post({
        url: store.state.rootURLBulkAPI + "v3/senderId/apply",
        type: "POST",
        data: form, //JSON.stringify({"file":myFile}),
        cache: false,
        contentType: false,
        processData: false,
        success: function (response, status, jQxhr) {
          // console.log(" status" + status + " data " + JSON.stringify(response));
          vm.agreeTerms = true;
          $("#applyID").html("");
          vm.$parent.fetchAllClients();
          alertify.success(response.data.message);
          vm.sendID = "";
          vm.network_saf = false;
          vm.network_airtel = false;
          vm.network_telkom = false;
          vm.uploadedfilename = null;
          vm.hideModal();
        },
        error: function (jQxhr, status, error) {
          vm.agreeTerms = true;
          vm.applySenderIDStatus = true;
          vm.applySenderIDStatusDesc = jQxhr.responseJSON.statusDescription;
          $("#applyID").html("");
          alertify.error("Error " + JSON.stringify(jQxhr.responseJSON.statusDescription));
        },
      }).done(function () {
        vm.$parent.fetchData();
      });
    },

    uploadedFile(e) {
      console.log(e.target.files[0]);
      this.uploadedfilename = e.target.files[0];
    },
    hideModal() {
      $(".ui.modal#modal-apply-senderid").modal("hide");
    },
  },
};
</script>
