<template>
    <div class="l-homepage">
        <div class="l-homepage-top">
            <pageHeader/>
        </div>
        <div class="l-homepage-desktop">
            <div class="l-homepage-desktop-jumbo">
                <div class="ui centered grid">
                    <div class="equal width top aligned row">
                        <div class="sixteen wide mobile only sixteen wide tablet only center aligned column">
                            <div class="padding-xl"></div>
                        </div>
                        <div class="ten wide computer only bottom aligned column l-homepage-desktop-left">
                            <div class="padding-md">
                                <h1 class="text-3x"><strong>Grow your business</strong></h1>
                                <div class="products">
                                    <span class="items green" style="">Bulk&nbsp;SMS</span>
                                    <span class="items orange" style="">Surveys</span>
                                    <span class="items red" style="">Payments</span>
                                    <span class="items teal" style="">Rewards</span>
                                </div>
                            </div>
                        </div>
                        <div class="five wide computer only column">
                            <div class=" padding-lg l-homepage-desktop-bg">
                                <div class="ui form">
                                    <div class="ui negative message" v-show="registerStatus">
                                        {{registerStatusMessage}} <router-link :to="{name: 'login'}" v-show="loginRiderrect" >Click here to login</router-link>
                                        
                                    </div>
                                    <h1>Lets grow your business</h1>
                                    <span>Already signed up? <router-link class="text-underline" :to="{name: 'login'}">Login here</router-link></span>
                                    <div class="padding-sm"></div>
                                    <div class="required field" id="input-full-name">
                                        <label>Your Name</label>
                                        <div class="ui input">
                                            <input type="text" placeholder="John Pro" required v-model="fullName" />
                                        </div>
                                    </div>
                                    <div class="required field" id="input-phone-number">
                                        <label>Phone Number</label>
                                        <div class="ui input">
                                            <input type="text" class="" placeholder="723456780" required v-model="mobile" />
                                        </div>
                                    </div>
                                    <div class="required field" id="input-company_name">
                                        <label>Company Name</label>
                                        <div class="ui input">
                                            <input type="text" placeholder="Vaspro" required v-model="companyName" />
                                        </div>
                                    </div>
                                    <div class="required field" id="input-email">
                                        <label>Email</label>
                                        <div class="ui input">
                                            <input type="email" placeholder="john@core.com" required v-model="email" />
                                        </div>
                                    </div>
                                    <div class="field"  id="input-referral-code">
                                        <label>Referral</label>
                                        <div class="ui input">
                                            <input type="text" placeholder="enter referral code" v-model="referral" />
                                        </div>
                                    </div>
                                    <div class="field">
                                        <label>By signing up, you agree to our <router-link to="" class="text-underline">Terms of Use</router-link> and Privacy Policy.</label>
                                    </div>
                                    <div class="padding-md"></div>
                                    <div class="actions">
                                        <button id="button-next" class="ui large red fluid button" @click="register" :disabled="!mobile">Signup</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="l-homepage-desktop-blocks">
                <div class="ui container">
                    <div class="ui middle aligned relaxed padded centered grid">
                        <div class="three wide right aligned column border-right">
                            <h2 class="padding-bottom-none">Our Clients</h2>
                            <a href="#" class="text-grey-light" @click="showMetaInfo()">More info</a>
                        </div>
                        <div class="ten wide column blocks-img center aligned">
                            <img src="../assets/images/companies/coca-cola@2x.png" height="30">
                            <img src="../assets/images/companies/eabl@2x.png" height="30">
                            <img src="../assets/images/companies/envirofit-logo-01.png" height="30">
                            <img src="../assets/images/companies/odibets@2x.png" height="30">
                        </div>
                        <div class="three wide column right aligned border-left">
                            <h4>Get in touch</h4>
                            <h3 class="text-title padding-bottom-none"><i class="icon phone"></i> <a href="tel:0709183222">0709 183222</a></h3>
                            <h4 class=""><i class="icon mail text-grey"></i> <a href="mailto:sales@vaspro.co" class="text-title">sales@vaspro.co</a></h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="l-homepage-jumbo-mobile">
            <div class="jumbo">
                <div class="top-header">
                    <div class="top-header-left">
                        <img src="../assets/images/logo-long@3x.png" height="40" class="logo" />
                    </div>
                    <div class="top-header-right">
                        <router-link :to="{name: 'login'}" class="ui basic inverted right floated active button" v-show="!loginStatus">Login</router-link>
                        <div class="ui basic top pointing large dropdown" v-show="loginStatus">
                            <i class="icon user circle"></i> My Account
                            <div class="right floating menu">
                                <div class="header">
                                    <h4 style="min-width: 200px; font-size:20px;">{{full_names}}</h4>
                                    <p>{{client_name }}</p>
                                </div>
                                <router-link :to="{name: 'bulk-dashboard'}" class="icon item"><i class="icon home"></i> Dashboard</router-link>
                                <router-link :to="{name: 'account-summary'}" class="icon item"><i class="icon cog"></i> Account</router-link>
                                <div class="ui divider"></div>
                                <div v-on:click="logout()" class="icon item"><i class="icon red power"></i> Logout</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <h2 class="text-2x"><strong>Grow your business</strong></h2>
                    <p>VasPro is the smart, innovative, customer engagement tool you need to take your Business to the next level.</p>
                    <div class="products">
                        <span class="items green" style="">Bulk&nbsp;SMS</span>
                        <span class="items orange" style="">Surveys</span>
                        <span class="items red" style="">Payments</span>
                        <span class="items teal" style="">Rewards</span>
                    </div>
                    <router-link :to="{name: 'signup'}" class="ui large fluid button red">Sign up now</router-link>
                    <div class="padding-sm"></div>
                </div>
            </div>
            
            <div class="blocks">
                <div class="blocks-title">
                    <h2>Our Clients</h2>
                </div>
                <div class="blocks-div">
                    <div class="blocks-e"><img src="../assets/images/companies/cocacola-bg@2x.png"></div>
                    <div class="blocks-e"><img src="../assets/images/companies/envirofit-bg@2x.png"></div>
                    <div class="blocks-e"><img src="../assets/images/companies/odibets-bg@2x.png"></div>
                    <div class="blocks-e"><img src="../assets/images/companies/eabl-bg@2x.png"></div>
                </div>
            </div>

            <div class="text-center padding-lg bg-grey-lighter">
                <h2><i class="icon comments text-grey"></i></h2>
                <h4>Get in touch</h4>
                <h2 class="text-title padding-bottom-sm"><a href="tel:0709183222">0709 183222</a></h2>
                <h4 class="text-title"><i class="icon mail text-grey"></i> <a href="mailto:sales@vaspro.co">sales@vaspro.co</a></h4>
            </div>
        </div>

        <div class="l-homepage-metainfo" :class="{'show': metaInfo}">
            <div class="ui container">
                <div class="ui divider"></div>
                <div class="ui centered grid">
                    <div class="column">
                        <h1>VasPro is a Bulk SMS/Mobile Marketing and Payments Company in Kenya</h1>
                        <p>Kenya has a population of over 55 million. The World Bank says that there are more than 1.56 million licenced businesses in Kenya as of 2019.That’s approximately 30 people on the tail of every business.</p>
                        <p>Such competition makes it hard for new and old businesses to survive past their first birthday according to the Business Daily. You can hide behind a good shop or a team of salespeople but you need a better, automated strategy to help your business generate revenue continuously.</p>
                        <p>Mobile/SMS marketing is one of the most effective ways your business can use to grow in Kenya.</p>

                        <h2>Bulk SMS in Kenya + VasPro = Growth For Your Business</h2>
                        <p>Using bulk SMS can help businesses improve their reach and revenues. Kenya has over 30 million mobile devices in circulation which means that almost everyone with buying power can send or receive a text message. Using bulk text messaging for marketing can help businesses grow their bottom line.</p>

                        <h3>Benefits of bulk sms in Kenya</h3>
                        <ul>
                            <li>There are many benefits of using bulk sms in Kenya. You can use bulk sms to send promotional SMS with coupons and discounts to your customers. This can stimulate growth in sales.</li>
                            <li>You can also use bulk sms to send two way transactional text messages to customers. This is ideal where a customer is paying for goods and services at the point of sale with MPESA or even cards.</li>
                            <li>Bulk sms can be used by Kenyan businesses to make announcements about the direction your business is taking.</li>
                            <li>You can use bulk sms to prospect for new customers if you have a list of users who might be in need of your product or services- legally.</li>
                        </ul>

                        <h2>Successful Use of Bulk SMS in KENYA</h2>
                        <p>To completely benefit from bulk sms in Kenya, you need a bulk sms services provider who has the precise ability to tap into your SMS/database resources. This is no mean feat, but at VasPro, sms marketing is what we specialize in and do. </p>
                        <p>We will help you find uses for SMS in your business and how to tap into its inner power for higher revenues. Simply put: You no longer need to worry because VasPro + Your business = More revenue + Growth.</p>

                        <h2>How VasPro does bulk sms in Kenya differently</h2>
                        <p>There are many bulk sms companies in Kenya. As a bulk sms company in Kenya, how are we better than other premiums service providers. First of all, we offer clients a tailor made solution for their specific goals, needs and expectations. We take the time to understand your business to come up with a completely unique bulk sms strategy for Kenya. </p>
                        <p>The data law Kenya just passed is very crucial to our processes. At VasPro, you will only get ethical data practices. We don’t skim your data or take part in any data handling improprieties that will violate the laws of Kenya. The account manager assigned to your business will help you turn benefit immensely from everyone in your database</p>

                        
                        <h2>And we know mobile and sms marketing in Kenya</h2>
                        <p>What makes us a great SMS marketing company in Kenya? As VasPro, we put it down to two things; getting the respect of others in the industry and getting results for our clients. When it comes to mobile marketing in Kenya, we are proud to put our clients right at the top with award winning SMS campaigns in Kenya.</p>
                        <p>The long term results of these campaigns have increased revenues tenfold for our clients such as Coca Cola, Total, Mwananchi Credit, Odibets, Bonfire Adventures, numerous saccos, dentists, etc.</p>
                        <p>Although we don’t let our success go to our heads, we hope that the mechanisms we have used to execute these immensely successful mobile marketing campaigns in Kenya have convinced you that we are a bulk sms marketing company firmly on the pulse of Kenya’s mobile marketing trends.</p>

                        <h2>FAQs</h2>
                        <h3>What makes VasPro different from other Kenya Bulk SMS companies</h3>
                        <p>The major difference between VasPro and other Kenya bulk sms companies is that we continue to evolve our methods to reflect the changing realities on the ground. Other SMS marketing companies in Kenya use obsolete tactics that are not only outdated, but also dangerous to your company such as selling data or buying databases- which come with hefty penalties and jail sentences.</p>
                        <p>Not only do we keep up with the latest mobile marketing trends, but we are also active participants. We do our own research. When we publicise our findings, they’re taken seriously by the business community. View our latest articles on <a href="https://techmoran.com/2019/05/29/kenyas-vaspro-set-to-empower-smes-and-developers-with-more-api-features/" target="_blank" class="text-underline text-link">Tech Moran</a>, Business Daily, and <a href="https://www.standardmedia.co.ke/business/article/2001325413/firm-makes-foray-into-sms-marketing" target="_blank" class="text-underline text-link">The Standard</a>.</p>
                        
                        <h3>Why are you slightly expensive than other Bulk sms companies in Kenya?</h3>
                        <p>First of all, VasPro does not cut corners. We ensure that we give you a custom solution that ensures everything meets the high standards we set. You are assigned your own account manager to ensure that you get the best results. Rather than follow the same procedure with each client, we tailor everything to your needs through our APIs. For more information, give us a call.</p>

                        <h3>How much do you charge for bulk sms services in Kenya</h3>
                        <p>Since we offer multiple services like USSD, mobile payments, app development, bulks sms, rewards and surveys, we don’t have a list of packages, However we cater for businesses of all sizes at their level of need. </p>
                        <p>However, at the basic level, getting your custom alphanumeric like “VasPro” and set up in our system will cost you KES 12,600 which includes VAT. You can top up SMS units at 1 bob each and 0.85 for units above 1 Million Kenya shillings.</p>

                        <h3>I’m Looking for a Nairobi Kenya Based Mobile and SMS marketing company, where are you based?</h3>
                        <p>If you are looking for a company that provides bulk sms services for Nairobi Kenya, it’s beneficial to choose a Kenya based SMS marketing company that is near enough to visit and has a better understanding of what’s going on in the country. We are based on the 7th Floor, Kalson Towers Nairobi Kenya. Contact us now.</p>

                        <h3>What bulk sms services do you offer in Kenya?</h3>
                        <p>Our bulk sms services are trusted by hundreds of businesses in Kenya. They have been used for a variety of situations and circumstances including but not limited to payment alerts by saccos, surveys, competitions, rewards, payments, etc. We provide these solutions:</p>
                        <ul>
                            <li>Bulk sms for schools</li>
                            <li>Bulk sms for churches</li>
                            <li>Bulk sms for colleges and universities</li>
                            <li>Bulk sms for banks and saccos</li>
                            <li>Bulk sms for dentists and clinics</li>
                            <li>Bulk sms for ecommerce stores/shops</li>
                            <li>Bulk sms and short codes for safaricom customers</li>
                            <li>Bulk sms services to travel agents</li>
                            <li>Bulk sms in Kenya for phone verifications/systems</li>
                            <li>Bulk sms API’s for developers</li>
                            <li>Bulk sms to be used with MPESA payments + more</li>
                        </ul>

                        <p><strong>Contact us for all your bulk sms needs. </strong></p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .ui.dropdown .header {
        text-transform: capitalize !important;
    }
    
    .ui.dropdown .header p {
        font-weight: 400 !important;
    }
</style>

<script>
    import store from "../store";
    import bulkstore from "@/bulk/bulksms-state";
    import pageFooter from "@/components/page-footer";
    import pageHeader from "@/components/page-header";
    import verifyAccount from "@/modals/verify-account";
    
    import alertify from 'alertifyjs';
    export default {
        data: function() {
            return {
                fullName: null,
                email: null,
                referral: null,
                mobile: null,
                companyName: "",
                registerStatus: false,
                registerStatusMessage: null,
                agreeTerms: false,
                loginRiderrect: false,
                full_names: null,
                client_name: "",
                loginStatus: false,
                metaInfo: false
            }
        },
        components: {
            verifyAccount,
            pageHeader, pageFooter,
            verifyAccount
        },
        created() {
            let vm = this;
            if(vm.$cookies.get("key") != null){
                vm.showCreateMessage = store.state.showCreateMessageContainer;
                $.post({
                    url: store.state.rootURLBulkAPI + 'v3/account/view',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                    }),
                    success: function(response, status, jQxhr) {
                        var bonus = response.data.data.bonus;
                        bonus = bonus.replace(/\D/g, '');
                        vm.full_names = response.data.data.account_name;
                        vm.client_name = response.data.data.client_name;
        
        
                        store.commit("setWalletBalance", response.data.data.balance);
                        store.commit("setAccountDetails", response.data.data);
        
                    },
                    error: function(jQxhr, status, error) {
                        //alertify.set('notifier', 'position', 'top-right');
                        //alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });

            }
            
        },
        mounted() {
            if(this.$cookies.get("key") != null){
                this.loginStatus = true;
                
            }
            else{
                this.loginStatus = false;
            }
         },
        methods: {
            showMetaInfo(){
                if(this.metaInfo == false){
                    this.metaInfo = true;
                } else if (this.metaInfo == true){
                    this.metaInfo = false;
                }
            },
            register() {
                var vmRegister = this;
                $("#input-full-name, #input-last-name, #input-email, #input-phone-number, #input-company_name").removeClass("error");
                if (!vmRegister.fullName) {
                    $("#input-full-name").addClass("error");
                    return;
                } else if (!vmRegister.email) {
                    $("#input-email").addClass("error");
                    return;
                } else if(!vmRegister.companyName){
                    $("#input-company_name").addClass("error");
                    return;
                } 
                else if (!vmRegister.mobile || vmRegister.mobile.length === 0) {
                    $("#input-phone-number").addClass("error");
                    return;
                } else if (vmRegister.mobile.length < 9 || vmRegister.mobile.length > 13) {
                    $("#input-phone-number").addClass("error");
                    return;
                }
    
                vmRegister.registerStatus = false;
                $('#button-next').html('<i class="fas fa-circle-notch fa-spin"></i>');
    
                $.post({
                    url: store.state.rootURLUser + 'users/create',
                    type: "POST",
                    data: JSON.stringify({
                        mobile_no: vmRegister.mobile,
                        email_address: vmRegister.email,
                        full_names: vmRegister.fullName,
                        referrer: vmRegister.referral,
                        client_name: vmRegister.companyName ? vmRegister.companyName : "",
                    }),
                    success: function(response, status, jQxhr) {
                        if (response.data.code != 200) {
                            vmRegister.registerStatus = true;
                            vmRegister.loginRiderrect = true;
                            vmRegister.registerStatusMessage = response.data.message;
                            $('#button-next').html('Next');
                        } else {
                            vmRegister.$cookies.set("iscalled",true,"60s");
                            vmRegister.$cookies.set("username",window.btoa(vmRegister.mobile));
                            vmRegister.$router.push({name: 'verify-account'});
                        }
                    },
                    error: function(jQxhr, status, error) {
                        var statustext = jQxhr.responseJSON.statusDescription;
                        vmRegister.registerStatus = true;
                        if (!statustext) {
                            vmRegister.registerStatusMessage = "An error occurred. Try again later.";
                        } else {
                            vmRegister.registerStatusMessage = statustext; 
                        }
                        $('#button-next').html('Next');
                    }
                });
            },
            verifcationModal() {
                $('.ui.modal#modal-verify-account').modal({
                    closable: true
                }).modal('show');
            },
            logout() {
                let vm = this;
                vm.$cookies.remove("key");
                vm.$cookies.remove("username");
                vm.$cookies.remove("iscalled");
                store.state.user = null;
                bulkstore.state.selectUser = null;
                vm.$forceUpdate();
                vm.$router.go();
                vm.$router.push({
                    name: 'home'
                });
            }
        }
    
    }
</script>
