<template>
<div class="l-app-main bg-white">
    <div class="l-app-main-inboxzero bg-primary-lighter" v-show="inboxzero">
         <h2>You dont have permision. </h2>
        <p>Kindly enable permission to proceed</p>
        <div class="padding-sm"></div>
        <button class="ui large blue button" @click="enableServices()">Enable permision</button>
        <div class="padding-xl"></div>
    </div>
    <div class="l-app-util" v-show="!inboxzero">
        <ul class="l-app-util-header hidden">
            <li v-for="(list, index) in utilities" :key="index++" :class="{'active': selectedTab == list.utility_name}"
                 @click="selectedTab = list.utility_name, selectedStatus = list.status ">
                <div>
                     <span class="i-add"><i :class="list.icon"></i></span>
                    <span class="text">{{list.utility_name}}</span>
                </div>
            </li>
        </ul>
        <div class="l-app-util-content" v-if="selectedTab == 'utilities-send-airtime'">
           <div v-show="statusConfirm">
                <div class="ui relaxed padded equal width grid margin-top-lg">
                        <div class="l-app-main-inboxzero bg-primary-lighter">
                        <h2>Sorry But Airtime is currently </h2>
                        <h2 class="text-red">Unavaliable</h2>
                    </div>
                </div>
           </div>
           <div v-show="!statusConfirm">
                <div class="l-app-util-content-l">
                    <div class="ui relaxed padded equal width grid">
                        <div class="left aligned row">
                            <div class="column">
                                <h2 class="text-title">Send Airtime <br />
                                <small class="text-grey-light">
                                    <span v-show="editState">List Name: {{listTitle}}</span>
                                    <h6 class="italic">Upload CSV file with Headers (Phone, Amount,Account)</h6></small>
                                </h2>
                            </div>
                            <div class="right aligned column">
                                <!-- <button class="ui small button">Upload File</button> -->
                                <vue-csv-import 
                                    v-model="csvAirtime" 
                                    :mapFields="['phone', 'amount','account']" class="ui form" v-if="selectedTab == 'utilities-send-airtime'">
                                
                                    
                                    <template slot="hasHeaders" slot-scope="{headers, toggle}">
                                        <label>
                                            <div class="ui toggle checkbox">
                                                <input type="checkbox" id="hasHeaders" :value="headers" @change="toggle">
                                                <label><strong>Select if the File has  Headers </strong></label><br />
                                            </div>
                                        </label>
                                    </template>
                                    <template slot="error">
                                        File type is invalid
                                    </template>
                                
                                    <template slot="thead" >
                                        <tr>
                                            <th>My Fields</th>
                                            <th>Column</th>
                                        </tr>
                                    </template>
                                
                                    <template slot="next" slot-scope="{load}">
                                        <br />
                                        <button @click.prevent="load" class="ui small button">Next!</button>
                                    </template>
                                
                                    <template slot="submit" slot-scope="{submit}">
                                        <button @click.prevent="submit" class="ui small button">Load File!</button>
                                    </template>
                                </vue-csv-import>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column"> <label> Filter: </label>
                                <select class="ui small dropdown" v-model="listLimit">
                                    <option value="">Filter</option>
                                    <option value="10">10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option :value="csvAirtime.length">All</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <table class="ui very basic table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th class="six wide computer">Phone Number</th>
                                            <th class="five wide left aligned">Acount</th>
                                            <th class="left aligned">Amount</th>
                                            <th class="right aligned"> Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(list,index) in csvAirtime" :key="index++" v-show="index < listLimit">
                                            <td>{{list.id}}</td>
                                            <td><input class="table-input" v-on:keyup="updatePhoneAirtime(list.phone,list.id)" v-model="list.phone"/></td>
                                            <td><input class="table-input text-left" v-on:keyup="updateAccountAirtime(list.account,list.id)" v-model="list.account"/></td>
                                            <td><input class="table-input text-left" v-on:keyup="updateAmountAirtime(list.amount,list.id)" v-model="list.amount"/></td>
                                            <td class="center aligned" @click="removeAirtimeList(list.id)">
                                                <div class="field">
                                                    <div class="ui pointing dropdown float right">
                                                        <i class="icon red trash"></i> 
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colspan="5" class=" text-center" v-show="csvAirtime.length < 1000">
                                                <button class="table-add-row" @click="addRow(csvAirtime.length)">Add row</button>
                                            </td>
                                            
                                        </tr>
                                        <tr>
                                            <td colspan="5" class=" text-center" v-show="csvAirtime.length > listLimit">
                                                <button class="table-add-row" @click="loadMoreRecord">Load Mode</button>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="l-app-util-content-r">
                    <div class="ui relaxed padded grid">
                        
                        <div class="row">
                            <div class="column">
                                <h3 class="text-center">Summary</h3>
                                <table class="ui celled styled table">
                                    <thead>
                                        <tr>
                                            <th>Duplicates <br /><small>*** Any duplicate will be removed ***</small></th>
                                            <th class="right aligned">{{duplicates}}</th>
                                        </tr>
                                    </thead>
                                </table>
                                <table class="ui celled styled table">
                                    <thead>
                                        <tr>
                                            <th>Total Rows</th>
                                            <th class="right aligned">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="right aligned">{{csvAirtime.length}}</td>
                                            <td class="right aligned">{{totalCashAirtime}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th class="right aligned">Subtotal</th>
                                            <th class="right aligned">KES. {{totalCashAirtime}}</th>
                                        </tr>
                                       
                                        <tr>
                                            <th class="right aligned">TOTAL COST</th>
                                            <th class="right aligned">KES. {{totalCashAirtime}}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="right aligned column">
                                <div class="ui toggle checkbox ">
                                    <label><strong>Save the List </strong></label>
                                    <input type="checkbox" v-model="savePay"> 
                                </div><br /><br />
                                <div class="field" v-show="savePay">
                                    <div class="ui small input">
                                        <input type="text" placeholder="Enter title" v-model="listTitle"> 
                                    </div><br /><br />
                                </div>
                                <button class="ui button" id="button-save-list" v-show="savePay && !editState" @click="savePayList(4)"> Save the list</button>
                                <button class="ui button" id="button-save-list" v-show="savePay && editState" @click="updatePayList(4)"> Update the list</button>
                                <button class="ui bg-accent button" v-show="!savePay" @click="payDisbursAirtime(4)"> Pay & Disburse</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="l-app-util-content" v-if="selectedTab == 'utilities-send-data'">
           <div v-show="statusConfirm">
                <div class="ui relaxed padded equal width grid margin-top-lg">
                        <div class="l-app-main-inboxzero bg-primary-lighter">
                        <h2>Sorry But Data Bundles is currently </h2>
                        <h2 class="text-red">Unavaliable</h2>
                    </div>
                </div>
           </div>
           <div v-show="!statusConfirm">
               <div class="l-app-util-content-l">
                    <div class="ui relaxed padded equal width grid">
                        <div class="left aligned row">
                            <div class="column">
                                <h2 class="text-title">Send Bundles <br />
                                <small class="text-grey-light" v-show="editState">List Name: {{listTitle}}</small>
                                <h6 class="text-grey-light">Upload CSV file with Headers (Phone, Amount,Account)</h6>
                                </h2>
                            </div>
                            <div class="right aligned column">
                                <vue-csv-import 
                                    v-model="cvsBundles" 
                                    :mapFields="['phone', 'amount','account']" class="ui form" v-if="selectedTab == 'utilities-send-data'">
                                    <template slot="hasHeaders" slot-scope="{headers, toggle}">
                                        <label>
                                            <div class="ui toggle checkbox">
                                                <input type="checkbox" id="hasHeaders" :value="headers" @change="toggle">
                                                <label><strong>Select if the File has  Headers  </strong></label><br />
                                            </div>
                                        </label>
                                    </template>
                                    <template slot="error">
                                        File type is invalid
                                    </template>
                                
                                    <template slot="thead" >
                                        <tr>
                                            <th>My Fields</th>
                                            <th>Column</th>
                                        </tr>
                                    </template>
                                
                                    <template slot="next" slot-scope="{load}">
                                        <br />
                                        <button @click.prevent="load" class="ui small button">Next!</button>
                                    </template>
                                
                                    <template slot="submit" slot-scope="{submit}">
                                        <button @click.prevent="submit" class="ui small button">Load File!</button>
                                    </template>
                                </vue-csv-import>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column"> <label> Filter: </label>
                                <select class="ui small dropdown" v-model="listLimit">
                                    <option value="">Filter</option>
                                    <option value="10">10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option :value="cvsBundles.length">All</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <table class="ui very basic table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th class="six wide computer">Phone Number</th>
                                            <th class="six wide computer">Account</th>
                                            <th class="left aligned">Amount</th>
                                            <th class="right aligned"> Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(list,index) in cvsBundles" :key="index++" v-show="index < listLimit">
                                            <td>{{list.id}}</td>
                                            <td><input class="table-input" v-on:keyup="updatePhoneBundles(list.phone,list.id)" v-model="list.phone"/></td>
                                            <td><input class="table-input text-right" v-on:keyup="updateAmountBundles(list.amount,list.id)" v-model="list.amount"/></td>
                                            <td>{{list.account}}</td>
                                            <td class="right aligned" @click="removeBundlesList(list.id)">
                                                <div class="field">
                                                    <div class="ui pointing dropdown float right">
                                                        <i class="icon red delete"></i>  Discard
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colspan="4" class=" text-center" v-show="cvsBundles.length < 1000">
                                                <button class="table-add-row" @click="addRowBundles(cvsBundles.length)">Add row</button>
                                            </td>
                                            
                                        </tr>
                                        <tr>
                                            <td colspan="4" class=" text-center" v-show="cvsBundles.length > listLimit">
                                                <button class="table-add-row" @click="loadMoreRecord">Load Mode</button>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="l-app-util-content-r">
                    <div class="ui relaxed padded grid">
                        
                        <div class="row">
                            <div class="column">
                                <h3 class="text-center">Summary</h3>
                                <table class="ui celled styled table">
                                    <thead>
                                        <tr>
                                            <th>Duplicates <br /><small>*** Any duplicate will be removed ***</small></th>
                                            <th class="right aligned">{{duplicatesBundles}}</th>
                                        </tr>
                                    </thead>
                                </table>
                                <table class="ui celled styled table">
                                    <thead>
                                        <tr>
                                            <th>Total Contacts</th>
                                            <th class="right aligned">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="right aligned">{{cvsBundles.length}}</td>
                                            <td class="right aligned">{{totalCashBundles}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th class="right aligned">Subtotal</th>
                                            <th class="right aligned">KES. {{totalCashBundles}}</th>
                                        </tr>
                                        <tr>
                                            <th class="right aligned">TOTAL COST</th>
                                            <th class="right aligned">KES. {{totalCashBundles}}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="right aligned column">
                                <div class="ui toggle checkbox ">
                                    <label><strong>Save the List </strong></label>
                                    <input type="checkbox" v-model="savePay"> 
                                </div><br /><br />
                                <div class="field" v-show="savePay">
                                    <div class="ui small input">
                                        <input type="text" placeholder="Enter title" v-model="listTitle"> 
                                    </div><br /><br />
                                </div>
                                <button class="ui button" id="button-save-list" v-show="savePay && !editState" @click="savePayList(5)"> Save the list</button>
                                <button class="ui button" id="button-save-list" v-show="savePay && editState" @click="updatePayList(5)"> Update the list</button>
                                <button class="ui bg-accent button" v-show="!savePay" @click="payDisbursAirtime(5)"> Pay & Disburse</button>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
            
        </div>
        <div class="l-app-util-content" v-if="selectedTab == 'utilities-send-kplc-pre'">
            <div v-show="statusConfirm">
                <div class="ui relaxed padded equal width grid margin-top-lg">
                        <div class="l-app-main-inboxzero bg-primary-lighter">
                        <h2>Sorry But Electricity Token(KPLC) is currently </h2>
                        <h2 class="text-red">Unavaliable</h2>
                    </div>
                </div>
           </div>
           <div v-show="!statusConfirm">
                <div class="l-app-util-content-l">
                    <div class="ui relaxed padded equal width grid">
                        <div class="left aligned row">
                            <div class="column">
                                <h2 class="text-title">Send KPLC Prepaid Tokens <br />
                                <small class="text-grey-light" v-show="editState">List Name: {{listTitle}}</small>
                                <h6 class="text-grey-light">Upload CSV file with Headers (Phone, Amount,Account)</h6>
                                </h2>
                                    
                            </div>
                            <div class="right aligned column">
                                <!-- <button class="ui small button">Upload File</button> -->
                                <vue-csv-import 
                                    v-model="cvsPrepaidToken" 
                                    :mapFields="['phone','amount','account']" class="ui form" v-if="selectedTab == 'utilities-send-kplc-pre'">
                                
                                    
                                    <template slot="hasHeaders" slot-scope="{headers, toggle}">
                                        <label>
                                            <div class="ui toggle checkbox">
                                                <input type="checkbox" id="hasHeaders" :value="headers" @change="toggle">
                                                <label><strong>Select if the File has  Headers </strong></label><br />
                                            </div>
                                        </label>
                                    </template>
                                    <template slot="error">
                                        File type is invalid
                                    </template>
                                
                                    <template slot="thead" >
                                        <tr>
                                            <th>My Fields</th>
                                            <th>Column</th>
                                        </tr>
                                    </template>
                                
                                    <template slot="next" slot-scope="{load}">
                                        <br />
                                        <button @click.prevent="load" class="ui small button">Next!</button>
                                    </template>
                                
                                    <template slot="submit" slot-scope="{submit}">
                                        <button @click.prevent="submit" class="ui small button">Load File!</button>
                                    </template>
                                </vue-csv-import>
                            </div>
                        </div>
                         <div class="row">
                            <div class="column"> <label> Filter: </label>
                                <select class="ui small dropdown" v-model="listLimit">
                                    <option value="">Filter</option>
                                    <option value="10">10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option :value="cvsPrepaidToken.length">All</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <table class="ui very basic table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th class="six wide computer">Phone Number</th>
                                            <th class=" six wide computer">Account</th>
                                            <th class="left aligned">Amount</th>
                                            <th class="right aligned"> Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(list,index) in cvsPrepaidToken" :key="index++" v-show="index < listLimit">
                                            <td>{{list.id}}</td>
                                            <td><input class="table-input" v-on:keyup="updatePhonePrepaid(list.phone,list.id)" v-model="list.phone"/></td>
                                            <td><input class="table-input text-right" v-on:keyup="updateAccountPrepaid(list.account,list.id)" v-model="list.account"/></td>
                                            <td><input class="table-input text-right" v-on:keyup="updateAmountPrepaid(list.amount,list.id)" v-model="list.amount"/></td>
                                            <td class="right aligned" @click="removePrepaidList(list.id)">
                                                <div class="field">
                                                    <div class="ui pointing dropdown float right">
                                                        <i class="icon red delete"></i>  Discard
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colspan="4" class=" text-center" v-show="cvsPrepaidToken.length < 1000">
                                                <button class="table-add-row" @click="addRowPrepaid(cvsPrepaidToken.length)">Add row</button>
                                            </td>
                                            
                                        </tr>
                                        <tr>
                                            <td colspan="4" class=" text-center" v-show="cvsPrepaidToken.length > listLimit">
                                                <button class="table-add-row" @click="loadMoreRecord">Load Mode</button>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="l-app-util-content-r">
                    <div class="ui relaxed padded grid">
                        
                        <div class="row">
                            <div class="column">
                                <h3 class="text-center">Summary</h3>
                                <table class="ui celled styled table">
                                    <thead>
                                        <tr>
                                            <th>Duplicates <br /><small>*** Any duplicate will be removed ***</small></th>
                                            <th class="right aligned">{{duplicatesPrepaid}}</th>
                                        </tr>
                                    </thead>
                                </table>
                                <table class="ui celled styled table">
                                    <thead>
                                        <tr>
                                            <th>Total Accounts</th>
                                        
                                            <th class="right aligned">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="right aligned">{{cvsPrepaidToken.length}}</td>
                                            <td class="right aligned">{{totalCashPrepaid}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th class="right aligned">Subtotal</th>
                                            <th class="right aligned">KES. {{totalCashPrepaid}}</th>
                                        </tr>
                                        <tr>
                                            <td class="right aligned">Fees</td>
                                            <td class="right aligned">KES. {{totalPrepaidFees}}</td>
                                        </tr>
                                        <tr>
                                            <td class="right aligned">Tax (16% VAT of Fee)</td>
                                            <td class="right aligned">KES. {{totalPrepaidVat}}</td>
                                        </tr>
                                        <tr>
                                            <th class="right aligned">TOTAL COST</th>
                                            <th class="right aligned">KES. {{totalPrepaidCost}}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="right aligned column">
                                <div class="ui toggle checkbox ">
                                    <label><strong>Save the List </strong></label>
                                    <input type="checkbox" v-model="savePay"> 
                                </div><br /><br />
                                <div class="field" v-show="savePay">
                                    <div class="ui small input">
                                        <input type="text" placeholder="Enter title" v-model="listTitle"> 
                                    </div><br /><br />
                                </div>
                                <button class="ui button" id="button-save-list" v-show="savePay && !editState" @click="savePayList(6)"> Save the list</button>
                                <button class="ui button" id="button-save-list" v-show="savePay && editState" @click="updatePayList(6)"> Update the list</button>
                                <button class="ui bg-accent button" v-show="!savePay" @click="payDisbursAirtime(6)"> Pay & Disburse</button>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </div>

         <div class="l-app-util-content" v-if="selectedTab == 'utilities-send-kplc-post'">
           <div v-show="statusConfirm">
                <div class="ui relaxed padded equal width grid margin-top-lg">
                        <div class="l-app-main-inboxzero bg-primary-lighter">
                        <h2>Sorry But Electricity Post-Paid (KPLC) is currently </h2>
                        <h2 class="text-red">Unavaliable</h2>
                    </div>
                </div>
           </div>
           <div v-show="!statusConfirm">
                <div class="l-app-util-content-l">
                    <div class="ui relaxed padded equal width grid">
                        <div class="left aligned row">
                            <div class="column">
                                <h2 class="text-title">Send KPLC Postpaid Tokens <br />
                                <small class="text-grey-light" v-show="editState">List Name: {{listTitle}}</small>
                                <h6 class="text-grey-light">Upload CSV file with Headers (Phone, Amount,Account)</h6>
                                </h2>
                            </div>
                            <div class="right aligned column">
                                <!-- <button class="ui small button">Upload File</button> -->
                                <vue-csv-import 
                                    v-model="cvsPostpaidToken" 
                                    :mapFields="['phone','amount','account']" class="ui form" v-if="selectedTab == 'utilities-send-kplc-post'">
                                
                                    
                                    <template slot="hasHeaders" slot-scope="{headers, toggle}">
                                        <label>
                                            <div class="ui toggle checkbox">
                                                <input type="checkbox" id="hasHeaders" :value="headers" @change="toggle">
                                                <label><strong>Select if the File has  Headers  </strong></label><br />
                                            </div>
                                        </label>
                                    </template>
                                    <template slot="error">
                                        File type is invalid
                                    </template>
                                
                                    <template slot="thead" >
                                        <tr>
                                            <th>My Fields</th>
                                            <th>Column</th>
                                        </tr>
                                    </template>
                                
                                    <template slot="next" slot-scope="{load}">
                                        <br />
                                        <button @click.prevent="load" class="ui small button">Next!</button>
                                    </template>
                                
                                    <template slot="submit" slot-scope="{submit}">
                                        <button @click.prevent="submit" class="ui small button">Load File!</button>
                                    </template>
                                </vue-csv-import>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column"> <label> Filter: </label>
                                <select class="ui small dropdown" v-model="listLimit">
                                    <option value="">Filter</option>
                                    <option value="10">10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option :value="cvsPostpaidToken.length">All</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <table class="ui very basic table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th class="six wide computer">Phone Number</th>
                                            <th class=" six wide computer">Account</th>
                                            <th class="left aligned">Amount</th>
                                            <th class="right aligned"> Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(list,index) in cvsPostpaidToken" :key="index++" v-show="index < listLimit">
                                            <td>{{list.id}}</td>
                                            <td><input class="table-input" v-on:keyup="updatePhonePostpaid(list.phone,list.id)" v-model="list.phone"/></td>
                                            <td><input class="table-input text-right" v-on:keyup="updateAccountPostpaid(list.account,list.id)" v-model="list.account"/></td>
                                            <td><input class="table-input text-right" v-on:keyup="updateAmountPostpaid(list.amount,list.id)" v-model="list.amount"/></td>
                                            <td class="right aligned" @click="removePostpaidList(list.id)">
                                                <div class="field">
                                                    <div class="ui pointing dropdown float right">
                                                        <i class="icon red delete"></i>  Discard
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colspan="4" class=" text-center" v-show="cvsPostpaidToken.length < 1000">
                                                <button class="table-add-row" @click="addRowPrepaid(cvsPostpaidToken.length)">Add row</button>
                                            </td>
                                            
                                        </tr>
                                        <tr>
                                            <td colspan="4" class=" text-center" v-show="cvsPostpaidToken.length > listLimit">
                                                <button class="table-add-row" @click="loadMoreRecord">Load Mode</button>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="l-app-util-content-r">
                    <div class="ui relaxed padded grid">
                        
                        <div class="row">
                            <div class="column">
                                <h3 class="text-center">Summary</h3>
                                <table class="ui celled styled table">
                                    <thead>
                                        <tr>
                                            <th>Duplicates <br /><small>*** Any duplicate will be removed ***</small></th>
                                            <th class="right aligned">{{duplicatesPostpaid}}</th>
                                        </tr>
                                    </thead>
                                </table>
                                <table class="ui celled styled table">
                                    <thead>
                                        <tr>
                                            <th>Total Accounts</th>
                                        
                                            <th class="right aligned">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="right aligned">{{cvsPostpaidToken.length}}</td>
                                            <td class="right aligned">{{totalCashPostpaid}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th class="right aligned">Subtotal</th>
                                            <th class="right aligned">KES. {{totalCashPostpaid}}</th>
                                        </tr>
                                        <tr>
                                            <td class="right aligned">Fees</td>
                                            <td class="right aligned">KES. {{totalPostpaidFees}}</td>
                                        </tr>
                                        <tr>
                                            <td class="right aligned">Tax (16% VAT of Fee)</td>
                                            <td class="right aligned">KES. {{totalPostpaidVat}}</td>
                                        </tr>
                                        <tr>
                                            <th class="right aligned">TOTAL COST</th>
                                            <th class="right aligned">KES. {{totalPostpaidCost}}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="right aligned column">
                                <div class="ui toggle checkbox ">
                                    <label><strong>Save the List </strong></label>
                                    <input type="checkbox" v-model="savePay"> 
                                </div><br /><br />
                                <div class="field" v-show="savePay">
                                    <div class="ui small input">
                                        <input type="text" placeholder="Enter title" v-model="listTitle"> 
                                    </div><br /><br />
                                </div>
                                <button class="ui button" id="button-save-list" v-show="savePay && !editState" @click="savePayList(7)"> Save the list</button>
                                <button class="ui button" id="button-save-list" v-show="savePay && editState" @click="updatePayList(7)"> Update the list</button>
                                <button class="ui bg-accent button" v-show="!savePay" @click="payDisbursAirtime(7)"> Pay & Disburse</button>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </div>
        
        
    </div>
    <UtilitiesPay />
</div>
</template>
<script>
import UtilitiesPay from "@/modals/utilitiesPayConfirm";
import { VueCsvImport } from 'vue-csv-import';
 import alertify from 'alertifyjs';
 import store from "@/store";
import { setTimeout } from 'timers';
export default {
    data(){
        return {
            inboxzero: false,
            selectedTab: this.$route.name,
            csvAirtime:[],
            totalCashAirtime:0,
            totalAirtimeFee:0,
            totalVATAirtime:0,
            totalAirtimeCost:0,
            cvsBundles:[],
            totalCashBundles:0,
            totalBundlesFees:0,
            totalBundlesVat:0,
            totalBundlesCost:0,
            cvsPrepaidToken:[],
            totalCashPrepaid:0,
            totalPrepaidFees:0,
            totalPrepaidVat:0,
            totalPrepaidCost:0,
            cvsPostpaidToken:[],
            totalCashPostpaid:0,
            totalPostpaidFees:0,
            totalPostpaidVat:0,
            totalPostpaidCost:0,
            utilities:[],
            savePay:false,
            listTitle:"",
            airtimeState:false,
            listLimit:10,
            duplicates:0,
            duplicatesBundles:0,
            duplicatesPrepaid:0,
            duplicatesPostpaid:0,
            renderAirtime:true,
            renderBundles:false,
            selectedStatus:1,
            statusConfirm:false,
            editState:false,
            mobile:null

        }
    },
    components: { VueCsvImport, UtilitiesPay },
    mounted(){
       
        let vm = this;
        if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
             console.log(JSON.stringify(this.selectedTab));
        this.getAllUtilities();
        $(".ui.toggle.checkbox").checkbox();
        $(".ui.dropdown").dropdown();
        if (this.$route.params.id != "" && !isNaN(this.$route.params.id)) {
            console.log("The selected list "+JSON.stringify(store.state.selectedUtilites));
            if(store.state.selectedUtilites == "" || store.state.selectedUtilites == null){
                vm.$router.push({name: 'utilities'});
            }
            vm.editState = true
           var fee = store.state.selectedUtilites.processing_fee;
           var vat = store.state.selectedUtilites.tax_amount;
           var cash = store.state.selectedUtilites.totalCost;
           var unique_id = store.state.selectedUtilites.unique_id;
           var payLoadNew = (store.state.selectedUtilites.payload).replace(/\\/g, "");
           var utilityId = store.state.selectedUtilites.utility_id;
           vm.renderComponent = false;
           vm.selectedTab = store.state.selectedUtilites.utility;
           vm.listTitle = store.state.selectedUtilites.list_name;
           //var objectH = JSON.parse((payLoadNew.replace("[", "")).replace("]", ""));
           console.log("Utitlity id "+utilityId );
           if(utilityId == 4){
                vm.totalAirtimeCost =cash;
                 vm.totalAirtimeCost = vat;
                 vm.totalAirtimeFee = fee;
                 vm.totalCashAirtime = vm.totalCashAirtime - (vm.totalAirtimeFee + vm.totalVATAirtime);
                 vm.csvAirtime = JSON.parse(payLoadNew);
                 

            }
            if(utilityId == 5){
                 vm.totalBundlesCost = cash;
                 vm.totalBundlesVat = vat
                 vm.totalBundlesFees =fee;
                 vm.totalCashBundles =  vm.totalBundlesCost - (vm.totalBundlesVat + vm.totalBundlesFees);
                 vm.cvsBundles = JSON.parse(payLoadNew);

            }
            if(utilityId == 6){

                vm.totalPrepaidCost = cash;
                vm.totalPrepaidVat = vat
                vm.totalPrepaidFees =fee;
                vm.totalCashPrepaid =  vm.totalPrepaidCost - (vm.totalPrepaidVat + vm.totalPrepaidFees);
                vm.cvsPrepaidToken = JSON.parse(payLoadNew);
            }
            if(utilityId == 7){
                
                vm.totalPostpaidCost = cash;
                vm.totalPostpaidVat = vat
                vm.totalPostpaidFees =fee;
                vm.totalCashPostpaid =  vm.totalPostpaidCost - (vm.totalPostpaidVat + vm.totalPostpaidFees);
                vm.cvsPostpaidToken = JSON.parse(payLoadNew);
            }
            $(".ui.toggle.checkbox").checkbox();
        }
    },
    watch : {
        csvAirtime:function(val) {
            let vm = this
            vm.csvAirtime = val;
            vm.totalCashAirtime = 0;
            vm.totalAirtimeFee = 0;
            vm.totalVATAirtime  = 0;
            vm.totalAirtimeCost = 0;
            for (var i = 0; i < vm.csvAirtime.length; i++) {
                
                vm.csvAirtime[i].id = i + 1;
                vm.totalCashAirtime =  vm.totalCashAirtime + parseInt(vm.csvAirtime[i].amount);
            }
            var testObject = {};
            vm.csvAirtime.map(function(item) {
                var itemPropertyName = item['phone']; 
                var listId = item['id'];
                if (itemPropertyName in testObject) {
                    vm.removeAirtimeList(listId)
                    vm.duplicates = vm.duplicates + 1;
                }
                else {
                testObject[itemPropertyName] = item;
                delete item.duplicate;
                }
            });
            console.log("Total Duplicates "+ vm.duplicates);
            console.log(JSON.stringify(this.csvAirtime));
            vm.totalAirtimeFee = parseInt(vm.totalCashAirtime  * 0.1);
            vm.totalVATAirtime =  parseInt(vm.totalAirtimeFee * 0.16);
            vm.totalAirtimeCost = vm.totalCashAirtime + vm.totalAirtimeFee + vm.totalVATAirtime;
            $(".ui.toggle.checkbox").checkbox();
            
        },
        
        cvsBundles:function(val) {
            let vm = this
            vm.cvsBundles = val;

            vm.totalCashBundles = 0;
            vm.totalBundlesFees = 0;
            vm.totalBundlesVat  = 0;
            vm.totalBundlesCost = 0;
            for (var i = 0; i < vm.cvsBundles.length; i++) {
                
                vm.cvsBundles[i].id = i + 1;
                vm.totalCashBundles =  vm.totalCashBundles + parseInt(vm.cvsBundles[i].amount);
            }
            var testObject = {};
            vm.cvsBundles.map(function(item) {
                var itemPropertyName = item['phone']; 
                var listId = item['id'];
                if (itemPropertyName in testObject) {
                    vm.removeBundlesList(listId)
                    vm.duplicatesBundles = vm.duplicatesBundles + 1;
                }
                else {
                testObject[itemPropertyName] = item;
                delete item.duplicate;
                }
            });
            console.log("Total Duplicates "+ vm.duplicatesBundles);
            console.log(JSON.stringify(this.cvsBundles));
            vm.totalBundlesFees = parseInt(vm.totalCashBundles  * 0.1);
            vm.totalBundlesVat =  parseInt(vm.totalBundlesFees * 0.16);
            vm.totalBundlesCost = vm.totalCashBundles + vm.totalBundlesFees + vm.totalBundlesVat;
            $(".ui.toggle.checkbox").checkbox();
            
        },
        cvsPrepaidToken:function(val) {
            let vm = this
            vm.cvsPrepaidToken = val;
            console.log(vm.cvsPrepaidToken);
            vm.totalCashPrepaid = 0;
            vm.totalPrepaidFees = 0;
            vm.totalPrepaidVat  = 0;
            vm.totalPrepaidCost = 0;
            for (var i = 0; i < vm.cvsPrepaidToken.length; i++) {
                
                vm.cvsPrepaidToken[i].id = i + 1;
                vm.totalCashPrepaid =  vm.totalCashPrepaid + parseInt(vm.cvsPrepaidToken[i].amount);
            }
            var testObject = {};
            vm.cvsPrepaidToken.map(function(item) {
                var itemPropertyName = item['account']; 
                var listId = item['id'];
                if (itemPropertyName in testObject) {
                    vm.removePrepaidList(listId)
                    vm.duplicatesPrepaid = vm.duplicatesPrepaid + 1;
                }
                else {
                testObject[itemPropertyName] = item;
                delete item.duplicate;
                }
            });
            console.log("Total Duplicates "+ vm.duplicatesPrepaid);
            console.log(JSON.stringify(this.cvsPrepaidToken));
            vm.totalPrepaidFees = parseInt(vm.totalCashPrepaid  * 0.1);
            vm.totalPrepaidVat =  parseInt(vm.totalPrepaidFees * 0.16);
            vm.totalPrepaidCost = vm.totalCashPrepaid + vm.totalPrepaidFees + vm.totalPrepaidVat;
            $(".ui.toggle.checkbox").checkbox();
            
        },
        cvsPostpaidToken:function(val) {
            let vm = this
            vm.cvsPostpaidToken = val;
            console.log(vm.cvsPostpaidToken);
            vm.totalCashPostpaid = 0;
            vm.totalPostpaidFees = 0;
            vm.totalPostpaidVat  = 0;
            vm.totalPostpaidCost = 0;
            for (var i = 0; i < vm.cvsPostpaidToken.length; i++) {
                
                vm.cvsPostpaidToken[i].id = i + 1;
                vm.totalCashPostpaid =  vm.totalCashPostpaid + parseInt(vm.cvsPostpaidToken[i].amount);
            }
            var testObject = {};
            vm.cvsPostpaidToken.map(function(item) {
                var itemPropertyName = item['account']; 
                var listId = item['id'];
                if (itemPropertyName in testObject) {
                    vm.removePostpaidList(listId)
                    vm.duplicatesPostpaid = vm.duplicatesPostpaid + 1;
                }
                else {
                testObject[itemPropertyName] = item;
                delete item.duplicate;
                }
            });
            console.log("Total Duplicates "+ vm.duplicatesPostpaid);
            console.log(JSON.stringify(this.cvsPostpaidToken));
            vm.totalPostpaidFees = parseInt(vm.totalCashPostpaid  * 0.1);
            vm.totalPostpaidVat =  parseInt(vm.totalPostpaidFees * 0.16);
            vm.totalPostpaidCost = vm.totalCashPostpaid + vm.totalPostpaidFees + vm.totalPostpaidVat;
            $(".ui.toggle.checkbox").checkbox();
            
        },
        selectedTab: function(val){

            let vm = this
            vm.renderComponent = false;
            vm.selectedTab =  val;
        },
        selectedStatus: function(val){
            let vm = this
            console.log("the current status "+val);
            if(val == 0){
                vm.statusConfirm = true
            }
            else{
                vm.statusConfirm = false
            }
            vm.selectedStatus = val;
            
        },

    },
    methods: {
       
        removeAirtimeList(listId){
            let vm = this;
            console.log("Id Remove "+ listId);
            for(var i = 0; i < vm.csvAirtime.length; i++) {
                if(vm.csvAirtime[i].id == listId) {
                    vm.csvAirtime.splice(i, 1);
                    break;
                }
            }
            alertify.set('notifier', 'position', 'top-right');
            alertify.success("Record "+listId+" has been removed successfully");
            
        },
        removeBundlesList(listId){
            let vm = this;
            console.log("Id Remove "+ listId);
            for(var i = 0; i < vm.cvsBundles.length; i++) {
                if(vm.cvsBundles[i].id == listId) {
                    vm.cvsBundles.splice(i, 1);
                    break;
                }
            }
            alertify.set('notifier', 'position', 'top-right');
            alertify.success("Record "+listId+" has been removed successfully");
            
        },
        removePrepaidList(listId){
            let vm = this;
            console.log("Id Remove "+ listId);
            for(var i = 0; i < vm.cvsPrepaidToken.length; i++) {
                if(vm.cvsPrepaidToken[i].id == listId) {
                    vm.cvsPrepaidToken.splice(i, 1);
                    break;
                }
            }
            alertify.set('notifier', 'position', 'top-right');
            alertify.success("Record "+listId+" has been removed successfully");
            
        },
        removePostpaidList(listId){
            let vm = this;
            console.log("Id Remove "+ listId);
            for(var i = 0; i < vm.cvsPostpaidToken.length; i++) {
                if(vm.cvsPostpaidToken[i].id == listId) {
                    vm.cvsPostpaidToken.splice(i, 1);
                    break;
                }
            }
            alertify.set('notifier', 'position', 'top-right');
            alertify.success("Record "+listId+" has been removed successfully");
            
        },
        updatePhoneAirtime(val,id){
            let vm = this
            console.log("Value: "+val+" Id: "+id);
             for(var i = 0; i < vm.csvAirtime.length; i++) {
                if(vm.csvAirtime[i].id == id) {
                    vm.csvAirtime[i].phone = val ;
                    break;
                }
            }
          //  console.log(JSON.stringify(vm.csvAirtime));

        },
        updatePhoneBundles(val,id){
            let vm = this
            console.log("Value: "+val+" Id: "+id);
             for(var i = 0; i < vm.cvsBundles.length; i++) {
                if(vm.cvsBundles[i].id == id) {
                    vm.cvsBundles[i].phone = val ;
                    break;
                }
            }
          //  console.log(JSON.stringify(vm.cvsBundles));

        },
        updatePhonePrepaid(val,id){
            let vm = this
            console.log("Value: "+val+" Id: "+id);
             for(var i = 0; i < vm.cvsPrepaidToken.length; i++) {
                if(vm.cvsPrepaidToken[i].id == id) {
                    vm.cvsPrepaidToken[i].phone = val ;
                    break;
                }
            }
          //  console.log(JSON.stringify(vm.cvsPrepaidToken));

        },
        updatePhonePostpaid(val,id){
            let vm = this
            console.log("Value: "+val+" Id: "+id);
             for(var i = 0; i < vm.cvsPostpaidToken.length; i++) {
                if(vm.cvsPostpaidToken[i].id == id) {
                    vm.cvsPostpaidToken[i].phone = val ;
                    break;
                }
            }
          //  console.log(JSON.stringify(vm.cvsPrepaidToken));

        },
        enableServices(){
            let vm = this;
                $.post({
                url: store.state.rootURLBulkAPI + 'v2/users/profile',
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key"),
                }),
                success: function(response, status, jQxhr) {
                    vm.memberPermission = response.data.data.permission_acl;
                    var user_mapId = response.data.data.user_mapId;
                    vm.updateServices(user_mapId,vm.memberPermission+":41:59:60:61:62");
                },
                error: function(jQxhr, status, error) {
                }
            });
        },
        updateServices(user_mapId,permission){
            let vm = this;
            $.post({
                url: store.state.rootURLBulkAPI + "v2/users/permission/edit",
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key"),
                    user_mapId: user_mapId,
                    permission_acl: permission
                }),
                success: function(response, status, jQxhr) {
                    if (response.data.code != 200) {
                        alertify.set("notifier", "position", "top-right");
                        alertify.error(response.data.message);
                    } else {
                        vm.getAllUtilities();
                        alertify.set("notifier", "position", "top-right");
                        alertify.success(response.data.message);
                    }
                },
                error: function(jQxhr, status, error) {
                    alertify.set("notifier", "position", "top-right");
                    alertify.error(jQxhr.responseJSON.statusDescription);
                }
            });
        },
        updateAmountAirtime(val,id){
            let vm = this
            console.log("Value: "+val+" Id: "+id);
            vm.totalCashAirtime = 0;
            vm.totalAirtimeFee = 0;
            vm.totalVATAirtime  = 0;
            vm.totalAirtimeCost = 0;
             for(var i = 0; i < vm.csvAirtime.length; i++) {
                if(vm.csvAirtime[i].id == id) {
                    vm.csvAirtime[i].amount = val ;
                }
                vm.totalCashAirtime =  vm.totalCashAirtime + parseInt(vm.csvAirtime[i].amount);
            }
            vm.totalAirtimeFee = parseInt(vm.totalCashAirtime  * 0.1);
            vm.totalVATAirtime =  parseInt(vm.totalAirtimeFee * 0.16);
            vm.totalAirtimeCost = vm.totalCashAirtime + vm.totalAirtimeFee + vm.totalVATAirtime;
            //console.log(JSON.stringify(vm.csvAirtime));

        },
       
        updateAmountBundles(val,id){
            let vm = this
            console.log("Value: "+val+" Id: "+id);
            vm.totalCashBundles = 0;
            vm.totalBundlesFees = 0;
            vm.totalBundlesVat  = 0;
            vm.totalBundlesCost = 0;
             for(var i = 0; i < vm.cvsBundles.length; i++) {
                if(vm.cvsBundles[i].id == id) {
                    vm.cvsBundles[i].amount = val ;
                }
                vm.totalCashBundles =  vm.totalCashBundles + parseInt(vm.cvsBundles[i].amount);
            }
            vm.totalBundlesFees = parseInt(vm.totalCashBundles  * 0.1);
            vm.totalBundlesVat =  parseInt(vm.totalBundlesFees * 0.16);
            vm.totalBundlesCost = vm.totalCashBundles + vm.totalBundlesFees + vm.totalBundlesVat;
            //console.log(JSON.stringify(vm.cvsBundles));

        },
        updateAmountPrepaid(val,id){
            let vm = this
            console.log("Value: "+val+" Id: "+id);
            vm.totalCashPrepaid = 0;
            vm.totalPrepaidFees = 0;
            vm.totalPrepaidVat  = 0;
            vm.totalPrepaidCost = 0;
             for(var i = 0; i < vm.cvsPrepaidToken.length; i++) {
                if(vm.cvsPrepaidToken[i].id == id) {
                    vm.cvsPrepaidToken[i].amount = val ;
                }
                vm.totalCashPrepaid =  vm.totalCashPrepaid + parseInt(vm.cvsPrepaidToken[i].amount);
            }
            vm.totalPrepaidFees = parseInt(vm.totalCashPrepaid  * 0.1);
            vm.totalPrepaidVat =  parseInt(vm.totalPrepaidFees * 0.16);
            vm.totalPrepaidCost = vm.totalCashPrepaid + vm.totalPrepaidFees + vm.totalPrepaidVat;
            //console.log(JSON.stringify(vm.cvsPrepaidToken));

        },
        updateAmountPostpaid(val,id){
            let vm = this
            console.log("Value: "+val+" Id: "+id);
            vm.totalCashPostpaid = 0;
            vm.totalPostpaidFees = 0;
            vm.totalPostpaidVat  = 0;
            vm.totalPostpaidCost = 0;
             for(var i = 0; i < vm.cvsPostpaidToken.length; i++) {
                if(vm.cvsPostpaidToken[i].id == id) {
                    vm.cvsPostpaidToken[i].amount = val ;
                }
                vm.totalCashPostpaid =  vm.totalCashPostpaid + parseInt(vm.cvsPostpaidToken[i].amount);
            }
            vm.totalPostpaidFees = parseInt(vm.totalCashPostpaid  * 0.1);
            vm.totalPostpaidVat =  parseInt(vm.totalPostpaidFees * 0.16);
            vm.totalPostpaidCost = vm.totalCashPostpaid + vm.totalPostpaidFees + vm.totalPostpaidVat;
            //console.log(JSON.stringify(vm.cvsPrepaidToken));

        },
        updateAccountAirtime(val,id){
            let vm = this
             for(var i = 0; i < vm.csvAirtime.length; i++) {
                if(vm.csvAirtime[i].id == id) {
                    vm.csvAirtime[i].account = val ;
                    break;
                }
            }
        },
        updateAccountPrepaid(val,id){
            let vm = this
            console.log("Value: "+val+" Id: "+id);
             for(var i = 0; i < vm.cvsPrepaidToken.length; i++) {
                if(vm.cvsPrepaidToken[i].id == id) {
                    vm.cvsPrepaidToken[i].account = val ;
                    break;
                }
            }
        },
        updateAccountPostpaid(val,id){
            let vm = this
            console.log("Value: "+val+" Id: "+id);
             for(var i = 0; i < vm.cvsPostpaidToken.length; i++) {
                if(vm.cvsPostpaidToken[i].id == id) {
                    vm.cvsPostpaidToken[i].account = val ;
                    break;
                }
            }
        },
        addRow(id){
            console.log("The id "+id)
            this.csvAirtime.push({id: id, amount: 0,phone: ""});
        },
        addRowBundles(id){
            console.log("The id "+id)
            this.cvsBundles.push({id: id, amount: 0,phone: ""});
        },
        addRowPrepaid(id){
            console.log("The id "+id)
            this.cvsPrepaidToken.push({id: id, amount: 0, account:"", phone: ""});
        },
        loadMoreRecord(){
            let vm = this;
            vm.listLimit = vm.listLimit + 10;

        },
        getAllUtilities(){
            let vm = this;
            vm.inboxzero = false;
            $.post({
                url: store.state.rootURLBulkAPI + "v3/rewards/view",
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key"),

                }),
                success: function(response, status, jQxhr) {
                    vm.loading = false;
                    var les = response.data.data;
                    if(les.length == 0){
                        vm.inboxzero = true;
                    }
                    for(var i=0; i< les.length; i++){
                        
                        vm.utilities.push(les[i]);
                    }
                    setTimeout(function(){
                        $(".l-app-util-content").css({top: $(".l-app-util-header").outerHeight()});
                    }, 300);
                },
                error: function(jQxhr, status, error) {
                    vm.inboxzero = true;
                } 
            });
        },
        savePayList(utilityId){
            let vm = this;
            
            var payLoadNew =[];
            var cash = 0;
            var vat = 0;
            var fee = 0;

            if(utilityId == 4){
                payLoadNew = vm.csvAirtime;
                cash = vm.totalCashAirtime;
                vat = vm.totalVATAirtime
                fee = vm.totalAirtimeFee;

            }
            if(utilityId == 5){
                payLoadNew = vm.cvsBundles;
                cash = vm.totalCashBundles;
                vat = vm.totalBundlesVat
                fee = vm.totalBundlesFees;
            }
            if(utilityId == 6){
                payLoadNew = vm.cvsPrepaidToken;
                cash = vm.totalCashPrepaid;
                vat = vm.totalPrepaidVat
                fee = vm.totalPrepaidFees;
            }
            if(utilityId == 7){
                payLoadNew = vm.cvsPostpaidToken;
                cash = vm.totalCashPostpaid;
                vat = vm.totalPostpaidVat
                fee = vm.totalPostpaidFees;
            }

            if(payLoadNew.length == 0){
                alertify.set('notifier', 'position', 'top-right');
                alertify.error("Your list can not be empty!");
            }
            if(vm.listTitle == ""){
                alertify.set('notifier', 'position', 'top-right');
                alertify.error("Your list title cannot be empty");
            }
            if(payLoadNew.length > 1000){
                alertify.set('notifier', 'position', 'top-right');
                alertify.error("Your list can not be more than 1000 records");
            }
            else{
                $('#button-save-list').html('<i class="fas fa-circle-notch fa-spin"></i> Saving List ...');
                var uniqueId = Math.floor(Math.random() * 1000000) + 9000000
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/rewards/save",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        listName: vm.listTitle,
                        payLoad: payLoadNew,
                        utilityId: utilityId,
                        amount: cash,
                        totalContact: payLoadNew.length,
                        VAT:vat,
                        fee:fee,
                        uniqueId: uniqueId
                    }),
                    success: function(response, status, jQxhr) {
                        vm.loading = false;
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.success(response.data.message);
                        vm.$router.push({name: 'utilities'});
                        $('#button-save-list').html('Save the List');
                        setTimeout(function(){
                            $(".l-app-util-content").css({top: $(".l-app-util-header").outerHeight()});
                        }, 300);
                    },
                    error: function(jQxhr, status, error) {
                        $('#button-save-list').html('Save the List');
                        alertify.set('notifier', 'position', 'top-right');
                         alertify.error(jQxhr.responseJSON.statusDescription);
                    } 
                });
            }
            
        },
        payDisbursAirtime(utilityId){
            let vm = this;
             var payLoadNew =[];
            var cash = 0;
            var vat = 0;
            var fee = 0;
            if(utilityId == 4){
                payLoadNew = vm.csvAirtime;
                cash = vm.totalCashAirtime;
                vat = vm.totalVATAirtime
                fee = vm.totalAirtimeFee;

            }
            if(utilityId == 5){
                payLoadNew = vm.cvsBundles;
                cash = vm.totalCashBundles;
                vat = vm.totalBundlesVat
                fee = vm.totalBundlesFees;
            }
            if(utilityId == 6){
                payLoadNew = vm.cvsPrepaidToken;
                cash = vm.totalCashPrepaid;
                vat = vm.totalPrepaidVat
                fee = vm.totalPrepaidFees;
            }
            if(utilityId == 7){
                payLoadNew = vm.cvsPostpaidToken;
                cash = vm.totalCashPostpaid;
                vat = vm.totalPostpaidVat
                fee = vm.totalPostpaidFees;
            }
             var uniqueId = Math.floor(Math.random() * 1000000) + 90000000000;
             $.post({
                url: store.state.rootURLBulkAPI + "v3/rewards/bulk/",
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key"),
                    unique_id: uniqueId,
                    utilityID:utilityId,
                    payLoad: payLoadNew,
                    amount:cash,
                    VAT:0,
                    fee:0,
                }),
                success: function(response, status, jQxhr) {
                    vm.loading = false;
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.success(response.data.message);
                    vm.$router.push({name: 'utilities-transactions'});
                    
                },
                error: function(jQxhr, status, error) {
                    vm.airtimeState = false;
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.error(jQxhr.responseJSON.statusDescription);
                } 
            });
        },
        updatePayList(utilityId){
            let vm = this;
            
            var payLoadNew =[];
            var cash = 0;
            var vat = 0;
            var fee = 0;

            if(utilityId == 4){
                payLoadNew = vm.csvAirtime;
                cash = vm.totalCashAirtime;
                vat = vm.totalVATAirtime
                fee = vm.totalAirtimeFee;

            }
            if(utilityId == 5){
                payLoadNew = vm.cvsBundles;
                cash = vm.totalCashBundles;
                vat = vm.totalBundlesVat
                fee = vm.totalBundlesFees;
            }
            if(utilityId == 6){
                payLoadNew = vm.cvsPrepaidToken;
                cash = vm.totalCashPrepaid;
                vat = vm.totalPrepaidVat
                fee = vm.totalPrepaidFees;
            }
            if(utilityId == 7){
                payLoadNew = vm.cvsPostpaidToken;
                cash = vm.totalCashPostpaid;
                vat = vm.totalPostpaidVat
                fee = vm.totalPostpaidFees;
            }

            if(payLoadNew.length == 0){
                alertify.set('notifier', 'position', 'top-right');
                alertify.error("Your list can not be empty!");
            }
            if(vm.listTitle == ""){
                alertify.set('notifier', 'position', 'top-right');
                alertify.error("Your list title cannot be empty");
            }
            if(payLoadNew.length > 1000){
                alertify.set('notifier', 'position', 'top-right');
                alertify.error("Your list can not be more than 1000 records");
            }
            else{
                $('#button-save-list').html('<i class="fas fa-circle-notch fa-spin"></i> Saving List ...');
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/rewards/update",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        listName: vm.listTitle,
                        payLoad: payLoadNew,
                        utilityId:utilityId,
                        amount:cash,
                        totalContact:payLoadNew.length,
                        VAT:0,
                        fee:0,
                        saveId:vm.$route.params.id
                    }),
                    success: function(response, status, jQxhr) {
                        vm.loading = false;
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.success(response.data.message);
                        vm.$router.push({name: 'utilities'});
                        $('#button-save-list').html('Save the List');
                        setTimeout(function(){
                            $(".l-app-util-content").css({top: $(".l-app-util-header").outerHeight()});
                        }, 300);
                    },
                    error: function(jQxhr, status, error) {
                        $('#button-save-list').html('Save the List');
                        alertify.set('notifier', 'position', 'top-right');
                         alertify.error(jQxhr.responseJSON.statusDescription);
                    } 
                });
            }
        }
    }
}
</script>