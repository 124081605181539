<template>
    <div class="ui tiny modal" id="modal-create-template">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-botto  lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md">
                <h2 class="padding-bottom-sm">Create Template</h2>
                <h4>You can create template message here</h4>
                <div class="ui negative message" v-show="templateListStatus">
                    {{createListDesc}}
                </div>
            </div>
            <div class="ui centered container grid">
                <div class="column">
                    <div class="ui form">
                         <div class="required field" id="input-list-name">
                            <div class="field">
                                <label>Template Title</label>
                                <div class="ui large input">
                                    <input type="text" placeholder="e.g. Template title" required v-model="template_title">
                                </div>
                            </div>
                         </div>
                         <div class="required field" id="input-description-name">
                            <div class="field">
                                <label>Template</label>
                                <div class="ui large input">
                                   <textarea class="l-type" placeholder="Type your SMS here" v-model="template"></textarea>
                                </div>
                            </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui cancel button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary button" @click="createTemplate()">Create Template</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import alertify from "alertifyjs";
    import bulkstore from "@/bulk/bulksms-state";
    export default {
        data() {
            return {
                inboxzero: false,
                template_title: "",
                template: "",
                templateListStatus:false,
                createListDesc: ""
                
            };
        },
        methods: {
            hideModal() {
                $(".ui.modal#modal-create-template").modal("hide");
            },
            createTemplate() {
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/account/templates/create",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        template_title:vm.template_title,
                        template:vm.template,
                    }),
                    success: function(response, status, jQxhr) {
                        alertify.success("Template has been created");
                        vm.hideModal();
                    },
                    error: function(jQxhr, status, error) {
                        vm.templateListStatus = true;
                        vm.createListDesc = jQxhr.responseJSON.statusDescription;
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            }
        }
    };
</script>
