<template>
    <div class="l-app-main-section-container two">
        <div class="ui relaxed padded grid">
            <div class="middle aligned row">
                <div class="six wide column">
                    <h2 class="text-green text-medium">{{group_name}}</h2>
                    <h5 class="text-lightgrey">Updated: {{updated_at}} </h5>
                    <h6 class="text-lightgrey">Description: {{description}} </h6>
                </div>
                
                <div class="ten wide right aligned column">
                    <button class="ui small button" id="contacts-upload-file-btn" :disabled="uploadStatus==1">Upload  Contacts File</button>
                    <button class="ui small button" @click="showContactListSendMessage()">Send Message</button>
                    <div class="ui small icon floating dropdown button">
                        <i class="icon dropdown"></i>
                        <div class="ui menu">
                            <a class="item" @click="editContactList()">Edit Contact List</a>
                            <a href="../../files/uploadFileSMS.csv" class="item">Download Sample CSV</a>
                            <a class="item" @click="exportData(selectedList)">Export Contacts</a>
                            <a class="item" @click="deleteContactList()">Delete List</a>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-show="showUploadSection">
                <div class="center aligned column">
                    <div class="ui negative message" v-show="UploadStatus">
                        {{UploadStatusDesc}}  
                        
                    </div>
                    <div class="ui secondary center aligned segment">
                        <div class="ui top aligned grid">
                            <div class="twelve wide left aligned column" id="droppedFiles">
                                <div class='ui right labeled icon label'>{{filename}}<i class='icon close i-file' @click='closeUpload()'></i></div>
                            </div>
                            <div class="four wide right aligned column">
                                <button class="ui grey button" @click="uploadContacts">Upload</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-show="uploadStatus==1">
                <div class="column">
                    <div class="ui orange fluid message">Processing file</div>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <div class="ui top attached segment">
                        <h2> {{total}} <small class="text-lightgrey text-normal">contacts</small></h2>
                    </div>
                    <div class="ui bottom attached secondary segment">
                        <div class="ui equal width grid">
                            <div class="column border-right">
                                <h4 class="text-medium">{{saf}}</h4>
                                <h5>Safaricom</h5>
                            </div>
                            <div class="column border-right">
                                <h4 class="text-medium">{{airtel}}</h4>
                                <h5>Airtel</h5>
                            </div>
                            <div class="column border-right">
                                <h4 class="text-medium">{{telcom}}</h4>
                                <h5>Telkom</h5>
                            </div>
                             <div class="column border-right">
                                <h4 class="text-medium">{{mtnUg}}</h4>
                                <h5>MTN UG</h5>
                            </div>
                             <div class="column border-right">
                                <h4 class="text-medium">{{mtnRWD}}</h4>
                                <h5>MTN RWD</h5>
                            </div>
                            <div class="column border-right">
                                <h4 class="text-medium">{{mtnRWD}}</h4>
                                <h5>MTN RWD</h5>
                            </div>
                            <div class="column">
                                <h4 class="text-medium">{{somalia}}</h4>
                                <h5>Somalia</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <div class="padding-top-md"></div>
                    <div class="ui equal width middle aligned grid">
                        <div class="column">
                            <h3 class="text-medium">{{total}} Contacts</h3>
                        </div>
                        <div class="right aligned column">
                            <div class="ui search link small input">
                                <input type="text" placeholder="Search Phone Number" v-on:keyup="setListValue(selectedList,false)" v-model="mobile" />
                            </div>
                        </div>
                    </div>
                    <table class="ui styled celled top attached table">
                        <thead>
                            <tr>
                                <th>Phone Number</th>
                                <th>Names</th>
                                <th v-show="custom_field2">{{custom_field2}}</th>
                                <th v-show="custom_field3">{{custom_field3}}</th>
                                <th v-show="custom_field4">{{custom_field4}}</th>
                                <th v-show="custom_field5">{{custom_field5}}</th>
                                <th class="hidden">Tags</th>
                                <th class="center aligned" colspan="3"><i class="fas fa-ellipsis-h"></i></th>
   
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="tr-input">
                                <td class="table-input"><input placeholder="add phone" required v-model="new_mobile"></td>
                                <td class="table-input"><input placeholder="add full name" v-model="profile_custom1"></td>
                                <td class="table-input" v-show="custom_field2"><input placeholder="" v-model="profile_custom2"></td>
                                <td class="table-input" v-show="custom_field3"><input placeholder="" v-model="profile_custom3"></td>
                                <td class="table-input" v-show="custom_field4"><input placeholder="" v-model="profile_custom4"></td>
                                <td class="table-input" v-show="custom_field5"><input placeholder="" v-model="profile_custom5"></td>
                                <td class="table-input hidden"><input placeholder="add tags" v-model="profileTags"></td>
                                <td class="td-input-btn center aligned collapsing" colspan="3"><button class="ui green button" @click="addContactToList()">add</button></td>
                                
                            </tr>
                            <tr v-for="contact in contactList" :key="contact.contactsID">
                                <td>{{contact.mobile}}</td>
                                <td>{{contact.custom1}}</td>
                                <td v-show="custom_field2">{{contact.custom2}}</td>
                                <td v-show="custom_field3">{{contact.custom3}}</td>
                                <td v-show="custom_field4">{{contact.custom4}}</td>
                                <td v-show="custom_field5">{{contact.custom5}}</td>
                                <td class="hidden">{{contact.tagsNew}}</td>
                                <td>
                                    <button v-show="contact.contact_status==1" class="ui icon mini button" @click="muteContact(contact.mobile,0)">Mute</button>
                                    <button v-show="contact.contact_status==0" class="ui icon basic mini button" @click="muteContact(contact.mobile,1)">Unmute</button>
                                    
                                </td>
                                <td class="center aligned collapsing">
                                    <button class="ui icon primary mini button" @click="editContact(contact,custom_field2,custom_field3,custom_field4,custom_field5)">Edit</button>
                                </td>
                                <td class="center aligned collapsing">
                                    <button class="ui icon red mini button" @click="muteContact(contact.mobile,-5)">Delete</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-show="!loading" @click="setListValue(selectedList,true)" class="ui fluid small bottom attached button">Load More</div>
                </div>
            </div>
        </div>
        <loader v-show="loading"></loader>
        <ContactListSendMessage />
        <EditContact />
        <EditList />
        <DeleteList />
        <ContactsUpload/>
    </div>

</template>

<script>
    import loader from "@/components/loader";
    import EditList from "@/modals/contact-list-edit";
    import EditContact from "@/modals/contact_edit";
    import DeleteList from "@/modals/contact-list-delete";
    import ContactsUpload from "@/modals/contacts-upload";
    import ContactListSendMessage from "@/modals/contact-list-message";
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    import Dropzone from "dropzone";
    import moment from "moment";
    
    
    
    export default {
        components: {
            EditList,
            ContactsUpload,
            ContactListSendMessage,
            loader,
            DeleteList,
            EditContact
        },
        data() {
            return {
                listData: [],
                client_id: "",
                list_id: "",
                token: "",
                list_name: "",
                status: "",
                limit: 10,
                offset: 0,
                order: "",
                contactList: [],
                selectedList: [],
                totalContacts: '',
                loading: true,
    
                custom_field1: '',
                custom_field2: '',
                custom_field3: '',
                custom_field4: '',
                custom_field5: '',
    
                profile_custom1: '',
                profile_custom2: '',
                profile_custom3: '',
                profile_custom4: '',
                profile_custom5: '',
                new_mobile: '',
                mobile: '',
                profileTags: '',
                customTags: '',
                showUploadSection: false,
                uploadFiles: null,
                processingUploadFile: false,
                showContactMessageModal: false,
                UploadStatus:false,
                UploadStatusDesc:"",
                group_name:"",
                updated_at:"",
                description:"",
                uploadStatus:"",
                total:0,
                saf:0,
                airtel:0,
                telcom:0,
                mtnUg:0,
                mtnRWD:0,
                somalia:0,
                filename:""
            }
        },
        computed: {
            maker() {
                var maker = false;
                var checker = false;
                var permisions = store.state.user.clients[0].permission_acl;
                if (permisions.indexOf('[1,') !== -1 || permisions.indexOf(',1,') !== -1 || permisions.indexOf(',1]') !== -1) {
                    maker = true;
                }
    
                if (permisions.indexOf('[2,') !== -1 || permisions.indexOf(',2,') !== -1 || permisions.indexOf(',2]') !== -1) {
                    checker = true;
                }
    
                if (maker && checker) {
                    return 3;
                } else if (maker && !checker) {
                    return 1;
                } else if (!maker && checker) {
                    return 2;
                } else {
                    return 0;
                }
            },
        },
        mounted() {
    
            let vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            
            if (vm.$route.params.id != "" && !isNaN(vm.$route.params.id)) {
                   
                var listid = vm.$route.params.id;
                vm.getDetails(listid);

            }
            
            

            if (vm.listData.uploadStatus == 0) {
                vm.processingUploadFile = true;
            }
            $(".ui.dropdown").dropdown();
            this.$root.$on('refresh_contacts', (data) => {
                this.contactList = [];
                this.offset = 0;
                this.totalContacts = 0;
                this.setListValue(bulkstore.state.selectedList);
            });
    
            Dropzone.autoDiscover = false;
            vm.uploadFiles = new Dropzone('#contacts-upload-file-btn', {
                url: store.state.rootUrl + 'file/uploadcontacts',
                paramName: "file", // The name that will be used to transfer the file
                maxFilesize: 1,
                createImageThumbnails: false,
                acceptedFiles: ".csv",
                autoProcessQueue: false,
                params: {
                    "apiKey": this.$cookies.get("key"),
                    "list_id": vm.listData.contact_groupId
                },
                previewTemplate: "<div style='display: none;'></div>"
            });
            vm.uploadFiles.on("addedfile", function(file) {
                vm.showUploadSection = true;
                vm.filename = file.name;
                //$('#droppedFiles').html("")
            });
            vm.uploadFiles.on("removedFile", function(file) {
              
                vm.showUploadSection = false;
                $('#droppedFiles').html("");
            });
            $(".icon.close.i-file").click(function(e){
                vm.closeUpload();
            });
        },
        methods: {
            getDetails(listid){
                let vm =this;
                console.log("details check here");
                $.post({
                    url: store.state.rootUrl + 'contact/get/list',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        list_id: listid,
                        list_name: "",
                        status: "",
                        limit: "",
                        offset: ""

                    }),
                    success: function(response, status, jQxhr) {

                        var list_c = response.data.data;

                        vm.setListValue(list_c);
                    },
                    error: function(jQxhr, status, error) {
                    }
                });
            },
            closeUpload(){
                let vm = this;
                vm.uploadFiles.removeAllFiles(true);
                vm.UploadStatusDesc = "";
                vm.UploadStatus =  false;
                vm.showUploadSection = false;
            },
            editContactList(contactList) {
                $('.ui.modal#modal-edit-contact-list').modal({
                    closable: true
                }).modal('show');
            },
            editContact(contactDetails,custom_field2,custom_field3,custom_field4,custom_field5) {
                var customs = {

                    "custom2":custom_field2,
                    "custom3":custom_field3,
                    "custom4":custom_field4,
                    "custom5":custom_field5
                }
               // console.log(JSON.stringify(contactDetails));
                bulkstore.commit("setCustomsContact",customs);
                bulkstore.commit("setSelectedContact",contactDetails);

                $('.ui.modal#modal-edit-contact').modal({
                    closable: true
                }).modal('show');
            },
            deleteContactList(){
                $('.ui.modal#modal-delete-contact-list').modal({
                    closable: true
                }).modal('show');
            },
            showContactsUpload() {
                //$('#contacts-upload').modal('show');
                this.showUploadSection = true;
            },
            showContactListSendMessage() {
                //this.showContactMessageModal = true;
                $('.ui.modal#modal-contact-list-message').modal({
                    closable: true
                }).modal('show');
                $('#modal-scheduleDate').calendar({
                    type: 'date'
                });
                $('#modal-scheduleTime').calendar({
                    type: 'time'
                });
            },
            setListValue(selectedList, is_loadmore) {
                var vm = this;
                 
                if (!is_loadmore) {
                    
                    if (selectedList.contact_groupId != vm.listData.contact_groupId) {
                        
                        vm.listData = selectedList;
                        vm.selectedList = selectedList;
                        vm.custom_field1 = selectedList.custom_field1;
                        vm.custom_field2 = selectedList.custom_field2;
                        vm.custom_field3 = selectedList.custom_field3;
                        vm.custom_field4 = selectedList.custom_field4;
                        vm.custom_field5 = selectedList.custom_field5;
                        vm.totalContacts = 0;
                        vm.contactList = [];
                        vm.offset = 0;
                    } else {
                       
                        vm.listData = selectedList;
                        vm.selectedList = selectedList;
                        
                        vm.totalContacts = 0;
                        vm.contactList = [];
                        vm.offset = 0;
                    }

    
                }
                if (vm.$route.params.id != "" && !isNaN(vm.$route.params.id)) {
                    console.log("show test");
                    vm.list_id = vm.listData[0].contact_groupId;
                    vm.group_name = vm.listData[0].group_name;
                    vm.updated_at = vm.listData[0].updated_at;
                    vm.description = vm.listData[0].description;
                    vm.uploadStatus = vm.listData[0].uploadStatus; 
                    vm.total = vm.listData[0].total; 
                    vm.saf = vm.listData[0].saf; 
                    vm.airtel = vm.listData[0].airtel; 
                    vm.telcom = vm.listData[0].telcom; 
                    vm.mtnUg = vm.listData[0].MTN_UGX;
                    vm.mtnRWD = vm.listData[0].MTN_RWX;
                    vm.somalia = vm.listData[0].SOMALIA;
                }
                else if(vm.listData != "" || vm.listData != null){
                    console.log("am here test");
                    vm.list_id = vm.listData.contact_groupId;
                    vm.group_name = vm.listData.group_name;
                    vm.updated_at = vm.listData.updated_at;
                    vm.description = vm.listData.description;
                    vm.uploadStatus = vm.listData.uploadStatus; 
                    vm.total = vm.listData.total; 
                    vm.saf = vm.listData.saf; 
                    vm.airtel = vm.listData.airtel; 
                    vm.telcom = vm.listData.telcom; 
                    vm.mtnUg = vm.listData.MTN_UGX;
                    vm.mtnRWD = vm.listData.MTN_RWX;
                    vm.somalia = vm.listData.SOMALIA;
                    
                }
                else{
                    console.log("test message");
                }
    
    
                if (vm.totalContacts === 0 || vm.totalContacts >= ((vm.offset - 1) * vm.limit)) {
                    vm.offset++;
                    vm.loading = true;
                    $.post({
                        url: store.state.rootUrl + 'contact/get/contacts',
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: this.$cookies.get("key"),
                            list_id: vm.list_id,
                            client_id: vm.client_id,
                            list_name: vm.list_name,
                            status: vm.status,
                            limit: vm.limit,
                            offset: vm.offset,
                            mobile: vm.mobile
                        }),
                        success: function(data, status, jQxhr) {
                            vm.loading = false;
                            var code = data.data.code;
    
                            if (code == 404) {
    
                            } else {
                                for (var i = 0; i < data.data.data.length; i++) {
                                    vm.contactList.push(data.data.data[i])
                                }
                                vm.totalContacts = data.data.data[0].total;
                            }
                        },
                        error: function(jQxhr, status, error) {
    
                            vm.loading = false;
                            // vm.contactList=[];
                            //vm.totalContacts=0;
                            var error = jQxhr.responseJSON.statusDescription;
                            if (error) {
                                alertify.error(error);
                            } else {
                                alertify.error("An error occurred ");
                            }
    
    
                        }
                    });
    
                }
    
    
    
            },
            muteContact(msisdn, status) {
                var vm = this;
                $.post({
                    url: bulkstore.state.bulksmsUrl + '/contact/mute',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        msisdn: msisdn,
                        status: status,
                        list_id: vm.list_id,
                    }),
                    success: function(data, status, jQxhr) {
                        alertify.success(data.statusDescription);
                        vm.setListValue(vm.selectedList);
                    },
                    error: function(jQxhr, status, error) {
                        var error = jQxhr.responseJSON.statusDescription;
                        if (error) {
                            alertify.error(error);
                        } else {
                            alertify.error("An error occurred ");
                        }
                    }
                });
            },
            addContactToList() {
                var vm = this;
                var contacts = [];
                contacts.push(vm.new_mobile);
                $.post({
                    url: bulkstore.state.bulksmsUrl + 'contact/create',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        list_name: vm.group_name,
                        list_id: vm.list_id,
                        custom_tag: vm.profileTags,
                        contacts: contacts,
                        custom_field1: vm.profile_custom1,
                        custom_field2: vm.profile_custom2,
                        custom_field3: vm.profile_custom3,
                        custom_field4: vm.profile_custom4,
                        custom_field5: vm.profile_custom5
                    }),
                    success: function(data, status, jQxhr) {
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.success(data.data.message);
                        vm.setListValue(vm.selectedList);
                        vm.new_mobile = '';
                        vm.profile_custom1 = "";
                        vm.profile_custom2 = "";
                        vm.profile_custom3 = "";
                        vm.profile_custom4 = "";
                        vm.profile_custom5 = "";
                        vm.profileTags = "";
                        vm.$root.$emit('refreshSelected');
                        //vm.selectedList();
                        //vm.$refs.contacts.quickcontactslist = [];
                        //vm.$root.$emit('refresh_contacts');
                    },
                    error: function(jQxhr, status, error) {
                        var error = jQxhr.responseJSON.statusDescription;
                        if (error) {
                            alertify.error(JSON.stringify("error" +error));
                        } else {
                            alertify.error("An error occurred ");
                        }
                    }
                });
            },
            exportData(selectedList) {
                var vm = this;
                var exportRecipients = [];
                vm.token = vm.$cookies.get("key");
                $.post({
                    url: store.state.rootUrl + 'contact/get/contacts',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        list_id: vm.list_id,
                        list_name: vm.list_name,
                        status: vm.status,
                        limit: vm.limit,
                        offset: vm.offset,
                        export:1
                    }),
                    success: function(data, status, jQxhr) {
                        $('.ui.accordion').accordion('refresh');
                        for (var i = 0; i < data.data.data.length; i++) {
                            var item = {};
                            item.mobile = data.data.data[i].mobile;
                            item.network = data.data.data[i].network;
                            if (data.data.data[i].status == 1) {
                                item.status = 'Muted';
                            } else {
                                item.status = 'Active';
    
                            }
                            item.created_at = data.data.data[i].created_at;
                            item.custom_field1 = data.data.data[i].custom1;
                            item.custom_field2 = data.data.data[i].custom2;
                            item.custom_field3 = data.data.data[i].custom3;
                            item.custom_field4 = data.data.data[i].custom4;
                            item.custom_field5 = data.data.data[i].custom5;
    
    
                            exportRecipients.push(item);
                        }
    
                        var exportDate = moment().format('DD_MMMM_YYYY_h:mm');
    
                        vm.JSONToCSVConvertor(exportRecipients, selectedList.list_name + "_" + exportDate, 1);
    
                    },
                    error: function(jQxhr, status, error) {
    
                        var error = jQxhr.responseJSON.data.message;
                        if (error) {
                            alertify.error(error);
                        } else {
                            alertify.error("An error occurred please try again  ");
                        }
    
                        return;
                    }
                });
            },
            JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
                //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
                var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    
                var CSV = '';
                //Set Report title in first row or line
    
                //CSV += ReportTitle + '\r\n\n';
    
                //This condition will generate the Label/Header
                if (ShowLabel) {
                    var row = "";
    
                    //This loop will extract the label from 1st index of on array
                    for (var index in arrData[0]) {
    
                        //Now convert each value to string and comma-seprated
                        row += index + ',';
                    }
    
                    row = row.slice(0, -1);
    
                    //append Label row with line break
                    CSV += row + '\r\n\n\n';
                }
    
                //1st loop is to extract each row
                for (var i = 0; i < arrData.length; i++) {
                    var row = "";
    
                    //2nd loop will extract each column and convert it in string comma-seprated
                    for (var index in arrData[i]) {
                        row += '"' + arrData[i][index] + '",';
                    }
    
                    row.slice(0, row.length - 1);
    
                    //add a line break after each row
                    CSV += row + '\r\n';
                }
    
                if (CSV == '') {
                    alert("Invalid data");
                    return;
                }
    
                //Generate a file name
                var fileName = "VasPro_";
                //this will remove the blank-spaces from the title and replace it with an underscore
                fileName += ReportTitle.replace(/ /g, "_");
    
                //Initialize file format you want csv or xls
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    
                // Now the little tricky part.
                // you can use either>> window.open(uri);
                // but this will not work in some browsers
                // or you will not get the correct file extension
    
                //this trick will generate a temp <a /> tag
                var link = document.createElement("a");
                link.href = uri;
    
                //set the visibility hidden so it will not effect on your web-layout
                link.style = "visibility:hidden";
                link.download = fileName + ".csv";
    
                //this part will append the anchor tag and remove it after automatic click
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            uploadContacts() {
                let vm = this;
                if (vm.showUploadSection) {
                    var myFile = vm.uploadFiles.files[0];
    
                    var data = new FormData();
                    data.append("file", myFile);
                    data.append("apiKey", vm.$cookies.get("key"));
                    data.append("list_id", vm.listData.contact_groupId);
                    $.ajax({
                        url: store.state.rootUrl + 'contact/upload',
                        type: "POST",
                        data: data, //JSON.stringify({"file":myFile}),
                        cache: false,
                        contentType: false,
                        processData: false,
    
                        success: function(data, status, jQxhr) {
                            alertify.success(data.data.data.success[0].message);
                            vm.uploadStatus = 1;
                            vm.UploadStatusDesc = "";
                            vm.UploadStatus =  false;
                            vm.showUploadSection = false;
                        },
                        error: function(jQxhr, status, error) {
                            vm.uploadFiles.removeAllFiles(true);
                            vm.UploadStatus = true;
                            vm.UploadStatusDesc = jQxhr.responseJSON.statusDescription;
                            
                        }
                    });
                }
    
                // vm.uploadFiles.processQueue();
            },
          
            selectList(selectedList) {
                this.selectedKey = selectedList.list_id;
                this.selectedList = selectedList;
                bulkstore.commit("setSelectedList", selectedList);
                this.$refs.details.setListValue(selectedList);
            }
        }
    }
</script>
