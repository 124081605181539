<template>
  <div class="l-app-main">
    <div class="l-app-main-inboxzero bg-primary-lighter" v-show="inboxzero">
      <h2>Create Message</h2>
      <p>Click below to <br />and send messages</p>
      <div class="padding-sm"></div>
      <button class="ui large blue button" @click="showCreateTemplate(true)">
        Send Message
      </button>
      <div class="padding-xl"></div>
    </div>
    <div class="l-app-main-content" v-show="!inboxzero">
      <div class="ui grid">
        <div class="row">
          <div class="column">
            <div class="ui grid">
              <div class="four wide column">
                <h3>All Messages</h3>
                <h5 class="text-lightgrey">from all Sender IDs</h5>
                <small>{{ formatDates(dateOne, dateTwo) }}</small>
              </div>
              <div class="four wide right aligned column hidden">
                <div class="ui toggle checkbox">
                  <input type="checkbox" name="schedule" v-model="downloadReport" />
                  <label><strong>Download Entire Delivery Report </strong></label>
                </div>
              </div>
              <div class="twelve wide right aligned column">
                <select class="ui small dropdown" v-model="senderId">
                  <option value="">Select</option>
                  <option
                    v-for="s_id in dataLists"
                    v-show="dataLists.length > 0 && s_id.status == 13"
                    :key="s_id.short_code"
                  >
                    {{ s_id.short_code }}
                  </option>
                  <option value="VasPro">VasPro</option>
                </select>
                <div class="ui small input datepicker-trigger margin-left-xs">
                  <input
                    type="text"
                    id="datepicker-trigger"
                    placeholder="Select date"
                    :value="formatDates(dateOne, dateTwo)"
                  />
                  <AirbnbStyleDatepicker
                    :trigger-element-id="'datepicker-trigger'"
                    :mode="'range'"
                    :fullscreen-mobile="true"
                    :months-to-show="2"
                    :date-one="dateOne"
                    :date-two="dateTwo"
                    :close-after-select="false"
                    @date-one-selected="
                      (val) => {
                        dateOne = val;
                      }
                    "
                    @date-two-selected="
                      (val) => {
                        dateTwo = val;
                      }
                    "
                  />
                </div>
                <button
                  class="ui button margin-left-md"
                  @click="getAllMessage()"
                  :class="{ blue: dateOne && dateTwo && senderId }"
                  :disabled="!dateOne && !dateTwo && !senderId"
                >
                  View Report
                </button>
                <button
                  class="ui button margin-left-md"
                  :class="{ blue: dateOne && dateTwo }"
                  :disabled="!dateOne && !dateTwo"
                  @click="emailReport"
                >
                  Email Entire Report
                </button>
              </div>
            </div>
            <div v-show="downloadReport">
              <div class="ui negative message" v-show="MessangeStatus">
                {{ StatusMessageDesc }}
              </div>
              <div class="ui positive message" v-show="MessangeStatusP">
                {{ StatusMessageDescPo }}
              </div>
            </div>
            <div v-show="displayRecordStatus">
              <div class="padding-md"></div>
              <div class="left aligned column">
                <div class="ui search link input margin-right-md">
                  <input
                    type="text"
                    placeholder="Search Phone Number"
                    v-on:keyup="getAllMessageReport()"
                    v-model="mobile"
                  />
                </div>
              </div>
              <table class="ui celled styled top aligned striped compact table">
                <thead>
                  <tr>
                    <th class="center aligned">#</th>
                    <th>Phone</th>
                    <th>Message</th>
                    <th class="center aligned">Status</th>
                    <th>Networks</th>
                    <th>Short Code</th>
                    <th>Template</th>
                    <th>List Name</th>
                    <th>Name</th>
                    <th>Custom 2</th>
                    <th>Custom 3</th>
                    <th>Custom 4</th>
                    <th>Custom 5</th>
                    <th>Date Created</th>
                    <th>Date Sent</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(list, index) in lists" :key="list.outbox_id">
                    <td>{{ index + 1 }}</td>
                    <td class="two wide">{{ list.msisdn }}</td>
                    <td class="center aligned">{{ list.message }}</td>
                    <td class="seven wide">{{ list.description }}</td>
                    <td>{{ list.network }}</td>
                    <td>{{ list.short_code }}</td>
                    <td>{{ list.template_title }}</td>
                    <td>{{ list.group_name }}</td>
                    <td>{{ list.custom1 }}</td>
                    <td>{{ list.custom2 }}</td>
                    <td>{{ list.custom3 }}</td>
                    <td>{{ list.custom4 }}</td>
                    <td>{{ list.custom5 }}</td>
                    <td>{{ list.created_at }}</td>
                    <td>{{ list.received_on }}</td>
                  </tr>
                </tbody>
              </table>
              <div
                v-show="!loading"
                @click="getAllMessage"
                class="ui icon large labeled button"
              >
                <i class="icon refresh"></i> Load More
              </div>
              <div
                @click="generateExport()"
                class="ui bg-accent icon large labeled button"
                id="button-export"
                :disabled="uploadStatus"
              >
                <i class="icon download"></i> Download
              </div>
            </div>
            <loader v-show="loading"></loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import bulkstore from "@/bulk/bulksms-state";
import Choices from "choices.js";
import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
import format from "date-fns/format";
import Vue from "vue";
import alertify from "alertifyjs";
import loader from "@/components/loader";
import moment from "moment";
export default {
  data() {
    return {
      inboxzero: false,
      userBalance: "",
      userBonus: "",
      totalMessage: 0,
      loading: false,
      dataLists: [],
      lists: [],
      dateOne: "",
      dateTwo: "",
      dateFormat: "D MMM",
      displayRecordStatus: false,
      senderId: "",
      limit: 10,
      offset: 0,
      mobile: "",
      uploadStatus: false,
      downloadReport: false,
      MessangeStatus: false,
      MessangeStatusP: false,
      StatusMessageDesc: "",
      StatusMessageDescPo: "",
    };
  },
  mounted() {
    let vm = this;
    if (!vm.$cookies.get("key") || vm.$cookies.get("key") == null) {
      vm.$router.push({
        name: "home",
      });
    }
    $(".ui.dropdown").dropdown();
    vm.fetchData();
    //vm.getAllMessage();
  },
  components: {
    loader,
  },
  methods: {
    getAllMessageReport() {
      let vm = this;
      //console.log("Date One: "+vm.dateOne +" Date Two: "+vm.dateTwo+" Mobile: "+vm.mobile);
      $.post({
        url: store.state.rootURLBulkAPI + "v3/SMS/Reportoutbox",
        type: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key"),
          offset: "0",
          short_code: vm.senderId,
          message: "",
          sort: "",
          export: 0,
          start: vm.dateOne,
          stop: vm.dateTwo,
          msisdn: vm.mobile,
          campaignId: "",
          limit: vm.limit,
          offset: vm.offset,
        }),
        success: function (response, status, jQxhr) {
          vm.loading = false;
          vm.totalRecords = response.data.record_count;

          if (response.data.code != 200) {
            alertify.set("notifier", "position", "top-right");
            alertify.error(response.data.message);
            //vm.totalMessage = 0;
          } else {
            vm.displayRecordStatus = true;
            vm.lists = response.data.data;
          }
        },
        error: function (jQxhr, status, error) {
          vm.loading = false;
          //console.log(jQxhr)
          alertify.set("notifier", "position", "top-right");
          alertify.error(jQxhr.responseJSON.statusDescription);
          //console.log("failed");
        },
      });
    },
    emailReport() {
      let vm = this;
      vm.loading = true;
      vm.MessangeStatusP = false;
      vm.MessangeStatus = false;
      $.post({
        url: store.state.rootURLBulkAPI + "v3/SMS/Reportdelivery",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
          export: 1,
          start: vm.dateOne,
          stop: vm.dateTwo,
        }),
        success: function (response, status, jQxhr) {
          vm.loading = false;
          alertify.set("notifier", "position", "top-right");
          alertify.success(response.data.message);
        },
        error: function (jQxhr, status, error) {
          vm.loading = false;
          vm.MessangeStatus = true;
          alertify.set("notifier", "position", "top-right");
          alertify.error(jQxhr.responseJSON.data.message);
          vm.StatusMessageDesc = jQxhr.responseJSON.data.message;
        },
      });
    },
    getAllMessage() {
      let vm = this;

      if (vm.totalMessage == 0 || vm.totalMessage >= (vm.offset - 1) * vm.limit) {
        vm.offset++;
        vm.loading = true;
        $.post({
          url: store.state.rootURLBulkAPI + "v3/SMS/Reportoutbox",
          type: "POST",
          data: JSON.stringify({
            apiKey: vm.$cookies.get("key"),
            offset: "0",
            short_code: vm.senderId,
            message: "",
            sort: "",
            export: 0,
            start: vm.dateOne,
            stop: vm.dateTwo,
            msisdn: vm.mobile,
            campaignId: "",
            limit: vm.limit,
            offset: vm.offset,
          }),
          success: function (response, status, jQxhr) {
            vm.loading = false;
            vm.totalRecords = response.data.record_count;
            var resp = response.data.data;
            if (response.data.code != 200) {
              vm.offset = 0;
              vm.totalRecords = [];
              alertify.set("notifier", "position", "top-right");
              alertify.error(response.data.message);
              //vm.totalMessage = 0;
            } else {
              vm.totalMessage = response.data.record_count;
              if (vm.totalMessage == 0) {
                vm.inboxzero = true;
              }
              vm.displayRecordStatus = true;
              for (var i = 0; i < resp.length; i++) {
                // console.log(" loop " + i + " " + JSON.stringify(resp[i]));
                vm.lists.push(resp[i]);
              }
            }
          },
          error: function (jQxhr, status, error) {
            vm.loading = false;
            vm.offset = 0;
            //console.log(jQxhr)
            alertify.set("notifier", "position", "top-right");
            alertify.error(jQxhr.responseJSON.statusDescription);
            //console.log("failed");
          },
        });
      }
    },
    dowonloadReport() {
      let vm = this;
      vm.uploadStatus = true;
      $("#button-export").html(
        '<i class="fas fa-circle-notch fa-spin"></i> Download Please Wait ...'
      );
      axios({
        url: store.state.rootURLBulkAPI + "v3/SMS/Reportoutbox",
        method: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key"),
          offset: "0",
          short_code: vm.senderId,
          message: "",
          sort: "",
          export: 1,
          start: vm.dateOne,
          stop: vm.dateTwo,
          msisdn: vm.mobile,
          campaignId: "",
          limit: vm.limit,
          offset: vm.offset,
        }),
        responseType: "blob", // important
      }).then((response) => {
        console.log(JSON.stringify(response));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
        $("#button-export").html('<i class="icon download"></i> Download');
      });
    },

    generateExport() {
      let vm = this;
      vm.uploadStatus = true;
      $("#button-export").html(
        '<i class="fas fa-circle-notch fa-spin"></i> Download Please Wait ...'
      );
      var exportRecipients = [];
      $.post({
        url: store.state.rootURLBulkAPI + "v3/SMS/Reportoutbox",
        type: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key"),
          offset: "0",
          short_code: vm.senderId,
          message: "",
          sort: "",
          export: 1,
          start: vm.dateOne,
          stop: vm.dateTwo,
          msisdn: vm.mobile,
          campaignId: "",
          limit: vm.limit,
          offset: vm.offset,
        }),
        success: function (response, status, jQxhr) {
          $(".ui.accordion").accordion("refresh");
          for (var i = 0; i < response.data.data.length; i++) {
            var item = {};
            item.msisdn = response.data.data[i].msisdn;
            item.short_code = response.data.data[i].short_code;
            item.message = response.data.data[i].message;
            item.description = response.data.data[i].description;
            item.network = response.data.data[i].network;
            item.template = response.data.data[i].template_title;
            item.group_name = response.data.data[i].group_name;
            item.custom1 = response.data.data[i].custom1;
            item.custom2 = response.data.data[i].custom1;
            item.custom3 = response.data.data[i].custom3;
            item.custom4 = response.data.data[i].custom4;
            item.custom5 = response.data.data[i].custom5;
            item.received_on = response.data.data[i].received_on;
            item.created_at = response.data.data[i].created_at;
            exportRecipients.push(item);
          }

          if (response.data.data.length < 10001) {
            var exportDate = moment().format("DD_MMMM_YYYY_h:mm");

            vm.JSONToCSVConvertor(
              exportRecipients,
              "Campaign ID: " + vm.senderId,
              +"_" + exportDate,
              1
            );
          }
          alertify.set("notifier", "position", "top-right");
          alertify.success(
            "Your file has been sent to your email. Kindly check your email"
          );
          $("#button-export").html('<i class="icon download"></i> Download');
        },
        error: function (jQxhr, status, error) {
          vm.loading = true;
          //console.log(jQxhr)
          alertify.set("notifier", "position", "top-right");
          alertify.error(jQxhr.responseJSON.statusDescription);
          $("#button-export").html('<i class="icon download"></i> Download');
          //console.log("failed");
        },
      });
    },
    JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
      //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
      var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;

      var CSV = "";
      //Set Report title in first row or line

      //CSV += ReportTitle + '\r\n\n';

      //This condition will generate the Label/Header
      if (ShowLabel) {
        var row = "";

        //This loop will extract the label from 1st index of on array
        for (var index in arrData[0]) {
          //Now convert each value to string and comma-seprated
          row += index + ",";
        }

        row = row.slice(0, -1);

        //append Label row with line break
        CSV += row + "\r\n\n\n";
      }

      //1st loop is to extract each row
      for (var i = 0; i < arrData.length; i++) {
        var row = "";

        //2nd loop will extract each column and convert it in string comma-seprated
        for (var index in arrData[i]) {
          row += '"' + arrData[i][index] + '",';
        }

        row.slice(0, row.length - 1);

        //add a line break after each row
        CSV += row + "\r\n";
      }

      if (CSV == "") {
        alert("Invalid data");
        return;
      }

      //Generate a file name
      var fileName = "VasPro_";
      //this will remove the blank-spaces from the title and replace it with an underscore
      fileName += ReportTitle.replace(/ /g, "_");

      //Initialize file format you want csv or xls
      var uri = "data:text/csv;charset=utf-8," + escape(CSV);

      // Now the little tricky part.
      // you can use either>> window.open(uri);
      // but this will not work in some browsers
      // or you will not get the correct file extension

      //this trick will generate a temp <a /> tag
      var link = document.createElement("a");
      link.href = uri;

      //set the visibility hidden so it will not effect on your web-layout
      link.style = "visibility:hidden";
      link.download = fileName + ".csv";

      //this part will append the anchor tag and remove it after automatic click
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    fetchData() {
      //console.log("TEST");
      // this.dataLists = [];
      var vmBalance = this;

      var vm = this;

      $.post({
        url: bulkstore.state.bulksmsUrl + "senderId/list",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
        }),
        success: function (response, status, jQxhr) {
          vm.dataLists = response.data.data;
          //alertify.set('notifier', 'position', 'top-right');
          ///alertify.success(response.data.message);
          //console.log("test message " + JSON.stringify(response.data.data));
        },
        error: function (jQxhr, status, error) {
          alertify.set("notifier", "position", "top-right");
          alertify.error(jQxhr.responseJSON.data.message);
          // console.log(" status bulk " + status + " data " + JSON.stringify(jQxhr.responseJSON.statusDescription));
        },
      });
    },
    formatDates(dateOne, dateTwo) {
      let formattedDates = "";
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat);
      }
      if (dateTwo) {
        formattedDates += " - " + format(dateTwo, this.dateFormat);
      }
      return formattedDates;
    },
  },
};
</script>
