<template>
    <div class="ui tiny modal" id="modal-delete-contact-list">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md"/>
                <h2 class="padding-bottom-sm">Delete Contact List</h2>
                <h4>Are you sure you want to delete contact List <strong>{{originalName}}</strong>?</h4>
            </div>
        </div>
        <div class="actions">
            <button class="ui button" @click="hideModal()">Cancel</button>
            <button class="ui red button" @click="updateList()">Delete List</button>
        </div>
    </div>
</template>

<script>
import store from "@/store";
import bulkstore from "@/bulk/bulksms-state";
import alertify from 'alertifyjs';

export default {
     data(){
        return {
            selectedList:bulkstore.state.selectedList,
            listName:'',
            custom_field1:'',
            custom_field2:'',
            custom_field3:'',
            custom_field4:'',
            custom_field5:'',
           // inboxzero: false,
        }
    },
    computed:{
           originalName() {
               var vm =this;
               vm.listName = bulkstore.state.selectedList.group_name;
               return bulkstore.state.selectedList.group_name;
               //Console.log("Custom Fil" +bulkstore.state.selectedList.list_name);
            }
       },
       watch:{
           custom_field1(oldVal,val){
               // console.log(oldVal+" <=val oldVal=> "+val);
               //return bulkstore.state.selectedList.custom_field1
           }
       },
    methods: {
        hideModal(){
            $('.ui.modal#modal-delete-contact-list').modal('hide');
            
        },
         updateList(){
                var vm = this;
                $.post({
                    url:bulkstore.state.bulksmsUrl+'contact/list/update',
                    type:"POST",
                    data:JSON.stringify(
                        {   apiKey: vm.$cookies.get("key"),
                            listName: vm.listName,
                            list_id:bulkstore.state.selectedList.contact_groupId,
                            status:-5
                        }
                    ),
                    success:function(data,status,jQxhr){
                        //mixpanel.track("Updated contact list");
                       // console.log("list success "+JSON.stringify(data.statusDescription));
                        $('#btn-save-edit').text('Save').removeAttr("disabled");
                        if(data.statusDescription){
                            alertify.success(data.statusDescription);
                        }
                        $('.ui.modal#modal-delete-contact-list').modal('hide');
                        vm.$root.$emit('refreshList');
                    },
                    error:function (jQxhr,status,error) {
                        $('#btn-save-edit').text('Save').removeAttr("disabled");
                        var  error =jQxhr.responseJSON.statusDescription;
                        if(error){
                            alertify.error(error);

                        }
                        else {
                            alertify.error("An error occurred ");
                        }

                    }
                });
            }
        
    }
}
</script>
