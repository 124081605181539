<template>
<div class=" ">
    <div class="bg-white">
        <div class="ui padded relaxed grid bg-white l-app-main-top">
            <div class="middle aligned row bg-primary-lighter border-bottom padding-bottom-none">
                <div class="six wide computer six wide tablet twelve wide mobile column">
                    <h2 class="text-medium text-title">{{surveyName}}</h2>
                    <small class="hidden">Created a few seconds ago</small>

                    <ul class="l-tabs">
                        <li :class="{active: selectedTab == 'questions'}" class="item" @click="selectedTab = 'questions'">Questionnaire</li>
                        <li :class="{active: selectedTab == 'settings'}" class="item" @click="selectedTab = 'settings'">Settings</li>
                        <li :class="{active: selectedTab == 'summary'}" class="item" @click="selectedTab = 'summary'">Finish</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="l-app-main-segment">
            <createSurveyQuestions v-if="selectedTab == 'questions'" />

            <createSurveySettings v-if="selectedTab == 'settings'" />

            <createSurveySummary v-if="selectedTab == 'summary'" />
        
        </div>
    </div>
    <surveyQuestionsOptions />
</div>
    
</template>
<script>
import store from "@/store";
import surveyQuestionsOptions from "@/modals/survey-questions-options";
import createSurveyQuestions from "./create-questions"
import createSurveySettings from "./create-settings"
import createSurveySummary from "./create-summary"
import alertify from 'alertifyjs';
    import loader from "@/components/loader";
import { setTimeout } from 'timers';

export default {
    data(){
        return {
            selectedTab: 'questions',
            surveyName:"",
        }
    },
    components: {
        loader
     },
    components:{
        surveyQuestionsOptions, createSurveyQuestions, createSurveySettings, createSurveySummary
    },
    mounted(){
        let vm = this;
        if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            
        vm.surveyName = store.state.selectSurvey.campaign_name;
        //console.log("am here "+vm.$route.params.id);
        //if(vm.surveyName ==null || vm.surveyName==""){
        vm.getCampaignRecords(vm.$route.params.id);
       // }
        setTimeout(function(){
            $(".l-app-main-segment").css({
                top: $(".l-app-main-top").height()
            });
        }, 100);
    },
    methods:{
        showQuestionsOptions(){
            $("#modal-survey-questions-options").modal({centered: false}).modal("show");
        },
         getCampaignRecords(appID){
            let vm = this
        $.post({
            url: store.state.rootURLBulkAPI + "v3/survey/query",
            type: "POST",
            data: JSON.stringify({
                apiKey: vm.$cookies.get("key"),
                app_id:appID
            }),
            success: function(response, status, jQxhr) {
                
                var survey = response.data.data;
                vm.surveyName = survey[0].campaign_name;
                store.commit("setSurveySelect", survey[0]);
                setTimeout(function(){
                    $(".l-app-main-segment").css({
                        top: $(".l-app-main-top").height()
                    });
                }, 1000);

            },
            error: function(jQxhr, status, error) {
                alertify.set('notifier', 'position', 'top-right');
                alertify.error(jQxhr.responseJSON.statusDescription);
            }
        });

        }
    }
}
</script>
