<template>
    <div class="">
        <div class="body">
            <div class="body-messages">
                <div class="chatMessages">
                <div v-for="(list,index) in listData" :key="index+1">
                    
                    <div class="message m1" v-if="list.client_id != null">
                        <div class="message-container">
                            <div class="message-body whatsapp">{{list.message}}</div>
                            <div class="message-meta">
                                {{list.created_at}}
                            </div>
                        </div>
                    </div>
                    
                    <div v-if="list.client_id == null">
                        <div class="message m0">
                            <div class="message-container">
                                <div class="message-body">{{list.message}}</div> 
                                <div class="message-meta">{{list.created_at}}</div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <loader v-show="loading"></loader>
            <div class="body-create" v-show="sentState && startBTNState">
               <div style="margin-left:40%" v-show="sentState && startBTNState" class="ui bg-primary button" id="button-reply" @click="sendmessageState(0)">Start the Chat</div>
            </div>
           <div class="body-create" v-show="sentState && !startBTNState">
               {{messageCount}} <span class="text-green text-link text-medium"> Characters {{sentState}}</span> 
                <span class="right aligned column"> {{messagePages}} <span class="text-green text-link text-medium"> WhatsApp Message</span>
                </span> <br /><br />
                <div class="ui action fluid input" >
                    <select class="ui compact selection dropdown dropup" v-model="type" >
                        <option value="TEXT">TEXT</option>
                        <option value="IMAGE">IMAGE</option>
                        <option value="VIDEO">VIDEO</option>
                        <option value="FILE">FILE</option>
                        <option value="AUDIO">AUDIO</option>
                        <option value="LOCATION">LOCATION</option>
                    </select>
                    <input type="text" placeholder="Type your WhatsApp message here" v-model="composedMessage">
                    <div class="ui bg-primary button" id="button-reply" v-show="type=='TEXT'" @click="sendmessage()">Reply</div>
                </div>
                <div class="ui action fluid input" v-show="!sentState">
                    <select class="ui compact selection dropdown dropup" v-model="type" >
                        <option value="TEXT">TEXT</option>
                    </select>
                    <input type="text" placeholder="Type your SMS message here" v-model="composedMessage">
                    <div class="ui bg-primary button" id="button-reply" v-show="type=='TEXT'" @click="sendmessage()">Reply</div>
                </div>
                <div class="ui action fluid input" v-show="type=='LOCATION'">
                    <input type="text" placeholder="Longitude" v-model="longitudeNo" >
                    <input type="text" placeholder="Latitude" v-model="latitudeNo">
                    <input type="text" placeholder="Location Name" v-model="locName" >
                    <input type="text" placeholder="Address" v-model="addressName" >
                    <div class="ui bg-primary button" id="button-reply" @click="sendmessage()">Reply</div>
                </div>
                <div class="ui action fluid input">
                    <span v-show="type!='TEXT'"><br /></span>
                    <input :accept="uploadAccept" v-show="type=='FILE' || type=='IMAGE' || type=='VIDEO' || type=='AUDIO'" type="file" name="files" id="files" ref="files" class="inputfile inputfile-6"  multiple v-on:change="handleFileUploads()"/>
                    <div class="ui bg-primary button" id="button-reply" :disabled="!sendStatus" v-show="type=='FILE' || type=='IMAGE' || type=='VIDEO' || type=='AUDIO'" @click="sendmessage()">Reply</div>
                </div>
            </div>
            <div class="body-create" v-show="!sentState && startBTNState">
               {{messageCount}} <span class="text-green text-link text-medium"> Characters {{sentState}}</span> 
                <span class="right aligned column"> {{messagePages}} <span class="text-green text-link text-medium"> SMS</span>
                </span> <br /><br />
                
                <div class="ui action fluid input" v-show="!sentState">
                    <select class="ui compact selection dropdown dropup" v-model="type" >
                        <option value="TEXT">TEXT</option>
                    </select>
                    <input type="text" placeholder="Type your SMS message here" v-model="composedMessage">
                    <div class="ui bg-primary button" id="button-reply" v-show="type=='TEXT'" @click="sendmessage()">Reply</div>
                </div>
            </div>
        </div>
        <div class="info">
            <div class="info-header">
                <div class="ui middle aligned relaxed padded equal width grid">
                    <div class="row">
                        <div class="column">
                            <div class="info-user">
                                <span class="fa-stack fa-2x text-grey">
                                                <i class="fas fa-circle fa-stack-2x"></i>
                                                <i class="fas fa-user fa-stack-1x fa-inverse"></i>
                                            </span>
                                <h3 class="padding-top-md">{{msisdn}}</h3>
                            </div>
                            <div class="ui divider"></div>
                            <h4>Bio Information</h4>
                            <small>{{name}}</small><br />
                            <small>{{age_bracket}}</small>
    
                            <div class="ui divider"></div>
                            <h4>Activity</h4>
                            <small>Last Sent: {{lastUse}}</small>
                            <div class="ui divider"></div>
                             
                            <div v-show="sentState && !startBTNState" class="ui orange button " id="button-reply" @click="sendmessageState(1)">Close Chat</div>
                            <h4 v-show="!sentState" class="text-red">24hrs Chat Window for <stron>{{name}}</stron> has expired. If you wish to send message it will be sent as SMS.</h4>
                            <div v-show="!sentState" class="ui divider"></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import format from 'date-fns/format';
    import loader from "@/components/loader";
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    import Dropzone from "dropzone";
    import moment from "moment";
import { setTimeout } from 'timers';
    export default {
        data() {
            return {
                listData: [],
                status: "",
                limit: 10,
                offset: 0,
                order: "",
                profile_id: "",
                name: "",
                age_bracket: "",
                origin: "",
                useFreq: "",
                msisdn: "",
                loading: false,
                lastUse: null,
                senderId:"",
                dataLists:[],
                composedMessage:"",
                inbox_id:"",
                endMessage:"",
                composeTotalCharacters:0,
                replyWhatsApp:false,
                type:'TEXT',
                whatsAppURL:"",
                longitudeNo:"",
                latitudeNo:"",
                locName:"",
                addressName:"",
                shortcode:"",
                uploadState: false,
                sendStatus:true,
                full_names:"",
                client_name:"",
                sentState:false,
                startBTNState:true
            }
        },
        components: {
            loader,
        },
        computed: {
    uploadAccept() {
                if(this.whatsAppType == 'IMAGE'){
                    return "image/*";
                }
                else if(this.whatsAppType == 'AUDIO'){
                    return "audio/*";
                }
                else if(this.whatsAppType == 'VIDEO'){
                    return "video/*";
                }
                else {
                    return "file_extension"
                }

            },
            messageCount() {
                //var defaultCount = 14;
                var phoneNu = "";
                phoneNu = window.atob(this.$cookies.get("username"));
                var phone = "";

               this.composeTotalCharacters = this.composedMessage.length;
                
    
                return this.composeTotalCharacters;
            },
            messagePages() {
    
                if (this.composedMessage.length > 0) {
                    $('#textarea-sms').removeClass("red");
                }
    
                if (this.composeTotalCharacters == 0) {
                    return 0;
                } else if (this.composeTotalCharacters / 160 <= 1) {
                    return 1;
                } else if (this.composeTotalCharacters / 160 <= 2) {
                    return 2;
                } else if (this.composeTotalCharacters / 160 <= 3) {
                    return 3;
                } else if (this.composeTotalCharacters / 160 <= 4) {
                    return 4;
                } else if (this.composeTotalCharacters / 160 <= 5) {
                    return 5;
                } else {
                    return " Max ";
                }
            },
        },
        mounted() {
            let vm = this;
            vm.getProfile();
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            if (store.state.selectInboxDetails) {
                //console.log(JSON.stringify(store.state.selectInboxDetails));
                vm.msisdn = store.state.selectInboxDetails.msisdn;
                vm.profile_id = store.state.selectInboxDetails.profile_id;
                vm.inbox_id = store.state.selectInboxDetails.inbox_id;
                vm.name =  store.state.selectInboxDetails.name;
                vm.lastUse = store.state.selectInboxDetails.updated_at;
                vm.shortcode = store.state.selectWhatsAppNumber;
                var startDay = new Date().getTime() - (1 * 24 * 60 * 60 * 1000);
                var endDay = new Date().getTime();
                var sentDay = Date.parse(vm.lastUse);
                if(sentDay>=startDay && sentDay<=endDay){
                    vm.sentState = true;
                }
                else {
                    vm.sentState = false;
                }
                vm.getDetails(vm.profile_id);
              
    
            }
            vm.$root.$on('refreshList', (data) => {
                vm.profile_id = "";
                vm.name = "";
                vm.age_bracket = "";
                vm.origin = "";
                vm.useFreq = "";
                vm.listData = [];
                vm.msisdn = store.state.selectInboxDetails.msisdn;
                vm.profile_id = store.state.selectInboxDetails.profile_id;
                vm.name =  store.state.selectInboxDetails.name;
                vm.lastUse = store.state.selectInboxDetails.updated_at;
                vm.shortcode = store.state.selectWhatsAppNumber;
                var startDay = new Date().getTime() - (1 * 24 * 60 * 60 * 1000);
                var endDay = new Date().getTime();
                var sentDay = Date.parse(vm.lastUse);
                if(sentDay>=startDay && sentDay<=endDay){
                    vm.sentState = true;
                }
                else {
                    vm.sentState = false;
                }

                vm.getDetails(vm.profile_id);
            });
            $(".ui.dropdown").dropdown();
            vm.fetchData();
           
        },
         created() {
    this.interval = setInterval(() => this.getDetails(this.profile_id), 10000);
   // this.interval = setInterval(() => this.getProfile(), 10000);
      },
        methods: {
            getProfile(){
                let vm = this;
            $.post({
                url: store.state.rootURLBulkAPI + 'v3/account/view',
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key"),
                }),
                success: function(response, status, jQxhr) {

                    vm.full_names = response.data.data.account_name;
                    vm.client_name = response.data.data.client_name;
    
                },
                error: function(jQxhr, status, error) {
                    //console.log(" status" + status + " data " + JSON.stringify(jQxhr.responseJSON.statusDescription));
                }
            });
            },
            handleFileUploads() {
                let vm = this;
                vm.sendStatus = false;
                vm.uploadState =  true;
                var myFile = this.$refs.files.files[0];
                var data = new FormData();
                data.append("apiKey", this.$cookies.get("key"));
                data.append("fileType", vm.type);
                data.append("file", myFile);
                $.ajax({
                    url: bulkstore.state.bulksmsUrl  + '/whatsApp/upload',
                    type: "POST",
                    data: data, 
                    cache: false,
                    contentType: false,
                    processData: false,
                    success: function(response, status, jQxhr) {
                        vm.whatsAppURL = response.data.data.file_url;
                        vm.sendStatus = true;
                        vm.uploadState = false;
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.success(response.data.message);
                    },
                    error: function(jQxhr, status, error) {
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
               
            },
            getDetails(profileID) {
                let vm = this;
                vm.loading = true;
                $.post({
                    url: store.state.rootUrl + 'whatsApp/view/conversation',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        profileId: profileID,
                        shortCode:vm.shortcode
    
                    }),
                    success: function(response, status, jQxhr) {
    
                        var list_c = response.data.data;
                        vm.loading = false;
    
                        for (var i = 0; i < list_c.length; i++) {
                            list_c[i].created_at = format(list_c[i].created_at, "hh:mma DD MMM YYYY");
                            var OneDay = new Date().getTime() + (1 * 24 * 60 * 60 * 1000)
                                   
                            if (OneDay > list_c[i].created_at) {
                                vm.replyWhatsApp  = true;
                            }
                            else if (OneDay < list_c[i].created_at) {
                                vm.replyWhatsApp = false;
                            }
                            else{
                                 vm.replyWhatsApp = true;
                            }
                            

                            vm.listData.push(list_c[i]);
                        }
                          //vm.scrollToBottom();
                          setTimeout(function(){
                              $(".body-messages").scrollTop($(".chatMessages").height())
                          }, 100)
                           
                    },
                    error: function(jQxhr, status, error) {
                         alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            },
            fetchData() {
                //console.log("TEST");
                // this.dataLists = [];
                var vmBalance = this;
    
                var vm = this;
                $.post({
                    url: store.state.rootUrl + 'whatsApp/view/keywords',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),

                    }),
                    success: function(response, status, jQxhr) {
                        if(response.data.code == 200){
                            vm.dataLists=response.data.data
                        }
                        
                    },
                    error: function(jQxhr, status, error) {
                    }
                });
               
    
            },
            scrollToBottom(){
               
            const element = this.$el.querySelector(".chatMessages");
             element.scrollIntoView({behavior: "smooth", block: "end"})
            },
            sendMessageInbox(clientDetails) {
                let vm = this;
                //console.log("am here");
                store.commit("setInboxDetails",clientDetails);
                $('.ui.modal#modal-inbox-new-message').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            addComments(list){
                let vm = this;
                //console.log("am here");
                store.commit("setInboxDetails",list);
                $('.ui.modal#modal-inbox-comments').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            sendmessage() {

                var vm = this;
                $.post({
                    url: bulkstore.state.bulksmsUrl + 'whatsApp/outbound/send',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        shortCode: vm.shortcode,
                        whatsMessage: !vm.composedMessage.endsWith(vm.endMessage) ?
                            vm.composedMessage + "" + vm.endMessage : vm.composedMessage,
                        phoneNumber: vm.msisdn,
                        type: vm.type,
                        url: vm.whatsAppURL,
                        activateChat:0,
                        longitudeNo:vm.longitudeNo,
                        latitudeNo: vm.latitudeNo,
                        locName:vm.locName,
                        addressName:vm.addressName
                    }),
                    success: function(data, status, jQxhr) {
                        vm.sendStatus = false;
                        vm.composedMessage = "";
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.success(data.data.message);
                        vm.getDetails(vm.profile_id);

                    },
                    error: function(jQxhr, status, error) {
                        vm.sendStatus = false;
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                        vm.sendSMSStatus = true;
                        vm.sendSMSResponseMessage = jQxhr.responseJSON.statusDescription;

    
                    }
                });
            },
            sendmessageState(state) {

                var vm = this;
                var message = "";
                var names = vm.name.split(" ");
                if(state==0){
                    message = "Hello "+names[0]+", \n\nYou are now chatting with our agent "+vm.full_names+".\n\n How can we help you today?\n\nSent By "+vm.client_name;
                    vm.startBTNState = false;
                }
                else {
                    message = "Hello "+names[0]+",\n\nYour chat with our agent "+vm.full_names+". has been closed.\n\nThank you for interacting with us. Feel free to get in touch with us.\n\nSent By "+vm.client_name;
                    vm.startBTNState = true;
                }
                $.post({
                    url: bulkstore.state.bulksmsUrl + 'whatsApp/outbound/send',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        shortCode: vm.shortcode,
                        whatsMessage: message,
                        phoneNumber: vm.msisdn,
                        type: vm.type,
                        url: vm.whatsAppURL,
                        activateChat:state,
                        longitudeNo:vm.longitudeNo,
                        latitudeNo: vm.latitudeNo,
                        locName:vm.locName,
                        addressName:vm.addressName
                    }),
                    success: function(data, status, jQxhr) {
                        vm.sendStatus = false;
                        vm.composedMessage = "";
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.success(data.data.message);
                        vm.getDetails(vm.profile_id);

                    },
                    error: function(jQxhr, status, error) {
                        vm.sendStatus = false;
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                        vm.sendSMSStatus = true;
                        vm.sendSMSResponseMessage = jQxhr.responseJSON.statusDescription;

    
                    }
                });
            },
        }
    }
</script>
