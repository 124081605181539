<template>
  <!-- Report tab-->
  <div class="l-app-main-segment-container bg-grey-lightest">
    <div class="ui equal width relaxed padded grid bg-white border-bottom">
      <div class="row">
        <div class="computer only tablet only column">
          <div class="ui tiny buttons">
            <button class="ui grey button">All Channels</button>
            <button class="ui basic button">SMS</button>
            <button class="ui basic button">WhatsApp</button>
            <button class="ui basic button" disabled="disabled">Web</button>
            <button class="ui basic button" disabled="disabled">Email</button>
            <button class="ui basic button" disabled="disabled">USSD</button>
          </div>
        </div>
        <div class="mobile only column">
          <button class="ui tiny grey button">All Channels</button>
        </div>
        <div class="right aligned column">
          <button class="ui tiny hidden button">Response Summary</button>
          <div class="ui small fluid input datepicker-trigger margin-left-xs">
            <input
              type="text"
              id="datepicker-trigger"
              placeholder="Select date"
              :value="formatDates(dateOne, dateTwo)"
            />
            <AirbnbStyleDatepicker
              :trigger-element-id="'datepicker-trigger'"
              :mode="'range'"
              :fullscreen-mobile="true"
              :months-to-show="1"
              :date-one="dateOne"
              :date-two="dateTwo"
              :close-after-select="false"
              @date-one-selected="
                (val) => {
                  dateOne = val;
                }
              "
              @date-two-selected="
                (val) => {
                  dateTwo = val;
                }
              "
              @apply="getResponseGraph()"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="six wide computer eight wide tablet sixteen wide mobile column">
          <h3>Response Summary</h3>
          <span class="text-grey-light">Data from survey responses</span>
          <div class="padding-md"></div>
          <h1 class="text-primary text-lg">
            <strong>{{ progressRate }}%</strong>
          </h1>
          <h4 class="text-grey">Completion Rate</h4>
          <div class="padding-md"></div>
          <div class="ui equal width grid">
            <div class="column">
              <h2 class="text-grey">
                {{ completed }}
                <small class="text-grey-light">of {{ totalcontacts }}</small>
              </h2>
              <h5 class="text-grey">contacts completed</h5>
            </div>
            <div class="column">
              <h2 class="text-grey">{{ averageTime }}</h2>
              <h5 class="text-grey">Avg. time taken</h5>
            </div>
          </div>
          <div class="padding-md"></div>
          <div class="text-grey padding-bottom-sm">
            {{ incomplete }} pending completion
          </div>
          <div class="text-grey">{{ rejected }} rejected the survey</div>
          <div class="padding-sm"></div>
        </div>
        <div class="sixteen wide mobile only column">
          <div class="ui divider"></div>
        </div>
        <div
          class="ten wide computer eight wide tablet sixteen wide mobile right aligned column"
        >
          <canvas id="response-chart"></canvas>
        </div>
      </div>
    </div>
    <div class="ui equal width relaxed padded grid">
      <div class="padding-sm"></div>
      <div class="row">
        <div class="eleven wide computer sixteen wide tablet sixteen wide mobile column">
          <div class="l-app-question">
            <div class="q" v-for="(question, index) in questionList" :key="question.id">
              <div class="q-no">
                <span class="ui orange label" v-if="question.question_typeID == 1">{{
                  index + 1
                }}</span>
                <span class="ui teal label" v-if="question.question_typeID == 2">{{
                  index + 1
                }}</span>
                <span class="ui grey label" v-if="question.question_typeID == 3">{{
                  index + 1
                }}</span>
                <span class="ui green label" v-if="question.question_typeID == 4">{{
                  index + 1
                }}</span>
              </div>
              <div class="q-info">
                <div class="q-title">{{ question.question }}</div>
                <reportData
                  :options="question.options"
                  :questionID="question.id"
                  :questionTypeId="question.question_typeID"
                  :indexN="index"
                  :question="question.question"
                  :appIDS="appID"
                  :totalContacts="question.totalContacts"
                  :responseCount="question.totalResponse"
                ></reportData>
              </div>
            </div>
          </div>
        </div>
        <div class="five wide computer sixteen wide tablet sixteen wide mobile column">
          <div class="ui segment height-sm">
            <h3 class="text-title">Channels</h3>
            <p>Users will use these channels to complete the survey</p>
            <div class="ui checkbox margin-right-lg">
              <input type="checkbox" name="example" disabled v-model="smsState" />
              <label>SMS</label>
            </div>
            <div class="ui checkbox margin-right-lg">
              <input type="checkbox" name="example" disabled v-model="whatsAppState" />
              <label>WhatsApp</label>
            </div>
            <div class="ui checkbox margin-right-lg">
              <input type="checkbox" name="example" disabled />
              <label>USSD</label>
            </div>
            <div class="ui checkbox">
              <input type="checkbox" name="example" disabled />
              <label>Web</label>
            </div>
          </div>
          <div class="ui segment height-md">
            <h3 class="text-title">Rewards</h3>
            <span class="text-grey-light">No data</span>
          </div>
          <div class="ui segment">
            <h3 class="text-title">Rewards</h3>
            <span class="text-grey-light">No data</span>
          </div>
          <div class="ui segment">
            <h3 class="text-title">Age</h3>
            <span class="text-grey-light">No data</span>
          </div>
          <div class="ui segment">
            <h3 class="text-title">Gender</h3>
            <span class="text-grey-light">No data</span>
          </div>
          <div class="ui segment">
            <h3 class="text-title">Location</h3>
            <span class="text-grey-light">No data</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import reportData from "./survey-report-data";
import store from "@/store";
import Choices from "choices.js";
import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
import alertify from "alertifyjs";
import loader from "@/components/loader";
import format from "date-fns/format";
import moment from "moment";
import Chart from "chart.js";

export default {
  data() {
    return {
      totalQuestion: 0,
      offset: 0,
      limit: 10,
      questionnaireID: "",
      loading: false,
      survey: [],
      questionList: [],
      appID: "",
      progressRate: "",
      responded: "",
      totalcontacts: "",
      averageTime: 0.0,
      incomplete: 0,
      rejected: 0,
      smsState: false,
      whatsAppState: false,
      completed: "",
      dateOne: null,
      dateTwo: null,
      dateFormat: "D MMM",
      questionnnaireType: "",
      surveySummary: [],
    };
  },
  components: {
    loader,
    reportData,
  },
  mounted() {
    let vm = this;
    if (!vm.$cookies.get("key") || vm.$cookies.get("key") == null) {
      vm.$router.push({
        name: "home",
      });
    }
    vm.survey = store.state.selectSurvey;
    vm.questionnnaireType = store.state.selectSurvey.questionnnaireType;
    if (vm.survey == null || vm.survey == "") {
      vm.getCampaignRecords(vm.$route.params.id);
      console.log("am here at " + vm.$route.params.id);
      //vm.$router.push({name: 'surveys'});
    } else {
      if (vm.survey.channel == "SMS") {
        vm.smsState = true;
      }
      if (vm.survey.channel == "WHATSAPP") {
        vm.whatsAppState = true;
      }
      vm.questionnaireID = store.state.selectSurvey.questionnare_id;
      vm.appID = store.state.selectSurvey.app_id;
      vm.getQuestion();
      vm.getSummary();
      vm.getResponseGraph();
    }
  },
  methods: {
    getQuestion() {
      let vm = this;
      if (vm.totalQuestion == 0 || vm.totalQuestion >= (vm.offset - 1) * vm.limit) {
        vm.offset++;
        vm.loading = true;
        $.post({
          url: store.state.rootURLBulkAPI + "v3/survey/view/question",
          type: "POST",
          data: JSON.stringify({
            apiKey: vm.$cookies.get("key"),
            questionnare_id: vm.questionnaireID,
            offset: vm.offset,
            limit: vm.limit,
            offset: vm.offset,
            appID: vm.appID,
            questionnnaireType: vm.questionnnaireType,
          }),
          success: function (response, status, jQxhr) {
            vm.loading = false;
            var resp = response.data.data;
            if (response.data.code != 200) {
              vm.offset = 0;
              alertify.set("notifier", "position", "top-right");
              //alertify.error(response.data.message);
              //vm.totalMessage = 0;
            } else {
              vm.totalQuestion = response.data.total;
              var quest = [];
              for (var i = 0; i < resp.length; i++) {
                // console.log(" loop " + i + " " + JSON.stringify(resp[i]));
                resp[i].created_at = format(resp[i].created_at, "DD MMM YYYY");
                var options = resp[i].options;
                if (options != null) {
                  var optionArry = options.split("||");
                  var n = 0;
                  var optionList = [];
                  optionArry.forEach((element) => {
                    element = element.split("|");
                    var list = {
                      id: element[2],
                      value: element[0],
                    };

                    optionList.push(list);
                  });
                  resp[i].options = optionList;
                }
                vm.questionList.push(resp[i]);
              }
              store.commit("setSelectQuestion", vm.questionList);

              setTimeout(function () {
                $(".ui.small.dropdown").dropdown();
                $(".question-box").on("input", function () {
                  var scroll_height = $(".question-box").get(0).scrollHeight;
                  $(".question-box").css("height", scroll_height + "px");
                });
              }, 100);
            }
          },
          error: function (jQxhr, status, error) {
            vm.loading = false;
            vm.offset = 0;
            // alertify.set('notifier', 'position', 'top-right');
            // alertify.error(jQxhr.responseJSON.statusDescription);
          },
        });
      }
    },
    getSummary() {
      let vm = this;
      $.post({
        url: store.state.rootURLBulkAPI + "v3/survey/view/summary",
        type: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key"),
          app_id: vm.appID,
        }),
        success: function (response, status, jQxhr) {
          vm.loading = false;
          var resp = response.data.data;
          if (response.data.code != 200) {
            alertify.set("notifier", "position", "top-right");
            alertify.error(response.data.message);
          } else {
            vm.totalSurvey = response.data.data[0].count;
            for (var i = 0; i < resp.length; i++) {
              // console.log(" loop " + i + " " + JSON.stringify(resp[i]));
              vm.progressRate =
                resp[i].contacts != 0
                  ? (
                      (parseInt(resp[i].complete) / parseInt(resp[i].contacts)) *
                      100
                    ).toFixed(0)
                  : 0;
              vm.responded = resp[i].response != null ? resp[i].response : 0;
              vm.completed = resp[i].complete != null ? resp[i].complete : 0;
              vm.totalcontacts =
                parseInt(resp[i].contacts) != null ? parseInt(resp[i].contacts) : 0;
              vm.averageTime = resp[i].averageTime;
              vm.incomplete = resp[i].incomplete != null ? resp[i].incomplete : 0;
              vm.rejected = resp[i].rejected != null ? resp[i].rejected : 0;
              resp[i].complete = vm.completed =
                resp[i].complete != null ? resp[i].complete : 0;
              resp[i].progress = resp[i].progress != null ? resp[i].progress : 0;
              vm.surveySummary.push(resp[i]);
            }
          }
        },
        error: function (jQxhr, status, error) {
          //alertify.set('notifier', 'position', 'top-right');
          //alertify.error(jQxhr.responseJSON.statusDescription);
        },
      });
    },
    getCampaignRecords(appID) {
      console.log("I have reached here");
      let vm = this;
      $.post({
        url: store.state.rootURLBulkAPI + "v3/survey/query",
        type: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key"),
          app_id: appID,
        }),
        success: function (response, status, jQxhr) {
          var survey = response.data.data;
          vm.questionnaireID = survey[0].questionnare_id;
          vm.appID = survey[0].app_id;
          vm.getQuestion();
          vm.getSummary();
          vm.getResponseGraph();
        },
        error: function (jQxhr, status, error) {
          alertify.set("notifier", "position", "top-right");
          alertify.error(jQxhr.responseJSON.statusDescription);
        },
      });
    },
    getResponseGraph() {
      let vm = this;
      $.post({
        url: store.state.rootURLBulkAPI + "v3/survey/response/graph",
        type: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key"),
          app_id: vm.appID,
          days: 20,
          start: vm.dateOne,
          stop: vm.dateTwo,
        }),
        success: function (response, status, jQxhr) {
          let yData = [];
          let xDate = [];
          let result = response.data.data;

          result.forEach(function (data) {
            yData.push(parseInt(data["COUNT"]));
            xDate.push(data["day"]);
          });
          var responseChartContainer = $("#response-chart");
          var responseChart = new Chart(responseChartContainer, {
            type: "line",
            data: {
              labels: xDate,
              datasets: [
                {
                  label: "Responses",
                  data: yData,
                  backgroundColor: "rgba(38,168,66, 1)",
                  borderColor: "#26A842",
                  borderWidth: 1,
                  lineTension: 0,
                },
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: true,
                align: "end",
                position: "top",
              },
              layout: {
                padding: {
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                },
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
                /*xAxes: [{
                                gridLines: {
                                    display: false
                                },
                            }]*/
              },
            },
          });
        },
        error: function (jQxhr, status, error) {
          //alertify.set('notifier', 'position', 'top-right');
          //alertify.error(jQxhr.responseJSON.statusDescription);
        },
      });
    },
    formatDates(dateOne, dateTwo) {
      let formattedDates = "";
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat);
      }
      if (dateTwo) {
        formattedDates += " - " + format(dateTwo, this.dateFormat);
      }
      return formattedDates;
    },
  },
};
</script>
