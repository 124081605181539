<template>
    <div class="ui tiny modal" id="modal-client-new-message">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">Send Message</h2>
                <h4>You can send message to {{originalName}}</h4>
            </div>
            <div class="ui negative message" v-show="sendSMSStatus">{{sendSMSResponseMessage}}</div>
            <div class="ui centered container grid">
                <div class="column">
                    <div class="ui form">
                        <div class="field">
                            <label>Select Sender ID </label>
                            <select class="ui fluid dropdown" v-model="senderId">
                                    <option value="">From</option>
                                    <option v-for="s_id in dataLists" v-show="dataLists.length > 0 && s_id.status == 13" :key="s_id.short_code">{{s_id.short_code}}</option>
                                    <option v-show="dataLists.length == 0"  value="VasPro">VasPro</option>
                                </select>
                        </div>
                        <div class="field">
                            <div v-show="templateLists.length > 0">
                                <label>Select Template</label>
                                <div class="column">
                                    <select class="ui fluid dropdown" v-model="template" v-on:change="setComposeMessage(template)">
                                        <option value="">Select Template</option>
                                        <option v-for="s_id in templateLists" v-show="templateLists.length > 0" :key="s_id.id" :value="s_id.template">{{s_id.template_title}}</option>
    
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <label>Type your message</label>
                            <div class="ui top attached segment" id="textarea-sms">
                                <textarea class="l-type-message" rows="3" placeholder="Type your SMS here" v-model="composedMessage"></textarea>
                            </div>
                            <div class="ui bottom attached tiny secondary segment">
                                <div class="ui equal width relaxed grid">
                                    <div class="row">
                                        <div class="column">
                                            <small>{{messageCount}} characters 
                                                    <div id="textarea-sms" v-show="dataLists.length > 0">(14 include OPT Out)</div>
                                                    <div id="textarea-sms" v-show="dataLists.length == 0">(32 include OPT and Sent By)</div>
                                                </small>
                                        </div>
                                        <div class="right aligned column"> <small>{{messagePages}}</small> Pages</div>
                                    </div>
    
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <div class="ui toggle checkbox">
                                <input type="checkbox" name="schedule" v-model="isScheduled">
                                <label><strong>Schedule Message</strong></label>
                                <p class="padding-top-sm"><small>Select date and time to send message</small></p>
                            </div>
                        </div>
    
                        <div class="two fields" v-show="isScheduled">
                            <div class="field" v-show="isScheduled">
                                <label>Date</label>
                                <div class="ui calendar">
                                    <div class="ui input left icon">
                                        <i class="calendar icon"></i>
                                        <input type="date" placeholder="Select date" v-model="scheduleDate">
                                    </div>
                                </div>
                            </div>
                            <div class="field" v-show="isScheduled">
                                <label>Time</label>
                                <div class="ui calendar">
                                    <div class="ui input left icon">
                                        <i class="time icon"></i>
                                        <input type="time" v-model="scheduledTime" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui button" @click="hideModal()">Cancel</button>
            <button @click="sendmessage(1)" v-show="isScheduled" class="ui bg-primary button" id="button-schedule-message" :disabled="!sendStatus">Schedule Message</button>
            <button @click="sendmessage(0)" v-show="!isScheduled" class="ui bg-primary button" id="button-send-message" :disabled="!sendStatus">Send Message</button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    textarea {
        width: 100%;
        border: none;
        outline: none;
        font-size: 1.4em;
        &::placeholder {
            color: #ccc;
        }
    }
    
    .row {
        padding-top: 0 !important;
    }
</style>

<script>
    import {
        mapGetters
    } from 'vuex'
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import Choices from "choices.js";
    import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
    import format from 'date-fns/format';
    import moment from "moment";
    import Vue from "vue";
    import alertify from 'alertifyjs';
    import _ from "lodash";
    import Dropzone from "dropzone";
    
    export default {
        data() {
            return {
                //selectedList: "",
                composedMessage: '',
                listName: "",
                messagePrefix: "CO",
                droppedFile: null,
                schedule_date: new Date(),
                schedule_time: '',
                isScheduled: false,
                dateOne: null,
                senderId: "",
                token: "",
                list_id: "",
                list_name: "",
                status: "",
                limit: "",
                offset: "",
                order: "",
                sendSMSStatus: false,
                dateTwo: "",
                dateOne: "",
                sendSMSResponseMessage: "",
                dataLists: [],
                endMessage: "",
                composeTotalCharacters: 0,
                templateLists: [],
                template: "",
                scheduledTime: null,
                scheduleDate: null,
                sendStatus: true,
                contactNumber: []
    
    
            }
        },
        watch: {
            scheduleDate() {
                let vm = this;
                console.log(vm.scheduleDate);
                return
            }
        },
        mounted() {
            const vm = this;
            $(".ui.dropdown").dropdown('refresh');
            vm.token = vm.$cookies.get("key");
    
            vm.getTemplateList();
            vm.fetchData();
        },
        computed: {
    
            originalName() {
                var vm = this;
                if (store.state.selectMoveClientDetails != "") {
                    console.log(JSON.stringify(store.state.selectMoveClientDetails));
                    vm.contactNumber = store.state.selectMoveClientDetails.msisdn;
                }
    
                return vm.contactNumber;
            },
            messageCount() {
                //var defaultCount = 14;
                var phoneNu = "";
                phoneNu = window.atob(this.$cookies.get("username"));
                var phone = "";
    
                if (this.dataLists.length > 0) {
                    this.endMessage = " STOP*456*9*5#";
                    this.composeTotalCharacters = this.endMessage.length + this.composedMessage.length;
                } else {
                    this.endMessage = " STOP*456*9*5#";
                    this.composeTotalCharacters = this.endMessage.length + 18 + this.composedMessage.length;
                }
    
                return this.composeTotalCharacters;
            },
            messagePages() {
    
                if (this.composedMessage.length > 0) {
                    $('#textarea-sms').removeClass("red");
                }
    
                if (this.composeTotalCharacters == 0) {
                    return 0;
                } else if (this.composeTotalCharacters / 160 <= 1) {
                    return 1;
                } else if (this.composeTotalCharacters / 160 <= 2) {
                    return 2;
                } else if (this.composeTotalCharacters / 160 <= 3) {
                    return 3;
                } else if (this.composeTotalCharacters / 160 <= 4) {
                    return 4;
                } else if (this.composeTotalCharacters / 160 <= 5) {
                    return 5;
                } else {
                    return " Max ";
                }
            },
        },
        methods: {
            formatDates(dateOne, dateTwo) {
                let formattedDates = ''
                if (dateOne) {
                    formattedDates = format(dateOne, this.dateFormat)
                }
                if (dateTwo) {
                    formattedDates += ' - ' + format(dateTwo, this.dateFormat)
                }
                return formattedDates
            },
            sendmessage(is_scheduled) {
                //console.log("jSON SELECTLIST  "+JSON.stringify(vm.selectedList));
                var vm = this;
                vm.sendStatus = false;
                if (!vm.composedMessage) {
                    $('#textarea-sms').addClass("red");
                    alertify.error("Message is required");
                    return;
                }
                if (this.isScheduled) {
                    $('#button-schedule-message').html('<i class="fas fa-circle-notch fa-spin"></i> Scheduling Message ...');
                } else {
                    $('#button-send-message').html('<i class="fas fa-circle-notch fa-spin"></i> Sending Message ...');
                }
    
                $.post({
                    url: bulkstore.state.bulksmsUrl + 'BulkSMS/create',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        shortCode: vm.senderId,
                        message: !vm.composedMessage.endsWith(vm.endMessage) ?
                            vm.composedMessage + "" + vm.endMessage : vm.composedMessage,
                        contacts: {
                            recipients: vm.contactNumber
                        },
                        scheduleDate: vm.scheduleDate,
                        scheduleTime: vm.scheduledTime,
                        isScheduled: vm.isScheduled,
                        callbackURL: "",
                        origin: 'WEB',
                        enqueue: 1,
                    }),
                    success: function(data, status, jQxhr) {
                        vm.composedMessage = "";
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.success(data.data.message);
                        vm.$router.push({
                                name: 'bulk-messages'
                        });
                        vm.hideModal();
                    },
                    error: function(jQxhr, status, error) {
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                        vm.sendSMSStatus = true;
                        vm.sendSMSResponseMessage = jQxhr.responseJSON.statusDescription;

    
                    }
                });
            },
            getTemplateList() {
                var vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/account/templates/list",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        template_title: "",
                        template: "",
                        template_status: 1,
    
                    }),
                    success: function(response, status, jQxhr) {
                        if (response.data.data != "") {
                            vm.templateLists = response.data.data;
                        }
                    },
                    error: function(jQxhr, status, error) {
                        vm.templateLists = [];
                    }
                });
            },
            setComposeMessage(template) {
                let vm = this;
                vm.saveTemplateStatus = false;
                vm.composedMessage = template;
                //console.log(vm.saveTamplate);
    
            },
            fetchData() {
                //console.log("TEST");
                // this.dataLists = [];
                var vmBalance = this;
    
                var vm = this;
    
                $.post({
                    url: bulkstore.state.bulksmsUrl + 'senderId/list',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        status: 13,
                        p_status: 4
                    }),
                    success: function(response, status, jQxhr) {
                        vm.dataLists = response.data.data;
                        //console.log(JSON.stringify(vm.dataLists));
                        //alertify.set('notifier', 'position', 'top-right');
                        //alertify.success(response.data.message);
                        //console.log("test message " + JSON.stringify(response.data.data));
                    },
                    error: function(jQxhr, status, error) {
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.data.message);
                        // console.log(" status bulk " + status + " data " + JSON.stringify(jQxhr.responseJSON.statusDescription));
                    }
                });
    
            },
            hideModal() {
                $('.ui.modal#modal-client-new-message').modal('hide');
            }
        }
    }
</script>
