<template>
    <div class="ui tiny modal" id="modal-senderID-Pay">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">Sender ID: {{originalName}} Payment Confirmation</h2>
            </div>
            <div class="ui negative message" v-show="permissionStatus">
                {{permissionStatusDesc}}  
            </div>
            <div class="ui equal width centered container grid">
                <div class="column">
                    <div class="ui form">
                        <div class="field">
                             <h3>Amount: {{amount}} </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui cancel button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary button" @click="completePayment()">Confirm</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    import reportsVue from '../bulk/reports.vue';
    export default {
        data() {
            return {
                permissionStatus: false,
                permissionStatusDesc: "",
                senderID:"",
                selectSenderID:"",
                instruction:"",
                amount:0,
                invoice_id:""
            }
        },
        mounted() {
            let vm = this;
           // vm.checkServices(service);
        },
        computed: {
            originalName() {
                var vm = this;
                vm.selectSenderID = store.state.senderIDSelect;
                vm.amount = vm.selectSenderID.amount;
                vm.invoice_id = vm.selectSenderID.invoice_id;
                return vm.selectSenderID.short_code;
    
            }
        },
        methods: {
          
            completePayment() {
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/payment/initiate",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        invoice_id: vm.invoice_id
                    }),
                    success: function(response, status, jQxhr) {
                        if (response.data.code != 200) {
                            alertify.set("notifier", "position", "top-right");
                            alertify.error(response.data.message);
                            vm.permissionStatus = true;
                            vm.permissionStatusDesc = response.data.message;
                            vm.instruction = reponse.data.text;
                        } else {
                            vm.hideModal();
                            alertify.set("notifier", "position", "top-right");
                            alertify.success(response.data.message);
                        }
                    },
                    error: function(jQxhr, status, error) {
                       // console.log("failed "+JSON.stringify(jQxhr));
                        alertify.set("notifier", "position", "top-right");
                        alertify.error(jQxhr.responseJSON.statusDescription);
                        vm.permissionStatus = true;
                        vm.permissionStatusDesc = jQxhr.responseJSON.statusDescription;
                    }
                });
            },
            hideModal() {
                $('.ui.modal#modal-senderID-Pay').modal('hide');
            }
        }
    }
</script>
