<template>
    <div class="ui tiny modal" id="modal-whatsApp-edit-message">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">Edit Message </h2>
                <h4>{{messageType}}</h4>
            </div>
            <div class="ui negative message" v-show="errorStatus">{{ErrorDesc}}</div>
            <div class="ui centered container grid">
                <div class="column">
                    <div class="ui form">
                        <div class="field">
                            <label>* Type</label>
                                <select class="selection dropdown" v-model="type">
                                    <option value="TEXT">TEXT</option>
                                    <option value="IMAGE">IMAGE</option>
                                    <option value="LOCATION">LOCATION</option>
                                    <option value="FILE">FILE</option>
                                </select>
                            
                        </div>
                        <div class="field" v-show="type=='LOCATION'">
                            <label>Longitude</label>
                            <div class="ui large input">
                                 <input type="text" placeholder="Longitude" v-model="longitudeNo" >
                            </div>
                            <label>Latitude</label>
                            <div class="ui large input">
                                 <input type="text" placeholder="Latitude" v-model="latitudeNo">
                            </div>
                            <label>Location</label>
                            <div class="ui large input">
                                <input type="text" placeholder="Location Name" v-model="locName" >
                            </div>
                            <label>Address</label>
                            <div class="ui large input">
                                 <input type="text" placeholder="Address" v-model="addressName" >
                            </div>
                        </div>
                        <div class="field" v-show="type == 'IMAGE' || type == 'FILE'">
                            <label v-show="type == 'IMAGE'">Image Url</label>
                            <label v-show="type == 'FILE'">File Url</label>
                            <div class="ui large input">
                               <input type="text" placeholder=""  required v-model="imageURL" />
                            </div>
                        </div>
                        <div class="required field" v-show="type == 'IMAGE' || type == 'FILE' " id="input-description-name">
                            <div class="field">
                                <label>Caption</label>
                                <div class="ui large input">
                                   <textarea class="l-type" placeholder="Type your SMS here" v-model="caption"></textarea>
                                </div>
                            </div>
                         </div>
                        <div class="required field" v-show="type == 'TEXT'" id="input-description-name">
                            <div class="field">
                                <label>Message</label>
                                <div class="ui large input">
                                   <textarea class="l-type" placeholder="Type your SMS here" v-model="message"></textarea>
                                </div>
                            </div>
                         </div>
                         <div class="field">
                            <label>* Keyword</label>
                            <div class="ui input">
                                <input type="text" v-model="keyword"/>
                            </div>
                        </div>
                        <div class="field">
                            <label>Reply Keywords <br /><small>Numeric Keywords separated by full colon (:). e.g 1:2:3</small></label>
                            
                            <div class="ui input">
                                <input type="text" v-model="keywords" required/>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui large button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary large button" @click="updateWhatsAppMessage()">update</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    export default {
        data() {
            return {
                keyword: "",
                newContact: true,
                agreeTerms: false,
                title: "",
                msisdn:"",
                callbackUrl: "",
                companyDetails: '',
                authorization:"",
                imageURL:null,
                errorStatus:false,
                ErrorDesc:"",
                caption:"",
                type:"",
                message:"",
                messageID:"",
                longitudeNo:"",
                latitudeNo:"",
                locName:"",
                addressName:"",
                keywords:""
            }
        },
        mounted() {
            let vm = this;

        },
        computed:{
           messageType() {
               var vm =this;
               vm.type = store.state.selectWhatsAppMessage.type;
               vm.message = store.state.selectWhatsAppMessage.message;
               vm.imageURL = store.state.selectWhatsAppMessage.message;
               vm.caption = store.state.selectWhatsAppMessage.caption;
               vm.messageID = store.state.selectWhatsAppMessage.id;
               vm.keyword = store.state.selectWhatsAppMessage.ifKeyword;
               vm.keywords = store.state.selectWhatsAppMessage.keywords;
               vm.longitudeNo = store.state.selectWhatsAppMessage.longitudeNo;
               vm.latitudeNo = store.state.selectWhatsAppMessage.latitudeNo;
               vm.locName = store.state.selectWhatsAppMessage.locName;
               vm.addressName = store.state.selectWhatsAppMessage.addressName;
               //Console.log("Custom Fil" +bulkstore.state.selectedList.list_name);
            }
       },
        methods: {
            updateWhatsAppMessage(){
                
                let vm = this;
                if(vm.type =="IMAGE" || vm.type =="FILE"){
                    vm.message = vm.imageURL;
                }
                
                    $.post({
                        url: store.state.rootURLBulkAPI + 'v3/whatsApp/message/edit',
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: vm.$cookies.get("key"),
                            messageID: vm.messageID,
                            type: vm.type,
                            message: vm.message,
                            caption: vm.caption,
                            ifKeyword: vm.keyword,
                            keywords:vm.keywords,
                            longitudeNo: vm.longitudeNo,
                            latitudeNo: vm.latitudeNo,
                            locName: vm.locName,
                            addressName: vm.addressName
                        }),
                        success: function(response, status, jQxhr) {
                        alertify.set("notifier", "position", "top-right");
                        alertify.success(response.data.message);
                        vm.hideModal();
                        },
                        error: function(jQxhr, status, error) {
                            vm.errorStatus = true;
                            vm.ErrorDesc = jQxhr.responseJSON.statusDescription;
                            alertify.set("notifier", "position", "top-right");
                            alertify.error(jQxhr.responseJSON.statusDescription);
                        }
                    });
                

            },
            
            hideModal() {
                $('.ui.modal#modal-whatsApp-edit-message').modal('hide');
            }
        }
    }
</script>
