<template>
    <div class="ui tiny modal" id="modal-create-survey">
        <i class="close icon"></i>
        <div class="">
            <div class="ui equal width relaxed padded grid">
                <div class="row">
                    <div class="column">
                        <h2 class="text-primary">Create a survey</h2>
                        <p>Fill in the details below</p>
                    </div>
                    <div class="ui negative message" v-show="SurveyStatus">
                            {{SurveyStatusDesc}} 
                    </div>
                </div>
                <div class="row bg-grey-lighter border-top">
                    <div class="column">
                       <div class="ui form">
                           <div class="required field">
                                <label>Survey title</label>
                                <div class="ui fluid large input">
                                    <input placeholder="eg. Customer satisfaction" v-model="surveyName"/>
                                </div>
                           </div>
                           <div class="required field">
                               <label>Purpose of the survey</label>
                               <select class="ui dropdown" v-model="surveyObjectives">
                                   <option value="Customer Satisfaction">Customer Satisfaction</option>
                                   <option value="Data Collection">Data collection</option>
                                   <option value="Product Reviews">Product Reviews</option>
                               </select>
                           </div>

                           <div class="required field" id="input-suvery">
                                <label>Suvery Period</label>
                                <div class="ui small input datepicker-trigger margin-left-xs">
                                    <input type="text" id="datepicker-trigger" placeholder="Select date" :value="formatDates(dateOne, dateTwo)">
                                    <AirbnbStyleDatepicker :trigger-element-id="'datepicker-trigger'" :mode="'range'" :fullscreen-mobile="true" :months-to-show="2" 
                                    :date-one="dateOne" :date-two="dateTwo" :min-date="startDate" :close-after-select="false" @date-one-selected="val => { dateOne = val }" 
                                    @date-two-selected="val => { dateTwo = val }" 
                                    />
                                </div>
                            </div>
                            <div class="required field">
                                <label>Shortcode</label>
                                <select class="ui fluid dropdown" v-model="shortcode">
                                    <option value="">From</option>
                                    <option v-for="s_id in dataLists" v-show="dataLists.length > 0 && s_id.status == 13" :key="s_id.short_code">{{s_id.short_code}}</option>
                                    <option v-if="dataLists.length == 0"  value="40399">40399</option>
                                </select>
                            </div>
                             <div class="field">
                                <div class="ui toggle checkbox">
                                    <input type="checkbox" name="schedule" v-model="sendIncentive">
                                    <label><strong>Send Incentive </strong></label>
                                </div>
                            </div>
                            <div class="two fields" v-show="sendIncentive">
                                <div class="field">
                                    <label>Incentive</label>
                                    <select class="ui dropdown" v-model="utility_id">
                                        <option value="4">Airtime</option>
                                    </select>
                                </div>
                                <div class="field">
                                    <label>Incentive Type</label>
                                    <select class="ui dropdown" v-model="incentiveType">
                                        <option value="2">At the End of Survey</option>
                                    </select>
                                </div>
                            </div>
                            <div class="two fields" v-show="sendIncentive">
                                <div class="field">
                                    <label>Incentive Amount</label>
                                    <div class="ui fluid large input">
                                        <input type="number" placeholder="100" v-model="incentiveAmount"/>
                                    </div>
                                </div>
                            </div>
                       </div>
                       <div class="padding-md"></div>
                    </div>
                </div>
                <div class="row bg-grey-lighter border-top">
                    <div class="column">
                        <h3 class="text-title">Questionnaire</h3>
                        <p class="text-grey">The questionnaire will be used with this survey. It will be saved as a template for future surveys</p>
                       <div class="ui form">
                           <div class="required field"  >
                               <select class="ui dropdown" v-model="questionnaire" >
                                   <option value="">Select a questionnaire or create new *</option>
                                   <option v-for="s_id in questionnaireList" v-show="questionnaireList.length > 0 && s_id.status == 1" :key="s_id.id" :value="s_id.id">{{s_id.questionnare_name}}</option>
                                   <option value="account" class="text-accent"><i class="icon plus"></i> Create new questionnaire</option>
                               </select>
                           </div>
                           <div class="required field" v-show="questionnaire == 'account'">
                                <label>Questionnaire Name</label>
                                <div class="ui fluid large input">
                                    <input placeholder="eg. Customer Questionnaire" v-model="questionnareName" />
                                </div>
                           </div>
                           <h4 class="padding-bottom-md padding-top-md" v-show="questionnaire == 'account'">Type of questionnaire</h4>
                           <div class="required field " v-show="questionnaire == 'account'">
                               <select class="ui dropdown" v-model="questionType">
                                   <option value="">Select a questionnaire type</option>
                                   <option v-for="s_id in suverysType" v-show="suverysType.length > 0 && s_id.status == 1" :key="s_id.type_id" :value="s_id.type_id">{{s_id.survey_name}}</option>
                               </select>
                           </div>
                          
                            
                       </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui left floated button" @click="hideModal()">Discard</button>
            <button id="button-create-survey"  :disabled="createStatus" class="ui bg-primary close button" v-show="questionnaire != 'account'" @click="applyCopyQuestionnaire()">Create Survey</button>
            <button id="button-create-survey-questionnaire"  :disabled="createStatus" class="ui bg-primary close button" v-show="questionnaire == 'account'" @click="applyquestionnaire()">Create Survey & Questionnaire</button>
        </div>
    </div>
    
</template>
<script>
import bulkstore from "@/bulk/bulksms-state";
import Router from 'vue-router';
import alertify from 'alertifyjs';
import store from "@/store";
import Choices from "choices.js";
import format from 'date-fns/format';
export default {
    data() {
        return {
            SurveyStatus: false,
            sendIncentive:false,
            incentiveType:"2",
            incentiveAmount:50,
            utility_id:4,
            SurveyStatusDesc:"",
            suverysType: [],
            questionnaireList:[],
            questionnaire:null,
            questionnaireNew:null,
            questionType:"",
            questionnareName:"",
            surveyName:"",
            surveyObjectives:"",
            dateOne: "",
            dateTwo: "",
            dateFormat: 'D MMM',
            surveySummary:[],
            app_id:"",
            startDate:null,
            createStatus:false,
            dataLists:[],
            shortcode:"40399"
        }
        },
    mounted() {
        $(".ui.dropdown").dropdown();
        this.getSurveyType();
        this.getQuestionnaire()
        this.fetchData();
        var d = new Date();
        this.startDate = format(d.setDate(d.getDate() + 1), this.dateFormatTwo);
    },
    methods:{
        fetchData() {
        var vm = this;

        $.post({
            url: bulkstore.state.bulksmsUrl + 'senderId/list',
            type: "POST",
            data: JSON.stringify({
                apiKey: this.$cookies.get("key"),
                status: 13,
                p_status: 4,
                type_id: 4
            }),
            success: function(response, status, jQxhr) {
                vm.dataLists = response.data.data;
            },
            error: function(jQxhr, status, error) {
                alertify.set('notifier', 'position', 'top-right');
                alertify.error(jQxhr.responseJSON.data.message);
            }
        });

    },
        getSurveyType() {
            let vm = this;
            $.post({
                url: store.state.rootURLBulkAPI + "v3/survey/types",
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key")
                }),
                success: function(response, status, jQxhr) {
                    vm.loading = false;
                    var resp = response.data.data;
                    if (response.data.code != 200) {
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(response.data.message);

                    } else {
                        for (var i = 0; i < resp.length; i++) {
                            vm.suverysType.push(resp[i]);
                        }
                    }
                    setTimeout(function() {
                        $(".ui.dropdown").dropdown();
                    }, 100)
                },
                error: function(jQxhr, status, error) {
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.error(jQxhr.responseJSON.statusDescription);
                }
            });
        },
        getQuestionnaire() {
            let vm = this;
            $.post({
                url: store.state.rootURLBulkAPI + "v3/survey/view/questionaire",
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key")
                }),
                success: function(response, status, jQxhr) {
                    vm.loading = false;
                    var resp = response.data.data;
                    if (response.data.code != 200) {
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(response.data.message);

                    } else {
                        for (var i = 0; i < resp.length; i++) {
                            vm.questionnaireList.push(resp[i]);
                        }
                    }
                    setTimeout(function() {
                        $(".ui.dropdown").dropdown();
                    }, 100)
                },
                error: function(jQxhr, status, error) {
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.error(jQxhr.responseJSON.statusDescription);
                }
            });
        },
        applyquestionnaire(){
            let vm = this;
           
                $('#button-create-survey').html('<i class="fas fa-circle-notch fa-spin"></i> Create Survey...');
                $('#button-create-survey-questionnaire').html('<i class="fas fa-circle-notch fa-spin"></i> Create Survey & Questionnaire ...');
                vm.createStatus = true;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/questoinnaire/create",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        questionnare_name:vm.questionnareName,
                        type_id:vm.questionType,
                        description: "Questionnaire Application :"+vm.questionnareName
                    }),
                    success: function(response, status, jQxhr) {
                        vm.questionnaireNew = response.data.data.id;
                        vm.applySurvey();
                    },
                    error: function(jQxhr, status, error) {
                        vm.closeLoader();
                        vm.SurveyStatus = true;
                        vm.SurveyStatusDesc = jQxhr.responseJSON.statusDescription;
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            
        },
        applyCopyQuestionnaire(){
            let vm = this;
            $.post({
                url: store.state.rootURLBulkAPI + "v3/survey/questoinnaire/copy",
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key"),
                    questionnaireID:vm.questionnaire,
                    surveyName: vm.surveyName
                }),
                success: function(response, status, jQxhr) {
                    vm.questionnaireNew = response.data.data.questionnaire_id;
                    vm.applySurvey();
                },
                error: function(jQxhr, status, error) {
                    vm.closeLoader();
                    vm.SurveyStatus = true;
                    vm.SurveyStatusDesc = jQxhr.responseJSON.statusDescription;
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.error(jQxhr.responseJSON.statusDescription);
                }
            });
        },
        applySurvey(){
            let vm = this;
            $.post({
                url: store.state.rootURLBulkAPI + "v3/survey/create",
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("key"),
                    survey_name:vm.surveyName,
                    questionnaire_id:vm.questionnaireNew,
                    survey_objectives: vm.surveyObjectives,
                    start_date: vm.dateOne,
                    end_date: vm.dateTwo,
                    targeted_respondents:1000,
                    is_incentive: vm.sendIncentive,
                    incentive_amount: vm.incentiveAmount,
                    incentive_type:vm.incentiveType,
                    utility_id: vm.utility_id,
                    short_code:  vm.shortcode,
                    channel:'SMS'
                }),
                success: function(response, status, jQxhr) {
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.success(response.data.message);
                    $(".ui.modal#modal-create-survey").modal("hide");
                    vm.closeLoader();
                    vm.getsurveyData();
                    vm.$router.push({name: 'survey-create-new', params: {id: response.data.data.id}});
                },
                error: function(jQxhr, status, error) {
                    vm.closeLoader();
                    vm.SurveyStatus = true;
                    vm.SurveyStatusDesc = jQxhr.responseJSON.data.message;
                    alertify.set('notifier', 'position', 'top-right');
                    alertify.error(jQxhr.responseJSON.data.message);
                }
            });

        },
        hideModal(){
            $(".ui.modal#modal-create-survey").modal("hide");
        },
        formatDates(dateOne, dateTwo) {
            let formattedDates = ''
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat)
            }
            if (dateTwo) {
                formattedDates += ' - ' + format(dateTwo, this.dateFormat)
            }
            return formattedDates
        },
        getsurveyData(){
            let vm = this;
            $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/view/summary",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        app_id: vm.app_id
                    }),
                    success: function(response, status, jQxhr) {
                        var resp = response.data.data;
                        if (response.data.code != 200) {
                            alertify.set('notifier', 'position', 'top-right');
                            alertify.error(response.data.message);
    
                        } else {
                            var responseRate = 0;
                            for (var i = 0; i < resp.length; i++) {
                                // console.log(" loop " + i + " " + JSON.stringify(resp[i]));
                                resp[i].progressRate = resp[i].contacts !=0 ? ((parseInt(resp[i].complete) / parseInt(resp[i].contacts)) *100).toFixed(0) :0;
                                resp[i].response = resp[i].response !=null ? resp[i].response : 0;
                                resp[i].complete = resp[i].complete !=null ? resp[i].complete : 0;
                                resp[i].progress = resp[i].progress !=null ? resp[i].progress : 0;
                               vm.surveySummary.push(resp[i]);
                            }
                            store.commit("setSurveySelect", vm.surveySummary);
                            
                           

                        }
    
                    },
                    error: function(jQxhr, status, error) {
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
        },
        closeLoader(){
            let vm = this;
            vm.createStatus = false;
            $('#button-create-survey').html('Create Survey');
            $('#button-create-survey-questionnaire').html('Create Survey & Questionnaire');
        }
    }
}
</script>
