<template>
    <div class="l-app-main">
        <div class="l-app-main-content">
            <div class="ui grid">
                <div class="row">
                    <div class="column">
                        <div class="ui grid">
                            <div class="four wide column">
                                <h3>All Transaction</h3>
                                <h5 class="text-lightgrey">For Client <small>{{msisdn}}</small></h5>
                                  <small>{{email}}</small><br />
                                <small>{{formatDates(dateOne, dateTwo)}}</small>
                            </div>
                            <div class="twelve wide right aligned column">
                                 <select class="ui small dropdown hidden" v-model="senderId">
                                    <option value="">Select</option>
                                    <option v-for="s_id in dataLists" v-show="dataLists.length > 0 && s_id.status == 13" :key="s_id.short_code">{{s_id.short_code}}</option>
                                    <option value="VasPro">VasPro</option>
                                </select>
                                <div class="ui small input datepicker-trigger margin-left-xs">
                                    <input type="text" id="datepicker-trigger" placeholder="Select date" :value="formatDates(dateOne, dateTwo)">
                                    <AirbnbStyleDatepicker :trigger-element-id="'datepicker-trigger'" :mode="'range'" :fullscreen-mobile="true" :months-to-show="2" :date-one="dateOne" :date-two="dateTwo" :close-after-select="false" @date-one-selected="val => { dateOne = val }" @date-two-selected="val => { dateTwo = val }"
                                    @apply="getAllTransaction()"  />
                                </div>
                            </div>
                        </div>
                        <div v-show="displayRecordStatus">
                            <div class="padding-md"></div>
                            <table class="ui celled styled top aligned striped compact table">
                                <thead>
                                    <tr>
                                        <th class="center aligned">#</th>
                                        <th>Source</th>
                                        <th class="center aligned">Description</th>
                                        <th>Amount</th>
                                        <th>Client Name</th>
                                        <th>Date Created</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(list, index) in lists" :key="list.outbox_id">
                                        <td>{{index +1}}</td>
                                        <td>{{list.source}}</td>
                                        <td class="six wide">{{list.description}}</td>
                                        <td class="two wide">{{list.amount}}</td>
                                        <td>{{list.client_name}}</td>
                                        <td>{{list.created_at}}</td>
    
                                    </tr>
                                </tbody>
                            </table>
                            <div v-show="!loading" @click="getAllTransaction"  class="ui icon large labeled button"><i class="icon refresh"></i> Load More</div>
                            <div @click="generateExport()" class="ui bg-accent icon large labeled button" id="button-export" :disabled="uploadStatus"><i class="icon download"></i> Download</div>
                        </div>
                        <loader v-show="loading"></loader>
                    </div>
                </div>
    
            </div>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import Choices from "choices.js";
    import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
    import format from 'date-fns/format';
    import Vue from "vue";
    import alertify from 'alertifyjs';
    import loader from "@/components/loader";
    import moment from "moment";
    export default {
        data() {
            return {
                inboxzero: false,
                userBalance: "",
                userBonus: "",
                totalMessage: 0,
                loading: false,
                dataLists: [],
                lists: [],
                dateOne: '',
                dateTwo: '',
                dateFormat: 'D MMM',
                displayRecordStatus: false,
                senderId: '',
                limit: 10,
                offset: 0,
                mobile: '',
                uploadStatus:false,
                user_mapId:'',
                msisdn:"",
                email:""
    
            };
        },
        mounted() {
            let vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            const datepickerOptions = {};
            vm.dateTwo = format(Date(), this.dateFormatTwo);
            var d = new Date();
            vm.dateOne = format(d.setDate(d.getDate() - 7), this.dateFormatTwo);
            $('.ui.dropdown').dropdown();
            vm.user_mapId = vm.$route.params.id;
            vm.getAllTransaction();
            //vm.fetchData();
            //vm.getAllMessage();
        },
        components: {
            loader
        },
        methods: {
    
            getAllTransaction() {
                let vm = this;
                if (vm.totalMessage == 0 || vm.totalMessage >= ((vm.offset - 1) * vm.limit)) {
                    vm.offset++;
                    vm.loading = true;
                    $.post({
                        url: store.state.rootURLBulkAPI + "v3/dsa/view/transaction",
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: vm.$cookies.get("key"),
                            start: vm.dateOne,
                            stop: vm.dateTwo,
                            user_mapId: vm.user_mapId
                        }),
                        success: function(response, status, jQxhr) {
                            vm.loading = false;
                            vm.totalRecords = response.data.record_count;
                            
                            var resp = response.data.data;
                            if (response.data.code != 200) {
                                vm.offset = 0;
                                vm.totalRecords =[];
                                alertify.set('notifier', 'position', 'top-right');
                                alertify.error(response.data.message);
                                //vm.totalMessage = 0;
    
                            } else {
                                vm.totalMessage = response.data.record_count;
                                vm.displayRecordStatus = true;
                                for (var i = 0; i < resp.length; i++) {
                                    // console.log(" loop " + i + " " + JSON.stringify(resp[i]));
                                    vm.lists.push(resp[i]);
                                }
                                vm.msisdn =  vm.lists[0].msisdn;
                                vm.email =  vm.lists[0].email_address;
    
                            }
    
                        },
                        error: function(jQxhr, status, error) {
                            vm.loading = false;
                            vm.offset = 0;
                            //console.log(jQxhr)
                            alertify.set('notifier', 'position', 'top-right');
                            alertify.error(jQxhr.responseJSON.statusDescription);
                            //console.log("failed");
                        }
                    });
                }
            },
            
            generateExport() {
                let vm = this;
                vm.uploadStatus =  true;
                $('#button-export').html('<i class="fas fa-circle-notch fa-spin"></i> Download Please Wait ...');
                var exportRecipients = [];
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/dsa/view/transaction",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        start: vm.dateOne,
                        stop: vm.dateTwo,
                        user_mapId: vm.user_mapId,
                        export:1
                    }),
                    success: function(response, status, jQxhr) {
                        console.log(JSON.stringify(response));
                        $('.ui.accordion').accordion('refresh');
                        for (var i = 0; i < response.data.data.length; i++) {
                            var item = {};
                            item.source = response.data.data[i].source;
                            item.description = response.data.data[i].description;
                            item.client_name = response.data.data[i].client_name;
                            item.full_names = response.data.data[i].full_names;
                            item.msisdn = response.data.data[i].msisdn;
                            item.amount = response.data.data[i].amount;
                            item.created_at = response.data.data[i].created_at;
                            exportRecipients.push(item);
                        }
    
    
                        var exportDate = moment().format('DD_MMMM_YYYY_h:mm');
    
                        vm.JSONToCSVConvertor(exportRecipients, "Campaign ID: " + vm.senderId, +"_" + exportDate, 1);
                        $('#button-export').html('<i class="icon download"></i> Download');
    
                    },
                    error: function(jQxhr, status, error) {
                        vm.loading = true;
                        //console.log(jQxhr)
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                        $('#button-export').html('<i class="icon download"></i> Download');
                        //console.log("failed");
                    }
                });
            },
            JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
                //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
                var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    
                var CSV = '';
                //Set Report title in first row or line
    
                //CSV += ReportTitle + '\r\n\n';
    
                //This condition will generate the Label/Header
                if (ShowLabel) {
                    var row = "";
    
                    //This loop will extract the label from 1st index of on array
                    for (var index in arrData[0]) {
    
                        //Now convert each value to string and comma-seprated
                        row += index + ',';
                    }
    
                    row = row.slice(0, -1);
    
                    //append Label row with line break
                    CSV += row + '\r\n\n\n';
                }
    
                //1st loop is to extract each row
                for (var i = 0; i < arrData.length; i++) {
                    var row = "";
    
                    //2nd loop will extract each column and convert it in string comma-seprated
                    for (var index in arrData[i]) {
                        row += '"' + arrData[i][index] + '",';
                    }
    
                    row.slice(0, row.length - 1);
    
                    //add a line break after each row
                    CSV += row + '\r\n';
                }
    
                if (CSV == '') {
                    alert("Invalid data");
                    return;
                }
    
                //Generate a file name
                var fileName = "VasPro_";
                //this will remove the blank-spaces from the title and replace it with an underscore
                fileName += ReportTitle.replace(/ /g, "_");
    
                //Initialize file format you want csv or xls
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    
                // Now the little tricky part.
                // you can use either>> window.open(uri);
                // but this will not work in some browsers
                // or you will not get the correct file extension
    
                //this trick will generate a temp <a /> tag
                var link = document.createElement("a");
                link.href = uri;
    
                //set the visibility hidden so it will not effect on your web-layout
                link.style = "visibility:hidden";
                link.download = fileName + ".csv";
    
                //this part will append the anchor tag and remove it after automatic click
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            formatDates(dateOne, dateTwo) {
                let formattedDates = ''
                if (dateOne) {
                    formattedDates = format(dateOne, this.dateFormat)
                }
                if (dateTwo) {
                    formattedDates += ' - ' + format(dateTwo, this.dateFormat)
                }
                return formattedDates
            },
        }
    }
</script>
