<template>
    <div class="ui tiny modal" id="contacts-upload">
        <i class="close icon"></i>
        <div class="header">Add Contacts to List</div>
        <div class="scrolling content bg-grey-lighter">
            <Contacts ref="contacts"></Contacts>
        </div>
        <div class="actions">
            <button class="ui cancel button">Cancel</button>
            <button id="add-contact" class="ui approve green button" v-on:click="uploadContactFiles()">Save</button>
        </div>
    </div>
</template>

<script>
    import Contacts from "@/components/contacts";
     import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";

    export default {
        data(){
            return {}
        },
        components: {
            Contacts
        },
        methods:{
            uploadContactFiles(){
               // console.log("clicked ");

                var myFile = this.$refs.contacts.contactsUpload.files[0];
                var list_id = bulkstore.state.selectedList.list_id;
                var list_name = bulkstore.state.selectedList.group_name;
//console.log("Selected "+ JSON.stringify(bulkstore.state.selectedList));
                var vm =this;

                var data = new FormData();
                data.append("list_id",list_id);
                data.append("list_name",list_name);
                data.append("custom_field1","");
                data.append("custom_field2","");
                data.append("custom_field3","");
                data.append("custom_field4","");
                data.append("custom_field5","");
                data.append("apiKey",this.$cookies.get("token"));
                data.append("file",myFile);

                $('#add-contact').html('<i class="fas fa-circle-notch fa-spin"></i>' ).attr("disabled","disabled");


                $.ajax({
                    url:bulkstore.state.bulksmsUrl+'contact/upload',
                    type:"POST",
                    data:data,
                    cache: false,
                    contentType: false,
                    processData: false,

                    success:function(data,status,jQxhr){
                        mixpanel.track("Uploaded files to contact list");
                        $('#add-contact').text('Save').removeAttr("disabled");
                        vm.$root.$emit('refresh');
                        vm.$refs.contacts.removeFile();
                        $("#contacts-upload").modal("hide");
                        //alertify.success(data.data.success[0].message);
                    },
                    error:function (jQxhr,status,error) {
                        $('#add-contact').text('Save').removeAttr("disabled");
                        alertify.error(jQxhr.responseJSON.statusDescription);
                        vm.$refs.contacts.removeFile();
                        $("#contacts-upload").modal("hide");
                    }
                });

            }
        }
    }
</script>
