<template>
  <div class="l-app-main-section account">
    <div class="l-app-main-section-top">
      <div class="ui padded relaxed grid">
        <div class="padding-sm"></div>
        <loader style="margin-left: 30px" v-show="loadingAccount">
          Please Wait....</loader
        >
        <div class="top aligned row" v-show="!loadingAccount">
          <div
            class="eight wide computer only column text-accent border-right border-light loading"
          >
            <h2 class="padding-bottom-sm">{{ full_names }}</h2>
            <div class="ui horizontal relaxed list">
              <div class="item"><i class="icon phone"></i>{{ msisdn }}</div>
              <div class="item"><i class="icon envelope"></i>{{ email_address }}</div>
              <div class="item"><i class="icon account"></i>{{ account_id }}</div>
            </div>
            <div class="padding-top-sm">
              <a href="#" class="text-primary" @click="showEditModal()">Edit Client</a>
            </div>
          </div>

          <div class="eight wide computer sixteen wide tablet sixteen wide mobile column">
            <div class="ui equal width grid">
              <div class="row">
                <div class="column">
                  <h4>Wallet Balance</h4>
                  <h2 class="text-primary">{{ walletBalance }}</h2>
                </div>
                <div class="column text-accent border-left border-light">
                  <h4>SMS Credit</h4>
                  <h2 class="text-primary">{{ totalSMS }}</h2>
                </div>
                <div class="column text-accent border-left border-light">
                  <h4>Bonus</h4>
                  <h2 class="text-primary">{{ bonus }}</h2>
                </div>
              </div>
              <div class="row">
                <div class="column">
                  <p>
                    <i class="icon bell"></i> Alert client when the wallet is below
                    <span class="text-primary">{{ alert_threshold }}</span
                    >.
                  </p>
                  <p>
                    <i class="icon mobile"></i>SMS Unit Cost
                    <span class="text-primary"> {{ unit_cost }} </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="padding-sm"></div>
      </div>
    </div>
    <div class="l-app-main-section-content">
      <div class="padding-md"></div>

      <div class="ui relaxed padded grid">
        <div class="eight wide computer only eight wide tablet only column">
          <div class="ui segment height-lg">
            <div class="" v-if="dataLists != 0">
              <h3 class="text-medium">Sender IDs</h3>
              <table class="ui very basic unstackable table">
                <thead>
                  <tr>
                    <th class="center aligned collapsing">#</th>
                    <th>Sender ID</th>
                    <th class="">Status</th>
                    <th class="center aligned">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(senderid, index) in dataLists" :key="index">
                    <td class="center aligned">{{ index + 1 }}</td>
                    <td>
                      <h4>{{ senderid.short_code }}</h4>
                      <small></small>
                    </td>
                    <td class="">
                      <label class="ui red label" v-show="senderid.status == 14"
                        >Cancelled</label
                      >
                      <label class="ui yellow label" v-show="senderid.status == 11"
                        >Pending Approval</label
                      >
                      <label class="ui green label" v-show="senderid.status == 13"
                        >Online Sender</label
                      >
                      <label class="ui gray label" v-show="senderid.status == 12"
                        >Pending Approval</label
                      >
                    </td>
                    <td class="right aligned collapsing">
                      <button
                        class="ui tiny blue button"
                        v-show="senderid.status == 11"
                        @click="makePayment(senderid)"
                      >
                        Complete Payment
                      </button>
                      <button
                        class="ui tiny button"
                        v-show="senderid.status == 11"
                        @click="changeStatusSenderID(senderid.sender_id, 3)"
                      >
                        Cancel
                      </button>
                      <button class="ui tiny red button" v-show="senderid.status == 13">
                        Activated Code
                      </button>
                      <button class="ui tiny button" v-show="senderid.status == 12">
                        Pending Approval
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-show="!loading" @click="fetchData" class="ui small button hidden">
                Load More
              </div>
            </div>
            <div class="l-app-inboxzero" v-if="dataLists == 0">
              <h2>No Sender ID...yet</h2>
              <p>Apply for a Sender ID to start sending branded messages</p>
              <div class="padding-sm"></div>
              <button class="ui large bg-primary button" @click="showApplySenderID()">
                Apply for Sender ID
              </button>
            </div>
          </div>
        </div>
        <div class="eight wide computer only eight wide tablet only column">
          <div class="ui segment height-lg">
            <div class="" v-if="paybillsTotal != 0">
              <h3 class="text-medium">Paybill & Tills</h3>
              <table class="ui very basic unstackable table">
                <thead>
                  <tr>
                    <th>Paybill / Till</th>
                    <th class="center aligned">Status</th>
                    <th>Transactions</th>
                    <th class="right aligned">Balance</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="paybill in paybills" :key="paybill.paybill">
                    <td v-if="paybill.pStatus != -5">
                      <h3>{{ paybill.paybill }}</h3>
                      <div>{{ paybill.organisation_name }}</div>
                      <small
                        ><span class="text-green" v-if="paybill.type_id == 1">Till</span
                        ><span class="text-red" v-if="paybill.type_id == 2"
                          >Paybill</span
                        ></small
                      >
                    </td>
                    <td class="center aligned" v-if="paybill.pStatus != -5">
                      <span
                        class="text-green"
                        v-if="paybill.pStatus == 1 && paybill.aggregated_on != null"
                        >Active</span
                      >
                      <span
                        class="text-grey"
                        v-if="paybill.pStatus == 0 && paybill.aggregated_on != null"
                        >Inactive</span
                      >
                      <span
                        class="text-orange"
                        v-if="paybill.aggregated_on == null && paybill.pStatus != 0"
                        >Awaiting Verification</span
                      >
                      <span
                        class="text-grey"
                        v-if="paybill.aggregated_on == null && paybill.pStatus == 0"
                        >Inactive</span
                      >
                    </td>
                    <td v-if="paybill.pStatus != -5">{{ paybill.transaction_count }}</td>
                    <td class="right aligned" v-if="paybill.pStatus != -5">
                      <h3 class="text-green text-medium">
                        KES {{ paybill.org_acc_balance }}
                      </h3>
                    </td>
                    <td class="center aligned collapsing" v-if="paybill.pStatus != -5">
                      <div class="ui icon floating dropdown button">
                        <i class="icon dropdown"></i>
                        <div class="menu">
                          <a class="item" @click="updateStatusPaybill(paybill)">
                            <span class="text-red" v-if="paybill.pStatus == 1"
                              >Deactivate</span
                            >
                            <span class="text-green" v-if="paybill.pStatus == 0"
                              >Activate</span
                            >
                          </a>
                          <a class="item" @click="responseMessage(paybill)"
                            >Response Message</a
                          >
                          <a class="item" @click="updateStatusPaybillDelete(paybill)"
                            >Delete Paybill</a
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <loader v-show="loading"></loader>
            </div>
            <div class="l-app-inboxzero" v-if="paybillsTotal == 0">
              <h2>No Paybills</h2>
              <p class="padding-left-lg padding-right-lg">
                Add a Paybill/Till number to start aggregating payments
              </p>
              <div class="padding-sm"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="ui padded grid">
        <div class="mobile only row bg-white border-top border-bottom border-light">
          <div class="column" v-if="dataLists != 0">
            <h3 class="">Sender IDs</h3>
            <table class="ui very basic unstackable table">
              <tbody>
                <tr v-for="(senderid, index) in dataLists" :key="index">
                  <td>
                    <div class="border-right border-light padding-right-xs">
                      <h4>{{ senderid.short_code }}</h4>
                      <small>
                        <span class="text-grey" v-show="senderid.status == 14"
                          >Cancelled</span
                        >
                        <span class="text-orange" v-show="senderid.status == 11"
                          >Pending Approval</span
                        >
                        <span class="text-primary" v-show="senderid.status == 13"
                          >Active</span
                        >
                        <span class="text-red" v-show="senderid.status == 12"
                          >Pending Approval</span
                        >
                      </small>
                    </div>
                  </td>
                  <td class="right aligned">
                    <div class="ui icon dropdown floating button">
                      <i class="icon dropdown"></i>
                      <div class="menu">
                        <div
                          class="item"
                          v-show="senderid.status == 11"
                          @click="makePayment(senderid)"
                        >
                          Complete Payment
                        </div>
                        <div
                          class="item"
                          v-show="senderid.status == 11"
                          @click="changeStatusSenderID(senderid.sender_id, 3)"
                        >
                          Cancel
                        </div>
                        <div class="item" v-show="senderid.status == 13">
                          Activated Code
                        </div>
                        <div class="item" v-show="senderid.status == 12">
                          Pending Approval
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="l-app-inboxzero" v-if="dataLists == 0">
            <h2>No Sender ID...yet</h2>
            <p>Apply for a Sender ID to start sending branded messages</p>
            <div class="padding-sm"></div>
          </div>
        </div>
        <div
          class="mobile only row bg-white border-top border-bottom border-light margin-top-md"
        >
          <div class="column">
            <div class="" v-if="paybillsTotal != 0">
              <h3>Paybill & Tills</h3>
              <div class="ui equal width grid">
                <div class="row">
                  <div class="column">
                    <table class="ui very basic unstackable table">
                      <tbody>
                        <tr v-for="paybill in paybills" :key="paybill.paybill">
                          <td v-if="paybill.pStatus != -5">
                            <div
                              class="border-right border-light padding-right-xs"
                              @click="loadDetails(paybill.paybill)"
                            >
                              <h4>{{ paybill.paybill }}</h4>
                              <div>
                                <span class="text-lightgrey" v-if="paybill.type_id == 1"
                                  >Till</span
                                >
                                <span class="text-grey" v-if="paybill.type_id == 2"
                                  >Paybill</span
                                >
                                -
                                <span>{{ paybill.organisation_name }}</span>
                              </div>
                              <div>
                                <small
                                  class="text-green"
                                  v-if="
                                    paybill.pStatus == 1 && paybill.aggregated_on != null
                                  "
                                  >Active</small
                                >
                                <small
                                  class="text-grey"
                                  v-if="
                                    paybill.pStatus == 0 && paybill.aggregated_on != null
                                  "
                                  >Inactive</small
                                >
                                <small
                                  class="text-orange"
                                  v-if="
                                    paybill.aggregated_on == null && paybill.pStatus != 0
                                  "
                                  >Awaiting Verification</small
                                >
                                <small
                                  class="text-grey"
                                  v-if="
                                    paybill.aggregated_on == null && paybill.pStatus == 0
                                  "
                                  >Inactive</small
                                >
                              </div>
                            </div>
                          </td>
                          <td class="right aligned" v-if="paybill.pStatus != -5">
                            <small class="text-lightgrey">Balance</small>
                            <h4>KES {{ paybill.org_acc_balance }}</h4>
                          </td>
                          <td class="collapsing" v-if="paybill.pStatus != -5">
                            <div class="ui dropdown icon button">
                              <i class="icon dropdown"></i>
                              <div class="menu">
                                <div class="item" @click="updateStatusPaybill(paybill)">
                                  <span v-if="paybill.pStatus == 1">Deactivate</span>
                                  <span v-if="paybill.pStatus == 0">Activate</span>
                                </div>
                                <div class="item" @click="responseMessage(paybill)">
                                  Edit Response Message
                                </div>
                                <div
                                  class="item"
                                  @click="updateStatusPaybillDelete(paybill)"
                                >
                                  Delete Paybill
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="l-app-inboxzero" v-if="paybillsTotal == 0">
              <h2>No Paybills</h2>
              <p class="padding-left-lg padding-right-lg">
                Add a Paybill/Till number to start aggregating payments
              </p>
              <div class="padding-sm"></div>
              <button class="ui large bg-primary button" @click="showAggPaybillTill()">
                Aggregate Paybiil/Till
              </button>
            </div>
          </div>
        </div>
        <div class="computer only tablet only row">
          <div class="column">
            <table class="ui celled styled unstackable table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Description</th>
                  <th class="computer only">Transaction ID</th>
                  <th class="right aligned">Amount</th>
                </tr>
              </thead>
              <tr v-for="(transc, index) in depositDataList" :key="index">
                <td class="collapsing center aligned">{{ index + 1 }}</td>
                <td>{{ transc.created_at | formatDate }}</td>
                <td>{{ transc.description }}</td>
                <td class="computer only">{{ transc.transaction_id }}</td>
                <td class="right aligned">{{ transc.amount }}</td>
              </tr>
            </table>
            <div v-show="!loading" @click="fetchTransaction" class="ui fluid button">
              Load More
            </div>
            <loader v-show="loading"></loader>

            <div class="padding-xl"></div>
          </div>
        </div>

        <div class="mobile only row bg-white border-top border-bottom border-light">
          <div class="column">
            <table class="ui very basic unstackable table">
              <thead>
                <tr>
                  <th>Description</th>
                  <th class="right aligned">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(transc, index) in depositDataList" :key="index">
                  <td>
                    <div class="border-right border-light padding-right-xs">
                      <h4>{{ transc.description }}</h4>
                      <small class="text-grey"
                        ><span class="padding-right-md">{{
                          transc.created_at | formatDate
                        }}</span>
                        <span class="padding-right-sm"
                          >#{{ transc.transaction_id }}</span
                        ></small
                      >
                    </div>
                  </td>
                  <td class="right aligned">
                    <h4>Ksh.&nbsp;{{ transc.amount }}</h4>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-show="!loading" @click="fetchTransaction" class="ui fluid button">
              Load More
            </div>
            <loader v-show="loading"></loader>
          </div>
        </div>
      </div>
      <ApplySenderID />
      <AggregatePaybil />
      <EditAccount />
      <PayEditResponse />
      <PayStatus />
      <PayStatusDelete />
      <AccountThreshold />
      <senderIDPay />
    </div>
  </div>
</template>

<script>
import AggregatePaybill from "../aggregate-paybill";
import ApplySenderID from "../apply-senderid";
import PayEditResponse from "@/modals/pay_edit_response";
import PayStatus from "@/modals/pay_updateStatus";
import PayStatusDelete from "@/modals/pay_deleteStatus";
import AccountThreshold from "@/modals/account_threshold";
import EditAccount from "@/modals/account_edit";
import loader from "@/components/loader";
import store from "@/store";
import DepositModal from "@/modals/deposit";
import alertify from "alertifyjs";
import senderIDPay from "@/modals/senderIDPay";
import moment from "moment";
export default {
  data() {
    return {
      inboxzero: false,
      walletBalance: null,
      full_names: null,
      msisdn: null,
      email_address: null,
      dataLists: [],
      depositDataList: [],
      paybills: [],
      paybillsTotal: 0,
      limit: 10,
      offset: 0,
      totalSenderID: null,
      loading: true,
      client_name: null,
      account_id: "",
      bonus: 0,
      alert_threshold: 0,
      profileData: [],
      unit_cost: 0,
      totalSMS: 0,
      client_id: "",
      loadingAccount: false,
      depositDataList: [],
      limitDepo: 10,
      offsetDepo: 0,
      totalTrans: 0,
    };
  },
  components: {
    DepositModal,
    loader,
    EditAccount,
    PayEditResponse,
    PayStatus,
    AccountThreshold,
    PayStatusDelete,
    senderIDPay,
    AggregatePaybill,
    ApplySenderID,
  },
  mounted() {
    let vm = this;
    if (vm.$route.params.id == null || !vm.$route.params.id) {
      vm.$router.push({
        name: "reseller",
      });
    }
    vm.client_id = vm.$route.params.id;

    this.getPaybills();
    this.fetchData();
    this.fetchTransaction();

    this.$root.$on("refresh", (data) => {
      (this.dataLists = []), (this.dataAmount = ""), (this.selectedList = "");
      this.totalSenderID = 0;
      this.offset = 0;
    });
    $(".l-app-content-body").css("top", $(".l-app-content-header").outerHeight());
    $(".ui.dropdown").dropdown();
  },
  created() {
    let vm = this;
    vm.loadingAccount = true;
    $.post({
      url: store.state.rootURLBulkAPI + "v3/account/view",
      type: "POST",
      data: JSON.stringify({
        apiKey: vm.$cookies.get("key"),
        client_id: vm.$route.params.id,
      }),
      success: function (response, status, jQxhr) {
        //console.log(JSON.stringify(response.data.data));
        vm.loadingAccount = false;
        vm.walletBalance = response.data.data.balance;
        vm.full_names = response.data.data.account_name;
        vm.msisdn = response.data.data.mobile_number;
        vm.email_address = response.data.data.email_address;
        vm.depositDataList = response.data.data.transaction_data;
        vm.client_name = response.data.data.client_name;
        vm.account_id = response.data.data.account_id;
        vm.bonus = response.data.data.bonus;
        vm.alert_threshold = response.data.data.alert_threshold;

        store.commit("setResellerAccounts", response.data.data);
        vm.getprofiledetails();
      },
      error: function (jQxhr, status, error) {
        vm.loadingAccount = false;
        //console.log(" status" + status + " data " + JSON.stringify(jQxhr.responseJSON.statusDescription));
      },
    });
  },

  methods: {
    showAggPaybillTill() {
      console.log("Am here to test");
      store.commit("setResellerAccounts", store.state.selectResellerClient);
      $("#modal-agg-paybill-till-client")
        .modal({
          centered: true,
          closable: true,
        })
        .modal("show");
    },
    addPaybill() {
      store.commit("setResellerAccounts", store.state.selectResellerClient);
      $("#modal-agg-paybill-till-client")
        .modal({
          centered: true,
          closable: true,
        })
        .modal("show");
    },
    getprofiledetails() {
      let vm = this;
      $.post({
        url: store.state.rootURLBulkAPI + "v2/users/profile",
        type: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key"),
        }),
        success: function (response, status, jQxhr) {
          vm.profileData = response.data.data;
          vm.unit_cost = vm.profileData.unit_cost;
          var balance = vm.walletBalance.replace("KES. ", "");
          vm.totalSMS =
            parseInt(balance.replace(/,/g, ""), 10) /
            parseFloat(vm.unit_cost, 10).toFixed(2);
          vm.totalSMS = vm.totalSMS.toFixed(2);
        },
        error: function (jQxhr, status, error) {},
      });
    },

    loadDetails(path) {
      this.$router.push({
        name: "payments-transactions-details",
        params: {
          id: path,
        },
      });
    },
    showDeposit() {
      $(".ui.modal#modal-deposit")
        .modal({
          centered: false,
          closable: true,
        })
        .modal("show");
    },
    responseMessage(paybill) {
      store.commit("setpayBill", paybill);

      $(".ui.modal#modal-edit-response-paybill")
        .modal({
          centered: false,
          closable: true,
        })
        .modal("show");
    },
    updateStatusPaybill(paybill) {
      store.commit("setpayBill", paybill);

      $(".ui.modal#modal-Paybill_status")
        .modal({
          centered: false,
          closable: true,
        })
        .modal("show");
    },
    updateStatusPaybillDelete(paybill) {
      store.commit("setpayBill", paybill);

      $(".ui.modal#modal-Paybill_status_delete")
        .modal({
          centered: false,
          closable: true,
        })
        .modal("show");
    },
    showEditModal() {
      store.commit("setResellerAccounts", store.state.selectResellerClient);
      $(".ui.modal#modal-edit-account")
        .modal({
          centered: false,
        })
        .modal("show");
    },
    showEditThresholdModal() {
      store.commit("setResellerAccounts", store.state.selectResellerClient);
      $(".ui.modal#modal-edit-threshold")
        .modal({
          centered: false,
        })
        .modal("show");
    },
    changeStatusSenderID(id, status) {
      var vm = this;
      $.post({
        url: store.state.rootURLBulkAPI + "v3/senderId/activate",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
          senderId: id,
          status: status,
          clientID: vm.client_id,
        }),
        success: function (response, status, jQxhr) {
          alertify.success("Success " + JSON.stringify(response.data.message));
          vm.offset = 0;
          vm.dataLists = [];
          vm.totalSenderID = 0;

          vm.fetchData();

          //vm.$root.$emit('refresh');
        },
        error: function (jQxhr, status, error) {
          alertify.error("Error " + JSON.stringify(jQxhr.responseJSON.statusDescription));
        },
      });
    },
    fetchTransaction() {
      let vm = this;
      if (vm.totalTrans === 0 || vm.totalTrans >= (vm.offset - 1) * vm.limit) {
        vm.loading = true;
        $.post({
          url: store.state.rootURLBulkAPI + "v3/account/view",
          type: "POST",
          data: JSON.stringify({
            apiKey: vm.$cookies.get("key"),
            limit: vm.limitDepo,
            start: vm.dateOne,
            stop: vm.dateTwo,
            offset: vm.offsetDepo,
            client_id: vm.client_id,
          }),
          success: function (response, status, jQxhr) {
            //console.log(response.data.records_count);
            vm.loading = false;
            var list_c = response.data.data.transaction_data;
            vm.totalTrans = response.data.records_count;
            for (var i = 0; i < list_c.length; i++) {
              vm.depositDataList.push(list_c[i]);
            }
          },
          error: function (jQxhr, status, error) {
            vm.loading = false;
            //console.log(" status" + status + " data " + JSON.stringify(jQxhr.responseJSON.statusDescription));
          },
        });

        vm.offset++;
      }
    },
    fetchData() {
      //console.log("TEST");
      // this.dataLists = [];
      var vmBalance = this;

      var vm = this;
      //vm.totalSenderID = number(vm.totalSenderID);
      // console.log(((vm.offset - 1) * vm.limit));
      //console.log(vm.totalSenderID);

      if (vm.totalSenderID == 0 || vm.totalSenderID >= (vm.offset - 1) * vm.limit) {
        // console.log("test am here");
        vm.offset++;
        vm.loading = true;

        $.post({
          url: store.state.rootURLBulkAPI + "v3/senderId/list",
          type: "POST",
          data: JSON.stringify({
            apiKey: this.$cookies.get("key"),
            client_id: vm.client_id,
            //offset: vm.offset
          }),
          success: function (response, status, jQxhr) {
            vm.loading = false;
            vm.totalSenderID = response.data.record_count;
            var new_da = [];
            var resp = response.data.data;

            if (response.data.code == 404) {
              vm.inboxzero = true;
              //alertify.error("Error: " + JSON.stringify(response.data.message));
            }
            if (response.data.data != null) {
              // enter total
              for (var i = 0; i < resp.length; i++) {
                // console.log(" loop " + i + " " + JSON.stringify(resp[i]))
                vm.dataLists.push(resp[i]);
              }
              setTimeout(function () {
                $(".ui.dropdown").dropdown();
              }, 100);

              // console.log("list "+vm.dataLists.length);
            }
            // else if (response.data.data == "") {
            //     vm.inboxzero = true;
            // }
          },
          error: function (jQxhr, status, error) {
            vm.loading = false;
            //console.log(" status bulk " + status + " data " + JSON.stringify(jQxhr.responseJSON.statusDescription));
          },
        });
      }
    },
    getPaybills() {
      var vm = this;
      if (!vm.token) {
        vm.token = vm.$cookies.get("key");
      }
      vm.loading = true;
      $.post({
        url: store.state.rootUrl + "payment/paybill/list",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
          client_id: vm.client_id,
        }),
        success: function (response, status, jQxhr) {
          vm.loading = false;
          if (response.data.code == 404) {
            //  console.log("No Records");
          }
          vm.paybills = response.data.data;
          vm.paybillsTotal = response.data.record_count;
          //console.log("success" + JSON.stringify(response.data.record_count));
          setTimeout(function () {
            $(".ui.dropdown").dropdown();
          }, 100);
        },
        error: function (jQxhr, status, error) {
          vm.loading = false;
          //console.log(jQxhr.responseJSON.statusDescription);
        },
      });
    },
    makePayment(senderID) {
      store.commit("setSenderIDSelect", senderID);
      //console.log(JSON.stringify(senderID));
      $(".ui.modal#modal-senderID-Pay")
        .modal({
          centered: false,
        })
        .modal("show");
    },
  },
  filters: {
    formatDate: function (value) {
      if (!value) return "";
      return moment(value).fromNow();
    },
  },
};
</script>
