<template>
  <div class="ui tiny modal" id="modal-edit-message">
    <i class="close icon"></i>
    <div class="scrolling content">
      <div class="text-center margin-bottom-lg">
        <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
        <h2 class="padding-bottom-sm">Edit Message</h2>
        <h4>You can use contact list to send bulk messages {{ originalName }}</h4>
      </div>
      <div class="ui negative message" v-show="sendSMSStatus">
        {{ sendSMSResponseMessage }}
      </div>
      <div class="ui centered container grid">
        <div class="column">
          <div class="ui form">
            <div class="field">
              <label>Type your message</label>
              <div class="ui top attached segment" id="textarea-sms">
                <textarea
                  class="l-type-message"
                  placeholder="Type your SMS here"
                  v-model="composedMessage"
                ></textarea>
              </div>
              <div class="ui bottom attached tiny secondary segment">
                <div class="ui equal width relaxed grid">
                  <div class="row">
                    <div class="column">{{ messageCount }} characters</div>
                    <div class="right aligned column">{{ messagePages }} Pages</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="two fields" v-show="!makerEdit">
              <div class="field">
                <div class="ui toggle checkbox">
                  <input type="checkbox" name="schedule" v-model="isScheduled" checked />
                  <label><strong>Update Date & Time</strong></label>
                  <p class="padding-top-sm">
                    <small>Select date and time to send message</small>
                  </p>
                </div>
              </div>
              <div class="field">
                <div class="ui toggle checkbox">
                  <input type="checkbox" name="schedule" v-model="isCancel" checked />
                  <label><strong>Cancel Schedule</strong></label>
                  <p class="padding-top-sm">
                    <small>If enable you message will be cancel</small>
                  </p>
                </div>
              </div>
            </div>

            <div class="two fields" v-show="isScheduled && !isCancel && !makerEdit">
              <div class="field">
                <label>Choose a date</label>
                <div class="ui fluid calendar" id="message-schedule-time">
                  <div class="ui input left icon">
                    <input
                      type="text"
                      id="datepicker-trigger"
                      placeholder="Select date"
                      :value="formatDates(dateOne)"
                    />
                    <AirbnbStyleDatepicker
                      :trigger-element-id="'datepicker-trigger'"
                      :mode="'range'"
                      :fullscreen-mobile="true"
                      :months-to-show="1"
                      :date-one="dateOne"
                      :date-two="dateTwo"
                      :close-after-select="true"
                      @date-one-selected="
                        (val) => {
                          dateOne = val;
                        }
                      "
                      @date-two-selected="
                        (val) => {
                          dateTwo = val;
                        }
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="field">
                <label>Choose time</label>
                <div class="ui fluid calendar" id="message-schedule-time">
                  <div class="ui input left icon">
                    <i class="calendar icon"></i>
                    <input type="time" placeholder="Time" v-model="selectedTime" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <button class="ui cancel button" @click="hideModal()">Cancel</button>
      <button
        @click="sendmessage(1)"
        v-show="isScheduled"
        class="ui bg-primary button"
        id="button-schedule-message"
      >
        Update
      </button>
      <button
        @click="sendmessage(0)"
        v-show="!isScheduled"
        class="ui bg-primary icon labeled button"
      >
        <i class="icon send"></i> Send Now!
      </button>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import bulkstore from "@/bulk/bulksms-state";
import alertify from "alertifyjs";
import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
import format from "date-fns/format";
import Vue from "vue";

export default {
  data() {
    return {
      composedMessage: "",
      isScheduled: true,
      message: "",
      token: "",
      status: "",
      sendSMSStatus: false,
      sendSMSResponseMessage: "",
      selectedCampaign: "",
      dateOne: null,
      dateTwo: null,
      dateFormat: "D MMM",
      approvedStatus: 700,
      messageCount: 0,
      selectedTime: null,
      messagePages: 0,
      short_code: "",
      isCancel: false,
      makerEdit: false,
    };
  },
  mounted() {
    const vm = this;
    $(".ui.dropdown").dropdown("refresh");
    vm.token = vm.$cookies.get("key");
    const datepickerOptions = {};
    Vue.use(AirbnbStyleDatepicker, datepickerOptions);
    console.log(vm.selectedCampaign);
  },
  computed: {
    originalName() {
      var vm = this;

      vm.selectedCampaign = bulkstore.state.selectedCampaign;
      vm.short_code = bulkstore.state.selectedCampaign.short_code;
      vm.composedMessage = vm.selectedCampaign.message;
      if (vm.selectedCampaign.status == 701) {
        vm.makerEdit = true;
      }
      console.log("message status " + vm.selectedCampaign.status);
      // vm.messageCount = vm.composedMessage.length;
      // if (vm.composedMessage) {
      //     vm.messageCount = vm.composedMessage.length;
      //     if (vm.composedMessage.length > 0) {
      //         $('#textarea-sms').removeClass("red");
      //     }

      //     if (vm.composedMessage.length == 0) {
      //         vm.messagePages = 0;
      //     } else if (vm.composedMessage.length / 160 <= 1) {
      //         vm.messagePages = 1;
      //     } else if (vm.composedMessage.length / 160 <= 2) {
      //         vm.messagePages = 2;
      //     } else if (vm.composedMessage.length / 160 <= 3) {
      //         vm.messagePages = 3;
      //     } else if (vm.composedMessage.length / 160 <= 4) {
      //         vm.messagePages = 4;
      //     } else if (vm.composedMessage.length / 160 <= 5) {
      //         vm.messagePages = 5;
      //     } else {
      //         vm.messagePages = " Max ";
      //     }
      // }
      var dateTimeString = vm.selectedCampaign.send_time;
      if (dateTimeString) {
        var dateTimeArray = dateTimeString.split(" ");
        vm.dateOne = dateTimeArray[0];
        vm.selectedTime = dateTimeArray[1];
      }
      return vm.short_code;
    },
  },
  methods: {
    formatDates(dateOne, dateTwo) {
      let formattedDates = "";
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat);
      }
      if (dateTwo) {
        formattedDates += " - " + format(dateTwo, this.dateFormat);
      }
      return formattedDates;
    },
    sendmessage(is_scheduled) {
      //console.log("jSON SELECTLIST  "+JSON.stringify(vm.selectedList));
      var vm = this;
      if (!vm.composedMessage) {
        $("#textarea-sms").addClass("red");
        alertify.error("Message is required");
      }
      if (this.isScheduled) {
        $("#button-schedule-message").html('<i class="fas fa-circle-notch fa-spin"></i>');
      } else {
        $("#button-send-message").html('<i class="fas fa-circle-notch fa-spin"></i>');
      }
      vm.selectedCampaign = bulkstore.state.selectedCampaign;
      var campaignIdEdit = vm.selectedCampaign.campaign_id;
      console.log(
        "Campaign ID " +
          vm.selectedCampaign.campaign_id +
          "status " +
          vm.selectedCampaign.status
      );
      if (is_scheduled != 1) {
        vm.approvedStatus = 200;
      }
      if (vm.isCancel) {
        vm.approvedStatus = 506;
      }
      if (vm.selectedCampaign.status == 701) {
        $.post({
          url:
            bulkstore.state.rootURLBulkAPI +
            "BulkSMS/edit/" +
            campaignIdEdit +
            "/makerMessage",
          type: "POST",
          data: JSON.stringify({
            apiKey: vm.$cookies.get("key"),
            shortCode: vm.short_code,
            message: vm.composedMessage,
            campaignId: vm.selectedCampaign.campaign_id,
          }),
          success: function (data, status, jQxhr) {
            $("#contacts-list-message").modal("hide");
            $("#button-send-message").text("Send Message");
            $("#button-schedule-message").text("Update");

            if (data.data.message) {
              alertify.set("notifier", "position", "top-right");
              alertify.success(data.data.message);
            } else {
              // console.log(JSON.stringify(data));
              alertify.set("notifier", "position", "top-right");
              alertify.success("Message successfully sent");
            }
            vm.$parent.setCampaignValue();
            vm.hideModal();
          },
          error: function (jQxhr, status, error) {
            $("#button-send-message").text("Send Message");
            $("#button-schedule-message").text("Update");
            alertify.set("notifier", "position", "top-right");
            alertify.error(jQxhr.responseJSON.statusDescription);
            vm.sendSMSStatus = true;
            vm.sendSMSResponseMessage = jQxhr.responseJSON.statusDescription;
          },
        });
      } else {
        $.post({
          url: bulkstore.state.rootURLBulkAPI + "BulkSMS/edit/message",
          type: "POST",
          data: JSON.stringify({
            apiKey: vm.$cookies.get("key"),
            shortCode: vm.short_code,
            message: vm.composedMessage,
            scheduleDate: vm.dateOne,
            scheduleTime: vm.selectedTime,
            isScheduled: is_scheduled,
            approval: vm.approvedStatus,
            campaignId: vm.selectedCampaign.campaign_id,
          }),
          success: function (data, status, jQxhr) {
            $("#contacts-list-message").modal("hide");
            $("#button-send-message").text("Send Message");
            $("#button-schedule-message").text("Update");

            if (data.data.message) {
              alertify.set("notifier", "position", "top-right");
              alertify.success(data.data.message);
            } else {
              // console.log(JSON.stringify(data));
              alertify.set("notifier", "position", "top-right");
              alertify.success("Message successfully sent");
            }
            vm.$parent.setCampaignValue();
            vm.hideModal();
          },
          error: function (jQxhr, status, error) {
            $("#button-send-message").text("Send Message");
            $("#button-schedule-message").text("Update");
            alertify.set("notifier", "position", "top-right");
            alertify.error(jQxhr.responseJSON.statusDescription);
            vm.sendSMSStatus = true;
            vm.sendSMSResponseMessage = jQxhr.responseJSON.statusDescription;
          },
        });
      }
    },
    hideModal() {
      this.$parent.showEdit = false;
      $(".ui.modal#modal-edit-message").modal("hide");
    },
  },
};
</script>
