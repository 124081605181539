<template>
    <div class="ui tiny modal" id="modal-deposit">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">Buy Credits</h2>
                <h4>Deposit via MPESA to your wallet to use the funds to send bulk sms and more</h4>
                <div class="ui negative message" v-show="depositPayment">
                            <div v-html= "depositPaymentStatus"></div> {{originalName}}
                </div>
                
            </div>
            <div class="ui equal width centered container grid">
                <div class="column">
                    <div class="ui form">
                        <div class="field">
                            <label>Phone Number</label>
                            <div class="ui input">
                                <input  v-model="msisdn" />
                            </div>
                        </div>
                        <div class="field">
                            <label>Amount</label>
                            <div class="ui input">
                                <input type= "number" placeholder="" v-model="amount"/>
                            </div>
                        </div>
                         <div class="ui toggle checkbox">
                            <input type="checkbox" id="hasVAT" v-model="vatStatus" @change="updatesAmount">
                            <label><strong>Include VAT  </strong></label><br />
                        </div>
                    </div>
                    <br />
                    <div class="ui border padding-md">
                        <h4 class="text-center"><strong>MPESA Payment Instructions</strong></h4>
                        <p>
                            <ol>
                                <li>Go to M-PESA Menu on your mobile phone</li>
                                <li>Select Lipa na M-PESA</li>
                                <li>Select Pay Bill</li>
                                <li>Enter <strong>789789</strong> as the Business Number</li>
                                <li>Enter <strong><em>{{accountDetails}}</em></strong> as Account Number</li>
                                <li>Enter the amount <strong><em>{{totalAmount}} </em></strong> to pay </li>
                                <li>Enter your M-PESA PIN and send</li>
                                <li>You will receive an SMS confirming the transaction</li>
                            </ol>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui cancel button">Cancel</button>
            <button class="ui bg-primary button" :disabled="!agreeTerms" @click="deposit()"><i id="applyDeposit"></i> Deposit</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    export default {
         data() {
            return {
                offersState:false,
                offers:[],
                msisdn: null,
                amount: 1000,
                account:null,
                depositPayment:false,
                depositPaymentStatus:null,
                agreeTerms: true,
                vatStatus:false,
                vatAmount:0,
                accountDetails:null,
                totalAmount:0
            }
        },
        computed:{
           originalName() {
               var vm =this;
               vm.msisdn = window.atob(this.$cookies.get("username"));
               vm.account = store.state.selectedProfile.account_id;
               vm.accountDetails = "ACC"+vm.account;
               vm.totalAmount = vm.amount;
               $(".ui.toggle.checkbox").checkbox();
               //return window.atob(this.$cookies.get("username"));
               //Console.log("Custom Fil" +bulkstore.state.selectedList.list_name);
            }
       },
       mounted() {
           this.getOffers()
       },
       watch :{
           amount:function(val){
               let vm = this;
               
               if(vm.vatStatus){
                    vm.totalAmount = parseInt(vm.amount * 0.14) + parseInt(vm.amount);
                }
                else{
                    vm.totalAmount =  vm.amount;
                }
                console.log(vm.vatStatus +" the amount "+vm.totalAmount);
           },
           totalAmount:function(val){
               let vm = this;
               vm.totalAmount = val;
           }
       },
        methods: {
            updatesAmount(){
                let vm = this;
                if(vm.vatStatus){
                    
                    vm.vatAmount = parseInt(vm.amount * 0.16);
                    vm.totalAmount = parseInt(vm.amount * 0.16) + parseInt(vm.amount);
                    vm.accountDetails = "VAT-ACC"+vm.account;
                }
                else{
                    vm.totalAmount =  vm.amount;
                    vm.accountDetails = "ACC"+vm.account;
                }
                console.log(vm.vatAmount+vm.amount);
            },
            getOffers() {
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + 'v3/offers/all',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                    }),
                    success: function(response, status, jQxhr) {
                    
                    if(response.data.code != 200){
                        vm.offersState = false;
                        alertify.success(response.data.message);
                    }
                    else {
                        vm.offersState = true;
                        vm.offers = response.data.data;
                    }
                        
                    },
                    error: function(jQxhr, status, error) {
                        vm.offersState = false;
                    }
                });
            },
            deposit() {
                this.dataAmount = 0;
                let vm = this;
                vm.agreeTerms = false;
                $('#applyDeposit').html('<i class="fas fa-circle-notch fa-spin"></i>');
    
                $.post({
                    url: store.state.rootURLBulkAPI + 'v3/account/deposit',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        mobile_number: vm.msisdn,
                        amount: vm.amount,
                        vat: vm.vatAmount
                    }),
                    success: function(response, status, jQxhr) {
                    
                    if(response.data.code != 200){
                        vm.depositPayment = true;
                        vm.agreeTerms = true;
                         $('#applyDeposit').html('');
                        vm.depositPaymentStatus = response.data.data.text;
                        alertify.set("notifier", "position", "top-right");
                        alertify.error(response.data.data.text);
                        alertify.error(response.data.message);
                        //console.log("failed"+response.data.message);
                    }
                    else {

                        vm.agreeTerms = true;
                        $('#applyDeposit').html('');
                        alertify.success(response.data.message);
                        //vm.hideModal();
                    }
                        
                    },
                    error: function(jQxhr, status, error) {
                        vm.agreeTerms = true;
                        $('#applyDeposit').html('');
                        //vm.hideModal();
                        //console.log(" status bulk new");
                    }
                });
            
                
            },
            hideModal() {
                $('.ui.modal#modal-deposit').modal('hide');
            }
        }
    }
</script>
