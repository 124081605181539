<template>
  <div class="l-app-main bg-primary-bg">
    <div class="l-app-dashboard bg-primary-lighter">
      <div class="ui padded relaxed grid">
        <div class="equal width middle aligned row">
          <div class="column">
            <h3 class="text-medium">All Credit Transactions</h3>
            <small>{{ from | formatST }} - {{ to | formatST }}</small>
          </div>
          <div class="right aligned column">
            <div class="ui input">
              <input
                type="date"
                placeholder="Enter date"
                v-model="from"
                @change="fetchAllCreditTransaction(1)"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <table class="ui striped celled table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Client</th>
                  <th>Client Email</th>
                  <th>Previous Balance</th>
                  <th class="right aligned">Amount Credited</th>
                </tr>
              </thead>
              <tbody>
                <loader v-show="isLoading"></loader>
                <div class="ui negative message" v-show="messageError">
                  {{ messageError }}
                </div>
                <tr v-for="(list, index) in transactions" :key="index + 1">
                  <td>{{ list.created | formatDT }}</td>
                  <td>{{ list.client_name }}</td>
                  <td>{{ list.client_email }}</td>
                  <td>{{ list.previous_balance | formatNumber }}</td>
                  <td>{{ list.amount_credited | formatNumber }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import alertify from "alertifyjs";
import loader from "@/components/loader";
import moment from "moment";
import numeral from "numeral";
import format from "date-fns/format";
export default {
  components: {
    loader,
  },
  data() {
    return {
      // credits: store.state.credits,
      isLoading: false,
      transactions: [],
      messageError: null,
      from: "",
      to: "",
      dateFormatTwo: "YYYY-MM-DD",
    };
  },
  mounted() {
    var d = new Date();
    this.from = format(d.setDate(d.getDate() - 7), this.dateFormatTwo);
    this.to = moment();
    this.fetchAllCreditTransaction(0);
  },
  methods: {
    fetchAllCreditTransaction(n) {
      let vm = this;
      if (n != 0) {
        vm.transactions = [];
      }
      vm.isLoading = true;
      $.post({
        url: store.state.rootUrl + "reseller/transaction/list",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
          start: vm.from,
        }),
        success: function (response, status, jQxhr) {
          vm.isLoading = false;
          vm.transactions = response.data.data;
          if (response.data.code != 200) {
            vm.messageError = response.statusDescription;
          }
        },
        error: function (jQxhr, status, error) {
          vm.isLoading = false;
          alertify.error(jQxhr.responseJSON.statusDescription);
        },
      });
    },
  },
  filters: {
    formatDate: function (value) {
      if (!value) return "";
      return moment(value).fromNow();
    },
    formatDT: function (value) {
      if (!value) return "";
      return moment(value).format("LLLL");
    },
    formatST: function (value) {
      if (!value) return "";
      return moment(value).format("LL");
    },
    formatNumber: function (value) {
      if (!value) return "";
      return numeral(value).format("0,0.00");
    },
  },
};
</script>
