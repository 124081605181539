<template>
  <!-- Settings tab-->
  <div class="l-app-main-segment-container bg-white">
    <div class="ui relaxed padded equal width grid">
      <div class="middle aligned row">
        <div class="column">
          <h4 class="text-medium">Channels</h4>
          <p>Users will use these channels to complete the survey</p>
          <div class="ui checkbox margin-right-lg">
            <input type="checkbox" name="example" v-model="smsState" />
            <label>SMS</label>
          </div>
          <div class="ui checkbox margin-right-lg">
            <input type="checkbox" name="example" v-model="whatsAppState" />
            <label>WhatsApp</label>
          </div>
          <div class="ui checkbox margin-right-lg">
            <input type="checkbox" name="example" disabled />
            <label>USSD</label>
          </div>
          <div class="ui checkbox">
            <input type="checkbox" name="example" disabled />
            <label>Web</label>
          </div>
        </div>
      </div>
      <div class="ui divider"></div>

      <div class="row" v-show="smsState">
        <loader v-show="loading"></loader>
        <div class="column border-right border-light" v-show="displayMpesaOption">
          <h4 class="text-medium">Payments Integration</h4>
          <p>
            When a customer pays through a till number or paybill, the survey will be
            triggered.
          </p>
          <div class="ui equal width middle aligned grid">
            <div class="column">
              <div class="ui checkbox">
                <input type="checkbox" name="example" v-model="mpesaState" />
                <label>Enable MPESA integration</label>
              </div>
            </div>

            <div class="column" v-show="mpesaState">
              <select class="ui fluid dropdown" v-model="selectPaybill">
                <option value="">Select Paybill/Till</option>
                <option
                  v-for="paybill in paybills"
                  :key="paybill.paybill"
                  :value="paybill.paybill"
                >
                  <span class="description">{{ paybill.type }} | </span>
                  <span class="text">{{ paybill.paybill }}</span>
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-show="smsState">
        <div class="column">
          <h4 class="text-medium">Shortcode Integration</h4>
          <p>
            When a user sends the keyword to the shortcode, the survey will be triggered.
          </p>
          <div class="ui equal width middle aligned grid">
            <div class="column">
              <div class="ui checkbox">
                <input type="checkbox" name="example" v-model="keywordState" />
                <label>Enable Shortcode Keywords</label>
              </div>
            </div>
            <div class="column" v-show="keywordState">
              <select class="ui fluid dropdown" v-model="selectShortcode">
                <option value="">Select Shortcode</option>
                <option value="40399"><span>40399</span></option>
                <option
                  v-for="short in shortcode"
                  :key="short.id"
                  :value="short.shortcode"
                >
                  <span>{{ short.short_code }} </span>
                </option>
              </select>
            </div>
            <div class="column" v-if="keywordState && selectKeyword">
              <select class="ui fluid dropdown" v-model="selectKeyword" disabled>
                <option value="">Select Keywords</option>
                <option v-for="keyword in keywords" :key="keyword.id" :value="keyword.id">
                  <span>{{ keyword.keyword }} </span>
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-show="whatsAppState"></div>
      <div class="column">
        <h4 class="text-medium">WhatsApp Business Number Integration</h4>
        <p>
          When a user sends the WhatsApp keyword to the phone number, the survey will be
          triggered.
        </p>
        <div class="ui equal width middle aligned grid">
          <div class="column">
            <div class="ui checkbox">
              <input type="checkbox" name="example" v-model="keywordState" />
              <label>Enable WhatsApp Keywords</label>
            </div>
          </div>
          <div class="column" v-show="keywordState">
            <select class="ui fluid dropdown" v-model="selectShortcode">
              <option value="">Select Phone Number</option>
              <option value="254709183222" selected><span>254709183222</span></option>
            </select>
          </div>
          <div class="column" v-if="keywordState">
            <select class="ui fluid dropdown" v-model="selectKeyword">
              <option value="">Select Keywords</option>
              <option v-for="keyword in keywords" :key="keyword.id" :value="keyword.id">
                <span>{{ keyword.keyword }} </span>
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="ui divider"></div>
      <div class="row hidden">
        <div class="column">
          <h4 class="text-medium">Rewards</h4>
          <p>Reward customers when they complete the survey</p>
        </div>
      </div>
      <div class="row">
        <div class="right aligned column">
          <button class="ui button" @click="$parent.selectedTab = 'questions'">
            Cancel
          </button>
          <button class="ui green button" v-show="selectKeyword" @click="updateRecord()">
            Save & Continue
          </button>
          <button
            class="ui green button"
            v-if="keywordState && !selectKeyword"
            @click="createKeyword()"
          >
            Generate Keyword, Save & Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import alertify from "alertifyjs";
import loader from "@/components/loader";
export default {
  data() {
    return {
      paybills: [],
      keywords: [],
      offset: 0,
      limit: 10,
      loading: false,
      displayMpesaOption: false,
      smsState: false,
      whatsAppState: false,

      mpesaState: false,
      keywordState: false,
      survey: [],
      selectKeyword: "",
      selectPaybill: "",
      selectShortcode: "",
      channel: "",
      shortcode: [],
      app_id: "",
    };
  },
  components: {
    loader,
  },
  mounted() {
    let vm = this;
    vm.getPaybills();

    vm.getAllShortcode();
    vm.survey = store.state.selectSurvey;
    console.log("select keyword id " + JSON.stringify(vm.survey.keyword_id));

    vm.selectKeyword = vm.survey.keyword_id;
    vm.selectPaybill = vm.survey.paybill_id;
    vm.selectShortcode = vm.survey.selectShortcode;
    vm.app_id = vm.survey.app_id;
    if (vm.survey.channel == "SMS") {
      vm.smsState = true;
      vm.channel = "SMS";
      vm.getAllkeyword();
    }
    if (vm.survey.channel == "WHATSAPP") {
      vm.whatsAppState = true;
      vm.channel = "WHATSAPP";
      vm.selectShortcode = "254709183222";
      vm.getWhatsAppKeyword();
    }
    if (vm.selectKeyword != null) {
      vm.keywordState = true;
    }
    if (vm.selectPaybill != null) {
      vm.mpesaState = true;
    }

    //console.log("survey "+JSON.stringify(vm.survey));
  },
  methods: {
    getPaybills() {
      let vm = this;
      vm.loading = true;
      $.post({
        url: store.state.rootUrl + "payment/paybill/list",
        type: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key"),
        }),
        success: function (response, status, jQxhr) {
          vm.loading = false;
          if (response.data.code == 404) {
            vm.paybills = [];
            vm.displayMpesaOption = false;
          } else {
            vm.displayMpesaOption = true;
            vm.paybills = response.data.data;
          }
          setTimeout(function () {
            $(".ui.dropdown").dropdown();
          }, 100);
        },
        error: function (jQxhr, status, error) {
          vm.loading = false;
        },
      });
    },
    getWhatsAppKeyword() {
      var vm = this;
      $.post({
        url: store.state.rootUrl + "whatsApp/view/keywords",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
        }),
        success: function (response, status, jQxhr) {
          if (response.data.data != "") {
            vm.keywords = response.data.data;
          }
          setTimeout(function () {
            $(".ui.dropdown").dropdown();
          }, 100);
        },
        error: function (jQxhr, status, error) {},
      });
    },
    getAllkeyword() {
      var vm = this;
      $.post({
        url: store.state.rootUrl + "contact/keyword/view",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
        }),
        success: function (response, status, jQxhr) {
          if (response.data.data != "") {
            vm.keywords = response.data.data;
          }
          setTimeout(function () {
            $(".ui.dropdown").dropdown();
          }, 100);
        },
        error: function (jQxhr, status, error) {},
      });
    },
    getAllShortcode() {
      let vm = this;
      $.post({
        url: store.state.rootURLBulkAPI + "v3/inboxmain/view/shortcode",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
          category: 1,
        }),
        success: function (response, status, jQxhr) {
          vm.shortcode = response.data.data;
          setTimeout(function () {
            $(".ui.dropdown").dropdown();
          }, 100);
        },
        error: function (jQxhr, status, error) {
          //alertify.error(jQxhr.responseJSON.statusDescription);
        },
      });
    },
    createKeyword() {
      let vm = this;
      $.post({
        url: store.state.rootURLBulkAPI + "v3/contact/keyword/create",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
          title: store.state.selectSurvey.campaign_name,
          response: "",
        }),
        success: function (response, status, jQxhr) {
          vm.selectKeyword = response.data.data.id;
          vm.updateSurvey();
        },
        error: function (jQxhr, status, error) {
          alertify.error(jQxhr.responseJSON.statusDescription);
        },
      });
    },
    updateRecord() {
      let vm = this;
      //console.log("Paybill "+ vm.selectPaybill)
      $.post({
        url: store.state.rootUrl + "survey/update",
        type: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key"),
          app_id: vm.app_id,
          short_code: vm.selectShortcode,
          channel: vm.channel,
          keyword_id: vm.selectKeyword,
          paybill_id: vm.selectPaybill,
        }),
        success: function (response, status, jQxhr) {
          if (response.data.code != 200) {
            vm.SurveyStatus = true;
            vm.SurveyStatusDesc = response.data.data.message;
            alertify.set("notifier", "position", "top-right");
            alertify.error(response.data.message);
          } else {
            alertify.set("notifier", "position", "top-right");
            alertify.success(response.data.message);
            vm.$parent.selectedTab = "report";
          }
        },
        error: function (jQxhr, status, error) {
          vm.SurveyStatus = true;
          vm.SurveyStatusDesc = jQxhr.responseJSON.statusDescription;
          alertify.set("notifier", "position", "top-right");
          alertify.error(jQxhr.responseJSON.statusDescription);
        },
      });
    },
    updateSurvey() {
      let vm = this;
      $.post({
        url: store.state.rootUrl + "survey/update",
        type: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key"),
          app_id: vm.app_id,
          short_code: vm.selectShortcode,
          channel: "SMS",
          keyword_id: vm.selectKeyword,
          paybill_id: vm.selectPaybill,
        }),
        success: function (response, status, jQxhr) {
          if (response.data.code != 200) {
            vm.SurveyStatus = true;
            vm.SurveyStatusDesc = response.data.data.message;
            alertify.set("notifier", "position", "top-right");
            alertify.error(response.data.message);
          } else {
            alertify.set("notifier", "position", "top-right");
            alertify.success(response.data.message);

            vm.$parent.selectedTab = "summary";
          }
        },
        error: function (jQxhr, status, error) {
          vm.SurveyStatus = true;
          vm.SurveyStatusDesc = jQxhr.responseJSON.statusDescription;
          alertify.set("notifier", "position", "top-right");
          alertify.error(jQxhr.responseJSON.statusDescription);
        },
      });
    },
  },
};
</script>
