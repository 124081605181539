<template>
    <div class="l-app-main">
        <div class="l-app-main-inboxzero bg-primary-lighter" v-show="inboxzero">
            <h2>Create questionnaire</h2>
            <p>Get feedback from your customers<br />and and improve your services</p>
            <div class="padding-sm"></div>
            <button class="ui large blue button" @click="showCreateSurveyModal()">Create a survey</button>
            <div class="padding-xl"></div>
        </div>
        <div class="l-app-main-content" v-show="!inboxzero">
            <div class="ui equal width grid">
                
                <div class="column">
                    <div class="ui grid">
                            <div class="four wide column">
                                <h3>All Questionnaire</h3>
                                <h5 class="text-lightgrey">from all surveys</h5>
                            </div>
                            <div class="twelve wide right aligned column">
                                <button class="ui bg-primary button margin-left-md" @click="showCreateQuestionnaireModal()">Create Questionnaire</button>
                            </div>
                        </div>
                    <table class="ui selectable celled table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Questionnaire</th>
                                <th>Type</th>
                                <th>Completion Rate</th>
                                <th>No. of Questions</th>
                                <th>Created</th>
                                <th class="hidden"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="selectable" @click="showQuestionnairel(list.id)" v-for="(list, index) in lists" :key="list.id">
                                <td class="center aligned collapsing">{{index +1}}</td>
                                <td>
                                    <h4 class="text-medium text-title">{{list.questionnare_name}}</h4>
                                    <small class="text-grey">Created by {{list.client_name}}</small>
                                </td>
                                <td>{{list.name}}</td>
                                <td>
                                    <h4 class="text-primary">{{list.progressRate}}%</h4>
                                    <small class="text-grey">{{list.totalSurvey}} surveys</small>
                                </td>
                                <td>{{list.totalQuestions}}</td>
                                <td>{{list.created_at}}</td>
                                <td class="center alinged collapsing hidden">
                                    <div class="ui dropdown icon button">
                                        <i class="icon dropdown"></i>
                                        <div class="menu">
                                            <div class="item" @click="showQuestionnairel(list.id)">View Questionnaire</div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                    <div v-show="!loading" @click="getSurveyDetails" class="ui button link"><i class="icon refresh"></i> Load More</div>
                    <loader v-show="loading"></loader>
                </div>
            </div>
        </div>
        <createSurveyModal />
        <createQuestionnaire />
    </div>
</template>
<script>
import store from "@/store";
import Choices from "choices.js";
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
import format from 'date-fns/format';
import Vue from "vue";
import alertify from 'alertifyjs';
import loader from "@/components/loader";
import moment from "moment";
import createSurveyModal from "@/modals/survey-create"
import createQuestionnaire from "@/modals/survey-questionnaire"
export default {
     data(){
        return {
        inboxzero:false,
        totalQuestionnaire:0,
        offset:0,
        limit:10,
        lists:[],
        sort:"",
        loading:false,
        }
    },
    components: {
        createSurveyModal,loader,
        createQuestionnaire
    },
    mounted(){
        $('.ui.dropdown').dropdown();
        let vm = this;
        vm.getSurveyDetails();
    },
    methods:{
        showCreateSurveyModal(qId){
            $("#modal-create-survey").modal({centered: false}).modal("show");
        },
         showCreateQuestionnaireModal(){
            $("#modal-create-questionnaire").modal({centered: false}).modal("show");
        },
        showQuestionnairel(questionnaire_id){
            let vm = this;
            vm.$router.push({name: 'questionnaire-add', params: {id: questionnaire_id}});
        },
        getSurveyDetails(){
            let vm = this;
            
            if (vm.totalQuestionnaire == 0 || vm.totalQuestionnaire >= ((vm.offset) * vm.limit)) {
                vm.offset++;
                vm.loading = true;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/survey/view/questionaire",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        sort: vm.sort,
                        offset: vm.offset,
                        limit: vm.limit
                    }),
                    success: function(response, status, jQxhr) {
                        vm.loading = false;
                        var resp = response.data.data;
                        //console.log(parseInt(response.data.count,10));
                        
                        if (response.data.code != 200) {
                            vm.offset = 0;
                            vm.totalQuestionnaire = 0;
                            vm.inboxzero = true;
                            
                            //alertify.set('notifier', 'position', 'top-right');
                            //alertify.error(response.data.message);

                        } else {
                            vm.totalQuestionnaire = parseInt(response.data.count,10);
                            //console.log("count: "+vm.totalQuestionnaire + " offset: " +vm.offset + " limit :"+vm.limit);
                            for (var i = 0; i < resp.length; i++) {
                                resp[i].progressRate = resp[i].contacts !=0 ? ((parseInt(resp[i].response) / parseInt(resp[i].contacts)) *100).toFixed(0) :0;
                                vm.lists.push(resp[i]);
                                console.log(resp[i].progressRate);
                            }
                                setTimeout(function() {
                                    $('.ui.dropdown').dropdown();
                                }, 100)
                        }
                    },
                    error: function(jQxhr, status, error) {
                        vm.loading = false;
                        vm.offset = 0;
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
                
            }
            
        }
    }
}
</script>
