<template>
    <div class="l-onboard">
        <div class="l-onboard-center">
            <div class="ui centered grid">
                <div class="row">
                    <div class="sixteen wide computer only column">
                        <div class="padding-xl"></div>
                    </div>
                    <div class="sixteen wide center aligned column padding-bottom-md">
                        <router-link to="/"><img src="../assets/images/logo.png" height="40" /></router-link>
                    </div>
                    <div class="twelve wide computer fourteen wide tablet sixteen wide mobile column">
                        <h1 class="padding-bottom-lg text-medium text-center"><strong>Set New Password</strong></h1>
                        <div class="ui negative message" v-show="showMessage">
                            {{messages}}
                        </div>
                        <div class="ui success message" v-show="showMessageSuccess">
                            {{messages}} <a href="/login">Click here to login</a>
                        </div>
                        <form class="ui form" v-on:submit.prevent="changePWD">
                            <div class="field">
                                <label>Enter password</label>
                                <div class="ui large input">
                                    <input type="password" placeholder="enter password" required v-model="newPassword" />
                                </div>
                            </div>
                            <div class="field" id="input-confirm-passowrd">
                                <label>Confirm password</label>
                                <div class="ui large input">
                                    <input type="password" placeholder="confirm password" required v-model="verifyPassword" />
                                </div>
                            </div>
                            <div class="padding-md"></div>
                            <div class="field">
                                <button id="button-reset" type="submit" class="ui large green fluid button">Save Password</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import store from "../store";
    import pageFooter from "../components/page-footer";
    import pageHeader from "../components/page-header";
    import alertify from "alertifyjs";
    export default {
        components: {
            pageFooter,
            pageHeader
        },
        mounted(){
            this.$router.push({ name: "login" });
        },
        data: function() {
            return {
                isHidden: true,
                showMessage: false,
                showMessageSuccess: false,
                messages: "",
                newPassword: "",
                verifyPassword: ""
            }
        },
    
    
    
    }
</script>
