<template>
    <div class="l-app-main bg-primary-bg">
        <div class="l-app-main-inboxzero bg-primary-lighter" v-show="inboxzero">
            <h2>WhatsApp Keyword</h2>
            <p>Apply for whatsApp Keyword<br />on WhatsApp Business Number {{phoneNumber}}</p>
            <div class="padding-sm"></div>
            <button class="ui large bg-primary button" @click="showWhatsAppKeyword()">Apply for whatsApp Keyword</button>
            <div class="padding-xl"></div>
        </div>
        <div class="l-app-dashboard bg-primary-lighter" v-show="!inboxzero">
             
            <div class="ui equal width relaxed grid padded">
                <div class="row">
                    <div class="computer only column">
                        <h3 class="text-medium">WhatsApp Keyword</h3>
                        <table class="ui celled styled unstackable table">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th class="center aligned">Keyword</th>
                                    <th>WhatsApp Phone</th>
                                    <th>Status</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
    
                                <tr v-for="keyword in keywords" :key="keyword.keyword">
    
                                    <td>
                                       
                                            <span class="text-green">{{keyword.title}}</span>
                                      
                                    </td>
                                   
                                    <td>{{keyword.keyword }}</td>
                                    <td >{{keyword.msisdn }}</td>
                                   <td v-show="keyword.status == 1">
                                        <h4 class="text-green text-medium">Active</h4>
                                    </td>
                                    <td v-show="keyword.status == 9">
                                        <h4 class="text-red text-medium">NO KEYWORD</h4>
                                    </td>
                                    <td v-show="keyword.status == 0">
                                        <h4 class="text-red text-medium">Inactive</h4>
                                    </td>
                                    <td>
                                        {{keyword.created_at}}
                                    </td>
                                    <td v-show="keyword.status == 1">
                                        <button class="ui icon red button" @click="updateKeyword(keyword.id,0)">Disabled Keywords</button>
                                    </td>
                                    <td v-show="keyword.status == 9">
                                    </td>
                                    <td v-show="keyword.status == 0">
                                        <button class="ui icon bg-primary button" @click="updateKeyword(keyword.id,1)">Enable</button>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
    
                    </div>
                    <loader v-show="loading"></loader>
                    <div class="mobile only column bg-white">
                        <h3>WhatsApp Keyword</h3>
                        <table class="ui very basic unstackable table">
                            <tr v-for="keyword in keywords" :key="keyword.keyword">
                                <td>
                                    <h3 class="text-medium">{{keyword.title}}</h3>
                                    <h5 class="margin-bottom-sm">{{keyword.keyword}}</h5>
                                    <small>{{keyword.created_at}}</small>
                                    
                                </td>
                                <td class="right aligned top aligned">
                                    <h4> {{keyword.msisdn}}</h4>
                                </td>
                                <td class="collapsing top aligned">
                                     {{keyword.callbackUrl}} <br />
                                     {{keyword.authorization}}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="sixteen wide mobile only column">
                        <button class="ui fluid bg-primary button" @click="showWhatsAppCreateMessage()">Create Message</button>
                    </div>
                </div>
                <div class="middle aligned row">
                    <div class="column">
                        <h3 class="text-medium">First 5 WhatsApp Auto Messages</h3>
                    </div>
                    
                    <div class="computer only right aligned column">
                        <button class="ui bg-primary button" @click="showWhatsAppCreateMessage()">Create Message</button>
                    </div>
                </div>
                <div class="row">
                    <div class="computer only column">
                        
                         <table class="ui celled styled unstackable table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Type</th>
                                    <th>If Keyword</th>
                                    <th class="right aligned">link Message</th>
                                    <th>status</th>
                                    <th>Date</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
    
                                <tr v-for="message in messages" :key="message.id">
                                    <td><small>{{message.id}}</small></td>
                                    
                                    <td>
                                        
                                            <span class="text-green">{{message.type}}</span>
                                       
                                    </td>
                                    <td>
                                        <h4 class="text-green text-medium"> {{message.ifKeyword}}</h4>
                                    </td>
                                    <td class="center aligned collapsing">
                                        {{message.linkMessage}}
                                    </td>
                                    <td v-show="message.status == 1">
                                        <h4 class="text-green text-medium">Active</h4>
                                    </td>
                                    <td v-show="message.status != 1">
                                        <h4 class="text-red text-medium">Pending</h4>
                                    </td>
                                    <td>
                                        {{message.created_at}}
                                    </td>
                                    <td> <button class="ui icon bg-primary button" @click="showWhatsAppEditMessage(message)">Edit</button> </td>
                                </tr>
    
    
    
                            </tbody>
                            <loader v-show="loading"></loader>
                        </table>
                        <div class="padding-md">
                            <div v-show="!loading" @click="getWhatsAppMessage()" class="ui fluid button bottom aligned button">Load More</div>
                        </div>
                    </div>
                     <div class="mobile only column bg-white">
                        <table class="ui very basic unstackable table">
                            <tr v-for="message in messages" :key="message.id" @click="showWhatsAppEditMessage(message)">
                                <td>
                                    <h4 class="text-medium">{{message.type}}</h4>
                                    <small>{{message.created_at}}</small>
                                    
                                </td>
                                <td class="right aligned top aligned">
                                    <h4>{{message.linkMessage}}</h4>
                                </td>
                            </tr>
                            <loader v-show="loading"></loader>
                        </table>
                        <div v-show="!loading" @click="getWhatsAppMessage()" class="ui fluid button bottom aligned button">Load More</div>
                    </div>
                </div>
                <div class="padding-bottom-xl"></div>
            </div>
            <whatsAppkeyword />
            <whatAppEditMessages />
            <whatAppCreateMessages />
        </div>
    </div>
</template>

<script>
    import whatsAppkeyword from "@/modals/whatsApp-keyword";
    import whatAppEditMessages from "@/modals/whatAppEditMessages";
    import whatAppCreateMessages from "@/modals/whatsAppMessageCreate";
    import numeral from "numeral";
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import moment from 'moment';
    import loader from "@/components/loader";
    import format from 'date-fns/format';
    import Chart from 'chart.js';
    import alertify from 'alertifyjs';
    export default {
        components: {
            whatsAppkeyword,
            whatAppEditMessages,
            whatAppCreateMessages,
            loader,
        },
    
        data() {
            return {
                // credits: store.state.credits,
                paybills: [],
                loading: true,
                dateFormat: "D MMM",
                dateFormatTwo: "YYYY-MM-DD",
                dateOne: "",
                dateTwo: "",
                keywords:[],
                messages:[],
                limit: 10,
                offset: 0,
                offsettr: 0,
                totalKeywords:0,
                totalMessage:0,
                inboxzero:true,
                dataLoading:false,
                totalSent:0,
                totalCost:0,
                totalAccount:0,
                day:7,
                phoneNumber:"254709183222"
    
            }
        },
        mounted() {
            const vm = this;
            if(!vm.$cookies.get("key") || vm.$cookies.get("key") == null){
                vm.$router.push({
                    name: 'home'
                });
            }
            var d = new Date();
            var to = format(d.setDate(d.getDate()), this.dateFormatTwo);
            var from = format(d.setDate(d.getDate() - vm.day), this.dateFormatTwo);
            vm.getGraphData(vm.day);
            vm.getWhatsAppKeywords();
            vm.getWhatsAppMessage();
            vm.getAllCount();
            vm.fetchData();
            
        },
    
        methods: {
            fetchData() {
                let vm = this;
                $.post({
                    url: bulkstore.state.bulksmsUrl + 'senderId/list',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        status: 13,
                        p_status: 4,
                        type_id: 5
                    }),
                    success: function(response, status, jQxhr) {
                        if (response.data.data != "") {
                                vm.phoneNumber = response.data.data[0].short_code;
                                var record = {
                                    "title":"",
                                    "keyword":"",
                                    "msisdn":vm.phoneNumber,
                                    "status":9,
                                    "created_at":response.data.data[0].created_at
                                };
                                vm.keywords.push(record);
                                
                            } 
                    },
                    error: function(jQxhr, status, error) {
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.data.message);
                        // console.log(" status bulk " + status + " data " + JSON.stringify(jQxhr.responseJSON.statusDescription));
                    }
                });
    
            },
            getWhatsAppKeywords(){
                let vm = this;
                if (vm.totalKeywords === 0 || vm.totalKeywords >= ((vm.offset - 1) * vm.limit)) {
    
                    vm.loading = true;
                    vm.offset++;
                    $.post({
                        url: store.state.rootUrl + 'whatsApp/view/keywords',
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: this.$cookies.get("key"),
                            limit: vm.limit,
                            offset: vm.offset,
                            msisdn: vm.msisdn,
                            start: vm.dateOne,
                            stop: vm.dateTwo,
    
                        }),
                        success: function(response, status, jQxhr) {
                            vm.loading = false;
                            if (response.data.data != "") {
                                vm.inboxzero = false;
                                //vm.dataLists = response.data.data;
                                vm.totalKeywords = response.data.total;
                                var list_c = response.data.data
                                if (response.data.code != 404) {
                                    for (var i = 0; i < list_c.length; i++) {
                                        list_c[i].created_at = format(list_c[i].created_at, "hh:mma DD MMM YYYY");
                                        vm.keywords.push(list_c[i]);
                                    }
                                } else {
                                    alertify.error(response.data.message);
                                }
                            } else if (vm.offset > 1) {
                                vm.inboxzero = false;
                            } else {
                                vm.inboxzero = true;
                            }
                        },
                        error: function(jQxhr, status, error) {
                            vm.loading = false;
                            vm.dataLists = [];
                            store.commit("setInboxDetails", []);
                            alertify.error(jQxhr.responseJSON.statusDescription);
                        }
                    });
                }

            },
            getWhatsAppMessage(){
                let vm = this;
                if (vm.totalMessage === 0 || vm.totalMessage >= ((vm.offsettr - 1) * vm.limit)) {
    
                    vm.loading = true;
                    vm.offsettr++;
                    $.post({
                        url: store.state.rootUrl + 'whatsApp/view/messages',
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: this.$cookies.get("key"),
                            limit: 5,
                            offset: vm.offsettr,
                            start: vm.dateOne,
                            stop: vm.dateTwo,
    
                        }),
                        success: function(response, status, jQxhr) {
                            vm.loading = false;
                            if (response.data.data != "") {
                                vm.inboxzero = false;
                                //vm.dataLists = response.data.data;
                                vm.totalMessage = response.data.total;
                                var list_c = response.data.data;
                                if (response.data.code != 404) {
                                    for (var i = 0; i < list_c.length; i++) {
                                        list_c[i].created_at = format(list_c[i].created_at, "hh:mma DD MMM YYYY");
                                        vm.messages.push(list_c[i]);
                                    }
                                } else {
                                    alertify.error(response.data.message);
                                }
                            } 
                        },
                        error: function(jQxhr, status, error) {
                            vm.loading = false;
                            vm.dataLists = [];
                            store.commit("setInboxDetails", []);
                            alertify.error(jQxhr.responseJSON.statusDescription);
                        }
                    });
                }

            },
            getGraphData(day) {
    
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/whatsApp/view/graph",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        day: day
                    }),
                    success: function(response, status, jQxhr) {
    
                        vm.graphData = response.data.data;
    
                        let yData = [];
                        let xDate = [];
                        let result = response.data.data;
    
                        result.forEach(function(data) {
                            yData.push(parseInt(data["total"]));
                            xDate.push(data["Date"]);
                        });
    
                        let ctx = $("#payments");
                        let myChart = new Chart(ctx, {
                            type: "line",
                            data: {
                                labels: xDate,
                                datasets: [{
                                    data: yData,
                                    backgroundColor: ["#F7FAFA"],
                                    borderColor: ["#26A842"],
                                    borderWidth: 1
                                }]
                            },
                            options: {
                                maintainAspectRatio: false,
                                responsive: true,
                                layout: {
                                    padding: {
                                        right: 0
                                    }
                                },
                                legend: {
                                    display: false
                                },
                                scales: {
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                                },
                                elements: {
                                    line: {
                                        tension: 0 // disables bezier curves
                                    }
                                }
                            }
                        });
                        // console.log(vm.graphData);
                    },
                    error: function(jQxhr, status, error) {
                        vm.loading = false;
                        //console.log(JSON.stringify(jQxhr.responseJSON.statusDescription));
                    }
                });
    
            },
            updateKeyword(id, status){
                let vm = this;
                

                 $.post({
                    url: store.state.rootUrl + 'whatsApp/keyword/update',
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        keywordID:id,
                        status:status

                    }),
                    success: function(response, status, jQxhr) {
                            alertify.set('notifier', 'position', 'top-right');
                            alertify.success(response.data.message);
                            if(response.data.code == 200){
                                vm.keywords =[];
                                vm.offset =0;
                                vm.totalKeywords = 0;
                                vm.getWhatsAppKeywords();
                            }
                            
                    },
                    error: function(jQxhr, status, error) {
                        alertify.set('notifier', 'position', 'top-right');
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
                
            },
            getAllCount(){
                let vm = this;
                 vm.dataLoading = true;
                 $.post({
                        url: store.state.rootUrl + 'whatsApp/view/count',
                        type: "POST",
                        data: JSON.stringify({
                            apiKey: this.$cookies.get("key"),
    
                        }),
                        success: function(response, status, jQxhr) {
                            vm.dataLoading = false;
                            vm.totalSent = response.data.data[0].totalMessage;
                            vm.totalCost = response.data.data[0].totalCost;
                            vm.totalAccount = response.data.data[0].totalAccount;

                        },
                        error: function(jQxhr, status, error) {
                            vm.dataLoading = false;
                            vm.dataLists = [];
                            store.commit("setInboxDetails", []);
                            alertify.error(jQxhr.responseJSON.statusDescription);
                        }
                    });

            },
            showWhatsAppKeyword() {
                $('.ui.modal#modal-whatsApp-keyword').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            showWhatsAppEditMessage(val) {
                store.commit("setWhatsAppMessage", val);
                $('.ui.modal#modal-whatsApp-edit-message').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            showWhatsAppCreateMessage() {
                $('.ui.modal#modal-whatsApp-message-create').modal({
                    centered: false,
                    closable: true
                }).modal("show");
            },
            formatDates(dateOne, dateTwo) {
                let formattedDates = "";
                if (dateOne) {
                    formattedDates = format(dateOne, this.dateFormat);
                }
                if (dateTwo) {
                    formattedDates += " - " + format(dateTwo, this.dateFormat);
                }
                return formattedDates;
            },
        
        },
        filters: {
            formatNumber(value) {
                return numeral(value).format("0,0.00");
            },
            formatInt(value) {
                return numeral(value).format("0,0");
            }
        }
    }
</script>
