<template>
  <div class="l-app-dashboard">
    <div class="ui relaxed padded grid">
      <div class="computer only tablet only equal width middle aligned row hidden">
        <div class="column">
          <div class="ui warning message">
            <div class="ui grid">
              <div class="twelve wide column">
                <h4>
                  <strong class="margin-right-md">{{ offers.offer_name }}</strong>
                  <small
                    ><br />Boost your business by reaching more customers with discounted
                    SMS offers from today till midnight 2nd June, 2021.
                  </small>
                </h4>
              </div>
              <div class="four wide right aligned column">
                <button class="ui green button" @click="showDeposit()">
                  Buy SMS Offer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="computer only tablet only equal width middle aligned row hidden"
        v-show="userBonusStatus"
      >
        <div class="column">
          <div class="ui warning message">
            <h4>
              You have
              <strong class="margin-right-md">{{ userBonus }} free credits.</strong>
              <router-link :to="{ name: 'bulk-create' }" class="text-underline"
                >Send test message</router-link
              >
            </h4>
          </div>
        </div>
      </div>
      <div class="computer only tablet only equal width row">
        <div class="column">
          <h3 class="text-medium">Summary</h3>
          <h4>{{ formatDates(dateOne, dateTwo) }}</h4>
          <small
            >Previous period: {{ formatDates(dateOnePrevious, dateTwoPrevious) }}</small
          >
        </div>
        <div class="right aligned column">
          <div class="ui input datepicker-trigger">
            <input
              type="text"
              id="datepicker-trigger"
              placeholder="Select date"
              :value="formatDates(dateOne, dateTwo)"
            />
            <AirbnbStyleDatepicker
              :trigger-element-id="'datepicker-trigger'"
              :mode="'range'"
              :fullscreen-mobile="true"
              :months-to-show="2"
              :date-one="dateOne"
              :date-two="dateTwo"
              :close-after-select="false"
              @date-one-selected="
                (val) => {
                  dateOne = val;
                }
              "
              @date-two-selected="
                (val) => {
                  dateTwo = val;
                }
              "
              @apply="fetchSummaryData(dateOne, dateTwo)"
            />
          </div>
        </div>
      </div>
      <div class="computer only tablet only equal width row">
        <div class="column">
          <div
            @click="$router.push({ name: 'bulk-messages' })"
            class="ui green inverted segment margin-bottom-sm"
            :class="{ loading: dataLoading }"
          >
            <div class="padding-sm">
              <h3>SMS Sent</h3>
              <h1 class="text-medium text-2x">
                <strong>{{ numberWithCommas(totalSent) }}</strong>
              </h1>
              <h5>
                <i class="icon chevron up" v-show="sentUp"></i
                ><i class="icon chevron down" v-show="!sentUp"></i>
                {{ percentagePreviousSent }}% from previous period
              </h5>
            </div>
          </div>
        </div>
        <div class="column">
          <div
            class="ui teal inverted segment margin-bottom-sm"
            @click="$router.push({ name: 'contacts' })"
            :class="{ loading: contactLoading }"
          >
            <div class="padding-sm">
              <h3>Contacts</h3>
              <h1 class="text-medium text-2x">
                <strong>{{ numberWithCommas(contacts) }}</strong>
              </h1>
              <h5>{{ numberWithCommas(contactList) }} contact lists</h5>
            </div>
          </div>
        </div>
        <div class="column">
          <div
            class="ui bg-primary-dark inverted segment margin-bottom-sm"
            :class="{ loading: dataLoading }"
          >
            <div class="padding-sm">
              <h3>Total Cost</h3>
              <h1 class="text-medium text-2x">
                <strong>KES {{ numberWithCommas(totalTransaction.toFixed(2)) }}</strong>
              </h1>
              <h5>
                <i class="icon chevron up" v-show="costUp"></i>
                <i class="icon chevron down" v-show="!costUp"></i>
                {{ percentagePreviousCost }}% from previous period
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="computer only tablet only equal width row">
        <div class="sixteen wide column">
          <div class="ui segment" :class="{ loading: dataLoading }">
            <div class="padding-sm">
              <div class="ui grid">
                <div class="top aligned row">
                  <div
                    class="five wide computer six wide tablet sixteen wide mobile column"
                  >
                    <h3 class="text-medium">Delivery Rate</h3>
                    <h1 class="text-primary text-2x">
                      <strong>{{ percentageDelivered }}%</strong>
                    </h1>
                    <!--<h5 class="text-grey">Delivery Rate</h5> -->
                    <div class="padding-sm"></div>
                    <div class="padding-bottom-sm">
                      <i class="fas fa-circle text-primary"></i>
                      <strong> {{ numberWithCommas(totalSent) }}</strong> Sent
                    </div>
                    <div class="padding-bottom-sm">
                      <i class="fas fa-circle text-orange"></i
                      ><strong> {{ numberWithCommas(scheduleTotal) }}</strong> Schedule
                    </div>
                    <div class="padding-bottom-sm">
                      <i class="fas fa-circle text-orange"></i
                      ><strong> {{ numberWithCommas(totalPending) }}</strong> Pending
                    </div>
                    <div class="padding-bottom-sm">
                      <i class="fas fa-circle text-red"></i>
                      <strong> {{ numberWithCommas(totalFailed) }}</strong> failed
                    </div>
                  </div>
                  <div class="eleven wide computer only column">
                    <div class="ui bg-grey-lightest segment">
                      <div class="padding-bottom-sm">
                        <h3>Delivery Rate</h3>
                        <div class="padding-bottom-md">
                          <small>Daily Delivery rate</small>
                        </div>
                      </div>
                      <div><canvas class="height-sm" id="payments"></canvas></div>
                    </div>
                  </div>
                </div>
                <div class="padding-sm"></div>
                <div class="row">
                  <div class="column">
                    <h3 class="text-medium text-title">Delivery By Channel</h3>
                    <table class="ui very basic compact unstackable table">
                      <thead>
                        <tr>
                          <th>Channel</th>
                          <th>Traffic</th>
                          <th colspan="2">Delivery Rate</th>
                          <th class="right aligned">Channel Cost</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <h4>SMS</h4>
                          </td>
                          <td>
                            <h4 class="text-medium">{{ numberWithCommas(totalSent) }}</h4>
                          </td>
                          <td>
                            <h4 class="text-medium">{{ percentageDelivered }}%</h4>
                          </td>
                          <td class="six wide">
                            <div
                              class="ui indicating small progress margin-bottom-none"
                              :data-percent="percentageDelivered"
                            >
                              <div class="bar"></div>
                            </div>
                          </td>
                          <td class="right aligned">
                            <h4 class="text-medium">
                              KES {{ numberWithCommas(totalTransaction.toFixed(2)) }}
                            </h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="padding-top-xs"></div>
        </div>
        <div class="eight wide computer eight wide tablet sixteen wide mobile column">
          <div class="ui segment" :class="{ loading: dataLoading }">
            <div class="padding-sm">
              <h3 class="text-medium text-title">Delivery By Mobile Network</h3>
              <table class="ui very basic compact unstackable table">
                <tbody>
                  <tr>
                    <td>
                      <h4>Safaricom</h4>
                    </td>
                    <td>
                      <h4 class="text-medium">{{ numberWithCommas(safaricomTotal) }}</h4>
                    </td>
                    <td>
                      <h4 class="text-medium">{{ percentageSafaricom }}%</h4>
                    </td>
                    <td class="six wide">
                      <div
                        class="ui indicating small progress margin-bottom-none"
                        :data-percent="percentageSafaricom"
                      >
                        <div class="bar"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4>Airtel</h4>
                    </td>
                    <td>
                      <h4 class="text-medium">{{ numberWithCommas(AirtelKeTotal) }}</h4>
                    </td>
                    <td>
                      <h4 class="text-medium">{{ percentageAirtel }}%</h4>
                    </td>
                    <td class="six wide">
                      <div
                        class="ui indicating small progress margin-bottom-none"
                        :data-percent="percentageAirtel"
                      >
                        <div class="bar"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4>Telkom</h4>
                    </td>
                    <td>
                      <h4 class="text-medium">{{ numberWithCommas(TelkomTotal) }}</h4>
                    </td>
                    <td>
                      <h4 class="text-medium">{{ percentageTelkom }}%</h4>
                    </td>
                    <td class="six wide">
                      <div
                        class="ui indicating small progress margin-bottom-none"
                        :data-percent="percentageTelkom"
                      >
                        <div class="bar"></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="eight wide computer eight wide tablet sixteen wide mobile column">
          <div class="ui segment">
            <div class="padding-sm">
              <h3 class="text-medium text-title">Delivery Rate by Countries</h3>
              <table class="ui very basic compact unstackable table">
                <tbody>
                  <tr>
                    <td>
                      <h4>Kenya</h4>
                    </td>
                    <td>
                      <h4 class="text-medium">{{ numberWithCommas(totalSent) }}</h4>
                    </td>
                    <td>
                      <h4 class="text-medium">{{ pertcentageKenya }}%</h4>
                    </td>
                    <td class="six wide">
                      <div
                        class="ui indicating small progress margin-bottom-none"
                        :data-percent="pertcentageKenya"
                      >
                        <div class="bar"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4>Uganda</h4>
                    </td>
                    <td>
                      <h4 class="text-medium">{{ numberWithCommas(totalUganda) }}</h4>
                    </td>
                    <td>
                      <h4 class="text-medium">{{ pertcentageUganda }}%</h4>
                    </td>
                    <td class="six wide">
                      <div
                        class="ui indicating small progress margin-bottom-none"
                        :data-percent="pertcentageUganda"
                      >
                        <div class="bar"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4>Rwanda</h4>
                    </td>
                    <td>
                      <h4 class="text-medium">{{ numberWithCommas(totalRwanda) }}</h4>
                    </td>
                    <td>
                      <h4 class="text-medium">{{ pertcentageRwanda }}%</h4>
                    </td>
                    <td class="six wide">
                      <div
                        class="ui indicating small progress margin-bottom-none"
                        :data-percent="pertcentageRwanda"
                      >
                        <div class="bar"></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="computer only tablet only equal width row">
        <div class="computer only column">
          <div class="padding-md"></div>
          <h3 class="text-medium">Last 5 Messages Sent</h3>
          <table class="ui styled celled unstackable table">
            <thead>
              <tr>
                <th class="eight wide">Message</th>
                <th>Traffic</th>
                <th class="right aligned">Cost</th>
                <th>Delivery</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="list in lists" :key="list.campaign_id">
                <td>
                  <h4 class="text-bold-dark">{{ list.message }}</h4>
                  <small class="text-grey padding-right-lg"
                    >Sent {{ list.send_time | formatDate }}</small
                  >
                  <small class="text-grey padding-right-lg"></small>
                </td>
                <td>
                  <h4>{{ list.campaign_sent }}</h4>
                </td>
                <td class="right aligned">
                  <span v-show="list.origin != 'PAYMENT_ACK_QUEUE'">
                    {{ list.cost_incurred | formatAmount }}
                  </span>
                  <span v-show="list.origin == 'PAYMENT_ACK_QUEUE'"
                    >{{ (list.cost_incurred * list.campaign_sent) | formatAmount }}
                  </span>
                </td>
                <td class="right aligned">
                  <h4>
                    <span class="text-medium"
                      >{{ list.campaign_delivered }} delivered</span
                    >
                  </h4>
                  <small class="padding-top-sm text-grey"
                    >{{ list.campaign_sent }} Sent,
                    {{ list.campaign_failed }} Failed</small
                  >
                  <div
                    class="ui indicating tiny progress margin-bottom-none margin-top-sm"
                    :data-value="list.campaign_delivered"
                    :data-total="list.campaign_sent"
                  >
                    <div class="bar"></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="ui padded grid">
      <div class="mobile only row bg-orange-light padding-md" v-show="userBonusStatus">
        <div class="column">
          <h4>
            You have
            <strong class="margin-right-md">{{ userBonus }} free credits.</strong>
            <router-link :to="{ name: 'bulk-create' }" class="text-underline"
              >Send test message</router-link
            >
          </h4>
        </div>
      </div>
      <div class="mobile only equal width row bg-white margin-bottom-xs">
        <div class="eight wide column">
          <h3 class="text-medium">Summary</h3>
        </div>
        <div class="eight wide right aligned column">
          <div class="ui floating dropdown">
            {{ rangePeriod }}<i class="icon dropdown"></i>
            <div class="menu">
              <div class="item" @click="fetchSummaryData(dateOneYesterday, dateTwo, '1')">
                Yesterday
              </div>
              <div class="item" @click="fetchSummaryData(dateOneSeven, dateTwo, '2')">
                Last 7 days
              </div>
              <div class="item" @click="fetchSummaryData(dateOne28, dateTwo, '3')">
                Last 28 days
              </div>
              <div class="item" @click="fetchSummaryData(dateOne90, dateTwo, '4')">
                Last 90 days
              </div>
            </div>
          </div>
        </div>
        <div class="sixteen wide column">
          <div class="l-app-card-3">
            <div class="l-app-card">
              <div class="bg-primary">
                <h5 class="text-medium padding-bottom-sm">SMS</h5>
                <h2>{{ totalSent }}</h2>
                <small
                  ><i class="icon chevron up"></i>{{ percentagePreviousSent }}%</small
                >
              </div>
            </div>
            <div class="l-app-card">
              <div class="bg-teal">
                <h5 class="text-medium padding-bottom-sm">Contacts</h5>
                <h2>{{ contacts }}</h2>
                <small>{{ contactList }} lists</small>
              </div>
            </div>
            <div class="l-app-card">
              <div class="bg-primary-dark">
                <h5 class="text-medium padding-bottom-sm">Cost</h5>
                <h2>{{ totalTransaction.toFixed(2) }}/-</h2>
                <small
                  ><i class="icon chevron up"></i>{{ percentagePreviousSent }}%</small
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile only row bg-white border-top border-bottom margin-bottom-xs">
        <div class="column">
          <h3 class="text-medium">Sent Rate</h3>
          <h1 class="text-primary text-2x">
            <strong>{{ percentageDelivered }}%</strong>
          </h1>
          <h5 class="text-grey">Successful Sent Rate</h5>
          <div class="padding-md"></div>
          <h3 class="text-medium text-title">Delivery By Channel</h3>
          <table
            class="ui very basic compact unstackable table"
            :class="{ loading: dataLoading }"
          >
            <thead>
              <tr>
                <th>Channel</th>
                <th>Rate</th>
                <th>Traffic</th>
                <th class="right aligned">Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h4>SMS</h4>
                </td>
                <td>
                  <h4 class="text-medium">{{ percentageDelivered }}%</h4>
                </td>
                <td>
                  <h4 class="text-medium">{{ totalSent }}</h4>
                </td>
                <td class="right aligned">
                  <h4 class="text-medium">{{ totalTransaction.toFixed(2) }}/-</h4>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="padding-md"></div>
          <h3 class="text-medium text-title">Delivery By Mobile Network</h3>
          <table
            class="ui very basic compact unstackable table"
            :class="{ loading: dataLoading }"
          >
            <tbody>
              <tr>
                <td>
                  <h4>Safaricom</h4>
                </td>
                <td>
                  <h4 class="text-medium">{{ safaricomTotal }}</h4>
                </td>
                <td class="right aligned">
                  <h4 class="text-medium">{{ percentageSafaricom }}%</h4>
                </td>
              </tr>
              <tr>
                <td>
                  <h4>Airtel</h4>
                </td>
                <td>
                  <h4 class="text-medium">{{ AirtelKeTotal }}</h4>
                </td>
                <td class="right aligned">
                  <h4 class="text-medium">{{ percentageAirtel }}%</h4>
                </td>
              </tr>
              <tr>
                <td>
                  <h4>Telkom</h4>
                </td>
                <td>
                  <h4 class="text-medium">{{ TelkomTotal }}</h4>
                </td>
                <td class="right aligned">
                  <h4 class="text-medium">{{ percentageTelkom }}%</h4>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="mobile only row bg-white border-top border-bottom">
        <div class="column">
          <div class="five wide computer six wide tablet sixteen wide mobile column">
            <h2 class="text-medium text-title">Last 5 Sent Messages</h2>
            <table class="ui very basic unstackable top aligned table">
              <thead>
                <tr>
                  <th>Campaign Name</th>
                  <th class="right aligned">Delivery</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="list in lists" :key="list.campaign_id">
                  <td>
                    <router-link
                      :to="{
                        name: 'bulk-messages-details',
                        params: { id: list.campaign_id },
                      }"
                      class="border-right border-lighter d-inline-block padding-right-md"
                    >
                      <h4>{{ list.campaign_name | truncate(20, "...") }}</h4>
                      <small class="text-grey"
                        >Sent {{ list.send_time | formatDate }}</small
                      ><br />
                      <small class="hidden"
                        >{{ (list.campaign_delivered / list.campaign_sent) * 100 }}%
                        delivery</small
                      >
                    </router-link>
                  </td>
                  <td class="right aligned">
                    <h4 class="text-medium">{{ list.campaign_delivered }} delivered</h4>
                    <div
                      class="ui indicating tiny progress margin-bottom-none margin-top-sm"
                      :data-value="list.campaign_delivered"
                      :data-total="list.campaign_sent"
                    >
                      <div class="bar"></div>
                    </div>
                    <small class="padding-top-sm text-grey"
                      >{{ list.campaign_sent }} Sent, KES. {{ list.cost_incurred }}</small
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <DepositOfferModal />
      </div>
    </div>
  </div>
</template>

<script>
import DepositOfferModal from "@/modals/deposit-offer";
import loader from "@/components/loader";
import message from "./messages";
import store from "@/store";
import pageFooter from "@/components/page-footer";
import pageHeader from "@/components/page-header";
import Chart from "chart.js";
import numeral from "numeral";
import bulkstore from "@/bulk/bulksms-state";
import Choices from "choices.js";
import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
import format from "date-fns/format";
import Vue from "vue";
import alertify from "alertifyjs";
import { mapActions } from "vuex";
import _ from "lodash";
import moment from "moment";
export default {
  data() {
    return {
      offersState: false,
      offers: [],
      userBalance: "",
      userBonus: "",
      totalMessage: "",
      lastSent: "",
      userBonusStatus: false,
      messageList: "",
      scheduleMessage: false,
      contactsOptions: null,
      composedMessage: "",
      dateFormat: "D MMM",
      dateFormatTwo: "YYYY-MM-DD",
      dateOne: "",
      dateTwo: "",
      dateOnePrevious: "",
      dateTwoPrevious: "",
      contactListData: [],
      contactGroup: [],
      phoneGroup: [],
      numberContainer: [],
      getTime: "",
      getDate: "",
      dataLists: null,
      lists: [],
      totalMessageSent: 0,
      limit: 5,
      offset: 0,
      loading: true,
      days: 7,
      graphData: [],
      contactList: 0,
      contacts: 0,
      totalSent: 0,
      totalTransaction: 0,
      totalDelivered: 0,
      totalFailed: 0,
      percentageDelivered: 0,
      totalPending: 0,
      safaricomTotal: 0,
      AirtelKeTotal: 0,
      TelkomTotal: 0,
      percentageSafaricom: 0,
      percentageAirtel: 0,
      percentageTelkom: 0,
      networkSString: "",
      networkObject: null,
      dataLoading: false,
      contactLoading: false,
      totalsentPrevious: 0,
      totalcostPrevious: 0,
      percentagePreviousSent: 0,
      percentagePreviousCost: 0,
      dateOneSeven: 0,
      dateOne28: 0,
      dateOne90: 0,
      rangePeriod: "Last 7 days",
      scheduleTotal: 0,
      sentUp: true,
      costUp: true,
      totalRwanda: 0,
      totalUganda: 0,
      pertcentageUganda: 0,
      pertcentageRwanda: 0,
      pertcentageKenya: 0,
    };
  },
  components: {
    // pageFooter,
    pageHeader,
    loader,
    message,
    DepositOfferModal,
  },
  mounted() {
    let vm = this;
    if (!vm.$cookies.get("key") || vm.$cookies.get("key") == null) {
      vm.$router.push({
        name: "home",
      });
    }
    const datepickerOptions = {};
    Vue.use(AirbnbStyleDatepicker, datepickerOptions);
    vm.dateTwo = format(Date(), this.dateFormatTwo);
    var d = new Date();
    vm.dateOneYesterday = format(d.setDate(d.getDate() - 1), this.dateFormatTwo);
    vm.dateOne = format(d.setDate(d.getDate() - 7), this.dateFormatTwo);
    vm.dateOneSeven = format(d.setDate(d.getDate() - 7), this.dateFormatTwo);
    vm.dateOne28 = format(d.setDate(d.getDate() - 28), this.dateFormatTwo);
    vm.dateOne90 = format(d.setDate(d.getDate() - 90), this.dateFormatTwo);
    vm.getAllessage();
    vm.getOffers();

    vm.fetchSummaryData(vm.dateOne, vm.dateTwo);
    vm.getContactList();
    $(".ui.dropdown").dropdown();

    vm.testAction();
    vm.getGraphData(vm.dateOne, vm.dateTwo);
    vm.getAllessageState();
  },
  methods: {
    ...mapActions(["testAction", "selectCampaign"]),
    formatDates(dateOne, dateTwo) {
      let formattedDates = "";
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat);
      }

      if (dateTwo) {
        formattedDates += " - " + format(dateTwo, this.dateFormat);
      }
      return formattedDates;
    },
    getOffers() {
      let vm = this;
      $.post({
        url: store.state.rootURLBulkAPI + "v3/offers/all",
        type: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key"),
        }),
        success: function (response, status, jQxhr) {
          if (response.data.code != 200) {
            vm.offersState = false;
            alertify.success(response.data.message);
          } else {
            vm.offersState = true;
            vm.offers = response.data.data[0];
          }
        },
        error: function (jQxhr, status, error) {
          vm.offersState = false;
        },
      });
    },
    getAllessageState() {
      let vm = this;
      vm.inboxzero = false;
      $.post({
        url: store.state.rootURLBulkAPI + "v3/SMS/Reportview",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
          offset: "",
        }),
        success: function (response, status, jQxhr) {
          vm.loading = false;
          vm.sentTotal = response.data.record_count;
          if (vm.sentTotal == 0) {
            vm.inboxzero = true;
          }
          vm.scheduleTotal = response.data.Scheduled;
          var pending = response.data.pending;
          var awaitingApproval = response.data.AwaitingApproval;
          if (pending != "" && awaitingApproval != "") {
            vm.pendingTotal =
              Number(response.data.pending) + Number(response.data.AwaitingApproval);
          } else {
            vm.pendingTotal = "";
          }
        },
        error: function (jQxhr, status, error) {
          ///console.log(JSON.stringify(jQxhr.responseJSON.statusDescription));
        },
      });
    },
    getAllessage() {
      let vm = this;
      if (
        vm.totalMessageSent === 0 ||
        vm.totalMessageSent >= (vm.offset - 1) * vm.limit
      ) {
        vm.offset++;
        vm.loading = true;
        $.post({
          url: store.state.rootURLBulkAPI + "v3/SMS/Reportview",
          type: "POST",
          data: JSON.stringify({
            apiKey: this.$cookies.get("key"),
            offset: "",
            short_code: "",
            message: "",
            sort: "",
            export: "",
            startDate: "",
            endDate: "",
            status: "",
            offset: vm.offset,
            limit: vm.limit,
          }),
          success: function (response, status, jQxhr) {
            vm.loading = false;
            vm.totalMessageSent = response.data.success;
            var list_c = response.data.data;

            for (var i = 0; i < list_c.length; i++) {
              vm.lists.push(list_c[i]);
            }
          },
          error: function (jQxhr, status, error) {
            vm.loading = false;
          },
        });
      }
    },
    getContactList: function () {
      //this.dataAmount = "";
      var vm = this;
      vm.contactLoading = true;
      $.post({
        url: store.state.rootUrl + "contact/get/list",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
        }),
        success: function (response, status, jQxhr) {
          vm.contactLoading = false;
          vm.loading = false;
          if (response.data.data != null) {
            var list_c = response.data.data;
            var totalContacts = 0;
            for (var i = 0; i < list_c.length; i++) {
              totalContacts = totalContacts + parseInt(list_c[i].total, 10);
            }
            vm.contactList = response.data.data.length;
            vm.contacts = totalContacts;
          }
        },
        error: function (jQxhr, status, error) {},
      });
    },
    fetchSummaryData(from, to, type = null) {
      let vm = this;
      if (type == 1) {
        vm.rangePeriod = "Yesterday";
      }
      if (type == 2) {
        vm.rangePeriod = "Last 7 days";
      }
      if (type == 3) {
        vm.rangePeriod = "Last 28 days";
      }
      if (type == 4) {
        vm.rangePeriod = "Last 90 days";
      }

      vm.dataLoading = true;
      vm.totalSent = 0;
      vm.totalTransaction = 0;
      vm.totalDelivered = 0;
      vm.totalFailed = 0;
      vm.safaricomTotal = 0;
      vm.AirtelKeTotal = 0;
      vm.TelkomTotal = 0;
      vm.totalPending = 0;
      vm.percentageDelivered = 0;
      vm.percentageSafaricom = 0;
      vm.percentageTelkom = 0;
      vm.dateOnePrevious = 0;
      vm.dateTwoPrevious = 0;
      const date1 = new Date(from);
      const date2 = new Date(to);
      const diffTime = Math.abs(date2.getTime() - date1.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      const diffDaysTwo = diffDays + 1;
      const diffDaysOne = diffDays + diffDaysTwo;
      vm.dateOnePrevious = format(
        date1.setDate(date1.getDate() - diffDaysOne),
        this.dateFormatTwo
      );
      vm.dateTwoPrevious = format(
        date2.setDate(date2.getDate() - diffDaysTwo),
        this.dateFormatTwo
      );

      vm.getGraphData(from, to);

      $.post({
        url: store.state.rootURLBulkAPI + "v3/SMS/Reportdashboard/stats",
        type: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key"),
          start: from,
          end: to,
        }),
        success: function (response, status, jQxhr) {
          vm.dataLoading = false;
          var list_c = response.data.data;

          if (list_c.length <= 0) {
            alertify.set("notifier", "position", "top-right");
            //alertify.error(response.data.message);
          } else {
            var floor = Math.floor;
            var totalSentSMS = 0;
            for (var i = 0; i < list_c.length; i++) {
              vm.totalSent = vm.totalSent + Number(list_c[i].sent);
              vm.totalTransaction = vm.totalTransaction + Number(list_c[i].cost_incurred);
              vm.totalDelivered = vm.totalDelivered + Number(list_c[i].delivered);
              vm.totalFailed = vm.totalFailed + Number(list_c[i].failed);
              vm.networkSString = list_c[i].networks;
              vm.totalRwanda = vm.totalRwanda + Number(list_c[i].MTN_RWX);
              vm.totalUganda = vm.totalUganda + Number(list_c[i].MTN_UGX);
              vm.safaricomTotal =
                vm.safaricomTotal + Number(list_c[i].Safaricom) + Number(list_c[i].Yu);
              vm.AirtelKeTotal = vm.AirtelKeTotal + Number(list_c[i].airtel);
              vm.TelkomTotal = vm.TelkomTotal + Number(list_c[i].TELKOM);
            }

            //vm.totalPending = vm.totalSent - (vm.totalDelivered + vm.totalFailed);
            vm.percentageDelivered = (
              (vm.totalDelivered /
                (vm.totalSent - (vm.totalSent - (vm.totalDelivered + vm.totalFailed)))) *
              100
            ).toFixed(2);

            vm.pertcentageUganda = (
              (vm.totalUganda / (vm.totalSent + vm.totalUganda + vm.totalRwanda)) *
              100
            ).toFixed(2);
            vm.pertcentageRwanda = (
              (vm.totalRwanda / (vm.totalSent + vm.totalUganda + vm.totalRwanda)) *
              100
            ).toFixed(2);
            vm.pertcentageKenya = (
              (vm.totalSent / (vm.totalSent + vm.totalUganda + vm.totalRwanda)) *
              100
            ).toFixed(2);
            vm.percentageSafaricom = ((vm.safaricomTotal / vm.totalSent) * 100).toFixed(
              2
            );
            vm.percentageAirtel = ((vm.AirtelKeTotal / vm.totalSent) * 100).toFixed(2);
            vm.percentageTelkom = ((vm.TelkomTotal / vm.totalSent) * 100).toFixed(2);

            vm.fetchSummaryDataPrevious(vm.dateOnePrevious, vm.dateTwoPrevious);
            setTimeout(function () {
              $(".ui.progress").progress();
            }, 100);
          }
        },
        error: function (jQxhr, status, error) {
          alertify.set("notifier", "position", "top-right");
          alertify.error(jQxhr.responseJSON.statusDescription);
          if (jQxhr.responseJSON.statusDescription == "Authentication Failure.") {
            vm.$cookies.remove("key");
            vm.$cookies.remove("username");
            vm.$cookies.remove("iscalled");
            vm.$cookies.remove("reseller");
            vm.$cookies.remove("reseller-account");
            vm.$cookies.remove("reseller-logo_url");
            vm.$cookies.remove("reseller-baseColor");
            vm.$cookies.remove("reseller-secondary_color");
            store.state.user = null;
            bulkstore.state.selectUser = null;
            //vm.$forceUpdate();
            // vm.$router.go();
            // window.location.href = "https://vaspro.co";
            vm.$router.push({
              path: "/",
            });
          }
        },
      });
    },
    loadData(selectedCampaign) {
      let vm = this;
      message.selectCampaign(selectedCampaign);
    },
    fetchSummaryDataPrevious(from, to) {
      let vm = this;
      vm.dataLoading = true;
      vm.totalsentPrevious = 0;
      vm.totalcostPrevious = 0;
      vm.percentagePreviousSent = 0;
      vm.percentagePreviousCost = 0;
      $.post({
        url: store.state.rootURLBulkAPI + "v3/SMS/Reportdashboard/stats",
        type: "POST",
        data: JSON.stringify({
          apiKey: vm.$cookies.get("key"),
          start: from,
          end: to,
        }),
        success: function (response, status, jQxhr) {
          vm.dataLoading = false;
          var list_c = response.data.data;

          if (list_c.length <= 0) {
            alertify.set("notifier", "position", "top-right");
            //alertify.error("No Records for Previous Period");
          } else {
            for (var i = 0; i < list_c.length; i++) {
              vm.totalsentPrevious =
                vm.totalsentPrevious + parseInt(list_c[i].sms_pages, 10);
              vm.totalcostPrevious =
                vm.totalcostPrevious + parseInt(list_c[i].cost_incurred, 10);
            }
            if (vm.totalsentPrevious > vm.totalSent) {
              vm.percentagePreviousSent = (
                ((vm.totalsentPrevious - vm.totalSent) / vm.totalsentPrevious) *
                100
              ).toFixed(2);
              vm.sentUp = false;
            } else {
              vm.percentagePreviousSent = (
                ((vm.totalSent - vm.totalsentPrevious) / vm.totalSent) *
                100
              ).toFixed(2);
              vm.sentUp = true;
            }
            if (vm.totalcostPrevious > vm.totalTransaction) {
              vm.percentagePreviousCost = (
                ((vm.totalcostPrevious - vm.totalTransaction) / vm.totalcostPrevious) *
                100
              ).toFixed(2);
            } else {
              vm.percentagePreviousCost = (
                ((vm.totalTransaction - vm.totalcostPrevious) / vm.totalTransaction) *
                100
              ).toFixed(2);
            }
            setTimeout(function () {
              $(".ui.progress").progress();
            }, 100);
          }
        },
        error: function (jQxhr, status, error) {
          alertify.set("notifier", "position", "top-right");
          alertify.error(jQxhr.responseJSON.statusDescription);
          if (jQxhr.responseJSON.statusDescription == "Authentication Failure.") {
            vm.$cookies.remove("key");
            vm.$cookies.remove("username");
            vm.$cookies.remove("iscalled");
            vm.$cookies.remove("reseller");
            vm.$cookies.remove("reseller-account");
            vm.$cookies.remove("reseller-logo_url");
            vm.$cookies.remove("reseller-baseColor");
            vm.$cookies.remove("reseller-secondary_color");
            store.state.user = null;
            bulkstore.state.selectUser = null;
            //vm.$forceUpdate();
            // vm.$router.go();
            // window.location.href = "https://vaspro.co";
            vm.$router.push({
              path: "/",
            });
          }
        },
      });
    },
    getGraphData(from, to) {
      let vm = this;
      $.post({
        url: store.state.rootURLBulkAPI + "v3/SMS/Reportgraph",
        type: "POST",
        data: JSON.stringify({
          apiKey: this.$cookies.get("key"),
          start: from,
          end: to,
          paybill: "",
        }),
        success: function (response, status, jQxhr) {
          vm.graphData = response.data.data;

          let yData = [];
          let xDate = [];
          let result = response.data.data;

          result.forEach(function (data) {
            yData.push(parseInt(data["COUNT"]));
            xDate.push(data["Date"]);
          });

          let ctx = $("#payments");
          let myChart = new Chart(ctx, {
            type: "line",
            data: {
              labels: xDate,
              datasets: [
                {
                  data: yData,
                  backgroundColor: ["#26A842"],
                  borderColor: ["#26A842"],
                  borderWidth: 1,
                },
              ],
            },
            options: {
              maintainAspectRatio: false,
              responsive: true,
              layout: {
                padding: {
                  right: 0,
                },
              },
              legend: {
                display: false,
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
              elements: {
                line: {
                  tension: 0, // disables bezier curves
                },
              },
            },
          });
        },
        error: function (jQxhr, status, error) {
          vm.loading = false;
        },
      });
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    showDeposit() {
      console.log();
      $(".ui.modal#modal-deposit-offer")
        .modal({
          centered: false,
          closable: true,
        })
        .modal("show");
    },
  },
  filters: {
    formatDate: function (value) {
      if (!value) return "";
      return moment(value).fromNow();
    },
    formatAmount: function (value) {
      return numeral(value).format("0,0.00");
    },
    truncate: function (text, length, clamp) {
      clamp = clamp || "...";
      var node = document.createElement("div");
      node.innerHTML = text;
      var content = node.textContent;
      return content.length > length ? content.slice(0, length) + clamp : content;
    },
  },
};
</script>
