<template>
    <div class="ui tiny modal" id="modal-status-template">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md">
                <h2 class="padding-bottom-sm">Status Template</h2>
                <h4>You can update status of  template {{originalName}}</h4>
                <div class="ui negative message" v-show="templateListStatus">
                            {{createListDesc}} 
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui cancel button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary button" @click="editTemplate()">Update Template</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import alertify from "alertifyjs";
    import bulkstore from "@/bulk/bulksms-state";
    export default {
        data() {
            return {
                inboxzero: false,
                template_title: "",
                template: "",
                templateListStatus:false,
                createListDesc: "",
                templateId:"",
                status:""
                
            };
        },
         computed:{
           originalName() {
               var vm =this;
               vm.template_title = store.state.selectTemplate.template_title;
               vm.template = store.state.selectTemplate.template_title;
               vm.templateId = store.state.selectTemplate.id;
               vm.status = store.state.selectTemplateStatus;

               return vm.template_title;
               //Console.log("Custom Fil" +bulkstore.state.selectedList.list_name);
            }
       },
        methods: {
            hideModal() {
                $(".ui.modal#modal-status-template").modal("hide");
            },
            editTemplate() {
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + "v3/account/templates/edit",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: this.$cookies.get("key"),
                        template_title:vm.template_title,
                        template:vm.template,
                        template_id:vm.templateId,
                        template_status:vm.status
                    }),
                    success: function(response, status, jQxhr) {
                        alertify.success("Template has been updated");
                        vm.hideModal();
                    },
                    error: function(jQxhr, status, error) {
                        vm.templateListStatus = true;
                        vm.createListDesc = jQxhr.responseJSON.statusDescription;
                        alertify.error(jQxhr.responseJSON.statusDescription);
                    }
                });
            }
        }
    };
</script>
