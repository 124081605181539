<template>
    <div class="l-app-main bg-primary-bg">
        <div class="l-app-dashboard bg-primary-lighter" v-show="!inboxzero">
            <div class="ui equal width relaxed grid padded">
                <div class="middle align row">
                    <div class="column">
                        <div class="ui fluid steps">
                            <div class="link active step">
                                <div class="content">
                                <div class="title">Create payment</div>
                                <div class="description">Choose your shipping options</div>
                                </div>
                            </div>
                            <div class="link step">
                                <div class="content">
                                <div class="title">Confirm details</div>
                                <div class="description">Enter billing information</div>
                                </div>
                            </div>
                            <div class="link step">
                                <div class="content">
                                <div class="title">Make payment</div>
                                <div class="description">Enter billing information</div>
                                </div>
                            </div>
                        </div>
                        <h6>Last saved: 12:34pm, 23 May 20201</h6>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <div class="ui top attached tabular menu">
                            <div class="active item">Mobile money</div>
                            <div class="item">Bank</div>
                            <div class="item">Cash Pickup</div>
                        </div>
                        <div class="ui bottom attached active segment">
                            <table class="ui very basic celled table">
                                <thead>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Surname Name</th>
                                        <th>Email</th>
                                        <th>MSISDN</th>
                                        <th>Amount (USD)</th>
                                        <th class="collapsing">Country</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="tr-input">
                                        <td class="table-input"><input placeholder="John" /></td>
                                        <td class="table-input"><input placeholder="Doe" /></td>
                                        <td class="table-input"><input placeholder="Mac" /></td>
                                        <td class="table-input"><input placeholder="me@example.com" /></td>
                                        <td class="table-input"><input placeholder="254712345678" /></td>
                                        <td class="table-input"><input placeholder="120" /></td>
                                        <td class=""><select class="ui dropdown margin-top-0 margin-bottom-0"><option>Kenya</option></select></td>
                                        <td class="right aligned">
                                            <i class="icon red close"></i>
                                        </td>
                                    </tr>
                                    <tr class="tr-input">
                                        <td class="table-input"><input placeholder="John" /></td>
                                        <td class="table-input"><input placeholder="Doe" /></td>
                                        <td class="table-input"><input placeholder="Mac" /></td>
                                        <td class="table-input"><input placeholder="me@example.com" /></td>
                                        <td class="table-input"><input placeholder="254712345678" /></td>
                                        <td class="table-input"><input placeholder="120" /></td>
                                        <td class=""><select class="ui dropdown margin-top-0 margin-bottom-0"><option>Kenya</option></select></td>
                                        <td class="right aligned">
                                            <i class="icon red close"></i>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="7" class="text-center"><button class="ui fluid basic button">+ Add row</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <button class="ui button">Cancel</button>
                    </div>
                    <div class="right aligned column">
                        <button class="ui button">Save</button>
                        <button class="ui green button">Save & continue</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>

export default {
    
}
</script>
