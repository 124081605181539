<template>
    <div class="ui tiny modal" id="modal-edit-contact-list">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md"/>
                <h2 class="padding-bottom-sm">Edit Contact List</h2>
                <h4>You can use the contact list to send bulk messages</h4>
            </div>
            <div class="ui centered container grid">
                <div class="column">
                    <div class="ui form">
                        <div class="field">
                            <label>List name {{originalName}}</label>
                            <div class="ui large input">
                               <input type="text" placeholder=""  required v-model="listName" />
                            </div>
                        </div>
                         <div class="required field" id="input-description-name">
                            <div class="field">
                                <label>List Description</label>
                                <div class="ui large input">
                                    <input type="text" placeholder="e.g. all our customers" required v-model="descDetails">
                                </div>
                            </div>
                         </div>

                        <div class="field">
                            <div class="ui input">
                                <input placeholder="custom field" v-model="custom_field">
                            </div>
                        </div>
                        <button class="ui button" @click="populateCustomFields()">Add custom field.</button>
                        <div class="padding-sm"></div>
                        <div class="custom-fields" v-show="custom_field2 || custom_field3 || custom_field4 || custom_field5">
                            
                            <span class="ui blue label" v-show="custom_field2" @click="removeCustomField(2)">
                            {{custom_field2}}
                            <i class="fas fa-times"></i>
                            </span>
                                        <span class="ui purple label" v-show="custom_field3" @click="removeCustomField(3)">
                                {{custom_field3}}
                                <i class="fas fa-times"></i>
                            </span>
                                        <span class="ui orange label" v-show="custom_field4" @click="removeCustomField(4)">
                                {{custom_field4}}
                                <i class="fas fa-times"></i>
                            </span>
                                        <span class="ui teal label" v-show="custom_field5" @click="removeCustomField(5)">
                                {{custom_field5}}
                                <i class="fas fa-times"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary button" @click="updateList()">Save</button>
        </div>
    </div>
</template>

<script>
import store from "@/store";
import bulkstore from "@/bulk/bulksms-state";
import alertify from 'alertifyjs';

export default {
     data(){
        return {
            selectedList:bulkstore.state.selectedList,
            listName:'',
            custom_field1:'',
            custom_field2:'',
            custom_field3:'',
            custom_field4:'',
            custom_field5:'',
            custom_field: "",
            descDetails: "",
           // inboxzero: false,
        }
    },
    computed:{
           originalName() {
               var vm =this;
               vm.listName = bulkstore.state.selectedList.group_name;
               vm.custom_field1 = bulkstore.state.selectedList.custom_field1;
               vm.custom_field2 = bulkstore.state.selectedList.custom_field2;
               vm.custom_field3 = bulkstore.state.selectedList.custom_field3;
               vm.custom_field4 = bulkstore.state.selectedList.custom_field4;
               vm.custom_field5 = bulkstore.state.selectedList.custom_field5;
               vm.descDetails =  bulkstore.state.selectedList.description;
               return bulkstore.state.selectedList.group_name;
               //Console.log("Custom Fil" +bulkstore.state.selectedList.list_name);
            }
       },
       watch:{
           custom_field1(oldVal,val){
               // console.log(oldVal+" <=val oldVal=> "+val);
               //return bulkstore.state.selectedList.custom_field1
           }
       },
    methods: {
        hideModal(){
            $('.ui.modal#modal-edit-contact-list').modal('hide');
            
        },
        removeCustomField(key) {
            if (key == 2) {
                this.custom_field2 = "";

            } else if (key == 3) {
                this.custom_field3 = "";

            } else if (key == 4) {
                this.custom_field4 = "";

            } else if (key == 5) {
                this.custom_field5 = "";
            }
    
        },
        populateCustomFields() {
                if (!this.custom_field2) {
                    this.custom_field2 = this.custom_field;
                    this.custom_field = "";
                } else if (!this.custom_field3) {
                    this.custom_field3 = this.custom_field;
                    this.custom_field = "";
                } else if (!this.custom_field4) {
                    this.custom_field4 = this.custom_field;
                    this.custom_field = "";
                } else if (!this.custom_field5) {
                    this.custom_field5 = this.custom_field;
                    this.custom_field = "";
                } else {
                    this.customStatus = true;
                    this.StatusMessage = "Maximum fields reached";
                    console.log("Maximum fields reached");
                }
    
            },
         updateList(){
                var vm = this;
                $.post({
                    url:bulkstore.state.bulksmsUrl+'contact/list/update',
                    type:"POST",
                    data:JSON.stringify(
                        {   apiKey: vm.$cookies.get("key"),
                            listName: vm.listName,
                            list_id:bulkstore.state.selectedList.contact_groupId,
                            list_description: vm.descDetails,
                            custom_field2:vm.custom_field2 ? vm.custom_field2:'',
                            custom_field3:vm.custom_field3 ? vm.custom_field3:'',
                            custom_field4:vm.custom_field4 ? vm.custom_field4:'',
                            custom_field5:vm.custom_field5 ? vm.custom_field5:''
                        }
                    ),
                    success:function(data,status,jQxhr){
                        //mixpanel.track("Updated contact list");
                       // console.log("list success "+JSON.stringify(data.statusDescription));
                        $('#btn-save-edit').text('Save').removeAttr("disabled");
                        if(data.statusDescription){
                            alertify.success(data.statusDescription);
                        }
                        else{
                            alertify.success("Alert list successful");
                        }
                        vm.listName='';
                        vm.listDescription='';
                        vm.custom_field1='';
                        vm.custom_field2='';
                        vm.custom_field3='';
                        vm.custom_field4='';
                        vm.custom_field5='';
                        vm.$parent.getContactList();
                        vm.$root.$emit('refreshList');
                        $('.ui.modal#modal-edit-contact-list').modal('hide');
                    },
                    error:function (jQxhr,status,error) {
                        $('#btn-save-edit').text('Save').removeAttr("disabled");
                        var  error =jQxhr.responseJSON.statusDescription;
                        if(error){
                            alertify.error(error);

                        }
                        else {
                            alertify.error("An error occurred ");
                        }

                    }
                });
            }
        
    }
}
</script>
