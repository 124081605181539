<template>
    <div class="ui large modal" id="modal-client-permission">
        <i class="close icon"></i>
        <div class="scrolling content">
            <div class="text-center margin-bottom-lg">
                <img src="../assets/images/logo@2x.png" height="30" class="margin-bottom-md" />
                <h2 class="padding-bottom-sm">Select Permission For <br />{{originalName}}</h2>
    
            </div>
            <div class="ui negative message" v-show="permissionStatus">{{permissionStatusDesc}}</div>
            <div class="ui equal width centered container grid">
                <div class="column">
                    <div class="ui form">
                        <div class="field">
                            <div class="padding-xs"></div>
                            <div class="ui grid">
    
                                <div class="ten wide stretched column">
                                    <h4 class="text-medium">Select Permissions</h4>
                                    <div class="padding-xs"></div>
                                    <div class="ui grid">
                                        <div class="eight wide column">
                                            <div class="ui vertical fluid tabular menu">
    
                                                <a :class="{active: service.service_id == selectedServiceId}" class="item" v-for="service in servicesList" :key="service.service_id" :data-tab="service.service_name" @click="checkServices(service)">{{service.service_name}}</a>
    
                                            </div>
                                        </div>
                                        <div class="eight wide stretched column">
    
                                            <div v-for="service in servicesList" :key="service.service_name" :class="{active: service.service_id == selectedServiceId}" class="ui tab" :data-tab="service.service_id">
                                                <div class="ui checkbox" style="display: block; padding-bottom: .75em" v-for="permission in permissionList" :key="permission.service_id">
                                                    <input type="checkbox" :value="permission.permission_id" v-model="permissionSelected">
                                                    <label>{{permission.permission}}</label>
                                                </div>
                                            </div>
    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="ui button" @click="hideModal()">Cancel</button>
            <button class="ui bg-primary button" @click="changePerminison()">Save</button>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import bulkstore from "@/bulk/bulksms-state";
    import alertify from 'alertifyjs';
    export default {
        data() {
            return {
                username: null,
                permission: [1, 2, 3, 4, 5, 6, 7, 8],
                permissionStatus:false,
                servicesList: [],
                permissionList: [],
                selectedService: null,
                selectedServiceId: 2,
                permissionSelected: [],
                user_mapId:null,
                permissionStatusDesc:""
            }
        },
        mounted() {
            let vm = this;
            
           // vm.checkServices(service);
        },
        computed: {
            originalName() {
                var vm = this;
                vm.user_mapId = store.state.user_mapId;
                vm.username = store.state.clientUsername;
                var permissionData = store.state.permission_acl;
                vm.fetchService();
                //console.log(permissionData);
                if(permissionData == null){
                    vm.permissionSelected = [];
                }
                else{
                vm.permissionSelected = permissionData.split(":");
                }
                
                return vm.username;
    
            }
        },
        methods: {
            fetchService() {
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + "v2/users/service/view",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key")
                    }),
                    success: function(response, status, jQxhr) {
                        vm.servicesList = response.data.data;
                     //   console.log(JSON.stringify(response.data.data));
    
                    },
                    error: function(jQxhr, status, error) {
                      //  console.log("failed to create account");
                    }
                });
    
            },
            checkServices(service) {
                let vm = this;
                vm.selectedServiceId = service.service_id;
                $.ajax({
                    url: store.state.rootURLBulkAPI + "v2/users/permission/view",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        service_id: vm.selectedServiceId
                    }),
                    success: function(response, status, jQxhr) {
                        vm.permissionList = response.data.data;
                       // console.log(JSON.stringify(response.data.data));
                    },
                    error: function(jQxhr, status, error) {
                        //console.log("failed to create account");
                    }
                });
    
            },
            changePerminison() {
                let vm = this;
                $.post({
                    url: store.state.rootURLBulkAPI + "v2/users/permission/edit",
                    type: "POST",
                    data: JSON.stringify({
                        apiKey: vm.$cookies.get("key"),
                        user_mapId: vm.user_mapId,
                        permission_acl: vm.permissionSelected.join(":")
                    }),
                    success: function(response, status, jQxhr) {
                        if (response.data.code != 200) {
                            alertify.set("notifier", "position", "top-right");
                            alertify.error(response.data.message);
                            vm.permissionStatus = true;
                            vm.permissionStatusDesc = response.data.message;
                        } else {
                            vm.hideModal();
                            alertify.set("notifier", "position", "top-right");
                            alertify.success(response.data.message);
                        }
                    },
                    error: function(jQxhr, status, error) {
                       // console.log("failed "+JSON.stringify(jQxhr));
                        alertify.set("notifier", "position", "top-right");
                        alertify.error(jQxhr.responseJSON.statusDescription);
                        vm.permissionStatus = true;
                        vm.permissionStatusDesc = jQxhr.responseJSON.statusDescription;
                    }
                });
            },
            hideModal() {
                $('.ui.modal#modal-client-permission').modal('hide');
            }
        }
    }
</script>
